import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  ComponentWithAs,
  Icon,
  IconProps,
  InputAddonProps,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react"
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactNode,
} from "react"

export const INPUT_SIZES = ["default", "compact"] as const

export type InputSize = (typeof INPUT_SIZES)[number]

type InputIconType = ComponentWithAs<"svg", IconProps> | ReactNode

type BaseInputProps = {
  size?: InputSize
  rounded?: ChakraInputProps["rounded"]
  maxW?: ChakraInputProps["maxW"]
  minW?: ChakraInputProps["minW"]
  placeholder?: string
  isDisabled?: boolean
  isInvalid?: boolean
  leftIcon?: InputIconType
  rightIcon?: InputIconType
}

type InputProps = Omit<
  ComponentPropsWithoutRef<"input">,
  keyof BaseInputProps
> &
  BaseInputProps

const InputRoot = forwardRef<HTMLInputElement, PropsWithChildren<InputProps>>(
  (
    {
      size = "default",
      leftIcon,
      rightIcon,
      rounded,
      isDisabled,
      maxW,
      minW,
      children,
      ...props
    },
    ref
  ) => {
    const radius = rounded ?? (size === "compact" ? 6 : 8)

    return (
      <InputGroup
        size={size === "compact" ? "sm" : "md"}
        rounded={radius}
        position="relative"
        maxW={maxW}
        minW={minW}
        backgroundColor={isDisabled ? "ds.background.disabled" : "transparent"}
        _after={{
          content: '""',
          position: "absolute",
          inset: 0,
          zIndex: 1,
          borderRadius: "inherit",
          border: "1px solid",
          borderColor: isDisabled ? "ds.border.disabled" : "ds.border.input",
          pointerEvents: "none",
        }}
        _hover={{
          borderColor: "transparent",
          ".chakra-input:not(:focus)": {
            backgroundColor: "ds.background.input.hovered",
          },
          _after: {
            borderColor: isDisabled ? "ds.border.disabled" : "ds.border.input",
          },
        }}
        sx={{
          "--input-padding-x": `var(--chakra-space-${size === "compact" ? "2" : "3"})`,
          "--input-font-size": "var(--chakra-fontSizes-sm)",
          "&:has([aria-invalid='true'])": {
            _after: {
              borderColor: "ds.border.danger",
            },
          },
          "&:focus-within": {
            _after: {
              borderColor: "ds.border.focused",
            },
          },
          "&:has(:disabled)": {
            ".chakra-input__left-addon, .chakra-input__right-addon": {
              borderColor: "ds.border.disabled",
              color: "ds.text.disabled",
            },
          },
        }}
      >
        {children}
        {leftIcon && (
          <InputLeftElement pointerEvents="none">
            {isValidElement(leftIcon) ? (
              leftIcon
            ) : (
              <Icon
                as={leftIcon as ComponentWithAs<"svg", IconProps>}
                color="ds.icon.subtle"
              />
            )}
          </InputLeftElement>
        )}
        <ChakraInput
          ref={ref}
          rounded={radius}
          {...props}
          isDisabled={isDisabled}
          boxShadow="none"
          backgroundColor={isDisabled ? "transparent" : "ds.background.input"}
          borderColor="transparent"
          borderWidth={0}
          textStyle="ds.paragraph.primary"
          data-size={size}
          px="var(--input-padding-x)"
          _focusVisible={{
            boxShadow: "none",
            backgroundColor: "ds.background.input.focused",
          }}
          _invalid={{
            boxShadow: "none",
          }}
          _placeholder={{
            textStyle: "ds.paragraph.primary",
            color: "ds.text.subtlest",
          }}
          sx={{
            "--input-font-size": "inherit",
            ".chakra-input__left-addon ~ &": {
              borderLeftRadius: 0,
            },
            ".chakra-input__right-addon ~ &": {
              borderRightRadius: 0,
            },
          }}
        />
        {rightIcon && (
          <InputRightElement pointerEvents="none">
            {isValidElement(rightIcon) ? (
              rightIcon
            ) : (
              <Icon
                as={rightIcon as ComponentWithAs<"svg", IconProps>}
                color="ds.icon.subtle"
              />
            )}
          </InputRightElement>
        )}
      </InputGroup>
    )
  }
)

const AddOn: React.FC<InputAddonProps & { side: "left" | "right" }> = ({
  side,
  sx = {},
  children,
  ...props
}) => {
  const Component = side === "left" ? InputLeftAddon : InputRightAddon
  return (
    <Component
      {...props}
      borderLeftRadius={side === "left" ? "inherit" : undefined}
      borderRightRadius={side === "right" ? "inherit" : undefined}
      backgroundColor="ds.background.neutral.resting"
      borderColor="ds.border.input"
      borderWidth={side === "left" ? "0 1px 0 0" : "0 0 0 1px"}
      margin="0"
      px="var(--input-padding-x)"
      textStyle="ds.interface.medium"
      order={side === "right" ? 1 : undefined}
      sx={{
        ...sx,
        ...(side === "left"
          ? {
              "&:not(:first-child)": {
                borderLeftRadius: 0,
              },
            }
          : {
              "&:not(:nth-last-child(1))": {
                borderRightRadius: 0,
              },
            }),
      }}
    >
      {children}
    </Component>
  )
}

const Left: React.FC<InputAddonProps> = (props) => (
  <AddOn side="left" {...props} />
)

const Right: React.FC<InputAddonProps> = (props) => (
  <AddOn side="right" {...props} />
)

export const Input = Object.assign(InputRoot, { Left, Right })
