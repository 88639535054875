import { Container, Flex } from "@chakra-ui/react"
import {
  Body,
  Header,
  Title,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import React, { PropsWithChildren } from "react"

export const BrowserNotSupportedPage: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Container h="100%">
    <Flex
      h="100%"
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Header>
        <Title>Browser not supported</Title>
      </Header>
      <Body>{children}</Body>
    </Flex>
  </Container>
)
