import React, { ComponentProps, PropsWithChildren } from "react"

import { useLocalStorage } from "Shared/hooks/useLocalStorage"
import { FilterContextProvider } from "UsabilityHub/components/FilterControls/FilterContext"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { ROUTES } from "UsabilityHub/views/routes"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks,
  useGetModeratedStudyLocations,
} from "~/api/generated/usabilityhub-components"
import { generateApplicationsFilters } from "./applicationsFilters"

type Filters = ComponentProps<typeof FilterContextProvider>["defaultFilters"]

export const ApplicationsFilterProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { moderatedStudyId } = useTypedParams(ROUTES.INTERVIEW.APPLICANTS)
  const { data: locationsOnStudy } = useGetModeratedStudyLocations({
    pathParams: { moderatedStudyId },
  })

  const flatLocations = useFlatLocations("interviews")
  const [savedFilter, setSavedFilter] = useLocalStorage<Filters>(
    `moderated-study-${moderatedStudyId}-application-filters`,
    undefined
  )
  const { data, isLoading } =
    useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks({
      pathParams: { moderatedStudyId },
    })

  // If one of the saved filters is on source, we need to delay initializing until we have the
  // master list of sources from the query above.
  if (isLoading && savedFilter?.find((f) => f.attribute === "source"))
    return null

  const locationOptions =
    locationsOnStudy?.locations.map((a) => ({
      label: flatLocations[a.type][a.id]?.qualifiedName ?? "",
      value: `${a.type}-${a.id}`,
    })) ?? []

  const applicationFilters = generateApplicationsFilters(
    data ? data.moderated_study_orders : [],
    data ? data.moderated_study_recruitment_links : [],
    locationOptions
  )

  return (
    <FilterContextProvider
      replaceState
      entityBeingFiltered="Applicant"
      availableAttributes={applicationFilters}
      onFilterChange={setSavedFilter}
      defaultFilters={
        savedFilter ?? [
          { attribute: "eligible", comparator: "eq", options: ["true"] },
        ]
      }
    >
      {children}
    </FilterContextProvider>
  )
}
