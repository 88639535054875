import { ButtonGroup, Flex } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import React from "react"
import { getStep } from "./constants"
import { OnboardingSteppingState } from "./useOnboardingStepping"

export const FooterActions: React.FC<{
  steps: OnboardingSteppingState<any>
  isSaving: boolean
  isValid: boolean
}> = ({ steps, isSaving, isValid }) => {
  const { index, id, skip, back } = steps
  const definition = getStep(id)

  return (
    <Flex justify="space-between" align="center">
      <Button
        type="button"
        size="emphasized"
        w="fit-content"
        isDisabled={isSaving}
        visibility={index <= 1 ? "hidden" : undefined}
        onClick={back}
      >
        Back
      </Button>

      <ButtonGroup justifySelf="end">
        {skip && (
          <Button
            justifySelf={{ base: "start", lg: "center" }}
            variant="secondary"
            size="emphasized"
            onClick={skip}
          >
            Skip
          </Button>
        )}

        <Button
          variant="primary"
          size="emphasized"
          type="submit"
          form="onboarding_form"
          isDisabled={!isValid}
          isLoading={isSaving}
        >
          {definition.continue || "Continue"}
        </Button>
      </ButtonGroup>
    </Flex>
  )
}
