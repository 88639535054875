import { Flex, FlexProps, Icon, MenuButton } from "@chakra-ui/react"
import { IconButton } from "DesignSystem/components"
import { AddIcon } from "Icons/AddIcon"
import { AppliedFilter as AppliedFilterType } from "Types"
import React, { useState } from "react"
import { AddFilterButton } from "./AddFilterButton"
import { AppliedFilter } from "./AppliedFilter"
import { useFilterContext } from "./FilterContext"
import { FreeTextFilterModal } from "./FreeTextFilterModal"

export const FilterList: React.FC<FlexProps> = (flexProps) => {
  const { entityBeingFiltered, filters, changeFilter } = useFilterContext()

  const [freeTextFilter, setFreeTextFilter] =
    useState<AppliedFilterType | null>(null)

  if (filters.length === 0) return null

  return (
    <Flex wrap="wrap" gap={2} {...flexProps}>
      {filters.map((filter) => (
        <AppliedFilter
          key={filter.id}
          entityBeingFiltered={entityBeingFiltered}
          filter={filter}
          onEditFreeTextFilter={setFreeTextFilter}
        />
      ))}

      <AddFilterButton>
        <MenuButton
          as={IconButton}
          size="compact"
          aria-label="Add filter"
          icon={<Icon as={AddIcon} />}
        />
      </AddFilterButton>

      {freeTextFilter && (
        <FreeTextFilterModal
          attributeLabel={freeTextFilter.attribute.label}
          onSave={(value) => {
            changeFilter(freeTextFilter.id, freeTextFilter.comparator, {
              type: "freeText",
              value,
            })
            setFreeTextFilter(null)
          }}
          onClose={() => setFreeTextFilter(null)}
        />
      )}
    </Flex>
  )
}
