import { Box, Flex } from "@chakra-ui/react"
import { State } from "Redux/app-store"
import { pluralizeWithCount } from "Utilities/string"
import { compact, uniq } from "lodash"
import React from "react"
import { useSelector } from "react-redux"

export const ReviewingBanner: React.FC = () => {
  const data = useSelector((state: State) => state.testResults)

  const responseSectionsWithRecording = data?.responseSectionRecordings.map(
    (item) => item.response_section_id
  )
  const usabilityTestSectionsWithRecording = data?.responseSections.map((rs) =>
    responseSectionsWithRecording?.includes(rs.id)
      ? rs.usability_test_section_id
      : null
  )

  const noOfTestSectionWithRecording = compact(
    uniq(usabilityTestSectionsWithRecording)
  ).length

  if (!data) return null

  const isRevising = data.isRevising
  const responseCount = data.responses.length
  const totalResponses = data.responsesCount

  // The order review screen has a data.reviewInfo object which does not yet exist for practice tests
  // Might be useful in future if we do assignment etc.

  return (
    <Flex pos="fixed" top={0} left={0} width="full" justify="center" zIndex="1">
      <Flex
        width="800px"
        bg="white"
        border="1px solid #329af0"
        p={1}
        direction="column"
      >
        <Box textAlign="center">
          {isRevising ? "Revising" : "Reviewing"} {responseCount} /{" "}
          {totalResponses} responses from practice test "
          {data.usabilityTest.name}".{" "}
        </Box>
        {noOfTestSectionWithRecording > 0 && (
          <Box textAlign="center">
            This test contains{" "}
            {pluralizeWithCount(
              noOfTestSectionWithRecording,
              "recorded section",
              "recorded sections"
            )}
            . Please review each video/audio recording for quality.
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
