import { Flex, Text } from "@chakra-ui/react"
import { StudyRequirements } from "Participants/components/StudyRequirements"
import React from "react"
import { BrowserNotSupportedPage } from "../context/BrowserNotSupportedPage"

export const LiveWebsiteTestBrowserNotSupportedPage = () => (
  <BrowserNotSupportedPage>
    <Flex flexDirection="column" gap={6}>
      <Text textStyle="ds.paragraph.emphasized">
        This test is supported only on Chromium-based desktop browsers like
        Chrome and Edge. Switch to a compatible browser that supports the
        following requirements to participate:
      </Text>
      <StudyRequirements requirements={["picture_in_picture"]} />
    </Flex>
  </BrowserNotSupportedPage>
)
