import {
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import React, { PropsWithChildren } from "react"

import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { QuestionCheckboxIcon } from "Icons/QuestionCheckboxIcon"
import { QuestionLongTextIcon } from "Icons/QuestionLongTextIcon"
import { QuestionRadioIcon } from "Icons/QuestionRadioIcon"
import { QuestionShortTextIcon } from "Icons/QuestionShortTextIcon"

import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"
import { ScreenerAnswer } from "./participantInfoDrawerData"

export interface Props {
  screenerQuestion: ScreenerQuestion
  screenerAnswer: ScreenerAnswer
  questionIndex: number
}

export const ParticipantDrawerScreenerAnswer = ({
  screenerQuestion,
  screenerAnswer,
  questionIndex,
}: Props) => {
  return (
    <ScreenerAnswerContainer
      {...{ screenerAnswer, screenerQuestion }}
      questionIndex={questionIndex}
    >
      {isChoiceAnswer(screenerAnswer) ? (
        screenerAnswer.type === "multi_select" ? (
          <UnorderedList ps={3}>
            {screenerAnswer.selected_options?.map((n) => (
              <ListItem key={n.screener_question_option_id}>
                <Flex alignItems="center" gap={2}>
                  <Text as="span">
                    {
                      screenerQuestion.options?.find(
                        (q) => q.id === n.screener_question_option_id
                      )?.value
                    }
                  </Text>
                  {n.rule === "qualify" && (
                    <CheckCircleFilledIcon color={"green.400"} />
                  )}
                </Flex>
              </ListItem>
            ))}
          </UnorderedList>
        ) : (
          // single select
          screenerAnswer.selected_options?.map((n) => (
            <Flex
              alignItems="center"
              gap={2}
              key={n.screener_question_option_id}
            >
              <Text as="span">
                {
                  screenerQuestion.options?.find(
                    (q) => q.id === n.screener_question_option_id
                  )?.value
                }
              </Text>
              {n.rule === "qualify" && (
                <CheckCircleFilledIcon color={"green.400"} />
              )}
            </Flex>
          ))
        )
      ) : (
        // short_text or long_text
        <Text>{screenerAnswer.answer}</Text>
      )}
    </ScreenerAnswerContainer>
  )
}

const ScreenerAnswerContainer: React.FC<
  {
    screenerQuestion: ScreenerQuestion
    screenerAnswer: ScreenerAnswer
    questionIndex: number
  } & PropsWithChildren
> = ({ screenerQuestion, screenerAnswer, questionIndex, children }) => (
  <Box px={2} color="text.primary">
    <Flex gap={2} mb={3} color="text.secondary" alignItems="center">
      <ScreenerQuestionTypeIcon type={screenerAnswer.type} />
      <Text as="span" fontSize="sm">
        {questionIndex}. {getScreenerQuestionTypeLabel(screenerAnswer.type)}
      </Text>
    </Flex>
    <DisplayBlockMarkdownText text={screenerQuestion.text} />
    <Box mt={2} mb={8} color="text.secondary">
      {children}
    </Box>
  </Box>
)

type ChoiceAnswer = Extract<ScreenerAnswer, { selected_options: any }>

const isChoiceAnswer = (answer: ScreenerAnswer): answer is ChoiceAnswer => {
  return "selected_options" in answer
}

const ScreenerQuestionTypeIcon: React.FC<{ type: ScreenerAnswer["type"] }> = ({
  type,
}) => {
  const icon = {
    short_text: QuestionShortTextIcon,
    long_text: QuestionLongTextIcon,
    single_select: QuestionRadioIcon,
    multi_select: QuestionCheckboxIcon,
  }[type]

  return <Icon as={icon} boxSize={3.5} color="gray.400" />
}

export const getScreenerQuestionTypeLabel: React.FC<string> = (type) =>
  ({
    short_text: "Short text question",
    long_text: "Long text question",
    single_select: "Single Choice question",
    multi_select: "Multi Choice question",
  })[type]
