import { Flex } from "@chakra-ui/react"
import React from "react"

import { DragHandleIcon } from "Icons/DragHandleIcon"

interface DragHandleProps {
  readonly isDisabled?: boolean
  color?: string
}

export const DragHandle: React.FC<React.PropsWithChildren<DragHandleProps>> = ({
  isDisabled = false,
  color,
}) => {
  const disabledStyles = {
    opacity: 0.5,
    cursor: "not-allowed",
  }

  return (
    <Flex
      w="100%" // Fill whatever space we're given so we can center appropriately
      h="100%" // and give an accurate indicator of grab area
      alignItems="center"
      justifyContent="center"
      cursor="grab"
      color={color}
      {...(isDisabled && disabledStyles)}
    >
      <DragHandleIcon w={5} h={5} />
    </Flex>
  )
}
