import { Box, BoxProps, Flex, Text, useToast } from "@chakra-ui/react"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import { RawAudioScreenshot as AudioScreenshotType } from "Types"

import { VideoPlayer } from "./VideoPlayer"

interface AudioScreenshotProps extends BoxProps {
  screenshot: AudioScreenshotType
  canSkipAhead: boolean
  onMediaEnded: () => void
}

export const AudioScreenshot: React.FC<
  React.PropsWithChildren<AudioScreenshotProps>
> = ({ screenshot, onMediaEnded, canSkipAhead, ...boxProps }) => {
  const toast = useToast()
  const translate = useTranslate()
  return (
    <Box bg="brand.primary.100" p={4} {...boxProps}>
      <Flex
        lineHeight="short"
        align="center"
        position="absolute"
        justify="center"
        direction="column"
        top={4}
        left={4}
        right={4}
        bottom={4}
        pointerEvents="none"
      >
        <Text fontWeight="bold">
          {translate("test.screenshots.audio.audio_only")}
        </Text>
        <Text>{translate("test.screenshots.audio.enable")}</Text>
      </Flex>
      <VideoPlayer
        url={screenshot.url || undefined}
        canSkipAhead={canSkipAhead}
        onMediaEnded={onMediaEnded}
        onSkippedAhead={() => {
          toast.closeAll()
          toast({
            title: translate("test.screenshots.audio.skipping_ahead_disabled"),
            status: "warning",
          })
        }}
      />
    </Box>
  )
}
