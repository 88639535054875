import { Spinner } from "@chakra-ui/react"
import { Flex } from "@chakra-ui/react"
import { Alert } from "DesignSystem/components"
import { DeviceFramesProvider } from "UsabilityHub/contexts/DeviceFramesContext"
import React, { PropsWithChildren } from "react"
import { ResultUsabilityTestResponseData } from "./types"
import { useResultsDataSource } from "./useResultsDataSource"

type Props = {
  queryKey: string
  apiFunction: () => Promise<ResultUsabilityTestResponseData>
}

export const BaseResultsDataProvider: React.FC<PropsWithChildren<Props>> = ({
  queryKey,
  apiFunction,
  children,
}) => {
  const { isInitialized, error, data } = useResultsDataSource(
    queryKey,
    apiFunction
  )

  if (error) {
    return <Alert status="danger" description={error.message} />
  }

  if (!isInitialized) {
    return (
      <Flex mt="32" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    <DeviceFramesProvider deviceFrames={data.device_frames}>
      {children}
    </DeviceFramesProvider>
  )
}
