import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react"
import { IconButton } from "DesignSystem/components"
import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { useMatch, useNavigate } from "react-router"
import { VariationSetUsabilityTest } from "~/api/generated/usabilityhubSchemas"
import PreviewsApi from "~/api/previewsApi"
import { DeleteVariationSetModal } from "./DeleteVariationSetModal"
import { RenameVariationSetModal } from "./RenameVariationSetModal"

type VariationSetMenu = React.FC<
  React.PropsWithChildren<{
    variationSet: {
      id: number
      unique_id: string
      name: string
      usability_tests: VariationSetUsabilityTest[]
    }
  }>
>

export const VariationSetMenu: VariationSetMenu = ({ variationSet }) => {
  const navigate = useNavigate()
  const showManagementActions = useCurrentUser().can_manage_tests
  const onVariationSetPage = useMatch("/sets/:unique_id/*") !== null

  const { open: openRenameModal } = useModal(RenameVariationSetModal)
  const { open: openDeleteModal } = useModal(DeleteVariationSetModal)

  return (
    <Menu placement="bottom-end" isLazy>
      {onVariationSetPage ? (
        <MenuButton
          as={IconButton}
          variant="default"
          icon={<Icon boxSize={4} as={MenuKebabIcon} />}
        />
      ) : (
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon boxSize={4} as={MenuKebabIcon} />}
          variant="subtle"
        />
      )}
      <Portal>
        <MenuList>
          <MenuItem
            as="a"
            target="_blank"
            justifyContent="space-between"
            href={PreviewsApi.set.path({ unique_id: variationSet.unique_id })}
            isDisabled={variationSet.usability_tests.length === 0}
          >
            Preview
            <Icon
              as={LinkExternalIcon}
              alignSelf="center"
              boxSize={4}
              color="gray.400"
            />
          </MenuItem>
          {showManagementActions && (
            <>
              <MenuItem
                onClick={() => openRenameModal({ variationSet: variationSet })}
              >
                Rename
              </MenuItem>
              <MenuItem
                color="red.600"
                onClick={() =>
                  openDeleteModal({
                    variationSet: variationSet,
                    onSuccess: () => {
                      if (onVariationSetPage) navigate("/dashboard")
                    },
                  })
                }
              >
                Delete
              </MenuItem>
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}
