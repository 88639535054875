import React, { PropsWithChildren } from "react"
import { useCallback, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import {
  AppliedFilter,
  FilterAttribute,
  FilterComparator,
  FilterOptionValue,
} from "Types"
import { serializeFilterOptions } from "UsabilityHub/components/FilterControls/utils"
import {
  decodeValueFromURLUse,
  encodeValueForURLUse,
} from "Utilities/encode-values-for-url"

interface FilterContextValue {
  entityBeingFiltered: string
  availableAttributes: FilterAttribute[]
  filters: AppliedFilter[]
  addFilter: (
    attribute: FilterAttribute,
    options: FilterOptionValue
  ) => AppliedFilter
  changeFilter: (
    id: string,
    newComparator: FilterComparator,
    newOptions: FilterOptionValue
  ) => AppliedFilter | null
  removeFilter: (id: string) => void
  removeAllFilters: () => void
}

type SerializedFilter = {
  attribute: string
  comparator: string
  options: string[]
}

const FilterContext = React.createContext<FilterContextValue | null>(null)

export const useFilterContext = () => {
  const contextValue = React.useContext(FilterContext)

  if (!contextValue) {
    throw new Error(
      "useFilterContext must be used within a FilterContextProvider"
    )
  }

  return contextValue
}

export interface FilterContextProviderProps {
  entityBeingFiltered: string
  availableAttributes: FilterAttribute[]
  defaultFilters?: SerializedFilter[]
  onFilterChange?: (filters: SerializedFilter[]) => void
  replaceState?: boolean
}

export const FilterContextProvider: React.FC<
  PropsWithChildren<FilterContextProviderProps>
> = ({
  entityBeingFiltered,
  availableAttributes,
  onFilterChange,
  defaultFilters = [],
  children,
  replaceState = false,
}) => {
  const [, setSearchParams] = useMutableQueryString({ replace: replaceState })

  const [filters, setFilters] = useState<AppliedFilter[]>(() => {
    // Load filters from the URL on initial render
    const queryParams = new URLSearchParams(window.location.search)
    const encodedFilter = queryParams.get("filter")

    const serializedFilters = encodedFilter
      ? decodeValueFromURLUse<SerializedFilter[]>(encodedFilter)
      : null

    const filtersToDeserialize = serializedFilters ?? defaultFilters

    return filtersToDeserialize
      .map((f) => {
        const attribute = availableAttributes.find(
          (a) => a.value === f.attribute
        )
        if (!attribute) return

        let options: FilterOptionValue

        if (attribute.possibleOptions.type === "freeText") {
          options = {
            type: "freeText",
            value: f.options.join(),
          }
        } else if (attribute.possibleOptions.type === "static") {
          options = { type: "static" }
        } else if (attribute.possibleOptions.type === "multi") {
          const choices = attribute.possibleOptions.choices.filter((c) =>
            f.options.includes(c.value)
          )
          options = { type: "multi", choices }
        } else if (attribute.possibleOptions.type === "single") {
          const choice = attribute.possibleOptions.choices.find((c) =>
            f.options.includes(c.value)
          )
          if (!choice) return
          options = { type: "single", choice }
        } else {
          return
        }

        if (!options) return

        return {
          id: uuidv4(),
          attribute,
          comparator:
            attribute?.possibleComparators.find(
              (c) => c.value === f.comparator
            ) || attribute?.possibleComparators[0],
          options,
        }
      })
      .filter(isNotNullish)
  })

  // Sync filter changes to the URL and via onFilterChange (if provided)
  useEffect(() => {
    if (filters.length === 0) {
      onFilterChange?.([])
      setSearchParams({ filter: null })
    } else {
      const serializedFilters: SerializedFilter[] = filters.map((filter) => ({
        attribute: filter.attribute.value,
        comparator: filter.comparator.value,
        options: serializeFilterOptions(filter.options),
      }))

      onFilterChange?.(serializedFilters)
      setSearchParams({ filter: encodeValueForURLUse(serializedFilters) })
    }
  }, [filters, setSearchParams])

  const addFilter = useCallback(
    (attribute: FilterAttribute, options: FilterOptionValue) => {
      const newFilter = {
        id: uuidv4(),
        attribute,
        // Always start off with the first comparator by default
        comparator:
          attribute.possibleComparators.find((c) => c.default) ??
          attribute.possibleComparators[0],
        options,
      }

      setFilters((currentFilters) => [...currentFilters, newFilter])

      return newFilter
    },
    []
  )

  const changeFilter = useCallback(
    (
      id: string,
      newComparator: FilterComparator,
      newOptions: FilterOptionValue
    ) => {
      let updatedFilter: AppliedFilter | null = null

      setFilters((currentFilters) =>
        currentFilters.map((filter) => {
          if (filter.id !== id) return filter

          updatedFilter = {
            ...filter,
            comparator: newComparator,
            options: newOptions,
          }

          return updatedFilter
        })
      )

      return updatedFilter
    },
    []
  )

  const removeFilter = useCallback((id: string) => {
    setFilters((currentFilters) =>
      currentFilters.filter((filter) => filter.id !== id)
    )
  }, [])

  const removeAllFilters = useCallback(() => {
    setFilters([])
  }, [])

  return (
    <FilterContext.Provider
      value={{
        entityBeingFiltered,
        availableAttributes,
        filters,
        addFilter,
        changeFilter,
        removeFilter,
        removeAllFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

function isNotNullish<T>(element: T | undefined | null): element is T {
  return element !== undefined && element !== null
}
