import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import { ROUTES } from "UsabilityHub/views/routes"
import { FunctionalModal } from "Utilities/modals/types"
import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  GetModeratedStudyBookingsResponse,
  RescheduleModeratedStudyBookingRequestBody,
} from "~/api/generated/usabilityhub-components"
import { bookingWithin12Hours } from "./CancelBookingModal"

interface Props {
  rescheduleBooking: (body: RescheduleModeratedStudyBookingRequestBody) => void
  hasAvailableBookingSlots: boolean
  moderatedStudyId: string
  booking: GetModeratedStudyBookingsResponse[number]
}

export const RescheduleBookingModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  rescheduleBooking,
  hasAvailableBookingSlots,
  moderatedStudyId,
  booking,
}) => {
  const [reason, setReason] = useState<string | null>(null)

  const isPanelist = booking.is_panelist
  const isBookingWithin12Hours = bookingWithin12Hours(
    new Date(booking.starts_at)
  )

  const handleSend = () => {
    rescheduleBooking({ reason, booking_begins_soon: isBookingWithin12Hours })
    onClose()
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setReason(e.target.value)

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader textColor="text.primary" fontSize="lg" fontWeight="bold">
            Request reschedule
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody as={Stack} spacing={2}>
            {!hasAvailableBookingSlots && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                <Box>
                  <AlertTitle w="100%">No availability</AlertTitle>
                  <AlertDescription>
                    Participant will be unable to choose a time.{" "}
                    <Link
                      as={RouterLink}
                      onClick={() => onClose()}
                      textDecoration="none"
                      to={ROUTES.INTERVIEW.EDIT.buildPath({
                        moderatedStudyId,
                      })}
                    >
                      Change availability
                    </Link>
                  </AlertDescription>
                </Box>
              </Alert>
            )}
            <Heading
              fontSize="md"
              fontWeight="medium"
              textColor="text.primary"
              mb={2}
            >
              Reason to reschedule (Optional)
            </Heading>
            <Textarea
              height="8.25rem"
              textColor="text.primary"
              onChange={handleTextChange}
              autoFocus
            />

            {isPanelist ? (
              isBookingWithin12Hours ? (
                <>
                  <Text fontSize="sm" color="text.primary">
                    <strong>Important:</strong> This session is within the next
                    12 hours. If the panelist declines the request to reschedule
                    or if they do not respond within 3 days, the slot will not
                    be replaced and{" "}
                    <Text
                      as="span"
                      textDecoration="underline"
                      fontWeight="bold"
                    >
                      you will not be refunded.
                    </Text>{" "}
                  </Text>
                  <Text fontSize="sm" color="text.primary">
                    <Link
                      href={
                        Constants.HELP_CENTER_MANAGING_INTERVIEW_SESSIONS_URL
                      }
                      isExternal
                    >
                      Read more about our cancelation policy
                    </Link>
                  </Text>
                </>
              ) : (
                <Text fontSize="sm" color="text.primary">
                  Once you confirm the reschedule request, the panelist will
                  have up to 3 days to respond.
                </Text>
              )
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Flex gap={2} w="100%" align="center">
              <Text color="red.600">
                <Icon
                  as={WarningFilledIcon}
                  color="red.600"
                  aria-label="Attention"
                  mr={2}
                />
                This will cancel the existing session
              </Text>
              <Spacer />
              <Button
                variant="outline"
                textColor="text.primary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="brand.primary"
                onClick={handleSend}
              >
                Send
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
