import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "DesignSystem/components"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"
import {
  useDeleteAutomaticPurchase,
  useGetAutomaticPurchase,
} from "~/api/generated/usabilityhub-components"

export const AutoTopUpDeleteModal: FunctionalModal = ({ isOpen, onClose }) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { data, isLoading } = useGetAutomaticPurchase({})

  const { mutate: deleteAutomaticPurchase } = useDeleteAutomaticPurchase({
    onSuccess: () => {
      toast({
        title: "Auto top-up turned off",
        description: `We${"\u2019"}ll no longer automatically purchase credits for you.`,
        status: "success",
      })

      onClose()

      return queryClient.invalidateQueries([
        "api",
        "credits",
        "automatic_purchase",
      ])
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.payload.message,
        status: "error",
      })
    },
  })

  const onDelete = () => {
    deleteAutomaticPurchase({
      body: { current_purchase_id: data!.automatic_purchase!.id },
    })
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent borderRadius={16} width="400px">
        <ModalCloseButton size="sm" borderRadius="50%" />
        <ModalHeader
          textStyle="ds.display.primary"
          fontWeight={500}
          paddingTop={6}
          paddingBottom={0}
          paddingX={6}
        >
          Turn off auto top-up
        </ModalHeader>
        <ModalBody p={6}>
          <Text textStyle={"ds.paragraph.primary"}>
            Credits will no longer be automatically purchased for your account.
            You can activate this again at any time
          </Text>
        </ModalBody>

        <ModalFooter paddingTop={0} paddingBottom={6} paddingX={6} gap={3}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDelete}>
            Yes turn off auto top-up
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
