import { Flex, Icon, IconProps, chakra, keyframes } from "@chakra-ui/react"
import { SparklesFilledIcon } from "Icons/SparklesFilledIcon"
import { random, range } from "lodash"
import React from "react"
import { usePrefersReducedMotion } from "./usePrefersReducedMotion"
import { useRandomInterval } from "./useRandomInterval"

export const Sparkles: React.FC<React.PropsWithChildren> = ({
  children,
  ...delegated
}) => {
  const [sparkles, setSparkles] = React.useState(() => {
    return range(2).map(() => generateSparkle())
  })
  const prefersReducedMotion = usePrefersReducedMotion()
  useRandomInterval(
    () => {
      const sparkle = generateSparkle()
      const now = Date.now()
      const nextSparkles = sparkles.filter((sp) => {
        const delta = now - sp.createdAt
        return delta < 1000
      })
      nextSparkles.push(sparkle)
      setSparkles(nextSparkles)
    },
    prefersReducedMotion ? null : 350,
    prefersReducedMotion ? null : 650
  )

  return (
    <Wrapper {...delegated}>
      {sparkles.map((sparkle) => (
        <SparkleInstance key={sparkle.id} {...sparkle} />
      ))}
      <ChildWrapper>{children}</ChildWrapper>
    </Wrapper>
  )
}

const generateSparkle = () => {
  const randomSide = random(0, 3) // 0: top, 1: right, 2: bottom, 3: left
  let top = random(-20, 80) + "%"
  let left = random(-2, 98) + "%"

  switch (randomSide) {
    case 0:
      top = random(-20, -15) + "%"
      break
    case 1:
      left = random(95, 98) + "%"
      break
    case 2:
      top = random(70, 80) + "%"
      break
    case 3:
      left = random(-2, 2) + "%"
      break
  }
  return {
    id: String(random(10000, 99999)),
    createdAt: Date.now(),
    boxSize: random(2, 5),
    // Pick a random spot in the available space
    top,
    left,
  }
}

const SparkleInstance: React.FC<IconProps> = ({ ...props }) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <Flex
      position="absolute"
      left={props.left}
      top={props.top}
      borderRadius="50%"
      zIndex="2"
      css={
        prefersReducedMotion
          ? {}
          : { animation: `${comeInOut} 1000ms forwards` }
      }
    >
      <Flex
        css={prefersReducedMotion ? {} : { animation: `${spin} 2000ms linear` }}
      >
        <Icon
          as={SparklesFilledIcon}
          color="yellow.400"
          boxSize={props.boxSize}
        />
      </Flex>
    </Flex>
  )
}

const comeInOut = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`

const Wrapper = chakra("span", {
  baseStyle: {
    display: "inline-block",
    position: "relative",
  },
})

const ChildWrapper = chakra("strong", {
  baseStyle: {
    position: "relative",
    zIndex: 1,
    fontWeight: "bold",
  },
})
