import { Checkbox, Flex, Icon, Text } from "@chakra-ui/react"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import React from "react"

export type SelectionState = "selected" | "unselected" | "indeterminate"

type Props = {
  label: string
  secondaryLabel?: string
  selectionState: SelectionState
  onToggle: () => void
  onDrill?: () => void
}

export const LocationRow: React.FC<Props> = ({
  label,
  secondaryLabel,
  selectionState,
  onToggle,
  onDrill,
}) => {
  return (
    <Flex
      align="stretch"
      _hover={{
        bg: "ds.background.neutral.subtle.hovered",
      }}
      cursor="pointer"
    >
      <Flex
        align="center"
        onClick={onToggle}
        ps={4}
        pe={2}
        data-qa="location-select-hitbox"
      >
        <Checkbox
          isChecked={selectionState === "selected"}
          isIndeterminate={selectionState === "indeterminate"}
          pointerEvents="none"
        />
      </Flex>

      <Flex
        flexGrow={1}
        // Clicking the non-checkbox part of the row will drill down if there is nesting below.
        // Otherwise it will toggle the checkbox.
        onClick={onDrill ?? onToggle}
      >
        <Flex flexGrow={1} direction="column" gap={0.5}>
          <Text textStyle="ds.text.paragraph.primary">
            {label}
            {secondaryLabel && (
              <Text as="span" color="ds.text.subtle" ms={1}>
                {secondaryLabel}
              </Text>
            )}
          </Text>
        </Flex>

        {onDrill && (
          <Icon as={ChevronRightIcon} boxSize={5} me={1} pointerEvents="none" />
        )}
      </Flex>
    </Flex>
  )
}
