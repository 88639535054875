import { Badge, Box, HStack, Icon, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { ExpandableRegion } from "Components/expandable-region/expandable-region"
import { iconForQuestionType } from "Components/question-type-icon/question-type-icon"
import {
  TestCardHeading,
  TestCardHint,
} from "UsabilityHub/components/TestCard/TestCard"
import { TestLogicBadge } from "UsabilityHub/components/TestLogicBadge/TestLogicBadge"
import {
  useQuestionContext,
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import { getFullSectionTitle } from "Utilities/get-full-section-title"
import { floorMsToS } from "Utilities/time"
import {
  getQuestionPrefix,
  getQuestionTypeLabel,
} from "Utilities/usability-test-section-question"
import { isPresent } from "Utilities/values"

import { getResultSectionTypeRules } from "./resultSectionTypeRules"

interface SectionResultsSectionHeadingProps {
  taskTimeLimit?: number
}

export const SectionResultsSectionHeading: React.FC<
  React.PropsWithChildren<SectionResultsSectionHeadingProps>
> = ({ taskTimeLimit }) => {
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const { colorScheme, icon } = getResultSectionTypeRules(section)
  const showLogicBadge = !!section.test_logic_statement

  const sectionTitle = getFullSectionTitle(section, sectionIndex)

  return (
    <HStack>
      <Box
        backgroundColor={colorScheme}
        rounded="full"
        boxSize={5}
        flexShrink={0}
        p={0.5}
      >
        <Icon as={icon} boxSize={4} color="white" display="block" />
      </Box>
      <TestCardHeading>{sectionTitle}</TestCardHeading>
      {taskTimeLimit !== undefined && (
        <TestCardHint>
          (design shown for {floorMsToS(taskTimeLimit)}s)
        </TestCardHint>
      )}
      {showLogicBadge && <TestLogicIndicator fieldType="section" />}
    </HStack>
  )
}

export const SectionResultsSectionText = () => {
  const { section } = useSectionContext()

  if (!isPresent(section.text)) {
    return null
  }
  return (
    <ExpandableRegion
      maxHeight={200}
      expandText="Expand"
      collapseText="Collapse"
    >
      <DisplayBlockMarkdownText text={section.text} />
    </ExpandableRegion>
  )
}

export const SectionResultsQuestionHeading = () => {
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const { question, questionIndex } = useQuestionContext()
  const sectionNumber = sectionIndex + 1
  const showLogicBadge =
    !!section.test_logic_statement || !!question.test_logic_statement

  return (
    <HStack>
      {iconForQuestionType(question.type)}
      <Text textStyle="label">
        {getQuestionPrefix(sectionNumber, questionIndex)}{" "}
        {getQuestionTypeLabel(question.type)}
      </Text>
      {question.required && (
        <Badge backgroundColor="brand.background.light">Required</Badge>
      )}
      {showLogicBadge && <TestLogicIndicator fieldType="question" />}
    </HStack>
  )
}

export const SectionResultsQuestionText = () => {
  const { question } = useQuestionContext()
  return <DisplayBlockMarkdownText text={question.text} />
}

interface TestLogicIndicatorProps {
  fieldType: string
}

const TestLogicIndicator: React.FC<
  React.PropsWithChildren<TestLogicIndicatorProps>
> = ({ fieldType }) => (
  <Tooltip
    label={`This ${fieldType} was only shown to some participants due to logic conditions`}
    hasArrow
    placement="top"
  >
    <TestLogicBadge alignSelf="center" ml={2} />
  </Tooltip>
)
