import {
  ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"

import { FilterAttribute } from "Types"

import { Button } from "DesignSystem/components"
import { AddIcon } from "Icons/AddIcon"
import { useFilterContext } from "./FilterContext"
import { FilterMenuMulti } from "./FilterMenuMulti"
import { FilterMenuSingle } from "./FilterMenuSingle"
import { FreeTextFilterModal } from "./FreeTextFilterModal"

interface Props {
  // Switch to a clear button if there are filters applied
  showClearButton?: boolean
}

export const AddFilterButton: React.FC<ButtonProps & Props> = ({
  showClearButton = false,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { availableAttributes, addFilter, filters, removeAllFilters } =
    useFilterContext()
  const [selectedAttribute, setSelectedAttribute] =
    useState<FilterAttribute | null>(null)

  useEffect(() => {
    if (isOpen) {
      setSelectedAttribute(null)
    }
  }, [isOpen])

  if (showClearButton && filters.length > 0) {
    return (
      <Button variant="secondary" onClick={removeAllFilters}>
        Clear filters
      </Button>
    )
  }

  return (
    <>
      <Menu
        closeOnSelect={!!selectedAttribute}
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {children ?? (
          <MenuButton
            as={Button}
            variant="secondary"
            leftIcon={<Icon as={AddIcon} />}
            {...props}
          >
            Add filter
          </MenuButton>
        )}
        <MenuList overflow="hidden">
          {!selectedAttribute ? (
            availableAttributes.map((attribute) => (
              <MenuItem
                key={attribute.value}
                onClick={() => {
                  if (attribute.possibleOptions.type === "static") {
                    addFilter(attribute, { type: "static" })
                    setIsOpen(false)
                  } else if (attribute.possibleOptions.type === "freeText") {
                    setSelectedAttribute(attribute)
                  } else {
                    setSelectedAttribute(attribute)
                  }
                }}
              >
                {attribute.label}
              </MenuItem>
            ))
          ) : selectedAttribute.possibleOptions.type === "single" ? (
            <FilterMenuSingle selectedAttribute={selectedAttribute} />
          ) : selectedAttribute.possibleOptions.type === "multi" ? (
            <FilterMenuMulti selectedAttribute={selectedAttribute} />
          ) : (
            <div>select value</div>
          )}
        </MenuList>
      </Menu>

      {selectedAttribute &&
        selectedAttribute.possibleOptions.type === "freeText" && (
          <FreeTextFilterModal
            attributeLabel={selectedAttribute.label}
            onSave={(value) => {
              addFilter(selectedAttribute, { type: "freeText", value })
              setSelectedAttribute(null)
            }}
            onClose={() => setSelectedAttribute(null)}
          />
        )}
    </>
  )
}
