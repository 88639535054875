import {
  Box,
  BoxProps,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Stack,
  useDisclosure,
} from "@chakra-ui/react"
import { StripeSubscriptionPastDueBanner } from "Components/stripe-subscription-past-due-banner/stripe-subscription-past-due-banner"
import { IconButton } from "DesignSystem/components"
import { CloseIcon } from "Icons/CloseIcon"
import { MenuHamburgerIcon } from "Icons/MenuHamburgerIcon"
import { SearchIcon } from "Icons/SearchIcon"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { LyssnaLogoIcon } from "Shared/icons/LyssnaLogoIcon"
import { AccountNotInGoodStandingBanner } from "UsabilityHub/components/AccountNotInGoodStandingBanner/AccountNotInGoodStandingBanner"
import { HEADER_NAVBAR_HEIGHT } from "UsabilityHub/constants/layoutConstants"
import {
  useMaybeCurrentPlan,
  useMaybeCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useIsLoggedIn } from "UsabilityHub/hooks/useIsLoggedIn"
import { useShowAccountNotInGoodStandingBanner } from "UsabilityHub/hooks/useShowAccountNotInGoodStandingBanner"
import { useShowStripeSubsriptionPastDueBanner } from "UsabilityHub/hooks/useShowStripeSubsriptionPastDueBanner"
import { ROUTES } from "UsabilityHub/views/routes"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React from "react"
import { Link } from "react-router-dom"
import { CreditsButton } from "./CreditsButton"
import { DashboardButton } from "./DashboardButton"
import { FreeTrialPersistentCallout } from "./FreeTrialPersistentCallout"
import { HelpMenu } from "./HelpMenu"
import { MobileMenu } from "./MobileMenu"
import { SettingsMenu } from "./SettingsMenu"
import { UpdatesMenu } from "./UpdatesMenu"

type UsabilityHubNavbar = React.FC<
  React.PropsWithChildren<{
    isOnDashboard?: boolean
    // Temporary until we have the breadcrumbs header on all pages so there's a
    // way to get back to the dashboard - then we can remove this and always
    // just show the logo
    showLogoInsteadOfDashboardButton?: boolean
    isOnSignInPage?: boolean
    isOnSignUpPage?: boolean
  }>
>

export const UsabilityHubNavbar: UsabilityHubNavbar = ({
  isOnDashboard = false,
  isOnSignInPage = false,
  isOnSignUpPage = false,
  showLogoInsteadOfDashboardButton = false,
  children,
}) => {
  const { isOpen: mobileMenuIsOpen, onToggle: toggleMobileMenu } =
    useDisclosure()
  const isLoggedIn = useIsLoggedIn()
  const currentUser = useMaybeCurrentUser()
  const currentPlan = useMaybeCurrentPlan()
  const showAccountNotInGoodStandingBanner =
    useShowAccountNotInGoodStandingBanner()
  const showStripeSubscriptionPastDueBanner =
    useShowStripeSubsriptionPastDueBanner()

  return (
    <>
      <Navbar>
        {isLoggedIn && !isOnSignUpPage ? (
          <Grid
            w="full"
            h="full"
            templateAreas={{
              base: `"logo logo logo callouts actions"`,
              xl: `"logo callouts actions"`,
            }}
            gridTemplateColumns={{ base: "auto 1fr 1fr", xl: "auto 1fr auto" }}
            gridAutoFlow="column"
            gridRow="1"
            justifyContent="space-between"
          >
            <GridItem area={"logo"} display="flex" alignItems="center">
              <HStack
                gap={{ base: 4, md: 8 }}
                flexBasis="100%"
                justifyContent="space-between"
              >
                {isOnDashboard || showLogoInsteadOfDashboardButton ? (
                  <Link to={ROUTES.DASHBOARD.path}>
                    <LyssnaLogoIcon h={8} w="auto" maxW="128px" />
                  </Link>
                ) : (
                  <DashboardButton />
                )}

                {children && (
                  <Box display={{ base: "none", md: "flex" }}>{children}</Box>
                )}
              </HStack>
            </GridItem>
            <GridItem
              area={"callouts"}
              display="flex"
              justifySelf={{ lg: "center" }}
              alignItems="center"
            >
              <FreeTrialPersistentCallout
                source="global_nav_callout"
                isOnDashboard={isOnDashboard}
              />
            </GridItem>
            <GridItem
              area={"actions"}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                display={{ base: "none", lg: "flex" }}
              >
                <UpdatesMenu />
                {/* The Intercom tour had problems detecting the help button when this attribute
              was on the button itself, so now it's on the button container */}
                <Box data-intercom-target="help-button">
                  <HelpMenu />
                </Box>
                <CreditsButton />
                <Box>
                  <SettingsMenu />
                </Box>
              </Stack>
              <Flex gap={2}>
                {isOnDashboard && (
                  <IconButton
                    as={Link}
                    to={ROUTES.SEARCH.path}
                    justifyContent="center"
                    display={["flex", null, null, "none"]}
                    icon={<Icon as={SearchIcon} />}
                    variant="secondary"
                    aria-label="search"
                  />
                )}
                <IconButton
                  onClick={toggleMobileMenu}
                  variant="secondary"
                  display={{ base: "flex", lg: "none" }}
                  icon={
                    mobileMenuIsOpen ? (
                      <Icon as={CloseIcon} />
                    ) : (
                      <Icon as={MenuHamburgerIcon} />
                    )
                  }
                  aria-label="Menu"
                />
              </Flex>
            </GridItem>
          </Grid>
        ) : (
          <Flex justify="space-between" flex={1} h="full" alignItems="center">
            {isOnSignInPage || isOnSignUpPage ? (
              // Ugly hack until everything is in the SPA. Linking to "/" here
              // always redirects to sign in because dashboard isn't routed yet.
              <LyssnaLogoIcon w="105px" h="29px" />
            ) : (
              <Button size="sm" variant="ghost" as="a" href="/">
                <LyssnaLogoIcon w="105px" h="29px" />
              </Button>
            )}
            {!isLoggedIn && !isOnSignInPage && (
              <Button
                size="sm"
                variant="ghost"
                as={RoutedLink}
                to={ROUTES.USERS.SIGN_IN.path}
              >
                Sign in
              </Button>
            )}
          </Flex>
        )}
      </Navbar>
      {children && (
        <HStack display={{ md: "none" }} backgroundColor="white">
          {children}
        </HStack>
      )}
      {mobileMenuIsOpen && <MobileMenu isOnDashboard={isOnDashboard} />}
      {currentPlan && showStripeSubscriptionPastDueBanner && (
        <StripeSubscriptionPastDueBanner
          currentUserIsAdmin={currentUser?.role === "admin"}
          planName={currentPlan.name}
        />
      )}
      {showAccountNotInGoodStandingBanner && <AccountNotInGoodStandingBanner />}
    </>
  )
}

// UsabilityHubNavbar currently includes UpgradePlanButton which opens a modal.
// Use this to load the navbar successfully outside the SPA, eg. when mounting it directly with mount_react
export const ModalContextWrappedUsabilityHubNavbar: UsabilityHubNavbar = ({
  isOnDashboard = false,
  isOnSignInPage = false,
  isOnSignUpPage = false,
  children,
}) => {
  return (
    <ModalContextProvider>
      <UsabilityHubNavbar
        isOnDashboard={isOnDashboard}
        isOnSignInPage={isOnSignInPage}
        isOnSignUpPage={isOnSignUpPage}
      >
        {children}
      </UsabilityHubNavbar>
    </ModalContextProvider>
  )
}

const Navbar: React.FC<React.PropsWithChildren<BoxProps>> = ({ children }) => {
  return (
    <Box
      as="nav"
      px={{
        base: 6,
        sm: 8,
        lg: 10,
      }}
      py={0}
      alignItems="center"
      h={HEADER_NAVBAR_HEIGHT}
      bg="white"
      borderBottom="1px solid"
      borderColor="border.default"
    >
      {children}
    </Box>
  )
}
