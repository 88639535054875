import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Select,
  Skeleton,
  Text,
} from "@chakra-ui/react"
import { Button, Heading, IconButton } from "DesignSystem/components"
import { DeleteFilledIcon } from "Icons/DeleteFilledIcon"
import React from "react"
import { useFieldArray } from "react-hook-form"
import { useAdminListDemographicGroups } from "~/api/generated/usabilityhub-components"
import { DemographicAttribute } from "./types"
import { useDemographicForm } from "./useDemographicForm"

type Props = {
  demographicAttribute: DemographicAttribute | null
  onClose: () => void
}

export const EditDemographicForm: React.FC<Props> = ({
  demographicAttribute,
  onClose,
}) => {
  const [
    {
      control,
      register,
      formState: { isSubmitting, errors },
      handleSubmit,
    },
    onSubmit,
  ] = useDemographicForm(demographicAttribute)

  const {
    fields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: "options",
  })

  const { data: demographicGroups, status: groupStatus } =
    useAdminListDemographicGroups({})

  return (
    <form
      onSubmit={handleSubmit(async (args) => {
        try {
          await onSubmit(args)
        } catch (e) {
          console.error(e)
        }
        onClose()
      })}
    >
      <ModalBody>
        <Flex w="full" gap={4}>
          <Flex flexGrow={1} flexBasis={0} direction="column" gap={2}>
            <Flex
              direction="column"
              bg="purple.50"
              borderRadius="md"
              gap={2}
              p={2}
            >
              <Heading as="h3" textStyle="ds.heading.primary">
                Appearance on UserCrowd
              </Heading>

              <FormControl isInvalid={!!errors.question}>
                <FormLabel>
                  Question
                  <Input {...register("question")} />
                </FormLabel>
                <FormErrorMessage>{errors.question?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.profile_helper}>
                <FormLabel>
                  Help text
                  <Input {...register("profile_helper")} />
                </FormLabel>
                <FormErrorMessage>
                  {errors.profile_helper?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex
              direction="column"
              bg="teal.50"
              borderRadius="md"
              gap={2}
              p={2}
            >
              <Heading as="h3" textStyle="ds.heading.primary">
                Appearance on Lyssna
              </Heading>

              <FormControl isInvalid={!!errors.name}>
                <FormLabel>
                  Name
                  <Input {...register("name")} />
                </FormLabel>
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.target_helper}>
                <FormLabel>
                  Help text
                  <Input {...register("target_helper")} />
                </FormLabel>
                <FormErrorMessage>
                  {errors.target_helper?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex
              direction="column"
              bg="gray.50"
              borderRadius="md"
              gap={2}
              p={2}
            >
              <Heading as="h3" textStyle="ds.heading.primary">
                Group and positioning
              </Heading>

              <FormControl isInvalid={!!errors.demographic_attribute_group_id}>
                <FormLabel>
                  Group
                  {groupStatus === "success" ? (
                    <Select {...register("demographic_attribute_group_id")}>
                      <option value="">Select a group</option>

                      {demographicGroups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Skeleton h="40px" />
                  )}
                </FormLabel>
                <FormErrorMessage>
                  {errors.demographic_attribute_group_id?.message}
                </FormErrorMessage>
              </FormControl>

              <Flex justify="space-between" gap={2}>
                <FormControl isInvalid={!!errors.sort_order}>
                  <FormLabel>
                    Group order
                    <Input type="number" {...register("sort_order")} />
                  </FormLabel>
                  <FormErrorMessage>
                    {errors.sort_order?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.individual_sort_order}>
                  <FormLabel>
                    Dashboard priority
                    <Input
                      type="number"
                      {...register("individual_sort_order")}
                    />
                  </FormLabel>
                  <FormErrorMessage>
                    {errors.individual_sort_order?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>

            <Flex
              direction="column"
              bg="gray.50"
              borderRadius="md"
              gap={2}
              p={2}
            >
              <Heading as="h3" textStyle="ds.heading.primary">
                Visibility
              </Heading>

              <FormControl isInvalid={!!errors.visible_to_customers}>
                <Checkbox {...register("visible_to_customers")}>
                  <Text as="span" fontSize="xs" pos="relative" top={-0.5}>
                    Published on Lyssna
                  </Text>
                </Checkbox>
                <FormErrorMessage>
                  {errors.visible_to_customers?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.visible_to_panelists}>
                <Checkbox {...register("visible_to_panelists")}>
                  <Text as="span" fontSize="xs" pos="relative" top={-0.5}>
                    Published on UserCrowd
                  </Text>
                </Checkbox>
                <FormErrorMessage>
                  {errors.visible_to_panelists?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            flexGrow={1}
            flexBasis={0}
            bg="gray.50"
            borderRadius="md"
            p={2}
            gap={2}
          >
            <FormControl isInvalid={!!errors.multi_select}>
              <Flex justify="space-between">
                <Heading as="h3" textStyle="ds.heading.primary">
                  Answers
                </Heading>

                <Checkbox {...register("multi_select")}>
                  <Text as="span" fontSize="xs" pos="relative" top={-0.5}>
                    Multi-select?
                  </Text>
                </Checkbox>
              </Flex>

              <FormErrorMessage>
                {errors.multi_select?.message}
              </FormErrorMessage>
            </FormControl>

            {fields.map((field, index) => {
              return (
                <Flex
                  key={field.id}
                  borderTop={index > 0 ? "1px solid" : undefined}
                  borderColor="gray.200"
                  pt={index > 0 ? 2 : 0}
                >
                  <Flex flexGrow={1} direction="column" gap={1}>
                    <Input
                      placeholder="Question text"
                      flexGrow={1}
                      {...register(`options.${index}.value`)}
                    />
                    <Input
                      placeholder="Profile helper text"
                      flexGrow={1}
                      {...register(`options.${index}.profile_helper`)}
                    />
                    <Flex align="center" flexWrap="wrap" gap={2}>
                      <Checkbox
                        {...register(`options.${index}.is_none_of_above`)}
                      >
                        <Text as="span" fontSize="xs" pos="relative" top={-0.5}>
                          None of the above
                        </Text>
                      </Checkbox>
                      <Checkbox
                        {...register(`options.${index}.is_prefer_not_to_say`)}
                      >
                        <Text as="span" fontSize="xs" pos="relative" top={-0.5}>
                          Prefer not to say
                        </Text>
                      </Checkbox>
                    </Flex>
                  </Flex>
                  <IconButton
                    variant="ghost"
                    flexShrink={0}
                    aria-label="Remove option"
                    icon={<DeleteFilledIcon />}
                    onClick={() => removeOption(index)}
                  />
                </Flex>
              )
            })}

            <Button
              variant="primary"
              onClick={() =>
                appendOption({
                  id: null,
                  value: "",
                  profile_helper: "",
                  is_none_of_above: false,
                  is_prefer_not_to_say: false,
                })
              }
            >
              Add another option
            </Button>
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter gap={2}>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button isLoading={isSubmitting} variant="primary" type="submit">
          {demographicAttribute ? "Save changes" : "Create"}
        </Button>
      </ModalFooter>
    </form>
  )
}
