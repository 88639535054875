import * as Sentry from "@sentry/browser"
import { getSectionTypeRules } from "Constants/test-section-types"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { useSingleShot } from "Shared/hooks/useSingleShot"
import { PermittedDeviceType, UsabilityTestSectionType } from "Types"
import { useMemo } from "react"
import { useSelector } from "react-redux"

/*
 * Returns details related to permitted device types for a test.
 * Reasons for the rules are also returned ([liveWebsiteTestEnabled, screenRecordingEnabled]),
 * so that the UI can display the reasons for the rules.
 *
 * NOTE: This does not yet handle sections with incompatible rules.
 * (We only have one rule so far, so desired behaviour for incompatible rules are not yet clear).
 */
export const usePermittedDeviceTypeRules = () => {
  const sections = useSelector(getSections)
  const singleShot = useSingleShot()

  return useMemo(() => {
    let permittedDeviceType: PermittedDeviceType = "any"
    let screenRecordingEnabled = false
    let liveWebsiteTestEnabled = false
    for (const section of sections) {
      if (!section) {
        singleShot(() => {
          Sentry.captureMessage("Section is not defined", {
            extra: {
              sectionTypes: sections.map((s) => s?.type),
              path: window.location.pathname,
            },
          })
        }, "section-is-not-defined")
        continue
      }

      const rule = getSectionTypeRules(section.type).permittedDeviceType
      if (rule && rule !== "any") {
        permittedDeviceType = rule
      }
      if (section.recording_attributes?.screen) {
        screenRecordingEnabled = true
      }
      if (section.type === UsabilityTestSectionType.LiveWebsiteTest) {
        liveWebsiteTestEnabled = true
      }
      if (
        permittedDeviceType !== "any" &&
        screenRecordingEnabled &&
        liveWebsiteTestEnabled
      ) {
        break
      }
    }
    return {
      permittedDeviceType,
      screenRecordingEnabled,
      liveWebsiteTestEnabled,
    }
  }, [sections])
}
