import { Box, Flex, Icon, Stack, Text } from "@chakra-ui/react"
import { Button, NavigationMenu } from "DesignSystem/components"
import { InterviewCalendarIcon } from "Icons/InterviewCalendarIcon"
import { InterviewEmailsIcon } from "Icons/InterviewEmailsIcon"
import { InterviewLinkIcon } from "Icons/InterviewLinkIcon"
import { InterviewSettingsIcon } from "Icons/InterviewSettingsIcon"
import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { ScreenerIcon } from "Icons/ScreenerIcon"
import { ROUTES } from "UsabilityHub/views/routes"
import { capitalizeFirstLetter, pluralizeWithCount } from "Utilities/string"
import { minutesToHoursAndMinutes } from "Utilities/time"
import { partition, sortBy } from "lodash"
import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { InterviewHostsIcon } from "../../../../../shared/icons/system/InterviewHostsIcon"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import { ModeratedStudySavingIndicator } from "../ModeratedStudySavingIndicator"
import { ModeratedStudySectionStatuses } from "../ModeratedStudySectionStatuses"
import { useStudyDetails } from "./StudyDetailsProvider"

import { DEVICE_TYPE_OPTIONS } from "Shared/constants/testTakingDeviceRequirements"

export const Navigation: React.FC = () => {
  const { separatePages, isLoading } = useStudyDetails()

  if (isLoading) return null

  if (separatePages) return <NewNavigation />

  return (
    <Box my={8} ml={8} w="xs">
      <OldNavigation />
    </Box>
  )
}

const NewNavigation: React.FC = () => {
  const {
    moderatedStudyId,
    availabilityForm,
    deviceRequirementForm,
    meetingUrlForm,
    teamMembersForm,
    isScreenerEnabled,
    screenerQuestions,
    isAnyFormDirty,
    isAnyFormSubmitting,
  } = useStudyDetails()

  const { moderatedStudy, moderatedStudySummary } = useModeratedStudyContext()

  const { startsAt, endsAt, eventDurationMins } = availabilityForm.watch()
  const { device_types } = deviceRequirementForm.watch()
  const { location_type, meeting_url } = meetingUrlForm.watch()
  const { bookingCalendarSummary } = teamMembersForm.watch()

  const [mainHosts, others] = useMemo(
    () => partition(moderatedStudy.members, (m) => m.role === "main_host"),
    [moderatedStudy.members]
  )
  const mainHost = mainHosts[0]
  const hosts = useMemo(
    () => (
      <Flex columnGap="0.5ch" rowGap="0" wrap="wrap" minWidth={0} w="100%">
        {mainHost && (
          <Text
            as="span"
            minWidth={0}
            flex="0 1 auto"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {mainHost.name}
          </Text>
        )}
        {others.length > 0 && (
          <Text as="span">{`+ ${pluralizeWithCount(others.length, "co-host")}`}</Text>
        )}
      </Flex>
    ),
    [mainHost, others]
  )

  const availability = useMemo(
    () =>
      [startsAt, endsAt]
        .filter(Boolean)
        .map((date) =>
          new Date(date).toLocaleDateString(undefined, {
            day: "numeric",
            month: "long",
          })
        )
        .join(" – "),
    [startsAt, endsAt]
  )
  const availabilityError = useMemo(() => {
    if (
      moderatedStudySummary &&
      !moderatedStudySummary.has_available_booking_slots
    ) {
      return (
        <NavigationMenu.Error>
          All available time slots are in the past or booked
        </NavigationMenu.Error>
      )
    }
  }, [moderatedStudySummary])

  const deviceTypesLabel = useMemo(() => {
    if (device_types.length === 0) {
      return "No devices"
    } else if (device_types.length === 1) {
      return `${capitalizeFirstLetter(device_types[0])} only`
    } else if (device_types.length === DEVICE_TYPE_OPTIONS.length) {
      return "Any device"
    } else {
      return sortBy(device_types.map(capitalizeFirstLetter)).join(" or ")
    }
  }, [device_types])

  const meetingLinkLabel = useMemo(() => {
    if (location_type === "none") {
      return "Custom: Add after booking"
    } else if (location_type === "custom") {
      return "Custom: Single link"
    } else if (location_type === "teams") {
      return "Microsoft Teams"
    } else {
      return capitalizeFirstLetter(location_type)
    }
  }, [location_type])
  const meetingLinkError = useMemo(() => {
    if (
      (location_type === "zoom" && !mainHost?.has_zoom_oauth_credentials) ||
      (location_type === "teams" && !mainHost?.has_microsoft_oauth_credentials)
    ) {
      return (
        <NavigationMenu.Error>
          Connect main host{"\u2019"}s account
        </NavigationMenu.Error>
      )
    } else if (location_type === "custom" && !meeting_url) {
      return (
        <NavigationMenu.Error severity="warning">
          No link entered
        </NavigationMenu.Error>
      )
    }
  }, [location_type, meeting_url, mainHost])

  return (
    <NavigationMenu.Root w="xs" top="3rem">
      <NavigationMenu.Link
        exact
        to={ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId })}
        icon={InterviewSettingsIcon}
        title="Study details"
      >
        <Text>{minutesToHoursAndMinutes(eventDurationMins)}</Text>
        <Text>{deviceTypesLabel}</Text>
      </NavigationMenu.Link>
      <NavigationMenu.Link
        to={ROUTES.INTERVIEW.EDIT.SCREENER.buildPath({ moderatedStudyId })}
        icon={ScreenerIcon}
        title="Screener"
      >
        <Text>
          {isScreenerEnabled && screenerQuestions.length > 0
            ? `Enabled, ${pluralizeWithCount(screenerQuestions.length, "question")}`
            : "None"}
        </Text>
      </NavigationMenu.Link>
      <NavigationMenu.Link
        to={ROUTES.INTERVIEW.EDIT.AVAILABILITY.buildPath({
          moderatedStudyId,
        })}
        icon={InterviewHostsIcon}
        title="Hosts and availability"
      >
        {hosts}
        <Text>{availability}</Text>
        {!!availabilityError && availabilityError}
      </NavigationMenu.Link>
      <NavigationMenu.Link
        to={ROUTES.INTERVIEW.EDIT.LINK.buildPath({ moderatedStudyId })}
        icon={InterviewLinkIcon}
        title="Meeting link"
      >
        <Text>{meetingLinkLabel}</Text>
        {!!meetingLinkError && meetingLinkError}
      </NavigationMenu.Link>
      <NavigationMenu.Link
        to={ROUTES.INTERVIEW.EDIT.CALENDAR.buildPath({
          moderatedStudyId,
        })}
        icon={InterviewCalendarIcon}
        title="Event calendar"
      >
        <Text>{bookingCalendarSummary ?? "None selected"}</Text>
      </NavigationMenu.Link>
      <NavigationMenu.Link
        to={ROUTES.INTERVIEW.EDIT.EMAILS.buildPath({ moderatedStudyId })}
        icon={InterviewEmailsIcon}
        title="Automated emails"
      />

      <NavigationMenu.Footer>
        <Stack spacing={2}>
          <ModeratedStudySavingIndicator
            isAnyDirty={isAnyFormDirty}
            isAnySubmitting={isAnyFormSubmitting}
          />
          <Button
            as={Link}
            target="_blank"
            size="emphasized"
            to={ROUTES.INTERVIEW.PREVIEW.APPLY.buildPath({
              moderatedStudyId,
            })}
            rightIcon={<Icon as={LinkExternalIcon} />}
          >
            Preview study
          </Button>

          <Button
            size="emphasized"
            variant="primary"
            as={Link}
            to={ROUTES.INTERVIEW.RECRUIT.buildPath({
              moderatedStudyId,
            })}
            isDisabled={isAnyFormDirty || isAnyFormSubmitting}
          >
            Recruit participants
          </Button>
        </Stack>
      </NavigationMenu.Footer>
    </NavigationMenu.Root>
  )
}

const OldNavigation: React.FC = () => {
  const {
    moderatedStudyId,
    studyDetailsForm,
    meetingUrlForm,
    deviceRequirementForm,
    availabilityForm,
    teamMembersForm,
    combinedScreenerQuestionErrors,
    isAnyFormDirty,
    isAnyFormSubmitting,
  } = useStudyDetails()

  return (
    <Flex direction="column" position="sticky" top={20} gap={2}>
      <ModeratedStudySectionStatuses
        studyDetailsForm={studyDetailsForm}
        meetingUrlForm={meetingUrlForm}
        deviceRequirementForm={deviceRequirementForm}
        availabilityForm={availabilityForm}
        teamMembersForm={teamMembersForm}
        screenerQuestions={combinedScreenerQuestionErrors}
      />

      <Box my={2}>
        <ModeratedStudySavingIndicator
          isAnyDirty={isAnyFormDirty}
          isAnySubmitting={isAnyFormSubmitting}
        />
      </Box>

      <Button
        as={Link}
        target="_blank"
        size="emphasized"
        to={ROUTES.INTERVIEW.PREVIEW.APPLY.buildPath({
          moderatedStudyId,
        })}
      >
        Preview
      </Button>

      <Button
        size="emphasized"
        variant="primary"
        as={Link}
        to={ROUTES.INTERVIEW.RECRUIT.buildPath({
          moderatedStudyId,
        })}
        isDisabled={isAnyFormDirty || isAnyFormSubmitting}
      >
        Next: Recruit
      </Button>
    </Flex>
  )
}
