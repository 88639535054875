import { Link, LinkProps } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { getIsPanelistDomain } from "Shared/helpers/getIsPanelistDomain"
import React from "react"

type Props = React.PropsWithChildren<LinkProps>

export const SupportMailtoLink: React.FC<Props> = ({
  children,
  ...linkProps
}) => {
  const email = getIsPanelistDomain()
    ? Constants.TESTER_SUPPORT_EMAIL_ADDRESS
    : Constants.CUSTOMER_SUPPORT_EMAIL_ADDRESS

  return (
    <Link href={`mailto:${email}`} isExternal {...linkProps}>
      {children ? children : email}
    </Link>
  )
}
