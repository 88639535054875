import { useToast } from "@chakra-ui/react"
import { ROUTES } from "UsabilityHub/views/routes"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  OnboardingStepId,
  OnboardingStepValues,
  getStepByIndex,
} from "./constants"
import { OnboardingApi } from "./useOnboardingApi"
import {
  OnboardingFormWiring,
  useOnboardingFormWiring,
} from "./useOnboardingFormWiring"
import {
  OnboardingSteppingState,
  useOnboardingStepping,
} from "./useOnboardingStepping"
import { useOnboardingValues } from "./useOnboardingValues"
import { useSetRedirect } from "./useSetRedirect"

type Props = {
  api: OnboardingApi
  stepIdParam?: OnboardingStepId
}

type UseOnboardingReturn<ID extends OnboardingStepId> = {
  api: OnboardingApi
  steps: OnboardingSteppingState<ID>
  wiring: OnboardingFormWiring<ID>
  values: OnboardingStepValues<ID> // Form is reactive to this (load data into here).
  submit: (data: OnboardingStepValues<ID>) => Promise<void>
}

/**
 * Orchestrates the onboarding flow.
 *
 * Responsible for:
 * - Effects that we don't want in hooks that it orchestrates, e.g. navigation.
 * - Actions composed of multiple hooks, e.g. when a form is submitted.
 * - Creating the `wiring` that is used to allow interaction between components.
 */
export const useOnboarding = <ID extends OnboardingStepId>({
  api,
  stepIdParam,
}: Props): UseOnboardingReturn<ID> => {
  const navigate = useNavigate()
  const toast = useToast()
  const save = api.save({
    onError: (error) => {
      toast({
        title: "Error",
        description: error.payload.message,
        status: "error",
      })
    },
  })

  const [redirect, setRedirect] = useSetRedirect()
  const onComplete = async () => {
    await save({ completedOnboarding: true })
    setRedirect(null)
    navigate(redirect || ROUTES.DASHBOARD.buildPath({}), {
      state: { onboardingComplete: true },
    })
  }
  const steps = useOnboardingStepping<ID>({ stepIdParam, onComplete })
  const { values, mergeValues } = useOnboardingValues(steps.id, api)
  const wiring = useOnboardingFormWiring(values)

  useEffect(() => {
    if (steps.id === (stepIdParam ?? getStepByIndex(1).id)) {
      return
    }
    navigate(ROUTES.SETUP.ONBOARDING.buildPath({ stepId: steps.id }), {
      replace: true,
    })
  }, [steps.id])

  const submit = async (data: OnboardingStepValues<ID>) => {
    await save(data)
    mergeValues(data)
    steps.next()
  }

  return {
    api,
    steps,
    values,
    wiring,
    submit,
  }
}
