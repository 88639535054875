import { trackSubscriptionCreated } from "JavaScripts/analytics/track"
import { isBadRequestError } from "Services/axios"
import { delay } from "Utilities/promise"
import { BaseError } from "make-error"
import {
  fetchCreateSubscription,
  fetchGetSubscription,
  fetchUpdateSubscription,
} from "~/api/generated/usabilityhub-components"
import { Plan, Subscription } from "~/api/generated/usabilityhubSchemas"
import { throwIfDeclinedErrorMessage } from "./stripe"

interface SubscribeOptions {
  plan: Plan
}

interface UpdateSubscriptionOptions {
  subscriptionGuid: Subscription["guid"]
  planUniqueId: string
}

export class SubscriptionError extends BaseError {}

function subscriptionError(
  guid: string | null,
  message?: string | null
): never {
  throw new SubscriptionError(
    `Sorry, something went wrong${message ? `: ${message}` : ""}. ` +
      `Please contact support${
        guid === null ? "" : ` and give them transaction ID: ${guid}`
      }.`
  )
}

export async function createSubscription({
  plan,
}: SubscribeOptions): Promise<void> {
  const MaxRetries = 60
  const RetryDelay = 500

  const payload = { plan_unique_id: plan.unique_id }

  // Create a subscription, and poll for its status
  // Return a promise that the caller can chain onto to handle success and various errors
  const { guid } = await fetchCreateSubscription({
    body: {
      trialing: false,
      ...payload,
    },
  })
  if (guid === null) {
    subscriptionError(guid)
  }

  for (let i = 0; i < MaxRetries; i++) {
    const { stripe_subscription } = await fetchGetSubscription({
      pathParams: { guid: guid },
    })
    switch (stripe_subscription.state) {
      case "active":
        trackSubscriptionCreated(plan)
        return
      case "errored":
        throwIfDeclinedErrorMessage(stripe_subscription.error)
        subscriptionError(guid, stripe_subscription.error)
        // NOTE: fallthrough error doesn't realize that the above line throws.
        //ts-ignore it's easier to make ts ignore unreachable code than biome to ignore this falls through
        break
      default:
        await delay(RetryDelay)
    }
  }

  // Show an error if we don't succeed after MaxRetries
  return subscriptionError(guid)
}

export async function updateSubscription({
  subscriptionGuid,
  planUniqueId,
}: UpdateSubscriptionOptions): Promise<void> {
  try {
    await fetchUpdateSubscription({
      pathParams: {
        guid: subscriptionGuid,
      },
      body: {
        plan_unique_id: planUniqueId,
        resume_subscription: false,
      },
    })
  } catch (error) {
    if (isBadRequestError(error)) {
      return subscriptionError(subscriptionGuid, error.response.data.message)
    } else {
      throw error
    }
  }
}

export async function createTrialSubscription({
  plan,
}: SubscribeOptions): Promise<void> {
  const MaxRetries = 60
  const RetryDelay = 500

  const payload = { plan_unique_id: plan.unique_id, trialing: true }

  // Create a subscription, and poll for its status
  // Return a promise that the caller can chain onto to handle success and various errors
  const { guid } = await fetchCreateSubscription({
    body: payload,
  })
  if (guid === null) {
    subscriptionError(guid)
  }

  for (let i = 0; i < MaxRetries; i++) {
    const data = await fetchGetSubscription({
      pathParams: { guid: guid },
    })

    switch (data.stripe_subscription.state) {
      case "active":
        // TODO: Track the events similar to trackSubscriptionCreated(plan)
        // when it's decided that we want to track trial subscriptions.
        return
      case "errored":
        throwIfDeclinedErrorMessage(data.stripe_subscription.error)
        subscriptionError(guid, data.stripe_subscription.error)
        // NOTE: fallthrough error doesn't realize that the above line throws.
        //ts-ignore it's easier to make ts ignore unreachable code than biome to ignore this falls through
        break
      default:
        await delay(RetryDelay)
    }
  }

  // Show an error if we don't succeed after MaxRetries
  return subscriptionError(guid)
}
