import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { Resolver, useForm } from "react-hook-form"
import { OnboardingStepId, OnboardingStepValues, getStep } from "./constants"
import { OnboardingFormWiring } from "./useOnboardingFormWiring"

type Options<ID extends OnboardingStepId> = {
  id: ID
  values: OnboardingStepValues<ID>
  wiring: OnboardingFormWiring<ID>
}

export function useOnboardingForm<ID extends OnboardingStepId>(
  options: Options<ID>
) {
  const { id, values, wiring } = options
  const { setWatchedValues, setIsValid } = wiring
  const { schema } = getStep<ID>(id)
  const form = useForm<OnboardingStepValues<ID>>({
    mode: "onTouched",
    values,
    resolver: yupResolver(schema) as unknown as Resolver<
      OnboardingStepValues<ID>
    >,
  })

  useEffect(() => {
    setIsValid(form.formState.isValid)
  }, [form.formState])

  useEffect(() => {
    const { unsubscribe } = form.watch(() => setWatchedValues(form.getValues()))
    return () => unsubscribe()
  }, [])

  return form
}
