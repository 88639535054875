import { Heading, Stack, Text } from "@chakra-ui/react"
import React, { PropsWithChildren, useEffect } from "react"
import { useRouteError } from "react-router"

import { SupportMailtoLink } from "Shared/components/Links/SupportMailtoLink"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { reportErrorToSentry } from "~/application/javascripts/utilities/error"

type ErrorBoundaryState = { hasError: boolean }

// ErrorBoundaries have to be class components
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
class ErrorBoundary extends React.Component<
  PropsWithChildren,
  ErrorBoundaryState
> {
  constructor(props: PropsWithChildren) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    reportErrorToSentry(error)
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return <>{this.props.children}</>
  }
}

export const CatchRouterError: React.FC<PropsWithChildren> = ({ children }) => {
  const error = useRouteError() as Error

  useEffect(() => {
    reportErrorToSentry(error)
  }, [error])

  return (
    <Page noBasicFlash title="Error">
      {children && <ErrorBoundary>{children}</ErrorBoundary>}
      <PageMain>
        <PageContent
          maxW="8xl"
          display="grid"
          placeContent="center"
          style={{ minHeight: "75vh" }}
        >
          {error && (
            <Stack spacing={6} textAlign="center">
              <Heading>Something went wrong</Heading>
              <Text>
                <SupportMailtoLink>Send us an email</SupportMailtoLink> if you
                {"\u2019"}re having trouble and we{"\u2019"}ll help out.
              </Text>
            </Stack>
          )}
        </PageContent>
      </PageMain>
    </Page>
  )
}
