import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import ResponseSection from "./response-section"

export const TEST_RECORDING_TYPES: RecordingType[] = [
  "screen",
  "microphone",
  "camera",
]

export type RawResponseSectionRecording = {
  id: string
  response_id: number
  response_section_id: number
  filename: string
  duration: number
  types: RecordingType[]
} & (
  | {
      url: null
      status: "unprocessed" | "processing" | "failed"
    }
  | {
      url: string
      status: "processed"
    }
)

export type ResponseSectionRecording = RawResponseSectionRecording & {
  responseSection: ResponseSection
  isAudio: boolean
}

export type ResponseSectionLiveWebsiteTestTaskRecording = {
  response_section_live_website_test_task_id: string
  live_website_test_task_id: string
  isAudio: boolean
} & Omit<RawResponseSectionRecording, "response_section_id">
