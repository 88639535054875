import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { useTranslate } from "Shared/hooks/useTranslate"
import { throttle } from "lodash"
import React, { useEffect } from "react"
import cardSortInstructionsDesktop from "./images/cardSortInstructionsDesktop.svg"
import cardSortInstructionsMobile from "./images/cardSortInstructionsMobile.svg"

interface Props {
  taskInstructions: string
  testInformation: string
  onStart: () => void
}

export const CardSortInstructions: React.FC<Props> = ({
  taskInstructions,
  testInformation,
  onStart,
}) => {
  const translate = useTranslate()

  // Using clientHeight rather than something like 100vw sp we can handle mobile address bar etc
  const [height, setHeight] = React.useState(
    document.documentElement.clientHeight
  )
  useEffect(() => {
    const handleResize = throttle(
      () => setHeight(document.documentElement.clientHeight),
      100
    )

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <Box w="100%" h={[`${height}px`, "100%"]} overflowY="auto" p={4}>
      <Flex
        minH="100%"
        h="fit-content"
        gap={[2, null, 20]}
        alignItems="center"
        direction={["column", null, "row"]}
        justifyContent={["unset", null, "center"]}
      >
        <Image
          display={["none", "block"]}
          w={437}
          h={389}
          src={cardSortInstructionsDesktop}
        />

        <Image
          display={["block", "none"]}
          w={340}
          h={116}
          src={cardSortInstructionsMobile}
          mx="auto"
        />

        <Stack spacing={4} maxW="550px" p={4}>
          {taskInstructions && (
            <Text textStyle="ds.heading.primary" color="ds.text.default">
              <DisplayBlockMarkdownText text={taskInstructions} />
            </Text>
          )}

          <Text textStyle="ds.paragraph.primary" color="ds.text.default">
            <DisplayBlockMarkdownText text={testInformation} />
          </Text>

          <ThemedButton w="fit-content" onClick={onStart}>
            {translate("test.card_sort.start")}
          </ThemedButton>
        </Stack>
      </Flex>
    </Box>
  )
}
