import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react"
import React from "react"

import styled from "@emotion/styled"
import { Badge } from "DesignSystem/components"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { highlightSearchTerm } from "./highlightSearchTerm"

interface ChildProps {
  isExpanded: boolean
  isDisabled: boolean
}

type Props = {
  demographicAttributeId?: number
  name: string
  badgeText?: string
  visible: boolean
  textToHighlight: string | null
  lockedOpen: boolean
  allowOverflow?: boolean
  tooltipContent?: string | null
  children: React.ReactNode | ((p: ChildProps) => React.ReactNode)
}

// We want borders between accordion items, but not between the first item and the header
const AccordionItemWithOverflowInitial = styled(AccordionItem)`
  /* This is necessary to get the loction menu to "pop out" of the accordion
     but it makes things look weird when animating open and closed — maybe there's a better way? */
  .chakra-collapse {
    overflow: initial !important;
  }
`

export const DemographicAttributeSection = React.forwardRef<
  HTMLButtonElement,
  Props
>(
  (
    {
      demographicAttributeId,
      name,
      badgeText,
      children,
      visible,
      textToHighlight,
      tooltipContent,
      allowOverflow = false,
    },
    ref
  ) => {
    if (!visible) return null

    return (
      <AccordionItemWithOverflowInitial
        key={demographicAttributeId}
        borderWidth="1px"
        borderColor="ds.border.default"
        data-ui="accordion-item"
        _focusWithin={{ boxShadow: "var(--chakra-shadows-outline)" }}
        style={
          allowOverflow
            ? {
                zIndex: 1,
                position: "relative",
              }
            : undefined
        }
      >
        {({ isExpanded, isDisabled }) => (
          <>
            <AccordionButton
              ref={ref}
              _hover={{}}
              _focus={{ boxShadow: "none" }}
              p={4}
            >
              <Box
                id={`attribute_${name.toLowerCase().replace(" ", "_")}`}
                flex="1"
                textAlign="left"
                textStyle="ds.heading.primary"
              >
                {textToHighlight
                  ? highlightSearchTerm(name, textToHighlight)
                  : name}{" "}
                {tooltipContent && <HelpPopover>{tooltipContent}</HelpPopover>}
              </Box>
              {badgeText ? (
                <Badge colorScheme="default" label={badgeText} />
              ) : null}
              <AccordionIcon
                as={ChevronDownIcon}
                color="ds.icon.subtle"
                boxSize={6}
              />
            </AccordionButton>
            <AccordionPanel>
              {typeof children === "function"
                ? children({ isExpanded, isDisabled })
                : children}
            </AccordionPanel>
          </>
        )}
      </AccordionItemWithOverflowInitial>
    )
  }
)

DemographicAttributeSection.displayName = "DemographicAttributeSection"
