import { Box, Flex, Text } from "@chakra-ui/react"
import { DemographicsIcon } from "Icons/DemographicsIcon"
import { LanguagesIcon } from "Icons/LanguagesIcon"
import { TagIcon } from "Icons/TagIcon"
import { TimeIcon } from "Icons/TimeIcon"
import { UsersIcon } from "Icons/UsersIcon"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import React, { FC } from "react"
import { useGetExperiments } from "~/api/generated/usabilityhub-components"

export const PanelRecruitmentSummary: FC = () => {
  const { isLoading, data: experiments } = useGetExperiments({})

  const newSummary = experiments?.panel_order_discount === "banner"

  if (isLoading) {
    return null
  }

  return (
    <Flex sx={cqContainer()} flexGrow="1">
      {newSummary ? (
        <Box
          display="grid"
          flexGrow="1"
          alignItems="center"
          columnGap={6}
          rowGap={3}
          sx={cq("32rem", { gridTemplateColumns: "auto auto" })}
        >
          <Flex gap={2}>
            <UsersIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized">
              Over 690,000 panelists
            </Text>
          </Flex>
          <Flex gap={2}>
            <DemographicsIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized" mr={4}>
              124 countries
            </Text>
          </Flex>
          <Flex gap={2}>
            <LanguagesIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized" mr={4}>
              55 languages
            </Text>
          </Flex>
          <Flex gap={2}>
            <TagIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized" mr={4}>
              395+ attributes
            </Text>
          </Flex>
          <Flex gap={2}>
            <TimeIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized" mr={4}>
              ~30 min average turnaround
            </Text>
          </Flex>
        </Box>
      ) : (
        <Text textStyle="ds.paragraph.emphasized">
          Access our global panel of 690k+ participants.
        </Text>
      )}
    </Flex>
  )
}
