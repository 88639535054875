import { ScreenerResponse } from "Redux/reducers/test-results/reducer"
import { ResponseAnswer } from "Types"
import { groupBy } from "lodash"

export const convertScreenerResponsesToAnswers = (
  screenerResponses: readonly ScreenerResponse[],
  screenerQuestionId: string
): ResponseAnswer[] => {
  const selectedOptions = screenerResponses.flatMap((screenerResponse) =>
    screenerResponse.screener_question_selected_options.map((option) => ({
      ...option,
      response_id: screenerResponse.response_id,
    }))
  )

  const optionsForQuestion = selectedOptions.filter(
    (screenerQuestionSelectedOption) =>
      screenerQuestionSelectedOption.screener_question_id === screenerQuestionId
  )

  const groupedByResponseId = groupBy(optionsForQuestion, "response_id")

  return (
    Object.entries(groupedByResponseId).map(([responseId, options]) => ({
      id: -1,
      response_id: Number(responseId),
      usability_test_section_question_id: -1,
      answer: options[0].answer,
      answers: options.map((option) => option.answer),
      duration_ms: null,
      answer_tags: [],
      paste_detected: false,
    })) ?? []
  )
}
