import { Tag } from "DesignSystem/components"
import { CursorFilledIcon } from "Icons/CursorFilledIcon"
import { ExternalStudyIcon } from "Icons/ExternalStudyIcon"
import { UsersFilledIcon } from "Icons/UsersFilledIcon"
import React from "react"

export const UsabilityTestTag: React.FC = () => (
  <Tag label="Test" leftIcon={CursorFilledIcon} colorScheme="purple" />
)

export const ExternalStudyTag: React.FC = () => (
  <Tag label="External study" leftIcon={ExternalStudyIcon} colorScheme="teal" />
)

export const ModeratedStudyTag: React.FC = () => (
  <Tag label="Interview" leftIcon={UsersFilledIcon} colorScheme="cyan" />
)
