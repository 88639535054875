import { ButtonProps, IconButton } from "DesignSystem/components"
import { FilterFilledIcon } from "Icons/FilterFilledIcon"
import { FilterIcon } from "Icons/FilterIcon"
import React, { forwardRef } from "react"

import { Omit } from "Types"

type BaseProps = {
  value: boolean
  onChange: (isEnabled: boolean) => void
}

export type Props = Omit<ButtonProps, keyof BaseProps> & BaseProps

export const FilterToggle = forwardRef<HTMLButtonElement, Props>(
  ({ id, value, onChange, ...rest }, ref) => (
    <IconButton
      ref={ref}
      aria-label="Filter"
      icon={value ? <FilterFilledIcon /> : <FilterIcon />}
      isSelected={value}
      data-qa="filter-toggle-checkbox"
      onClick={() => onChange(!value)}
      {...rest}
    />
  )
)

FilterToggle.displayName = "FilterToggle"

type FilterButtonProps = {
  readonly isEnabled: boolean
} & ButtonProps

export const FilterButton: React.FC<FilterButtonProps> = forwardRef(
  ({ isEnabled, ...rest }, ref: React.Ref<HTMLButtonElement>) => (
    <IconButton
      ref={ref}
      aria-label="Filter"
      icon={isEnabled ? <FilterFilledIcon /> : <FilterIcon />}
      data-qa="filter-toggle-button"
      isSelected={isEnabled}
      {...rest}
    />
  )
)

FilterButton.displayName = "FilterButton"
