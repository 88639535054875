import {
  Box,
  Stack,
  StackProps,
  Tag,
  TagCloseButton,
  Text,
} from "@chakra-ui/react"
import { UploadIcon } from "Icons/UploadIcon"
import { uniqueId } from "lodash"
import React, { ComponentProps, useMemo, useState } from "react"

type AcceptList = ComponentProps<"input">["accept"]

type FileChooserProps = Omit<StackProps, "onChange"> & {
  file: File | null
  accept: AcceptList
  onChange: (file: File | null) => void
}

export const FileChooser: React.FC<FileChooserProps> = ({
  id,
  file,
  accept,
  onChange,
  children,
  sx = {},
  ...props
}) => {
  const container = React.useRef<HTMLDivElement>(null)

  const [draggingOver, setDraggingOver] = useState(false)

  const inputId = useMemo(() => id || uniqueId("file-chooser"), [id])

  const fileSize = useMemo(() => {
    if (!file) return null

    const size = file.size
    if (size > 1048576) {
      return `${(size / 1048576).toFixed(2)}MB`
    } else if (size > 1024 * 50) {
      return `${Math.round(size / 1024)}KB`
    } else {
      return `${(size / 1024).toFixed(1)}KB`
    }
  }, [file])

  const isAcceptable = (file: File | DataTransferItem) =>
    !accept || accept.includes(file.type)

  const dragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const { items } = e.dataTransfer
    if (
      items.length === 1 &&
      items[0].kind === "file" &&
      isAcceptable(items[0])
    ) {
      e.dataTransfer.dropEffect = "link"
    } else {
      e.dataTransfer.dropEffect = "none"
    }
  }

  const drop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    dragOver(e)

    if (e.dataTransfer.dropEffect === "link") {
      onChange(e.dataTransfer.files[0])
    }
  }

  if (file) {
    return (
      <Tag size="lg" py={2} px={4} width="100%">
        <Text as="span" fontSize="md" lineHeight={1.5} flex={1}>
          {`${file.name} (${fileSize})`}
        </Text>
        <TagCloseButton onClick={() => onChange(null)} />
      </Tag>
    )
  }

  return (
    <Stack
      ref={container}
      alignItems="center"
      textAlign="center"
      pos="relative"
      p={8}
      spacing={2}
      border="2px dashed transparent"
      borderColor="border.default"
      rounded={6}
      data-dragging-over={draggingOver || undefined}
      onDragOver={dragOver}
      onDrop={drop}
      onDragEnterCapture={() => {
        setDraggingOver(true)
      }}
      onDragLeave={(e) => {
        if (!container.current?.contains(e.relatedTarget as Node)) {
          setDraggingOver(false)
        }
      }}
      sx={{
        ...sx,
        "&[data-dragging-over]": {
          bg: "gray.50",
        },
      }}
      {...props}
    >
      <UploadIcon color="icon.subtle" boxSize="4.5rem" />
      <Text fontWeight="medium" fontSize="md">
        Drag and drop file here
      </Text>
      <Text>
        or{" "}
        <Text
          as="label"
          htmlFor={inputId}
          color="text.link.default"
          fontWeight="medium"
          cursor="pointer"
        >
          choose file
        </Text>
      </Text>
      <Box
        as="input"
        id={inputId}
        type="file"
        accept={accept}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.currentTarget.files?.[0] || null)
        }
        pos="absolute"
        top={0}
        left={0}
        opacity={0}
        pointerEvents="none"
      />
      {children}
    </Stack>
  )
}
