import { Divider, Text } from "@chakra-ui/react"
import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  UsabilityTestSectionQuestion as Question,
  ResponseAnswer,
  UsabilityTestSection,
} from "Types"
import UsabilityTestSectionQuestion from "UsabilityHub/components/UsabilityTestSectionQuestion/UsabilityTestSectionQuestion"
import React from "react"
import {
  InstructionsContainer,
  SystemInstructions,
  TaskInstructions,
} from "./Instructions"
import { PrototypeTaskPhase } from "./usePrototypeTaskPhases"

interface ContentProps {
  prototypeTaskPhase: PrototypeTaskPhase
  prototypeType: PrototypeType
  question?: Question
  taskInstructions: string
  section: UsabilityTestSection
  handleQuestionSubmit: (
    answer: Omit<ResponseAnswer, "id" | "response_id">
  ) => void
}

export const TaskFlowOrFreeRoamInstructionsContent: React.FC<
  React.PropsWithChildren<ContentProps>
> = ({
  prototypeTaskPhase,
  prototypeType,
  question,
  taskInstructions,
  section,
  handleQuestionSubmit,
}) => {
  const translate = useTranslate()

  const systemInstructionsTaskFlow =
    section.test_instructions ??
    translate(
      "test.instructions.prototype_task.default_system_instructions.task_flow"
    )

  const systemInstructionsFreeRoam =
    section.test_instructions ??
    translate(
      "test.instructions.prototype_task.default_system_instructions.free_roam"
    )

  if (prototypeType === "task_flow") {
    if (prototypeTaskPhase === "interacting") {
      return (
        <InstructionsContainer>
          <TaskInstructions hideHeader>{taskInstructions}</TaskInstructions>
          <Divider />
          <SystemInstructions>{systemInstructionsTaskFlow}</SystemInstructions>
        </InstructionsContainer>
      )
    } else if (prototypeTaskPhase === "success_screen") {
      return (
        <InstructionsContainer>
          <Text textStyle="ds.heading.primary" color="ds.text.default">
            {translate("test.instructions.prototype_task.test_success_primary")}
          </Text>

          <Text textStyle="ds.heading.primary" color="ds.text.default">
            {translate(
              "test.instructions.prototype_task.test_success_secondary"
            )}
          </Text>
        </InstructionsContainer>
      )
    } else if (prototypeTaskPhase === "answering_questions" && question) {
      return (
        <UsabilityTestSectionQuestion
          section={section}
          key={question.id}
          question={question}
          onAnswerSubmit={handleQuestionSubmit}
          willShowInInstructionsModal
        />
      )
    }
  } else if (prototypeType === "free_roam") {
    if (prototypeTaskPhase === "interacting") {
      return (
        <InstructionsContainer>
          <TaskInstructions hideHeader>{taskInstructions}</TaskInstructions>
          <Divider />
          <SystemInstructions>{systemInstructionsFreeRoam}</SystemInstructions>
        </InstructionsContainer>
      )
    } else if (prototypeTaskPhase === "answering_questions" && question) {
      return (
        <UsabilityTestSectionQuestion
          section={section}
          key={question.id}
          question={question}
          onAnswerSubmit={handleQuestionSubmit}
          willShowInInstructionsModal
        />
      )
    }
  }
  return null
}
