import { MutateOptions } from "@tanstack/react-query"
import { camelCase, isEmpty, mapKeys, merge, omit, snakeCase } from "lodash"
import {
  InviteTeamMemberError,
  InviteTeamMemberVariables,
  UpdateOnboardingError,
  UpdateOnboardingVariables,
  useGetOnboarding,
  useInviteTeamMember,
  useUpdateOnboarding,
} from "~/api/generated/usabilityhub-components"
import { OnboardingData, OnboardingStepValues } from "./constants"

type SaveOptions = MutateOptions<
  void,
  UpdateOnboardingError,
  UpdateOnboardingVariables
>
type SaveOnboarding = (
  data: Partial<OnboardingStepValues> & { completedOnboarding?: boolean },
  options?: SaveOptions
) => Promise<void>
type FetchOnboarding = () => Promise<Partial<OnboardingData>>
type InviteOptions = MutateOptions<
  void,
  InviteTeamMemberError,
  InviteTeamMemberVariables
>

export type OnboardingApi = {
  save: (options?: SaveOptions) => SaveOnboarding
  fetch: FetchOnboarding
  inviteTeamMember: (
    data: InviteTeamMemberVariables["body"],
    options?: InviteOptions
  ) => Promise<void>
  isSaving: boolean
  isLoading: boolean
  isFetched: boolean
}

export const useOnboardingApi = (): OnboardingApi => {
  const { mutateAsync, isLoading: isSavingData } = useUpdateOnboarding()
  const { refetch, isFetched, isLoading } = useGetOnboarding(
    {},
    { enabled: false, retry: false }
  )

  const save: (options: SaveOptions) => SaveOnboarding =
    (defaults) => async (data, options) => {
      // Backend ignores teamMembers. No need to send an API request.
      data = omit(data, "teamMembers")
      if (isEmpty(data)) {
        return
      }
      const opts = merge({}, defaults, options)
      try {
        await mutateAsync(
          {
            body: mapKeys(data, (_, k) => snakeCase(k)),
          },
          opts
        )
      } catch (error) {
        if (!opts.onError) {
          throw error
        }
      }
    }

  const fetch: FetchOnboarding = async () => {
    const { data } = await refetch()
    return mapKeys(data as Record<string, unknown>, (_, k) =>
      camelCase(k)
    ) as OnboardingData
  }

  const { mutateAsync: mutateInvite, isLoading: isInviting } =
    useInviteTeamMember()

  const inviteTeamMember = (data: InviteTeamMemberVariables["body"]) =>
    mutateInvite({ body: data })

  const isSaving = isSavingData || isInviting

  return { save, fetch, inviteTeamMember, isSaving, isLoading, isFetched }
}
