import { Box, Image, Stack, Text } from "@chakra-ui/react"
import React, { ReactNode } from "react"

type NoResultsProps = {
  title?: string
  image?: string
  description?: string
  action?: ReactNode
}

export const NoResults: React.FC<NoResultsProps> = ({
  title,
  description,
  image,
  action,
}) => {
  return (
    <Stack
      align="center"
      justify="center"
      minH="20rem"
      p={10}
      my={3}
      gap={6}
      bgColor="ds.background.neutral.resting"
      rounded={16}
    >
      {image && <Image src={image} alt="No results" maxH="6.5rem" />}
      {title && (
        <Text as="div" textStyle="ds.display.secondary">
          {title}
        </Text>
      )}
      {description && (
        <Text as="div" textStyle="ds.text.primary">
          {description}
        </Text>
      )}
      {action && <Box>{action}</Box>}
    </Stack>
  )
}
