import { Box, IconButton, ListItem, ListItemProps } from "@chakra-ui/react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import React from "react"

import { DragHandleIcon } from "Icons/DragHandleIcon"

interface SortableScreenerQuestionOptionProps extends ListItemProps {
  id: string
  readOnly: boolean
}

export function SortableScreenerQuestionOption({
  id,
  readOnly,
  children,
  ...props
}: SortableScreenerQuestionOptionProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled: readOnly })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  }

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      display="flex"
      gap={2}
      alignItems="center"
      {...props}
    >
      <IconButton
        {...listeners}
        {...attributes}
        variant="ghost"
        size="sm"
        minW="auto"
        aria-label="drag option"
        icon={<DragHandleIcon w={5} h={5} />}
        _hover={{ cursor: "grab" }}
      />
      <Box flexGrow={1}>{children}</Box>
    </ListItem>
  )
}
