import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import {
  ColumnFiltersState,
  Header,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowDownIcon } from "Icons/ArrowDownIcon"
import { ArrowUpIcon } from "Icons/ArrowUpIcon"
import {
  ParticipantInfoDrawer,
  useParticipantInfoDrawerContext,
} from "UsabilityHub/views/ModeratedStudy/interviewer/ParticipantInfoDrawer/ParticipantInfoDrawer"
import React, { useMemo } from "react"
import { useState } from "react"
import { GetModeratedStudyApplicationsResponse } from "~/api/generated/usabilityhub-components"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"
import {
  applicationsColumns,
  getScreenerQuestionsColumns,
} from "./ApplicationsColumns"
import { SelectedApplicationsFloatingActionsBar } from "./SelectedApplicationsFloatingActions"
import { ModeratedStudyApplication } from "./types"

interface ApplicationsTableProps {
  data: Pick<
    GetModeratedStudyApplicationsResponse,
    "applications" | "screener_questions"
  >
}

export function ApplicationsTable({ data }: ApplicationsTableProps) {
  const applications = data?.applications ?? []
  const screenerQuestions = data?.screener_questions as ScreenerQuestion[]

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "created_at",
      desc: false,
    },
  ])
  const [globalFilter] = useState("")
  const [columnFilters] = React.useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({})

  const { openParticipantInfoDrawer } = useParticipantInfoDrawerContext()

  const columns = useMemo(() => {
    if (!screenerQuestions) return []
    return [
      ...applicationsColumns,
      ...getScreenerQuestionsColumns(screenerQuestions),
    ]
  }, [screenerQuestions])

  const table = useReactTable({
    data: applications,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableSortingRemoval: false,
    state: {
      sorting,
      globalFilter,
      columnFilters,
      columnVisibility,
    },
  })

  const someRowsSelected =
    table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()

  const selectedApplications = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original)

  const currentSource = selectedApplications[0]?.source

  const sortedRowIds = useMemo(() => {
    return table.getSortedRowModel().rows.map((r) => r.original.id)
  }, [table.getSortedRowModel()])

  return (
    <Box w="full" display="flex" flexDirection="column" gap="2">
      <Box width="full">
        <TableContainer isolation="isolate">
          <Table layout="fixed">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index, allHeaders) => (
                    <TableHeader
                      key={header.id}
                      header={header}
                      index={index}
                      allHeaders={allHeaders}
                    />
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row, rowIndex, allRows) => (
                <TableRow
                  key={row.id}
                  row={row}
                  rowIndex={rowIndex}
                  allRows={allRows}
                  openParticipantInfoDrawer={openParticipantInfoDrawer}
                  currentSource={currentSource}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {someRowsSelected && (
        <SelectedApplicationsFloatingActionsBar
          selectedApplications={selectedApplications}
          onResetSelections={() => table.resetRowSelection()}
          source={currentSource}
        />
      )}
      <ParticipantInfoDrawer currentlyVisibleApplicationIds={sortedRowIds} />
    </Box>
  )
}

type TableHeaderProps = {
  header: Header<ModeratedStudyApplication, unknown>
  index: number
  allHeaders: Header<ModeratedStudyApplication, unknown>[]
}

const TableHeader: React.FC<TableHeaderProps> = ({
  header,
  index,
  allHeaders,
}) => {
  return (
    <Th
      key={header.id}
      onClick={header.column.getToggleSortingHandler()}
      userSelect="none"
      width={`${header.getSize()}px`}
      px="3"
      py="0"
      h="44px"
      borderColor="gray.200"
      borderWidth={1}
      borderLeftWidth={index === 0 ? 0 : 1}
      borderRightWidth={index === allHeaders.length - 1 ? 0 : 1}
      borderTopWidth={0}
      textTransform="none"
      textColor="gray.500"
      fontSize="sm"
      fontWeight="medium"
      letterSpacing="normal"
      _hover={header.column.getCanSort() ? { cursor: "pointer" } : {}}
    >
      <Flex
        justifyContent={index === 0 ? "center" : "space-between"}
        alignItems="center"
      >
        {header.id === "select" || header.id.match(/^q:/) ? (
          // Don't try to wrap the checkbox field or Screeners in a <p> or the DOM will be sad
          flexRender(header.column.columnDef.header, header.getContext())
        ) : (
          <Text isTruncated>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </Text>
        )}
        <div>
          {header.column.getIsSorted() ? (
            header.column.getIsSorted() === "desc" ? (
              <ArrowDownIcon aria-label="sorted descending" boxSize={4} />
            ) : (
              <ArrowUpIcon aria-label="sorted ascending" boxSize={4} />
            )
          ) : null}
        </div>
      </Flex>
    </Th>
  )
}

type TableRowProps = {
  row: Row<ModeratedStudyApplication>
  rowIndex: number
  allRows: Row<ModeratedStudyApplication>[]
  currentSource: ModeratedStudyApplication["source"] | undefined
  openParticipantInfoDrawer: (id: string) => void
}

const TableRow: React.FC<TableRowProps> = ({
  row,
  rowIndex,
  allRows,
  currentSource,
  openParticipantInfoDrawer,
}) => {
  const handleRowClick = () => {
    openParticipantInfoDrawer(row.original.id)
  }

  const isSelectDisabled =
    !!currentSource && row.original.source.id !== currentSource.id

  return (
    <Tr key={row.id} _hover={{ bgColor: "blackAlpha.100" }}>
      {row.getVisibleCells().map((cell, index, allCells) => (
        <Td
          key={cell.id}
          width={`${cell.column.getSize()}px`}
          py="0"
          // for checkboxes, rely on text-align to center
          px={index > 0 ? "3" : 0}
          textAlign={index === 0 ? "center" : "start"}
          // nudge checkboxes into vertical center
          transform={index === 0 ? "translateY(3px)" : undefined}
          h="44px"
          borderColor="gray.200"
          borderWidth={1}
          borderLeftWidth={index === 0 ? 0 : 1}
          borderRightWidth={index === allCells.length - 1 ? 0 : 1}
          borderBottomWidth={rowIndex === allRows.length - 1 ? 0 : 1}
          opacity={isSelectDisabled ? 0.3 : 1}
          // don't add onClick to the leftmost checkbox column
          onClick={index > 0 ? handleRowClick : undefined}
          _hover={index > 0 ? { cursor: "pointer" } : {}}
        >
          {flexRender(cell.column.columnDef.cell, {
            ...cell.getContext(),
            currentSource,
          })}
        </Td>
      ))}
    </Tr>
  )
}
