import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { EditFilledIcon } from "Icons/EditFilledIcon"
import React, { ChangeEvent, KeyboardEvent, useState } from "react"

import { isEnter } from "Utilities/keyboard-event"
import { isPresent } from "Utilities/values"

interface CommonPathNameProps {
  name: string
  onRename: (name: string) => void
  isEditable: boolean
}

export const CommonPathName: React.FC<CommonPathNameProps> = ({
  name,
  onRename,
  isEditable,
}) => {
  const [renaming, setRenaming] = useState(false)
  const handleRename = (name: string) => {
    onRename(name)
    setRenaming(false)
  }

  return renaming ? (
    <RenameCommonPath
      currentName={name}
      onCancel={() => setRenaming(false)}
      onSave={handleRename}
    />
  ) : (
    <HStack spacing={1}>
      <Text fontWeight="bold">{name}</Text>
      {isEditable && (
        <Tooltip hasArrow placement="top" label="Rename">
          <IconButton
            aria-label="Rename common path"
            icon={<Icon as={EditFilledIcon} boxSize={5} />}
            color="gray.500"
            variant="ghost"
            size="sm"
            onClick={() => setRenaming(true)}
          />
        </Tooltip>
      )}
    </HStack>
  )
}

interface RenameCommonPathProps {
  currentName: string
  onCancel: () => void
  onSave: (name: string) => void
}

const RenameCommonPath: React.FC<RenameCommonPathProps> = ({
  currentName,
  onCancel,
  onSave,
}) => {
  const [name, setName] = useState(currentName)

  const handleRenameKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isEnter(event) && isPresent(name)) {
      onSave(name)
    }
  }
  const handleRenameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  return (
    <HStack onKeyDown={handleRenameKeyDown} spacing={1} width="100%">
      <Input
        size="sm"
        value={name}
        maxLength={80}
        onChange={handleRenameChange}
        required
        autoFocus
      />
      <Button size="sm" variant="outline" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        size="sm"
        colorScheme="brand.primary"
        onClick={() => onSave(name)}
      >
        Save
      </Button>
    </HStack>
  )
}
