import { Grid, HStack, Text } from "@chakra-ui/react"
import { IconButton } from "DesignSystem/components"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { FilterFilledIcon } from "Icons/FilterFilledIcon"
import { FilterIcon } from "Icons/FilterIcon"
import { UserProfileFilledIcon } from "Icons/UserProfileFilledIcon"
import { useTranslate } from "Shared/hooks/useTranslate"
import { TreeNode, TreeTestNodeResult } from "Types"
import { useSectionContext } from "UsabilityHub/contexts/SectionContext"
import React from "react"
import { usePathDiagramColors } from "../TreeTestPathDiagram/Legend"
import { useTreeTestFilter } from "./useTreeTestFilter"

export type Answer = {
  id: string
  label: string
  path: TreeNode[] | null
  count: number
  total: number
  percentage: number
  result: TreeTestNodeResult
}

type ItemProps = {
  answer: Answer
  correct: boolean
}

export const Item: React.FC<ItemProps> = ({ answer, correct }) => {
  const { section } = useSectionContext()

  const translate = useTranslate()

  const { toggleTreeTestFilter, isFilter } = useTreeTestFilter(
    section.id,
    answer.id === "skipped" ? null : answer.id
  )

  const colors = usePathDiagramColors()

  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      p={3}
      border="1px solid"
      borderColor="border.default"
      rounded="base"
      bg={`linear-gradient(to right, var(--chakra-colors-gray-100) ${answer.percentage}%, transparent ${answer.percentage}%)`}
    >
      <HStack spacing={1}>
        {answer.id === "skipped" ? (
          <Text fontStyle="italic" color="gray.400">
            {translate("test.tree_test.pass")}
          </Text>
        ) : (
          <Text>{answer.label}</Text>
        )}
        {correct && (
          <CheckCircleFilledIcon color={colors.node.direct_success.fill} />
        )}
      </HStack>
      <Grid
        gridTemplateColumns="auto 2.5rem auto"
        alignItems="center"
        fontSize="sm"
        color="text.subtlest"
        gap={4}
      >
        <Text textAlign="right" color="blue.400" fontWeight="medium">
          {answer.percentage.toFixed(1)}%
        </Text>
        <HStack spacing={1}>
          <UserProfileFilledIcon boxSize={3} color="text.subtlest" />
          <Text>{answer.count}</Text>
        </HStack>
        <IconButton
          aria-label="Filter"
          icon={isFilter ? <FilterFilledIcon /> : <FilterIcon />}
          alignSelf="center"
          isSelected={isFilter}
          onClick={() => toggleTreeTestFilter()}
        />
      </Grid>
    </HStack>
  )
}
