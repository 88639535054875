import { Box, Icon } from "@chakra-ui/react"
import React from "react"

import { Flag } from "Components/flag/flag"
import { UserProfileFilledIcon } from "Icons/UserProfileFilledIcon"

interface ParticipantIconProps {
  readonly country: string | null
}

export const ParticipantIcon: React.FC<
  React.PropsWithChildren<ParticipantIconProps>
> = ({ country }) => (
  <Box position="relative" w="2.25rem" mr={3}>
    <Icon as={UserProfileFilledIcon} boxSize={6} color="gray.400" />
    {country && (
      <Box
        position="absolute"
        w="20px"
        top={0}
        right={0}
        border="2px solid white"
        borderRadius={1}
      >
        <Flag country={country} />
      </Box>
    )}
  </Box>
)
