import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ChevronUpIcon } from "Icons/ChevronUpIcon"
import React from "react"

interface Props {
  isExpanded: boolean
}

export const ExpandChevron: React.FC<React.PropsWithChildren<Props>> = ({
  isExpanded,
}) =>
  isExpanded ? <ChevronUpIcon boxSize={5} /> : <ChevronDownIcon boxSize={5} />
