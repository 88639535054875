import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CursorFilledIcon = createIcon({
  displayName: "CursorFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      d="M4.90043 2.61991C4.67512 2.53898 4.44651 2.45686 4.25348 2.4067C4.07036 2.3591 3.71683 2.28021 3.33778 2.41147C2.90323 2.56196 2.56172 2.90347 2.41123 3.33802C2.27996 3.71707 2.35886 4.0706 2.40645 4.25372C2.45662 4.44675 2.53873 4.67536 2.61966 4.90068L8.34857 20.8599C8.44914 21.1402 8.54558 21.4089 8.64077 21.616C8.72118 21.791 8.90405 22.1737 9.30165 22.39C9.73641 22.6266 10.2599 22.6335 10.7007 22.4084C11.1039 22.2025 11.2967 21.8248 11.3817 21.652C11.4823 21.4475 11.5857 21.1813 11.6936 20.9038L14.2724 14.2727L20.9036 11.6939C21.1811 11.586 21.4472 11.4825 21.6517 11.382C21.8245 11.297 22.2023 11.1041 22.4081 10.701C22.6332 10.2602 22.6263 9.73671 22.3897 9.30194C22.1734 8.90434 21.7907 8.72147 21.6157 8.64106C21.4087 8.54588 21.1399 8.44944 20.8597 8.34887L4.90043 2.61991Z"
      fill="currentColor"
    />
  ),
})
