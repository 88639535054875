import { Box, Icon, IconButton } from "@chakra-ui/react"
import React from "react"

import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ChevronUpIcon } from "Icons/ChevronUpIcon"

interface Props {
  moveUpAction: () => void
  moveDownAction: () => void
  upDisabled: boolean
  downDisabled: boolean
}

export const NextAndPrevArrows: React.FC<Props> = ({
  moveUpAction,
  moveDownAction,
  upDisabled,
  downDisabled,
}) => {
  return (
    <Box>
      <IconButton
        variant="outline"
        aria-label="Move up"
        icon={<Icon as={ChevronUpIcon} boxSize={5} />}
        size="md"
        onClick={moveUpAction}
        mr={2}
        isDisabled={upDisabled}
      />
      <IconButton
        variant="outline"
        aria-label="Move down"
        icon={<Icon as={ChevronDownIcon} boxSize={5} />}
        size="md"
        onClick={moveDownAction}
        isDisabled={downDisabled}
      />
    </Box>
  )
}
