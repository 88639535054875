import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import * as Yup from "yup"

import { SubmitButton } from "Components/button/submit-button"
import { LyssnaLogoIcon } from "Shared/icons/LyssnaLogoIcon"
import {
  useApplyForModeratedStudy,
  useCreateModeratedStudyApplicant,
} from "~/api/generated/usabilityhub-components"

import { interviewApplicationReceivedGoogle } from "JavaScripts/analytics/google"
import { useModeratedStudyPreviewData } from "UsabilityHub/hooks/useModeratedStudyPreviewData"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"
import { PreferredNameField } from "./PreferredNameField"

const CreateApplicantSchema = Yup.object().shape({
  fullName: Yup.string()
    .required()
    .test({
      name: "fullName",
      test: (value) => !value || /^[^\/.:]*(\.\s[^\/.:]*)*(\.)?$/.test(value),
      message: "${path} is invalid",
    }),
  preferredName: Yup.string().required(),
  email: Yup.string()
    .email("Please enter a valid email")
    .test({
      name: "email",
      test: (value) => !value || /@[^.]+(\.[^.]+)+/.test(value),
      message: "${path} is invalid",
    })
    .required(),
  consentToInterview: Yup.boolean()
    .oneOf([true], "You must consent to the interview")
    .required(),
})

type CreateApplicantFormValues = Yup.InferType<typeof CreateApplicantSchema>

export function ModeratedStudyPersonalDetailsPage() {
  const navigate = useNavigate()

  const {
    application: moderatedStudyApplication,
    nextPageLink,
    invalidateApplicationQuery,
  } = useModeratedStudyApplicationContext()

  const hasScreener =
    moderatedStudyApplication.screener_required &&
    moderatedStudyApplication.moderated_study.screener_questions.length > 0

  const updatePreviewData = useModeratedStudyPreviewData()

  const { mutateAsync: createModeratedStudyApplicant, error } =
    useCreateModeratedStudyApplicant({
      onSuccess: invalidateApplicationQuery,
    })

  const { mutateAsync: applyForModeratedStudy } = useApplyForModeratedStudy()

  const {
    register,
    watch,
    formState: { errors, isValid, isSubmitting },
    setValue,
    handleSubmit,
  } = useForm<CreateApplicantFormValues>({
    mode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(CreateApplicantSchema),
    defaultValues: {
      fullName: "",
      preferredName: "",
      email: "",
      consentToInterview: false,
    },
  })

  const moderatedStudyApplicationId =
    moderatedStudyApplication.moderated_study_application_id

  const onSubmit: SubmitHandler<CreateApplicantFormValues> = async (values) => {
    if (moderatedStudyApplicationId === "preview") {
      updatePreviewData({
        applicant: {
          full_name: values.fullName,
          preferred_name: values.preferredName,
          email: values.email,
        },
      })
    } else {
      await applyForModeratedStudy({
        pathParams: {
          moderatedStudyApplicationId: moderatedStudyApplicationId,
        },
      })

      await createModeratedStudyApplicant({
        body: {
          moderated_study_application_id: moderatedStudyApplicationId,
          full_name: values.fullName,
          preferred_name: values.preferredName,
          email: values.email,
          agreed_to_terms: values.consentToInterview,
        },
      })

      // If the screener is required, we'll record the activity when the applicant completes the screener questions.
      // If the screener is not required, we'll record the activity when the applicant clicks on the button "I'm interested".
      if (!hasScreener) interviewApplicationReceivedGoogle()
    }

    navigate(nextPageLink!)
  }

  const setPreferredName = useCallback(
    (name: string) => setValue("preferredName", name),
    [setValue]
  )

  const isMobile = useBreakpointValue<boolean>(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  )

  const fullName = watch("fullName")
  const preferredName = watch("preferredName")

  // NOTE: The <form> uses manual margins to control the position of the
  // children so we can control the margins manually when animating in child
  // elements
  return (
    <Flex
      flexDirection="column"
      h="full"
      w="full"
      justifyContent={["space-between", null, null, "center"]}
    >
      {/* Empty div to take advantage of justify-content: space-between */}
      <Box />

      <Box>
        <Heading fontSize="2xl" fontWeight="400" mt={4}>
          Your details
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt="10">
            {error && (
              <Alert mt="4" status="error">
                <AlertIcon />
                <AlertTitle>Something went wrong!</AlertTitle>
                <AlertDescription>{error.payload.message}</AlertDescription>
              </Alert>
            )}
            <Box>
              <FormControl mt="4" isInvalid={!!errors.fullName}>
                <FormLabel fontSize="md" fontWeight="medium" htmlFor="fullName">
                  Full name
                </FormLabel>
                <Input
                  {...register("fullName")}
                  autoFocus
                  id="fullName"
                  type="text"
                  placeholder="Your full name"
                  size="lg"
                />
              </FormControl>
              <PreferredNameField
                fullName={fullName}
                preferredName={preferredName}
                setPreferredName={setPreferredName}
                inputProps={register("preferredName")}
                isInvalid={!!errors.preferredName}
              />
              <FormControl mt="4" isInvalid={!!errors.email}>
                <FormLabel fontSize="md" fontWeight="medium" htmlFor="email">
                  Email address
                </FormLabel>
                <Input
                  {...register("email")}
                  type="email"
                  id="email"
                  placeholder="Your email address"
                  size="lg"
                />
              </FormControl>
            </Box>
            <FormControl mt="6" isInvalid={!!errors.consentToInterview}>
              <Checkbox
                {...register("consentToInterview")}
                textColor="text.primary"
                alignItems="flex-start"
                sx={{
                  // Make sure the checkbox aligns with the top of the text when it wraps onto two lines
                  ".chakra-checkbox__control": { mt: 1 },
                }}
              >
                I agree to have my details stored and to be contacted for this
                study
              </Checkbox>
            </FormControl>
            <Flex mt="4" gap="4" flexDirection={["column", null, null, "row"]}>
              <SubmitButton
                isDisabled={!isValid}
                isLoading={isSubmitting}
                loadingAction="Submitting"
                flexShrink={0}
              >
                I{"\u2019"}m interested
              </SubmitButton>
              {hasScreener && (
                <Text fontSize="sm" textColor="text.primary">
                  You will be asked to complete some questions in order to be
                  considered for this study.
                </Text>
              )}
            </Flex>
          </Box>
        </form>
      </Box>
      {isMobile && (
        <Flex width="full" justifyContent="center" mt={8} mb={[4, null, 8]}>
          <Flex alignItems="center" flexDirection="column" gap={1}>
            <LyssnaLogoIcon color="neutral" w="128px" h="35px" />
            <Text textAlign="center" fontSize="xs" color="text.secondary">
              This invitation was sent via Lyssna.com
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
