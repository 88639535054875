import {
  Box,
  Button,
  Grid,
  Heading,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { AlertCircleIcon } from "Icons/AlertCircleIcon"
import React, { useEffect, useState } from "react"
import { useUploadModeratedStudyInterviewTranscript } from "~/api/generated/usabilityhub-components"
import { InterviewTranscript } from "~/api/generated/usabilityhubSchemas"

type UploadTranscriptProps = Omit<ModalProps, "children"> & {
  moderatedStudyId: string
  sessionId: string
  recordingId: string
  onSuccess: (transcript: InterviewTranscript) => void
}

export const UploadTranscript: React.FC<UploadTranscriptProps> = ({
  moderatedStudyId,
  sessionId,
  recordingId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [file, setFile] = useState<File | null>(null)

  const [text, setText] = useState<string | null>(null)

  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (isOpen) {
      setFile(null)
      setText(null)
      setError(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (!file) return

    setError(null)
    const reader = new FileReader()
    reader.addEventListener("load", (e) => {
      setText(e.target?.result as string)
    })
    reader.readAsText(file)
  }, [file])

  useEffect(() => {
    if (!text) return

    try {
      checkTranscript(text)
    } catch (e) {
      setError(e)
    }
  }, [text])

  const { isLoading: isUploading, mutateAsync } =
    useUploadModeratedStudyInterviewTranscript()

  const upload = () => {
    if (!text || !!error) return

    mutateAsync({
      pathParams: {
        moderatedStudyId,
        moderatedStudyBookingId: sessionId,
        recordingId,
      },
      body: {
        text,
      },
    })
      .then((data) => {
        onSuccess(data)
        onClose()
      })
      .catch(() => {
        setError(new Error("There was a problem uploading your transcript."))
      })
  }

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload transcript</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Upload a transcript in the standard <code>.SRT</code> format used
            for video subtitles.
          </Text>
          <Box fontSize="sm" rounded="md" bg="gray.50" p={3} my={3}>
            <UnorderedList>
              <ListItem>
                Your file should consist of a number of sections separated by
                blank lines.
              </ListItem>
              <ListItem>
                Each section should contain a number, a time range, and one or
                more lines of text, with speaker names indicated in{" "}
                <Text as="code" whiteSpace="nowrap">
                  [square brackets]
                </Text>{" "}
                at the start of a line.
              </ListItem>
              <ListItem>
                Time ranges should be formatted as{" "}
                <Text as="code" whiteSpace="nowrap">
                  hh:mm:ss,xxx --&gt; hh:mm:ss,xxx
                </Text>
                {", "}
                where <code>xxx</code> is milliseconds.
              </ListItem>
              <ListItem>
                <Link download href="/templates/sample.srt">
                  Download an example transcript
                </Link>
              </ListItem>
            </UnorderedList>
          </Box>
          {error && (
            <Grid
              gridTemplateColumns="auto 1fr"
              gap={3}
              alignItems="start"
              bg="red.50"
              p={3}
              rounded="md"
            >
              <AlertCircleIcon color="red.500" boxSize="1.5rem" />
              <Stack spacing={3}>
                <Heading as="h4" size="sm" fontWeight="medium">
                  {error.message}
                </Heading>
                <Text>Please check the file format and try again.</Text>
                {!!error.cause && <pre>{String(error.cause)}</pre>}
              </Stack>
            </Grid>
          )}
        </ModalBody>
        <ModalFooter
          justifyContent="space-between"
          alignItems="stretch"
          gap={3}
        >
          {file ? (
            <Tag size="lg" minW={0}>
              <TagLabel
                display="block"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                minW={0}
              >
                {file.name}
              </TagLabel>
              <TagCloseButton
                onClick={() => {
                  setFile(null)
                  setError(null)
                }}
              />
            </Tag>
          ) : (
            <Button as="label" variant="solid" cursor="pointer">
              Browse files…
              <input
                type="file"
                accept=".srt"
                style={{ display: "none" }}
                onChange={(e) => setFile(e.currentTarget.files?.[0] || null)}
              />
            </Button>
          )}
          <Button
            colorScheme="brand.primary"
            isDisabled={!file || !!error || undefined}
            isLoading={isUploading}
            loadingText="Please wait…"
            onClick={upload}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const checkTranscript = (transcript: string) => {
  const screens = transcript.split(/\n{2,}/)
  const invalidScreen = screens.findIndex((screen) => {
    const lines = screen.split(/\n/)
    if (lines.length < 3) return true
    if (!lines[0].match(/^\d+\s*$/)) return true
    if (
      !lines[1].match(
        /^(\d{2}:\d{2}:\d{2}(,\d{3})?)\s*-->\s*(\d{2}:\d{2}:\d{2}(,\d{3})?)\s*$/
      )
    )
      return true
    return false
  })
  if (invalidScreen > -1) {
    let index = parseInt(screens[invalidScreen].split(/\n/)[0])
    if (Number.isNaN(index)) index = invalidScreen + 1
    throw new Error(`There’s a problem in section ${invalidScreen + 1}.`, {
      cause: screens[invalidScreen],
    })
  }
}
