import { Flex, Link, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { AlertCircleIcon } from "Icons/AlertCircleIcon"
import { SessionLimitIndicator } from "UsabilityHub/components/ModeratedStudy/SessionLimitIndicator"
import React from "react"
import { useGetModeratedStudyQuota } from "~/api/generated/usabilityhub-components"
import { useModeratedStudyContext } from "./ModeratedStudyContext"

export const SessionQuotaExhaustedBanner: React.FC = () => {
  const moderatedStudy = useModeratedStudyContext()
  const studyCreatedBeforeCommercialLaunch =
    moderatedStudy.moderatedStudy.created_at <
    Constants.INTERVIEWS_COMMERCIAL_LAUNCH_DATE
  const { data } = useGetModeratedStudyQuota(
    {},
    {
      enabled: !studyCreatedBeforeCommercialLaunch,
    }
  )

  if (!data) return null
  // Even though the query is not enabled, it might have data from a previous run
  // so we need to explicitly check the date here too
  if (studyCreatedBeforeCommercialLaunch) return null
  if (data.self_recruited_sessions.used < data.self_recruited_sessions.quota)
    return null

  return (
    <Flex
      h="40px"
      bg="purple.50"
      color="text.primary"
      align="center"
      justify="center"
      px={8}
      gap={2}
    >
      <AlertCircleIcon color="purple.700" />
      <Flex align="baseline" gap={2}>
        <Text noOfLines={1}>
          You{"\u2019"}ve reached this month{"\u2019"}s session limit for
          interview sessions from your network
        </Text>
        <SessionLimitIndicator
          customTrigger={
            <Link
              variant="unstyled"
              color="purple.700"
              textDecoration="none"
              _hover={{
                color: "purple.700",
                textDecoration: "none",
              }}
            >
              Learn more
            </Link>
          }
        />
      </Flex>
    </Flex>
  )
}
