import { HStack, MenuItem, Stack, Text } from "@chakra-ui/react"
import { Input } from "DesignSystem/components"
import { AppliedFilter, FilterAttribute, FilterOptionChoice } from "Types"
import { deburr } from "lodash"
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

interface Props {
  appliedFilter?: AppliedFilter | null
  selectedAttribute: FilterAttribute
  icon?: (choice: FilterOptionChoice, isActive: boolean) => ReactElement | null
  onSelect: (choice: FilterOptionChoice) => void
}

export const FilterMenu: React.FC<Props> = ({
  appliedFilter = null,
  selectedAttribute,
  icon,
  onSelect,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [textFilter, setTextFilter] = useState("")

  const filteredChoices = useMemo(() => {
    const { type } = selectedAttribute.possibleOptions
    if (type !== "multi" && type !== "single") return []

    const query = deburr(textFilter.toLowerCase())
    return selectedAttribute.possibleOptions.choices.filter((choice) =>
      deburr(choice.label.toLowerCase()).includes(query)
    )
  }, [textFilter, selectedAttribute])

  const applied = useMemo(() => {
    const options = appliedFilter?.options
    if (options?.type !== "multi") return new Set<string>()
    return new Set(options.choices.map((c) => c.value))
  }, [appliedFilter])

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }, [])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inInput = (e.target as HTMLElement).tagName === "INPUT"

    if (e.key === "ArrowDown" && inInput) {
      e.preventDefault()
      e.stopPropagation()
      wrapperRef.current?.querySelector("button")?.focus()
    }

    if (e.key === "ArrowUp" && !inInput) {
      const previousItem = (e.target as HTMLElement).previousElementSibling
      if (previousItem?.tagName !== "BUTTON") {
        e.preventDefault()
        e.stopPropagation()
        inputRef.current?.focus()
      }
    }
  }

  return (
    <Stack
      gap={0}
      ref={wrapperRef}
      rounded="inherit"
      onKeyDownCapture={handleKeyDown}
    >
      <Stack
        borderBottom="1px solid"
        borderColor="ds.border.default"
        sx={{
          ".chakra-input__group": {
            roundedBottom: "0",
            background: "transparent",

            input: {
              "&, &:not(:focus)": {
                background: "transparent !important",
              },
            },

            "&::after": {
              display: "none",
            },
          },
        }}
      >
        <Input
          ref={inputRef}
          autoFocus
          placeholder={selectedAttribute.label}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
        />
      </Stack>
      {filteredChoices.length ? (
        filteredChoices.map((choice) => (
          <MenuItem
            key={choice.value}
            icon={icon?.(choice, applied.has(choice.value)) ?? undefined}
            sx={{
              "&:last-child": {
                borderBottomRadius: "inherit",
              },
            }}
            onClick={() => onSelect(choice)}
          >
            <HStack gap={2}>
              {choice.icon}
              <Text>{choice.label}</Text>
            </HStack>
          </MenuItem>
        ))
      ) : (
        <HStack justify="center" px={6} py={4}>
          <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
            (Nothing matches)
          </Text>
        </HStack>
      )}
    </Stack>
  )
}
