import { Container } from "@chakra-ui/react"
import { AnimatePresence, MotionConfig, Variants, motion } from "framer-motion"
import React, { PropsWithChildren } from "react"

const footerVariants: Variants = {
  out: {
    y: "100%",
    opacity: 0,
  },
  in: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
}

export const OnboardingFooter: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <MotionConfig transition={{ duration: 0.3, ease: [0.4, 0, 0.2, 1] }}>
      <Container
        as="footer"
        maxW="532px"
        gridArea="footer"
        py={8}
        minH={10}
        color="text.secondary"
        fontSize="sm"
      >
        <AnimatePresence mode="popLayout" initial={false}>
          <motion.div
            key={children ? 1 : 0}
            variants={footerVariants}
            initial="out"
            animate="in"
            exit="out"
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </Container>
    </MotionConfig>
  )
}
