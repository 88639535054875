import { createIcon } from "@chakra-ui/react"
import React from "react"

export const QuestionCheckboxIcon = createIcon({
  displayName: "QuestionCheckboxIcon",
  viewBox: "0 0 16 16",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V4C15 2.34315 13.6569 1 12 1H4ZM10.8212 5.2159L6.8201 9.53706L5.11264 8.15402C4.83897 7.93235 4.41745 7.95232 4.17114 8.19862C3.92484 8.44493 3.94702 8.8243 4.2207 9.04597L6.44291 10.846C6.72398 11.0736 7.15895 11.0455 7.40103 10.7841L11.8455 5.98412C12.0812 5.72955 12.043 5.35122 11.7601 5.13908C11.4773 4.92694 11.0569 4.96134 10.8212 5.2159Z"
      fill="currentColor"
    />
  ),
})
