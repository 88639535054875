import {
  FilterContextProvider,
  FilterContextProviderProps,
} from "UsabilityHub/components/FilterControls/FilterContext"
import { useFilterOptions } from "UsabilityHub/hooks/useFilterOptions"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { generateDashboardFilters } from "UsabilityHub/views/dashboard/dashboard-filters"
import React, { PropsWithChildren } from "react"
import { NewProject, VariationSet } from "~/api/generated/usabilityhubSchemas"

export const DashboardFilterProvider: React.FC<
  PropsWithChildren<Partial<FilterContextProviderProps>>
> = ({ children, ...props }) => {
  const teamMembers = useTeamMembers()
  const projects = useFilterOptions<NewProject>({
    filter: "projects",
    scopes: [],
  })

  const variationSets = useFilterOptions<VariationSet>({
    filter: "variation_sets",
    scopes: [],
  })

  const dashboardFilters = generateDashboardFilters(
    teamMembers,
    projects?.allOptions ?? [],
    variationSets?.allOptions ?? []
  )

  return (
    <FilterContextProvider
      entityBeingFiltered="Test"
      availableAttributes={dashboardFilters}
      {...props}
    >
      {children}
    </FilterContextProvider>
  )
}
