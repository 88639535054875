import { Flex, Icon, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { Badge, IconButton } from "DesignSystem/components"
import { EditFilledIcon } from "Icons/EditFilledIcon"
import React from "react"
import { AdminListDemographicsResponse } from "~/api/generated/usabilityhub-components"

type Props = {
  attribute: AdminListDemographicsResponse[number]
  sortingByPriority: boolean
  onEdit: () => void
}

export const DemographicAttributeCard: React.FC<Props> = ({
  attribute,
  sortingByPriority,
  onEdit,
}) => {
  return (
    <Flex
      pos="relative" // Needed for positioning the edit button correctly
      direction="column"
      align="flex-start"
      rounded="16px"
      boxShadow="ds.raised"
      bg="gray.50"
      gap={2}
      p={4}
      role="group"
    >
      <IconButton
        pos="absolute"
        top={2}
        right={2}
        aria-label="Edit this demographic attribute"
        icon={<Icon as={EditFilledIcon} />}
        opacity={0}
        _groupHover={{ opacity: 1 }}
        transition="opacity 0.15s ease-in-out"
        onClick={onEdit}
      >
        Edit
      </IconButton>

      <Flex align="center" gap={2} w="full" pe={6}>
        <Badge colorScheme="selected" label={attribute.group} />
        <Text textStyle="ds.interface.small" ms="auto">
          On UserCrowd:
        </Text>
        <Badge
          label={attribute.visible_to_panelists ? "Published" : "Draft"}
          colorScheme={attribute.visible_to_panelists ? "success" : "upgrade"}
        />
        <Text textStyle="ds.interface.small">On Lyssna:</Text>
        <Badge
          label={attribute.visible_to_customers ? "Published" : "Draft"}
          colorScheme={attribute.visible_to_customers ? "success" : "upgrade"}
        />
      </Flex>

      <Text textStyle="ds.display.secondary" color="ds.text.default">
        {attribute.question}
      </Text>

      <UnorderedList>
        {attribute.options.map((option) => {
          return (
            <ListItem
              key={option.id}
              textStyle="ds.paragraph.secondary"
              color="ds.text.default"
            >
              {option.value}

              {option.profile_helper && (
                <Text
                  as="span"
                  textStyle="ds.paragraph.secondary"
                  color="ds.text.subtlest"
                >
                  {" "}
                  ({option.profile_helper})
                </Text>
              )}
            </ListItem>
          )
        })}
      </UnorderedList>

      <Text
        alignSelf="flex-end"
        textStyle="ds.paragraph.secondary"
        color="ds.text.subtle"
      >
        <Text as="span" fontWeight={sortingByPriority ? undefined : "bold"}>
          Group order: {attribute.sort_order}
        </Text>
        ,{" "}
        <Text as="span" fontWeight={sortingByPriority ? "bold" : undefined}>
          Dashboard priority: {attribute.individual_sort_order}
        </Text>
      </Text>
    </Flex>
  )
}
