import {
  Button,
  Icon,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react"
import { MaintainAspectRatioContainer } from "Components/screenshot/maintain-aspect-ratio-container"
import { AnswerWordCloud } from "Components/test-results/question-results/answer-word-cloud"
import { NoResponses } from "Components/test-results/question-results/no-responses"
import { Props as BaseProps } from "Components/test-results/question-results/props"
import { TagCounts } from "Components/test-results/question-results/tag-counts/tag-counts"
import { EditAnswerListTags } from "Components/test-results/question-results/written-answer-question-results/edit-answer-list-tags"
import styles from "Components/test-results/question-results/written-answer-question-results/written-answer-question-results.module.scss"
import { DownloadIcon } from "Icons/DownloadIcon"
import { ListIcon } from "Icons/ListIcon"
import { TagIcon } from "Icons/TagIcon"
import { WordCloudIcon } from "Icons/WordCloudIcon"
import {
  getAnswerTags,
  isReadonly,
} from "Redux/reducers/test-results/selectors"
import { SortMethod } from "Types"
import {
  SectionResultsQuestionHeading,
  SectionResultsQuestionText,
} from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { useSectionContext } from "UsabilityHub/contexts"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { downloadPng } from "Utilities/dom-to-image"
import { reportErrorToSentry } from "Utilities/error"
import { Values } from "Utilities/values"
import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTrackEvent } from "~/api/generated/usabilityhub-components"

const TabId = {
  AnswerList: 0,
  TagCounts: 1,
  WordCloud: 2,
} as const

const Content: React.FC<React.PropsWithChildren<BaseProps>> = ({
  answers,
  question,
}) => {
  const toast = useToast()
  const { mutate: trackEvent } = useTrackEvent()
  const { section } = useSectionContext()
  const sectionNumber = section.position + 1

  const [selectedTabId, setSelectedTabId] = useState<Values<typeof TabId>>(
    TabId.AnswerList
  )

  const [isExporting, setIsExporting] = useState(false)

  const testIsReadonly = useSelector(isReadonly)
  const canManage = useMaybeCurrentUser()?.can_manage_tests ?? false
  const answerTags = useSelector(getAnswerTags)
  const hasTags = question.question_tags.length > 0
  const isTagTabVisible = !testIsReadonly || hasTags

  const wordCloudContainerRef = useRef<HTMLDivElement>(null)
  const exportWordCloud = async () => {
    if (isExporting) return

    const ref = wordCloudContainerRef.current
    if (ref) {
      setIsExporting(true)
      try {
        trackEvent({
          body: {
            event: "account_exported_word_cloud",
            event_properties: { question_id: question.id },
          },
        })
        await downloadPng(
          ref,
          `section-${sectionNumber}-question-${question.position}-wordcloud`,
          { bgcolor: "white" }
        )
      } catch (e) {
        toast({
          title:
            "Sorry, we were unable to export your results. Please try again.",
          status: "error",
        })
        reportErrorToSentry(e)
      } finally {
        setIsExporting(false)
      }
    }
  }

  return (
    <Tabs
      onChange={(index: Values<typeof TabId>) => {
        setSelectedTabId(index)
      }}
      // Load panel when its tab is selected so the word cloud is rendered with the correct scale.
      isLazy
      // unmount and recreate the tab component each time and pay the re-render performance costs
      // note: this fixes a bug where the EditAnswerListTags component would not render correctly post tab change
      lazyBehavior="unmount"
    >
      <TabList alignItems="center" color="gray.600">
        <Tab>
          <Icon as={ListIcon} mr={2} />
          <Text>
            Answers <small>{answers.length}</small>
          </Text>
        </Tab>
        {isTagTabVisible && (
          <Tab>
            <Icon as={TagIcon} mr={2} />
            <Text>
              Tags <small>{question.question_tags.length}</small>
            </Text>
          </Tab>
        )}
        <Tab>
          <Icon as={WordCloudIcon} mr={2} />
          <Text>Word cloud</Text>
        </Tab>

        <Spacer />
        {selectedTabId === TabId.WordCloud && (
          <Button
            size="sm"
            colorScheme="brand.primary"
            fontWeight="normal"
            isDisabled={isExporting}
            isLoading={isExporting}
            loadingText="Export word cloud as image"
            onClick={exportWordCloud}
          >
            <Icon as={DownloadIcon} boxSize="12px" mr={2} />
            Export word cloud as image
          </Button>
        )}
      </TabList>
      <TabPanels>
        <TabPanel px={0} pb={0}>
          <EditAnswerListTags
            answers={answers}
            question={question}
            isReadonly={testIsReadonly || !canManage}
          />
        </TabPanel>
        {isTagTabVisible && (
          <TabPanel px={0} pb={0}>
            <TagCounts
              answers={answers}
              answerTags={answerTags}
              question={question}
              sortMethod={SortMethod.PopularityDescending}
            />
          </TabPanel>
        )}
        <TabPanel px={0} pb={0}>
          <MaintainAspectRatioContainer
            ref={wordCloudContainerRef}
            className={styles.wordCloudContainer}
            dimensions={{ width: 1024, height: 1024 }}
          >
            <AnswerWordCloud className={styles.wordCloud} answers={answers} />
          </MaintainAspectRatioContainer>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export const WrittenAnswerQuestionResults: React.FC<
  React.PropsWithChildren<BaseProps>
> = ({ question, answers, totalResponseCount }) => {
  const hasAnswers = totalResponseCount > 0
  return (
    <Stack spacing={5}>
      <SectionResultsQuestionHeading />
      <SectionResultsQuestionText />
      {hasAnswers ? (
        <Content
          question={question}
          answers={answers}
          totalResponseCount={totalResponseCount}
        />
      ) : (
        <NoResponses />
      )}
    </Stack>
  )
}
