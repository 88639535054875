import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react"
import { IconButton } from "DesignSystem/components"
import { DeleteIcon } from "Icons/DeleteIcon"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { Dispatch } from "Redux/app-store"
import { deleteTask } from "Redux/reducers/test-builder-form/action-creators/live-website-test"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import React from "react"
import { useDispatch } from "react-redux"

type Props = {
  taskIndex: number
}

export const LiveWebsiteTaskContextMenu: React.FC<Props> = ({ taskIndex }) => {
  const dispatch: Dispatch = useDispatch()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const totalTasksInSection =
    section.live_website_test?.live_website_test_tasks.length ?? 0

  const onDelete = () => {
    dispatch(deleteTask(sectionIndex, taskIndex))
  }

  const isDeleteDisabled = readOnly || totalTasksInSection === 1

  return (
    <Menu placement="bottom-end" isLazy>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon boxSize={4} as={MenuKebabIcon} color="ds.icon.default" />}
        size="sm"
        onClick={(e) => e.stopPropagation()}
      />
      <Portal>
        <MenuList onClick={(e) => e.stopPropagation()} overflow="hidden">
          <MenuItem
            aria-label="Delete task"
            color="red.600"
            icon={<Icon color="red.600" boxSize={5} as={DeleteIcon} />}
            onClick={onDelete}
            isDisabled={isDeleteDisabled}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
