import { Button } from "DesignSystem/components"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { EmulateUserModal } from "./EmulateUserModal"

type Props = {
  userId: number
  isPanelist: boolean
  emulationToken: string | undefined
  adminId: number
}

// Designed to be used via mount_react in the server-rendered admin pages
export const EmulateUserButtonWrapper: React.FC<Props> = ({
  userId,
  isPanelist,
  emulationToken,
  adminId,
}) => {
  return (
    <ModalContextProvider>
      <EmulateUserButton
        userId={userId}
        isPanelist={isPanelist}
        emulationToken={emulationToken}
        adminId={adminId}
      />
    </ModalContextProvider>
  )
}

const EmulateUserButton: React.FC<Props> = ({
  userId,
  adminId,
  isPanelist,
  emulationToken,
}) => {
  const { open } = useModal(EmulateUserModal)

  return (
    <Button
      variant="primary"
      onClick={() => open({ userId, isPanelist, emulationToken, adminId })}
    >
      Emulate
    </Button>
  )
}
