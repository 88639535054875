import React, { PropsWithChildren, useCallback } from "react"
import { fetchSharedUsabilityTestResults } from "~/api/generated/usabilityhub-components"
import { BaseResultsDataProvider } from "./BaseResultsDataProvider"
import { ResultUsabilityTestResponseData } from "./types"

type Props = {
  usabilityTestUniqueId: string
  privateId: string
}

export const SharedUsabilityTestResultDataProvider: React.FC<
  PropsWithChildren<Props>
> = ({ children, usabilityTestUniqueId, privateId }) => {
  const queryKey = `shared-usability-test-result-${usabilityTestUniqueId}-${privateId}`
  const apiFunction = useCallback(
    async () =>
      (await fetchSharedUsabilityTestResults({
        pathParams: {
          usabilityTestId: usabilityTestUniqueId,
          privateId: privateId,
        },
      })) as ResultUsabilityTestResponseData,
    [usabilityTestUniqueId, privateId]
  )

  return (
    <BaseResultsDataProvider queryKey={queryKey} apiFunction={apiFunction}>
      {children}
    </BaseResultsDataProvider>
  )
}
