import {
  Avatar,
  Box,
  Button,
  Card,
  Heading as ChakraHeading,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  StyleProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Heading } from "DesignSystem/components"
import { CalendarIcon } from "Icons/CalendarIcon"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { DeleteIcon } from "Icons/DeleteIcon"
import { HelpCircleIcon } from "Icons/HelpCircleIcon"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import { ConflictCalendarModal } from "UsabilityHub/components/ConflictCalendarModal/ConflictCalendarModal"
import { googleCalendarCredentialsError } from "UsabilityHub/components/ModeratedStudy/ModeratedStudyAlerts"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { useTeamMembersForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useTeamMembersForm"
import { useModal } from "Utilities/modals/use-modal"
import { pluralizeWithCount } from "Utilities/string"
import React, { useState } from "react"
import { usePutModeratedStudyMembers } from "~/api/generated/usabilityhub-components"
import {
  ModeratedStudy,
  ModeratedStudyCalendar,
  ModeratedStudyMember,
} from "~/api/generated/usabilityhubSchemas"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import { AddMember } from "./AddMember"
import { BookingCalendarDropdown } from "./BookingCalendarDropdown"

const thStyles: StyleProps = {
  textTransform: "none",
  textColor: "text.secondary",
  fontSize: "sm",
  fontWeight: "medium",
  letterSpacing: "normal",
  borderColor: "gray.200",
  px: 4,
}

const tdStyles: StyleProps = {
  borderColor: "gray.200",
  px: 4,
  py: 0,
}

const ROW_HEIGHT = "56px"

interface CalendarConnection {
  moderatedStudyId: string
  bookingCalendar: ModeratedStudy["booking_calendar"]
  members: ModeratedStudyMember[]
  teamMembersForm: ReturnType<typeof useTeamMembersForm>
  setMutatingTeamMembers: (mutating: boolean) => void
}

export const CalendarConnection: React.FC<CalendarConnection> = ({
  moderatedStudyId,
  bookingCalendar,
  members,
  teamMembersForm,
  setMutatingTeamMembers,
}) => {
  const user = useCurrentUser()
  const currentMember = members.find((m) => m.id === user.id)
  const toast = useToast()
  const queryClient = useQueryClient()
  const { moderatedStudy } = useModeratedStudyContext()
  const { open: openConflictCalendarModal } = useModal(ConflictCalendarModal)
  const [confirmMainHostSwitch, setConfirmMainHostSwitch] =
    useState<ModeratedStudyMember | null>(null)

  const {
    watch: bookingCalendarWatch,
    setValue: bookingCalendarSetValue,
    formState: { errors: bookingCalendarErrors },
    reset: resetBookingCalendar,
  } = teamMembersForm

  const activeCollaborators = useTeamMembers({
    ignoreRoles: ["guest", "archived"],
    onlyActive: true,
  })
  const availableCollaborators = activeCollaborators.filter(
    (activeCollaborator) => {
      return !members.some(
        (member) => member.email === activeCollaborator.email
      )
    }
  )
  const mainHost = members.find((member) => member.role === "main_host")
  if (!mainHost) throw new Error("missing main host")
  const { mutate } = usePutModeratedStudyMembers({
    onMutate: () => setMutatingTeamMembers(true),
    onSettled: () => setMutatingTeamMembers(false),
    onSuccess: (_, variables) => {
      if (variables.body.host_id !== mainHost.id) {
        // Clear the booking calendar if the main host has changed.
        // This will happen on reload but because there's a watch on the form we need to trigger this to update immediately
        resetBookingCalendar({
          userId: variables.body.host_id,
          bookingCalendarId: null,
          bookingCalendarSummary: null,
          bookingCalendarType: null,
        })
      }
      return queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
    },
    onError: (error) => {
      toast({
        title: "There was a problem updating the members",
        description: error.payload.message,
        status: "error",
      })
    },
  })
  const coHostIds = members
    .filter((member) => member.role === "co_host")
    .map((member) => member.id)
  const addMember = (memberId: number) => {
    mutate({
      pathParams: { moderatedStudyId: moderatedStudyId },
      body: {
        host_id: mainHost.id,
        cohost_ids: [memberId, ...coHostIds],
      },
    })
  }
  const removeMember = (memberId: number) => {
    mutate({
      pathParams: { moderatedStudyId: moderatedStudyId },
      body: {
        host_id: mainHost.id,
        cohost_ids: coHostIds.filter((id) => id !== memberId),
      },
    })
  }

  const confirmChangeMainHost = (memberId: number) => {
    mutate({
      pathParams: { moderatedStudyId },
      body: {
        host_id: memberId,
        cohost_ids: [mainHost.id, ...coHostIds.filter((id) => id !== memberId)],
      },
    })
  }

  const setMemberRole = (
    memberId: number,
    role: ModeratedStudyMember["role"]
  ) => {
    const member = members.find((m) => m.id === memberId)
    if (!member || member?.role === role) {
      return
    }

    if (role === "main_host") {
      // If we're using Zoom meeting links there's a confirmation modal step
      if (moderatedStudy.location_type === "zoom") {
        setConfirmMainHostSwitch(member)
      } else {
        // Otherwise make the change immediately
        confirmChangeMainHost(member.id)
      }
    }
  }

  const currentBookingCalendar = bookingCalendarWatch()
  const setBookingCalendar = (calendar: ModeratedStudyCalendar) => {
    bookingCalendarSetValue("bookingCalendarId", calendar.id, {
      shouldDirty: true,
    })
    bookingCalendarSetValue("bookingCalendarType", calendar.provider ?? null, {
      shouldDirty: true,
    })
    bookingCalendarSetValue("bookingCalendarSummary", calendar.summary, {
      shouldDirty: true,
    })
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <HStack w="full">
        <Heading as="h3" textStyle="ds.display.primary">
          Team members
        </Heading>
        <Spacer />
        <AddMember
          collaborators={availableCollaborators}
          onSelect={addMember}
        />
      </HStack>
      <Spacer />
      <TableContainer w="full">
        <Table color="text.primary" variant="simple">
          <Thead>
            <Tr>
              <Th w="33%" {...thStyles} px={0}>
                Member
              </Th>
              <Th w="33%" {...thStyles}>
                Role
              </Th>
              <Th w="33%" {...thStyles}>
                Check availability on
                <Tooltip
                  hasArrow
                  rounded="md"
                  label="The booking calendar that applicants see will take into account the availability on your selected calendar/s. Applicants will not be able to book over any unavailable time slots."
                >
                  <HelpCircleIcon ms={1} mb={0.5} />
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {members.map((member) => {
              const isMe = member.id === user.id

              return (
                <Tr key={member.email}>
                  <Td {...tdStyles}>
                    <Flex h={ROW_HEIGHT} alignItems="center" gap={3}>
                      <Avatar
                        size="sm"
                        name={member.name}
                        src={member.avatar}
                      />
                      <Text color="text.primary">{member.name}</Text>
                    </Flex>
                  </Td>
                  <Td {...tdStyles}>
                    <RoleSelect
                      member={member}
                      setMemberRole={setMemberRole}
                      isDisabled={
                        !user.can_manage_tests ||
                        // can't change main host, can only select a new one
                        member.role === "main_host"
                      }
                    />
                  </Td>
                  <Td {...tdStyles}>
                    <Flex
                      h={ROW_HEIGHT}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {
                        <Button
                          color="brand.neutral.default"
                          size="sm"
                          fontSize="md"
                          aria-label="Check availability on these calendars"
                          variant="outline"
                          fontWeight="normal"
                          isDisabled={!isMe}
                          minW="149px" // keep the same width from "1 calendar" to "99 calendars"
                          onClick={() =>
                            openConflictCalendarModal({
                              initialSelectedCalendars:
                                member.conflict_calendars,
                              hasGoogleCalendarError:
                                member.has_google_calendar_error,
                            })
                          }
                        >
                          <Icon as={CalendarIcon} boxSize={4} me={2} />
                          {member.conflict_calendars.length === 0 && isMe
                            ? "Connect a calendar"
                            : pluralizeWithCount(
                                member.conflict_calendars.length,
                                "calendar",
                                "calendars"
                              )}
                          {member.has_google_calendar_error && (
                            <Tooltip
                              label={googleCalendarCredentialsError(
                                "conflict",
                                member === currentMember
                              )}
                            >
                              <Icon
                                as={WarningFilledIcon}
                                color="ds.icon.danger"
                                aria-label="Attention"
                                ml={1}
                              />
                            </Tooltip>
                          )}
                        </Button>
                      }

                      <Tooltip
                        hasArrow
                        placement="top"
                        label="Please assign another main host before removing this host"
                        isDisabled={member.role !== "main_host"}
                      >
                        <Box>
                          <IconButton
                            aria-label="Remove member"
                            icon={
                              <DeleteIcon
                                boxSize={5}
                                color="brand.neutral.default"
                              />
                            }
                            variant="ghost"
                            onClick={() => {
                              removeMember(member.id)
                            }}
                            isDisabled={
                              !user.can_manage_tests ||
                              member.role === "main_host"
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Spacer />

      <Card
        w="full"
        p={4}
        bg="gray.50"
        shadow="none"
        borderWidth={1}
        borderColor="gray.200"
      >
        <FormControl
          display="flex"
          isInvalid={!!bookingCalendarErrors.bookingCalendarId}
        >
          <Flex basis="50%" direction="column" shrink={0}>
            <FormLabel
              color="text.primary"
              fontSize="md"
              lineHeight={6}
              fontWeight="medium"
              margin={0}
            >
              Booking calendar
            </FormLabel>
            <Text color="text.secondary" fontSize="sm">
              Choose the calendar where you want to make new bookings
            </Text>
          </Flex>
          <Flex basis="50%" alignItems="center" justify="flex-end" maxW="50%">
            {currentMember && (
              <BookingCalendarDropdown
                bookingCalendar={bookingCalendar}
                newBookingCalendar={
                  currentBookingCalendar.bookingCalendarId !==
                  bookingCalendar?.id
                    ? {
                        summary:
                          currentBookingCalendar.bookingCalendarSummary ?? "",
                        provider:
                          currentBookingCalendar.bookingCalendarType ??
                          undefined,
                      }
                    : null
                }
                currentMember={currentMember}
                setBookingCalendar={setBookingCalendar}
              />
            )}
          </Flex>
        </FormControl>
      </Card>

      {confirmMainHostSwitch && (
        <SwitchHostWhenZoomEnabledModal
          newHost={confirmMainHostSwitch}
          handleClose={() => setConfirmMainHostSwitch(null)}
          handleSwitch={() => confirmChangeMainHost(confirmMainHostSwitch.id)}
        />
      )}
    </VStack>
  )
}

type RoleSelectProps = {
  member: ModeratedStudyMember
  setMemberRole: (memberId: number, role: ModeratedStudyMember["role"]) => void
  isDisabled: boolean
}

const RoleSelect: React.FC<RoleSelectProps> = ({
  member,
  setMemberRole,
  isDisabled,
}) => {
  type StudyRole = {
    value: ModeratedStudyMember["role"]
    title: string
    description: string
  }
  const studyRoles: StudyRole[] = [
    {
      value: "main_host",
      title: "Main host",
      description: "All communication will be sent from the main host",
    },
    {
      value: "co_host",
      title: "Co-host",
      description: "Co-hosts will be added as hosts on all new bookings",
    },
    // {
    //   value: "observer",
    //   title: "Observer",
    //   description: "Will be sent a separate invitation to watch the session",
    // },
  ]

  const assertNever = (role: never): never => {
    throw new Error(`Unsupported role: ${JSON.stringify(role)}`)
  }
  const humanizeRole = (role: ModeratedStudyMember["role"]) => {
    switch (role) {
      case "main_host":
        return "Main host"
      case "co_host":
        return "Co-host"
      case "observer":
        return "Observer"
      default:
        assertNever(role)
    }
  }

  return (
    <Flex h={ROW_HEIGHT} alignItems="center">
      <Menu>
        <MenuButton
          color="brand.neutral.default"
          as={Button}
          size="sm"
          w={40}
          textAlign="left"
          fontSize="md"
          rightIcon={<ChevronDownIcon />}
          aria-label={`Select this user${"\u2019"}s role in the study`}
          variant="outline"
          fontWeight="normal"
          isDisabled={isDisabled}
        >
          {humanizeRole(member.role)}
        </MenuButton>

        <MenuList overflow="hidden">
          {studyRoles.map((studyRole, index) => (
            <MenuItem
              key={studyRole.value}
              borderTopColor={index > 0 ? "gray.200" : undefined}
              borderTopWidth={index > 0 ? "1px" : undefined}
              onClick={() => {
                setMemberRole(member.id, studyRole.value)
              }}
              isDisabled={studyRole.value === member.role}
            >
              <VStack width="full" alignItems="start">
                <ChakraHeading
                  color="text.primary"
                  lineHeight={5}
                  fontWeight="medium"
                  fontSize={14}
                >
                  {studyRole.title}
                </ChakraHeading>
                <Text
                  color="text.secondary"
                  lineHeight={5}
                  fontWeight="medium"
                  fontSize={14}
                >
                  {studyRole.description}
                </Text>
              </VStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}

type SwitchHostWhenZoomEnabledModalProps = {
  handleClose: () => void
  handleSwitch: () => void
  newHost: ModeratedStudyMember
}

const SwitchHostWhenZoomEnabledModal: React.FC<
  SwitchHostWhenZoomEnabledModalProps
> = ({ handleClose, handleSwitch, newHost }) => {
  const currentUser = useCurrentUser()
  const newHostIsCurrentUser = currentUser?.id === newHost.id
  const newHostIsOnZoom = newHost.has_zoom_oauth_credentials

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Switch to main host?</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={2}>
              {newHostIsOnZoom || newHostIsCurrentUser ? (
                <Text>
                  This study uses Zoom for meeting links. By proceeding,{" "}
                  {newHostIsCurrentUser ? "you" : newHost.name} will become the
                  Zoom host for the future sessions.
                </Text>
              ) : (
                <Text>
                  {newHost.name} is not connected to Zoom. As this study uses
                  Zoom for meeting links, please contact {newHost.name} to log
                  in and connect their Zoom account.
                </Text>
              )}

              <Text>
                <strong>Note:</strong> Links for previously booked sessions
                remain unchanged.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              onClick={() => {
                handleSwitch()
                handleClose()
              }}
            >
              Confirm change
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
