import { ListItem, Text } from "@chakra-ui/react"
import React from "react"

import { SERVICES_AGREEMENT_DOCUMENT } from "Constants/legal-documents"
import {
  Document,
  DocumentIntro,
  DocumentSection,
  OrderedDocumentList,
} from "Shared/components/Document/Document"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { SupportMailtoLink } from "Shared/components/Links/SupportMailtoLink"
import { getDateString } from "Utilities/date-formats"

export const ServicesAgreementDocument = () => (
  <Document
    title="Services agreement"
    downloadUrl={SERVICES_AGREEMENT_DOCUMENT.downloadUrl}
  >
    <DocumentIntro>
      <Text>
        Version {SERVICES_AGREEMENT_DOCUMENT.currentVersion}, last updated on{" "}
        <strong>
          {getDateString(SERVICES_AGREEMENT_DOCUMENT.lastUpdated)}
        </strong>
        .
      </Text>
      <Text>
        This online platform (<strong>Platform</strong>) is operated by
        UsabilityHub Pty Ltd ACN 158 305 205 trading as Lyssna, or its
        successors and assignees (<strong>UsabilityHub</strong>,{" "}
        <strong>Lyssna</strong>, <strong>we</strong>, <strong>our</strong> or{" "}
        <strong>us</strong>). The Platform is available at{" "}
        <ExternalLink href="https://lyssna.com">lyssna.com</ExternalLink> (
        <strong>Site</strong>) and the websites of our sub-brands (
        <strong>Sub-brand Sites</strong>). The Sub-brand Sites are available at{" "}
        <ExternalLink href="https://www.usercrowd.com">
          usercrowd.com
        </ExternalLink>{" "}
        (<strong>UserCrowd</strong>) and{" "}
        <ExternalLink href="https://fivesecondtest.com">
          fivesecondtest.com
        </ExternalLink>{" "}
        (<strong>Five Second Test</strong>).
      </Text>
      <Text>
        The Platform connects businesses (<strong>Businesses</strong>,{" "}
        <strong>you</strong> or <strong>your</strong>) requiring factors,
        including the quality or usability, of the Businesses’ goods, services,
        website, or mobile application, to be reviewed by individuals sourced by
        Lyssna (<strong>Reviewing Services</strong>). Individuals may join and
        create accounts on the Site or Sub-brand Sites as members of our
        research panel, to review and provide feedback on a Business’ products
        or services available to be reviewed on the Site or Sub-brand Sites (
        <strong>Panel Participants</strong>). Businesses may also invite their
        own customers and clients to participate in a study, test, or interview
        to obtain their preferences and opinions (
        <strong>Company Sourced Participants</strong>).
      </Text>
    </DocumentIntro>
    <DocumentSection title="1. Acceptance">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          We own the cloud-based Platform and Sub-brand Sites, including any
          updates, modifications or releases of any part of that Platform which
          is accessible on the Site or Sub-brand Sites.
        </ListItem>
        <ListItem>
          This services agreement (<strong>Terms</strong>):
        </ListItem>
        <OrderedDocumentList>
          <ListItem>
            sets out the terms and conditions upon which we agree to grant you a
            right to use the Platform and Sub-brand Sites, as described on the
            Site or Sub-brand Sites (<strong>Platform Service</strong>) and
            provide any other services to you as set out in these Terms (the
            Platform Service and the Reviewing Service shall be known as the{" "}
            <strong>Services</strong>); and
          </ListItem>
          <ListItem>
            is binding on you from the date (<strong>Effective Date</strong>) on
            which we provide you with an account to enable you to access and use
            the Services (<strong>Account</strong>) until the date on which your
            Account is terminated in accordance with these Terms (
            <strong>Term</strong>).
          </ListItem>
        </OrderedDocumentList>
        <ListItem>
          Please read these Terms carefully and immediately terminate your
          Account if you do not agree to them.
        </ListItem>
        <ListItem>
          If the processing of any of your employees’ or Company Sourced
          Participants’ data (<strong>Company Personal Data</strong>) is
          governed by the General Data Protection Regulation 2016/679 (
          <strong>GDPR</strong>), the additional terms in our Data Processing
          Agreement (<strong>DPA</strong>) apply and form part of these Terms.
        </ListItem>
        <ListItem>
          By creating an Account and/or accessing and/or using the Services,
          you:
        </ListItem>
        <OrderedDocumentList>
          <ListItem>
            warrant to us that you have reviewed these Terms including our
            Privacy Policy, available on the Site, and you understand them;
          </ListItem>
          <ListItem>
            warrant to us that you have reviewed the DPA, if applicable
            (available on the Site), if the processing of any Company Personal
            Data is governed by the GDPR;
          </ListItem>
          <ListItem>
            warrant to us that you are over 18 years of age and have the legal
            capacity to enter into a legally binding agreement;
          </ListItem>
          <ListItem>
            warrant to us that you have the authority to act on behalf of any
            person or entity for whom you are using the Services, and you are
            deemed to have agreed to these Terms on behalf of any entity for
            whom you use the Services;
          </ListItem>
          <ListItem>
            warrant to us that you have all hardware, software, and services
            which are necessary to access and use the Services; and
          </ListItem>
          <ListItem>
            agree to use the Services in accordance with these Terms.
          </ListItem>
        </OrderedDocumentList>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="2. Personal Data">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You acknowledge and agree that:
          <OrderedDocumentList>
            <ListItem>
              in collecting, holding, and processing Company Personal Data
              through the Services, we are acting as the data processor for the
              purposes of the GDPR. Where the GDPR applies, you must obtain all
              necessary consents from the relevant individual to enable us to
              collect, use, hold, and process Company Personal Data in
              accordance with these Terms and, if applicable, the DPA; and
            </ListItem>
            <ListItem>
              in collecting, holding, and processing Panel Participant data we
              are acting as the data controller for the purposes of the GDPR.
              Where the GDPR applies we warrant that we have obtained all the
              necessary consents from the relevant Panel Participants who shall
              take part in the Reviewing Services to enable us to collect, use,
              hold, and process the Panel Participant’s personal data.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          Lyssna will comply with all GDPR and Australian data protection laws
          to the extent such laws by their terms impose obligations directly
          upon Lyssna in connection with its processing of personal data (as
          defined under the GDPR) under these Terms (and all related
          activities);
        </ListItem>
        <ListItem>
          Lyssna agrees that it shall:
          <OrderedDocumentList>
            <ListItem>
              process personal data only for the purposes of these Terms (and
              any other processing separately consented to by the data subject
              (as defined under the GDPR) from time to time);
            </ListItem>
            <ListItem>
              promptly assist you in complying with any data subject rights
              request under the GDPR that you may receive from any data subject;
            </ListItem>
            <ListItem>
              promptly assist you in complying with any duties to cooperate with
              supervising authorities under the GDPR;
            </ListItem>
            <ListItem>
              implement appropriate security measures reasonably designated to
              avoid accidental or unlawful destruction, accidental loss,
              alteration, unauthorized disclosure or access, and against all
              other unlawful forms of processing of personal data;
            </ListItem>
            <ListItem>
              provide notice of the processing to the data subject in accordance
              with applicable laws;
            </ListItem>
            <ListItem>
              if Lyssna processes the personal data for the purposes of direct
              marketing, Lyssna shall ensure that effective procedures are in
              place to allow the data subject to “opt-out” from having their
              personal data used for such direct marketing purposes and the
              appropriate consent has been obtained from the relevant data
              subject to allow the personal data to be used for the purposes of
              direct marketing in compliance with all applicable laws; and
            </ListItem>
            <ListItem>
              provide adequate training to its relevant staff and ensure such
              staff will carry out the security measures and comply with the
              obligations of Lyssna under these Terms.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="3. Registration and Account">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You will be required to create an Account with us in order to access
          and use the Services. You must ensure that any personal information
          you give us when creating an Account is accurate and up-to-date. All
          personal information that you give to us will be treated in accordance
          with these Terms, our Privacy Policy, and, if applicable, the DPA.
        </ListItem>
        <ListItem>
          When you create an Account, we will give you certain Account details
          (such as a username(s) and password(s)). You agree that we may alter
          or update these details at any time during the Term. It is your
          responsibility to keep your Account details confidential. You are
          liable for all content posted by you and all activity on your Account,
          including purchases made using your Account details and content posted
          by others who have logins or accounts associated with your Account,
          and you must immediately notify us of any unauthorized use of your
          Account.
        </ListItem>
        <ListItem>
          Businesses and Panel Participants may create Accounts on the Site or
          Sub-brand Sites. If you choose to create a study or test using our
          Services (<strong>User Research</strong>), then you may choose to
          receive responses:
          <OrderedDocumentList>
            <ListItem>from Panel Participants; and/or</ListItem>
            <ListItem>from Company Sourced Testers.</ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          At our sole discretion, we may refuse to allow any person to create an
          Account.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="4. Collection Notice">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          We may collect personal information about you in order to enable you
          to access and use the Site or Sub-brand Sites and its associated
          functionality, to contact and communicate with you, and for the other
          purposes set out in our Privacy Policy and, if applicable, the DPA.
        </ListItem>
        <ListItem>
          We may disclose that information to third party service providers who
          help us deliver our services (including information technology service
          providers, data storage, web-hosting and server providers,
          professional advisors, payment systems operators, and our business
          partners) or as required by law. If you do not provide this
          information we may not be able to provide the Services to you. We
          disclose your personal information to third parties located, or who
          store data, outside Australia.
        </ListItem>
        <ListItem>
          Our Privacy Policy and, if applicable, the DPA contains further
          information about: (i) how we store and use your personal information;
          (ii) how you can access and seek correction of your personal
          information; (iii) how you can make a privacy-related complaint; and
          (iv) our complaint handling process. By providing personal information
          to us, you consent to us, during the Term only, collecting, holding,
          using, and disclosing your personal information in accordance with our
          Privacy Policy and, if applicable, the DPA.
        </ListItem>
        <ListItem>
          Notwithstanding anything to the contrary in these Terms or elsewhere,
          we may monitor, analyze, and compile statistical and performance
          information based on and/or related to your use of the Services, in an
          aggregated and anonymized format (<strong>Analytics</strong>). For the
          avoidance of doubt, we acknowledge and agree the Analytics will not
          contain any of your Data. You agree that we may make such Analytics
          publicly available, provided that it: (i) does not contain identifying
          information i.e. it is strictly anonymized; and (ii) is not compiled
          using a sample size small enough to make the underlying data
          identifiable. We and/or our licensors own all rights, title, and
          interest in and to the Analytics and all related software, technology,
          documentation, and content provided in connection with the Analytics,
          including all intellectual property rights in the foregoing.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="5. Platform Licence">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          In consideration for payment of the fees, as set out on the Site (
          <strong>Fees</strong>), for your chosen subscription plan (
          <strong>Plan</strong>) or if you create an Account and use our
          Services through our ‘Free’ subscription plan, we grant you and your
          group companies, a worldwide, non-exclusive, royalty-free,
          non-transferable, non-sublicensable (except as otherwise permitted
          under these Terms) and revocable licence to access and use the
          Platform Service for business purposes for the Term (
          <strong>Platform Licence</strong>).
        </ListItem>
        <ListItem>
          You agree that:
          <OrderedDocumentList>
            <ListItem>
              the Platform Licence permits you to use the Platform Service in
              accordance with the Platform Service’s normal operating
              procedures;
            </ListItem>
            <ListItem>
              the Platform Licence permits you to access and use the Platform
              Service in accordance with the maximum test length and other
              limitations as set out in your Account or otherwise agreed by us;
            </ListItem>
            <ListItem>
              if the number of authorized team members is exceeded as set out in
              your Plan, or if you request to add authorized team members, we
              may charge a fee for each additional authorized team member as
              specified on the Site; and
            </ListItem>
            <ListItem>
              we reserve the right at any time and from time to time to: (i)
              refuse any request in relation to the Platform Service that we
              deem inappropriate, unreasonable, or illegal; and/or (ii) modify
              or discontinue, temporarily or permanently, access to the Platform
              Service (or any part thereof) with notice; and (iii) change or
              remove features of the Platform Service provided that, where there
              is any material alteration to the Platform Service in accordance
              with this clause, we will provide you with at least 20 business
              days’ prior notice in writing.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          The Platform Service includes functionality to notify you if you have
          exceeded the included features or other limits of your Plan.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="6. Restrictions">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You acknowledge and agree that these Terms incorporate by reference
          the terms of any acceptable use policy as set out on the Site or
          Sub-brand Sites or as provided to you from time to time.
        </ListItem>
        <ListItem>
          You must not (and must ensure the authorized team members do not)
          access or use the Platform Service except as permitted by the Platform
          License and you must not do, omit to do, or authorize any act that
          would or might invalidate or be inconsistent with our intellectual
          property rights in the Platform Service. Without limiting the
          foregoing provisions, you must not permit any other person to:
          <OrderedDocumentList>
            <ListItem>
              Re-sell, assign, transfer, distribute, or provide others with
              access to the Platform Service (except with our prior written
              permission);
            </ListItem>
            <ListItem>
              “frame”, “mirror” or serve any of the Platform Service on any web
              server or other computer server over the Internet or any other
              network;
            </ListItem>
            <ListItem>
              copy, alter, modify, create derivative works from, reproduce to a
              third party, reverse assemble, reverse engineer, reverse compile,
              or enhance the Platform Service (except as expressly permitted by
              the Copyright Act 1968 (Cth));
            </ListItem>
            <ListItem>
              alter, remove, or tamper with any trademarks, any patent or
              copyright notices, any confidentiality legend or notice, any
              numbers, or any other means of identification used on or in
              relation to the Platform Service;
            </ListItem>
            <ListItem>
              use the Platform Service in any way which is in breach of any
              applicable local, state, federal, and international laws and
              regulations (<strong>Laws</strong>) or which infringes any
              person's rights, including intellectual property rights;
            </ListItem>
            <ListItem>
              use the Platform Service in relation to crimes, including theft
              and fraud;
            </ListItem>
            <ListItem>
              use the Platform Service to transmit, publish, or communicate
              material that is defamatory, offensive, abusive, indecent,
              menacing, or unwanted, via User Research or otherwise;
            </ListItem>
            <ListItem>
              conduct User Research that is, or which contains material which is
              offensive, abusive, indecent, menacing, or unwanted;
            </ListItem>
            <ListItem>
              use the Platform Service in any way that damages, interferes with,
              or interrupts the supply of the Platform Service;
            </ListItem>
            <ListItem>
              introduce malicious programs into our hardware and software or our
              hardware, software, and services which are integrated and operate
              together, including our networks (<strong>Systems</strong>),
              including viruses, worms, trojan horses, and e-mail bombs;
            </ListItem>
            <ListItem>
              reveal your Account password to others or allow others to use your
              Account (other than authorized team members);
            </ListItem>
            <ListItem>
              use the Platform Service to make fraudulent offers of goods or
              services;
            </ListItem>
            <ListItem>
              use the Platform Service to carry out security breaches or
              disruptions of a network. Security breaches include accessing data
              where you are not the intended recipient or logging into a server
              or account that you are not expressly authorized to access or
              corrupting any data (including network sniffing/monitoring, pinged
              floods, packet spoofing, denial of service, and forged routing
              information for malicious purposes);
            </ListItem>
            <ListItem>
              use any program/script/command, or send messages of any kind, with
              the intent to interfere with, or disable, any person’s use of the
              Platform Service;
            </ListItem>
            <ListItem>
              send any unsolicited email messages through or to users of the
              Platform Service in breach of the Spam Act 2003 (Cth) or to send
              any form of harassment via email, or any other form of messaging,
              whether through language, frequency, or size of messages or use
              the Platform Service in breach of any person’s privacy (such as by
              way of identity theft or “phishing”);
            </ListItem>
            <ListItem>
              use the Platform Service to circumvent user authentication or
              security of any of your networks, accounts, or hosts or those of
              your customers or suppliers; or
            </ListItem>
            <ListItem>
              solicit or entice away, any person or organization that was our
              actual or prospective, client, employee, contractor,
              representative, agent, or developer during the Term.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="7. Support Services">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          During the Term, you have access to our help centre, available on the
          Site. You may also contact us at <SupportMailtoLink /> if you have any
          questions regarding our Services. We will endeavor to respond to you
          within a reasonable time.
        </ListItem>
        <ListItem>
          We may also provide you with additional support services, including
          appointing a dedicated account manager for your Account. Additional
          support services may be subject to the payment of additional fees; we
          will notify you of these fees in writing and if you accept in writing
          the support services and any additional fees then we will provide you
          with our invoice for additional support services, if required.
        </ListItem>
        <ListItem>
          We reserve the right to refuse any request for or in relation to
          support that we deem inappropriate, unreasonable, or illegal.
        </ListItem>
        <ListItem>
          Any support we provide to you will be provided to you on a
          non-exclusive basis.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="8. Your Data">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          <strong>You own your content</strong>: When you or your employees,
          contractors, officers or agents (<strong>Personnel</strong>) upload,
          submit or transmit information, surveys, documents, feedback and other
          data to the Platform Service (<strong>Data</strong>), you retain
          ownership of this Data and all intellectual property rights in your
          Data. We do not receive any licences or rights to use your Data except
          in accordance with these Terms. The clause below describes how we are
          permitted to use your Data. You own all intellectual property rights
          in the responses provided as part of the Reviewing Services by Testers
          and Company Sourced Testers.
        </ListItem>
        <ListItem>
          <strong>Licence</strong>: You grant us and our sub-processors a
          worldwide, non-exclusive, limited, irrevocable, royalty-free, fully
          paid, license to (i) use, copy, back up, process, transmit, store,
          edit, aggregate, or combine the Data and (ii) otherwise access, use or
          make reference to the Data or any intellectual property rights in the
          Data during the relevant Term:
          <OrderedDocumentList>
            <ListItem>
              to supply the Services (including enabling you, your Personnel and
              authorized team members to access and use the Platform Service and
              allow you to create a survey or interview via the Platform);
            </ListItem>
            <ListItem>for diagnostic purposes;</ListItem>
            <ListItem>
              to test, enhance and otherwise modify the Services (including to
              improve your user experience);
            </ListItem>
            <ListItem>
              as reasonably required for the performance of our obligations
              under these Terms; and
            </ListItem>
            <ListItem>
              where the Data is feedback on our Services, for our advertising
              and marketing purposes and to create new Services.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          The license described in this clause is for us to use your Data for
          the limited purposes described above and continues even after you stop
          using our Services, with respect to feedback relating directly to our
          Services, aggregate and anonymized personal information derived from
          your Data and any residual backup copies of your Data made in the
          ordinary course of running our business. The license in clause 8(b)
          also extends to any trusted third parties we work with to the extent
          necessary to provide the Services to you. Please note that if you ever
          provide us with any feedback, we may use your feedback without any
          obligation to you.
        </ListItem>
        <ListItem>
          <strong>General</strong>: You must, at all times, ensure the integrity
          of your Data and that your use of your Data is compliant with all
          Laws. You represent and warrant that: (i) you have obtained all
          necessary rights, releases, and permissions to provide all your Data
          to us and to grant the rights granted to us in these Terms; and (ii)
          your Data and its use by us as authorized by you under these Terms do
          not violate any applicable Laws (including those relating to export
          control and electronic communications) or rights of any third party,
          including any intellectual property rights, rights of privacy, or
          rights of publicity, and any use, collection, and disclosure
          authorized in these Terms is not inconsistent with the terms of any
          applicable privacy policies. We do not endorse and assume no
          responsibility or liability for your Data, and you shall be solely
          responsible for your Data and the consequences of using, disclosing,
          storing, or transmitting it.
        </ListItem>
        <ListItem>
          <strong>Removals</strong>: We have no obligation to monitor any
          content uploaded to the Platform Service. Nonetheless, if we deem such
          action necessary for any reason, we may (without limiting our other
          rights) remove your Data from the Platform Service. We have no
          liability to you for removing your Data from the Platform Service in
          accordance with this clause.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="9. Your Responsibilities and Obligations">
      <Text>You must, at your expense:</Text>
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          provide us with all materials and all reasonable assistance and
          cooperation in order for us to supply the Services in an efficient and
          timely manner, including obtaining from authorized team members any
          consent necessary to allow you and your personnel to engage in the
          activities described in these Terms and to allow us to provide the
          Services;
        </ListItem>
        <ListItem>
          ensure that only your personnel and authorized team members access and
          use the Platform Service and such use and access is in accordance with
          the terms and conditions of the Platform License;
        </ListItem>
        <ListItem>
          ensure that any third-party website to which Panel Participants are
          directed is secure and compliant with applicable data protection laws,
          provides clear information to Panel Participants about the purpose of
          the external website visit and what data will be collected, and
          promptly delete or anonymize any personal data collected once it is no
          longer needed;
        </ListItem>
        <ListItem>
          ensure all information provided to us is kept up-to-date and the email
          address you provide is valid and regularly checked; and
        </ListItem>
        <ListItem>
          make any changes to your Computing Environment, such as system
          upgrades, that may be required to support the delivery and operation
          of any Services.
        </ListItem>
        <Text>We warrant that throughout the Term that:</Text>
        <ListItem>
          we are properly constituted and have the right and authority to enter
          into this Agreement;
        </ListItem>
        <ListItem>
          we are responsible for obtaining rights, licenses, and permissions
          from third parties including, without limitation, Panel Participants
          to provide all Services and the Site as envisaged under this Agreement
          and in accordance with its terms;
        </ListItem>
        <ListItem>
          we will use the standard of diligence and care normally employed by
          duly qualified persons performing obligations similar to our
          obligations under these Terms;
        </ListItem>
        <ListItem>
          we will only use your Data in accordance with these Terms and we will
          procure that our Panel Participants use your Data only for the
          purposes of providing the Reviewing Services, during the Term; and
        </ListItem>
        <ListItem>
          we will provide the Services in accordance with all applicable laws
          and regulations including the GDPR where applicable.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="10. Payment">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You must pay us the Fee for your Plan and any other amount payable to
          us under these Terms, without set off or delay, via credit card or any
          other payment method set out on the Site.
        </ListItem>
        <ListItem>
          The Fee for your Plan is payable in advance of the next billing cycle
          for your Plan and any additional subscription charges will be billed
          in arrears at the end of the Plan cycle (unless otherwise agreed).
        </ListItem>
        <ListItem>
          You are responsible for reviewing the pricing schedule, features, and
          limits associated with your Plan, which are available on the Site. The
          Fee for your Plan is based on the Plan you select.
        </ListItem>
        <ListItem>
          To conduct User Research using the Services, where you wish to obtain
          responses from our Panel Participants, you must purchase a sufficient
          amount of credits on the Platform. The price in credits is set out on
          the Site. Any credits you purchase that you do not spend on conducting
          User Research will be retained on your Account as a balance available
          for future use.
        </ListItem>
        <ListItem>
          The number of credits required to perform User Research depends on the
          type and duration of your User Research.
        </ListItem>
        <ListItem>
          We may, in our sole discretion, cancel or nullify your credits if you
          attempt to access or obtain credits by manipulating the Platform, such
          as by creating multiple fake accounts and/or abusing any referral
          arrangements we have in place with users.
        </ListItem>
        <ListItem>
          The Fees are non-refundable. To the maximum extent permitted by law,
          there will be no refunds or credits for any unused Plan or credits (or
          part thereof), Plan downgrade, or unused Accounts. Downgrading your
          Plan may result in the loss of content, features, or capacity of your
          Account. We do not accept any liability for any losses or damages that
          may arise in such cases.
        </ListItem>
        <ListItem>
          If you downgrade your Plan, then the Fee will be prorated.
        </ListItem>
        <ListItem>
          If you upgrade your Plan, and you have a credit card linked to your
          Account this credit card will automatically be charged the Fee for
          your new Plan at the start of your next Plan billing cycle. Please
          ensure that this credit card has sufficient funds to pay the Fee. If
          your credit card expires and you do not cancel your Account, your
          Account will be placed in an automated dunning process and we will
          provide you with written notice of this. If no payment is received
          prior to the completion of the dunning period your subscription will
          automatically be canceled and your Account will revert to a free
          Account.
        </ListItem>
        <ListItem>
          If you upgrade your plan and you have negotiated a manual payment
          method with us we will issue an invoice with the new Fee for your
          Plan.
        </ListItem>
        <ListItem>
          You are responsible for all taxes, levies, or duties imposed by taxing
          authorities in your own country, and you shall be responsible for
          payment of them. We have no responsibility to them on your behalf.
        </ListItem>
        <ListItem>
          The Fee is subject to change upon 30 days’ written notice from us to
          you and will apply to the next Plan billing cycle. Such notice may be
          provided at any time by posting the changes on our Site, via email or
          via a notification to your Account. If you do not agree to the Fee
          change, you may cancel your Account in accordance with clause 16(a).
        </ListItem>
        <ListItem>
          If any payment is not made in accordance with our payment terms, we
          may (at our absolute discretion):
          <OrderedDocumentList>
            <ListItem>
              immediately cease providing the Services and recover as a debt due
              and immediately payable from you our additional costs of doing so;
            </ListItem>
            <ListItem>
              engage debt collection services and/or commence legal proceedings
              in relation to any unpaid amounts; and/or
            </ListItem>
            <ListItem>report you to independent credit data agencies.</ListItem>
          </OrderedDocumentList>
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="11. Intellectual Property Rights">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          All intellectual property (including copyright) in the Services,
          Platform, the Site and Sub-brand Sites developed, adapted, modified or
          created by us or our Personnel will at all times vest, or remain
          vested, in us.
        </ListItem>
        <ListItem>
          You must not, without our prior written consent:
          <OrderedDocumentList>
            <ListItem>
              copy or use, in whole or in part, any of our intellectual
              property;
            </ListItem>
            <ListItem>
              reproduce, retransmit, distribute, disseminate, sell, publish,
              broadcast or circulate any of our intellectual property to any
              third party; or
            </ListItem>
            <ListItem>
              breach any intellectual property rights connected with the Site,
              Sub-brand Sites or the Services, including (without limitation)
              altering or modifying any of our intellectual property; causing
              any of our intellectual property to be framed or embedded in
              another website (except where we expressly agree to this); or
              creating derivative works from any of our intellectual property.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          This clause 11 will survive termination of your Account.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="12. Exclusion of competitors">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You are prohibited from using our Platform, including the information
          and materials available on it (<strong>Content</strong>), in any way
          that competes with our business. If you breach this term, we will hold
          you responsible for any loss, damage or expense, howsoever arising,
          whether direct or indirect and/or whether present, unascertained,
          future or contingent (<strong>Liability</strong>) we suffer, and hold
          you accountable for any profits that you may make from non-permitted
          use.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="13. Indemnity and Liability">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          Despite anything to the contrary, to the maximum extent permitted by
          the law:
          <OrderedDocumentList>
            <ListItem>
              each party’s maximum aggregate Liability to the other party
              arising from or in connection with these Terms (including the
              Services or the subject matter of these Terms) will be limited to,
              and must not exceed the total amount of Fees you paid to us in the
              year in which the Liability arose or $1 if no such Fees have been
              paid; and
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          The parties will have no liability to the other party for any loss of
          profit (including anticipated profit), loss of revenue, loss of
          business, loss of goodwill, loss of opportunity, loss of savings
          (including anticipated savings), loss of reputation, loss of use
          and/or loss or corruption of data, whether under statute, contract,
          equity, tort (including negligence), indemnity or otherwise.
        </ListItem>
        <ListItem>
          Despite anything to the contrary, to the maximum extent permitted by
          the law, we have no Liability, and you release and discharge us from
          all Liability, arising from or in connection with any:
          <OrderedDocumentList>
            <ListItem>injury or loss to any person;</ListItem>
            <ListItem>failure or delay in providing the Services;</ListItem>
            <ListItem>
              unavailability, outage or interruption to the Services or your
              Systems; or
            </ListItem>
            <ListItem>breach of these Terms or any Laws,</ListItem>
            <ListItem>where caused or contributed to by any:</ListItem>
            <ListItem>
              event or circumstance beyond our reasonable control;
            </ListItem>
            <ListItem>
              a fault or defect in your Computing Environment;
            </ListItem>
            <ListItem>
              act or omission of you, your related parties, Personnel or a
              third-party service provider; or
            </ListItem>
            <ListItem>
              any violation of data protection laws in relation to panelists’
              data you collect or process.
            </ListItem>
          </OrderedDocumentList>
          and, in any event, any error, omission or lack of suitability (or the
          absence of, or reduction in, any anticipated result, outcome or
          benefit) with respect to the Services.
        </ListItem>
        <ListItem>
          Certain legislation including the Australian Consumer Law (
          <strong>ACL</strong>) in the Competition and Consumer Act 2010 (Cth),
          and similar consumer protection laws and regulations may confer you
          with rights, warranties, guarantees, and remedies relating to our
          provision of our services which cannot be excluded, restricted or
          modified (<strong>Statutory Rights</strong>). Nothing in these Terms
          attempts to exclude, restrict, or modify your Statutory Rights as a
          consumer under the ACL. Any and all other warranties or conditions
          which are not guaranteed by the ACL are expressly excluded where
          permitted, except to the extent such warranties and conditions are
          fully expressed in these Terms.
        </ListItem>
        <ListItem>
          You acknowledge and agree that:
          <OrderedDocumentList>
            <ListItem>
              you are responsible for all users using the Services associated
              with your Account, including your Personnel and any authorized
              team members;
            </ListItem>
            <ListItem>
              you use the Services and any associated programs and files at your
              own risk;
            </ListItem>
            <ListItem>
              we may use third-party service providers to provide the Platform.
              If the providers of third party applications or services cease to
              make their services or programs available on reasonable terms, we
              may cease providing any affected features without liability or
              entitling you to any refund, credit, or other compensation;
            </ListItem>
            <ListItem>
              we do not guarantee that using the Services or conducting User
              Research will help you improve your products or services, or that
              you will achieve measurable, actionable results from the User
              Research you conduct;
            </ListItem>
            <ListItem>
              you are responsible for conducting User Research and determining
              the aspects or qualities of your goods or services that you would
              like test – we are not responsible for conducting User Research on
              your behalf;
            </ListItem>
            <ListItem>
              the Platform Services may use third party products, facilities or
              services. We do not make any warranty or representation in respect
              of the third-party products, facilities or services other than
              where the GDPR and/or the DPA is applicable and as required in the
              DPA;
            </ListItem>
            <ListItem>
              we may relocate the Data to another jurisdiction in accordance
              with all applicable laws (including without limitation the GDPR),
              in which case we will give you 15 business days’ notice and use
              all reasonable endeavors to minimize the effect of such change on
              your access and use of the Services;
            </ListItem>
            <ListItem>
              we are not responsible for any corruption or loss of any Data if
              such corruption or loss is due to an act or omission by you, your
              Personnel, your related bodies corporate or any authorized team
              members;
            </ListItem>
            <ListItem>
              we are not responsible for the integrity or existence of any Data
              on the Computing Environment, network or any device controlled by
              you or your Personnel; and
            </ListItem>
            <ListItem>
              we may pursue any available equitable or other remedy against you
              if you breach any provision of these Terms.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          You must indemnify us and hold us harmless from and against any loss,
          cost, Liability or damage, howsoever arising, suffered or incurred by
          us and arising from or in connection with any claim relating to your
          Data.
        </ListItem>
        <ListItem>
          This clause 13 will survive termination of the Account.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="14. Panel Participant Responses">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          Please note that while most User Research studies receive their
          allocation of responses within 24 hours, response rates vary based on
          the number of responses ordered, targeted demographics, and site
          traffic. We cannot guarantee how long it will take to fulfill your
          order, though we do our best to provide a good estimate at the time
          the order for Panel Participant responses is placed.
        </ListItem>
        <ListItem>
          In progress orders (<strong>Incomplete Orders</strong>) may be
          canceled at any time prior to completion. If an Incomplete Order is
          canceled any responses not yet fulfilled will be refunded back on to
          your account as credits.
        </ListItem>
        <ListItem>
          We do our best to ensure that all Panel Participants submit a valid
          response, but this is not possible in all cases.
        </ListItem>
        <OrderedDocumentList>
          <ListItem>
            If you believe that you have received an invalid response from a
            Panel Participant to an unmoderated research study, you may delete a
            Panel Participant response using functionality on the Platform
            within 30 days of the order’s completion and the deleted response
            will be automatically replaced with a new response from a different
            Panel Participant.
          </ListItem>
          <ListItem>
            If you believe that you have received an invalid response from a
            Panel Participant to an interview research study, you may report the
            Panel Participant to us within 5 days of completion of the session
            to receive a refund or new response from a different Panel
            Participant. We reserve the right to review reports prior to
            providing a refund or replacement response.
          </ListItem>
        </OrderedDocumentList>
        <ListItem>
          You must not delete or report:
          <OrderedDocumentList>
            <ListItem>
              Panel Participant responses in order to groom or manipulate
              results or User Research data; or
            </ListItem>
            <ListItem>
              valid Panel Participant responses in order to receive other Panel
              Participant responses free of charge.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          We reserve the right to investigate any deletion or mass deletion of
          Panel Participant responses and we may, in our discretion, decide that
          a Panel Participant response should not be deleted.
        </ListItem>
        <ListItem>
          All responses ordered from us, using our Panel Participants, undergo a
          quality assurance process and a Panel Participant may be banned from
          testing if their responses have not met quality standards in the past.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="15. Confidentiality">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          <strong>Definition</strong>: The term Confidential Information means
          all trade secrets, know-how, inventions materials, developments,
          software, and other financial, business, or technical information that
          are disclosed by or for a party in relation to these Terms (including
          all copies, abstracts, summaries, analyses and derivatives thereof)
          and that are marked or otherwise identified as proprietary or
          confidential at the time of disclosure or that by their nature would
          be understood by a reasonable person to be proprietary or
          confidential. Confidential Information shall not include any
          information that the receiving party can demonstrate is (a) rightfully
          furnished to it without restriction by a third party without breach of
          any obligation to the disclosing party, (b) generally available to the
          public without breach of these Terms or (c) independently developed by
          it without reliance on such information.
        </ListItem>
        <ListItem>
          <strong>Confidentiality</strong>: Each party shall use reasonable care
          to keep the other's Confidential Information secret and, except for
          the specific rights granted by these Terms, neither party shall
          possess, access, use, reproduce, or disclose any of the other's
          Confidential Information without its prior written consent. The
          receiving party may disclose Confidential Information only to its
          employees and contractors who have a need to know for the permitted
          purpose and who are bound to safeguard the Confidential Information
          pursuant to obligations that are at least as protective as the
          restrictions in these Terms. Each party shall be responsible for any
          breach of confidentiality by its employees and contractors. Promptly
          after any termination of these Terms (or at the disclosing party's
          request at any other time), the receiving party shall return all of
          the other's tangible Confidential Information, permanently erase all
          Confidential Information from any storage media and destroy all
          information, records, and materials developed therefrom.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="16. Termination">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You may only terminate a paid Account by downgrading your Account to a
          free Account and you may do this at any time. You may delete your
          Account from the Account settings page on the Platform. No refunds
          will be given upon termination in accordance with this clause 16(a).
        </ListItem>
        <ListItem>
          We may, at any time and at our sole discretion, suspend or terminate
          your Account for any reason. You must ensure that all your Data on the
          Platform Service is backed up so that you do not lose your Data if we
          suspend or terminate your Account. We will generally alert you when we
          take such action and give you a reasonable opportunity to remedy any
          breach of these Terms, but if we determine that your actions endanger
          the operation of the Services or other users, we may suspend or
          terminate your Account immediately without notice. You will continue
          to be charged for the Services during any suspension period in which
          you are in breach of these Terms. If we suspend your Account and you
          are not in breach of these Terms, we will apply a credit to your
          Account for the Fees you have paid for any unused portion of your Plan
          to which the period of suspension relates. If we terminate your
          Account and you are not in breach of these Terms, we will refund you
          the Fees you have paid for any unused portion of your Plan. If we
          terminate your Account and you are in breach of these Terms, no
          refunds will be given.
        </ListItem>
        <ListItem>
          Where you have a credit card linked to your Account, your Plan will
          automatically renew at the end of the Plan period (and for the same
          Plan cycle) unless you terminate your Account in accordance with
          clause 16(a).
        </ListItem>
        <ListItem>
          Where you have negotiated a manual payment method with us we will send
          written notice 30 days before the end of your Plan period notifying
          you that your Plan is due to terminate. In these circumstances, your
          Plan will only be renewed with your written confirmation (including
          via email). If your Plan is not renewed, your Account will revert to a
          Free Account.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="17. General">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          <strong>Force Majeure</strong>: If a party (
          <strong>Affected Party</strong>) is unable to perform any of its
          obligations under these Terms due to an event or circumstance beyond
          its reasonable control (<strong>Force Majeure</strong>) and it gives
          the other party prompt written notice of such, the Affected Party’s
          obligations shall be suspended to the extent necessary. The Affected
          Party must use reasonable efforts to limit the impact of the event on
          its performance and must continue to perform its obligations in full
          as soon as the Force Majeure ceases.
        </ListItem>
        <ListItem>
          <strong>Publicity</strong>: You consent to us using advertising or
          publicly announcing that we have provided Services to you, including
          but not limited to mentioning you on our Site or Sub-brand Sites and
          in our promotional material. You may remove your consent by providing
          us with 30 days written notice via our contact details below and
          within 15 days of receiving the notice we will cease using you for
          advertising or in public announcements.
        </ListItem>
        <ListItem>
          <strong>Disputes</strong>: Neither party may commence court
          proceedings relating to any dispute arising from, or in connection
          with, these Terms without first meeting with a senior representative
          of the other party to seek (in good faith) to resolve that dispute
          (unless that party is seeking urgent interlocutory relief or the
          dispute relates to compliance with this provision).
        </ListItem>
        <ListItem>
          <strong>Notices</strong>: Any notice given under these Terms must be
          in writing and addressed to us at the details set out below or to you
          at the details provided when setting up your Account. Any notice may
          be sent by standard post or email, and will be deemed to have been
          served on the expiry of 48 hours in the case of post, or on the next
          business day in the case of email.
        </ListItem>
        <ListItem>
          <strong>Waiver</strong>: Any failure or delay by a party in exercising
          a power or right (either wholly or partly) in relation to these Terms
          does not operate as a waiver or prevent a party from exercising that
          power or right or any other power or right. A waiver must be in
          writing.
        </ListItem>
        <ListItem>
          <strong>Relationship of parties</strong>: These Terms are not intended
          to create a partnership, joint venture, or agency relationship between
          the parties.
        </ListItem>
        <ListItem>
          <strong>Severance</strong>: If a provision of these Terms is held to
          be void, invalid, illegal, or unenforceable, that provision is to be
          read down as narrowly as necessary to allow it to be valid or
          enforceable, failing which, that provision (or that part of that
          provision) will be severed from these Terms without affecting the
          validity or enforceability of the remainder of that provision or the
          other provisions.
        </ListItem>
        <ListItem>
          <strong>Assignment</strong>: You must not assign any rights or
          obligations under these Terms, whether in whole or in part, without
          our prior written consent.
        </ListItem>
        <ListItem>
          <strong>Entire agreement</strong>: These Terms contain the entire
          understanding and agreement between you and us in respect of their
          subject matter.
        </ListItem>
        <ListItem>
          <strong>Amendment</strong>: We may, at any time and at our discretion,
          vary these Terms by providing you with prior written notice and
          publishing varied terms on the Site. Such variation will take effect
          at the commencement of your next Plan billing cycle. Prior to the
          commencement of each Plan billing cycle, we recommend you carefully
          read the terms that are in effect at that time to ensure you
          understand and agree to them. If you reasonably believe that such
          variation/s are materially detrimental to your use of the Service,
          then you may terminate your Account with effect from the end of your
          then current Plan billing cycle.
        </ListItem>
        <ListItem>
          <strong>Governing law</strong>: These Terms are governed by the laws
          of Victoria. You irrevocably and unconditionally submit to the
          exclusive jurisdiction of the courts operating in Victoria and any
          courts entitled to hear appeals from those courts and waive any rights
          to object to proceedings being brought in those courts. The Services
          may be accessed in Australia and overseas. We make no representation
          that the Services complies with the laws (including intellectual
          property laws) of any country outside of Australia other than the GDPR
          where applicable. If you access the Services from outside Australia,
          you do so at your own risk and are responsible for complying with the
          laws in the place you access the Services.
        </ListItem>
      </OrderedDocumentList>
      <Text>For any questions or notices, please contact us at:</Text>
      <Text>UsabilityHub Pty Ltd ACN 158 305 205 trading as Lyssna</Text>
      <SupportMailtoLink />
    </DocumentSection>
  </Document>
)
