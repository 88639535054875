import { Box, Icon } from "@chakra-ui/react"
import classNames from "classnames"
import React, { CSSProperties, HTMLAttributes, ReactNode } from "react"

import styles from "Components/test-results/progress-box/progress-box.module.scss"
import { Dimensions, ImageScreenshot, Omit, Rectangle, Screenshot } from "Types"
import { url, percentage } from "Utilities/css-helpers"
import { aspectRatio, contain, toCss } from "Utilities/dimensions"
import { getDimensions, getHeight, getWidth } from "Utilities/rectangle"

import { AudioIcon } from "Icons/AudioIcon"
import {
  thumbnailContainerHeight,
  thumbnailContainerPadding,
  thumbnailContainerWidth,
} from "./constants"

const padding: number = thumbnailContainerPadding
export const thumbnailDimensions: Dimensions = {
  width: thumbnailContainerWidth - 2 * padding,
  height: thumbnailContainerHeight - 2 * padding,
}

function clipContainerStyle(
  sourceDimensions: Dimensions,
  rect: Rectangle
): CSSProperties {
  // Calculate aspect ratio of clipping rect (in normalized image co-ordinate space)
  const rectAspect = aspectRatio(getDimensions(rect))

  // Now calculate the actual aspect ratio of the rect in source image space.
  const clipAspect = aspectRatio(sourceDimensions) * rectAspect

  // Now resize the aspect so it's "contained" within the thumbnail, and convert
  // to pixels.
  return toCss(contain(clipAspect, thumbnailDimensions))
}

function clippedImageStyle(src: string, rect: Rectangle): CSSProperties {
  const width = 1 / getWidth(rect)
  const height = 1 / getHeight(rect)
  return {
    width: percentage(width),
    height: percentage(height),
    top: percentage(-rect.ya * height),
    left: percentage(-rect.xa * width),
    backgroundImage: url(src),
  }
}

interface ThumbnailProps
  extends Omit<HTMLAttributes<HTMLElement>, "styles" | "className"> {
  screenshot: Screenshot
  isExpanded?: boolean
}

export const Thumbnail: React.FC<React.PropsWithChildren<ThumbnailProps>> = ({
  screenshot,
  isExpanded,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        styles.thumbnailContainer,
        isExpanded === true && styles.isExpanded,
        isExpanded === false && styles.isCollapsed
      )}
    >
      {screenshot.media_type === "image" && (
        <img
          src={screenshot.thumbnail_url || ""}
          className={styles.thumbnail}
          {...rest}
          alt={screenshot.name}
        />
      )}
      {screenshot.media_type === "video" && (
        <video
          preload="metadata"
          src={screenshot._url || ""}
          className={styles.thumbnail}
          {...rest}
        />
      )}
      {screenshot.media_type === "audio" && (
        <Box {...rest}>
          {/* Audio doesn't have a screenshot */}
          <Icon as={AudioIcon} w={10} h={10} />
        </Box>
      )}
    </div>
  )
}

interface ClippedThumbnailProps {
  screenshot: ImageScreenshot
  clippingRectangle: Rectangle
  children?: ReactNode
}

export const ClippedThumbnail = (props: ClippedThumbnailProps) => {
  const { screenshot, clippingRectangle, children } = props
  return (
    <div className={styles.thumbnailContainer}>
      {screenshot._url !== null && (
        <div
          className={styles.clipContainer}
          style={clipContainerStyle(screenshot, clippingRectangle)}
        >
          <div
            className={styles.clippedImage}
            style={clippedImageStyle(screenshot._url, clippingRectangle)}
          />
          {children}
        </div>
      )}
    </div>
  )
}
