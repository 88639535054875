import {
  Avatar,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from "@chakra-ui/react"
import { AddIcon } from "Icons/AddIcon"
import React from "react"
import { Control, useFieldArray } from "react-hook-form"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"
import UserBadge from "./UserBadge"
import { BookingForm } from "./formSchema"

type HostEditorProps = {
  control: Control<BookingForm>
  teamMembers: TeamMember[]
  handleSave: () => void
  isReadOnly: boolean
}

const HostEditor: React.FC<HostEditorProps> = ({
  control,
  teamMembers: users,
  handleSave,
  isReadOnly,
}) => {
  const {
    fields: hosts,
    append: appendHost,
    remove: removeHost,
  } = useFieldArray({
    control,
    name: "hosts",
  })

  const missingHosts = users.filter((user) => {
    return !hosts.some((host) => host.userId === user.id)
  })

  return (
    <Flex gap={2}>
      {hosts.map((host, index) => {
        return (
          <UserBadge
            key={host.id}
            user={users.find((user) => user.id === host.userId)}
            handleRemove={
              !isReadOnly && hosts.length > 1
                ? () => {
                    removeHost(index)
                    handleSave()
                  }
                : undefined
            }
          />
        )
      })}

      {!isReadOnly && (
        <Menu isLazy>
          <MenuButton
            as={IconButton}
            variant="outline"
            icon={<Icon as={AddIcon} boxSize={5} color="gray.500" />}
            size="sm"
            aria-label="Add new Host"
            isDisabled={missingHosts.length === 0}
          />

          <Portal>
            {/* Each menu item is 3rem tall; this is just for readability */}
            <MenuList maxH={`${3 * 12}rem`} overflowY="auto" maxW="20rem">
              {missingHosts.map((user) => (
                <MenuItem
                  key={user.id}
                  onClick={() => {
                    appendHost({ userId: user.id })
                    handleSave()
                  }}
                >
                  <Avatar
                    size="sm"
                    boxSize={6}
                    fontSize="xs"
                    name={user.name}
                    src={user.avatar_url_medium}
                    me={2}
                  />
                  <Text noOfLines={1} wordBreak="break-all">
                    {user.name}
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      )}
    </Flex>
  )
}

export default HostEditor
