import {
  Box,
  Flex,
  FlexProps,
  Icon,
  Text,
  createStylesContext,
  useMultiStyleConfig,
} from "@chakra-ui/react"
import { CheckIcon } from "Icons/CheckIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { STUDY_STEPPER_HEIGHT } from "UsabilityHub/constants/layoutConstants"
import React from "react"

const [StylesProvider, useStyles] = createStylesContext("Steps")

type Steps = React.FC<React.PropsWithChildren<FlexProps>>

export const Steps: Steps = (props) => {
  const styles = useMultiStyleConfig("Steps", {})

  return (
    <StylesProvider value={styles}>
      <Flex
        as="nav"
        justify="center"
        // We want to be explicit that the height is for the study stepper bar,
        // but we don't want to use that constant in the steps.ts style config
        // because that would apply to all Chakra <Steps> usages.
        sx={{ ...styles.container, height: STUDY_STEPPER_HEIGHT }}
        {...props}
      />
    </StylesProvider>
  )
}

type Step = React.FC<
  React.PropsWithChildren<
    {
      label: string
      href?: string
      isDisabled?: boolean
      isActive?: boolean
      isComplete?: boolean
      count?: number
    } & FlexProps
  >
>

export const Step: Step = ({
  label,
  isDisabled = false,
  isActive = false,
  isComplete = false,
  count,
  ...props
}) => {
  const styles = useStyles()

  return (
    <Flex
      flex={[1, null, "initial"]}
      align="center"
      justify="center"
      aria-current={isActive ? "page" : undefined}
      data-disabled={isDisabled ? true : undefined}
      role="group"
      sx={styles.step}
      {...props}
    >
      {isComplete && (
        <Flex
          align="center"
          justify="center"
          data-component="number"
          sx={styles.stepIndicator}
        >
          <Icon as={CheckIcon} sx={styles.stepIcon} />
        </Flex>
      )}
      <Box position="relative">
        <Text position="absolute">{label}</Text>
        {/* This second copy of the label is just to keep the width consistent so
          that things don't jump around when the font weight changes */}
        <Text fontWeight="bold" visibility="hidden">
          {label}
        </Text>
      </Box>
      {count && <Text sx={styles.stepCount}>{count}</Text>}
    </Flex>
  )
}

export const StepsDivider = () => (
  <Flex alignItems="center">
    <ChevronRightIcon boxSize={4} color="ds.icon.subtle" />
  </Flex>
)
