import { Box, Flex, Text, TextProps } from "@chakra-ui/react"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { useTranslate } from "Shared/hooks/useTranslate"
import React, { PropsWithChildren } from "react"

export const InstructionsContainer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Flex
      direction="column"
      maxW="xl"
      transitionDuration="200ms"
      transitionProperty="font-size, font-weight, color"
    >
      {children}
    </Flex>
  )
}

export const TaskInstructions: React.FC<{
  hideHeader?: boolean
  children: string
}> = ({ children, hideHeader = false }) => {
  return (
    <Box pb={6}>
      {/* When displayed inside the modal, the header gets hoisted up to the top next to the modal controls */}
      {!hideHeader && <TaskInstructionsHeader mb={4} />}

      <Box textStyle="ds.heading.primary" color="ds.text.default">
        <DisplayBlockMarkdownText text={children} />
      </Box>
    </Box>
  )
}

export const TaskInstructionsHeader = (props: TextProps) => {
  const translate = useTranslate()

  return (
    <Text textStyle="ds.heading.secondary" color="ds.text.default" {...props}>
      {translate("test.instructions.prototype_task.task_instructions_header")}
    </Text>
  )
}

export const SystemInstructions: React.FC<{ children: string }> = ({
  children,
}) => {
  const translate = useTranslate()

  return (
    <Box pt={8}>
      <Text textStyle="ds.heading.secondary" color="ds.text.default" mb={4}>
        {translate(
          "test.instructions.prototype_task.system_instruction_header"
        )}
      </Text>

      <Box textStyle="ds.paragraph.primary" color="ds.text.default">
        <DisplayBlockMarkdownText text={children} />
      </Box>
    </Box>
  )
}
