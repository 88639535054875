import { Flex } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import React from "react"

type Props = {
  options: { value: string; label: string }[]
  value: string
  onChange: (value: string) => void
}

export const ButtonSelect: React.FC<Props> = ({ options, value, onChange }) => {
  return (
    <Flex w="full" justify="space-evenly">
      {options.map((o) => (
        <Button
          w="full"
          h="52px"
          variant="default"
          isSelected={o.value === value}
          key={o.value}
          value={o.value}
          onClick={() => onChange(o.value)}
          bg="transparent"
          borderColor="ds.border.default"
          borderWidth="1px 1px 1px 0"
          borderRadius="0"
          _first={{
            borderWidth: "1px 1px 1px 1px",
            borderStartRadius: "6px",
          }}
          _last={{ borderEndRadius: "6px" }}
          sx={{
            fontVariantNumeric: "tabular-nums",
          }}
        >
          {o.label}
        </Button>
      ))}
    </Flex>
  )
}
