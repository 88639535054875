import { Icon, Tooltip } from "@chakra-ui/react"
import React from "react"

import { MessageIcon } from "Icons/MessageIcon"

type AdminNotesIndicator = React.FC<{
  hasNote: boolean
}>

export const AdminNotesIndicator: AdminNotesIndicator = ({ hasNote }) => {
  return (
    <>
      {hasNote ? (
        <Tooltip label="Has notes" hasArrow placement="top" fontSize="xs">
          <Icon as={MessageIcon} />
        </Tooltip>
      ) : null}
    </>
  )
}
