import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from "@chakra-ui/react"
import { IconButton } from "DesignSystem/components"
import { DisableRecruitmentFilledIcon } from "Icons/DisableRecruitmentFilledIcon"
import { LinkIcon } from "Icons/LinkIcon"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { PlayCircleFilledIcon } from "Icons/PlayCircleFilledIcon"
import { SettingsSlidersIcon } from "Icons/SettingsSlidersIcon"
import { ShowIcon } from "Icons/ShowIcon"
import { StatusDotIcon } from "Icons/StatusDotIcon"
import {
  RecruitmentLinkStateAsString,
  getLinkStateAsString,
} from "JavaScripts/types/recruitment-linkable"
import { RecruitmentLinkCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { useCopyTextWithToast } from "Shared/hooks/useCopyTextWithToast"
import { ExtractFromUnion } from "Shared/types/ExtractFromUnion"
import { RecruitmentLinkState } from "Types"
import { Beacon } from "UsabilityHub/components/Beacon"
import Created from "UsabilityHub/components/Created"
import { ConfirmDisableLinkModal } from "UsabilityHub/components/RecruitmentLink/ConfirmDisableLinkModal"
import { usePersistLinkChanges } from "UsabilityHub/components/RecruitmentLink/persistLinkChanges"
import { CanceledOrCompleteLabel } from "UsabilityHub/components/recruitment-summary-card/CanceledOrCompleteLabel"
import { RecruitmentSummaryCard } from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCard"
import {
  RecruitmentSummaryCardManageButton,
  RecruitmentSummaryCardManageButtonProps,
} from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCardManageButton"
import { recruitmentLinkStateWithRecruitmentLink } from "Utilities/recruitment-linkable"
import { pluralize } from "Utilities/string"
import React, { Fragment, useState } from "react"
import type { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import previewsApi from "~/api/previewsApi"

type IdProps =
  | {
      usabilityTestId: string
      testSetId?: never
    }
  | {
      testSetId: string
      usabilityTestId?: never
    }

type Props = IdProps & {
  recruitedResponseCount: number
  recruitmentLink: RecruitmentLink | null
  onManageLink?: () => void
}

export const UsabilityTestRecruitmentLinkSummaryCard: React.FC<Props> = ({
  usabilityTestId,
  testSetId,
  recruitedResponseCount,
  recruitmentLink,
  onManageLink,
}) => {
  const toast = useToast()
  const copyText = useCopyTextWithToast()
  const [showDisableConfirmModal, setShowDisableConfirmModal] = useState(false)
  const persistLinkChanges = usePersistLinkChanges(
    usabilityTestId
      ? {
          usability_test_unique_id: usabilityTestId,
        }
      : testSetId
        ? { test_set_unique_id: testSetId }
        : {}
  )

  const linkState = !recruitmentLink
    ? RecruitmentLinkState.Disabled
    : recruitmentLinkStateWithRecruitmentLink(
        recruitedResponseCount,
        recruitmentLink
      )

  if (
    recruitmentLink === null ||
    (!recruitmentLink.active && recruitedResponseCount === 0)
  )
    return null

  const linkStateAsString = getLinkStateAsString(linkState)

  const updatedSuccessMessage = () => {
    toast({
      status: "success",
      title: `Recruitment link updated`,
      isClosable: false,
    })
  }

  const handleDisableLink = async (confirmed: boolean) => {
    setShowDisableConfirmModal(false)

    if (!confirmed) return

    await persistLinkChanges({ active: false })
    updatedSuccessMessage()
  }

  const handleCopyLink = () => {
    if (!recruitmentLink?.url) return
    copyText({
      text: recruitmentLink.url,
      toast: {
        title: "Link copied to clipboard",
        status: "success",
        duration: 3000,
      },
    })
  }

  const manageButtonActions: Record<
    ExtractFromUnion<RecruitmentLinkStateAsString, "collecting" | "disabled">,
    (() => void) | undefined
  > = {
    collecting: () => setShowDisableConfirmModal(true),
    disabled: onManageLink,
  }

  return (
    <Fragment>
      <RecruitmentSummaryCard
        icon={<RecruitmentLinkCompositeIcon size={12} />}
        title="Recruitment link"
        manage={
          <Flex gap={1}>
            {linkStateAsString === "collecting" ||
            linkStateAsString === "disabled" ? (
              <RecruitmentSummaryCardManageButton
                {...getManageButtonProps(
                  linkStateAsString,
                  manageButtonActions[linkStateAsString]
                )}
              />
            ) : (
              <CanceledOrCompleteLabel state={linkStateAsString} />
            )}
            {linkStateAsString === "collecting" && (
              <IconButton
                aria-label="link"
                icon={<LinkIcon />}
                onClick={handleCopyLink}
              />
            )}
          </Flex>
        }
        metrics={
          <>
            {recruitedResponseCount}
            {recruitmentLink.enable_response_limit
              ? ` of ${recruitmentLink.response_limit!} `
              : " "}
            {pluralize(
              recruitmentLink.enable_response_limit
                ? recruitmentLink.response_limit!
                : recruitedResponseCount,
              "response",
              "responses"
            )}
          </>
        }
        profile={
          <Created
            createdAt={recruitmentLink.created_at ?? ""}
            name={recruitmentLink.creator?.name ?? ""}
            email={recruitmentLink.creator?.email}
            // TODO: in future serialize avatars into recruitmentLink.creator and pass it here
            avatarUrl=""
          />
        }
        menu={
          <Menu autoSelect={false}>
            <MenuButton
              as={IconButton}
              variant="secondary"
              icon={<MenuKebabIcon />}
            ></MenuButton>
            <Portal>
              <MenuList
                rounded="8px"
                border="none"
                shadow="ds.overlay"
                zIndex="dropdown"
              >
                <MenuItem
                  px={4}
                  rounded="8px"
                  as="a"
                  href={previewsApi.test.path({
                    unique_id: usabilityTestId,
                  })}
                  target="_blank"
                >
                  <Flex gap={2}>
                    <ShowIcon boxSize={5} />
                    Preview
                  </Flex>
                </MenuItem>
                <MenuItem px={4} onClick={handleCopyLink}>
                  <Flex gap={2}>
                    <LinkIcon boxSize={5} />
                    Copy link
                  </Flex>
                </MenuItem>
                <MenuItem px={4} rounded="8px" onClick={onManageLink}>
                  <Flex gap={2}>
                    <SettingsSlidersIcon boxSize={5} />
                    Manage
                  </Flex>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        }
      />
      {showDisableConfirmModal && (
        <ConfirmDisableLinkModal onClose={handleDisableLink} />
      )}
    </Fragment>
  )
}

const getManageButtonProps = (
  state: ExtractFromUnion<
    RecruitmentLinkStateAsString,
    "collecting" | "disabled"
  >,
  action: (() => void) | undefined
): RecruitmentSummaryCardManageButtonProps => {
  const manageButtonStates: Record<
    string,
    RecruitmentSummaryCardManageButtonProps
  > = {
    collecting: {
      buttonLabel: "Link enabled",
      buttonIcon: <Beacon colorScheme="green" />,
      menuItems: [
        {
          text: "Disable link",
          icon: (
            <DisableRecruitmentFilledIcon
              boxSize="1.125rem"
              color="ds.icon.danger"
            />
          ),
          onClick: action,
        },
      ],
    },
    disabled: {
      buttonLabel: "Link disabled",
      buttonIcon: <StatusDotIcon color="ds.icon.disabled" />,
      menuItems: [
        {
          text: "Enable link",
          icon: (
            <PlayCircleFilledIcon boxSize="1.125rem" color="ds.icon.success" />
          ),
          onClick: action,
        },
      ],
    },
  }

  return manageButtonStates[state]
}
