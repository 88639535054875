import { ComponentWithAs, Flex, Icon, IconProps, Text } from "@chakra-ui/react"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import React, { PropsWithChildren } from "react"

const RequirementList: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex flexDirection="column" gap={2}>
      {children}
    </Flex>
  )
}

type RequirementItemProps = {
  icon: ComponentWithAs<"svg", IconProps>
  heading: string
  description?: string
  isReady?: boolean
}

const RequirementItem: React.FC<RequirementItemProps> = ({
  icon,
  heading,
  description,
  isReady,
}) => (
  <Flex
    py={3}
    px={4}
    gap={4}
    borderRadius="12px"
    border="1px solid"
    borderColor="ds.border.default"
    color="ds.text.default"
    alignItems="center"
  >
    <Icon as={icon} textColor="ds.icon.subtle" boxSize={6} />
    <Flex direction="column" flexBasis="100%" gap={1}>
      <Text textStyle="ds.heading.primary">{heading}</Text>
      {description && (
        <Text textStyle="ds.paragraph.primary">{description}</Text>
      )}
    </Flex>
    {isReady === undefined ? null : isReady === true ? (
      <Icon
        as={CheckCircleFilledIcon}
        textColor="ds.icon.success"
        boxSize={5}
      />
    ) : (
      <Icon as={RemoveFilledIcon} textColor="ds.icon.danger" boxSize={5} />
    )}
  </Flex>
)

export const Requirements = {
  Root: RequirementList,
  Item: RequirementItem,
}
