import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CreditsIcon = createIcon({
  displayName: "CreditsIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="credit">
        <path
          id="credit_2"
          d="M10.269 1.02301C11.0457 1.02689 11.9927 1.58263 12.3844 2.26429L14.7045 6.30278C15.0962 6.98445 15.0988 8.09122 14.7105 8.77484L12.4415 12.7692C12.0532 13.4529 11.1088 14.0039 10.3321 14L5.73097 13.977C4.95434 13.9731 4.00728 13.4174 3.61565 12.7357L1.29548 8.69724C0.903854 8.01557 0.901177 6.9088 1.2895 6.22518L3.55851 2.23078C3.94683 1.54717 4.89123 0.99614 5.66786 1.00002L10.269 1.02301Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
