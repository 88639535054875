import { HStack, Icon, Text } from "@chakra-ui/react"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import React from "react"
import { FieldArrayMetaProps, WrappedFieldMetaProps } from "redux-form"

export const FormFieldError: React.FC<
  React.PropsWithChildren<WrappedFieldMetaProps | FieldArrayMetaProps>
> = ({ invalid, error, submitFailed }) => {
  if (invalid && submitFailed) {
    return (
      <HStack py={2} spacing={1} color="red.500">
        <Icon boxSize={5} as={WarningFilledIcon} />
        <Text fontSize="sm" fontWeight="500">
          {error}
        </Text>
      </HStack>
    )
  }
  return null
}
