import { LyssnaOrderResultDataProvider } from "UsabilityHub/components/ResultsDataProvider/LyssnaOrderResultDataProvider"
import { ThirdPartyOrderResultDataProvider } from "UsabilityHub/components/ResultsDataProvider/ThirdPartyOrderResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React, { ComponentProps } from "react"
import { OrderResponsesReview } from "./OrderResponsesReview"

// This wrapper is needed because we use mount_react to render AdminOrderReview directly
// so it doesn't have access to the context providers from the SPA.
export const OrderResponsesReviewWrapper: React.FC<
  ComponentProps<typeof OrderResponsesReview>
> = ({ orderId, revising, isThirdPartyOrder }) => {
  return (
    <ModalContextProvider>
      {isThirdPartyOrder ? (
        <ThirdPartyOrderResultDataProvider
          orderId={orderId}
          revising={revising}
        >
          <OrderResponsesReview
            orderId={orderId}
            revising={revising}
            isThirdPartyOrder={!!isThirdPartyOrder}
          />
        </ThirdPartyOrderResultDataProvider>
      ) : (
        <LyssnaOrderResultDataProvider orderId={orderId} revising={revising}>
          <OrderResponsesReview
            orderId={orderId}
            revising={revising}
            isThirdPartyOrder={!!isThirdPartyOrder}
          />
        </LyssnaOrderResultDataProvider>
      )}
    </ModalContextProvider>
  )
}
