import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { Button, IconButton } from "DesignSystem/components"
import { CloseIcon } from "Icons/CloseIcon"
import { AppliedFilter as AppliedFilterType, FilterComparator } from "Types"
import React from "react"
import { useFilterContext } from "./FilterContext"
import { FilterMenuMulti } from "./FilterMenuMulti"
import { FilterMenuSingle } from "./FilterMenuSingle"
import { assertNever } from "./utils"

interface Props {
  entityBeingFiltered: string
  filter: AppliedFilterType
  onEditFreeTextFilter: (filter: AppliedFilterType) => void
}

export const AppliedFilter: React.FC<Props> = ({
  entityBeingFiltered,
  filter,
  onEditFreeTextFilter,
}) => {
  const { changeFilter, removeFilter } = useFilterContext()

  // There are some comparators that only make sense for type=multi filters when
  // multiple options are selected.
  const validComparators =
    filter.options.type === "multi" && filter.options.choices.length > 1
      ? filter.attribute.possibleComparators
      : filter.attribute.possibleComparators.filter((c) => !c.onlyShowForMulti)

  return (
    <Flex
      pos="relative" // So that FilterMenuMulti's pos=absolute menu can be positioned here
      h={6}
      align="stretch"
      rounded={4}
      maxW="full"
      bgColor="ds.background.neutral.resting"
      sx={{
        "[data-segment]": {
          borderRight: "1px solid",
          borderColor: "ds.border.default",
          borderRadius: "0",

          "&:first-child": {
            borderLeftRadius: "inherit",
          },

          "&:last-child": {
            borderRight: "0",
            borderRightRadius: "inherit",
          },

          "&[aria-expanded='true']": {
            "&, &:hover, &:active": {
              bgColor: "ds.background.selected.subtle.resting",
              color: "ds.text.selected",
            },
          },
        },
        "[data-segment='filter-attribute']": {
          color: "ds.text.subtle",
        },
        "[data-segment='comparator']": {
          color: "ds.text.default",

          "&:hover, &:active": {
            color: "ds.text.default",
          },
        },
        // We want filter values to truncate if they get too long
        "[data-segment='filter-value']": {
          py: 0,
          minW: 0,

          span: {
            minW: 0,
            lineHeight: "1.5rem", // otherwise overflow clips descenders

            "&:has(span)": {
              display: "contents",
            },
          },

          "&, &:hover, &:active": {
            color: "ds.text.default",
          },
        },
        ".chakra-button[aria-label^='Remove']": {
          color: "ds.icon.default",
          "&, &:hover, &:focus, &:active": {
            background: "transparent",
          },
        },
        "&:has([aria-label^='Remove']:where(:hover, :focus))": {
          backgroundColor: "ds.background.danger.subtle.hovered",
        },
        "&:has([aria-label^='Remove']:where(:active))": {
          backgroundColor: "ds.background.danger.subtle.pressed",
        },
        "&:has([aria-label^='Remove']:where(:hover, :focus, :active))": {
          "[data-segment]": {
            backgroundColor: "transparent",
            color: "ds.text.danger",
          },
          ".chakra-icon": {
            color: "ds.icon.danger",
          },
        },
      }}
    >
      <Flex align="center" px={1.5} data-segment="filter-attribute">
        <Text as="span" textStyle="ds.interface.medium">
          {filterAttributeLabel(filter, entityBeingFiltered)}
        </Text>
      </Flex>
      <Menu>
        <MenuButton
          as={Button}
          size="compact"
          variant="subtle"
          data-segment="comparator"
        >
          <Text as="span" textStyle="ds.interface.medium">
            {filterComparatorLabel(filter)}
          </Text>
        </MenuButton>
        <MenuList overflow="hidden" zIndex="dropdown">
          {validComparators.map((comparator) => (
            <MenuItem
              key={comparator.value}
              onClick={() =>
                changeFilter(filter.id, comparator, filter.options)
              }
            >
              {comparator.icon}
              <Text ms={comparator.icon ? 1.5 : 0}>
                {filterComparatorLabel(filter, comparator)}
              </Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {filter.options.type === "single" &&
      filter.attribute.possibleOptions.type === "single" ? (
        <Menu isLazy autoSelect={false}>
          <MenuButton
            as={Button}
            size="compact"
            variant="subtle"
            data-segment="filter-value"
          >
            <Text as="span" textStyle="ds.interface.medium" isTruncated>
              {filterOptionLabel(filter)}
            </Text>
          </MenuButton>
          <MenuList>
            <FilterMenuSingle
              appliedFilter={filter}
              selectedAttribute={filter.attribute}
            />
          </MenuList>
        </Menu>
      ) : filter.options.type === "multi" &&
        filter.attribute.possibleOptions.type === "multi" ? (
        <Menu isLazy autoSelect={false} closeOnSelect={false}>
          <MenuButton
            as={Button}
            size="compact"
            variant="subtle"
            data-segment="filter-value"
          >
            <Text as="span" textStyle="ds.interface.medium" isTruncated>
              {filterOptionLabel(filter)}
            </Text>
          </MenuButton>
          <MenuList>
            <FilterMenuMulti
              appliedFilter={filter}
              selectedAttribute={filter.attribute}
            />
          </MenuList>
        </Menu>
      ) : filter.options.type === "freeText" ? (
        <Button
          size="compact"
          variant="subtle"
          data-segment="filter-value"
          minW={0}
          onClick={() => onEditFreeTextFilter(filter)}
        >
          <Text as="span" textStyle="ds.interface.medium" isTruncated>
            {filterOptionLabel(filter)}
          </Text>
        </Button>
      ) : (
        <Flex align="center" data-segment="filter-value" px={1.5}>
          <Text as="span" textStyle="ds.interface.medium" isTruncated>
            {filterOptionLabel(filter)}
          </Text>
        </Flex>
      )}

      <IconButton
        variant="subtle"
        size="compact"
        icon={<Icon as={CloseIcon} />}
        aria-label="Remove filter"
        onClick={() => removeFilter(filter.id)}
        borderLeftRadius={0}
        flexShrink={0}
      />
    </Flex>
  )
}

const filterAttributeLabel = (
  filter: AppliedFilterType,
  entityBeingFiltered: string
) => {
  const filterType = filter.options.type

  if (filterType === "static") {
    return entityBeingFiltered
  } else if (
    filterType === "single" ||
    filterType === "multi" ||
    filterType === "freeText" ||
    filterType === "static"
  ) {
    return filter.attribute.label
  } else {
    assertNever(filter.options)
  }
}

const filterComparatorLabel = (
  filter: AppliedFilterType,
  comparator: FilterComparator = filter.comparator
) => {
  const filterType = filter.options.type

  if (filterType === "multi" && filter.options.choices.length > 1) {
    return comparator.labelMulti
  } else if (
    filterType === "single" ||
    filterType === "multi" ||
    filterType === "freeText" ||
    filterType === "static"
  ) {
    return comparator.labelSingle
  } else {
    assertNever(filter.options)
  }
}

const filterOptionLabel = (filter: AppliedFilterType) => {
  if (filter.options.type === "static") {
    return filter.attribute.label
  } else if (filter.options.type === "freeText") {
    return filter.options.value
  } else if (filter.options.type === "single") {
    return filter.options.choice.label
  } else if (filter.options.type === "multi") {
    const plural =
      filter.attribute.possibleOptions.type === "multi"
        ? filter.attribute.possibleOptions.pluralEntity
        : "options"

    return filter.options.choices.length !== 1
      ? `${filter.options.choices.length} ${plural}`
      : filter.options.choices[0].label
  } else {
    assertNever(filter.options)
  }
}
