import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@chakra-ui/react"
import { Alert } from "DesignSystem/components/Alert"
import { useLanguages } from "Hooks/use-languages"
import {
  getFormName,
  getFormValue,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { getId } from "Redux/reducers/test-builder/selectors/test-form"
import { usePermittedDeviceTypeRules } from "Shared/hooks/testForm/usePermittedDeviceTypeRules"
import { useTranslate } from "Shared/hooks/useTranslate"
import { PERMITTED_DEVICE_TYPES, UsabilityTestSection } from "Types"
import { useIsTestBuilderExternalStudy } from "UsabilityHub/hooks/useIsTestBuilderExternalStudy"
import { getFullSectionTitle } from "Utilities/get-full-section-title"
import { useModal } from "Utilities/modals/use-modal"
import { map } from "lodash"
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Field, WrappedFieldProps, change } from "redux-form"
import { useUsabilityTestUserActivityContext } from "../../UsabilityTestUserActivityContext"
import { TestInstructionsChangeLanguageModal } from "../SectionCards/SectionFields/TestInstructionsChangeLanguageModal"

// For each field we define the FormControl which gets redux-form’s
// WrappedFieldProps inserted by the Field component, which we pass
// to the underlying form element
const TestNameFormControl = ({ input }: WrappedFieldProps) => {
  const { readOnly } = useUsabilityTestUserActivityContext()
  const usabilityTestId = useSelector(getId)!
  const isNewTest = !usabilityTestId

  return (
    <FormControl id="name">
      <FormLabel>Test name</FormLabel>
      <Input
        autoFocus={isNewTest}
        isReadOnly={readOnly}
        placeholder="Enter test name"
        required
        {...input}
      />
    </FormControl>
  )
}

const TestLanguageFormControl = ({ input }: WrappedFieldProps) => {
  const languages = useLanguages()
  const dispatch = useDispatch()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const isExternal = useIsTestBuilderExternalStudy()
  const sections = useSelector(
    getFormValue("sections")
  ) as UsabilityTestSection[]
  const anyTestInstructionsCustomized = sections.some(
    (section) => !!section.test_instructions
  )

  const { open: openChangeLanguageModal } = useModal(
    TestInstructionsChangeLanguageModal
  )

  const checkForCustomizedTestInstructions = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // If no test instructions are customized, we can just change the language immediately
    if (!anyTestInstructionsCustomized) {
      input.onChange(e)
      return
    }

    // Otherwise, show a confirmation modal
    const newLanguageCode = e.target.value

    const sectionsWithIndex = sections.map((section, index) => ({
      ...section,
      index,
    }))
    const customisedSectionNames = sectionsWithIndex
      .filter(({ test_instructions }) => !!test_instructions)
      .map((section) => getFullSectionTitle(section, section.index))

    openChangeLanguageModal({
      handleChangeLanguage: () => {
        dispatch(change(getFormName(), "language_code", newLanguageCode))
      },
      sectionNames: customisedSectionNames,
    })
  }

  return (
    <FormControl id="language_code">
      <FormLabel>Language</FormLabel>
      <Tooltip
        hasArrow
        placement="top"
        isDisabled={!isExternal}
        label="We are limiting external studies to English for the Alpha"
      >
        <Select
          isRequired
          isDisabled={readOnly || isExternal}
          {...input}
          onChange={checkForCustomizedTestInstructions}
        >
          {languages.map(({ display_string, code }) => (
            <option key={code} value={code}>
              {display_string}
            </option>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  )
}

const TestPermittedDeviceTypeFormControl = ({ input }: WrappedFieldProps) => {
  const translate = useTranslate()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const permittedDeviceTypes = map(PERMITTED_DEVICE_TYPES, (deviceType) => ({
    label: translate(`permitted_device_type.${deviceType}`),
    value: deviceType,
  }))

  const rules = usePermittedDeviceTypeRules()
  if (
    rules.permittedDeviceType !== "any" &&
    input.value !== rules.permittedDeviceType
  ) {
    input.onChange(rules.permittedDeviceType)
  }

  const mobileEnabled = useMemo(
    () => input.value !== "desktop_only",
    [input.value]
  )

  return (
    <FormControl id="permitted_device_type">
      <FormLabel>Allow participation on</FormLabel>
      <RadioGroup {...input}>
        <HStack>
          {permittedDeviceTypes.map(({ label, value }) => (
            <Radio
              key={value}
              value={value}
              isDisabled={readOnly || rules.permittedDeviceType !== "any"}
              flexGrow={1}
            >
              {label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
      {rules.screenRecordingEnabled && mobileEnabled && (
        <Alert
          mt={3}
          status="warning"
          description={`You\u2019ve allowed participation on mobile devices for this test.
          Please note that mobile responses won\u2019t include screen recordings.`}
        />
      )}
      {rules.liveWebsiteTestEnabled &&
        rules.permittedDeviceType === "desktop_only" && (
          <Alert
            mt={3}
            status="info"
            description="Live website testing is only supported on desktop browsers. Remove section to test on other devices."
          />
        )}
    </FormControl>
  )
}

// Export each field individually, this should make it easier to
// migrate away from redux-form in the future
export const TestNameField: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Field name="name" component={TestNameFormControl} />
)
export const TestLanguageField: React.FC<
  React.PropsWithChildren<unknown>
> = () => <Field name="language_code" component={TestLanguageFormControl} />
export const TestPermittedDeviceTypeField: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Field
    name="permitted_device_type"
    component={TestPermittedDeviceTypeFormControl}
  />
)
