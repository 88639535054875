import { ListProjectsQueryParams } from "~/api/generated/usabilityhub-components"

export const SORTABLE_FIELDS: ReadonlyArray<
  ListProjectsQueryParams["sort_by"]
> = ["name", "created_at", "updated_at"] as const

export const SORT_OPTIONS: Array<
  [
    ListProjectsQueryParams["sort_by"],
    ListProjectsQueryParams["sort_direction"],
    string,
  ]
> = [
  ["updated_at", "desc", "Modified: Newest first"],
  ["updated_at", "asc", "Modified: Oldest first"],
  ["created_at", "desc", "Created: Newest first"],
  ["created_at", "asc", "Created: Oldest first"],
  ["name", "asc", "Name: A to Z"],
  ["name", "desc", "Name: Z to A"],
]
