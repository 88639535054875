import { Button, Divider, Flex, Icon, Spacer, VStack } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { WarningIcon } from "Icons/WarningIcon"
import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  ResponseAnswer,
  UsabilityTestSection,
  UsabilityTestSectionQuestion,
} from "Types"
import React from "react"
import { UsePrototypeReturn } from "../usePrototypeTask"
import {
  InstructionsContainer,
  SystemInstructions,
  TaskInstructions,
} from "./Instructions"
import { TaskFlowOrFreeRoamButton } from "./TaskFlowOrFreeRoamButton"
import { TaskFlowOrFreeRoamInstructionsContent } from "./TaskFlowOrFreeRoamInstructions"
import {
  PrototypeTaskPhase,
  phasesWithReportButtonVisible,
} from "./usePrototypeTaskPhases"

interface InstructionsContentProps {
  prototypeTask: UsePrototypeReturn
  openSkipModal?: () => void
  section: UsabilityTestSection
  prototypeTaskPhase: PrototypeTaskPhase
  prototypeType: PrototypeType
  advanceToNextPhase: () => void
  question?: UsabilityTestSectionQuestion
  handleQuestionSubmit: (
    answer: Omit<ResponseAnswer, "id" | "response_id">
  ) => void
}

export const InstructionsContent: React.FC<InstructionsContentProps> = ({
  prototypeTask,
  openSkipModal,
  section,
  prototypeTaskPhase,
  advanceToNextPhase,
  prototypeType,
  question,
  handleQuestionSubmit,
}) => {
  const translate = useTranslate()
  const taskInstructions = section.text
  const systemInstructions =
    section.test_instructions ??
    translate(
      `test.instructions.prototype_task.default_system_instructions.${prototypeType}`
    )

  const isReportButtonVisible =
    openSkipModal !== undefined &&
    prototypeTaskPhase &&
    phasesWithReportButtonVisible.includes(prototypeTaskPhase)

  return (
    <>
      <VStack
        alignItems="flex-start"
        maxH="calc(100vh - 12rem)"
        overflowY="auto"
        overflowX="auto"
      >
        {!prototypeTask.isTaskStarted ? (
          <InstructionsContainer>
            <TaskInstructions>{taskInstructions}</TaskInstructions>
            <Divider />
            <SystemInstructions>{systemInstructions}</SystemInstructions>
          </InstructionsContainer>
        ) : (
          <TaskFlowOrFreeRoamInstructionsContent
            section={section}
            prototypeType={prototypeType}
            prototypeTaskPhase={prototypeTaskPhase}
            taskInstructions={taskInstructions}
            question={question}
            handleQuestionSubmit={handleQuestionSubmit}
          />
        )}
        <Spacer />
      </VStack>
      <Spacer height={4} />
      <Flex justifyContent="space-between" alignItems="center" w="full">
        {!prototypeTask.isTaskStarted && (
          <ThemedButton
            isLoading={!prototypeTask.isTaskLoaded}
            loadingText={translate("test.buttons.task_loading")}
            onClick={prototypeTask.startTask}
          >
            {translate("test.buttons.start_task")}
          </ThemedButton>
        )}
        {prototypeTask.isTaskStarted && (
          <TaskFlowOrFreeRoamButton
            prototypeType={prototypeType}
            prototypeTask={prototypeTask}
            prototypeTaskPhase={prototypeTaskPhase}
            advanceToNextPhase={advanceToNextPhase}
          />
        )}
        {isReportButtonVisible && (
          <Button
            onClick={openSkipModal}
            background="none"
            leftIcon={<Icon as={WarningIcon} />}
          >
            Report
          </Button>
        )}
      </Flex>
    </>
  )
}
