import { STEP_SEQUENCE } from "UsabilityHub/components/Onboarding/constants"
import { merge } from "lodash"
import {
  AnyObject,
  InferType,
  ObjectSchema,
  array,
  boolean,
  object,
  string,
} from "yup"
import { InviteTeamAnimation } from "./animations/InviteTeamAnimation"
import { ResearchGoalsAnimation } from "./animations/ResearchGoalsAnimation"
import { RoleAnimation } from "./animations/RoleAnimation"
import { TrafficSourceAnimation } from "./animations/TrafficSourceAnimation"
import { InviteTeamForm } from "./forms/InviteTeamForm"
import { ResearchGoalsForm } from "./forms/ResearchGoalsForm"
import { RoleForm } from "./forms/RoleForm"
import { TrafficSourceForm } from "./forms/TrafficSourceForm"
import { EmailAddress, ResearchGoal, Role } from "./types"
import { OnboardingApi } from "./useOnboardingApi"
import { OnboardingFormWiring } from "./useOnboardingFormWiring"

export type OnboardingStepId = (typeof ONBOARDING_STEP_SEQUENCE)[number]
type OnboardingStep<ID extends OnboardingStepId> = {
  id: ID
  title: string
  schema: ObjectSchema<OnboardingStepValues<ID>, AnyObject>
  defaultValues: OnboardingStepValues<ID>
  form: React.FC<OnboardingFormProps<ID>>
  animation?: React.FC<OnboardingAnimationProps<ID>>
  isSkippable?: boolean
  continue?: string
}

export type OnboardingStepValues<
  ID extends OnboardingStepId = OnboardingStepId,
> = InferType<(typeof STEP_DEFINITIONS)[ID]["schema"]>

/*
 * NOTE: `OnboardingData` is not partitioned by step.
 * It is stored in the same format that is received from the API.
 */
export type OnboardingData = {
  [ID in OnboardingStepId]: Partial<OnboardingStepValues<ID>>
}[OnboardingStepId]

export type OnboardingFormProps<ID extends OnboardingStepId> = {
  api: OnboardingApi
  values: OnboardingStepValues<ID>
  wiring: OnboardingFormWiring<ID>
  submit: (data: OnboardingStepValues<ID>) => Promise<void>
}

export type OnboardingAnimationProps<ID extends OnboardingStepId> = {
  values: OnboardingStepValues<ID>
}

const NAME_FORMAT = /^[^\/.:<>]*(\.\s[^\/.:]*)*(\.)?$/
const ONBOARDING_STEP_SEQUENCE = STEP_SEQUENCE.filter((s) => s !== "account")

export const getStep = <ID extends OnboardingStepId>(
  id: ID
): OnboardingStep<ID> =>
  ({ ...STEP_DEFINITIONS[id], id }) as unknown as OnboardingStep<ID>

export const getStepByIndex = (
  index: number
): OnboardingStep<OnboardingStepId> =>
  getStep(ONBOARDING_STEP_SEQUENCE[index - 1])

export const stepIndex = (stepId: OnboardingStepId) =>
  ONBOARDING_STEP_SEQUENCE.indexOf(stepId) + 1

export const stepCount = ONBOARDING_STEP_SEQUENCE.length + 1

export const allDefaultValues = () =>
  Object.values(STEP_DEFINITIONS).reduce(
    (acc, step) => merge(acc, step.defaultValues),
    {}
  ) as OnboardingData

type StepDefinition<Data extends AnyObject> = {
  title: string
  defaultValues: Partial<Data>
  schema: ObjectSchema<Data, AnyObject>
  form: React.FC
  animation?: React.FC
  isSkippable?: boolean
  continue?: string
}

const STEP_DEFINITIONS = {
  role: {
    title: "Your role",
    schema: object({
      preferredName: string()
        .matches(
          NAME_FORMAT,
          "Please remove special characters such as slashes, colons, and periods"
        )
        .required("Enter your preferred name to continue"),
      role: string<Role>()
        .required("Please select a role")
        // The server currently accepts "Unspecified" but we want customers on this flow to choose a role
        .notOneOf(["Unspecified" as Role]),
      customRoleTitle: boolean()
        .required()
        .when("role", ([role], s) =>
          role === "other" ? s.transform(() => true) : s
        ),
      roleTitle: string().when(
        ["role", "customRoleTitle"],
        ([role, checked]) =>
          checked || role === "Other"
            ? string()
                .matches(
                  NAME_FORMAT,
                  "Please remove special characters such as slashes, colons, and periods"
                )
                .required("Please enter a role title")
            : string().transform(() => "")
      ),
    }),
    defaultValues: {
      // preferredName: "", deliberately omitted, it should be populated from the current user
      role: "" as Role,
      customRoleTitle: false,
      roleTitle: "",
    },
    form: RoleForm,
    animation: RoleAnimation,
    isSkippable: false,
  },
  research: {
    title: "Research goals",
    schema: object({
      researchGoals: array().of(string<ResearchGoal>().required()).required(),
      researchNeeds: string().when("researchGoals", ([goals], s) =>
        goals.length > 0
          ? s
          : s
              .matches(
                NAME_FORMAT,
                "Please remove special characters such as slashes, colons, and periods"
              )
              .required(
                "Please describe your research needs or select from the list above"
              )
      ),
    }),
    defaultValues: {
      researchGoals: [],
      researchNeeds: "",
    },
    form: ResearchGoalsForm,
    animation: ResearchGoalsAnimation,
    isSkippable: true,
  },
  collaborators: {
    title: "Invite your team",
    schema: object({
      teamMembers: array()
        .of(
          object({
            email: string<EmailAddress>().email(),
            invited: boolean().default(false),
          })
        )
        .test(
          "required",
          "Please invite a team member or skip",
          (v) => v && v.filter((t) => !!t.email?.trim()).length > 0
        )
        .required(),
    }),
    defaultValues: {
      teamMembers: Array(2).fill({ email: "" }),
    },
    form: InviteTeamForm,
    animation: InviteTeamAnimation,
    isSkippable: true,
    continue: "Send invites",
  },
  grapevine: {
    title: "How did you hear about us?",
    schema: object({
      trafficSource: string().required(
        "Enter a description of how you heard about us to continue"
      ),
    }),
    defaultValues: {
      trafficSource: "",
    },
    form: TrafficSourceForm,
    animation: TrafficSourceAnimation,
    isSkippable: false,
    continue: "Finish",
  },
} as const satisfies {
  [ID in OnboardingStepId]: StepDefinition<any>
}
