import { Avatar, Box, HStack, Stack, Text } from "@chakra-ui/react"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { Variants, motion } from "framer-motion"
import React, { useMemo, useState } from "react"
import { OnboardingAnimationProps } from "../constants"
import { TeamMember } from "../types"
import scribble3 from "./scribble-3.png"
import scribble5 from "./scribble-5.png"

const VARIANTS: Record<string, Variants> = {
  box: {
    out: {
      opacity: 0,
      scale: 0.75,
    },
    in: {
      opacity: 1,
      scale: 1,
      rotate: "-10deg",
      x: -120,
      y: -75,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  },
  popup: {
    out: {
      opacity: 0,
      scale: 0.5,
      rotate: "10deg",
    },
    in: {
      opacity: 1,
      scale: 1,
      rotate: "10deg",
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.025,
        delayChildren: 0.3,
      },
    },
  },
  burst: {
    out: {
      opacity: 0,
      scale: 0,
      rotate: "75deg",
    },
    in: {
      opacity: 1,
      scale: 0.78,
      rotate: "75deg",
    },
  },
  arrow: {
    out: {
      opacity: 0,
      scale: 0.75,
      rotate: "45deg",
    },
    in: {
      opacity: 1,
      scale: 1,
      rotate: "0deg",
    },
  },
  letter: {
    out: { display: "none" },
    in: { display: "inline-block" },
  },
  menu: {
    out: {
      opacity: 0,
      scaleY: 0,
    },
    in: {
      opacity: 1,
      scaleY: 1,
    },
  },
} as const

const nameFromEmail = (email: string) =>
  email
    .replace(/@.*/, "")
    .replace(/[\._]/g, " ")
    .replace(/(\w)(\w*)/g, (_, first, rest) => first.toUpperCase() + rest)
    .split(/\s+/)[0]

export const InviteTeamAnimation: React.FC<
  OnboardingAnimationProps<"collaborators">
> = ({ values: { teamMembers } }) => {
  const currentUser = useCurrentUser()

  const names = useMemo<string[]>(() => {
    const names = (teamMembers || [])
      .map((t: TeamMember) => t && nameFromEmail(t.email || ""))
      .filter(Boolean)
    return names.length ? names : [""]
  }, [teamMembers])

  const [animationCompleted, setAnimationCompleted] = useState(false)

  return (
    <Box
      as={motion.div}
      bg="brandPalette.pink.light"
      w="285px"
      h="247px"
      rounded={16}
      display="grid"
      placeContent="center"
      boxShadow="md"
      variants={VARIANTS.box}
    >
      <svg width="204" height="201" viewBox="0 0 204 201">
        <title>Pie chart</title>
        <Box
          as="path"
          d="M160.181 182.267C136.97 202.131 87.4656 208.889 50.4251 188.353C28.7824 176.328 12.2679 158.605 4.88354 134.413C-1.37571 113.988 -1.75761 85.3203 5.51399 64.8856C19.9244 24.7138 58.0104 6.78062 98.0619 1.37061C101.983 34.4275 96.3191 66.2244 98.1716 99.3771C107.668 114.44 153.199 173.164 160.181 182.267Z"
          fill="brandPalette.pink.dark"
        />
        <Box
          as="path"
          d="M107.685 99.2328L119.441 84.2586L167.338 22.1167C194.934 46.3428 203.877 74.6561 203.422 113.056C203.068 142.929 182.542 162.758 161.83 180.301L107.703 99.2142L107.685 99.2328Z"
          fill="brandPalette.pink.darkest"
        />
        <Box
          as="path"
          d="M104.552 96.2071L105.108 0.368652C133.12 2.79444 143.219 8.41959 161.757 19.3508L104.552 96.1885V96.2071Z"
          fill="brandPalette.pink.mid"
        />
      </svg>
      <Stack
        as={motion.div}
        variants={VARIANTS.popup}
        pos="absolute"
        top="170px"
        left="140px"
        w="360px"
        h="232px"
        px={7}
        py={5}
        gap={5}
        transformOrigin="0 0"
        bg="white"
        rounded="16px"
        boxShadow="md"
        fontFamily="promo"
      >
        <Box
          as={motion.img}
          src={scribble3}
          pos="absolute"
          right="12px"
          top="-40px"
          transformOrigin="100% 100%"
          variants={VARIANTS.burst}
        />
        <Box
          as={motion.img}
          src={scribble5}
          pos="absolute"
          left="-80px"
          top="40px"
          transformOrigin="100% 0"
          variants={VARIANTS.arrow}
        />

        <HStack align="center">
          <Avatar
            size="sm"
            name={currentUser?.preferred_name || "A"}
            bg="brandPalette.red.mid"
            color="white"
            fontWeight="bold"
            flex="0 0 auto"
          />
          <Text
            fontWeight="bold"
            flex={1}
            minW={0}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {currentUser?.preferred_name}
          </Text>
        </HStack>
        <Stack align="start" gap={2}>
          <Box w="297px" h={4} rounded={2} bg="icon.disabled" />
          <Box w="120px" h={4} rounded={2} bg="icon.disabled" />
        </Stack>
        <Box flex={1} bg="brandPalette.neutral.mid" rounded={7} p={4}>
          <AnimateText onComplete={() => setAnimationCompleted(true)}>
            {`Looks like the results are in! Check this out, @${
              animationCompleted ? names[0] : ""
            }`}
          </AnimateText>
          <Box
            as="svg"
            display="inline-block"
            width="8"
            height="17"
            viewBox="0 0 8 17"
            fill="brandPalette.neutral.dark"
            transform="translate(-12px, 3px)"
          >
            <path d="M3.77184 13.3956V9.22474H2.75984V8.23472H3.77184L3.77184 3.09407C3.56072 2.57384 3.23673 2.10716 2.85839 1.75717C2.58601 1.55045 2.2794 1.39304 2.00273 1.30484C1.59665 1.22371 1.18167 1.19609 0.768394 1.2227L0.703262 0.214564C1.20484 0.182273 1.7085 0.21579 2.25155 0.326993C2.69155 0.462846 3.10425 0.67472 3.50701 0.98314C3.79835 1.25038 4.05459 1.55164 4.27068 1.87959C4.49972 1.54603 4.77714 1.24058 5.10093 0.968696C5.46528 0.700581 5.86678 0.486824 6.36483 0.314189C6.85399 0.216824 7.35375 0.183317 7.85156 0.214509L7.78817 1.22275C7.37784 1.19704 6.96592 1.22466 6.63475 1.28496C6.30114 1.40452 5.98673 1.5719 5.72745 1.76136C5.31056 2.11244 4.98369 2.55755 4.77373 3.05961V8.23472H5.78573V9.22474H4.77373L4.77373 13.4195C4.98617 13.9219 5.31527 14.3662 5.71227 14.6982C5.99676 14.9084 6.31071 15.0755 6.57685 15.1754C6.97628 15.2583 7.3848 15.289 7.79214 15.2667L7.84758 16.2754C7.35264 16.3025 6.85626 16.2652 6.30378 16.1457C5.87685 15.9936 5.47471 15.7795 5.08698 15.492C4.77569 15.2325 4.50264 14.9327 4.27445 14.6017C4.05299 14.937 3.78559 15.2494 3.47131 15.5363C3.10466 15.8152 2.69156 16.0273 2.2016 16.1753C1.70861 16.2741 1.20475 16.3076 0.703004 16.2751L0.768651 15.267C1.18175 15.2938 1.59659 15.2662 1.95302 15.1974C2.27971 15.0969 2.58606 14.9396 2.82207 14.7628C3.23604 14.3827 3.56039 13.9157 3.77184 13.3956Z" />
          </Box>
        </Box>

        <Stack
          as={motion.div}
          pos="absolute"
          left="115px"
          top="205px"
          w="160px"
          bg="white"
          boxShadow="md"
          p={4}
          rounded={7}
          variants={VARIANTS.menu}
          animate={animationCompleted ? "in" : "out"}
          transformOrigin="0 0"
        >
          {names.map((name, index) => (
            <HStack align="center" key={index}>
              <Text
                fontWeight="medium"
                fontSize="1.5rem"
                lineHeight={1}
                color="brandPalette.teal.dark"
                flex="0 0 auto"
              >
                @
              </Text>
              {name ? (
                <Text
                  as="span"
                  fontWeight="medium"
                  flex="1"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {name}
                </Text>
              ) : (
                <Box w="103px" h={4} rounded={2} bg="icon.disabled" />
              )}
            </HStack>
          ))}
        </Stack>
      </Stack>
    </Box>
  )
}

const AnimateText = ({
  children,
  onComplete,
}: {
  children: string
  onComplete: () => void
}) => (
  <>
    {children.split("").map((char, index) => (
      <motion.span
        key={index}
        style={{ whiteSpace: "pre" }}
        variants={VARIANTS.letter}
        onAnimationComplete={
          index === children.length - 1 ? onComplete : undefined
        }
      >
        {char}
      </motion.span>
    ))}
  </>
)
