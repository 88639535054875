import { Box, Icon, Stack, Text } from "@chakra-ui/react"
import { SubmitButton } from "Components/button/submit-button"
import { ProTrialCallout } from "Components/trial-widget/ProTrialCallout"
import { TestThankYouIcon } from "Icons/TestThankYouIcon"
import { TestWelcomeIcon } from "Icons/TestWelcomeIcon"
import { Dispatch } from "Redux/app-store"
import { setIsPreviewClicked } from "Redux/reducers/test-builder/action-creators/save"
import { getUsabilityTestLastSavedAt } from "Redux/reducers/test-builder/selectors/save"
import { getUniqueId } from "Redux/reducers/test-builder/selectors/test-form"
import { ProFeatureTooltip } from "UsabilityHub/components/ProFeatureCallout/ProFeatureTooltip"
import { useIsTestBuilderExternalStudy } from "UsabilityHub/hooks/useIsTestBuilderExternalStudy"
import { useShowProTrialWidget } from "Utilities/account"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useGetTestBuilder } from "~/api/generated/usabilityhub-components"
import { useUsabilityTestUserActivityContext } from "../UsabilityTestUserActivityContext"
import { SidebarWarnings } from "./SidebarWarnings"
import { TestDurationAndCostEstimate } from "./TestDurationAndCostEstimate/TestDurationAndCostEstimate"
import { SidebarSectionsList } from "./sidebar-list"
import { SidebarListItem } from "./sidebar-list-item"

interface Props {
  disabled: boolean
}

export const TestFormSidebar: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
}) => {
  const dispatch: Dispatch = useDispatch()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const showProTrialWidget = useShowProTrialWidget()
  const isExternalStudy = useIsTestBuilderExternalStudy()

  const onSubmitClicked = () => {
    dispatch(setIsPreviewClicked(false))
  }
  const onPreviewClicked = () => {
    dispatch(setIsPreviewClicked(true))
  }

  const usabilityTestUniqueId = useSelector(getUniqueId)
  const _lastSavedAt = useSelector(getUsabilityTestLastSavedAt)

  const { refetch } = useGetTestBuilder({
    pathParams: { usabilityTestUniqueId: usabilityTestUniqueId ?? "new" },
  })

  // When "Save and preview" is clicked, the test builder remains open in a tab
  // and the data gets updated by the response from the server. For comments,
  // this means we may have moved comments that were on unsaved entities
  // (pointing to _clientId) to the real IDs.
  //
  // In order to get the new IDs we need to refetch the test builder API any
  // time the _lastSavedAt changes.
  useEffect(() => {
    if (usabilityTestUniqueId) {
      void refetch()
    }
  }, [_lastSavedAt, usabilityTestUniqueId, refetch])

  return (
    <Stack spacing={5}>
      <Box>
        <SidebarListItem targetId="test-details-card" mb={2}>
          <Text>Study details</Text>
        </SidebarListItem>

        <SidebarListItem targetId="screener" mt={2} mb={6}>
          <Text>Screener</Text>
        </SidebarListItem>

        {!isExternalStudy && (
          <SidebarListItem targetId="welcome-screen-card">
            <Icon as={TestWelcomeIcon} color="gray.500" boxSize={5} />
            <Text>Welcome screen</Text>
          </SidebarListItem>
        )}

        <SidebarSectionsList />

        {!isExternalStudy && (
          <SidebarListItem targetId="thank-you-screen-card">
            <Icon as={TestThankYouIcon} color="gray.500" boxSize={5} />
            <Text>Thank you screen</Text>
          </SidebarListItem>
        )}
      </Box>
      <TestDurationAndCostEstimate />
      <SidebarWarnings />
      <Box position="sticky" bottom={0} bg="gray.50" py={2}>
        <Stack
          // On submit triggered by a keydown, default behaviour is to submit the
          // first submit button in the DOM. Flip the buttons so default submit is
          // Continue but we can show it after Preview in the UI.
          direction="column-reverse"
          mb="44px"
        >
          <SubmitButton
            onClick={onSubmitClicked}
            isDisabled={readOnly || disabled}
            width="full"
          >
            Save and continue
          </SubmitButton>
          <SubmitButton
            colorScheme="gray"
            onClick={onPreviewClicked}
            isDisabled={readOnly || disabled}
            width="full"
          >
            Save and preview
          </SubmitButton>
        </Stack>
      </Box>
      {showProTrialWidget && (
        <ProTrialCallout>
          You’re getting{" "}
          <ProFeatureTooltip label="Switching your plan will limit your max test duration to 2 mins on Free plan or 5 mins on Basic plan">
            unlimited test duration
          </ProFeatureTooltip>{" "}
          and{" "}
          <ProFeatureTooltip label="Conditional logic is only available on the Pro plan">
            conditional logic
          </ProFeatureTooltip>{" "}
          on your{" "}
          <Text as="span" fontWeight="bold">
            Pro plan trial
          </Text>
        </ProTrialCallout>
      )}
    </Stack>
  )
}
