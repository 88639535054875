import {
  Box,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ParticipantIcon } from "Components/ParticipantIcon"
import { RecordingPlayer } from "Components/test-recording/RecordingPlayer"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { Alert } from "DesignSystem/components"
import { useFeatureFlagLoading } from "Hooks/use-feature-flag"
import {
  ResponseSectionLiveWebsiteTestTaskRecording,
  ResponseSectionRecording,
} from "JavaScripts/types/recording"
import { State } from "Redux/app-store"
import { getAllResponses } from "Redux/reducers/test-results/selectors"
import { RecordingContainer } from "UsabilityHub/components/RecordingContainer/RecordingContainer"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { getDateString } from "Utilities/date-formats"
import { formatPreciseDuration } from "Utilities/time"
import { motion } from "framer-motion"
import React, { useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useSelector } from "react-redux"
import { RecordingContent } from "./RecordingContent"

type Props = {
  recordings: (
    | ResponseSectionRecording
    | ResponseSectionLiveWebsiteTestTaskRecording
  )[]
}

export const RecordingsTab: React.FC<Props> = ({ recordings }) => {
  const { setResponseId } = useIndividualResponseFilter()

  const [recordingId, setRecordingId] = useState<
    ResponseSectionRecording["id"] | undefined
  >(recordings[0]?.id ?? undefined)

  const recording = recordingId
    ? recordings.find((rec) => rec.id === recordingId)
    : undefined

  const responses = useSelector((state: State) => getAllResponses(state))

  const allLocations = useFlatLocations("usability_tests")

  const locationData = useMemo(
    () =>
      responses.reduce(
        (acc, response) => {
          const reponseLocation =
            response.response_demographic_profile?.location
          if (!reponseLocation) return acc
          return {
            ...acc,
            [response.id]:
              allLocations[reponseLocation.type][reponseLocation.id],
          }
        },
        {} as Record<string, any>
      ),
    [allLocations, responses]
  )

  const { isOpen: isExpanded, onToggle } = useDisclosure()

  const { loading, enabled } = useFeatureFlagLoading(
    "new_recordings_results_ui"
  )

  const recordingDate = (
    recording:
      | ResponseSectionRecording
      | ResponseSectionLiveWebsiteTestTaskRecording
  ) => {
    const response = responses.find((r) => r.id === recording.response_id)
    return response ? getDateString(response.submitted_at) : null
  }

  if (recordings.length === 0) {
    if (enabled) {
      return (
        <Grid
          placeContent="center"
          minH="25rem"
          background="ds.background.neutral.resting"
          rounded={12}
        >
          <Text textStyle="ds.heading.primary">No recordings yet</Text>
        </Grid>
      )
    } else {
      return <Alert status="info" description="No recording data available" />
    }
  }

  if (loading) {
    return (
      <Box display="grid" placeItems={"center"} aspectRatio="16 / 9">
        <Spinner size="xl" />
      </Box>
    )
  }

  if (!enabled) {
    // Old grid view
    return (
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {recordings.map((recording) => (
          // When a single recording container has errors, we'll ignore it and continue rendering the rest of the recordings.
          <ErrorBoundary key={`error-${recording.id}`} fallback={null}>
            <GridItem boxShadow="base" rounded="md" key={recording.id}>
              <RecordingContainerOld recording={recording} />
            </GridItem>
          </ErrorBoundary>
        ))}
      </Grid>
    )
  }
  // New hotness
  return (
    <Box
      as={motion.div}
      layout="size"
      layoutId={`recordings-tab-${recordings[0].id}`}
      initial={false}
      display="grid"
      gridTemplate={
        isExpanded
          ? '"preview" auto "list" calc(13rem + 1px) / 1fr'
          : '"preview list" auto / 1fr 22.5rem'
      }
      backgroundColor="ds.background.neutral.resting"
      overflow="hidden"
      style={{
        borderRadius: 12, // specified in `style` so id doesn't warp during animation
      }}
    >
      {recording && (
        <RecordingContainer
          recording={recording}
          isParcipantView={false}
          isExpanded={isExpanded}
          onToggle={onToggle}
          setResponseId={setResponseId}
        />
      )}
      <Stack
        gridArea="list"
        alignSelf={"stretch"}
        borderLeft={isExpanded ? "none" : "1px solid"}
        borderTop={isExpanded ? "1px solid" : "none"}
        borderColor="ds.border.default"
        gap={0}
        minH={0}
      >
        <Box
          flex="1 1 0"
          overflowY="auto"
          p={2}
          minH={isExpanded ? undefined : 0}
        >
          {recordings.map((row) => (
            <HStack
              key={row.id}
              align="center"
              px={4}
              py={1}
              borderLeft="4px solid"
              borderColor={
                row.id === recordingId ? "ds.border.selected" : "transparent"
              }
              backgroundColor={
                row.id === recordingId
                  ? "ds.background.neutral.resting"
                  : "ds.background.neutral.subtle.resting"
              }
              onClick={() => setRecordingId(row.id)}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              roundedLeft={row.id === recordingId ? 0 : 8}
              roundedRight={8}
              _hover={{
                background:
                  row.id === recordingId
                    ? "ds.background.neutral.resting"
                    : "ds.background.neutral.hovered",
                cursor: "pointer",
              }}
            >
              <HStack gap={0} align="center">
                <Box>
                  <ParticipantIcon
                    country={locationData[row.response_id]?.countryCode ?? null}
                  />
                </Box>
              </HStack>
              <Text
                as="div"
                textStyle="ds.paragraph.secondary"
                overflow="hidden"
              >
                {recordingDate(row)}
              </Text>
              <Text
                as="div"
                textStyle="ds.paragraph.secondary"
                color="ds.text.subtle"
                overflow="hidden"
                flex={1}
              >
                {formatPreciseDuration(row.duration * 1000)}
              </Text>
            </HStack>
          ))}
        </Box>
      </Stack>
    </Box>
  )
}

const RecordingContainerOld: React.FC<{
  recording:
    | ResponseSectionRecording
    | ResponseSectionLiveWebsiteTestTaskRecording
}> = ({ recording }) => {
  const [hasRecordingError, setHasRecordingError] = React.useState(false)

  const responseSection =
    "responseSection" in recording ? recording.responseSection : null

  return (
    <Stack gap={0} h="full">
      <RecordingPlayer
        recording={recording}
        setHasRecordingError={setHasRecordingError}
      />
      <RecordingContent
        responseSection={responseSection}
        status={recording.status}
        hasRecordingError={hasRecordingError}
      />
    </Stack>
  )
}
