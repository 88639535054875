import {
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React from "react"

import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"

import { Button, IconButton } from "DesignSystem/components"
import { ArchiveFilledIcon } from "Icons/ArchiveFilledIcon"
import { ArchiveIcon } from "Icons/ArchiveIcon"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { useViewMenuParams } from "./useViewMenuParams"

interface Props {
  iconOnly?: boolean
  listType: "projects" | "studies"
}

export const ActiveMenu: React.FC<ButtonProps & Props> = ({
  iconOnly = false,
  listType = "studies",
  ...buttonProps
}) => {
  const [, setSearchParams] = useMutableQueryString()
  const { showArchived } = useViewMenuParams()

  const BoxIcon = showArchived ? ArchiveFilledIcon : ArchiveIcon

  return (
    <Menu>
      {iconOnly ? (
        <MenuButton as={IconButton} icon={<BoxIcon />} />
      ) : (
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          {...buttonProps}
        >
          {showArchived ? `Archived ${listType}` : `Active ${listType}`}
        </MenuButton>
      )}

      <MenuList overflow="hidden">
        <MenuItem
          onClick={() => {
            setSearchParams({
              show_archived: null,
            })
          }}
        >
          {`Active ${listType}`}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setSearchParams({
              show_archived: "true",
            })
          }}
        >
          {`Archived ${listType}`}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
