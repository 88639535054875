import { Box, Button, ButtonGroup, HStack } from "@chakra-ui/react"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import React from "react"
import { useTreeContext } from "../TreeProvider"
import { ImportTree } from "./ImportTree"
import { KeyboardShortcuts } from "./KeyboardShortcuts"

export const Toolbar: React.FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { expandAll, collapseAll } = useTreeContext()

  return (
    <HStack>
      <ButtonGroup size="sm" variant="outline">
        <Button leftIcon={<ChevronRightIcon />} onClick={collapseAll}>
          Collapse all
        </Button>
        <Button leftIcon={<ChevronDownIcon />} onClick={expandAll}>
          Expand all
        </Button>
      </ButtonGroup>
      <Box flexGrow={1} />
      <ButtonGroup size="sm" variant="ghost">
        <KeyboardShortcuts />
        <ImportTree isDisabled={readOnly} />
      </ButtonGroup>
    </HStack>
  )
}
