import { createContext } from "react"

type CollapsibleTabListContextType = {
  display: "tabs" | "menu"
}

export const CollapsibleTabListContext =
  createContext<CollapsibleTabListContextType>({
    display: "tabs",
  })
