import { Box, Flex, Icon, IconButton } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { InfoIcon } from "Icons/InfoIcon"
import { useTranslate } from "Shared/hooks/useTranslate"
import { useTestBranding } from "UsabilityHub/components/UsabilityTest/context/testBranding"
import React, { useState } from "react"
import { CardSortProgress } from "./CardSortProgress"
interface Props {
  taskInstructions: string
  testInformation: string
  enableDoneButton: boolean
  onComplete: () => void
}
export const CardSortHeader: React.FC<Props> = ({
  taskInstructions,
  testInformation,
  enableDoneButton,
  onComplete,
}) => {
  const translate = useTranslate()
  const branding = useTestBranding()
  const [showHelp, setShowHelp] = useState(false)

  return (
    <>
      <Flex
        align="stretch"
        h="fit-content"
        gap={2}
        borderBottomWidth={1}
        borderBottomColor="gray.200"
        bg="white"
        p={4}
        zIndex={7}
      >
        <CardSortProgress flexGrow={1} />

        <IconButton
          variant={showHelp ? "solid" : "outline"}
          icon={
            <Icon
              color={branding.button_color ?? "brand.primary.500"}
              boxSize={5}
              as={InfoIcon}
            />
          }
          aria-label={translate("test.card_sort.toggle_instructions")}
          onClick={() => setShowHelp((v) => !v)}
        />

        <ThemedButton
          isDisabled={!enableDoneButton}
          onClick={() => onComplete()}
          w="fit-content"
        >
          {translate("test.buttons.finish_task")}
        </ThemedButton>
      </Flex>
      {showHelp && (
        <>
          <Box
            bg="blackAlpha.500"
            position="absolute"
            inset={0}
            h="100%"
            onClick={() => setShowHelp(false)}
            zIndex={6} // Should be greater than the maximum zIndex of Category component
          />
          <Box
            position="absolute"
            borderBottomWidth={1}
            borderBottomColor="gray.200"
            bg="white"
            p={4}
            zIndex={7}
            top="73px"
          >
            <Text textStyle="ds.heading.primary" color="ds.text.default" mb={4}>
              <DisplayBlockMarkdownText text={taskInstructions} />
            </Text>

            <Text textStyle="ds.paragraph.primary" color="ds.text.default">
              <DisplayBlockMarkdownText text={testInformation} />
            </Text>
          </Box>
        </>
      )}
    </>
  )
}
