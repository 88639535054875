import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { DangerFilledIcon } from "Icons/DangerFilledIcon"
import { Beacon } from "UsabilityHub/components/Beacon"
import React from "react"
import { Link } from "react-router-dom"
import { Study } from "~/api/generated/usabilityhubSchemas"

type RecruitButtonProps = {
  study: Extract<Study, { type: "moderated_study" }>
  linkTo: (path: string) => string
}

const ButtonIcon: React.FC<{
  study: Extract<Study, { type: "moderated_study" }>
}> = ({ study }) => {
  const label = study.google_calendar_error
    ? "Calendar integration action required"
    : !study.has_availability
      ? "No availability"
      : undefined

  if (label) {
    return (
      <Tooltip hasArrow placement="top" label={label}>
        <Icon as={DangerFilledIcon} boxSize={4} color="ds.icon.danger" />
      </Tooltip>
    )
  } else {
    return <ChevronDownIcon />
  }
}

const RecruitButton: React.FC<RecruitButtonProps> = ({ study, linkTo }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return study.status === "Recruiting" ? (
    <Menu isLazy onOpen={onOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        aria-label="Options"
        rightIcon={<ButtonIcon study={study} />}
        w="8.5rem"
        zIndex={1}
        isSelected={isOpen}
      >
        <Flex justifyContent="center" alignItems="center" gap={2}>
          <Beacon colorScheme="brand" boxSize={4} />
          <Text>Recruiting</Text>
        </Flex>
      </MenuButton>
      <Portal>
        <MenuList overflow="hidden" zIndex="dropdown">
          <MenuItem as={Link} to={linkTo("recruit")}>
            Manage recruitment
          </MenuItem>

          <MenuItem as={Link} to={linkTo("applicants")}>
            View applicants
          </MenuItem>

          <MenuItem as={Link} to={linkTo("sessions")}>
            View sessions
          </MenuItem>

          <MenuItem as={Link} to={linkTo("recordings")}>
            View recordings
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  ) : (
    <Button
      gridArea="recruit"
      as={Link}
      to={linkTo("recruit")}
      justifySelf="end"
      w="8.5rem"
      zIndex={1}
    >
      Recruit
    </Button>
  )
}

export default RecruitButton
