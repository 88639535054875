import { Box } from "@chakra-ui/react"
import { TestResults } from "Components/test-results/test-results-container"
import { useAllowGoogleTranslate } from "Hooks/useAllowGoogleTranslate"
import { getUsabilityTestByUniqueId } from "Redux/reducers/usability-tests/selectors"
import { PageMain } from "Shared/components/Page/Page"
import { LocaleProvider } from "Shared/contexts/LocaleContext"
import { UsabilityTestResultDataProvider } from "UsabilityHub/components/ResultsDataProvider/UsabilityTestResultDataProvider"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { useTypedParams } from "react-router-typesafe-routes/dom"

export function UsabilityTestResultsRoute() {
  useAllowGoogleTranslate()

  const { testId } = useTypedParams(ROUTES.TEST.RESULTS)
  const test = useSelector(getUsabilityTestByUniqueId(testId))

  return (
    <PageMain>
      <Helmet>
        <title>{test?.name}</title>
      </Helmet>

      <UsabilityTestResultDataProvider usabilityTestUniqueId={testId}>
        <Box width="100%">
          <LocaleProvider
            keys={["demographics", "platform", "device_type", "test.tree_test"]}
          >
            <TestResults />
          </LocaleProvider>
        </Box>
      </UsabilityTestResultDataProvider>
    </PageMain>
  )
}
