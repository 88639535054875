import {
  Box,
  ComponentWithAs,
  HStack,
  Icon,
  IconProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { ClickMapOld } from "Components/click-map/click-map-old"
import { getFirstClickSectionClicks } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/FirstClickSectionIndividualResults/FirstClickSectionIndividualResults"
import { clickClippingRectangle } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/FirstClickSectionIndividualResults/click-summary-old"
import { getTreeTestResponseStats } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults"
import { Badge as TreeTestBadge } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults/Badge"
import {
  RecordingPlayer,
  getShareUrl,
} from "Components/test-recording/RecordingPlayer"
import { useFilteredClicks } from "Components/test-results/hooks/use-filtered-clicks"
import { ClippedThumbnail } from "Components/test-results/progress-box/thumbnail"
import { useNavigationTestResultsStepContext } from "Components/test-results/section-results/NavigationTestResultsStepContext"
import { GoalScreenBadge } from "Components/test-results/section-results/SectionResultsCards/PrototypeSectionResultsCard/PathsSummaries/PathStats/GoalScreenBadge"
import { useTaskGoalNodeId } from "Components/test-results/section-results/SectionResultsCards/PrototypeSectionResultsCard/hooks/use-goal-node-id"
import { getCardSortResponseStats } from "Components/test-results/section-results/card-sort-result"
import { getIndividualFigmaTaskPathFromTask } from "Components/test-results/utils/task-paths/get-individual-paths/get-individual-paths"
import { PreciseDuration } from "Components/time/precise-duration"
import { Badge, Heading, IconButton } from "DesignSystem/components"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { ClickFilledIcon } from "Icons/ClickFilledIcon"
import { DownloadIcon } from "Icons/DownloadIcon"
import { MaximizeIcon } from "Icons/MaximizeIcon"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { MinimizeIcon } from "Icons/MinimizeIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import { RestrictedIcon } from "Icons/RestrictedIcon"
import { ShareIcon } from "Icons/ShareIcon"
import { TimeFilledIcon } from "Icons/TimeFilledIcon"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import {
  ResponseSectionLiveWebsiteTestTaskRecording,
  ResponseSectionRecording,
} from "JavaScripts/types/recording"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  getFigmaTaskForResponseSection,
  getResponseSectionCardSortsForResponseSection,
} from "Redux/reducers/test-results/selectors"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { ImageScreenshot, Screenshot, UsabilityTestSectionType } from "Types"
import { ContactSupportButton } from "UsabilityHub/components/ContactSupportButton"
import { useSectionContext } from "UsabilityHub/contexts"
import { formatPercentage01 } from "Utilities/number"
import { motion } from "framer-motion"
import React, { PropsWithChildren, useEffect } from "react"
import { useSelector } from "react-redux"

type Props = {
  recording:
    | ResponseSectionRecording
    | ResponseSectionLiveWebsiteTestTaskRecording
  layoutId: string
} & (
  | { isParcipantView: true }
  | {
      isParcipantView: false
      isExpanded: boolean
      onToggle: () => void
      setResponseId: (responseId: number) => void
    }
)

export const RecordingContainer: React.FC<Props> = ({
  recording,
  layoutId,
  ...props
}) => {
  const toast = useToast()

  const [hasRecordingError, setHasRecordingError] = React.useState(false)

  useEffect(() => {
    setHasRecordingError(false)
  }, [recording.id])

  const onShare = async (responseId: number, responseSectionId: number) => {
    const url = getShareUrl(responseId, responseSectionId)
    await navigator.clipboard.writeText(url.toString())

    toast({
      title: "Link copied to clipboard",
      status: "success",
    })
  }

  const containerAttributes = props.isParcipantView
    ? {
        key: `participant_drawer_${recording.id}`,
      }
    : {
        key: `recording_tab_${recording.id}`,
        as: motion.div,
        layout: "size",
        layoutId,
        initial: false,
      }

  return (
    <Stack {...containerAttributes} gridArea="preview" gap={0} width="full">
      {hasRecordingError ? (
        <Stack
          alignItems="center"
          justify="center"
          background="ds.background.warning.subtle.resting"
          gap={4}
          aspectRatio="16 / 9"
        >
          <WarningFilledIcon color="ds.icon.warning" boxSize={8} />
          <Stack align="center" gap={2}>
            <Heading as="h3" textStyle="ds.heading.primary">
              Media couldn’t load
            </Heading>
            <Text textStyle="ds.paragraph.primary">
              Please refresh the page to try again.
            </Text>
          </Stack>
          <ContactSupportButton textStyle="ds.interface.medium" />
        </Stack>
      ) : recording.status === "processed" ? (
        <Box background="black" pos="relative" aspectRatio="16 / 9">
          <RecordingPlayer
            height="100%"
            recording={recording}
            isParticipantView={props.isParcipantView}
            hideParticipantButton
            hideShareButton
            roundedCorners={false}
            setHasRecordingError={setHasRecordingError}
          />
        </Box>
      ) : (
        <Stack
          alignItems="center"
          justify="center"
          background="ds.background.neutral.bold.resting"
          gap={4}
          aspectRatio="16 / 9"
        >
          <Spinner boxSize={8} color="ds.icon.accent.teal" />
          <Stack align="center" gap={2} color="ds.text.inverse">
            <Heading as="h3" textStyle="ds.heading.primary">
              Processing upload…
            </Heading>
            <Text textStyle="ds.paragraph.primary">
              Your recording will be available shortly. Please check back later.
            </Text>
          </Stack>
        </Stack>
      )}
      <HStack align="start" justify="space-between" p={3} gap={4}>
        {"responseSection" in recording ? (
          <RecordingDetails recording={recording} />
        ) : (
          <div />
        )}

        <HStack gap={2} alignSelf="stretch" maxH="50px">
          {!props.isParcipantView && (
            <>
              <IconButton
                variant="secondary"
                size="compact"
                icon={props.isExpanded ? <MinimizeIcon /> : <MaximizeIcon />}
                onClick={props.onToggle}
                aria-label="Toggle recording content"
              />
              <IconButton
                right={0}
                aria-label="View participant"
                icon={<UserResponseIcon />}
                variant="secondary"
                size="compact"
                onClick={() => props.setResponseId(recording.response_id)}
              />
            </>
          )}
          {recording.url && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="More"
                icon={<MenuKebabIcon />}
                variant="secondary"
                size="compact"
              />
              <MenuList py={2}>
                <MenuItem
                  as="a"
                  href={recording.url}
                  download
                  icon={<DownloadIcon />}
                >
                  Download
                </MenuItem>

                {"responseSection" in recording && (
                  <MenuItem
                    icon={<ShareIcon />}
                    onClick={() =>
                      onShare(
                        recording.response_id,
                        recording.responseSection.id
                      )
                    }
                  >
                    Share
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
        </HStack>
      </HStack>
    </Stack>
  )
}

type RecordingDetailsProps = {
  recording: ResponseSectionRecording
}

const RecordingDetails: React.FC<RecordingDetailsProps> = ({ recording }) => {
  const { section } = useSectionContext()

  const Components: {
    [key in UsabilityTestSectionType]?: React.FC<RecordingDetailsProps>
  } = {
    [UsabilityTestSectionType.CardSort]: CardSortRecordingDetails,
    [UsabilityTestSectionType.FirstClickTest]: FirstClickRecordingDetails,
    [UsabilityTestSectionType.NavigationTest]: NavigationRecordingDetails,
    [UsabilityTestSectionType.PrototypeTask]: PrototypeRecordingDetails,
    [UsabilityTestSectionType.TreeTest]: TreeTestRecordingDetails,
  } as const

  const Component = Components[section.type]

  return (
    <HStack columnGap={4} rowGap={2} py="2px" align="center" wrap="wrap">
      {Component && <Component recording={recording} />}
    </HStack>
  )
}

const CardSortRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { section } = useSectionContext()
  const { responseSection } = recording
  const sortResults = useSelector((state: State) =>
    getResponseSectionCardSortsForResponseSection(state, responseSection.id)
  )
  const cardSort = section.card_sort_attributes!
  const cards = cardSort.card_sort_cards_attributes

  const { noOfCards, noOfUnsortedCards } = getCardSortResponseStats(
    sortResults,
    cards
  )

  const noOfSortedCards = noOfCards - noOfUnsortedCards
  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <RowContentContainer>
        {noOfSortedCards} sorted, {noOfUnsortedCards} unsorted
      </RowContentContainer>
      <TaskDuration taskDuration={taskDuration} />
      <RowContentContainer icon={TimeFilledIcon}>
        Ave time per card{" "}
        <PreciseDuration ms={taskDuration / noOfSortedCards} />
      </RowContentContainer>
    </>
  )
}

const FirstClickRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const { section } = useSectionContext()

  if (!section) return null

  const clicks = getFirstClickSectionClicks({
    responseSection,
    usabilityTestSection: section,
  })

  if (clicks.length === 0) return

  // We used to historically allow multiple clicks (there was an option so you could set it to “Allow at most X clicks”, defaulted to 1 and maybe maxed out at 5 or something), but now it should have one click.
  const click = clicks[0]
  const sectionScreenshot = section.section_screenshots.find(
    (ss) => ss.id === click.usability_test_section_screenshot_id
  )
  const screenshot = useSelector<State, Screenshot>((state) =>
    getScreenshotWithId(state, sectionScreenshot!.screenshot_id)
  ) as ImageScreenshot

  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <Box
        sx={{
          "> div": {
            padding: 0,
            width: "auto",
            height: "auto",
            minWidth: "0",
            maxWidth: "none",
            rounded: 4,
            overflow: "hidden",
          },
        }}
      >
        <ClippedThumbnail
          screenshot={screenshot}
          clippingRectangle={clickClippingRectangle(click, screenshot)}
        >
          <ClickMapOld
            clicks={[{ id: click.id, x: 0.5, y: 0.5, hit: click.hit }]}
          />
        </ClippedThumbnail>
      </Box>
      <Text textStyle="ds.heading.secondary" color="ds.text.default">
        Click 1
      </Text>
      <TaskDuration taskDuration={taskDuration} />
    </>
  )
}

const NavigationRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const { stepNumber, sectionScreenshot } =
    useNavigationTestResultsStepContext()
  const clicks = useFilteredClicks()
  const click = clicks.find(
    (c) =>
      c.usability_test_section_screenshot_id === sectionScreenshot.id &&
      c.response_id === responseSection.response_id
  )

  const content = click
    ? click.hit
      ? "navigated successfully"
      : "navigated unsuccessfully"
    : "never reached"

  const icon = click
    ? click.hit
      ? CheckCircleFilledIcon
      : RemoveFilledIcon
    : RestrictedIcon

  return (
    <>
      <RowContentContainer>
        <Badge
          variant="subtle"
          colorScheme={click?.hit ? "success" : undefined}
          content={content}
          label={
            click
              ? click.hit
                ? "navigated successfully"
                : "navigated unsuccessfully"
              : "never reached"
          }
        />
      </RowContentContainer>
      <RowContentContainer icon={icon}>Step {stepNumber}</RowContentContainer>
      <TaskDuration taskDuration={responseSection.task_duration_ms || 0} />
    </>
  )
}

const PrototypeRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const figmaTask = useSelector(
    getFigmaTaskForResponseSection(responseSection?.id || null)
  )
  const goalNodeId = useTaskGoalNodeId()

  if (figmaTask === null) {
    throw new Error("No figma task found")
  }

  const { meta } = getIndividualFigmaTaskPathFromTask(figmaTask, goalNodeId)

  const { clicks, duration, misclickRate, goalScreenHasBeenReached } = meta

  return (
    <>
      <GoalScreenBadge goalScreenHasBeenReached={goalScreenHasBeenReached} />
      <RowContentContainer
        icon={ClickFilledIcon}
        tooltip="Total number of clicks by this participant"
      >
        {clicks.length} Clicks ({formatPercentage01(misclickRate)} misclicks)
      </RowContentContainer>
      <TaskDuration taskDuration={duration || 0} />
    </>
  )
}

const TreeTestRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const { section } = useSectionContext()
  const { result, direct } = getTreeTestResponseStats({
    responseSection,
    usabilityTestSection: section,
  })

  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <Box>
        <TreeTestBadge
          result={result}
          directness={direct ? "direct" : "indirect"}
        />
      </Box>
      <TaskDuration taskDuration={taskDuration} />
    </>
  )
}

type RowContentContainerProps = {
  tooltip?: string
  icon?: ComponentWithAs<"svg", IconProps>
}

const RowContentContainer: React.FC<
  PropsWithChildren<RowContentContainerProps>
> = ({ tooltip, icon, children }) => {
  const content = (
    <HStack spacing={1} alignItems="center">
      {icon && <Icon as={icon} boxSize={4} color="ds.icon.subtle" />}
      <Text
        textStyle="ds.paragraph.secondary"
        color="ds.text.default"
        fontWeight="medium"
      >
        {children}
      </Text>
    </HStack>
  )

  if (!tooltip) return content

  return (
    <Tooltip hasArrow placement="top" label={tooltip}>
      {content}
    </Tooltip>
  )
}

type TaskDurationProps = {
  taskDuration: number
}

const TaskDuration: React.FC<TaskDurationProps> = ({ taskDuration }) => (
  <RowContentContainer
    icon={TimeFilledIcon}
    tooltip="Total time spent by this participant"
  >
    Task duration <PreciseDuration ms={taskDuration} />
  </RowContentContainer>
)
