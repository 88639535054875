import { Divider, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"

import { MailIcon } from "Icons/MailIcon"

import { isFuture } from "date-fns"
import { formatBookingDate } from "../formatBookingDate"

import { CalendarIcon } from "Icons/CalendarIcon"
import { UserIcon } from "Icons/UserIcon"
import { CancelBooking } from "./CancelBooking"
import { CanceledContent } from "./CanceledContent"
import { IconDefinitionList } from "./IconDefinitionList"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"
import { PanelistPostApplicationActions } from "./PanelistPostApplicationActions"
import { PanelistProfileCompletionAlert } from "./PanelistProfileCompletionAlert"

export const ModeratedStudyBookingConfirmationPage: React.FC = () => {
  const { application: moderatedStudyApplication } =
    useModeratedStudyApplicationContext()

  const openCancellationModal = !!new URLSearchParams(
    window.location.search
  ).get("cancel")

  if (!moderatedStudyApplication.booking) return null

  if (
    moderatedStudyApplication.booking.state.startsWith("canceled") ||
    // Participants are unlikely to visit this confirmation page while their
    // booking is in a "rescheduled_by_researcher" state. For now we've decided
    // to simply show the generic "canceled" content
    moderatedStudyApplication.booking.state === "rescheduled_by_researcher"
  ) {
    return (
      <CanceledContent moderatedStudyApplication={moderatedStudyApplication} />
    )
  }

  const startsAt = new Date(moderatedStudyApplication.booking.starts_at)
  const isBeforeStartTime = isFuture(startsAt)

  return (
    <Flex direction="column">
      <Heading fontSize="2xl" fontWeight="normal" color="green.500" mb={2}>
        Booking confirmed
      </Heading>
      <Text fontSize="md" color="text.primary">
        A calendar invitation with all the details has been sent to your email.
      </Text>

      <IconDefinitionList mt={4}>
        {moderatedStudyApplication.applicant ? (
          <>
            <IconDefinitionList.Item icon={UserIcon} label="Name">
              <Text color="text.primary">
                {moderatedStudyApplication.applicant.full_name}
                {moderatedStudyApplication.applicant.preferred_name
                  ? ` (${moderatedStudyApplication.applicant.preferred_name})`
                  : null}
              </Text>
            </IconDefinitionList.Item>
            <IconDefinitionList.Item icon={MailIcon} label="Email">
              <Text color="text.primary">
                {moderatedStudyApplication.applicant.email}
              </Text>
            </IconDefinitionList.Item>
          </>
        ) : null}
        <IconDefinitionList.Item icon={CalendarIcon} label="Date & Time">
          <Text color="text.primary">
            {formatBookingDate(moderatedStudyApplication.booking)}
            {` (${moderatedStudyApplication.booking.participant_timezone})`}
          </Text>
        </IconDefinitionList.Item>
      </IconDefinitionList>

      {moderatedStudyApplication.panelist ? (
        <>
          <PanelistPostApplicationActions mt={6} />
          <PanelistProfileCompletionAlert
            pos={["static", null, null, "absolute"]}
            maxW={[null, null, null, "600px"]}
            bottom={[null, null, null, 10]}
            mt={[6, null, null, 0]}
          />
        </>
      ) : (
        isBeforeStartTime && (
          <>
            <Divider my={4} />

            <CancelBooking
              moderatedStudyApplication={moderatedStudyApplication}
              defaultOpen={openCancellationModal}
            />
          </>
        )
      )}
    </Flex>
  )
}
