import {
  Button,
  Link as ChakraLink,
  Flex,
  FlexProps,
  Icon,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Link } from "react-router-dom"

export const PanelistPostApplicationActions: React.FC<FlexProps> = (props) => {
  return (
    <Flex align="center" gap={6} {...props}>
      <Button
        as={Link}
        to={ROUTES.DASHBOARD.path}
        colorScheme="brand.primary"
        _hover={{
          color: "white",
          bg: "brand.primary.600",
        }}
      >
        Return to Dashboard
      </Button>
      <ChakraLink
        isExternal
        variant="noUnderline"
        href={Constants.PANELIST_INTERVIEWS_FAQ_URL}
      >
        Read more about interviews <Icon mt="-3px" as={LinkExternalIcon} />
      </ChakraLink>
    </Flex>
  )
}
