import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"

import { RawImageScreenshot as ImageScreenshotType } from "Types"

interface ImageScreenshotProps extends BoxProps {
  screenshot: ImageScreenshotType
}

export const ImageScreenshot: React.FC<
  React.PropsWithChildren<ImageScreenshotProps>
> = ({ screenshot, ...boxProps }) => {
  return <Box as="img" src={screenshot.url || undefined} {...boxProps} />
}
