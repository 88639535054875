import { pickBy } from "lodash"
import { useEffect, useMemo, useState } from "react"
import {
  OnboardingData,
  OnboardingStepId,
  OnboardingStepValues,
  allDefaultValues,
  getStep,
} from "./constants"
import { OnboardingApi } from "./useOnboardingApi"

export const useOnboardingValues = <ID extends OnboardingStepId>(
  stepId: ID,
  api: OnboardingApi
) => {
  const [data, setData] = useState<OnboardingData>(allDefaultValues)
  const mergeValues = (v: OnboardingStepValues<ID>) =>
    setData((d) => ({ ...d, ...v }))
  useEffect(() => {
    !api.isFetched && api.fetch().then(mergeValues)
  }, [stepId, api.isFetched])

  const values = useMemo(() => extractValues(stepId, data), [stepId, data])
  return { values, mergeValues }
}

const extractValues = <ID extends OnboardingStepId>(
  stepId: ID,
  data: OnboardingData
) => {
  const { schema } = getStep(stepId)
  return pickBy(
    data,
    (_, key) => key in schema.fields
  ) as OnboardingStepValues<ID>
}
