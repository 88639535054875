import { Center, Spinner } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { DocumentPictureInPictureProvider } from "UsabilityHub/components/DocumentPictureInPicture/DocumentPictureInPictureProvider"
import { useSectionRecordingContext } from "UsabilityHub/components/UsabilityTest/context/SectionRecordingContext"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"
import React, { useState } from "react"
import { LiveWebsiteTestTaskActive } from "./LiveWebsiteTestTaskActive"

export const LiveWebsiteTestTask: React.FC<
  React.PropsWithChildren<InnerProps>
> = ({ responseSection, updateResponseSection, usabilityTestSection }) => {
  const t = useTranslate()

  const { endOldTask, startNewTask } = useSectionRecordingContext()

  const [clickStartTime, setClickStartTime] = useState<number | null>(null)
  const [lastTaskEndedTime, setLastTaskEndedTime] = useState<number | null>(
    null
  )
  const [
    isSectionFinishedButStillUploading,
    setIsSectionFinishedButStillUploading,
  ] = useState(false)

  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)

  const startLiveWebsiteTest = () => {
    const startTime = performance.now()

    setClickStartTime(startTime)

    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: startTime - sectionStartTime(responseSection),
    })
  }

  const finishLiveWebsiteTest = () => {
    const finishTime = performance.now()

    // updateResponseSection doesn't actually update the responseSection immediately because of Recordings Magic™, it will
    // only update after all the queued uploads finish. So we need to store an interim loading state.
    // (See `updateResponseSectionWithRecording` in `SectionRecordingContext` for more details on the Magic™)
    updateResponseSection(usabilityTestSection.id, {
      task_duration_ms: finishTime - (clickStartTime ?? 0),
    })
    setIsSectionFinishedButStillUploading(true)
  }

  const startTask = () => {
    const allTaskIds =
      usabilityTestSection.live_website_test?.live_website_test_tasks.map(
        (t) => t.id
      ) ?? []
    const completedTaskCount =
      responseSection?.response_section_live_website_test_tasks?.length ?? 0
    const nextTaskId = allTaskIds[completedTaskCount]

    startNewTask(nextTaskId)
  }

  const finishTask = (lastTask: boolean) => {
    const finishTime = performance.now()

    setLastTaskEndedTime(finishTime)

    const allTaskIds =
      usabilityTestSection.live_website_test?.live_website_test_tasks.map(
        (t) => t.id
      ) ?? []
    const nextTaskIndex =
      responseSection?.response_section_live_website_test_tasks?.length ?? 0

    updateResponseSection(usabilityTestSection.id, {
      response_section_live_website_test_tasks: [
        ...(responseSection?.response_section_live_website_test_tasks ?? []),
        {
          live_website_test_task_id: allTaskIds[nextTaskIndex],
          duration_ms: finishTime - (lastTaskEndedTime ?? clickStartTime ?? 0),
          recording_ids: [], // These get added in the `ADD_RESPONSE_SECTION_RECORDING` action
        },
      ],
    })

    if (!lastTask) {
      // This kicks off the recording upload between tasks. If it _is_ the last task, the upload is handled
      // by setting the `finishAction` bool in the `SectionRecordingContext`
      endOldTask()
    }
  }

  if (isSectionFinishedButStillUploading) {
    return (
      <Center w="full">
        {t("test.live_website_test.recordings_in_progress")}{" "}
        <Spinner ms={2} size="xs" />
      </Center>
    )
  }

  if (!isComplete) {
    return (
      <DocumentPictureInPictureProvider>
        <LiveWebsiteTestTaskActive
          usabilityTestSection={usabilityTestSection}
          isStarted={isStarted}
          handleStart={startLiveWebsiteTest}
          handleFinish={finishLiveWebsiteTest}
          handleTaskStart={startTask}
          handleTaskEnd={finishTask}
        />
      </DocumentPictureInPictureProvider>
    )
  }

  // On task completion it will automatically move to the next section
  return null
}
