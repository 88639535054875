import { createIcon } from "@chakra-ui/react"
import React from "react"

export const StatusFilledIcon = createIcon({
  displayName: "StatusFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      d="M13.9923 2.12409C14.0473 1.68359 13.8054 1.25952 13.3982 1.08275C12.991 0.905986 12.516 1.01884 12.2318 1.35987L3.3023 12.0752C3.14798 12.2603 2.99168 12.4478 2.87788 12.6126C2.77026 12.7684 2.57375 13.0748 2.56768 13.4774C2.56072 13.9384 2.76614 14.377 3.12471 14.6667C3.43793 14.9198 3.79909 14.965 3.9877 14.9821C4.18712 15.0002 4.43121 15.0001 4.6722 15.0001L10.8672 15.0001L10.0077 21.876C9.95264 22.3165 10.1946 22.7406 10.6018 22.9174C11.009 23.0941 11.484 22.9813 11.7682 22.6402L20.6977 11.9249C20.852 11.7398 21.0083 11.5523 21.1221 11.3875C21.2297 11.2317 21.4262 10.9253 21.4323 10.5227C21.4392 10.0617 21.2338 9.62314 20.8753 9.33338C20.562 9.08027 20.2009 9.03509 20.0123 9.01802C19.8128 8.99996 19.5687 9.00001 19.3277 9.00005L13.1328 9.00006L13.9923 2.12409Z"
      fill="currentColor"
    />
  ),
})
