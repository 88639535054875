import { Flex, Text } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { InfoFilledIcon } from "Icons/InfoFilledIcon"
import React from "react"
import { SectionCard } from "../SectionCard"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"
import { LiveWebsiteTestTaskList } from "./LiveWebsiteTestTaskList"

export const LiveWebsiteTestSectionCard: React.FC = () => {
  return (
    <SectionCard>
      <SectionLogicActions />

      <BrowserSupportBanner />

      <LiveWebsiteTestTaskList />
    </SectionCard>
  )
}

const BrowserSupportBanner: React.FC = () => {
  return (
    <Flex
      align="center"
      w="calc(100% + 64px)" // To account for the padding on the section card
      bg="ds.background.information.subtle.resting"
      gap={2}
      p={4}
      mx={-8} // To account for the padding on the section card
    >
      <InfoFilledIcon boxSize={5} color="ds.icon.info" />
      <Text color="ds.text.default" textStyle="ds.paragraph.primary">
        Live website testing is only supported on Chrome, Edge, and other
        Chromium-based desktop browsers.
      </Text>

      <Button size="compact" ms="auto">
        Learn more
      </Button>
    </Flex>
  )
}
