import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { CheckCircleIcon } from "Icons/CheckCircleIcon"
import { LiveWebsiteTaskQuestions } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/LiveWebsiteTestSectionCard/LiveWebsiteTaskQuestions"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { validateStringLength } from "UsabilityHub/components/TestForm/validators/validateStringLength"
import { validateUrl } from "UsabilityHub/components/TestForm/validators/validateUrl"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"
import { LiveWebsiteTaskContextMenu } from "./LiveWebsiteTaskContextMenu"

type Props = {
  // Needs to match the position of the task in the live_website_test_tasks array
  taskIndex: number
  // For display purposes only
  taskNumber: number
}

export const LiveWebsiteTask: React.FC<Props> = ({ taskIndex, taskNumber }) => {
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const taskPath = `sections[${sectionIndex}].live_website_test.live_website_test_tasks[${taskIndex}]`
  const task = section.live_website_test?.live_website_test_tasks[taskIndex]

  return (
    <Flex direction="column" gap={7}>
      <Flex justify="space-between">
        <Flex align="center" gap={3}>
          <CheckCircleIcon boxSize={5} color="ds.icon.subtle" />

          <Heading
            as="h4"
            color="ds.text.default"
            textStyle="ds.display.secondary"
          >
            Task {taskNumber}
          </Heading>
        </Flex>

        <LiveWebsiteTaskContextMenu taskIndex={taskIndex} />
      </Flex>

      <Field
        name={`${taskPath}.url`}
        component={UrlField}
        validate={validateUrl("Must be a valid URL")}
        validateOnBlur
      />
      <Field
        name={`${taskPath}.instructions`}
        component={InstructionsField}
        validate={validateStringLength("Instructions must be present")}
        validateOnBlur
      />
      <Field
        name={`${taskPath}.estimated_duration_in_minutes`}
        component={DurationField}
      />

      {task && <LiveWebsiteTaskQuestions task={task} taskPath={taskPath} />}
    </Flex>
  )
}

const UrlField: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <Flex direction="column" gap={3}>
        <Flex direction="column" gap={1.5}>
          <Text color="ds.text.default" textStyle="ds.heading.secondary">
            URL
          </Text>
          <Text color="ds.text.default" textStyle="ds.paragrah.secondary">
            The web address where the participant will be directed.
          </Text>
        </Flex>

        <Input
          isReadOnly={readOnly}
          placeholder="https://example.com"
          {...input}
        />
      </Flex>

      {meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  )
}

const InstructionsField: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <Flex direction="column" gap={3}>
        <Flex direction="column" gap={1.5}>
          <Text color="ds.text.default" textStyle="ds.heading.secondary">
            Instruction
          </Text>
          <Text color="ds.text.default" textStyle="ds.paragrah.secondary">
            Provide clear task instructions for the participant to follow on
            your live site, or ask them for feedback or to answer specific
            questions while browsing the website.
          </Text>
        </Flex>

        <Textarea
          isReadOnly={readOnly}
          placeholder="Instructions go here"
          {...input}
        />
      </Flex>

      {meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  )
}

export const LWT_TASK_DURATION_OPTIONS = [
  { min: 1, max: 2 },
  { min: 3, max: 5 },
  { min: 6, max: 10 },
  { min: 11, max: 15 },
]

const DurationField: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl isInvalid={!!meta.error}>
      <Flex direction="column" gap={3}>
        <Flex direction="column" gap={1.5}>
          <Text color="ds.text.default" textStyle="ds.heading.secondary">
            Estimated task length
          </Text>
          <Text color="ds.text.default" textStyle="ds.paragrah.secondary">
            Indicate how much time participants should expect to spend on the
            task.
          </Text>
        </Flex>

        <Select maxW="fit-content" isReadOnly={readOnly} {...input}>
          {LWT_TASK_DURATION_OPTIONS.map(({ min, max }) => (
            <option key={max} value={max}>{`${min}-${max} minutes`}</option>
          ))}
        </Select>
      </Flex>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
