import { ButtonSelect } from "Components/form/button-select"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

type Props = {
  name: string
  options: { value: string; label: string }[]
}

export const ButtonSelectField: React.FC<Props> = ({ name, options }) => {
  return (
    <Field name={name} component={ButtonSelectFieldAdapter} options={options} />
  )
}

const ButtonSelectFieldAdapter: React.FC<WrappedFieldProps & Props> = ({
  options,
  input: { value, onChange },
}) => {
  return <ButtonSelect options={options} value={value} onChange={onChange} />
}
