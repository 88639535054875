import { Box, HStack, Icon, IconButton, Input } from "@chakra-ui/react"
import React, { FocusEventHandler } from "react"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldProps } from "redux-form"

import { Dispatch } from "Redux/app-store"
import { deleteQuestionOption } from "Redux/reducers/test-builder-form/action-creators/options"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useQuestionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import { minimumMultipleChoiceOptionCount } from "Utilities/usability-test-section-question"

import { useShouldAutoFocus } from "../../../useShouldAutofocus"
import {
  DraggableQuestionRow,
  DraggableRowHandle,
} from "../../DragDrop/DraggableQuestionRow"

import { DeleteIcon } from "Icons/DeleteIcon"
import { useSetCustomValidity } from "./useSetCustomValidity"

interface ChoiceFieldProps {
  onEnterPress: (optionIndex: number) => void
  onMultilinePaste: (lines: string[]) => void
  skipAutoFocus: boolean
  optionIndex: number
}

const WrappedChoiceField: React.FC<WrappedFieldProps & ChoiceFieldProps> = ({
  input,
  meta,
  optionIndex,
  skipAutoFocus,
  onEnterPress,
  onMultilinePaste,
}) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const { question, questionIndex } = useQuestionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const optionCount = question.multiple_choice_options.length

  // We can't avoid using indexes here because options have no unique ID.
  const draggableId = `${question._clientId}.${optionIndex}`

  const minChoiceCount = minimumMultipleChoiceOptionCount(question.type)
  const isDeleteDisabled = optionCount <= minChoiceCount

  const autoFocus = useShouldAutoFocus(input.value) && !skipAutoFocus
  const inputRef = useSetCustomValidity(meta.error)

  const onDeleteOption = () => {
    dispatch(deleteQuestionOption(sectionIndex, questionIndex, optionIndex))
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const text = event.clipboardData.getData("text")

    if (!text.includes("\n")) return

    event.preventDefault()
    onMultilinePaste(text.split("\n"))
  }

  return (
    <Draggable
      isDragDisabled={readOnly}
      draggableId={draggableId}
      key={draggableId}
      index={optionIndex}
    >
      {(draggable, snapshot) => (
        <DraggableQuestionRow draggableProvided={draggable} snapshot={snapshot}>
          <HStack py={2} px={2} data-qa={`option-${optionIndex}`}>
            {!readOnly && <DraggableRowHandle draggableProvided={draggable} />}
            <Input
              ref={inputRef}
              autoFocus={autoFocus}
              isRequired
              isInvalid={!meta.valid}
              isReadOnly={readOnly}
              onKeyDown={(evt) =>
                evt.key === "Enter" && onEnterPress(optionIndex)
              }
              onPaste={handlePaste}
              {...input}
            />
            <Box>
              <IconButton
                mr={-2}
                variant="ghost"
                aria-label="Delete option"
                icon={<Icon color="gray.500" boxSize={5} as={DeleteIcon} />}
                onClick={onDeleteOption}
                isDisabled={readOnly || isDeleteDisabled}
              />
            </Box>
          </HStack>
        </DraggableQuestionRow>
      )}
    </Draggable>
  )
}

export const ChoiceField: React.FC<
  React.PropsWithChildren<ChoiceFieldProps>
> = ({ optionIndex, skipAutoFocus, onEnterPress, onMultilinePaste }) => {
  // Supress onBlur event since it updates empty strings to null
  const onBlur: FocusEventHandler<HTMLInputElement> = (event) =>
    event.preventDefault()
  return (
    <Field
      name={`multiple_choice_options[${optionIndex}]`}
      component={WrappedChoiceField}
      optionIndex={optionIndex}
      skipAutoFocus={skipAutoFocus}
      onEnterPress={onEnterPress}
      onBlur={onBlur}
      onMultilinePaste={onMultilinePaste}
    />
  )
}
