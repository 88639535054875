import { createIcon } from "@chakra-ui/react"
import React from "react"

export const HideFilledIcon = createIcon({
  displayName: "HideFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289C9.71611 8.3019 15.7036 14.2894 21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L17.1392 18.5534C15.6943 19.3988 13.9716 20 12 20C9.13832 20 6.80533 18.7353 5.07674 17.2926C3.35049 15.8518 2.16695 14.1857 1.57421 13.2472L1.55012 13.2092C1.43249 13.024 1.27975 12.7836 1.20255 12.4468C1.14023 12.1748 1.14025 11.825 1.20261 11.553C1.27985 11.2161 1.43302 10.9751 1.55096 10.7895L1.57524 10.7512C2.17761 9.79768 3.4009 8.07527 5.19535 6.60956L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289ZM8.55392 9.96814C8.20216 10.5638 8 11.2591 8 11.9999C8 14.2091 9.79086 15.9999 12 15.9999C12.7409 15.9999 13.4361 15.7977 14.0318 15.446L12.5182 13.9324C12.3531 13.9765 12.1795 13.9999 12 13.9999C10.8954 13.9999 10 13.1045 10 11.9999C10 11.8204 10.0235 11.6468 10.0675 11.4818L8.55392 9.96814Z"
        fill="currentColor"
      />
      <path
        d="M15.9923 11.7496C15.8682 9.73992 14.2601 8.13182 12.2504 8.00771L8.79162 4.54897C9.77122 4.20697 10.8424 4 12 4C14.8617 4 17.1947 5.26472 18.9233 6.70743C20.6495 8.14818 21.833 9.81429 22.4258 10.7528L22.4499 10.7908C22.5675 10.976 22.7202 11.2163 22.7974 11.5532C22.8597 11.8251 22.8597 12.1749 22.7974 12.4468C22.7202 12.7837 22.5675 13.024 22.4499 13.2092L22.4258 13.2472C22.0023 13.9177 21.2772 14.9597 20.2681 16.0255L15.9923 11.7496Z"
        fill="currentColor"
      />
    </>
  ),
})
