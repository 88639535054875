import { Button, useToast } from "@chakra-ui/react"
import { JsForm } from "Components/form/form"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { useUpdateSubscription } from "~/api/generated/usabilityhub-components"

type Props = {
  buttonText: string
  planUniqueId: string
  subscriptionId: string
  onClose: () => void
}

export const ChangeSubscriptionPlanForm: React.FC<Props> = ({
  buttonText,
  onClose,
  planUniqueId,
  subscriptionId,
}) => {
  const toast = useToast()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const { mutate: updateSubscription, isLoading: isSubmitting } =
    useUpdateSubscription({
      onSuccess: (data) => {
        onClose()
        toast({
          status: "success",
          title: data.message,
        })
        // Update the subscription and account state so we can show the new
        // plan details immediately
        refreshCurrentAccount()
      },
      onError: (error) => {
        toast({
          status: "error",
          title: error.payload.message ?? "Unable to update subscription",
        })
      },
    })

  const handleSubmit = async () => {
    updateSubscription({
      pathParams: {
        guid: subscriptionId,
      },
      body: {
        plan_unique_id: planUniqueId,
        resume_subscription: true,
      },
    })
  }

  return (
    <JsForm onSubmit={handleSubmit}>
      <Button onClick={onClose} mr={3}>
        Cancel
      </Button>
      <Button
        type="submit"
        colorScheme="brand.primary"
        isLoading={isSubmitting}
      >
        {buttonText}
      </Button>
    </JsForm>
  )
}
