import { UsabilityTest } from "Types"

import { RelatedData } from "../types/related-data"
import { ResponseWithAttributes } from "../types/response"

import { filterResponseByFilter } from "./filters/filter-response-by-filter"
import { ResponseFilter } from "./filters/types"
import { getFilteredResponseRelatedData } from "./get-filtered-related-data"
import { groupFilterByCategory } from "./group-filter"

const emptyRelatedData: RelatedData = {
  responseAnswers: [],
  clientHitzones: [],
  screenshotClicks: [],
  responseSections: [],
  responseSectionFigmaTasks: [],
  answerTags: [],
  hitzones: [],
  usabilityTest: {} as UsabilityTest,
  cardSortCategoryCards: [],
  treeTestPaths: [],
  screenerQuestions: [],
  screenerResponses: [],
}

/**
 * The provided filters will be grouped by categories
 * Each response needs to match at least 1 (.some()) filter out of every (.every()) category
 */
export function filterResponses(
  responses: ResponseWithAttributes[],
  filters: ResponseFilter[],
  allRelatedData = emptyRelatedData
): ResponseWithAttributes[] {
  if (filters.length === 0) return responses

  const filterGroups = groupFilterByCategory(filters)

  return responses.filter((response) => {
    const filteredRelatedData = getFilteredResponseRelatedData(
      response,
      allRelatedData
    )

    const isFilter = filterGroups.every((filterGroup) =>
      filterGroup.some((filter) =>
        filterResponseByFilter({
          response,
          filter,
          filteredRelatedData,
          allRelatedData,
        })
      )
    )

    return isFilter
  })
}
