import { HStack, Icon, Text } from "@chakra-ui/react"
import { TimeFilledIcon } from "Icons/TimeFilledIcon"
import React, { HTMLAttributes, ReactNode } from "react"

interface TextStatProps extends HTMLAttributes<HTMLDivElement> {
  icon: typeof TimeFilledIcon
  iconColor?: string
  isHighlighted: boolean
  children: ReactNode
}

const highlightColour = "brand.primary.500"

export const PrototypeResultsTextStat = React.forwardRef<
  HTMLDivElement,
  TextStatProps
>(({ icon, isHighlighted, iconColor, children, ...props }, forwardedRef) => {
  return (
    <HStack ref={forwardedRef} spacing={1} color="text.secondary" {...props}>
      <Icon as={icon} color={isHighlighted ? highlightColour : iconColor} />
      <Text textStyle="label" color={isHighlighted ? highlightColour : ""}>
        {children}
      </Text>
    </HStack>
  )
})

PrototypeResultsTextStat.displayName = "TextStat"
