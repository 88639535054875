import { createIcon } from "@chakra-ui/react"
import React from "react"

export const PictureInPictureIcon = createIcon({
  displayName: "PictureInPictureIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2413 2H6.7587C5.95373 1.99999 5.28937 1.99998 4.74818 2.04419C4.18608 2.09012 3.66937 2.18868 3.18404 2.43597C2.43139 2.81947 1.81947 3.43139 1.43597 4.18404C1.18868 4.66937 1.09012 5.18608 1.04419 5.74817C0.999978 6.28936 0.999987 6.95372 1 7.75868V16.2413C0.999987 17.0463 0.999978 17.7106 1.04419 18.2518C1.09012 18.8139 1.18868 19.3306 1.43597 19.816C1.81947 20.5686 2.43139 21.1805 3.18404 21.564C3.66937 21.8113 4.18608 21.9099 4.74818 21.9558C5.28936 22 5.95372 22 6.75868 22H10C10.5523 22 11 21.5523 11 21C11 20.4477 10.5523 20 10 20H6.8C5.94342 20 5.36113 19.9992 4.91104 19.9624C4.47262 19.9266 4.24842 19.8617 4.09202 19.782C3.71569 19.5903 3.40974 19.2843 3.21799 18.908C3.1383 18.7516 3.07337 18.5274 3.03755 18.089C3.00078 17.6389 3 17.0566 3 16.2V7.8C3 6.94342 3.00078 6.36113 3.03755 5.91104C3.07337 5.47262 3.1383 5.24842 3.21799 5.09202C3.40974 4.7157 3.71569 4.40973 4.09202 4.21799C4.24842 4.1383 4.47262 4.07337 4.91104 4.03755C5.36113 4.00078 5.94342 4 6.8 4H17.2C18.0566 4 18.6389 4.00078 19.089 4.03755C19.5274 4.07337 19.7516 4.1383 19.908 4.21799C20.2843 4.40973 20.5903 4.7157 20.782 5.09202C20.8617 5.24842 20.9266 5.47262 20.9624 5.91104C20.9992 6.36113 21 6.94342 21 7.8V9C21 9.55229 21.4477 10 22 10C22.5523 10 23 9.55229 23 9V7.7587C23 6.95373 23 6.28937 22.9558 5.74817C22.9099 5.18608 22.8113 4.66937 22.564 4.18404C22.1805 3.43139 21.5686 2.81947 20.816 2.43597C20.3306 2.18868 19.8139 2.09012 19.2518 2.04419C18.7106 1.99998 18.0463 1.99999 17.2413 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5681 12C15.5787 12 15.5894 12 15.6 12H20.4C20.4106 12 20.4213 12 20.4319 12C20.6843 12 20.9301 11.9999 21.1382 12.0169C21.3668 12.0356 21.6366 12.0797 21.908 12.218C22.2843 12.4097 22.5903 12.7157 22.782 13.092C22.9203 13.3634 22.9644 13.6332 22.9831 13.8618C23.0001 14.0699 23 14.3157 23 14.5681V19.4319C23 19.6843 23.0001 19.9301 22.9831 20.1382C22.9644 20.3668 22.9203 20.6366 22.782 20.908C22.5903 21.2843 22.2843 21.5903 21.908 21.782C21.6366 21.9203 21.3668 21.9644 21.1382 21.9831C20.9301 22.0001 20.6843 22 20.4319 22H15.5681C15.3157 22 15.0699 22.0001 14.8618 21.9831C14.6332 21.9644 14.3634 21.9203 14.092 21.782C13.7157 21.5903 13.4097 21.2843 13.218 20.908C13.0797 20.6366 13.0356 20.3668 13.0169 20.1382C12.9999 19.9301 13 19.6843 13 19.4319C13 19.4213 13 19.4106 13 19.4V14.6C13 14.5894 13 14.5787 13 14.5681C13 14.3157 12.9999 14.0699 13.0169 13.8618C13.0356 13.6332 13.0797 13.3634 13.218 13.092C13.4097 12.7157 13.7157 12.4097 14.092 12.218C14.3634 12.0797 14.6332 12.0356 14.8618 12.0169C15.0699 11.9999 15.3157 12 15.5681 12ZM15.0114 14.0114C15.0111 14.0156 15.0107 14.02 15.0103 14.0246C15.0008 14.1412 15 14.3035 15 14.6V19.4C15 19.6965 15.0008 19.8588 15.0103 19.9754C15.0107 19.98 15.0111 19.9844 15.0114 19.9886C15.0156 19.9889 15.02 19.9893 15.0246 19.9897C15.1412 19.9992 15.3035 20 15.6 20H20.4C20.6965 20 20.8588 19.9992 20.9754 19.9897C20.98 19.9893 20.9844 19.9889 20.9886 19.9886C20.9889 19.9844 20.9893 19.98 20.9897 19.9754C20.9992 19.8588 21 19.6965 21 19.4V14.6C21 14.3035 20.9992 14.1412 20.9897 14.0246C20.9893 14.02 20.9889 14.0156 20.9886 14.0114C20.9844 14.0111 20.98 14.0107 20.9754 14.0103C20.8588 14.0008 20.6965 14 20.4 14H15.6C15.3035 14 15.1412 14.0008 15.0246 14.0103C15.02 14.0107 15.0156 14.0111 15.0114 14.0114Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 6.29289C5.68342 5.90237 6.31658 5.90237 6.70711 6.29289L10 9.58579V7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7V12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H8.58579L5.29289 7.70711C4.90237 7.31658 4.90237 6.68342 5.29289 6.29289Z"
        fill="currentColor"
      />
    </>
  ),
})
