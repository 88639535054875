import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react"
import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import { EditCommentForm } from "Components/comment-thread/EditCommentForm"
import { deletedUserName } from "Constants/user"
import { Z_INDEX_COMMENT_THREADS } from "Constants/zIndexes"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { motion } from "framer-motion"
import React from "react"
import { useDeleteComments } from "~/api/generated/usabilityhub-components"
import { Comment as CommentType } from "~/api/generated/usabilityhubSchemas"
import { AutoUpdatingRelativeTime } from "./AutoUpdatingRelativeTime"
import { UserPopover } from "./UserPopover"
import { processContent } from "./processContent"

const MotionBox = motion(Box)

type Props = {
  comment: CommentType
  isEntityPersisted: boolean
}

export const Comment: React.FC<Props> = ({ comment, isEntityPersisted }) => {
  const toast = useToast()
  const {
    usabilityTestUniqueId,
    deleteComment: removeCommentFromCache,
    deleteCommentLocally,
  } = useLocalCommentContext()
  const currentUser = useCurrentUser()
  const [isEditing, { on: startEditing, off: stopEditing }] = useBoolean(false)

  const deleteMutation = useDeleteComments({
    onSuccess: () => removeCommentFromCache(comment),
  })

  const deleteComment = () => {
    if (isEntityPersisted) {
      deleteMutation.mutate({ pathParams: { commentId: comment.id } })
    } else {
      deleteCommentLocally(comment.id)
    }
  }

  const copyLinkToComment = async () => {
    // We can't copy a link to a comment if the test has never been saved
    // since we don't know what the usabilityTestId will end up being.
    if (usabilityTestUniqueId === null) {
      toast({
        title: "Please save the test to copy the link.",
        status: "error",
        duration: 3000,
      })

      return
    }

    const url = new URL(window.location.href)
    url.hash = `comment-${comment.id}`
    await navigator.clipboard.writeText(url.toString())

    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 3000,
    })
  }

  const isAdmin = currentUser.role === "admin"
  const isAuthor = comment.user && comment.user.id === String(currentUser.id)
  const canEditComment = isAuthor
  const canDeleteComment = isAdmin || isAuthor
  const userName = comment.user?.name ?? deletedUserName

  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      id={`c-${comment.id}`}
      sx={{
        "& + &": {
          borderTopWidth: 1,
          borderColor: "gray.200",
        },
      }}
    >
      <Flex
        px={4}
        py={2}
        direction="column"
        gap={2}
        backgroundColor={comment.is_read ? undefined : "yellow.100"}
        transition="1s background-color"
        _first={{ pt: 4 }}
        _last={{ pb: 4 }}
      >
        <Flex alignItems="center" gap={2}>
          <Avatar
            size="sm"
            boxSize={6}
            fontSize="xs"
            name={userName}
            src={comment.user?.avatar_url}
          />
          <UserPopover userId={Number(comment.user?.id)}>
            {(userName) => (
              <Text
                fontSize="sm"
                lineHeight={5}
                fontWeight="semibold"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                flexGrow={1}
              >
                {userName}
              </Text>
            )}
          </UserPopover>
          <AutoUpdatingRelativeTime
            date={comment.created_at}
            fontSize="xs"
            lineHeight={4}
            color="blackAlpha.500"
            whiteSpace="nowrap"
          />
          <Menu isLazy>
            <MenuButton
              as={IconButton}
              minW="auto"
              variant="ghost"
              icon={<Icon as={MenuKebabIcon} color="gray.500" />}
              boxSize={5}
            />
            <Portal>
              <MenuList zIndex={Z_INDEX_COMMENT_THREADS} overflow="hidden">
                {canEditComment && (
                  <MenuItem onClick={startEditing}>Edit</MenuItem>
                )}
                {canDeleteComment && (
                  <MenuItem onClick={deleteComment}>
                    {comment.entity_type === "comment"
                      ? "Delete"
                      : "Delete thread"}
                  </MenuItem>
                )}
                <MenuItem onClick={copyLinkToComment}>
                  Copy link to comment
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Flex>

        {isEditing ? (
          <EditCommentForm
            comment={comment}
            stopEditing={stopEditing}
            isEntityPersisted={isEntityPersisted}
          />
        ) : (
          <MotionBox
            animate={{ backdropOpacity: 1 }}
            initial={{ backdropOpacity: 0 }}
          >
            <Text fontSize="sm" color="text.primary" whiteSpace="pre-wrap">
              {processContent(comment.content)}
            </Text>
          </MotionBox>
        )}
      </Flex>
    </MotionBox>
  )
}
