import { createIcon } from "@chakra-ui/react"
import React from "react"

export const MenuHamburgerIcon = createIcon({
  displayName: "MenuHamburgerIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M3 12H21M3 6H21M3 18H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})
