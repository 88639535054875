import {
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  IconButton,
  Spacer,
  Text,
} from "@chakra-ui/react"
import { add } from "date-fns"
import { isEqual } from "lodash"
import React, { useCallback, useMemo, useState } from "react"

import { ChevronLeftIcon } from "Icons/ChevronLeftIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { DateOverrides } from "./DayOverrideModal"

const WEEKDAY_ORDER = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]

interface Props {
  overriddenDates?: DateOverrides[]
  selectedDate: [number, number, number] | undefined
  setSelectedDate: (date: [number, number, number]) => void
}

export const Calendar: React.FC<Props> = ({
  // The dates in overriddenDates are in the selected timezone
  overriddenDates,
  selectedDate,
  setSelectedDate,
}) => {
  const [year, month, date] = selectedDate ?? []

  const today = new Date()
  const [currentYear, setCurrentYear] = useState(today.getFullYear())
  const [currentMonth, setCurrentMonth] = useState(today.getMonth())

  const days = useMemo(
    () =>
      Array.from(
        { length: new Date(currentYear, currentMonth + 1, 0).getDate() },
        (_, index) => index + 1
      ),
    [currentYear, currentMonth]
  )

  const isSelectedDate = useCallback(
    (dayNum: number) =>
      currentYear === year && currentMonth === month && dayNum === date,
    [currentMonth, currentYear, date, month, year]
  )

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay()
  const calendarPaddingDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1

  const changeMonth = (delta: number) => {
    return () => {
      const newDate = add(new Date(currentYear, currentMonth), {
        months: delta,
      })

      setCurrentYear(newDate.getFullYear())
      setCurrentMonth(newDate.getMonth())
    }
  }

  return (
    <Flex direction="column">
      <Flex align="center" mb={4}>
        <Text color="text.primary" fontSize="lg" fontWeight="normal">
          <Text as="span" fontWeight="semibold">
            {new Date(currentYear, currentMonth).toLocaleString(
              navigator.language,
              {
                month: "long",
              }
            )}
          </Text>{" "}
          {currentYear}
        </Text>

        <Spacer flexGrow={1} />

        <IconButton
          variant="ghost"
          size="sm"
          icon={
            <Icon boxSize={5} color="ds.icon.subtle" as={ChevronLeftIcon} />
          }
          aria-label="Previous month"
          onClick={changeMonth(-1)}
          isDisabled={new Date(currentYear, currentMonth, 0) < today}
        />
        <IconButton
          variant="ghost"
          size="sm"
          icon={
            <Icon boxSize={5} color="ds.icon.subtle" as={ChevronRightIcon} />
          }
          aria-label="Next month"
          onClick={changeMonth(1)}
        />
      </Flex>

      <Grid
        templateColumns="repeat(7, 42px)"
        templateRows="20px"
        autoRows="42px"
        ml={-1}
        gap={2}
      >
        {WEEKDAY_ORDER.map((day) => (
          <Text key={day} fontSize="xs" align="center">
            {day}
          </Text>
        ))}

        {new Array(calendarPaddingDays).fill(null).map((_, i) => (
          <div key={`padding-${i}`} />
        ))}

        {days.map((dayNum) =>
          (overriddenDates?.find((element) => {
            return isEqual(element.date, [currentYear, currentMonth, dayNum])
          }) &&
            !isSelectedDate(dayNum)) ||
          (new Date(currentYear, currentMonth, dayNum) < today &&
            dayNum < today.getDate()) ? (
            <Center
              key={dayNum}
              fontSize="md"
              fontWeight="normal"
              color="blackAlpha.500"
            >
              {dayNum}
            </Center>
          ) : (
            <Button
              key={dayNum}
              rounded="md"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
              background="white"
              boxShadow="sm"
              borderWidth={1}
              borderColor="gray.200"
              outline={isSelectedDate(dayNum) ? "2px solid" : undefined}
              outlineColor={isSelectedDate(dayNum) ? "blue.500" : undefined}
              _focusVisible={{
                borderColor: "blue.200",
                background: "blue.50",
              }}
              onClick={() => {
                setSelectedDate([currentYear, currentMonth, dayNum])
              }}
            >
              {dayNum}
            </Button>
          )
        )}
      </Grid>
    </Flex>
  )
}
