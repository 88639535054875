import type { ComponentSingleStyleConfig } from "@chakra-ui/react"
import { userCrowdThemeOverrideTokens } from "DesignSystem/tokens"

const baseStyle = ({ colorScheme = "teal" }) => ({
  color: `${colorScheme}.500`,
  fontWeight: "medium",
  textDecoration: "underline",
  _hover: {
    color: `${colorScheme}.700`,
  },
})

// We use this for the Button "link" variant - it has no underline by default,
// but we still have baseStyle above because we want the regular Link component
// to have an underline by default
// TODO: find out how to make the default param "teal" respected here (instead of gray)
export const noUnderlineBaseStyle = ({ colorScheme = "teal" }) => ({
  color: `${colorScheme}.500`,
  fontWeight: "medium",
  textDecoration: "none",
  _hover: {
    color: `${colorScheme}.700`,
  },
})

export const Link: ComponentSingleStyleConfig = {
  baseStyle,
  variants: {
    noUnderline: {
      textDecoration: "none",
      _hover: {
        textDecoration: "none",
      },
    },
  },
}

/**
 * This is a temporary override until we have a proper DS Link component which
 * is aware of whether it's on the UserCrowd domain or not. Once that exists, we
 * can remove this (and once the DS Link is the only link we're using, we can
 * remove the entire file.)
 */
export const UserCrowdLink: ComponentSingleStyleConfig = {
  ...Link,
  baseStyle: {
    ...baseStyle,
    color: userCrowdThemeOverrideTokens.semanticTokens.colors.ds.link.default,
    _hover: {
      color: userCrowdThemeOverrideTokens.semanticTokens.colors.ds.link.pressed,
    },
  },
}
