import React, { useEffect, useMemo, useRef, useState } from "react"

import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonProps,
  Container,
  Flex,
  Grid,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { TreeTestClick } from "JavaScripts/types/response-section"
import { State } from "Redux/app-store"
import { useTranslate } from "Shared/hooks/useTranslate"
import { TreeTestNode } from "Types"
import { AnimatePresence, motion } from "framer-motion"
import { debounce } from "lodash"
import { useSelector } from "react-redux"
import {
  SectionTask,
  SectionTaskContent,
  SectionTaskHeader,
  SectionTaskInstructions,
} from "../SectionTask"

type Node = TreeTestNode & { children: Node[]; path: string[] }

type SubmitButtonProps = ButtonProps & {
  selected: Node | null
  completed: boolean
  onComplete: () => void
}

const buildTree = (
  nodesByParentId: Map<string | null, TreeTestNode[]>,
  parentId: string | null = null,
  path: string[] = []
): Node[] =>
  (nodesByParentId.get(parentId) || []).map((node) => ({
    ...node,
    children: buildTree(nodesByParentId, node.id, [...path, node.label]),
    path: [...path, node.label],
  }))

const TreeTaskSubmit: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  selected,
  completed,
  onComplete,
  ...props
}) => {
  const translate = useTranslate()

  if (completed) return null

  return (
    <motion.div key="confirm-button" layout="position">
      <ThemedButton
        flexGrow={1}
        size="md"
        variant="solid"
        tabIndex={0}
        width={{ base: "100%", md: "auto" }}
        float="right"
        m={1}
        py={1}
        px={6}
        height={10}
        opacity={1}
        transform={`translateX(${selected ? 0 : 12}px)`}
        transition="all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
        transitionProperty="opacity, transform"
        transitionDelay={selected ? "0.2s" : "0s"}
        onClick={() => {
          if (!completed) onComplete()
        }}
        {...props}
      >
        {translate("test.tree_test.confirm_choice")}
      </ThemedButton>
    </motion.div>
  )
}

const TreeTestTaskWrapper: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean
    onClose: () => void
    trigger: React.ReactNode
    submitAction: React.ReactNode
  }>
> = ({ isOpen, onClose, trigger, submitAction }) => {
  const buttonPopperPosition = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (buttonPopperPosition) {
    return (
      <Popover isOpen={isOpen} onClose={onClose} placement="right-end">
        <PopoverTrigger>{trigger}</PopoverTrigger>
        <PopoverContent
          bg="transparent"
          border="none"
          boxShadow="none"
          width="auto"
        >
          {submitAction}
        </PopoverContent>
      </Popover>
    )
  } else {
    return <>{trigger}</>
  }
}

export const TreeTestTask: React.FC<React.PropsWithChildren<InnerProps>> = ({
  responseSection,
  usabilityTestSection,
  updateResponseSection,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)
  const [startTime, setStartTime] = useState(0)

  const [selected, setSelected] = useState<Node | null>(null)
  const [clicks, setClicks] = useState<TreeTestClick[]>([])
  const [completed, setCompleted] = useState<boolean>(false)

  const test = useSelector((state: State) => state.participantUsabilityTest)
  const { tree_id: treeId } = usabilityTestSection

  const tree = useMemo(() => {
    const tree = test?.trees?.[treeId ?? "missing"]
    if (!tree) return []
    const nodesByParentId = tree.nodes.reduce((acc, node) => {
      const parentId = node.parent_id || null
      const nodes = acc.get(parentId) || []
      nodes.push(node)
      acc.set(parentId, nodes)
      return acc
    }, new Map<string | null, TreeTestNode[]>())
    return buildTree(nodesByParentId)
  }, [test, treeId])

  const onStart = () => {
    const clickStartTime = performance.now()
    setStartTime(clickStartTime)

    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms:
        clickStartTime - sectionStartTime(responseSection),
    })
  }

  const addClick = (node: Node | null) => {
    const t =
      performance.now() - (startTime || sectionStartTime(responseSection))
    const newClicks = [
      ...clicks,
      { node_id: node?.id ?? null, milliseconds: t },
    ]
    setClicks(newClicks)
    updateResponseSection(usabilityTestSection.id, {
      tree_test_clicks: newClicks,
    })
  }

  const nodeSelected = (node: Node) => {
    setSelected(selected?.id === node?.id ? null : node)
    addClick(node)
  }

  const onComplete = () => {
    if (!isStarted || selected === null) return
    setPassable(false)
    setCompleted(true)

    updateResponseSection(usabilityTestSection.id, {
      task_duration_ms: performance.now() - startTime,
    })
  }

  const onSkip = () => {
    setSelected(null)
    addClick(null)
    setPassable(false)
    setCompleted(true)

    updateResponseSection(usabilityTestSection.id, {
      task_duration_ms: performance.now() - startTime,
    })
  }

  const [gray100] = useToken("colors", ["gray.100"])

  const PASS_DELAY_TIME = 10000
  const [passable, setPassable] = useState<boolean>(false)
  const [pageBottom, setPageBottom] = useState<boolean>(false)

  useEffect(() => {
    const checkScrollPosition = debounce(
      () => {
        if (
          containerRef &&
          containerRef.current &&
          containerRef.current.parentElement
        ) {
          // get the height of the buttons at the bottom of the page
          // excluding the padding around it. This promotes
          // better scroll behaviour toward the bottom
          // of the page and no flickering when buttons
          // appear/disappear based on selection or passability
          const footerButtonsStyles = containerRef.current?.lastElementChild
            ? window.getComputedStyle(
                containerRef.current?.lastElementChild,
                null
              )
            : null
          const footerButtonsHeight = footerButtonsStyles
            ? parseInt(footerButtonsStyles?.getPropertyValue("height")) -
              (parseInt(footerButtonsStyles?.getPropertyValue("padding-top")) +
                parseInt(
                  footerButtonsStyles.getPropertyValue("padding-bottom")
                ))
            : null

          setPageBottom(
            containerRef.current?.parentElement?.scrollTop >=
              containerRef.current?.parentElement?.scrollHeight -
                containerRef.current?.parentElement?.clientHeight -
                (footerButtonsHeight || 96)
          ) // 96 is a fallback based on the height of the buttons in English on most phone sizes we support
        }
      },
      50,
      { leading: true, trailing: true }
    )

    setTimeout(() => {
      setPassable(true)
      checkScrollPosition()
    }, PASS_DELAY_TIME)

    // here lies the ways in which the scroll or
    // container size can change whether we should
    // recalculate if the bottom of the page is visible
    containerRef.current?.parentElement?.addEventListener(
      "scroll",
      checkScrollPosition
    )

    containerRef.current?.parentElement?.addEventListener(
      "transitionend",
      checkScrollPosition
    )

    return () => {
      containerRef.current?.parentElement?.removeEventListener(
        "scroll",
        checkScrollPosition
      )
    }
  }, [])

  useEffect(() => {
    if (responseSection && !isStarted) onStart()
  }, [responseSection])

  const translate = useTranslate()

  const isDesktop = useBreakpointValue({
    base: false,
    md: true,
  })
  return (
    <SectionTask ref={containerRef}>
      <SectionTaskHeader>
        <SectionTaskInstructions variant={isComplete ? "secondary" : "primary"}>
          <DisplayInlineMarkdownText text={usabilityTestSection.text} />
        </SectionTaskInstructions>
        {!isComplete && (
          <SectionTaskInstructions variant="secondary">
            {translate("test.tree_test.instructions")}
          </SectionTaskInstructions>
        )}
      </SectionTaskHeader>
      <SectionTaskContent ref={contentRef}>
        <Container
          alignSelf="stretch"
          justifySelf="center"
          display="grid"
          py={10}
          gap={4}
        >
          <Box overflow="auto" gridRowStart="1" gridRowEnd="2">
            <Accordion allowMultiple={false}>
              {tree.map((node) => (
                <TreeItem
                  key={node.id}
                  node={node}
                  selected={selected}
                  completed={completed}
                  onSelect={nodeSelected}
                  onComplete={onComplete}
                />
              ))}
            </Accordion>
          </Box>
        </Container>
      </SectionTaskContent>
      {!completed && (
        // don't display while an item is selected or before the
        // elected wait time
        <Box
          position="sticky"
          bottom="0"
          padding={8}
          bgGradient={`linear(${gray100}00, ${gray100}CC, ${gray100}FF, ${gray100}FF)`}
          w="100%"
        >
          <Container maxW="container.2xl">
            {selected !== null && (
              <TreeTaskSubmit
                selected={selected}
                completed={completed}
                onComplete={onComplete}
                display={{ base: "block", lg: "none" }}
              />
            )}
            <AnimatePresence mode="popLayout">
              {passable && (isDesktop || pageBottom) && (
                <Button
                  as={motion.button}
                  key="pass-button"
                  layout
                  variant="outline"
                  colorScheme="gray"
                  onClick={onSkip}
                  width={{ base: "100%", md: "auto" }}
                  float="right"
                  m={1}
                  py={1}
                  px={6}
                  height={10}
                  initial={{ opacity: 0, transform: "translateY(100%)" }}
                  animate={{ opacity: 1, transform: "translateY(0%)" }}
                  exit={{ opacity: 0, transform: "translateY(100%)" }}
                >
                  {translate("test.tree_test.pass")}
                </Button>
              )}
            </AnimatePresence>
          </Container>
        </Box>
      )}
    </SectionTask>
  )
}

type TreeItemProps = {
  node: Node
  selected: Node | null
  completed: boolean
  onSelect: (node: Node) => void
  onComplete: (node: Node) => void
}

const TreeItemLabel: React.FC<{ labelText: string }> = ({
  labelText: label,
}) => (
  <Text
    gridArea="label"
    textAlign="left"
    lineHeight="1.5"
    fontSize="medium"
    color="text.primary"
    fontWeight="medium"
    size="medium"
  >
    {label}
  </Text>
)

const TreeItem: React.FC<TreeItemProps> = ({
  node,
  selected,
  completed,
  onSelect,
  onComplete,
}) => {
  const [brandNeutralDarkest] = useToken("colors", [
    "brandPalette.neutral.darkest",
  ])

  const isSelected = selected?.id === node.id

  const stateClasses = () => {
    let classString = isSelected ? "selected" : ""
    if (completed) classString += " disabled"

    return classString
  }

  const handleClick = (
    node: Node,
    e: React.MouseEvent | React.KeyboardEvent
  ) => {
    if (!isSelected) {
      if (!completed) onSelect(node)
    } else {
      e.stopPropagation()
    }
  }

  return (
    <AccordionItem border="0" isDisabled={completed}>
      {({ isExpanded }) => (
        <>
          <TreeTestTaskWrapper
            isOpen={isSelected}
            onClose={() => void 0}
            trigger={
              <Flex
                pos="relative"
                cursor="pointer"
                overflow="hidden"
                wrap={{ base: "wrap", lg: "nowrap" }}
                alignItems="center"
              >
                {node.children.length > 0 ? (
                  <AccordionButton
                    className={stateClasses()}
                    disabled={selected?.id != null}
                    display="grid"
                    flexGrow="1"
                    gridTemplateColumns="1.5rem 1fr 1.5rem"
                    gridTemplateAreas={`"icon label selected"`}
                    gap={1}
                    p={0}
                    h="100%"
                    bg="white"
                    border={`1px solid ${brandNeutralDarkest}14`}
                    rounded="md"
                    m={1}
                    px={2}
                    py={1}
                    minHeight={10}
                    _hover={{
                      "&:not(.disabled)": {
                        bg: `${brandNeutralDarkest}14`,
                      },
                    }}
                    _focus={{
                      boxShadow: "0 0 0 2px var(--chakra-colors-border-dark)",
                      outline: "none",
                    }}
                    sx={{
                      "&:disabled": {
                        opacity: 1,
                      },
                      "&.selected": {
                        bg: `${brandNeutralDarkest}0F`,
                        "&:disabled": {
                          bg: "alert.success.background",
                          border:
                            "1px solid var(--chakra-colors-alert-success-icon)",
                          "& > .chakra-text": {
                            color: "alert.success.icon",
                          },
                        },
                      },
                    }}
                    onClick={(e) => handleClick(node, e)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleClick(node, e)
                    }}
                  >
                    <ChevronRightIcon
                      gridArea="icon"
                      color="grey.dark"
                      boxSize={6}
                      transition="transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
                      transform={`rotate(${isExpanded ? 90 : 0}deg)`}
                    />
                    <TreeItemLabel labelText={node.label} />
                    {isSelected && completed && (
                      <CheckCircleFilledIcon
                        color="alert.success.icon"
                        gridArea="selected"
                      />
                    )}
                  </AccordionButton>
                ) : (
                  <Grid
                    tabIndex={completed ? undefined : 0}
                    gridTemplateColumns="1.5rem 1fr 1.5rem"
                    gridTemplateAreas={`"icon label selected"`}
                    flexGrow="1"
                    gap={1}
                    p={0}
                    h="100%"
                    w="100%"
                    bg="white"
                    className={stateClasses()}
                    border={`1px solid ${brandNeutralDarkest}14`}
                    rounded="md"
                    m={1}
                    px={2}
                    py={1}
                    minHeight={10}
                    _hover={{
                      "&:not(.disabled)": {
                        bg: `${brandNeutralDarkest}14`,
                      },
                    }}
                    _focus={{
                      boxShadow: "0 0 0 2px var(--chakra-colors-border-dark)",
                      outline: "none",
                    }}
                    sx={{
                      "&.disabled": {
                        cursor: "not-allowed",
                        pointerEvents: "all",
                        opacity: 1,
                      },
                      "&.selected": {
                        bg: `${brandNeutralDarkest}0F`,
                        outline: "none",
                        border: "1px solid var(--chakra-colors-border-dark)",
                        "&.disabled": {
                          bg: "alert.success.background",
                          border:
                            "1px solid var(--chakra-colors-alert-success-icon)",
                          "& > .chakra-text": {
                            color: "alert.success.icon",
                          },
                        },
                      },
                    }}
                    alignItems="center"
                    onClick={(e) => {
                      if (!completed) {
                        handleClick(node, e)
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && !completed) handleClick(node, e)
                    }}
                  >
                    <TreeItemLabel labelText={node.label} />
                    {isSelected && completed && (
                      <CheckCircleFilledIcon
                        color="brand.primary.500"
                        gridArea="selected"
                      />
                    )}
                  </Grid>
                )}
              </Flex>
            }
            submitAction={
              <TreeTaskSubmit
                selected={selected}
                completed={completed}
                onComplete={() => {
                  if (isSelected) onComplete(node)
                }}
              />
            }
          />
          {node.children.length > 0 && (
            <AccordionPanel p={0} pl={8}>
              <Accordion allowToggle={completed} allowMultiple={false}>
                {node.children.map((child) => (
                  <TreeItem
                    key={child.id}
                    node={child}
                    selected={selected}
                    completed={completed}
                    onSelect={onSelect}
                    onComplete={onComplete}
                  />
                ))}
              </Accordion>
            </AccordionPanel>
          )}
        </>
      )}
    </AccordionItem>
  )
}
