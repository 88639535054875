import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { DuplicateStudyModal } from "Components/duplicate-usability-test-modal/DuplicateStudyModal"
import { DeleteIcon } from "Icons/DeleteIcon"
import { ProjectIcon } from "Icons/ProjectIcon"
import lyssnaPlaceholder from "Images/lyssna-placeholder.svg"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useRemoveTestFromTestSet } from "~/api/generated/usabilityhub-components"
import { VariationSetUsabilityTest } from "~/api/generated/usabilityhubSchemas"

import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import { TestTypeIcons } from "UsabilityHub/views/dashboard/TestTypeIcons"
import { TestStatus } from "UsabilityHub/views/dashboard/UsabilityTestCard"

type Props = {
  testSetUniqueId: string
  test: VariationSetUsabilityTest
}

const usabilityTestCardGridTemplateColumns = [
  "4rem auto 2rem",
  "4rem auto 2rem",
  "4rem 5fr 7rem 12rem 10rem 3.5rem",
  "4rem 5fr 7rem 12rem 10rem 3.5rem",
  "4rem 5fr 7rem 16rem 10rem 3.5rem",
]

export const VariationSetPageUsabilityTestCard: React.FC<Props> = ({
  testSetUniqueId,
  test,
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate: removeTest, isLoading: isRemovingTest } =
    useRemoveTestFromTestSet({
      onSuccess: async () => {
        return queryClient.invalidateQueries([
          "api",
          "test_sets",
          testSetUniqueId,
        ])
      },
    })
  const userCanManageTests = useCurrentUser().can_manage_tests

  const { open: openDuplicateTestModal } = useModal(DuplicateStudyModal)

  const [isLargerThan48em] = useMediaQuery("(min-width: 48em)", { ssr: false })

  const ResponseCountAndStatusCompact = () => (
    <Flex wrap="wrap" alignItems="baseline" columnGap={5} my={2}>
      <Flex justifyContent="center" alignItems="center">
        <Text fontWeight="semibold" fontSize="md">
          {test.response_count}
        </Text>
        <Text color="text.secondary" ms={1} fontSize="md">
          {test.response_count === 1 ? "Response" : "Responses"}
        </Text>
      </Flex>
      <Flex flexDir="column" justifyContent="center">
        <TestStatus status={test.status} statusTags={[]} showDetail={false} />
      </Flex>
    </Flex>
  )

  const ResponseCountAndStatusLarge = () => (
    <>
      <Flex flexDir="column" justifyContent="center">
        <Text fontSize="md" fontWeight="semibold">
          {test.response_count}
        </Text>
        <Text fontSize="xs" color="text.secondary">
          {test.response_count === 1 ? "Response" : "Responses"}
        </Text>
      </Flex>
      <Flex flexDir="column" justifyContent="center">
        <TestStatus
          status={test.status}
          statusTags={test.status_tags}
          showDetail
        />
      </Flex>
    </>
  )

  return (
    <Flex
      minH="88px"
      align="stretch"
      bg={test.archived ? "gray.100" : "white"}
      borderColor="gray.200"
      borderWidth="0 1px 1px 1px"
      basis="100%"
      ps={[4, null, 0]}
      pe={[2, null, 0]}
      py={[4, null, 4]}
      position="relative"
      cursor="pointer"
      _hover={{
        outline: "1px solid",
        outlineColor: "gray.400",
        outlineOffset: -1,
      }}
      data-qa="test-row"
      _first={{
        borderTopRadius: "lg",
        borderTopWidth: 1,
      }}
      onClick={() => {
        navigate(`/tests/${test.unique_id}/results`)
      }}
    >
      <Grid
        flexGrow="1"
        gridTemplateColumns={usabilityTestCardGridTemplateColumns}
        ps={4}
      >
        <Flex
          boxSize={12}
          height="full"
          pt={1}
          alignItems="flex-start"
          my="auto"
        >
          <Box
            bg="gray.200"
            w="full"
            h="auto"
            sx={{ aspectRatio: "1" }}
            bgImage={`url('${test.thumbnail_url ?? lyssnaPlaceholder}')`}
            bgSize="cover"
            rounded="md"
          />
        </Flex>
        <GridItem display="flex" alignItems="center" minWidth={0} pe={6}>
          <Box>
            <Heading as="h3" fontSize="md" fontWeight="medium" lineHeight={5}>
              <Text
                as={Link}
                to={`/tests/${test.unique_id}/results`}
                noOfLines={2}
                _hover={{ color: "brand.primary.500" }}
              >
                {test.name}
              </Text>
              {test.admin_disabled && (
                <Text as="span" color="red.500" fontSize="xs" ps={1} pt={1}>
                  ADMIN DISABLED
                </Text>
              )}
            </Heading>

            {test.sections && (
              <Box mt={0.5}>
                <TestTypeIcons sections={test.sections} />
              </Box>
            )}

            {!isLargerThan48em && <ResponseCountAndStatusCompact />}

            {test.project && (
              <Flex
                fontSize="sm"
                lineHeight={4}
                gap={1}
                mt={2}
                as={Link}
                to={generateFriendlyProjectPath(test.project)}
                _hover={{ color: "brand.primary.500" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Flex gap={1}>
                  <Icon as={ProjectIcon} boxSize={4} />
                  <Text fontWeight="medium" noOfLines={2}>
                    {test.project.name}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Box>
        </GridItem>

        {isLargerThan48em && <ResponseCountAndStatusLarge />}

        <GridItem
          display={["none", null, "flex", "flex", "flex"]}
          justifyContent="center"
          alignItems="center"
        >
          {test.archived ? (
            <Text
              fontSize="md"
              lineHeight={4}
              fontWeight="medium"
              color="gray.600"
            >
              Archived
            </Text>
          ) : (
            userCanManageTests && (
              <Button
                size="md"
                onClick={(e) => {
                  e.stopPropagation()
                  openDuplicateTestModal({ usabilityTest: test })
                }}
              >
                Copy Variation
              </Button>
            )
          )}
        </GridItem>
        <GridItem
          display="flex"
          justifyContent="flex-end"
          alignItems={["flex-start", null, "center"]}
          pe={[0, null, 2]}
          mt={[-1, null]}
        >
          {userCanManageTests && (
            <Button
              colorScheme="red"
              variant="ghost"
              isLoading={isRemovingTest}
              onClick={(e) => {
                e.stopPropagation()
                removeTest({
                  pathParams: {
                    uniqueId: testSetUniqueId,
                  },
                  body: {
                    usability_test_id: test.id,
                  },
                })
              }}
            >
              <Icon as={DeleteIcon} />
            </Button>
          )}
        </GridItem>
      </Grid>
    </Flex>
  )
}
