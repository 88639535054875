import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { PictureInPictureIcon } from "Icons/PictureInPictureIcon"
import { RecordScreenIcon } from "Icons/RecordScreenIcon"
import { RecordThinkAloudIcon } from "Icons/RecordThinkAloudIcon"
import { useTranslate } from "Shared/hooks/useTranslate"
import React from "react"
import { Requirements } from "~/participants/components/Requirements"

const STUDY_REQUIREMENTS = [
  "camera",
  "screen",
  "microphone",
  "external_website",
  "picture_in_picture",
] as const
type StudyRequirement = (typeof STUDY_REQUIREMENTS)[number]
export const isStudyRequirement = (value: string): value is StudyRequirement =>
  (STUDY_REQUIREMENTS as readonly string[]).includes(value)

type Props = {
  requirements: StudyRequirement[]
}

export const StudyRequirements: React.FC<Props> = ({ requirements = [] }) => {
  const translate = useTranslate()

  const camera = requirements.includes("camera")
  const screen = requirements.includes("screen")
  const microphone = requirements.includes("microphone")
  const externalWebsite = requirements.includes("external_website")
  const pictureInPicture = requirements.includes("picture_in_picture")

  return (
    <Requirements.Root>
      {screen && (
        <Requirements.Item
          icon={RecordScreenIcon}
          heading={translate("test.recording.screen.label")}
          description={translate("test.recording.screen.body")}
        />
      )}
      {/* Displays Camera and Audio requirement */}
      {microphone && (
        <Requirements.Item
          icon={RecordThinkAloudIcon}
          heading={translate("test.recording.think_aloud.label")}
          description={
            camera
              ? translate("test.recording.camera.prompt")
              : translate("test.recording.microphone.prompt")
          }
        />
      )}
      {/* External website warning - for Live Website Tests */}
      {externalWebsite && (
        <Requirements.Item
          icon={LinkExternalIcon}
          heading={translate("test.recording.external.label")}
          description={translate("test.recording.external.body")}
        />
      )}
      {/* Picture-in-picture - for Live Website Tests */}
      {pictureInPicture && (
        <Requirements.Item
          icon={PictureInPictureIcon}
          heading="Picture-in-picture"
          // TODO We should translate this as a follow-up
          // heading={translate("test.recording.picture_in_picture.label")}
        />
      )}
    </Requirements.Root>
  )
}
