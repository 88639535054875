import { Flex, Icon, Text } from "@chakra-ui/react"
import { FileInputButton } from "Components/form/file-input/file-input-button"
import { ImageIcon } from "Icons/ImageIcon"
import { Dispatch } from "Redux/app-store"
import { addCardSortCardsImage } from "Redux/reducers/test-builder-form/action-creators/card_sort_cards_image"
import { ValidateFilesResult } from "Services/validate-files"
import { FileDragAndDrop } from "Shared/components/FileDragAndDrop/FileDragAndDrop"
import { ScreenshotMediaType } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { useItemIndexContext } from "UsabilityHub/contexts/ItemIndexContext"
import React from "react"
import { useDispatch } from "react-redux"

interface ImageDropZoneProps {
  allowedMediaTypes: ScreenshotMediaType[]
  id: string
  maxFiles: number
}

export const CardImageDropZone: React.FC<ImageDropZoneProps> = ({
  allowedMediaTypes,
  id,
  maxFiles,
}) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const cardIndex = useItemIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const handleValidateFilesResult = (
    validatedFiles: Readonly<ValidateFilesResult>
  ) => {
    void dispatch(
      addCardSortCardsImage(sectionIndex, cardIndex, validatedFiles)
    )
  }

  if (readOnly) {
    return (
      <Flex
        h="100%"
        justify="center"
        align="center"
        border="2px dashed"
        borderColor="gray.300"
        p={0.5}
        rounded="lg"
      >
        <Icon as={ImageIcon} boxSize={6} color="gray.500" />
      </Flex>
    )
  }

  return (
    <FileDragAndDrop
      allowedMediaTypes={allowedMediaTypes}
      maxFiles={maxFiles}
      handleValidateFilesResult={(result: ValidateFilesResult) =>
        handleValidateFilesResult(result)
      }
      h="full"
    >
      {({ isOver }) => (
        <Flex
          h="100%"
          justify="center"
          bg={isOver ? "brand.primary.50" : "transparent"}
          border="2px dashed"
          borderColor={isOver ? "brand.primary.300" : "gray.300"}
          p={0.5}
          rounded="lg"
          align="center"
          pos="relative"
          _hover={{
            bg: "brand.primary.50",
            borderColor: "brand.primary.300",
          }}
          data-group
        >
          <Text
            display={isOver ? "block" : "none"}
            fontSize="xs"
            lineHeight={4}
            fontWeight="semibold"
            textAlign="center"
            color="brand.primary.500"
            _groupHover={{ display: "block" }}
          >
            Drop <br /> image
            <br /> here
          </Text>
          <Icon
            as={ImageIcon}
            display={isOver ? "none" : "block"}
            boxSize="24px"
            color="gray.500"
            _groupHover={{ display: "none" }}
          />

          <FileInputButton
            w="100%"
            h="100%"
            pos="absolute"
            allowedMediaTypes={allowedMediaTypes}
            id={`${id}_button`}
            maxFiles={maxFiles}
            onFilesSelected={handleValidateFilesResult}
            opacity={0}
          />
        </Flex>
      )}
    </FileDragAndDrop>
  )
}

export const CardImageProcessing: React.FC = () => {
  return (
    <div style={{ height: "100%" }}>
      <Flex
        h="100%"
        justify="center"
        bg="transparent"
        border="2px dashed"
        borderColor="gray.300"
        p={0.5}
        rounded="lg"
        align="center"
        pos="relative"
        _hover={{
          bg: "brand.primary.50",
          borderColor: "brand.primary.300",
        }}
      >
        <Text
          display="block"
          fontSize="xs"
          lineHeight={4}
          fontWeight="semibold"
          textAlign="center"
          color="gray.400"
        >
          Uploading...
        </Text>
      </Flex>
    </div>
  )
}
