import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"
import { OnboardingFormProps } from "../constants"
import { useOnboardingForm } from "../useOnboardingForm"
import { LoadingForm } from "./LoadingForm"

export const TrafficSourceForm: React.FC<OnboardingFormProps<"grapevine">> = ({
  api,
  values,
  wiring,
  submit,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useOnboardingForm({ id: "grapevine", values, wiring })

  return (
    <Stack
      as="form"
      id="onboarding_form"
      spacing={6}
      onSubmit={handleSubmit(submit)}
    >
      <LoadingForm isLoading={api.isLoading}>
        <Stack>
          <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
            One last question…
          </Text>

          <Heading
            as="h1"
            textStyle="ds.display.emphasized"
            color="ds.text.default"
          >
            How did you hear about us?
          </Heading>
        </Stack>
        <FormControl isInvalid={!!errors.trafficSource}>
          <FormLabel>
            <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
              We{"\u2019"}re curious folks around here, always eager to know how
              our awesome community members found their way to us.
            </Text>
          </FormLabel>

          <Input
            autoFocus
            type="text"
            placeholder="Let us know how you heard about us…"
            {...register("trafficSource")}
            mt={2}
          />

          {errors.trafficSource && (
            <FormErrorMessage>{errors.trafficSource.message}</FormErrorMessage>
          )}
        </FormControl>
      </LoadingForm>
    </Stack>
  )
}
