import {
  AnswerTag,
  FigmaFileVersion,
  ParticipantUsabilityTest,
  RawParticipantResponse,
  RawParticipantUsabilityTest,
  RawScreenshot,
  ResponseDeletionReason,
} from "Types"
import {
  ResponseState,
  calculateResponseState,
  isPreview,
} from "Utilities/response"
import { Dictionary, keyBy, uniq } from "lodash"
import React, { createContext, useReducer, useState } from "react"
import type {
  RecruitmentLink,
  UsabilityTestResponseFlow,
} from "~/api/generated/usabilityhubSchemas"
import { loadUsabilityTest as getLoadUsabilityTest } from "../load-usability-test/loadUsabilityTest"
import {
  initialLoadState,
  usabilityTestLoadReducer,
} from "../reducer/usabilityTestLoadReducer"
import { getUsabilityTestScreenshots } from "../screenshots/getUsabilityTestScreenshots"

type Props = {
  currentResponse: RawParticipantResponse | null
  responseState: ResponseState | null
  usabilityTest: RawParticipantUsabilityTest | null
  usabilityTestLoadState: Pick<
    ParticipantUsabilityTest,
    "_isLoading" | "_isLoaded" | "_didLoadFail"
  >
  figmaFileVersions: FigmaFileVersion[]
  screenshotsById: Dictionary<RawScreenshot>
  screenshotsViewed: Record<number, boolean>
  markScreenshotAsViewed: (id: number) => void
  loadUsabilityTest: () => Promise<void>
}

const TestTakingContext = createContext<Props>({
  currentResponse: null,
  responseState: null,
  usabilityTest: null,
  usabilityTestLoadState: {
    _isLoading: false,
    _isLoaded: false,
    _didLoadFail: false,
  },
  figmaFileVersions: [],
  screenshotsById: {},
  screenshotsViewed: {},
  markScreenshotAsViewed: () => {},
  loadUsabilityTest: async () => {},
})

type ProviderProps = {
  flowData: Extract<UsabilityTestResponseFlow, { state: "usability_test" }>
  recruitmentLink: RecruitmentLink
}

// TODO: Start storing data in the context, but only use it for screenshots and figmaFileVersions for now. Will continue to refactor this.
export const TestTakingContextProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({ flowData, recruitmentLink, children }) => {
  const [usabilityTestLoadState, dispatchTestLoadState] = useReducer(
    usabilityTestLoadReducer,
    initialLoadState
  )

  const usabilityTest = flowData.participant_usability_test

  // This stores the response answers that is being submitted to the server.
  const responseData = {
    ...flowData.current_response,
    // The existing RawResponse type has these fields which are not used in
    // the test-taking interface and the new API does not provide them.
    // Once we remove Redux we won't use this type any more and these can
    // be deleted.
    sections: flowData.current_response.sections.map((s) => ({
      ...s,
      figma_file_version_answer: null,
      cards_sort_time: null,
      _taskFlowSuccessAcknowledged: null,
      _startTime: 0,
      response_section_live_website_test_tasks: [],
    })),
    answers: flowData.current_response.answers.map((a) => ({
      ...a,
      id: null,
      paste_detected: false,
      answer_tags: [] as ReadonlyArray<AnswerTag>,
    })),
    review_status: null,
    automated_review_status: null,
    device_type: null,
    platform: null,
    query_parameters: null,
    cint_respondent_id: null,
    deletion_reason: flowData.current_response
      .deletion_reason as ResponseDeletionReason,
  } as Omit<RawParticipantResponse, "id"> as RawParticipantResponse // This type casting is only necessary because of the null ID when previewing

  const currentResponse = {
    ...responseData,
    _isSubmitting: false,
    _creditsEarned: 0,
    _profileCompleteness: 0,
    _hasCompletedDemographicQuestions: false,
    _isDisconnected: false,
  }

  const figmaFileVersions = flowData.figma_file_versions.map((ffv) => ({
    ...ffv,
    last_synced_at: new Date(ffv.last_synced_at),
    last_modified_at: new Date(ffv.last_modified_at),
    frame_titles: ffv.frame_titles as Record<string, string>,
  }))

  // TODO: This is not in use temporarily. Will start using it once we remove Redux.
  const responseState = calculateResponseState(
    {
      ...usabilityTest,
      ...usabilityTestLoadState,
    },
    recruitmentLink,
    currentResponse,
    [] // TODO: demographics
  )

  // Initialize screenshots

  // The old RawScreenshot type has a more complicated discriminated union that the OpenAPI schema
  // does not currently include. When we remove this from Redux we can decide to add it (or not).
  const rawScreenshots = flowData.screenshots as ReadonlyArray<RawScreenshot>

  // This finds the screenshots that are part of the current usability test
  const screenshots = uniq(
    getUsabilityTestScreenshots(rawScreenshots, usabilityTest)
  )

  const screenshotsById = keyBy(screenshots, "id")

  // Initialize screenshotsViewed

  // By default, no screenshots are viewed
  const initialScreenshotsViewed = Object.keys(screenshotsById).reduce(
    (acc, id) => ({ ...acc, [id]: false }),
    {}
  )

  const [screenshotsViewed, setScreenshotViewed] = useState<
    Record<string, boolean>
  >(initialScreenshotsViewed)

  const markScreenshotAsViewed = (id: number) => {
    setScreenshotViewed((prev) => ({
      ...prev,
      [id]: true,
    }))
  }

  return (
    <TestTakingContext.Provider
      value={{
        currentResponse: currentResponse as RawParticipantResponse,
        responseState,
        usabilityTest,
        usabilityTestLoadState,
        figmaFileVersions,
        screenshotsById,
        screenshotsViewed,
        markScreenshotAsViewed,
        loadUsabilityTest: getLoadUsabilityTest(
          isPreview(responseData),
          usabilityTest,
          screenshots,
          figmaFileVersions,
          dispatchTestLoadState
        ),
      }}
    >
      {children}
    </TestTakingContext.Provider>
  )
}

export const useTestTakingContext = () => {
  const context = React.useContext(TestTakingContext)

  // We don't throw an error when child components use this context outside of the provider because some child components are shared between the test-taking and test-results contexts.
  if (context === null)
    return {
      currentResponse: null,
      responseState: null,
      usabilityTest: null,
      usabilityTestLoadState: {
        _isLoading: false,
        _isLoaded: false,
        _didLoadFail: false,
      },
      figmaFileVersions: [],
      screenshotsById: {} as Dictionary<RawScreenshot>,
      screenshotsViewed: {} as Record<number, boolean>,
      markScreenshotAsViewed: () => {},
      loadUsabilityTest: async () => {},
    }

  return context
}
