import { useEffect, useState } from "react"
import { OnboardingStepId, OnboardingStepValues } from "./constants"

export type OnboardingFormWiring<ID extends OnboardingStepId> = {
  watchedValues: OnboardingStepValues<ID> // Follows the form (animations watch this).
  setWatchedValues: (data: OnboardingStepValues<ID>) => void
  isValid: boolean
  setIsValid: (valid: boolean) => void
}

/**
 * Onboarding Form Wiring Hook
 *
 * Provides shared state for the few coupled components.
 * - Data for animations to watch
 * - isValid for the footer actions
 */
export const useOnboardingFormWiring = <ID extends OnboardingStepId>(
  values: OnboardingStepValues<ID>
): OnboardingFormWiring<ID> => {
  const [isValid, setIsValid] = useState(false)
  const [watchedValues, setWatchedValues] =
    useState<OnboardingStepValues<ID>>(values)

  useEffect(() => setWatchedValues(values), [values])

  return { watchedValues, setWatchedValues, isValid, setIsValid }
}
