import { Icon, Progress, useToast } from "@chakra-ui/react"
import classNames from "classnames"
import { isEmpty } from "lodash"
import React, {
  PropsWithChildren,
  PureComponent,
  useContext,
  useEffect,
  useState,
} from "react"

import DeviceFrameComponent, {
  Size,
} from "Components/device-frame/device-frame"
import { HitzoneMap } from "Components/hitzone-map/hitzone-map"
import { AudioIcon } from "Icons/AudioIcon"
import { AppContext } from "Shared/contexts/AppContext"
import {
  DeviceFrame,
  Screenshot,
  ScreenshotFetchStatus,
  ScreenshotHitzone,
} from "Types"
import { elementDimensions } from "Utilities/dimensions"
import { aspectRatio, contain, toCss } from "Utilities/dimensions"

interface Props {
  className: string
  screenshot: Readonly<Screenshot> | null
}

class ContainWrapper extends PureComponent<PropsWithChildren<Props>> {
  element: HTMLElement | null

  handleElement = (element: HTMLElement | null) => {
    this.element = element
  }

  componentDidMount() {
    this.updateImageStyle()
  }

  componentDidUpdate() {
    this.updateImageStyle()
  }

  updateImageStyle() {
    if (this.element === null) return
    if (this.props.screenshot === null) return
    const { parentElement } = this.element
    if (parentElement === null) return
    if (this.props.screenshot.media_type === "audio") return

    const sizeRules = toCss(
      contain(
        aspectRatio(this.props.screenshot),
        elementDimensions(parentElement)
      )
    )
    Object.assign(this.element.style, sizeRules)
  }

  render() {
    return <div ref={this.handleElement} {...this.props} />
  }
}

interface MaybeDeviceFrameProps {
  deviceFrame: null | DeviceFrame
  hitzones: ReadonlyArray<Readonly<ScreenshotHitzone>>
  screenshot: Screenshot | null
  showHitzones: boolean
}

const MaybeDeviceFrame: React.FC<MaybeDeviceFrameProps> = (props) => {
  const { deviceFrame, hitzones, screenshot, showHitzones } = props

  if (!screenshot) return null

  const content = (
    <>
      {screenshot.media_type === "image" && (
        <img
          alt={screenshot.name}
          src={screenshot._url || ""}
          className="ScreenshotEditor-image"
        />
      )}
      {screenshot.media_type === "video" && (
        // biome-ignore lint/a11y/useMediaCaption: We are currently unable to provide transcriptions on customer uploaded videos.
        <video
          preload="metadata"
          src={screenshot._url || ""}
          className="ScreenshotEditor-image"
        />
      )}
      {/* Audio doesn't have a screenshot */}
      {screenshot.media_type === "audio" && (
        <Icon as={AudioIcon} w={10} h={10} />
      )}
      {showHitzones && (
        <HitzoneMap
          className="ScreenshotEditor-hitzoneMap"
          hitzones={hitzones}
        />
      )}
    </>
  )

  if (deviceFrame) {
    return (
      <DeviceFrameComponent
        deviceFrame={deviceFrame}
        size={Size.ScreenshotEditorPreview}
      >
        <div className="ScreenshotEditor-imageWrapper">{content}</div>
      </DeviceFrameComponent>
    )
  }

  return (
    <ContainWrapper
      className="ScreenshotEditor-imageWrapper"
      screenshot={screenshot}
    >
      {content}
    </ContainWrapper>
  )
}

/* Responsbility bloat warning!
 *
 * Be careful, this component is a bit crappy because it handles stuff like
 * caching data URLs and listening for downloads to complete. It will show a
 * white space between showing the preview and loading the actual image because
 * it requests the image by mounting the `img` element in the DOM (replacing the
 * preview).
 *
 * This could be fixed, but it should be done properly to keep things
 * maintainable.
 *
 * See: https://github.com/wearelyssna/hub/issues/1965
 */

interface TestFormEditorScreenshotProps {
  deviceFrame: Readonly<DeviceFrame> | null
  screenshot: Readonly<Screenshot> | null
  hitzones: ReadonlyArray<Readonly<ScreenshotHitzone>>
}

type Message =
  | {
      action: "complete"
      data: {
        status: 200
        url: string
      }
    }
  | {
      action: "failed"
      data: {
        status: 500
        error: string
      }
    }

export const TestFormEditorScreenshot: React.FC<
  TestFormEditorScreenshotProps
> = ({ deviceFrame, screenshot, hitzones }) => {
  if (!screenshot) return null

  const hasHitzones = hitzones.length > 0
  const isUploading = screenshot._progress < 1

  const toast = useToast()
  const { consumer } = useContext(AppContext)
  const [serverScreenshotUrl, setServerScreenshotUrl] = useState<string | null>(
    null
  )

  // Assume it's processing if it's finished uploading and it's not done and there's no URL
  const isProcessing =
    !isUploading &&
    screenshot.status !== ScreenshotFetchStatus.Done &&
    isEmpty(serverScreenshotUrl)

  // Screenshot may be uploaded by:
  // - the client - wait for screenshot._url to be populated via Redux
  // - the server as part of a template - wait to hear the result on this channel
  useEffect(() => {
    const identifier = {
      channel: "ScreenshotProcessingChannel",
      screenshot_id: screenshot.id,
    }

    const channel = consumer?.subscriptions.create(identifier, {
      received: (message: Message) => {
        if (message.action === "complete") {
          setServerScreenshotUrl(message.data.url)
        } else {
          toast({
            title: `Failed to process ${screenshot.name}`,
            status: "error",
          })
        }
      },
    })

    return () => {
      channel?.unsubscribe()
    }
  }, [consumer, screenshot, setServerScreenshotUrl])

  const screenshotPercentage = !isEmpty(serverScreenshotUrl)
    ? 100
    : screenshot._progress * 100
  return (
    <div
      className={classNames("ScreenshotEditor", {
        "ScreenshotEditor--deviceFrame": deviceFrame !== null,
        hasImageData:
          !isEmpty(screenshot._url) || !isEmpty(serverScreenshotUrl),
      })}
    >
      <div className="ScreenshotEditor-imageContainer">
        <div className="ScreenshotEditor-fixedSizeImageWrapper">
          <MaybeDeviceFrame
            deviceFrame={deviceFrame}
            screenshot={{
              ...screenshot,
              _url: screenshot._url ?? serverScreenshotUrl,
            }}
            hitzones={hitzones.filter((hz) => !hz._destroy)}
            showHitzones={hasHitzones}
          />
        </div>
        <div
          className={classNames("ScreenshotEditor-progressBarContainer", {
            isComplete: !isUploading,
          })}
        >
          <Progress
            variant="round"
            size="sm"
            value={screenshotPercentage}
            boxShadow="base"
          />
        </div>
        {isProcessing && (
          <div className="ScreenshotEditor-processingMessage">
            Processing&hellip;
          </div>
        )}
      </div>
    </div>
  )
}
