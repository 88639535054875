import { State } from "Redux/app-store"
import { Dispatch } from "Redux/app-store"
import * as ResponseActions from "Redux/reducers/current-response/action-creators"
import {
  getCurrentResponse,
  isConnected,
  responseStateSelector,
} from "Redux/reducers/current-response/selectors"
import { loadUsabilityTest } from "Redux/reducers/participant-usability-test/action-creators"
import { usabilityTestScreenshotsSelector } from "Redux/reducers/screenshots/selectors"
import {
  Language,
  ResponseDemographicProfile,
  TestBranding,
  ThankYouMessageCopy,
  WelcomeMessageCopy,
} from "Types"
import { UsabilityTestImpl } from "UsabilityHub/components/UsabilityTest/UsabilityTestImpl"
import React from "react"
import type {
  RecordingType,
  RecruitmentLink,
} from "~/api/generated/usabilityhubSchemas"
interface Props {
  language: Readonly<Language>
  recruitmentLink: RecruitmentLink
  testBranding: TestBranding
  thankYouCopy: ThankYouMessageCopy
  welcomeCopy: WelcomeMessageCopy
  allowedRecordingTypes: RecordingType[]
  isExternalStudy: boolean
  isLiveWebsiteTest: boolean
  redirectLink: string
  areAllRecordingsUploaded: boolean
  cleanupScreenStream: () => void
  recordingSetupGuideFinished: boolean
}

import { useDispatch, useSelector } from "react-redux"

export const UsabilityTest: React.FC<Props> = (props) => {
  const dispatch = useDispatch<Dispatch>()

  // TODO: Once we start using the TestTakingContext, we can use the loadState and flowData.participant_usability_test here directly.
  const usabilityTest = useSelector(
    (state: State) => state.participantUsabilityTest
  )
  if (usabilityTest === null) {
    throw new TypeError("usabilityTest === null")
  }

  // TODO: This will be replaced by responseState in TestTakingContext
  const responseState = useSelector((state: Readonly<State>) =>
    responseStateSelector(
      state,
      usabilityTest,
      props.recruitmentLink,
      [] // TODO
    )
  )

  const response = useSelector(getCurrentResponse)
  const isConnectedState = useSelector(isConnected)
  const screenshots = useSelector((state: Readonly<State>) =>
    usabilityTestScreenshotsSelector(state, usabilityTest)
  )

  return (
    <UsabilityTestImpl
      isConnected={isConnectedState}
      response={response}
      responseState={responseState}
      screenshots={screenshots}
      // TODO: This will be replaced by usabilityTestLoadState in TestTakingContext
      didLoadFail={usabilityTest._didLoadFail}
      isLoaded={usabilityTest._isLoaded}
      hasPlayableScreenshot={usabilityTest.has_playable_screenshot}
      loadUsabilityTest={(isPreview) => dispatch(loadUsabilityTest(isPreview))}
      // response actions
      addResponseAnswer={(answer) =>
        dispatch(ResponseActions.addResponseAnswer(answer))
      }
      addResponseDemographics={(demographics: ResponseDemographicProfile) =>
        dispatch(ResponseActions.addResponseDemographics(demographics))
      }
      keepResponseAlive={() => dispatch(ResponseActions.keepResponseAlive())}
      submitResponse={() => dispatch(ResponseActions.submitResponse())}
      updateResponse={(fields) =>
        dispatch(ResponseActions.updateResponse(fields))
      }
      {...props}
      //
    />
  )
}
