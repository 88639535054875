import { GridItem } from "@chakra-ui/react"
import React from "react"
import { StatusTags, SummaryStatus } from "../TestResultsSummary"
import {
  ParticipantsAndScreenerInfoCard,
  ParticipantsCard,
  ResponsesCard,
  StatusCard,
} from "./SummaryMetricsCards"
import { SummaryMetricsCardsGrid } from "./SummaryMetricsCardsGrid"

export const SummaryMetrics: React.FC<{
  status: SummaryStatus | null
  statusTags: StatusTags
  participantsCount: number
  filteredResponsesCount: number
  screenedOutResponseCount: number
  responseCap: number
  usabilityTestUniqueId: string
  hasScreener: boolean
}> = ({
  status,
  statusTags,
  participantsCount,
  filteredResponsesCount,
  responseCap,
  usabilityTestUniqueId,
  hasScreener,
  screenedOutResponseCount,
}) => {
  const screenInRate =
    participantsCount === 0
      ? null
      : participantsCount / (participantsCount + screenedOutResponseCount)

  return (
    <SummaryMetricsCardsGrid>
      <GridItem gridArea="status">
        <StatusCard
          status={status}
          statusTags={statusTags}
          usabilityTestUniqueId={usabilityTestUniqueId}
        />
      </GridItem>
      <GridItem gridArea="participants">
        {hasScreener && screenInRate !== null ? (
          <ParticipantsAndScreenerInfoCard
            count={participantsCount}
            screenInRate={screenInRate}
          />
        ) : (
          <ParticipantsCard count={participantsCount} />
        )}
      </GridItem>
      <GridItem gridArea="responses">
        <ResponsesCard
          count={filteredResponsesCount}
          responseCap={responseCap}
        />
      </GridItem>
    </SummaryMetricsCardsGrid>
  )
}
