import { Box } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { useLanguage } from "Hooks/use-language"
import { DemographicTargetSummary } from "UsabilityHub/components/recruitment-summary-card/DemographicTargetSummary"
import { DemographicTargetSummaryTableRow } from "UsabilityHub/components/recruitment-summary-card/DemographicTargetSummaryTableRow"
import { DEVICE_TYPE_LABELS } from "Utilities/permittedDeviceType"
import React from "react"
import { Order, RecordingType } from "~/api/generated/usabilityhubSchemas"
import { PanelOrThirdPartyTestOrder, isPanelTestOrder } from "./orderTypes"

export const UsabilityTestOrderSummaryCardExpandedSection: React.FC<{
  order: PanelOrThirdPartyTestOrder
  recordingTypes: RecordingType[]
}> = ({ order, recordingTypes }) => (
  <Box>
    <DemographicTargetSummary
      ageRange={[
        order.demographic_target?.min_age ?? null,
        order.demographic_target?.max_age ?? null,
      ]}
      countries={
        order.demographic_target?.locations?.length === 0
          ? order.demographic_target?.countries && []
          : []
      }
      granularLocations={{
        scope: "usability_tests",
        targetLocations: order.demographic_target?.locations ?? [],
      }}
      activeDemographicAttributes={
        order.demographic_target?.demographic_attributes ?? []
      }
    >
      <TestLanguageRow
        languageCode={order.usability_test_language_code ?? ""}
      />
      <TestPermittedDeviceTypeRow
        permittedDeviceType={order.usability_test_permitted_device_type}
      />
      <TestRecordingRow recordingTypes={recordingTypes} />
      <TestScreenerRow
        screenerEnabled={(isPanelTestOrder(order) && order.screener) ?? false}
        order={
          isPanelTestOrder(order) && "screener" in order ? order : undefined
        }
      />
    </DemographicTargetSummary>
  </Box>
)

const TestPermittedDeviceTypeRow: React.FC<{
  permittedDeviceType: Order["usability_test_permitted_device_type"]
}> = (props) => (
  <DemographicTargetSummaryTableRow
    heading="Devices"
    value={DEVICE_TYPE_LABELS[props.permittedDeviceType]}
  />
)

const TestScreenerRow: React.FC<{
  screenerEnabled: boolean
  order?: Order
}> = ({ screenerEnabled, order }) => (
  <DemographicTargetSummaryTableRow
    heading="Screener"
    value={screenerEnabled ? "Enabled" : "Disabled"}
    secondaryText={
      screenerEnabled && order && "screener" in order
        ? getScreenInRateText(order)
        : undefined
    }
  />
)

const getScreenInRateText = (order: Order) => {
  const estimatedScreenInRate = order.estimated_incidence_rate
  const estimatedRange = Constants.VALID_SIR_RANGES.find(
    (range) => range.min === estimatedScreenInRate
  )

  const total =
    order.completed_response_count + order.screened_out_response_count
  const actualScreenInRate =
    total <= 0 ? 0 : (order.completed_response_count / total) * 100
  const screenInRateText = `Screen-in rate ${actualScreenInRate.toFixed(0)}%`

  if (!estimatedRange) return screenInRateText

  return `${screenInRateText} (Estimated ${estimatedRange.min}-${estimatedRange.max}%)`
}

const TestLanguageRow: React.FC<{ languageCode: string }> = (props) => (
  <DemographicTargetSummaryTableRow
    heading="Language"
    value={useLanguage(props.languageCode)?.display_string ?? "Default"}
  />
)

const TestRecordingRow: React.FC<{ recordingTypes: RecordingType[] }> = ({
  recordingTypes,
}) => {
  const recordingTypesToText = (recordingTypes: RecordingType[]) => {
    if (recordingTypes.length < 1) return "None"

    const result = []
    if (recordingTypes.includes("microphone")) {
      result.push("Think-aloud")
    }
    if (recordingTypes.includes("screen")) {
      result.push("Screen share")
    }
    return result.join(", ")
  }

  const value = recordingTypesToText(recordingTypes)
  return <DemographicTargetSummaryTableRow heading="Recording" value={value} />
}
