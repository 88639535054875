import { AppliedFilter, FilterAttribute, FilterOptionChoice } from "Types"
import React from "react"
import { useFilterContext } from "./FilterContext"
import { FilterMenu } from "./FilterMenu"

interface Props {
  appliedFilter?: AppliedFilter
  selectedAttribute: FilterAttribute
}

export const FilterMenuSingle: React.FC<Props> = ({
  appliedFilter = null,
  selectedAttribute,
}) => {
  const { addFilter, changeFilter } = useFilterContext()

  const handleSelect = (choice: FilterOptionChoice) => {
    if (appliedFilter) {
      changeFilter(appliedFilter.id, appliedFilter.comparator, {
        type: "single",
        choice: choice,
      })
    } else {
      addFilter(selectedAttribute, {
        type: "single",
        choice,
      })
    }
  }

  return (
    <FilterMenu
      appliedFilter={appliedFilter}
      selectedAttribute={selectedAttribute}
      onSelect={handleSelect}
    />
  )
}
