import { Checkbox, Flex, HStack, Icon, IconButton } from "@chakra-ui/react"
import { CloseIcon } from "Icons/CloseIcon"
import { EditFilledIcon } from "Icons/EditFilledIcon"
import React, { ChangeEventHandler, ReactElement, ReactNode } from "react"

import { IndeterminateBoolean } from "Types"

interface Props<T> {
  checkboxValue: IndeterminateBoolean
  focusRef?: (element: HTMLElement | null) => void
  disableCheckbox: boolean
  getLabel: (tag: Readonly<T>) => ReactNode
  onCheckboxChange: (tag: Readonly<T>, value: boolean) => void
  onEdit: (tag: Readonly<T>) => void
  onRemove: (tag: Readonly<T>) => void
  tag: Readonly<T>
}

export function EditableTag<T>({
  checkboxValue,
  disableCheckbox,
  getLabel,
  tag,
  onCheckboxChange,
  onRemove,
  onEdit,
}: Props<T>): ReactElement {
  const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    onCheckboxChange(tag, event.target.checked)
  }

  return (
    <Flex alignItems="center">
      <Checkbox
        variant="mdWithSmFont"
        isChecked={checkboxValue === true}
        isIndeterminate={checkboxValue === "mixed"}
        isReadOnly={disableCheckbox}
        onChange={handleCheckboxChange}
        w="100%"
      >
        {getLabel(tag)}
      </Checkbox>
      <HStack data-qa="tag-actions" spacing="0">
        <IconButton
          aria-label="Edit Tag"
          variant="ghost"
          size="xs"
          icon={<Icon as={EditFilledIcon} w={5} h={5} />}
          color="gray.400"
          _hover={{
            color: "currentColor",
          }}
          onClick={() => onEdit(tag)}
        />
        <IconButton
          aria-label="Remove Tag"
          variant="ghost"
          size="xs"
          icon={<Icon as={CloseIcon} w={5} h={5} />}
          color="gray.400"
          _hover={{
            color: "currentColor",
          }}
          onClick={() => onRemove(tag)}
        />
      </HStack>
    </Flex>
  )
}
