import { Box, BoxProps, Flex, Icon, Text } from "@chakra-ui/react"
import React from "react"
import { Link } from "react-router-dom"

import { ProjectIcon } from "Icons/ProjectIcon"
import { ProjectOpenIcon } from "Icons/ProjectOpenIcon"
import { NewProject } from "~/api/generated/usabilityhubSchemas"

import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import { ProjectContextMenu } from "../projects/$projectWithSlug/ProjectContextMenu"

import { Badge } from "DesignSystem/components"
import { Heading } from "DesignSystem/components/Heading"
import { cq } from "Shared/helpers/containerQuerySx"
import { archivedColors, defaultColors } from "./cardColors"
import { threeColumnsOrMore } from "./projectListGridBreakpoints"

type Props = {
  project: NewProject
} & BoxProps

export const ProjectCard: React.FC<Props> = ({ project, ...props }) => {
  return (
    <Box
      pos="relative"
      wordBreak="break-word"
      rounded="12px"
      shadow={project.archived ? "none" : "ds.raised"}
      borderWidth={1}
      borderColor="transparent"
      data-ui="project-card"
      data-qa="project-card"
      {...(project.archived ? archivedColors : defaultColors)}
      sx={{
        ...cq(threeColumnsOrMore, {
          rounded: "16px",
        }),
        // For devices that can hover
        "@media (hover: hover)": {
          "&:hover [data-folder-icon=closed]": {
            display: "none",
          },
          "&:hover [data-folder-icon=open]": {
            display: "block",
          },
        },
      }}
      {...props}
    >
      <Flex
        as={Link}
        to={generateFriendlyProjectPath(project)}
        flexDir={"row"}
        justifyContent={"normal"}
        gap={4}
        h={"auto"}
        p={3}
        sx={{
          ...cq(threeColumnsOrMore, {
            flexDir: "column",
            justifyContent: "space-between",
            gap: 0,
            h: "9.5rem",
            p: 4,
          }),
        }}
        _focusVisible={{
          outline: "none",
          boxShadow: "outline",
        }}
      >
        <Flex
          alignItems="start"
          basis={8}
          shrink={0}
          mb={0}
          sx={cq(threeColumnsOrMore, { mb: 3 })}
        >
          <Flex
            bg={
              project.archived
                ? "ds.background.neutral.resting"
                : "ds.background.accent.gray.subtle"
            }
            borderRadius="6px"
            w={8}
            h={8}
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              data-folder-icon="closed"
              as={ProjectIcon}
              boxSize={4}
              color="ds.icon.default"
            />
            <Icon
              data-folder-icon="open"
              display="none"
              as={ProjectOpenIcon}
              boxSize={4}
              color="ds.icon.default"
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          gap={1}
          pe={4}
          sx={cq(threeColumnsOrMore, { pe: 0 })}
        >
          <Heading
            as="h3"
            textStyle="ds.heading.primary"
            color="ds.text.default"
            noOfLines={2}
          >
            {project.name}
          </Heading>
          <Text textStyle="ds.paragraph.secondary" color="ds.text.subtle">
            {project.test_count} {project.test_count === 1 ? "test" : "tests"}
          </Text>
          {project.archived && (
            <Box
              // This is to make the box wrap the child Badge exactly at the same height
              lineHeight={0}
              sx={{
                // Can't use the cq helper here because of the hover state
                [`@container (min-width: ${threeColumnsOrMore})`]: {
                  "&": {
                    position: "absolute",
                    top: 3,
                    right: 12,
                    bottom: "auto",
                    left: "auto",
                    display: "none",
                    height: "fit-content",
                  },
                  "[data-ui='project-card']:hover &": {
                    display: "block",
                  },
                },
              }}
            >
              <Badge colorScheme="default" variant="subtle" label="Archived" />
            </Box>
          )}
        </Flex>
      </Flex>

      <Box pos="absolute" top={3} right={2}>
        <ProjectContextMenu project={project} variant="subtle" />
      </Box>
    </Box>
  )
}
