import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  OnboardingStepId,
  getStepByIndex,
  stepCount,
  stepIndex,
} from "./constants"

type Options = {
  stepIdParam?: OnboardingStepId
  onComplete: () => void
}

export type OnboardingSteppingState<ID extends OnboardingStepId> = ReturnType<
  typeof useOnboardingStepping<ID>
>

/**
 * Onboarding Stepping Hook
 *
 * Handles the transition between steps, and provides the actions to move between them.
 *
 * @param options
 */
export const useOnboardingStepping = <ID extends OnboardingStepId>(
  options: Options
) => {
  const { stepIdParam = getStepByIndex(1).id, onComplete } = options
  const [index, setIndex] = useState(stepIndex(stepIdParam))
  const step = useMemo(() => getStepByIndex(index), [index])

  const jumpTo = (id: OnboardingStepId) => {
    const index = stepIndex(id)
    setIndex((i) => (index < 0 ? i : index))
  }

  const next = () => {
    if (index === stepCount - 1) {
      onComplete()
    } else {
      setIndex((i) => (i >= stepCount - 1 ? i : i + 1))
    }
  }

  const skip = useMemo(
    () =>
      step.isSkippable
        ? () => {
            if (index < 1) {
              throw new Error("Cannot skip the first step")
            }
            next()
          }
        : undefined,
    [step.isSkippable, index]
  )

  const back = useCallback(() => {
    if (index < 1) {
      throw new Error("Cannot return to the previous step")
    }
    setIndex((i) => (i < 1 ? i : i - 1))
  }, [index, onComplete])

  const initialStepId = useRef<OnboardingStepId | undefined>(stepIdParam)
  useEffect(() => {
    // Don't jump on initial render
    if (initialStepId.current !== stepIdParam) {
      jumpTo(stepIdParam)
      initialStepId.current = undefined
    }
  }, [stepIdParam])

  return { index, id: step.id as ID, skip, back, next }
}
