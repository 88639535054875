import { Box, Flex, Stack, Text, TextProps } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { VideoPlayer } from "Components/screenshot/UnscaledScreenshot/VideoPlayer"
import { Button, Heading } from "DesignSystem/components"
import { DisplayModal } from "Shared/components/DisplayModal/DisplayModal"
import { LocaleContext } from "Shared/contexts/LocaleContext"
import { useTranslate } from "Shared/hooks/useTranslate"
import React, { useContext, useLayoutEffect, useRef, useState } from "react"

type Props = {
  unskippable: boolean
  onContinue: () => void
}

const subtitles = [
  {
    label: "English",
    srclang: "en",
    src: "https://lyssna-in-app-videos.s3.amazonaws.com/think_aloud_demo.en.vtt",
  },
  {
    label: "Français",
    srclang: "fr",
    src: "https://lyssna-in-app-videos.s3.amazonaws.com/think_aloud_demo.fr.vtt",
  },
] as const

export const ThinkAloudDemo: React.FC<Props> = ({
  unskippable,
  onContinue,
}) => {
  const [watchedVideo, setWatchedVideo] = React.useState(false)

  const [videoSrc, setVideoSrc] = useState<string | undefined>()

  const translate = useTranslate()

  const localeContext = useContext(LocaleContext)
  if (!localeContext) throw new Error("no locale provided")

  const { locale = "en" } = localeContext

  const [subtitleLocale] = React.useState(
    locale.startsWith("en") ? null : locale
  )

  const videoRef = useRef<HTMLVideoElement>(null)

  useLayoutEffect(() => {
    if (!videoRef.current) return

    setVideoSrc(
      "https://lyssna-in-app-videos.s3.amazonaws.com/think_aloud_demo.mp4"
    )

    for (const track of videoRef.current.textTracks) {
      track.mode = track.language === subtitleLocale ? "showing" : "hidden"
    }
  }, [subtitleLocale])

  return (
    <DisplayModal.SinglePaneContent
      controls={
        unskippable ? (
          <ThemedButton
            onClick={onContinue}
            width="fit-content"
            alignSelf="end"
            size="sm"
            isDisabled={unskippable && !watchedVideo}
          >
            {translate("test.buttons.continue")}
          </ThemedButton>
        ) : (
          <Flex flex={1}>
            <Button onClick={onContinue}>
              {translate("test.recording.think_aloud.back")}
            </Button>
          </Flex>
        )
      }
    >
      <Stack gap={6}>
        <Stack px={[6, 0]} gap={4}>
          <Heading as="h2" textStyle="ds.display.primary">
            {translate("test.recording.think_aloud.heading")}
          </Heading>
          <Text textStyle="ds.paragraph.emphasized">
            {translate("test.recording.think_aloud.body")}
          </Text>
          {unskippable && <Unskippable display={["inline-block", "none"]} />}
        </Stack>

        <Box
          position="relative"
          roundedTop={[24, 16]}
          roundedBottom={[0, 16]}
          overflow="hidden"
          aspectRatio="16 / 9"
          bg="black"
          mb={[0, 6]}
          sx={{
            order: [-1, 0],
          }}
        >
          <VideoPlayer
            ref={videoRef}
            url={videoSrc}
            crossOrigin="anonymous"
            canSkipAhead={!unskippable}
            onMediaEnded={() => setWatchedVideo(true)}
            onSkippedAhead={() => {}}
            style={{
              position: "absolute",
              inset: 0,
              width: "100%",
              height: "100%",
            }}
          >
            {subtitles.map((sub) => (
              <track
                key={sub.srclang}
                label={sub.label}
                kind="subtitles"
                src={sub.src}
                srcLang={sub.srclang}
              />
            ))}
          </VideoPlayer>
          {unskippable && (
            <Unskippable
              display={["none", "inline-block"]}
              pos="absolute"
              top={3}
              right={3}
            />
          )}
        </Box>
      </Stack>
    </DisplayModal.SinglePaneContent>
  )
}

const Unskippable: React.FC<TextProps> = (props) => {
  const translate = useTranslate()

  return (
    <Text
      backgroundColor="ds.surface.backdrop"
      color="white"
      display="inline-block"
      alignSelf="start"
      textStyle={["ds.paragraph.emphasized", "ds.interface.small"]}
      p={2}
      rounded={8}
      {...props}
    >
      {translate("test.recording.think_aloud.unskippable")}
    </Text>
  )
}
