import { CurrentAccountProvider } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import { FreeTrial } from "UsabilityHub/views/setup/trial/FreeTrial"
import React from "react"
import { Navigate } from "react-router-dom"

export const FreeTrialRoute: React.FC = () => {
  return (
    // <FreeTrial> and its subcomponents rely on the current account returned on account creation
    // which is manually inserted into the queryCache during onboarding.
    // It's a bit of a weird pattern and might be worth revisiting in future.
    <CurrentAccountProvider
      unauthenticatedFallback={<Navigate to={ROUTES.USERS.SIGN_IN.path} />}
    >
      <FreeTrial />
    </CurrentAccountProvider>
  )
}
