import { createIcon } from "@chakra-ui/react"
import React from "react"

export const RecordStopIcon = createIcon({
  displayName: "RecordStopIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <g>
      <path
        d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM7.10899 7.54601C7 7.75992 7 8.03995 7 8.6V13.4C7 13.9601 7 14.2401 7.10899 14.454C7.20487 14.6422 7.35785 14.7951 7.54601 14.891C7.75992 15 8.03995 15 8.6 15H13.4C13.9601 15 14.2401 15 14.454 14.891C14.6422 14.7951 14.7951 14.6422 14.891 14.454C15 14.2401 15 13.9601 15 13.4V8.6C15 8.03995 15 7.75992 14.891 7.54601C14.7951 7.35785 14.6422 7.20487 14.454 7.10899C14.2401 7 13.9601 7 13.4 7H8.6C8.03995 7 7.75992 7 7.54601 7.10899C7.35785 7.20487 7.20487 7.35785 7.10899 7.54601Z"
        stroke="currentColor"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </g>
  ),
})
