import { useToast } from "@chakra-ui/react"
import { Consumer, Subscription } from "@rails/actioncable"
import { getFigmaFileVersions } from "Redux/reducers/figma-file-versions/selectors"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { isAxiosErrorWithMessage } from "Services/axios"
import { AppContext } from "Shared/contexts/AppContext"
import { FigmaFileFlow, FigmaFileVersion, UsabilityTestSection } from "Types"
import { useFigmaOAuthContext } from "UsabilityHub/contexts/FigmaOauthCredentialsContext"
import { useContext, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { fetchSyncFigmaFileVersion } from "~/api/generated/usabilityhub-components"

type FailureMessage = {
  action: "download_failed"
  data: { status: number; message: string }
}
type Message =
  | {
      action: "download_complete"
      data: { status: number; figma_file_version: FigmaFileVersion }
    }
  | FailureMessage

// Can't pass much useful in because the callers won't have any context of
// which Figma details they're syncing until they're ready to sync
export const useSyncFigma = () => {
  const toast = useToast()
  const { consumer } = useContext(AppContext)
  const channel = useRef<Subscription<Consumer>>()
  const unsubscribe = () => {
    if (channel.current) {
      channel.current.unsubscribe()
      channel.current = undefined
    }
  }
  const figmaFileVersions = useSelector(getFigmaFileVersions)
  const sections = useSelector(getSections)
  const { setCurrentUserHasFigmaOauthCredentials } = useFigmaOAuthContext()

  useEffect(() => {
    return () => {
      unsubscribe()
    }
  }, [])

  const handleFailure = (message: FailureMessage, onFailure: () => void) => {
    if (message.data.status === 403) {
      // If we get back a 403 we need to invalidate their FigmaOauthCredentials
      setCurrentUserHasFigmaOauthCredentials(false)

      toast({
        title: `We couldn${"\u2019"}t connect Lyssna to your Figma account. Please try again to re-connect.`,
        status: "error",
      })
    } else {
      toast({
        title: message.data.message,
        status: "error",
      })
    }

    onFailure()
  }

  const handleRequestError = (error: any, onFailure: () => void) => {
    const errorMessage = isAxiosErrorWithMessage(error)
      ? error.response.data.message
      : "Sorry, we were unable to sync your prototype. Please check your link is correct and permissions have been correctly configured in Figma, and try again."

    toast({
      title: errorMessage,
      isClosable: true,
      position: "top-right",
      status: "error",
    })

    onFailure()
  }

  const requestFigmaFileVersion = async (
    figmaFileKey: string,
    startNodeIds: string[],
    onFailure: () => void
  ) => {
    try {
      // Request sync of a FigmaFile and FigmaFileVersion in the backend asynchronously.
      await fetchSyncFigmaFileVersion({
        body: {
          figma_file_key: figmaFileKey,
          start_node_ids: startNodeIds,
        },
      })
    } catch (error) {
      unsubscribe()
      handleRequestError(error, onFailure)
    }
  }

  const allStartNodeIds = (figmaFileKey: string, startNodeId: string) => {
    const oldFfv = figmaFileVersions?.find(
      ({ figma_file_key }) => figmaFileKey === figma_file_key
    )
    if (oldFfv !== undefined) {
      const hasOldFigmFileVersion = (
        section: UsabilityTestSection
      ): section is {
        figma_file_flow: FigmaFileFlow // help Typescript understand any section that passes has a figma_file_flow
      } & UsabilityTestSection =>
        section.figma_file_flow?.figma_file_version_id === oldFfv.id &&
        section.figma_file_flow?.start_node_id !== startNodeId
      return [
        startNodeId,
        ...sections
          .filter(hasOldFigmFileVersion)
          .map((section) => section.figma_file_flow.start_node_id),
      ]
    } else {
      return [startNodeId]
    }
  }

  /**
   *
   * @param figmaFileKey
   * @param startNodeId
   * @param onSuccess Called with the newly synced FigmaFileVersion.
   * @param onFailure Errors will be handled and reported within this hook. This function can be used to perform any additional clean up.
   */
  const syncFigma = (
    figmaFileKey: string,
    startNodeId: string,
    onSuccess: (figmaFileVersion: FigmaFileVersion) => void,
    onFailure: () => void
  ) => {
    unsubscribe()

    const uniqueStartNodeIds = [
      ...new Set(allStartNodeIds(figmaFileKey, startNodeId)),
    ]

    channel.current = consumer?.subscriptions.create(
      {
        channel: "FigmaFlowSyncChannel",
        figma_file_key: figmaFileKey,
        start_node_ids: uniqueStartNodeIds,
      },
      {
        connected: () => {
          // request sync once the subscription has been successfully completed
          void requestFigmaFileVersion(
            figmaFileKey,
            uniqueStartNodeIds,
            onFailure
          )
        },
        received: (message: Message) => {
          unsubscribe()
          const action = message.action
          if (action === "download_complete") {
            onSuccess(message.data.figma_file_version)
          } else if (action === "download_failed") {
            handleFailure(message, onFailure)
          } else {
            throw new Error(`Unknown action ${action}`)
          }
        },
      }
    )
  }

  return syncFigma
}
