import { getApplicationState } from "JavaScripts/state"
import { OnboardingLayout } from "UsabilityHub/components/Onboarding/OnboardingLayout"
import React from "react"
import { AccountForm } from "./AccountForm"
import { NewCustomerLogos } from "./NewCustomerLogos"
import { useSignUp } from "./useSignUp"

export const SignUpRoute: React.FC = () => {
  const { recaptchaSiteKey } = getApplicationState()
  const { signUp, error, intendedPlanId } = useSignUp()
  return (
    <OnboardingLayout
      index={0}
      form={
        <AccountForm
          signUp={signUp}
          error={error}
          intendedPlanId={intendedPlanId}
          recaptchaSiteKey={recaptchaSiteKey}
        />
      }
      animation={<NewCustomerLogos />}
    />
  )
}
