import { Center, Collapse, Flex } from "@chakra-ui/react"
import { Button, Heading } from "DesignSystem/components"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { ScreenerIcon } from "Icons/ScreenerIcon"
import { State } from "Redux/app-store"
import { getHasScreener } from "Redux/reducers/test-results/selectors"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { ScreenerQuestionResults } from "./ScreenerQuestionResults"

export const ScreenerResults: React.FC = () => {
  const hasScreener = useSelector(getHasScreener)

  if (!hasScreener) {
    return null
  }

  return <CollapsibleScreenerResults />
}

const CollapsibleScreenerResults: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const screenerQuestions = useSelector(
    (state: State) => state.testResults?.screenerQuestions
  )

  if (!screenerQuestions) {
    return null
  }

  return (
    <Flex
      direction="column"
      gap={2}
      bg="ds.surface.raised.resting"
      borderRadius="16px"
      boxShadow="ds.raised"
      p={6}
    >
      <Flex alignItems="center" gap={2}>
        <Center bg="ds.background.accent.gray.subtler" borderRadius="8px" p={2}>
          <ScreenerIcon boxSize={5} color="ds.icon.subtle" />
        </Center>

        <Heading as="h3" textStyle="ds.display.primary" color="ds.text.default">
          Screener
        </Heading>

        <Button
          variant="subtle"
          size="flush"
          rightIcon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          onClick={() => setIsOpen((v) => !v)}
          ms="auto"
        >
          {isOpen ? "Hide" : "Show"}
        </Button>
      </Flex>

      <Collapse in={isOpen}>
        <Flex direction="column" gap={5} pt={4}>
          {screenerQuestions.map((question, index) => (
            <ScreenerQuestionResults
              key={question.id}
              screenerQuestion={question}
              position={index}
            />
          ))}
        </Flex>
      </Collapse>
    </Flex>
  )
}
