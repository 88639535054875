import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Alert, Heading } from "DesignSystem/components"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { SupportMailtoLink } from "Shared/components/Links/SupportMailtoLink"
import React, { useMemo } from "react"
import { useFieldArray } from "react-hook-form"
import { OnboardingFormProps } from "../constants"
import { EmailAddress } from "../types"
import { useInviteTeamMembers } from "../useInviteTeamMembers"
import { useOnboardingForm } from "../useOnboardingForm"

export const InviteTeamForm: React.FC<OnboardingFormProps<"collaborators">> = ({
  api,
  values,
  wiring,
  submit,
}) => {
  const alreadyInvited = useMemo(
    () =>
      values.teamMembers.flatMap((t) =>
        t.invited && t.email ? [t.email] : []
      ),
    [values]
  )
  const { invite, invited } = useInviteTeamMembers({ api, alreadyInvited })

  const { handleSubmit, control, register, watch } = useOnboardingForm({
    id: "collaborators",
    values,
    wiring,
  })

  const { fields } = useFieldArray({ control, name: "teamMembers" })

  const onSubmit = handleSubmit(async (data) => {
    const emails = data.teamMembers
      .map((t) => t.email as EmailAddress)
      .filter((e) => e && invited[e]?.status !== "success")
    const success = await invite(emails)
    const teamMembers = data.teamMembers.map((t) => ({ ...t, invited: true }))
    success && (await submit({ ...data, teamMembers }))
  })

  const teamMembers = watch("teamMembers")
  const firstFailureIndex =
    teamMembers.findIndex(
      (t) => !t || (t?.email && invited[t.email]?.status === "failure")
    ) || undefined

  return (
    <Stack
      as="form"
      id="onboarding_form"
      spacing={6}
      width="100%"
      onSubmit={onSubmit}
    >
      <Heading
        as="h1"
        textStyle="ds.display.emphasized"
        color="ds.text.default"
      >
        Who do you collaborate with?
      </Heading>

      <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
        Invite your team to get the most out of Lyssna.
      </Text>

      {fields.length > 0 ? (
        <>
          <Stack spacing={2}>
            {fields.map((field, index) => {
              const props = register(`teamMembers.${index}.email` as const)
              const email = teamMembers[index].email
              const result = email && invited[email]
              const succeeded = email && result?.status === "success"
              const failed = email && result?.status === "failure"
              const error = failed && result.error
              return (
                <FormControl
                  key={field.id}
                  isDisabled={succeeded || undefined}
                  isInvalid={failed || undefined}
                >
                  {index === 0 && (
                    <FormLabel sx={{ "&[data-disabled]": { opacity: 1 } }}>
                      <Text
                        textStyle="ds.heading.primary"
                        color="ds.text.default"
                      >
                        Invite with email
                      </Text>
                    </FormLabel>
                  )}
                  {succeeded ? (
                    <HStack
                      align="center"
                      bg="bg.neutral.subtle.hover"
                      rounded={8}
                      px={4}
                      py={2}
                    >
                      <CheckCircleFilledIcon color="icon.subtle" />
                      <Text as="span" flex={1}>
                        {teamMembers[index].email}
                      </Text>
                      <Text
                        as="span"
                        color="status.success"
                        fontWeight="medium"
                      >
                        Invite sent
                      </Text>
                    </HStack>
                  ) : (
                    <InputGroup>
                      <Input
                        placeholder="coworker@lyssna.com"
                        autoFocus={index === firstFailureIndex || undefined}
                        autoComplete="off"
                        {...props}
                      />
                    </InputGroup>
                  )}
                  {error && <FormErrorMessage>{error}</FormErrorMessage>}
                </FormControl>
              )
            })}
          </Stack>
          <Alert
            status="info"
            description="Need more? You can add more collaborators later."
          />
        </>
      ) : (
        !api.isLoading && (
          <Stack gap={0}>
            <Text>
              You have reached the maximum number of team members on your
              current plan. Please{" "}
              <SupportMailtoLink>contact support</SupportMailtoLink> for more
              information.
            </Text>
          </Stack>
        )
      )}
    </Stack>
  )
}
