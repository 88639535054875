import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react"
import { InfoFilledIcon } from "Icons/InfoFilledIcon"
import React from "react"

type Props = {
  children: React.ReactNode
  hasArrow?: boolean
  contentProps?: PopoverContentProps
} & PopoverProps

export const HelpPopover: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  hasArrow = true,
  contentProps,
  ...props
}) => {
  return (
    <Popover trigger="hover" placement="right" {...props}>
      <PopoverTrigger>
        <InfoFilledIcon color="gray.400" ml={1} />
      </PopoverTrigger>
      <Portal>
        <PopoverContent {...contentProps}>
          {hasArrow && <PopoverArrow />}
          <PopoverBody>{children}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
