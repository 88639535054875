import React, { PropsWithChildren, useCallback } from "react"
import { fetchAdminResponseUsabilityTestResults } from "~/api/generated/usabilityhub-components"
import { BaseResultsDataProvider } from "./BaseResultsDataProvider"
import { ResultUsabilityTestResponseData } from "./types"

type Props = {
  responseId: number
}

export const SingleResponseResultDataProvider: React.FC<
  PropsWithChildren<Props>
> = ({ children, responseId }) => {
  const queryKey = `single-response-result-${responseId}`
  const apiFunction = useCallback(
    async () =>
      (await fetchAdminResponseUsabilityTestResults({
        pathParams: { responseId },
      })) as ResultUsabilityTestResponseData,
    [responseId]
  )

  return (
    <BaseResultsDataProvider queryKey={queryKey} apiFunction={apiFunction}>
      {children}
    </BaseResultsDataProvider>
  )
}
