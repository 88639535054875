import {
  Box,
  Center,
  Button as ChakraButton,
  Flex,
  Icon,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Progress,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { Button, Heading, IconButton } from "DesignSystem/components"
import { CalendarAddIcon } from "Icons/CalendarAddIcon"
import { CalendarIcon } from "Icons/CalendarIcon"
import { longWithTime } from "Utilities/date-formats"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import {
  GetModeratedStudyQuotaResponse,
  useGetModeratedStudyQuota,
} from "~/api/generated/usabilityhub-components"
import { PlanDrawerTrigger } from "../PlanChanger/plan-drawer"
import beBoldImage from "./be-bold.png"

const SESSIONS_FEATURE = {
  feature: "self_recruited_interview_sessions_per_month",
} as const

type Props = {
  customTrigger?: React.ReactNode
}

export const SessionLimitIndicator: React.FC<Props> = ({ customTrigger }) => {
  const { data, isLoading } = useGetModeratedStudyQuota({})

  const isTabletOrLarger = useBreakpointValue(
    { base: false, sm: true },
    { ssr: false }
  )

  const limitReached =
    data &&
    data.self_recruited_sessions.used >= data.self_recruited_sessions.quota

  const trigger: React.ReactNode =
    (customTrigger ?? isTabletOrLarger) ? (
      <Button
        leftIcon={<Icon as={limitReached ? CalendarIcon : CalendarAddIcon} />}
      >
        {limitReached ? "Session limit reached" : "Session limit"}
      </Button>
    ) : (
      <IconButton
        icon={<Icon as={limitReached ? CalendarIcon : CalendarAddIcon} />}
        aria-label={limitReached ? "Session limit reached" : "Session limit"}
      />
    )

  return (
    <Popover trigger="hover" placement="bottom-end">
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <Portal>
        <PopoverContent w="409px">
          <PopoverBody p={0}>
            {isLoading || !data ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <SessionLimitDetail data={data} />
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const SessionLimitDetail: React.FC<{
  data: GetModeratedStudyQuotaResponse
}> = ({ data }) => {
  const sessionsUsed = data.self_recruited_sessions.used
  const sessionsQuota = data.self_recruited_sessions.quota
  const sessionsRemaining = Math.max(
    data.self_recruited_sessions.quota - data.self_recruited_sessions.used,
    0
  )
  const formattedResetDate = longWithTime(new Date(data.period_end))

  return (
    <Flex direction="column" p={4}>
      <Heading as="h3" textStyle="ds.heading.primary">
        Interview sessions from your network
      </Heading>

      <PopoverCloseButton />

      <Progress
        value={sessionsUsed}
        max={sessionsQuota}
        h={2}
        colorScheme="teal"
        rounded="md"
        my={2}
      />

      <Flex justify="space-between" mb={2}>
        <Text color="text.primary" fontSize="sm" fontWeight="medium">
          {pluralizeWithCount(sessionsUsed, "session")} used
        </Text>
        <Text
          color={sessionsRemaining > 0 ? "text.primary" : "text.subtle"}
          fontSize="sm"
          fontWeight="medium"
        >
          {pluralizeWithCount(sessionsRemaining, "session")} left
        </Text>
      </Flex>

      {sessionsRemaining > 0 ? (
        <Text fontSize="sm" fontWeight="normal">
          You can collect as many applicants and send as many invitations as you
          like, but only{" "}
          <strong>
            {pluralizeWithCount(sessionsRemaining, "more participant")} from
            your network
          </strong>{" "}
          can book a session before your limit resets on {formattedResetDate}.
        </Text>
      ) : (
        <Box rounded="md" bg="purple.50" p={6}>
          <Flex align="center" gap={2}>
            <Image w="68px" src={beBoldImage} />
            <Heading as="h3" textStyle="ds.display.secondary">
              Kudos on getting the most our of your monthly limit!
            </Heading>
          </Flex>

          <Text fontSize="sm" fontWeight="normal" mt={3}>
            You can keep setting up studies and collecting applicants, but no
            new sessions can be booked until your balance resews on{" "}
            {formattedResetDate}.
          </Text>

          <Text fontSize="sm" fontWeight="normal" my={3}>
            If there's more research to be done, consider upgrading your plan
            for a larger session limit.
          </Text>

          <PlanDrawerTrigger
            colorScheme="brand.primary"
            as={ChakraButton}
            requestedFeatures={[SESSIONS_FEATURE]}
            source="session_limit_indicator"
          >
            Explore upgrades
          </PlanDrawerTrigger>
        </Box>
      )}

      <Box rounded="md" bg="bg.surface.callout" p={3} mt={2}>
        <Text fontSize="sm" fontWeight="normal">
          Sessions booked from the Lyssna panel don’t count towards your limit.
        </Text>
      </Box>

      {sessionsRemaining > 0 && (
        <Flex justify="flex-end" mt={3}>
          <PlanDrawerTrigger
            as={Link}
            h="auto"
            variant="unstyled"
            verticalAlign="inherit"
            userSelect="initial"
            fontSize="inherit"
            color="brand.primary.500"
            textDecoration="none"
            requestedFeatures={[SESSIONS_FEATURE]}
            source="session_limit_indicator"
          >
            Explore upgrades
          </PlanDrawerTrigger>
        </Flex>
      )}
    </Flex>
  )
}
