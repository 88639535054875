import {
  HStack,
  Icon,
  Progress,
  SimpleGrid,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import React from "react"

import { useSectionContext } from "UsabilityHub/contexts"
import { formatPercentage } from "Utilities/number"

import { IconButton } from "DesignSystem/components"
import { FilterFilledIcon } from "Icons/FilterFilledIcon"
import { FilterIcon } from "Icons/FilterIcon"
import { UserProfileIcon } from "Icons/UserProfileIcon"
import { usePrototypeNodeFilter } from "../hooks/use-prototype-node-filter"

interface ScreenResultsScreenDetailsBar {
  screenTitle: string | null
  selectedNodeId: string
  numberOfParticipants: number
  totalNumberOfParticipants: number
}

export function ScreenResultsScreenDetailsBar({
  screenTitle,
  selectedNodeId,
  numberOfParticipants,
  totalNumberOfParticipants,
}: ScreenResultsScreenDetailsBar) {
  const { section } = useSectionContext()

  const { isNodeFilteredIn, toggleIsFiltered } = usePrototypeNodeFilter(
    section.id,
    selectedNodeId
  )

  const participantPercentage =
    totalNumberOfParticipants === 0
      ? 0
      : (numberOfParticipants / totalNumberOfParticipants) * 100

  return (
    <SimpleGrid
      columns={2}
      gap="4"
      borderWidth="thin"
      borderColor={isNodeFilteredIn ? "brand.primary.500" : "transparent"}
      borderStyle="solid"
      // using internal padding because border indicates filter status, pulling filter state up would be a pain
      padding="3"
    >
      <Text fontWeight="semibold" color="text.secondary">
        {screenTitle}
      </Text>

      <HStack spacing="8">
        <Progress
          variant="round"
          colorScheme="green"
          size="sm"
          value={participantPercentage}
          aria-valuenow={participantPercentage}
          width="full"
        />

        <HStack spacing="4">
          <Tooltip
            hasArrow
            placement="top"
            label="Percentage of participants who visited this screen"
          >
            <Text
              fontSize="sm"
              color="green.400"
              style={{ fontVariantNumeric: "tabular-nums" }}
            >
              {formatPercentage(participantPercentage)}
            </Text>
          </Tooltip>

          <Tooltip
            hasArrow
            placement="top"
            label="Number of participants who visited this screen"
          >
            <HStack spacing="1">
              <Icon as={UserProfileIcon} color="text.secondary" />
              <Text
                fontSize="xs"
                color="text.secondary"
                style={{ fontVariantNumeric: "tabular-nums" }}
              >
                {numberOfParticipants}
              </Text>
            </HStack>
          </Tooltip>

          <Tooltip
            hasArrow
            placement="top"
            label="Filter the test based on participants who have visited this screen"
          >
            <IconButton
              // This component is already a child of a button, and button cannot appear as children of buttons so it pretends to be a div
              as="div"
              role="button"
              icon={isNodeFilteredIn ? <FilterFilledIcon /> : <FilterIcon />}
              aria-label="Filter"
              isSelected={isNodeFilteredIn}
              onClick={toggleIsFiltered}
            />
          </Tooltip>
        </HStack>
      </HStack>
    </SimpleGrid>
  )
}
