import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  Heading,
  Kbd,
  Stack,
  useDisclosure,
  usePrefersReducedMotion,
} from "@chakra-ui/react"
import { TestInstructionIcon } from "Icons/TestInstructionIcon"
import React, { useRef } from "react"

export const KeyboardShortcuts: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)
  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <>
      <Button ref={btnRef} leftIcon={<TestInstructionIcon />} onClick={onOpen}>
        Shortcut tips
      </Button>
      <Drawer
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          motionProps={
            prefersReducedMotion
              ? { transformTemplate: () => "none" }
              : undefined
          }
        >
          <DrawerCloseButton />
          <DrawerHeader>Keyboard shortcuts</DrawerHeader>

          <DrawerBody>
            <Stack spacing={8}>
              <Stack>
                <Heading size="sm" as="h4">
                  Moving around
                </Heading>
                <Grid
                  as="dl"
                  gridTemplateColumns="10rem 1fr"
                  columnGap={4}
                  rowGap={1}
                  alignItems="center"
                >
                  <dt>
                    <Kbd>&uarr;</Kbd>
                  </dt>
                  <dd>Select previous node</dd>
                  <dt>
                    <Kbd>&darr;</Kbd>
                  </dt>
                  <dd>Select next node</dd>
                </Grid>
              </Stack>
              <Stack>
                <Heading size="sm" as="h4">
                  Editing
                </Heading>
                <Grid
                  as="dl"
                  gridTemplateColumns="10rem 1fr"
                  columnGap={4}
                  rowGap={1}
                  alignItems="center"
                >
                  <dt>
                    <Kbd>Tab</Kbd>
                  </dt>
                  <dd>Indent current node</dd>
                  <dt>
                    <Kbd>Shift</Kbd> + <Kbd>Tab</Kbd>
                  </dt>
                  <dd>Unindent current node</dd>
                  <dt>
                    <Kbd>Enter</Kbd>
                  </dt>
                  <dd>Add sibling</dd>
                  <dt>
                    <Kbd>Shift</Kbd> + <Kbd>Enter</Kbd>
                  </dt>
                  <dd>Add child</dd>
                  <dt>
                    <Kbd>Esc</Kbd>
                  </dt>
                  <dd>Cancel editing</dd>
                </Grid>
              </Stack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
