import { Filter } from "Components/test-results/types/filters"
import { getFilterMeta } from "../get-filter-meta"
import { FilterFunction } from "./types"

export type ScreenerFilter = Filter<
  `answer/screener:${number}/answer`,
  "answer/screener",
  string | null
>

export const filterResponseByScreenerAnswer: FilterFunction<ScreenerFilter> = ({
  filter,
  allRelatedData: { screenerQuestions },
  filteredRelatedData: { screenerResponses },
}) => {
  const { questionIndex } = getFilterMeta(filter.category)

  const screenerQuestion = screenerQuestions[questionIndex]
  const screenerResponse = screenerResponses[0]

  if (!screenerResponse) return false

  return screenerResponse.screener_question_selected_options.some(
    (option) =>
      option.screener_question_id === screenerQuestion.id &&
      option.answer === filter.value
  )
}
