import { OnboardingLayout } from "UsabilityHub/components/Onboarding/OnboardingLayout"
import { CurrentAccountProvider } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Navigate } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { FooterActions } from "./FooterActions"
import { OnboardingStepId, getStep } from "./constants"
import { useOnboarding } from "./useOnboarding"
import { useOnboardingApi } from "./useOnboardingApi"

export const OnboardingRoute: React.FC = <ID extends OnboardingStepId>() => {
  const { stepId: stepIdParam } = useTypedParams(ROUTES.SETUP.ONBOARDING)
  const { api, steps, values, wiring, submit } = useOnboarding<ID>({
    api: useOnboardingApi(),
    stepIdParam,
  })
  const { form: Form, animation: Animation } = getStep<ID>(steps.id)

  return (
    <CurrentAccountProvider
      unauthenticatedFallback={<Navigate to={ROUTES.USERS.SIGN_IN.path} />}
    >
      <OnboardingLayout
        index={steps.index}
        footerActions={
          <FooterActions
            steps={steps}
            isSaving={api.isSaving}
            isValid={wiring.isValid}
          />
        }
        form={
          <Form api={api} values={values} wiring={wiring} submit={submit} />
        }
        animation={Animation && <Animation values={wiring.watchedValues} />}
      />
    </CurrentAccountProvider>
  )
}
