import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { State } from "Redux/app-store"
import { RecordingContainer } from "UsabilityHub/components/RecordingContainer/RecordingContainer"
import React from "react"
import { useSelector } from "react-redux"
import { QuestionsIndividualResults } from "../QuestionsIndividualResults"
import { NoResponse } from "../no-data"
import { TaskProps } from "./task-props"

export const LiveWebsiteTestIndividualResults = ({
  usabilityTestSection,
  responseSection,
}: TaskProps) => {
  if (responseSection === null) {
    return <NoResponse />
  }

  if (!usabilityTestSection.live_website_test) {
    throw new TypeError("Live website test not found")
  }

  const tasks = usabilityTestSection.live_website_test.live_website_test_tasks
  const allRecordings =
    useSelector(
      (state: State) =>
        state.testResults?.responseSectionLiveWebsiteTestTaskRecordings
    ) ?? []

  return (
    <Flex gap={4} flexFlow="column">
      {tasks.map((task, taskIndex) => {
        // Find IDs of all questions attached to this task so we can filter the QuestionResultsList to just those answers.
        const questionIdAllowlist = task.live_website_test_task_questions.map(
          (q) => q.usability_test_section_question_id
        )

        // Find recording for this task
        const recording = allRecordings.find(
          (r) => r.live_website_test_task_id === task.id
        )

        return (
          <Flex direction="column" gap={4} key={task.id}>
            <Box height="1px" bg="gray.200" mx={-8} />

            <Heading as="h3" mt={4}>
              Task {taskIndex + 1}
            </Heading>

            <Text mb={4}>{task.instructions}</Text>

            {recording && (
              <Flex
                backgroundColor="ds.background.neutral.resting"
                borderRadius="md"
                overflow="hidden"
              >
                <RecordingContainer
                  layoutId={`recording_container_individual_${recording.id}`}
                  recording={recording}
                  isParcipantView
                />
              </Flex>
            )}

            <Box height="1px" bg="gray.200" mx={-8} />

            <QuestionsIndividualResults
              key={task.id}
              responseId={responseSection.response_id}
              questionIdAllowlist={questionIdAllowlist}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}
