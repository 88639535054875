import { useQuery } from "@tanstack/react-query"
import { suitableForNpsSurveySegment } from "JavaScripts/analytics/segment"
import { Dispatch } from "Redux/app-store"
import { setScreenshots } from "Redux/reducers/screenshots/action-creators"
import { getInitializeScreenshots } from "Redux/reducers/screenshots/helper"
import { initializeTestResults } from "Redux/reducers/test-results/action-creators"
import { initUsabilityTests } from "Redux/reducers/usability-tests/action-creators"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ResultUsabilityTestResponseData } from "./types"

// Takes an API function that returns result data, calls it, and sets the data in the redux store
export const useResultsDataSource = (
  queryKey: string,
  apiFunction: () => Promise<ResultUsabilityTestResponseData>
):
  | { isInitialized: true; data: ResultUsabilityTestResponseData; error: null }
  | { isInitialized: false; data: null; error: Error | null } => {
  const dispatch = useDispatch<Dispatch>()

  const [isInitialized, setIsInitialized] = useState(false)
  const { isLoading, isError, error, data } = useQuery({
    queryKey: [queryKey],
    queryFn: apiFunction,
  })

  useEffect(() => {
    if (!data) return

    const initializedScreenshots = getInitializeScreenshots(data.screenshots)

    dispatch(setScreenshots(initializedScreenshots))
    dispatch(initializeTestResults(data.test_results))
    dispatch(initUsabilityTests(data.usability_tests))

    setIsInitialized(true)

    if (data.events && data.events.includes("suitable_for_nps_survey")) {
      suitableForNpsSurveySegment()
    }
  }, [data])

  if (isLoading || isError || !isInitialized) {
    const narrowedError = error instanceof Error ? error : null

    return {
      isInitialized: false,
      data: null,
      error: narrowedError,
    }
  }

  return {
    isInitialized,
    data,
    error: null,
  }
}
