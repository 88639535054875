import { Box } from "@chakra-ui/react"
import { Variants, motion } from "framer-motion"
import React from "react"

import { OnboardingAnimationProps } from "../constants"
import scribbles from "./round.png"

const VARIANTS: Record<string, Variants> = {
  box: {
    out: {
      opacity: 1,
      scale: 1,
    },
    in: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.5,
        duration: 0.0001,
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  },
  item: {
    out: {
      opacity: 0,
      scale: 0,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
  },
} as const

export const TrafficSourceAnimation: React.FC<
  OnboardingAnimationProps<"grapevine">
> = () => (
  <motion.svg
    width="507"
    height="354"
    viewBox="0 0 507 354"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    variants={VARIANTS.box}
    initial="out"
    animate="in"
  >
    <title>animation</title>
    <Box as={motion.g} variants={VARIANTS.box}>
      <Box
        as="path"
        d="M330.802 221.451C339.527 209.086 346.251 193.519 347.611 176.951C351.718 125.43 337.414 72.6865 288.988 45.6619C260.798 29.9127 233.804 24.4415 201.877 28.0816C168.439 32.0171 127.952 61.8808 112.902 92.0807C105.006 108.04 100.461 123.227 97.3792 140.751C93.6365 161.528 97.6491 188.265 107.742 206.655C124.295 236.94 146.994 255.217 177.552 269.096C214.255 285.881 254.298 281.068 287.21 260.865C313.153 285.655 329.234 303.609 357.668 329.827C367.71 339.087 391.054 342.608 400.166 331.165C413.859 313.966 408.4 294.745 398.097 285.616L330.802 221.451Z"
        fill="brandPalette.purple.dark"
      />
      <path
        d="M328.458 174.625C326.211 201.939 309.335 221.2 288.963 237.684C258.901 261.91 218.998 269.812 182.874 253.329C156.714 241.475 137.282 225.864 123.111 199.997C114.471 184.289 111.036 161.452 114.24 143.706C116.878 128.738 120.769 115.766 127.529 102.135C140.413 76.34 175.073 50.8325 203.699 47.471C231.03 44.3619 254.139 49.035 278.272 62.4869C319.729 85.5695 331.974 130.619 328.458 174.625Z"
        fill="url(#pattern0)"
        fillOpacity="0.6"
        style={{ mixBlendMode: "soft-light" }}
      />
    </Box>
    <Box as={motion.g} variants={VARIANTS.item} fill="brandPalette.teal.mid">
      <path d="M102.073 215.901L4.99998 221.54L24.0385 260.029L101.637 231.568L102.073 215.901Z" />
      <path d="M98.2989 249.307L43.9355 285.255L74.4013 317.658L114.763 261.05L98.2878 249.299L98.2989 249.307Z" />
      <path d="M134.081 273.999L111.851 322.525L135.746 339.758L150.021 285.483L134.081 273.999Z" />
    </Box>
    <Box
      as={motion.path}
      variants={VARIANTS.item}
      d="M425.458 190.932C415.394 186.771 400.138 180.372 389.776 177.058C398.917 159.047 405.611 151.461 423.409 142.532C432.567 137.957 440.464 138.196 449.447 131.036C459.441 123.11 459.169 101.825 447.825 95.6987C429.657 86.1118 415.565 93.3926 408.686 111.386C397.918 107.266 391.407 104.091 374.373 98.0437C379.629 81.1046 395.913 65.7911 413.295 62.3108C449.81 54.0532 497.881 88.8746 489.677 133.113C486.25 151.592 471.852 158.21 454.725 164.602C439.215 168.856 429.748 176.605 425.458 190.932ZM408.083 237.722C397.383 232.502 385.599 229.577 374.46 225.116C377.941 213.872 380.721 205.426 383.257 193.822C394.109 198.189 409.865 199.151 419.706 205.403C417.465 216.719 410.36 226.476 408.122 237.8L408.083 237.722Z"
      fill="brandPalette.teal.dark"
    />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_3001_4553"
          transform="translate(-0.331302) scale(0.000405909)"
        />
      </pattern>
      <image
        id="image0_3001_4553"
        width="4096"
        height="2458"
        xlinkHref={scribbles}
      />
    </defs>
  </motion.svg>
)
