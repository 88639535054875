import { Box, Grid, HStack, Image, Text } from "@chakra-ui/react"
import { HelpCircleIcon } from "Icons/HelpCircleIcon"
import { MotionConfig, Variants, motion } from "framer-motion"
import React from "react"
import Arrow from "./onboarding-curly-arrow.png"
import Gal from "./onboarding-gal.png"
import Guy from "./onboarding-guy.png"

const variants: Record<string, Variants> = {
  container: {
    out: {
      opacity: 0,
      scale: 0.9,
    },
    in: {
      opacity: 1,
      scale: 0.9,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.5,
      },
    },
  },
  guy: {
    out: {
      scale: 0.5,
      opacity: 0,
    },
    in: {
      scale: 1,
      opacity: 1,
    },
  },
  gal: {
    out: {
      x: 0,
      y: 24,
      scale: 0.5,
      opacity: 0,
    },
    in: {
      x: 40,
      y: 16,
      scale: 1,
      opacity: 1,
    },
  },
  arrow: {
    out: {
      x: -40,
      y: 80,
      opacity: 0,
      rotate: "-90deg",
    },
    in: {
      x: -40,
      y: 80,
      opacity: 1,
      rotate: "0deg",
    },
  },
  question: {
    out: {
      x: 25,
      y: 0,
      scale: 0,
      opacity: 0,
    },
    in: {
      x: 50,
      y: "50%",
      scale: 1,
      opacity: 1,
    },
  },
  exclamation: {
    out: {
      x: "60%",
      y: "-80%",
    },
    in: {
      x: "60%",
      y: "-80%",
      transition: {
        staggerChildren: 0.2,
      },
    },
  },
  shape: {
    out: {
      scale: 0.5,
      opacity: 0,
      originX: -20,
      originY: 20,
    },
    in: {
      scale: 1,
      opacity: 1,
      originX: -20,
      originY: 20,
      transition: {
        type: "spring",
        bounce: 0.1,
      },
    },
  },
} as const

export const SignupAnimation = () => (
  <MotionConfig transition={{ type: "spring", bounce: 0.2 }}>
    <Grid
      as={motion.div}
      placeContent="center"
      variants={variants.container}
      initial="in"
      animate="in"
      pos="relative"
      my={16}
    >
      <Image
        as={motion.img}
        gridArea="1 / 1"
        w="22.5rem"
        rounded="20px"
        boxShadow="xl"
        src={Guy}
        alt="Smiling young Asian man in hip clothing"
        variants={variants.guy}
      />
      <Image
        as={motion.img}
        gridArea="1 / 1"
        w="12.5rem"
        rounded="10px"
        justifySelf="end"
        boxShadow="xl"
        src={Gal}
        alt="Smiling white woman outdoors"
        variants={variants.gal}
      />
      <HStack
        as={motion.div}
        gridArea="1 / 1"
        alignSelf="end"
        justifySelf="end"
        p="10px"
        bg="white"
        rounded="lg"
        boxShadow="xl"
        variants={variants.question}
      >
        <Box bg="bg.brand.subtle.default" p={1} rounded="sm">
          <HelpCircleIcon color="text.selected" boxSize="1.5rem" />
        </Box>
        <Text fontFamily="promo">How does this design make you feel?</Text>
      </HStack>
      <Image
        as={motion.img}
        gridArea="1 / 1"
        alignSelf="end"
        justifySelf="start"
        transformOrigin="top right"
        src={Arrow}
        variants={variants.arrow}
      />
      <Box
        as={motion.svg}
        gridArea="1 / 1"
        alignSelf="start"
        justifySelf="end"
        width="94px"
        height="57px"
        viewBox="0 0 94 57"
        fill="none"
        role="presentation"
        xmlns="http://www.w3.org/2000/svg"
        variants={variants.exclamation}
      >
        <motion.path
          d="M18.7711 37.0927L16.6967 8.1937L0.82674 6.22266L8.1878 35.7833L18.7711 37.0927Z"
          fill="#63C9BA"
          variants={variants.shape}
        />
        <motion.path
          d="M42.2455 39.5141L58.7488 8.22166L35.7939 0.742536L31.3447 38.2183L42.253 39.5152L42.2455 39.5141Z"
          fill="#63C9BA"
          variants={variants.shape}
        />
        <motion.path
          d="M49.1622 56.4014L93.9029 28.3976L74.8005 15.0408L45.2832 48.8297L49.1622 56.4014Z"
          fill="#63C9BA"
          variants={variants.shape}
        />
      </Box>
    </Grid>
  </MotionConfig>
)
