import { ScreenerFilter } from "../filters/screener"
import { generateFilterId } from "./generate-filter-id"

function createScreenerCategory(
  questionIndex: number
): ScreenerFilter["category"] {
  return `answer/screener:${questionIndex}/answer`
}

export function createScreenerFilter(
  questionIndex: number,
  answer: string
): ScreenerFilter {
  return {
    id: generateFilterId(),
    category: createScreenerCategory(questionIndex),
    type: "answer/screener",
    value: answer,
  }
}
