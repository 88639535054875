import { ActionType } from "Redux/reducers/screenshots/constants"
import { NoopAction, RawScreenshot, Screenshot } from "Types"
import { createArrayUtility } from "Utilities/immutable-array"

import { Actions } from "./action-creators"

// -- Helper functions

const { mergeFirst, updateFirst } = createArrayUtility<Screenshot>("screenshot")

export type ScreenshotsState = ReadonlyArray<Readonly<Screenshot>>
export type RawScreenshotsState =
  | undefined
  | ReadonlyArray<Readonly<RawScreenshot>>

export default function screenshotsReducer(
  state: ScreenshotsState = [],
  action: Actions | NoopAction = { type: "__NOOP__" }
): ScreenshotsState {
  switch (action.type) {
    case ActionType.SET_SCREENSHOTS:
      return action.payload
    case ActionType.LOAD_SCREENSHOT_REQUEST:
      return mergeFirst(
        state,
        { _clientId: action.payload },
        { _isLoading: true },
        { _isLoading: false }
      )
    case ActionType.LOAD_SCREENSHOT_SUCCESS: {
      return updateFirst(state, { _clientId: action.payload }, (prev) => ({
        ...prev,
        _url: prev.url,
        _isLoaded: true,
      }))
    }
    case ActionType.LOAD_SCREENSHOT_FAILURE:
      return mergeFirst(
        state,
        { _clientId: action.payload },
        { _isLoaded: false, _isLoading: false }
        // NOTE: Don't assert that the screenshot is loading, because load
        // failure can occur when a screenshot has no URL (in which case it will
        // not even start loading).
      )
    case ActionType.UPLOAD_SCREENSHOTS_REQUEST:
      return [...state, ...action.payload]
    case ActionType.UPLOAD_SCREENSHOT_PROGRESS:
      return mergeFirst(
        state,
        { _clientId: action.payload.clientId },
        { _progress: action.payload.progress }
      )
    case ActionType.UPLOAD_SCREENSHOT_UPLOADED:
      // Set saving to true because the screenshot starts being persisted here.
      return mergeFirst(
        state,
        { _clientId: action.payload },
        { _progress: 1, _isSaving: true },
        { _isSaving: false }
      )
    case ActionType.UPLOAD_SCREENSHOT_FAILURE:
      // Do nothing here.
      return state
    case ActionType.UPLOAD_SCREENSHOT_CREATED:
      return mergeFirst(
        state,
        { _clientId: action.payload.clientId },
        { ...action.payload.attributes, _isSaving: false },
        { _isSaving: true }
      )
    case ActionType.UPLOAD_SCREENSHOT_SUCCESS:
      return mergeFirst(
        state,
        { _clientId: action.payload.clientId },
        action.payload.attributes
      )
    case ActionType.UPDATE_SCREENSHOT_REQUEST:
      return mergeFirst(
        state,
        { _clientId: action.payload },
        { _isSaving: true },
        { _isSaving: false }
      )
    case ActionType.UPDATE_UNPERSISTED_SCREENSHOT:
      return mergeFirst(
        state,
        { _clientId: action.payload.clientId },
        action.payload.attributes,
        { id: null }
      )
    case ActionType.UPDATE_SCREENSHOT_SUCCESS:
      return mergeFirst(
        state,
        { _clientId: action.payload.clientId },
        { ...action.payload.attributes, _isSaving: false },
        { _isSaving: true }
      )
    case ActionType.UPDATE_SCREENSHOT_FAILURE:
      return mergeFirst(
        state,
        { _clientId: action.payload },
        { _isSaving: false },
        { _isSaving: true }
      )
  }
  return state
}
