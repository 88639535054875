import { Box, Divider, HStack, Icon, IconButton, Text } from "@chakra-ui/react"
import React, { useCallback } from "react"

import { DeleteIcon } from "Icons/DeleteIcon"
import { EditIcon } from "Icons/EditIcon"

import { DayAvailabilityArray } from "./DayOverrideModal"

interface DateOverridesRowProps {
  date: string
  overrides: DayAvailabilityArray
  editOverride: () => void
  deleteOverride: (date: string) => void
}

export const DateOverridesRow: React.FC<DateOverridesRowProps> = ({
  date,
  overrides,
  editOverride,
  deleteOverride,
}) => {
  const getFormattedTime = useCallback((timeStr: string) => {
    const [hours, minutes] = timeStr.split(":")
    const date = new Date()
    date.setHours(Number(hours), Number(minutes))

    return date
      .toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h12",
      })
      .replace(" ", "")
  }, [])
  return (
    <>
      <HStack justifyContent="space-between" w="full" p={4}>
        <Box>
          <Text color="text.primary">
            {new Date(`${date}T00:00:00`).toLocaleDateString(undefined, {
              weekday: "short",
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Text>
          {overrides.length > 0 ? (
            overrides.map((override, index) => {
              return (
                <Text
                  key={`${date}.${index}`}
                  fontSize="sm"
                  color="text.secondary"
                >
                  {getFormattedTime(override.starts_at)} -{" "}
                  {getFormattedTime(override.ends_at)}
                </Text>
              )
            })
          ) : (
            <Text fontSize="sm" color="text.secondary">
              Unavailable all day
            </Text>
          )}
        </Box>
        <HStack spacing="1">
          <IconButton
            icon={<Icon as={EditIcon} />}
            variant="ghost"
            size="sm"
            aria-label="Edit date overrides"
            onClick={editOverride}
          />
          <IconButton
            variant="ghost"
            size="sm"
            aria-label="Delete date overrides"
            icon={<Icon as={DeleteIcon} color="brand.neutral.default" />}
            onClick={() => deleteOverride(date)}
          />
        </HStack>
      </HStack>
      <Divider />
    </>
  )
}
