import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"
import { Z_INDEX_MAIN_NAV_MENUS } from "Constants/zIndexes"
import { Button } from "DesignSystem/components"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ExamplesIcon } from "Icons/ExamplesIcon"
import { HelpCenterIcon } from "Icons/HelpCenterIcon"
import { HelpCircleIcon } from "Icons/HelpCircleIcon"
import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { TestingGuidesIcon } from "Icons/TestingGuidesIcon"
import { onClickAddOrttoCookieParam } from "JavaScripts/analytics/ortto"

type HelpMenu = React.FC<React.PropsWithChildren<unknown>>

export const HelpMenu: HelpMenu = () => {
  return (
    <Menu placement="bottom" eventListeners={false} isLazy>
      <MenuButton
        as={Button}
        variant="secondary"
        rightIcon={<Icon as={ChevronDownIcon} />}
        leftIcon={<Icon as={HelpCircleIcon} />}
      >
        Help
      </MenuButton>
      <Portal>
        <MenuList zIndex={Z_INDEX_MAIN_NAV_MENUS}>
          <MenuItem
            as="a"
            target="_blank"
            href={Constants.EXAMPLES_URL}
            icon={<Icon as={ExamplesIcon} />}
            alignItems="center"
            onClick={onClickAddOrttoCookieParam}
          >
            <Flex justify="space-between" alignItems="center">
              Examples
              <Icon as={LinkExternalIcon} color="gray.500" ml={2} />
            </Flex>
          </MenuItem>
          <MenuItem
            as="a"
            target="_blank"
            href={Constants.TESTING_GUIDES_URL}
            icon={<Icon as={TestingGuidesIcon} />}
            onClick={onClickAddOrttoCookieParam}
          >
            <Flex justify="space-between" alignItems="center">
              Testing guides
              <Icon as={LinkExternalIcon} color="gray.500" ml={2} />
            </Flex>
          </MenuItem>
          <MenuItem
            as="a"
            target="_blank"
            href={Constants.HELP_CENTER_URL}
            icon={<Icon as={HelpCenterIcon} />}
            alignItems="center"
          >
            <Flex justify="space-between" alignItems="center">
              Help center
              <Icon as={LinkExternalIcon} color="gray.500" ml={2} />
            </Flex>
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
