import {
  Alert,
  AlertDescription,
  AlertTitle,
  Stack,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { SupportMailtoLink } from "Shared/components/Links/SupportMailtoLink"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { useEffect, useState } from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  GetModeratedStudyBookingRecordingsResponse,
  useDeleteModeratedStudyBookingRecording,
  useGetModeratedStudyBookingRecordings,
  useGetModeratedStudyBookings,
  useUpdateModeratedStudyBooking,
  useUpdateModeratedStudyBookingRecording,
} from "~/api/generated/usabilityhub-components"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import { FileUpload } from "./FileUpload"
import { Loading } from "./Loading"
import { Recording } from "./Recording"
import UploadingTile from "./uploading-tile"

export const InterviewSessionRecordings = () => {
  const [error, setError] = useState<{ title: string; message: string } | null>(
    null
  )
  const toast = useToast()
  const queryClient = useQueryClient()
  const [recordings, setRecordings] =
    useState<GetModeratedStudyBookingRecordingsResponse>([])
  const [uploadFailed, setUploadFailed] = useState<boolean>(false)

  const { moderatedStudyId, sessionId } = useTypedParams(
    ROUTES.INTERVIEW.RECORDINGS.SESSION
  )

  const { isLoading: isLoadingBookings, data: bookings } =
    useGetModeratedStudyBookings({
      pathParams: { moderatedStudyId },
    })

  const { invalidateStudySummaryQuery } = useModeratedStudyContext()

  const booking = bookings?.find((booking) => booking.id === sessionId)

  if (!booking) return null

  const {
    data: fetchedRecordings,
    isLoading: isLoadingRecordings,
    refetch,
  } = useGetModeratedStudyBookingRecordings(
    {
      pathParams: {
        moderatedStudyId,
        moderatedStudyBookingId: booking.id,
      },
    },
    { retry: false }
  )

  // Keep summary counts in sync with the main list of recordings
  useEffect(() => {
    queryClient.invalidateQueries(
      ["api", "moderated_studies", moderatedStudyId, "summary"],
      {
        exact: true,
      }
    )
  }, [fetchedRecordings])

  const isLoading = isLoadingBookings || isLoadingRecordings

  useEffect(() => {
    if (!isLoading && fetchedRecordings) {
      setRecordings(fetchedRecordings)
      if (
        fetchedRecordings.some((recording) => recording.status === "failed")
      ) {
        setUploadFailed(true)
        setError({
          title: "We encountered an error fetching this recording",
          message: "Please try uploading the recording again manually",
        })
      } else {
        setUploadFailed(false)
        // if this was the error and they've manually uploaded a file since,
        // remove the error. If the error is from somewhere else, leave it alone
        if (error?.title.includes("fetch")) {
          setError(null)
        }
      }
    }
  }, [isLoading, fetchedRecordings])

  const deleteMutation = useDeleteModeratedStudyBookingRecording({
    onMutate: () => {
      setError(null)
    },
    onSuccess: (_data, variables) => {
      const idx = recordings.findIndex(
        (recording) => recording.id === variables.pathParams.recordingId
      )
      if (idx > -1) {
        recordings.splice(idx, 1)
      } else {
        refetch()
      }
    },
    onError: () => {
      setError({
        title: "We encountered an error deleting this recording",
        message: "Please try deleting this recording again",
      })
    },
  })

  const updateMutation = useUpdateModeratedStudyBooking({
    onSuccess: () => {
      queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
        "bookings",
      ])
      toast({
        status: "success",
        title: "Auto-upload sucessfully disabled for this booking",
      })
    },
    onError: () => {
      toast({
        status: "error",
        title: "Auto-upload could not be disabled for this booking",
        description: "Please disable via the sessions tab",
      })
    },
  })

  const deleteVideo = (recordingId: string) => {
    deleteMutation.mutate({
      pathParams: {
        moderatedStudyId,
        moderatedStudyBookingId: booking.id,
        recordingId,
      },
    })
  }

  const renameMutation = useUpdateModeratedStudyBookingRecording({
    onMutate: () => {
      setError(null)
    },
    onSuccess: (_data, variables) => {
      const idx = recordings.findIndex(
        (recording) => recording.id === variables.pathParams.recordingId
      )
      if (variables.body?.filename && idx > -1) {
        if (recordings[idx].filename !== variables.body.filename) {
          recordings[idx] = {
            ...recordings[idx],
            ...{ filename: variables.body.filename },
          }
        }
      } else {
        refetch()
      }

      toast({
        title: "Filename updated",
        status: "success",
      })
    },
    onError: () => {
      setError({
        title: "We encountered an error renaming this recording",
        message: "Please try renaming this recording again",
      })
    },
  })

  const renameVideo = (recordingId: string, newFilename: string) => {
    renameMutation.mutate({
      pathParams: {
        moderatedStudyId,
        moderatedStudyBookingId: booking.id,
        recordingId,
      },
      body: {
        filename: newFilename,
      },
    })
  }

  const performAfterDisablingAutoUploads = (actionToPerform: () => void) => {
    if (booking.auto_upload_recordings) {
      updateMutation.mutate({
        pathParams: { moderatedStudyId, moderatedStudyBookingId: booking.id },
        body: {
          incentive: booking.incentive || undefined,
          location: booking.location,
          location_type: booking.location_type,
          auto_upload_recordings: false,
          hosts: booking.host_ids,
        },
      })
    }
    actionToPerform()
    // when we add/remove videos, we need to update the count in the steps nav bar
    invalidateStudySummaryQuery()
  }

  return (
    <Stack
      p={6}
      spacing={6}
      border="1px solid gray"
      borderColor="gray.200"
      borderRadius="md"
      bg="white"
    >
      {error && (
        <Alert status="error">
          <AlertTitle>{error.title}</AlertTitle>
          <AlertDescription>
            {error.message} or{" "}
            <SupportMailtoLink>get in touch with support.</SupportMailtoLink>
          </AlertDescription>
        </Alert>
      )}
      {isLoading ? (
        <Loading />
      ) : recordings?.length && !uploadFailed ? (
        // At the moment, this is always only 1
        recordings.map((recording) =>
          // failed shouldn't show here but in case it has, give an option to delete if someone somehow gets in that state
          recording.status === "processing" || recording.status === "failed" ? (
            <UploadingTile
              key={recording.id}
              metadata={{ name: recording.filename!, url: recording.url! }}
              onAbort={() =>
                performAfterDisablingAutoUploads(() =>
                  deleteVideo(recording.id)
                )
              }
            />
          ) : (
            <Recording
              key={recording.id}
              recording={recording}
              onDelete={() =>
                performAfterDisablingAutoUploads(() =>
                  deleteVideo(recording.id)
                )
              }
              onRename={renameVideo}
            />
          )
        )
      ) : (
        // If there's no file or the upload failed, give option for upload
        <FileUpload
          moderatedStudyId={moderatedStudyId}
          booking={booking}
          onComplete={() => performAfterDisablingAutoUploads(refetch)}
          onError={setError}
          autoUploadFailed={uploadFailed}
        />
      )}
    </Stack>
  )
}
