import { createIcon } from "@chakra-ui/react"
import React from "react"

export const HashTagIcon = createIcon({
  displayName: "HashTagIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      d="M9.49999 3L6.49999 21M17.5 3L14.5 21M20.5 8H3.5M19.5 16H2.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
