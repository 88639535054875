import { Flex } from "@chakra-ui/react"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import type { ResponseSection } from "Types"
import { RecordingContainer } from "UsabilityHub/components/RecordingContainer/RecordingContainer"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import React from "react"
import { useResponseSectionRecording } from "./useResponseSectionRecording"

export const RecordingsSimplePreview: React.FC<{
  responseSection: ResponseSection
}> = ({ responseSection }) => {
  const recording = useResponseSectionRecording(responseSection)
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)

  if (!recordingFeatureEnabled || recording === null) {
    return
  }

  return (
    <Flex
      backgroundColor="ds.background.neutral.resting"
      borderRadius="md"
      overflow="hidden"
    >
      <RecordingContainer
        layoutId={`recording_container_simplepreview_${recording.id}`}
        recording={recording}
        isParcipantView
      />
    </Flex>
  )
}
