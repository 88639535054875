import React, { PropsWithChildren, useCallback } from "react"
import { fetchAdminPayoutsThirdPartyOrder } from "~/api/generated/usabilityhub-components"
import { BaseResultsDataProvider } from "./BaseResultsDataProvider"
import { ResultUsabilityTestResponseData } from "./types"

type Props = {
  orderId: number
  revising: boolean
}

export const ThirdPartyOrderResultDataProvider: React.FC<
  PropsWithChildren<Props>
> = ({ children, orderId, revising }) => {
  const queryKey = `third-party-order-result-${orderId}-${revising}`
  const apiFunction = useCallback(
    async () =>
      (await fetchAdminPayoutsThirdPartyOrder({
        pathParams: { thirdPartyOrderId: orderId },
        queryParams: { revise: revising },
      })) as ResultUsabilityTestResponseData,
    [orderId, revising]
  )

  return (
    <BaseResultsDataProvider queryKey={queryKey} apiFunction={apiFunction}>
      {children}
    </BaseResultsDataProvider>
  )
}
