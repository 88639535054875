import { Box, HStack, Icon, IconProps, Text, Tooltip } from "@chakra-ui/react"
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToParentElement } from "@dnd-kit/modifiers"
import {
  SortableContext,
  hasSortableData,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { DragHandle } from "Components/drag-handle/drag-handle"
import { getSectionTypeRules } from "Constants/test-section-types"
import { HideFilledIcon } from "Icons/HideFilledIcon"
import { RecordingsFilledIcon } from "Icons/RecordingsFilledIcon"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import { Dispatch } from "Redux/app-store"
import { moveSection } from "Redux/reducers/test-builder-form/action-creators/sections"
import { getHasSubmitFailed } from "Redux/reducers/test-builder-form/selectors/form"
import {
  getSectionSummaries,
  getSectionTitleBySectionClientId,
  getSectionValid,
} from "Redux/reducers/test-builder-form/selectors/sections"
import { ClientId, UsabilityTestSectionType } from "Types"
import { useIsTestBuilderExternalStudy } from "UsabilityHub/hooks/useIsTestBuilderExternalStudy"
import { isEqual } from "lodash"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useUsabilityTestUserActivityContext } from "../UsabilityTestUserActivityContext"
import { SidebarListItem } from "./sidebar-list-item"

export const SidebarSectionsList: React.FC = () => {
  const dispatch: Dispatch = useDispatch()
  const [draggingSectionId, setDraggingSectionId] = useState<string | null>(
    null
  )
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )
  const sectionSummaries = useSelector(getSectionSummaries, (left, right) =>
    isEqual(left, right)
  ).map((s) => ({ id: s.sectionClientId, ...s }))
  const draggingSection = sectionSummaries.find(
    (ss) => ss.id === draggingSectionId
  )

  const handleDragStart = (event: DragStartEvent) => {
    setDraggingSectionId(event.active.id as string)
  }

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setDraggingSectionId(null)
      const { active, over } = event

      if (!hasSortableData(active)) return
      if (!hasSortableData(over)) return // Will also be false if `over` is null

      const sourceIndex = active.data.current.sortable.index
      const destinationIndex = over.data.current.sortable.index

      dispatch(moveSection(sourceIndex, destinationIndex))
    },
    [dispatch]
  )

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToParentElement]}
      autoScroll={false}
    >
      <SortableContext
        items={sectionSummaries}
        strategy={verticalListSortingStrategy}
      >
        <Box py={1}>
          {sectionSummaries.map((summary) => (
            <DraggableSection
              isDragging={draggingSectionId === summary.sectionClientId}
              key={summary.sectionClientId}
              {...summary}
            />
          ))}
        </Box>

        <DragOverlay
          dropAnimation={{
            duration: 100,
            easing: "ease",
          }}
        >
          {draggingSection ? <Section {...draggingSection} /> : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  )
}

interface SectionProps {
  sectionType: UsabilityTestSectionType
  sectionClientId: ClientId
  sectionHasLogic: boolean
  sectionHasRecording: boolean
  sectionIndex: number
}

const DraggableSection: React.FC<SectionProps & { isDragging: boolean }> = (
  props
) => {
  const { readOnly } = useUsabilityTestUserActivityContext()
  const isExternalStudy = useIsTestBuilderExternalStudy()

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.sectionClientId,
      disabled: readOnly || isExternalStudy,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Box
      ref={setNodeRef}
      opacity={props.isDragging ? 0 : 1}
      style={style}
      {...attributes}
      {...listeners}
      py={1}
    >
      <Section {...props} />
    </Box>
  )
}

const Section: React.FC<SectionProps> = ({
  sectionClientId,
  sectionType,
  sectionHasLogic,
  sectionHasRecording,
  sectionIndex,
}) => {
  const { colorScheme, icon } = getSectionTypeRules(sectionType)
  const sectionValid = useSelector(getSectionValid(sectionIndex))
  const hasSubmitFailed = useSelector(getHasSubmitFailed)
  const sectionTitle = useSelector(
    getSectionTitleBySectionClientId(sectionClientId, sectionIndex)
  )
  const isExternalStudy = useIsTestBuilderExternalStudy()
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <SidebarListItem
      targetId={`section_${sectionIndex}`}
      isDraggable={!readOnly && !isExternalStudy}
    >
      <Box
        backgroundColor={colorScheme}
        rounded="full"
        boxSize={5}
        flexShrink={0}
        p={0.5}
      >
        <Icon as={icon} boxSize={4} color="white" display="block" />
      </Box>
      <Tooltip placement="top" label={sectionTitle} openDelay={500}>
        <Text flexGrow={1} color="gray.900" noOfLines={1}>
          {sectionTitle}
        </Text>
      </Tooltip>

      <HStack spacing={2} align="center">
        {sectionHasRecording && (
          <InfoIcon
            label="This section contains recording requirements"
            icon={RecordingsFilledIcon}
          />
        )}
        {sectionHasLogic && (
          <InfoIcon
            label="This section contains logic conditions"
            icon={HideFilledIcon}
          />
        )}
        {!sectionValid && hasSubmitFailed && (
          <InfoIcon
            label="This section contains errors you need to fix"
            icon={WarningFilledIcon}
            color="red.500"
          />
        )}

        {!readOnly && !isExternalStudy && <DragHandle color="gray.500" />}
      </HStack>
    </SidebarListItem>
  )
}

type InfoIconProps = {
  label: string
  icon: React.FC<IconProps>
  color?: string
}

const InfoIcon: React.FC<InfoIconProps> = ({
  label,
  icon,
  color = "gray.400",
}) => (
  <Tooltip hasArrow placement="top" label={label}>
    <Icon color={color} boxSize={5} as={icon} />
  </Tooltip>
)
