import {
  Flex,
  HStack,
  Icon,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react"
import { Avatar, Button, ButtonProps } from "DesignSystem/components"
import { UserAddIcon } from "Icons/UserAddIcon"
import {
  ChakraStylesConfig,
  GroupBase,
  OptionBase,
  Select,
  SelectComponentsConfig,
  chakraComponents,
} from "chakra-react-select"
import React, { useEffect, useRef } from "react"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"

interface IconOption extends OptionBase {
  label: string
  value: string
  avatar_url_medium: string
}

const customComponents: SelectComponentsConfig<
  IconOption,
  false,
  GroupBase<IconOption>
> = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <HStack gap={2}>
        <Avatar
          size="small"
          appearance="profile"
          name={props.label}
          profileImageSrc={props.data.avatar_url_medium}
        />
        <span>{children}</span>
      </HStack>
    </chakraComponents.Option>
  ),
}

type AddMemberProps = {
  label?: string
  variant?: ButtonProps["variant"]
  collaborators: TeamMember[]
  onSelect: (memberId: number | undefined) => void
}

export const AddMember: React.FC<AddMemberProps> = ({
  label = "Add member",
  variant = "outline",
  collaborators,
  onSelect,
}) => {
  const [isAdding, { on: startAdding, off: stopAdding }] = useBoolean(false)

  const ref = useRef(null)
  useOutsideClick({ ref, handler: stopAdding })

  const options: IconOption[] = collaborators.map((collaborator) => ({
    label: collaborator.name,
    value: String(collaborator.id),
    avatar_url_medium: collaborator.avatar_url_medium,
  }))

  const chakraStyles: ChakraStylesConfig<IconOption, false> = {
    option: (provided) => ({
      ...provided,
      p: 3,
    }),
    inputContainer: (provided) => ({
      ...provided,
      minW: 32,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "6px",
    }),
  }

  useEffect(() => {
    if (isAdding) {
      const input = document.querySelector(
        "#add-member #react-select-2-input:first-of-type"
      ) as HTMLInputElement
      input?.focus()
    }
  })

  return (
    <>
      <Button
        display={isAdding ? "none" : "flex"}
        variant={variant}
        leftIcon={<Icon as={UserAddIcon} color="brand.neutral.default" />}
        aria-label={label}
        isDisabled={collaborators.length === 0 || isAdding}
        onClick={startAdding}
      >
        {label}
      </Button>
      <Flex display={isAdding ? "flex" : "none"} ref={ref} minWidth={32}>
        <Select
          id="add-member"
          placeholder="Search"
          size="sm"
          chakraStyles={chakraStyles}
          defaultMenuIsOpen
          openMenuOnFocus
          isMulti={false}
          isDisabled={!isAdding}
          options={options}
          components={customComponents}
          value={null}
          onChange={(e) => {
            onSelect(Number(e?.value))
            stopAdding()
          }}
        />
      </Flex>
    </>
  )
}
