import { HStack, Text, useDisclosure } from "@chakra-ui/react"
import { Button, IconButton } from "DesignSystem/components"
import { AlertCircleFilledIcon } from "Icons/AlertCircleFilledIcon"
import { CloseIcon } from "Icons/CloseIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { useNavigate } from "react-router"
import { useGetAutomaticPurchase } from "~/api/generated/usabilityhub-components"

export const AutoTopUpFailedBanner: React.FC = () => {
  const navigate = useNavigate()
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const user = useCurrentUser()
  const { data, isLoading, isError } = useGetAutomaticPurchase(
    {},
    { enabled: user.can_purchase_credits }
  )

  if (
    !user.can_purchase_credits ||
    !isOpen ||
    isLoading ||
    isError ||
    !data.automatic_purchase?.error_message
  )
    return null

  return (
    <HStack
      padding={4}
      backgroundColor="ds.background.danger.subtle.resting"
      justifyContent={"center"}
      alignContent={"center"}
    >
      <HStack>
        <AlertCircleFilledIcon boxSize={5} color="ds.icon.danger" />
        <Text textStyle="ds.heading.secondary">Auto top-up issue</Text>
        <Text textStyle="ds.paragraph.primary">
          We couldn’t add credits to your account due to an issue with your
          payment. Please review your payment details or manually retry the
          auto-top up.
        </Text>
      </HStack>
      <HStack flexShrink={0}>
        <Button
          size="compact"
          onClick={() => navigate(ROUTES.CREDITS.PURCHASE.buildPath({}))}
        >
          Resolve Issue
        </Button>
        <IconButton
          aria-label="Dismiss"
          size="compact"
          variant="secondary"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </HStack>
    </HStack>
  )
}
