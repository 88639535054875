import React from "react"

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react"
import { ProIcon } from "Icons/ProIcon"

type Props = {
  title?: string
  content?: string
}

export const ProTrialCallout: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  content,
  children,
}) => (
  <Alert colorScheme="purple">
    <AlertIcon as={ProIcon} w="5" />
    {title && <AlertTitle>{title}</AlertTitle>}
    <AlertDescription>
      {content}
      {children}
    </AlertDescription>
  </Alert>
)
