import { TabList, Text, useTab } from "@chakra-ui/react"
import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query"
import { Button, ButtonProps } from "DesignSystem/components"
import React, { forwardRef } from "react"

import {
  ListModeratedStudiesError,
  ListModeratedStudiesResponse,
  ListProjectsError,
  ListProjectsResponse,
  ListUsabilityTestsError,
  ListUsabilityTestsResponse,
  ListVariationSetsError,
  ListVariationSetsResponse,
} from "~/api/generated/usabilityhub-components"

interface SearchTabsProps {
  testQuery: UseInfiniteQueryResult<
    ListUsabilityTestsResponse,
    ListUsabilityTestsError
  >
  projectQuery: UseInfiniteQueryResult<ListProjectsResponse, ListProjectsError>
  variationSetsQuery: UseInfiniteQueryResult<
    ListVariationSetsResponse,
    ListVariationSetsError
  >
  moderatedStudiesQuery: UseQueryResult<
    ListModeratedStudiesResponse,
    ListModeratedStudiesError
  >
}

type TabButtonProps = ButtonProps & {
  count?: number
}

const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  ({ children, count, ...props }, ref) => {
    const tabProps = useTab({ ...props, ref })
    const isSelected = !!tabProps["aria-selected"]
    return (
      <Button
        isSelected={isSelected}
        whiteSpace="nowrap"
        gap="0.5ch"
        {...tabProps}
      >
        {children}
        {count !== undefined && (
          <Text
            as="span"
            color={isSelected ? "ds.text.selected" : "ds.text.subtle"}
          >
            {count}
          </Text>
        )}
      </Button>
    )
  }
)

export const SearchTabs = ({
  testQuery,
  projectQuery,
  variationSetsQuery,
  moderatedStudiesQuery,
}: SearchTabsProps) => (
  <TabList gap={3}>
    <TabButton count={testQuery.data?.pages[0].total_records ?? 0}>
      Tests
    </TabButton>

    <TabButton
      count={
        moderatedStudiesQuery.isLoading
          ? undefined
          : moderatedStudiesQuery.data?.length
      }
    >
      Interviews
    </TabButton>

    <TabButton count={projectQuery.data?.pages[0].total_records ?? 0}>
      Projects
    </TabButton>

    <TabButton count={variationSetsQuery.data?.pages[0].total_records ?? 0}>
      Variation sets
    </TabButton>
  </TabList>
)
