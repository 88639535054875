import { Flex, FlexProps, Text } from "@chakra-ui/react"
import { TimeIcon } from "Icons/TimeIcon"
import React from "react"

type Props = {
  duration: string | null
  isLoading: boolean
} & FlexProps

export const EstimatedTurnaround: React.FC<Props> = ({
  duration,
  isLoading,
  ...flexProps
}) => {
  return (
    <Flex justify="center" align="center" {...flexProps}>
      <TimeIcon boxSize={5} mr={2} />
      <Text>Estimated turnaround time:</Text>
      <Text
        fontWeight={500}
        color={duration && !isLoading ? "ds.text.success" : "ds.text.warning"}
        ml={1}
      >
        {isLoading ? "Pending" : (duration ?? "—")}
      </Text>
    </Flex>
  )
}
