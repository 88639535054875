import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { FigmaIcon } from "Shared/icons/FigmaIcon"
import { useFigmaOAuth } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/PrototypeSectionCard/PrototypeSectionFigmaFlow/useFigmaOAuth"
import { getDateString } from "Utilities/date-formats"
import React from "react"
import {
  GetIntegrationsResponse,
  useDeleteIntegration,
} from "~/api/generated/usabilityhub-components"

interface Props {
  data?: GetIntegrationsResponse
  onConnectDisconnect: () => void | Promise<void>
}

export const FigmaIntegrationStatus: React.FC<
  React.PropsWithChildren<Props>
> = ({ data, onConnectDisconnect }) => {
  const toast = useToast()
  const { handleFigmaOAuth } = useFigmaOAuth(onConnectDisconnect)
  const { mutateAsync: deleteIntegration, isLoading: isDeletingIntegration } =
    useDeleteIntegration({
      onSuccess: async () => {
        toast({
          title: "Disconnected from Figma",
          status: "success",
        })
        await onConnectDisconnect()
      },
    })

  return (
    <Flex gap={4}>
      <FigmaIcon boxSize={10} mt={2} />
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Figma
        </Text>
        <Text>Connect to Figma to import your designs</Text>
      </Box>
      <Spacer />

      {data === undefined ? (
        <Spinner size="sm" />
      ) : !data.figma ? (
        <Button
          flexShrink={0}
          colorScheme="brand.primary"
          onClick={handleFigmaOAuth}
        >
          Connect
        </Button>
      ) : (
        <>
          <Text
            fontSize="xs"
            color="text.secondary"
            textAlign="right"
            flexShrink={0}
          >
            Connected on
            <br />
            {getDateString(data.figma.created_at, "medium")}
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<Icon as={MenuKebabIcon} color="gray.500" />}
            />
            <MenuList>
              <MenuItem
                color="red"
                isDisabled={isDeletingIntegration}
                onClick={async () => {
                  await deleteIntegration({
                    body: { integration: "figma" },
                  })
                }}
              >
                Disconnect
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </Flex>
  )
}
