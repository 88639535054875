import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
} from "@chakra-ui/react"
import {
  DesktopOnly,
  MobileOnly,
} from "Components/break-points/desktop-and-mobile-break-points"
import { getEnvState } from "JavaScripts/state"
import React from "react"
import {
  useAdminStopEmulatingPanelist,
  useAdminStopEmulatingUser,
} from "~/api/generated/usabilityhub-components"

type Props = {
  name: string
  id: number
  accountId: number
  isPanelist?: boolean
}

export const EmulationAlert: React.FC<Props> = ({
  name,
  id,
  accountId,
  isPanelist = false,
}) => {
  // We always want to redirect to Lyssna, regardless of which domain we're on currently
  const env = getEnvState()
  let url = window.location.protocol + "//" + env.APP_DOMAIN

  if (window.location.port) {
    url += `:${window.location.port}`
  }

  const { mutateAsync: stopEmulatingUser, isLoading: isUserLoading } =
    useAdminStopEmulatingUser({
      onSuccess: () => {
        window.location.href = `${url}/admin/accounts/${accountId}`
      },
    })

  const { mutateAsync: stopEmulatingPanelist, isLoading: isPanelistLoading } =
    useAdminStopEmulatingPanelist({
      onSuccess: () => {
        window.location.href = `${url}/admin/testers/${accountId}`
      },
    })

  return (
    <Alert
      colorScheme="orange"
      position="sticky"
      top={0}
      zIndex="banner"
      rounded="none"
    >
      <Flex alignItems="center" justifyContent="center" w="full">
        <AlertIcon mr={4} />
        <AlertTitle mb={0}>
          <DesktopOnly>Using Lyssna as </DesktopOnly>
          <MobileOnly>Emulating </MobileOnly>
          {name}
        </AlertTitle>
        <DesktopOnly>
          <AlertDescription>
            User ID: {id}, Account ID: {accountId}
          </AlertDescription>
        </DesktopOnly>
        <Button
          size="sm"
          colorScheme="undefined"
          variant="inverted"
          borderColor="alert.warning.icon"
          ml={4}
          onClick={
            isPanelist
              ? () => stopEmulatingPanelist({})
              : () => stopEmulatingUser({})
          }
          isLoading={isUserLoading || isPanelistLoading}
          _hover={{ borderColor: "alert.warning.hover" }}
        >
          Switch back
        </Button>
      </Flex>
    </Alert>
  )
}
