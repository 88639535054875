import { Flex, FormLabel, Icon, Switch } from "@chakra-ui/react"
import { HelpPopover } from "Components/exports"
import { Button } from "DesignSystem/components"
import { RefreshIcon } from "Icons/RefreshIcon"
import { Dispatch } from "Redux/app-store"
import {
  getFormName,
  getFormValue,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { useTranslate } from "Shared/hooks/useTranslate"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts/SectionIndexContext"
import { useModal } from "Utilities/modals/use-modal"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldsProps, change } from "redux-form"
import { MarkdownTextarea } from "../../TestFormMarkdown"
import { TestInstructionsResetConfirmationModal } from "./TestInstructionsResetConfirmationModal"

const MotionFlex = motion(Flex)

type Props = {
  defaultLocalePath: string
  tooltipSlot: React.ReactNode
}

export const TestInstructionsField: React.FC<Props> = ({
  defaultLocalePath,
  tooltipSlot,
}) => {
  const dispatch = useDispatch<Dispatch>()
  const t = useTranslate()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const { open: openResetConfirmationModal } = useModal(
    TestInstructionsResetConfirmationModal
  )

  const sectionIndex = useSectionIndexContext()
  const fieldPath = `sections[${sectionIndex}].test_instructions`
  const testInstructions = useSelector(getFormValue(fieldPath))
  const defaultInstructions = t(defaultLocalePath).trimEnd()

  const [isEnabled, setIsEnabled] = useState(!!testInstructions)
  const [previousValue, setPreviousValue] = useState(
    testInstructions || defaultInstructions
  )

  const resetToDefault = () => {
    dispatch(change(getFormName(), fieldPath, defaultInstructions))
  }

  const toggleCustomize = () => {
    if (isEnabled) {
      setPreviousValue(testInstructions)
      dispatch(change(getFormName(), fieldPath, null))
    } else {
      dispatch(change(getFormName(), fieldPath, previousValue))
    }

    setIsEnabled(!isEnabled)
  }

  const switchId = `test-instructions-switch-${sectionIndex}`

  return (
    <Flex direction="column" gap={3}>
      <Flex alignItems="center">
        <Switch
          id={switchId}
          isReadOnly={readOnly}
          isChecked={isEnabled}
          onChange={toggleCustomize}
          ms={1}
        />

        <FormLabel htmlFor={switchId} m={0} ms={2}>
          Customize system instructions
        </FormLabel>

        <HelpPopover>{tooltipSlot}</HelpPopover>
      </Flex>

      <AnimatePresence>
        {isEnabled && (
          <MotionFlex
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
            direction="column"
            align="flex-start"
            gap={3}
          >
            <Field name={fieldPath} component={WrappedTestInstructionsField} />

            <Button
              leftIcon={<Icon as={RefreshIcon} />}
              onClick={() =>
                openResetConfirmationModal({
                  handleReset: resetToDefault,
                })
              }
            >
              Reset to default instructions
            </Button>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Flex>
  )
}

const WrappedTestInstructionsField: React.FC<
  React.PropsWithChildren<WrappedFieldsProps>
> = ({ input }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return <MarkdownTextarea rows={3} isReadOnly={readOnly} {...input} />
}
