import { Text } from "@chakra-ui/react"
import React from "react"

import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"
import { useSectionContext } from "UsabilityHub/contexts"

import { SectionCard } from "../SectionCard"
import { SectionInstructionField } from "../SectionFields/SectionInstructionField"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

import { Flex } from "@chakra-ui/react"
import { HelpPopover } from "Components/exports"
import { Heading } from "DesignSystem/components"
import { TestInstructionsField } from "../SectionFields/TestInstructionsField"
import { CardSortOptions } from "./CardSortOptions"
import { CardSortSectionCardList } from "./CardSortSectionCardList"
import { CardSortSectionCategoryList } from "./CardSortSectionCategoryList"

export const CardSortSectionCard: React.FC = () => {
  const { section } = useSectionContext()
  const sortType = section.card_sort_attributes?.card_sort_type

  return (
    <SectionCard>
      <SectionLogicActions />
      <TestCardDivider />
      <CardSortOptions />
      <TestCardDivider />

      <Heading as="h3" textStyle="ds.heading.secondary" color="ds.text.default">
        Instructions
      </Heading>

      <SectionInstructionField
        expandable
        rows={1}
        label="Instructions"
        placeholder="Keep this short and straightforward"
        helpIconSlot={
          <HelpPopover>
            A specific task you may want to ask the participant to perform as
            part of this card sort. Keep these short and straightforward.
          </HelpPopover>
        }
      />
      <TestInstructionsField
        defaultLocalePath={`test.instructions.card_sort.default_system_instructions.${sortType ?? "open"}`}
        tooltipSlot={
          <Flex direction="column" gap={2}>
            <Text>
              Information to ensure participants understand how to interact with
              a card sort of this type. These instructions will be translated if
              your test has been set up using one of our supported languages.
            </Text>
            <Text>
              Customizing test information allows you to edit what is shown to
              participants. We don’t recommend removing them unless in very
              specific situations. If more detailed instructions are needed, add
              an instruction section at the start of the test.
            </Text>
          </Flex>
        }
      />
      <TestCardDivider />
      <CardSortSectionCardList />
      {sortType !== "open" && <CardSortSectionCategoryList />}
      <Text as="label" fontWeight="semibold">
        Follow up questions
      </Text>
      <SectionQuestions />
    </SectionCard>
  )
}
