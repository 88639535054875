import { DeviceType, PermittedDeviceType } from "Types"

export const isDeviceTypePermitted = (
  deviceType: DeviceType,
  permittedDeviceType: PermittedDeviceType
) => {
  switch (permittedDeviceType) {
    case "any":
      return true
    case "desktop_only":
      return deviceType === "desktop"
    case "mobile_only":
      return deviceType === "mobile"
  }
}

export const DEVICE_TYPE_LABELS: Record<PermittedDeviceType, string> = {
  mobile_only: "Mobile only",
  desktop_only: "Desktop only",
  any: "All devices",
}
