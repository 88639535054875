import {
  Button,
  HStack,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { InfoFilledIcon } from "Icons/InfoFilledIcon"
import variationSetImage from "Images/app-illustrations/variation-set.png"
import { PlanUpgradeSource } from "Types"
import { StaticPlanDrawer } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"

type Props = {
  source: PlanUpgradeSource
}

export const DescribeVariationSetModal: FunctionalModal<Props> = ({
  source,
  isOpen,
  onClose,
}) => {
  const { open: openPlanDrawer } = useModal(StaticPlanDrawer, "plan-drawer")
  const viewPlans = () => {
    onClose()
    openPlanDrawer({
      requestedFeatures: [
        {
          feature: "test_sets",
        },
      ],
      source: source,
    })
  }

  return (
    <Modal size="5xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent data-qa="describe-test-set-modal">
        <ModalCloseButton />
        <HStack direction="row" mx={10} mb={8} p={3}>
          <VStack px={3} spacing={4}>
            <ModalHeader
              p={0}
              color="text.primary"
              fontSize="3xl"
              fontWeight="semibold"
            >
              Elevate your research using variation sets
            </ModalHeader>
            <Text color="text.primary">
              Variation sets enable testing different design versions or
              multiple tests on the same design, while ensuring unique
              participants across all tests in the set.
            </Text>
            <Text color="text.primary">
              This prevents bias and is ideal for iterating on a design or
              testing variations to determine the best performer.
            </Text>
            <HStack
              spacing={3}
              backgroundColor="brand.primary.100"
              rounded={6}
              px={4}
              py={3}
            >
              <InfoFilledIcon
                color="brand.primary.500"
                mt={1}
                alignSelf="flex-start"
              />
              <Text color="text.primary">
                Variation sets are available on our Basic and Pro plans.
              </Text>
            </HStack>
            <HStack width="full">
              <Button
                width="full"
                colorScheme="brand.primary"
                onClick={viewPlans}
              >
                Upgrade plan
              </Button>
              <Button
                width="full"
                as="a"
                textDecoration="none"
                target="_blank"
                href={Constants.HELP_CENTER_VARIATION_SETS_URL}
              >
                Learn more
              </Button>
            </HStack>
          </VStack>
          <Image
            src={variationSetImage}
            alt=""
            role="presentation"
            width="460px"
          />
        </HStack>
      </ModalContent>
    </Modal>
  )
}
