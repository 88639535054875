import { Box, Flex, FlexProps, Icon, Text, VStack } from "@chakra-ui/react"
import { DeleteStudyModal } from "Components/delete-usability-test-modal/DeleteStudyModal"
import { Button, IconButton } from "DesignSystem/components"
import { AddIcon } from "Icons/AddIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import { MoveUsabilityTestsModal } from "UsabilityHub/components/UsabilityTestContextMenu/MoveUsabilityTestsModal"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import { AnimatePresence, motion } from "framer-motion"
import React, { ComponentProps, useState } from "react"
import { useNavigate } from "react-router"
import { Study } from "~/api/generated/usabilityhubSchemas"
import { ROUTES } from "../routes"
import { EmptyStateNoTests } from "./EmptyStateNoTests"
import { StudySkeleton } from "./StudySkeleton"
import { UsabilityTestCard } from "./UsabilityTestCard"
import ModeratedStudyCard from "./moderated-study-card/ModeratedStudyCard"
import { UsabilityTestCardListContext } from "./types"

const MotionBox = motion(Box)

type Props = {
  studies: Study[]
  context: UsabilityTestCardListContext
  projectIdForNewTestsFromEmptyState?: string
  compactPrompt?: boolean
  emptyStateSlot?: React.ReactNode
  isLoading: boolean
} & Omit<
  ComponentProps<typeof UsabilityTestCard>,
  | "study"
  | "isSelected"
  | "handleSelect"
  | "disableContextMenu"
  | "isSelectable"
> &
  FlexProps

export const StudyList: React.FC<Props> = ({
  studies,
  context,
  projectIdForNewTestsFromEmptyState,
  showProjectInfo,
  compactPrompt,
  isLoading,
  emptyStateSlot,
  ...props
}) => {
  const { open: openMoveModal } = useModal(MoveUsabilityTestsModal)
  const { open: openDeleteTestModal } = useModal(DeleteStudyModal)
  const [selectedTestIds, setSelectedTestIds] = useState<string[]>([])

  return (
    <>
      <Flex direction="column" gap={4} {...props}>
        {isLoading ? (
          <>
            <Box data-qa="test-skeleton" display="none" />
            <StudySkeleton />
            <StudySkeleton />
            <StudySkeleton />
            <StudySkeleton />
          </>
        ) : studies.length > 0 ? (
          studies.map((study) => {
            if (study.type === "usability_test") {
              return (
                <UsabilityTestCard
                  key={study.id}
                  study={study}
                  showProjectInfo={showProjectInfo}
                  isSelected={selectedTestIds.includes(study.id)}
                  disableContextMenu={selectedTestIds.length > 0}
                  isSelectable
                  handleSelect={(newValue) => {
                    if (newValue) {
                      setSelectedTestIds([...selectedTestIds, study.id])
                    } else {
                      setSelectedTestIds(
                        selectedTestIds.filter((id) => id !== study.id)
                      )
                    }
                  }}
                />
              )
            } else {
              return <ModeratedStudyCard key={study.id} study={study} />
            }
          })
        ) : (
          (emptyStateSlot ?? (
            <EmptyTestPrompt context={context} compactPrompt={compactPrompt} />
          ))
        )}
      </Flex>
      {studies !== undefined && (
        <AnimatePresence>
          {selectedTestIds.length > 0 && (
            <MotionBox
              animate={{ y: 0, x: "-50%" }}
              initial={{ y: "8rem", x: "-50%" }}
              exit={{ y: "8rem", x: "-50%" }}
              transition={{
                type: "spring",
                stiffness: 600,
                damping: 30,
              }}
              pos="fixed"
              left="50%"
              bottom="2rem"
            >
              <Flex
                align="center"
                p={3}
                borderColor="ds.border.default"
                borderWidth="1px"
                rounded="16"
                boxShadow="ds.overlay"
                bgColor="ds.surface.raised.resting"
                gap={2}
              >
                <Flex minW={32} justifyContent="start" align="center" gap={2}>
                  <IconButton
                    variant="subtle"
                    size="compact"
                    icon={<Icon as={RemoveFilledIcon} />}
                    onClick={() => setSelectedTestIds([])}
                    aria-label="Deselect all"
                  />
                  <Text as="div" textStyle="ds.heading.secondary">
                    {selectedTestIds.length} selected
                  </Text>
                </Flex>
                <Button
                  size="emphasized"
                  onClick={() =>
                    openMoveModal({
                      testIds: selectedTestIds.map(Number),
                      onSuccess: () => setSelectedTestIds([]),
                    })
                  }
                >
                  Move to
                </Button>
                <Button
                  variant="danger"
                  size="emphasized"
                  onClick={() =>
                    openDeleteTestModal({
                      tests: studies.filter((study) =>
                        selectedTestIds.includes(study.id)
                      ),
                      onSuccess: () => setSelectedTestIds([]),
                    })
                  }
                >
                  Delete
                </Button>
              </Flex>
            </MotionBox>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

const EmptyTestPrompt: React.FC<Pick<Props, "compactPrompt" | "context">> = ({
  context,
  compactPrompt,
}) => {
  const isUserAllowedToCreateTests = useCurrentUser().can_manage_tests
  const navigate = useNavigate()

  return compactPrompt ? (
    <VStack
      borderColor="gray.200"
      borderWidth={1}
      rounded="md"
      spacing={3}
      py={8}
    >
      <Text color="text.secondary">
        {isUserAllowedToCreateTests
          ? "Tests you create will be shown here."
          : "Tests will be shown here."}
      </Text>
      {isUserAllowedToCreateTests && (
        <Button
          onClick={() => navigate(ROUTES.TESTS.NEW.path)}
          size="emphasized"
          leftIcon={
            <AddIcon
              color="primary"
              fontSize="lg"
              transform="translateY(-1px)"
            />
          }
        >
          Create your first test
        </Button>
      )}
    </VStack>
  ) : (
    <EmptyStateNoTests
      isUserAllowedToCreateTests={isUserAllowedToCreateTests}
      context={context}
    />
  )
}
