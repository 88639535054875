import {
  Menu,
  MenuButton,
  MenuList,
  TabList,
  useMediaQuery,
  useTabsContext,
} from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import React, { isValidElement, PropsWithChildren, ReactElement } from "react"
import { CollapsibleTabListContext } from "./Context"
import { Tab } from "./Tab"

type RootProps = {
  narrowWidth?: string
}

export const Root = ({
  narrowWidth = "32em",
  children,
}: PropsWithChildren<RootProps>) => {
  const { selectedIndex } = useTabsContext()

  const [isNarrow] = useMediaQuery(`(max-width: ${narrowWidth})`, {
    ssr: false,
  })

  const selectedChild = React.Children.toArray(children)
    .map(getTabFromChild)
    .filter(Boolean)[selectedIndex]

  if (isNarrow) {
    return (
      <CollapsibleTabListContext.Provider value={{ display: "menu" }}>
        <Menu closeOnSelect>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {selectedChild?.props?.children}
          </MenuButton>
          <MenuList overflow="auto">{children}</MenuList>
        </Menu>
      </CollapsibleTabListContext.Provider>
    )
  }

  return (
    <CollapsibleTabListContext.Provider value={{ display: "tabs" }}>
      <TabList>{children}</TabList>
    </CollapsibleTabListContext.Provider>
  )
}

const getTabFromChild = (child: React.ReactNode): ReactElement | null => {
  if (!isValidElement(child) || child.type === "string") return null

  if (child.type === Tab) return child

  for (const descendant of React.Children.toArray(child.props.children)) {
    const tab = getTabFromChild(descendant)
    if (tab) return tab
  }

  return null
}
