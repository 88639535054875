import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Link,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { AutoUpdatingRemainingTime } from "Components/trial-widget/AutoUpdatingRemainingTime"
import { ChurnReason } from "Types"
import {
  useActiveStripeSubscription,
  useRefreshCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import {
  useHasUpcomingSubscriptionDuringATrial,
  useIsTrialing,
} from "Utilities/account"
import { getDateString } from "Utilities/date-formats"
import { shuffle } from "lodash"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"
import { useDeleteSubscription } from "~/api/generated/usabilityhub-components"
import { Plan, Subscription } from "~/api/generated/usabilityhubSchemas"
import { usePlanChangerContextOnScreen } from "./plan-changer-context"

const ChurnSurveySchema = Yup.object({
  reason: Yup.string()
    .required()
    .oneOf([
      ChurnReason.Unspecified,
      ChurnReason.NothingLeftToTest,
      ChurnReason.TooExpensive,
      ChurnReason.LackOfUse,
      ChurnReason.MissingFeature,
      ChurnReason.BadTestResults,
      ChurnReason.SwitchedToCompetitor,
      ChurnReason.Other,
    ]),
  additionalInformation: Yup.string().max(
    1000,
    "Please keep your feedback to 1000 characters or less"
  ),
})

type FormValues = Yup.InferType<typeof ChurnSurveySchema>

export const ChurnSurvey: React.FC<FlexProps> = (props) => {
  const {
    dispatch: planChangeDispatch,
    currentPlan,
    churnSurveySource,
    onClose,
  } = usePlanChangerContextOnScreen("churn-survey")

  const refreshCurrentAccount = useRefreshCurrentAccount()

  const isTrialing = useIsTrialing()

  if (!currentPlan) {
    throw new Error(`Cannot access churn survey without a current plan`)
  }

  const activeSub = useActiveStripeSubscription()
  const hasUpcomingSubDuringATrial = useHasUpcomingSubscriptionDuringATrial()
  const { mutate: deleteSubscription } = useDeleteSubscription({
    onSuccess: () => {
      refreshCurrentAccount()

      const title =
        isTrialing && !hasUpcomingSubDuringATrial
          ? `${currentPlan.name} plan free trial canceled`
          : `${currentPlan.name} plan subscription canceled`
      const message = isTrialing
        ? `You${"\u2019"}re now on the Free plan.`
        : `You${"\u2019"}ll be switched to the Free plan at the end of your current billing period on **${periodEndText}**.`

      planChangeDispatch({
        type: "plan-changed",
        title: title,
        message: message,
        changeToFree: isTrialing,
      })
    },
    onError: () => {
      const errorMessage =
        "Sorry, we were unable to cancel your subscription. Please refresh the page and try again."
      planChangeDispatch({
        type: "plan-change-failed",
        error: errorMessage,
      })
    },
  })

  const [churnOptions] = useState(() => [
    ChurnReason.Unspecified,
    ...shuffle([
      ChurnReason.NothingLeftToTest,
      ChurnReason.TooExpensive,
      ChurnReason.LackOfUse,
      ChurnReason.MissingFeature,
      ChurnReason.BadTestResults,
      ChurnReason.SwitchedToCompetitor,
    ]),
    ChurnReason.Other,
  ])

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(ChurnSurveySchema),
    defaultValues: {
      reason: ChurnReason.Unspecified,
      additionalInformation: "",
    },
  })

  if (!activeSub) return null

  const periodEndText = getDateString(activeSub.current_period_end!)

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    // Free plan users don't have a subscription so we need to call delete
    // rather than update here.
    deleteSubscription({
      pathParams: {
        guid: activeSub!.guid,
      },
      body: {
        reason: values.reason,
        additional_information: values.additionalInformation ?? null,
      },
    })
  }

  const followUpQuestion = reasonToFollowUpQuestion(watch("reason"))

  return (
    <Flex
      direction="column"
      background="gray.50"
      color="text.primary"
      {...props}
    >
      {/* Show the headling and content */}
      <Heading fontSize="xl" fontWeight="semibold" mb={4}>
        <HeadingMessage
          isTrialing={isTrialing}
          hasUpcomingSubDuringATrial={hasUpcomingSubDuringATrial}
          churnSurveySource={churnSurveySource}
        />
      </Heading>

      <Text>
        <ContentMessage
          isTrialing={isTrialing}
          hasUpcomingSubDuringATrial={hasUpcomingSubDuringATrial}
          churnSurveySource={churnSurveySource}
          currentPlan={currentPlan}
          periodEndText={periodEndText}
        />
      </Text>

      {isTrialing && !hasUpcomingSubDuringATrial && (
        <RemainingTrialAlert activeSub={activeSub} />
      )}

      {/* Ask the churn reason */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.reason} mt={4} mb={6}>
          <FormLabel fontSize="md" me={0} mb={4}>
            Could you take a moment to tell us why you{"\u2019"}re canceling?
          </FormLabel>
          <Select
            // placeholder="Select an option"
            {...register("reason")}
            autoFocus
            data-qa="reason"
          >
            {churnOptions.map((reason) => (
              <option key={reason} value={reason}>
                {reasonToString(reason)}
              </option>
            ))}
          </Select>
          {errors.reason && (
            <FormErrorMessage>{errors.reason.message}</FormErrorMessage>
          )}
        </FormControl>

        {followUpQuestion && (
          <FormControl isInvalid={!!errors.additionalInformation} mb={4}>
            <FormLabel fontSize="md" fontWeight="medium">
              {followUpQuestion}
            </FormLabel>
            <Textarea {...register("additionalInformation")} bg="white" />
            {errors.additionalInformation && (
              <FormErrorMessage>
                {errors.additionalInformation.message}
              </FormErrorMessage>
            )}
          </FormControl>
        )}

        <Button
          type="submit"
          w="full"
          isDisabled={!isValid}
          isLoading={isSubmitting}
          colorScheme="brand.primary"
        >
          {isTrialing && !hasUpcomingSubDuringATrial
            ? "Yes, cancel my free trial"
            : "Yes, cancel my subscription"}
        </Button>

        <Box mt={4} textAlign="center">
          <Text>Changed your mind?</Text>
          {hasUpcomingSubDuringATrial || !isTrialing ? (
            <Link onClick={onClose}>Stay on the {currentPlan.name} plan</Link>
          ) : (
            <Link onClick={onClose}>
              Stay on the {currentPlan.name} plan free trial
            </Link>
          )}
        </Box>
      </form>
    </Flex>
  )
}

const reasonToString = (reason: ChurnReason): string => {
  switch (reason) {
    case ChurnReason.Unspecified:
      return "Select an option"
    case ChurnReason.NothingLeftToTest:
      return "I only ever had one or two things to test"
    case ChurnReason.TooExpensive:
      return "My plan was too expensive"
    case ChurnReason.LackOfUse:
      return `I don${"\u2019"}t have anything to test right now, but I might later`
    case ChurnReason.MissingFeature:
      return `Lyssna was missing a feature I needed`
    case ChurnReason.BadTestResults:
      return `I didn${"\u2019"}t receive useful test results`
    case ChurnReason.SwitchedToCompetitor:
      return `I${"\u2019"}ve switched to another product`
    case ChurnReason.Other:
      return "Other"
  }
}

const reasonToFollowUpQuestion = (reason: ChurnReason): string | null => {
  switch (reason) {
    case ChurnReason.MissingFeature:
      return "Thanks! What features did you need?"
    case ChurnReason.BadTestResults:
      return "Thanks! Could you tell us a bit about what went wrong?"
    case ChurnReason.SwitchedToCompetitor:
      return "Thanks! Do you mind telling us which product you switched to?"
    case ChurnReason.Other:
      return "Could you tell us a bit more?"
    default:
      return null
  }
}

const RemainingTrialAlert: React.FC<{ activeSub: Subscription }> = ({
  activeSub,
}) => (
  <Alert status="info" alignItems="start" mt={4}>
    <AlertIcon />
    <Box>
      <AlertTitle>
        You still have{" "}
        <AutoUpdatingRemainingTime
          date={activeSub.trial_end!}
          addSuffix={false}
        />{" "}
        left.
      </AlertTitle>
      <AlertDescription>
        To get the most out of your free trial, we recommend waiting until it
        has ended before switching to the Free plan. You will be prompted at the
        end of the trial, and you will not be charged.
      </AlertDescription>
    </Box>
  </Alert>
)

const HeadingMessage: React.FC<{
  isTrialing: boolean
  hasUpcomingSubDuringATrial: boolean
  churnSurveySource: string
}> = ({ isTrialing, hasUpcomingSubDuringATrial, churnSurveySource }) => {
  if (
    (isTrialing &&
      hasUpcomingSubDuringATrial &&
      churnSurveySource === "switch-to-free") ||
    (isTrialing && !hasUpcomingSubDuringATrial)
  ) {
    return <>Are you sure you{"\u2019"}d like to switch to the Free plan now?</>
  }

  return <>Would you like to cancel your subscription?</>
}

const ContentMessage: React.FC<{
  isTrialing: boolean
  hasUpcomingSubDuringATrial: boolean
  churnSurveySource: string
  currentPlan: Plan
  periodEndText: string
}> = ({
  isTrialing,
  hasUpcomingSubDuringATrial,
  churnSurveySource,
  currentPlan,
  periodEndText,
}) => {
  // Switch to free plan from free trial with an upcoming subscription
  if (
    isTrialing &&
    hasUpcomingSubDuringATrial &&
    churnSurveySource === "switch-to-free"
  ) {
    return (
      <>
        By switching to the Free plan you will be{" "}
        <strong>cancelling your {currentPlan.name} plan subscription.</strong>{" "}
        You will be switched to the Free plan immediately. You won{"\u2019"}t be
        billed.
      </>
    )
  }

  // Cancel an upcoming subscription during a trial
  else if (
    isTrialing &&
    hasUpcomingSubDuringATrial &&
    churnSurveySource === "cancel-subscription"
  ) {
    return (
      <>
        If you cancel your <strong>{currentPlan.name} plan</strong> subscription
        you{"\u2019"}ll be switched to the Free plan immediately, and you won
        {"\u2019"}t be billed.
      </>
    )
  }

  // Switch to free plan from free trial without an upcoming subscription
  else if (isTrialing && !hasUpcomingSubDuringATrial) {
    return (
      <>
        By switching to the Free plan you will be{" "}
        <strong>cancelling your {currentPlan.name} plan free trial.</strong> You
        will be switched to the Free plan immediately.
      </>
    )
  }

  // Cancel an active subscription
  return (
    <>
      If you cancel your subscription you{"\u2019"}ll be switched to the{" "}
      <strong>Free plan</strong> at the end of your current billing period on{" "}
      <strong>{periodEndText}</strong>. You won{"\u2019"}t be billed again
      unless you choose to resubscribe.
    </>
  )
}
