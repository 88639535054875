import { SharedUsabilityTestResultDataProvider } from "UsabilityHub/components/ResultsDataProvider/SharedUsabilityTestResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React from "react"
import { SharedTestResultsContextProvider } from "./context/shared-test-results"
import { TestResults } from "./test-results-container"

type Props = {
  usabilityTestUniqueId: string
  privateId: string
}

// Since we render the TestResults component from a mount_react in `share_results.haml`,
// it won't have all the providers it needs.
export const StandaloneSharedTestResultsWrapper: React.FC<Props> = ({
  usabilityTestUniqueId,
  privateId,
}) => {
  return (
    <ModalContextProvider>
      <SharedUsabilityTestResultDataProvider
        usabilityTestUniqueId={usabilityTestUniqueId}
        privateId={privateId}
      >
        <SharedTestResultsContextProvider isShared>
          <TestResults />
        </SharedTestResultsContextProvider>
      </SharedUsabilityTestResultDataProvider>
    </ModalContextProvider>
  )
}
