import { Branded } from "Shared/types/Branded"
import { InferType, object, string } from "yup"

type EmailAddress = Branded<string, "EmailAddress">
type Password = Branded<string, "Password">

const NAME_FORMAT = /^[^\/.:<>]*(\.\s[^\/.:]*)*(\.)?$/
const PASSWORD_COMPLEXITY = /(?=.*[a-z])(?=.*[_\W\dA-Z])/

export type SignUpData = InferType<(typeof STEP_DEFINITION)["schema"]>

export const STEP_DEFINITION = {
  title: "Create an account",
  schema: object({
    name: string()
      .required("Please enter your name")
      .matches(
        NAME_FORMAT,
        "Please remove special characters such as slashes, colons, and periods"
      ),
    email: string<EmailAddress>()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
    password: string<Password>()
      .min(8, "Please try a longer password")
      .required()
      .matches(
        PASSWORD_COMPLEXITY,
        "Please include at least one lowercase letter, and at least one uppercase letter or digit or special character"
      ),
    recaptchaResponse: string().required(),
  }),
  defaultValues: {
    name: "",
    email: "" as EmailAddress,
    password: "" as Password,
  },
}
