import { createIcon } from "@chakra-ui/react"
import React from "react"

export const QuestionRadioIcon = createIcon({
  displayName: "QuestionRadioIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8C15 11.866 11.866 15 8 15C4.134 15 1 11.866 1 8C1 4.13401 4.134 1 8 1C11.866 1 15 4.13401 15 8ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
      fill="currentColor"
    />
  ),
})
