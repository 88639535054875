import { Flex, HStack, Icon, Text, useBreakpointValue } from "@chakra-ui/react"
import { CreateVariationSetModal } from "Components/create-variation-set-modal/create-variation-set-modal"
import { DescribeVariationSetModal } from "Components/describe-variation-set-modal/DescribeVariationSetModal"
import { ProTrialIcon } from "Components/trial-widget/ProTrialIcon"
import { Badge, Button, IconButton } from "DesignSystem/components"
import { ArrowLeftIcon } from "Icons/ArrowLeftIcon"
import { VariationSetIcon } from "Icons/VariationSetIcon"
import { HeaderTitleAndBreadcrumb } from "UsabilityHub/components/HeaderTitleAndBreadcrumb"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import { ROUTES } from "UsabilityHub/views/routes"
import { useShowProTrialWidget } from "Utilities/account"
import { useModal } from "Utilities/modals/use-modal"
import { useHasTestSetsFeature } from "Utilities/plan"
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import {
  UsabilityTestPageResponse,
  useUsabilityTestPage,
} from "~/api/generated/usabilityhub-components"
import { AppContext } from "~/shared/contexts/AppContext"
import { HeaderBreadcrumbs, HeaderBreadcrumbsData } from "../HeaderBreadcrumbs"
import { UsabilityTestContextMenu } from "../UsabilityTestContextMenu/UsabilityTestContextMenu"

interface UsabilityTestHeaderProps {
  id: number
  uniqueId: string
  redirect: boolean
  isDeletionAllowed: boolean
}

export const UsabilityTestHeader: React.FC<
  React.PropsWithChildren<UsabilityTestHeaderProps>
> = ({ id, uniqueId }) => {
  const showProTrialWidget = useShowProTrialWidget()
  const isAllowedTestSets = useHasTestSetsFeature()

  const { open: openVariationSetModal } = useModal(CreateVariationSetModal)
  const { open: openDescribeVariationSetModal } = useModal(
    DescribeVariationSetModal
  )
  const { isSPA } = useContext(AppContext)

  const clickVariationSetButton = () => {
    isAllowedTestSets
      ? openVariationSetModal({
          usabilityTestIds: [id],
        })
      : openDescribeVariationSetModal({
          source: "usability_test_header",
        })
  }

  const { data } = useUsabilityTestPage({
    pathParams: { usabilityTestId: uniqueId },
  })
  const usabilityTest = data?.usability_test

  const canManage = useCurrentUser().can_manage_tests
  const canViewControls = canManage
  const inVariationSet = !!usabilityTest?.variation_set
  const isExternalStudy = data?.usability_test.is_external_study

  const showBreadcrumbs = useBreakpointValue<boolean>(
    {
      base: false,
      lg: true,
    },
    { ssr: false }
  )

  const breadcrumbsData = React.useMemo(() => {
    const data: HeaderBreadcrumbsData = [
      { name: "Dashboard", to: ROUTES.DASHBOARD.path },
    ]
    if (usabilityTest?.project?.name) {
      data.push({
        name: usabilityTest.project.name,
        to:
          isSPA && usabilityTest.project.id
            ? generateFriendlyProjectPath({
                id: usabilityTest.project.id,
                name: usabilityTest.project.name,
              })
            : undefined,
      })
    }
    if (usabilityTest?.name) {
      data.push({
        name: usabilityTest.name,
        isCurrentPage: true,
      })
    }
    return data
  }, [usabilityTest?.project?.name, usabilityTest?.name])

  return (
    <HeaderTitleAndBreadcrumb>
      <Flex gap={2}>
        {showBreadcrumbs ? (
          <HeaderBreadcrumbs breadcrumbsData={breadcrumbsData} />
        ) : (
          <Flex gap={2} alignItems="center">
            <IconButton
              icon={<ArrowLeftIcon />}
              aria-label="Back"
              variant="secondary"
              as={Link}
              to={ROUTES.DASHBOARD.path}
              flexShrink={0}
            />
            <Text textStyle="ds.interface.medium">{usabilityTest?.name}</Text>
          </Flex>
        )}
        {usabilityTest?.upgraded && (
          <Badge variant="subtle" colorScheme="default" label="Upgraded" />
        )}
      </Flex>
      {canViewControls ? (
        <Controls
          inVariationSet={inVariationSet}
          isExternalStudy={isExternalStudy}
          clickVariationSetButton={clickVariationSetButton}
          showProTrialWidget={showProTrialWidget}
          isAllowedTestSets={isAllowedTestSets}
          usabilityTest={usabilityTest}
        />
      ) : null}
    </HeaderTitleAndBreadcrumb>
  )
}

const Controls: React.FC<{
  inVariationSet: boolean
  isExternalStudy: boolean | undefined
  clickVariationSetButton: () => void
  showProTrialWidget: boolean
  isAllowedTestSets: boolean
  usabilityTest: UsabilityTestPageResponse["usability_test"] | undefined
}> = ({
  inVariationSet,
  isExternalStudy,
  clickVariationSetButton,
  showProTrialWidget,
  isAllowedTestSets,
  usabilityTest,
}) => {
  return (
    <HStack spacing={2}>
      <Flex display={{ base: "none", sm: "flex" }}>
        {inVariationSet || isExternalStudy ? null : (
          <Button
            onClick={clickVariationSetButton}
            leftIcon={<Icon as={VariationSetIcon} color="ds.icon.default" />}
            rightIcon={
              <ProTrialIcon
                isAttractive={showProTrialWidget || !isAllowedTestSets}
              />
            }
          >
            Make variation set
          </Button>
        )}
      </Flex>
      {usabilityTest ? (
        <UsabilityTestContextMenu
          test={usabilityTest}
          redirectAfterDeletion
          variant="default"
        />
      ) : null}
    </HStack>
  )
}
