import { Box, Button, Flex, Heading, Text, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { useState } from "react"

import { EmptyTestSet } from "Components/routes/test-set/empty-test-set"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { UsabilityTestSelector } from "UsabilityHub/components/UsabilityTestSelector/UsabilityTestSelector"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useAddTestToTestSet } from "~/api/generated/usabilityhub-components"
import { VariationSet } from "~/api/generated/usabilityhubSchemas"
import { VariationSetPageUsabilityTestCard } from "./VariationSetPageUsabilityTestCard"

const VariationSetTestList: React.FC<{ testSet: VariationSet }> = ({
  testSet,
}) => {
  return (
    <Box className="TestList">
      {testSet.usability_tests.map((test) => {
        return (
          <VariationSetPageUsabilityTestCard
            testSetUniqueId={testSet.unique_id}
            test={test}
            key={test.id}
          />
        )
      })}
    </Box>
  )
}

type TestSetRoute = {
  testSet: VariationSet
}

export const TestSetRoute: React.FC<TestSetRoute> = ({ testSet }) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const showManagementActions = useCurrentUser().can_manage_tests
  const [selectedTestId, setSelectedTestId] = useState<number | null>(null)

  const handleTestChange = (testId: number | null) => {
    setSelectedTestId(testId)
  }

  const { mutate: addTestToTestSet, isLoading: loading } = useAddTestToTestSet({
    onSuccess: () => {
      toast({
        title: "Test added",
        status: "success",
      })
      return queryClient.invalidateQueries([
        "api",
        "test_sets",
        testSet.unique_id,
      ])
    },
    onError: () => {
      toast({
        title: "Adding test failed",
        status: "error",
      })
    },
  })

  const handleButtonClick = async () => {
    if (selectedTestId !== null) {
      setSelectedTestId(null)

      addTestToTestSet({
        pathParams: { uniqueId: testSet.unique_id },
        body: {
          usability_test_id: selectedTestId,
        },
      })
    }
  }

  const usabilityTestCount = testSet.usability_tests.length
  const disabled = loading || selectedTestId === null

  return (
    <>
      <Heading
        as="h3"
        size="sm"
        fontWeight="normal"
        color="gray.500"
        mb={10}
        textAlign="center"
      >
        We{"\u2019"}ll ensure that all participants&mdash;both your audience and{" "}
        Lyssna panelists&mdash;are unique across these tests.
        <HelpPopover>
          Your participants will be blocked from taking more than one of these
          tests, even if you share a link with them. Lyssna panelists who have
          already taken one of these tests will be excluded from orders you
          place. Please note that this can affect delivery times.
        </HelpPopover>
      </Heading>

      {usabilityTestCount === 0 ? (
        <EmptyTestSet testSetId={testSet.id} />
      ) : (
        <VariationSetTestList testSet={testSet} />
      )}

      {showManagementActions && (
        <Flex
          alignItems="center"
          borderRadius={4}
          backgroundColor="gray.50"
          padding={5}
          w="100%"
        >
          <Text color="gray.500" px={5} flex="1 1">
            Add as many tests as you like. Select a test and click “Add” &rarr;
          </Text>
          <Box w="355px">
            <UsabilityTestSelector
              value={selectedTestId ?? null}
              onChange={handleTestChange}
              excludedTestIds={testSet.usability_tests.map((ut) => ut.id)}
            />
          </Box>
          <Button
            colorScheme="brand.secondary"
            isDisabled={disabled}
            onClick={handleButtonClick}
            mx={5}
          >
            Add
          </Button>
        </Flex>
      )}
    </>
  )
}
