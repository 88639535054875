import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  Select,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import React, { ChangeEvent, KeyboardEvent, useState } from "react"

import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { isEnter } from "Utilities/keyboard-event"
import { isPresent } from "Utilities/values"

import { InfoIcon } from "Icons/InfoIcon"
import { useSectionMediaContext } from "../SectionMediaContext"

interface MediaFileNameFormProps {
  onCancel: () => void
  onSave: (name: string) => void
}

export const MediaFileNameForm: React.FC<
  React.PropsWithChildren<MediaFileNameFormProps>
> = ({ onCancel, onSave }) => {
  const sectionIndex = useSectionIndexContext()
  const { sectionMediaIndex, mediaFile } = useSectionMediaContext()
  const [mediaFileName, setMediaFileName] = useState(mediaFile?.name)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMediaFileName(event.target.value)
  }

  const handleSave = () => {
    onSave(mediaFileName)
  }

  const submitOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isEnter(event) && isPresent(mediaFileName)) {
      handleSave()
    }
  }

  return (
    <Box onKeyDown={submitOnEnter}>
      <Input
        name={`sections[${sectionIndex}].section_screenshots[${sectionMediaIndex}].screenshot.name`}
        value={mediaFileName}
        onChange={handleNameChange}
        required
        autoFocus
      />
      <HStack mt={3}>
        <Button size="sm" variant="outline" mr={1} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="sm"
          colorScheme="brand.primary"
          onClick={handleSave}
          isDisabled={mediaFile?._isSaving || !isPresent(mediaFileName)}
        >
          Save
        </Button>
      </HStack>
    </Box>
  )
}

interface DisplayScaleFieldProps {
  onSave: (displayScale: number) => void
}
export const DisplayScaleField: React.FC<
  React.PropsWithChildren<DisplayScaleFieldProps>
> = ({ onSave }) => {
  const { mediaFile } = useSectionMediaContext()
  const { readOnly } = useUsabilityTestUserActivityContext()
  // We only render this component when the display scale isn't null, so casting is safe.
  const [displayScale, setDisplayScale] = useState(mediaFile?.display_scale!)

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newDisplayScale = Number(event.target.value)
    setDisplayScale(newDisplayScale)
    onSave(newDisplayScale)
  }

  return (
    <Flex mt={4} align="baseline">
      <Tooltip
        placement="top"
        hasArrow
        label="Select Hi-DPI for images exported at 2x sizes"
      >
        <Flex>
          <Icon as={InfoIcon} position="relative" top="3px" />
          <Text ml={1} fontSize="sm" as="label" htmlFor="pixelDensity">
            Pixel density
          </Text>
        </Flex>
      </Tooltip>
      <Select
        ml={2}
        w={36}
        id="pixelDensity"
        name={`pixelDensity-${String(mediaFile.id)}`}
        size="sm"
        value={displayScale}
        isDisabled={readOnly}
        onChange={onChange}
      >
        <option value={0.5}>Hi-DPI</option>
        <option value={1.0}>Std-DPI</option>
      </Select>
    </Flex>
  )
}
