import { useToast } from "@chakra-ui/react"
import { getAllResponses } from "Redux/reducers/test-results/selectors"
import { isPresent } from "Utilities/values"
import React from "react"
import { useSelector } from "react-redux"
import { useAdminSubmitReview } from "~/api/generated/usabilityhub-components"
import { AdminResponseReview } from "../AdminResponseReview/AdminResponseReview"
import { ReviewingBanner } from "./ReviewingBanner"

export const AdminPracticeTestReview: React.FC = () => {
  const toast = useToast()

  const responses = useSelector(getAllResponses)

  const { mutateAsync: submitReview } = useAdminSubmitReview({
    onSuccess: () => {
      toast({
        title: "Review saved",
        description: "The review has been saved",
        status: "success",
      })
    },
  })

  const handleSave = async () => {
    const data = responses.map((response) => ({
      id: response.id,
      review_status: isPresent(response.review_status)
        ? response.review_status
        : response.automated_review_status,
    }))

    await submitReview({ body: { data } })

    window.location.href = "/admin/practice_test_responses/batched"
  }

  return (
    <AdminResponseReview revising={false} onSave={handleSave}>
      <ReviewingBanner />
    </AdminResponseReview>
  )
}
