import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { IconButton } from "DesignSystem/components"
import { DisableRecruitmentFilledIcon } from "Icons/DisableRecruitmentFilledIcon"
import { EditIcon } from "Icons/EditIcon"
import { LinkIcon } from "Icons/LinkIcon"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { PlayCircleFilledIcon } from "Icons/PlayCircleFilledIcon"
import { SettingsSlidersIcon } from "Icons/SettingsSlidersIcon"
import { ShowIcon } from "Icons/ShowIcon"
import { StatusDotIcon } from "Icons/StatusDotIcon"
import { RecruitmentLinkCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { Beacon } from "UsabilityHub/components/Beacon"
import Created from "UsabilityHub/components/Created"
import { InterviewMetrics } from "UsabilityHub/components/InterviewMetrics"
import { RecruitmentSummaryCard } from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCard"
import {
  RecruitmentSummaryCardManageButton,
  RecruitmentSummaryCardManageButtonProps,
} from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCardManageButton"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { ROUTES } from "UsabilityHub/views/routes"
import { capitalizeFirstLetter } from "Utilities/string"
import copy from "copy-to-clipboard"
import React from "react"
import { useNavigate } from "react-router"
import { useUpdateModeratedStudiesRecruitmentLink } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudyRecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import { InPlaceEditor } from "~/usabilityhub/components/InPlaceEditor/InPlaceEditor"

export function getRecruitmentLinkLink(token: string) {
  const url = new URL(window.location.href)
  url.pathname = `/apply/${token}`

  return url.toString()
}

interface ModeratedStudyRecruitmentLinkSummaryCardProps {
  recruitmentLink: ModeratedStudyRecruitmentLink
  moderatedStudyId: string
  showMenu?: boolean
}

export const ModeratedStudyRecruitmentLinkSummaryCard: React.FC<
  ModeratedStudyRecruitmentLinkSummaryCardProps
> = ({ recruitmentLink, moderatedStudyId, showMenu = true }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const [nameEditTrigger, setNameEditTrigger] = React.useState(0)

  const { invalidateStudySummaryQuery } = useModeratedStudyContext()

  const { mutateAsync: updateLink } = useUpdateModeratedStudiesRecruitmentLink({
    onSuccess: () => {
      const linkQueryKey = queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/recruitment_links/{moderated_study_recruitment_link_id}",
        operationId: "getModeratedStudyRecruitmentLink",
        variables: {
          pathParams: {
            moderatedStudyId,
            moderatedStudyRecruitmentLinkId: recruitmentLink.id,
          },
        },
      })

      const listQueryKey = queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/recruitment_links",
        operationId: "getApiModeratedStudiesModeratedStudyIdRecruitmentLinks",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })

      return Promise.all([
        queryClient.invalidateQueries(linkQueryKey, { exact: true }),
        queryClient.invalidateQueries(listQueryKey, { exact: true }),
      ])
    },
    onError: () => {
      toast({
        title: "Recruitment link could not be updated",
        status: "error",
      })
    },
  })

  const handleCopyRecruitmentLink = () => {
    const link = getRecruitmentLinkLink(recruitmentLink.token)
    copy(link)

    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 3000,
    })
  }

  const setLinkActive = (active: boolean) => {
    return updateLink(
      {
        pathParams: {
          moderatedStudyId,
          moderatedStudyRecruitmentLinkId: recruitmentLink.id,
        },
        body: {
          active,
        },
      },
      {
        onSuccess: (_, variables) => {
          void invalidateStudySummaryQuery()
          toast({
            title: variables.body?.active
              ? "Recruitment link enabled"
              : "Recruitment link disabled",
            status: "success",
            duration: 3000,
          })
        },
      }
    )
  }

  const handleRename = async (newName: string) => {
    await updateLink({
      pathParams: {
        moderatedStudyId,
        moderatedStudyRecruitmentLinkId: recruitmentLink.id,
      },
      body: {
        name: newName,
      },
    })
  }

  const currentLinkStatus = recruitmentLink.disabled_at ? "disabled" : "enabled"

  const formattedRecruitmentMode = capitalizeFirstLetter(
    recruitmentLink.recruitment_mode
  )
  const incentiveText =
    recruitmentLink.incentive_type !== "none"
      ? recruitmentLink.incentive_text
      : ""
  const description = [formattedRecruitmentMode, incentiveText]
    .filter(Boolean)
    .join(" • ")

  const manageButtonActions: Record<
    "enabled" | "disabled",
    (() => void) | undefined
  > = {
    enabled: () => setLinkActive(false),
    disabled: () => setLinkActive(true),
  }

  const menuActions = {
    preview: () => {
      window.open(
        ROUTES.INTERVIEW.PREVIEW.APPLY.buildPath(
          {
            moderatedStudyId,
          },
          {
            recruitmentLinkId: recruitmentLink.id,
          }
        ),
        "_blank"
      )
    },
    copy: handleCopyRecruitmentLink,
    rename: () => setNameEditTrigger((v) => v + 1),
    manage: () =>
      navigate(
        ROUTES.INTERVIEW.RECRUIT.LINK.buildPath({
          moderatedStudyId,
          moderatedStudyRecruitmentLinkId: recruitmentLink.id,
        })
      ),
  }

  return (
    <RecruitmentSummaryCard
      icon={<RecruitmentLinkCompositeIcon />}
      title={
        <InPlaceEditor
          as="h3"
          inputProps={{ size: "xs" }}
          value={recruitmentLink.name}
          handleSave={handleRename}
          defaultIsEditing={nameEditTrigger > 0}
          key={nameEditTrigger}
          textStyle="ds.heading.primary"
        />
      }
      description={description}
      manage={
        <Flex gap={1}>
          <RecruitmentSummaryCardManageButton
            {...getManageButtonProps(
              currentLinkStatus,
              manageButtonActions[currentLinkStatus]
            )}
          />
          {currentLinkStatus === "enabled" && (
            <IconButton
              aria-label="link"
              icon={<LinkIcon />}
              onClick={menuActions.copy}
            />
          )}
        </Flex>
      }
      metrics={
        <InterviewMetrics
          applicantCounts={recruitmentLink.applicant_counts}
          bookingCounts={recruitmentLink.booking_counts}
          maxBookings={
            recruitmentLink.recruitment_mode === "automatic"
              ? (recruitmentLink.maximum_number_of_bookings ?? undefined)
              : undefined
          }
          variant="wrapped"
        />
      }
      profile={
        <Created
          createdAt={recruitmentLink.created_at}
          name={recruitmentLink.creator?.name ?? ""}
          email={recruitmentLink.creator?.email ?? ""}
          avatarUrl={recruitmentLink.creator?.avatar_url ?? ""}
        />
      }
      menu={
        showMenu === false ? undefined : (
          <Menu autoSelect={false}>
            <MenuButton
              as={IconButton}
              variant="secondary"
              icon={<MenuKebabIcon />}
            ></MenuButton>
            <Portal>
              <MenuList rounded="8px" border="none" shadow="ds.overlay">
                <MenuItem px={4} rounded="8px" onClick={menuActions.preview}>
                  <Flex gap={2}>
                    <ShowIcon boxSize={5} />
                    Preview
                  </Flex>
                </MenuItem>
                <MenuItem px={4} onClick={menuActions.copy}>
                  <Flex gap={2}>
                    <LinkIcon boxSize={5} />
                    Copy link
                  </Flex>
                </MenuItem>
                <MenuItem px={4} onClick={menuActions.rename}>
                  <Flex gap={2}>
                    <EditIcon boxSize={5} />
                    Rename
                  </Flex>
                </MenuItem>
                <MenuItem px={4} rounded="8px" onClick={menuActions.manage}>
                  <Flex gap={2}>
                    <SettingsSlidersIcon boxSize={5} />
                    Manage
                  </Flex>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        )
      }
    />
  )
}

const getManageButtonProps = (
  state: "enabled" | "disabled",
  action: (() => void) | undefined
): RecruitmentSummaryCardManageButtonProps => {
  const manageButtonStates: Record<
    string,
    RecruitmentSummaryCardManageButtonProps
  > = {
    enabled: {
      buttonLabel: "Link enabled",
      buttonIcon: <Beacon colorScheme="green" />,
      menuItems: [
        {
          text: "Disable link",
          icon: (
            <DisableRecruitmentFilledIcon
              boxSize="1.125rem"
              color="ds.icon.danger"
            />
          ),
          onClick: action,
        },
      ],
    },
    disabled: {
      buttonLabel: "Link disabled",
      buttonIcon: <StatusDotIcon color="ds.icon.disabled" />,
      menuItems: [
        {
          text: "Enable link",
          icon: (
            <PlayCircleFilledIcon boxSize="1.125rem" color="ds.icon.success" />
          ),
          onClick: action,
        },
      ],
    },
  }

  return manageButtonStates[state]
}
