import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Center,
  Flex,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Navigate, useNavigate } from "react-router"

import { ApplicationsTable } from "UsabilityHub/components/ModeratedStudy/ApplicationsTable/ApplicationsTable"
import { ROUTES } from "UsabilityHub/views/routes"

import { useQueryClient } from "@tanstack/react-query"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { UsersIcon } from "Icons/UsersIcon"
import { AddFilterButton } from "UsabilityHub/components/FilterControls/AddFilterButton"
import { useFilterContext } from "UsabilityHub/components/FilterControls/FilterContext"
import { FilterList } from "UsabilityHub/components/FilterControls/FilterList"
import { serializeAppliedFilter } from "UsabilityHub/components/FilterControls/utils"
import { useInfiniteModeratedStudyApplicationList } from "UsabilityHub/hooks/useInfiniteModeratedStudyApplicationList"
import { useModeratedStudyContext } from "./ModeratedStudyContext"
import { SessionQuotaExhaustedBanner } from "./SessionQuotaExhaustedBanner"

export function ModeratedStudyApplicationsPage() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { filters, removeAllFilters } = useFilterContext()
  const serializedFilters = filters.map(serializeAppliedFilter)

  const { moderatedStudyId, moderatedStudySummary } = useModeratedStudyContext()

  const {
    data,
    isError,
    isLoading,
    isFetchingNextPage,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteModeratedStudyApplicationList(
    moderatedStudyId,
    {}, // Later when we do server-side sorting we can add the params here
    serializedFilters,
    {}
  )

  // Keep summary counts in sync with the main applications table
  useEffect(() => {
    queryClient.invalidateQueries(
      ["api", "moderated_studies", moderatedStudyId, "summary"],
      {
        exact: true,
      }
    )
  }, [data])

  const applicationLoaderRef = useInfiniteScroll<HTMLDivElement>(fetchNextPage)

  if (error?.status === 404) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  const numApplicantsMatchingFilter = data?.pages[0].total_records ?? 0
  const totalApplicants = data?.pages[0].total_records_without_filters ?? 0

  const allLoadedPages = {
    applications: data?.pages.flatMap((page) => page.applications) ?? [],
    screener_questions: data?.pages[0].screener_questions ?? [],
  }

  return (
    <>
      <Helmet>
        <title>Applications</title>
      </Helmet>

      <SessionQuotaExhaustedBanner />

      <main>
        <Box overflowX="auto" overflowY="hidden" height="100%" my={8} mx={8}>
          <Flex w="full" gap={4} alignItems="flex-start">
            <Flex
              direction="column"
              borderRadius="md"
              bg="white"
              borderWidth="1px"
              borderColor="gray.300"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Flex w="full" align="center" justify="space-between" p={2}>
                <Flex
                  align="center"
                  gap={2}
                  position="sticky"
                  left={0}
                  zIndex="dropdown"
                >
                  <FilterList />

                  <AddFilterButton showClearButton />
                </Flex>

                {!isLoading && (
                  <Flex
                    align="center"
                    gap={2}
                    pe={4}
                    position="sticky"
                    right={0}
                  >
                    <UsersIcon />
                    <Text color="text.primary">
                      {numApplicantsMatchingFilter} of {totalApplicants}
                    </Text>
                  </Flex>
                )}
              </Flex>

              {isLoading && (
                <Flex
                  my="32"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  gap="4"
                >
                  <Spinner size="lg" thickness="4px" color="gray.500" />
                  Loading...
                </Flex>
              )}

              {!isLoading && !isError && totalApplicants === 0 && (
                <Flex flexGrow={1} justifyContent="center" align="center">
                  <Box textAlign="center" py={10}>
                    <Text color="text.primary" fontWeight="medium">
                      You have no applicants yet
                    </Text>

                    {!moderatedStudySummary ||
                    moderatedStudySummary.has_active_recruitment_link ? (
                      <Text color="text.secondary">
                        Please check back later
                      </Text>
                    ) : (
                      <Link
                        variant="noUnderline"
                        fontWeight="normal"
                        onClick={() => {
                          navigate(
                            ROUTES.INTERVIEW.RECRUIT.buildPath({
                              moderatedStudyId,
                            })
                          )
                        }}
                      >
                        Start recruitment
                      </Link>
                    )}
                  </Box>
                </Flex>
              )}

              {isError && (
                <Center minH="200px" py={4}>
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>
                      There was a problem retrieving your applications.
                    </AlertDescription>
                  </Alert>
                </Center>
              )}
              {!isLoading &&
                !isError &&
                filters.length > 0 &&
                totalApplicants > 0 &&
                numApplicantsMatchingFilter === 0 && (
                  <Flex flexGrow={1} justifyContent="center" align="center">
                    <Box textAlign="center" py={10}>
                      <Text color="text.primary" fontWeight="medium">
                        No applicants match your filters
                      </Text>
                      <Link
                        variant="noUnderline"
                        fontWeight="normal"
                        onClick={removeAllFilters}
                      >
                        Clear filters
                      </Link>
                    </Box>
                  </Flex>
                )}

              {!isLoading && numApplicantsMatchingFilter > 0 && (
                <Flex borderTopWidth={1}>
                  <ApplicationsTable data={allLoadedPages} />
                </Flex>
              )}

              {isFetchingNextPage && <Spinner my={4} mx="auto" />}
              {hasNextPage && <Box w="full" ref={applicationLoaderRef} />}
            </Flex>
          </Flex>
        </Box>
      </main>
    </>
  )
}
