import classNames from "classnames"
import React, { ChangeEvent, MouseEvent, PureComponent } from "react"

import {
  TextInput,
  Props as TextInputProps,
} from "Components/form/text-input/text-input"
import { SearchIcon } from "Icons/SearchIcon"
import { Omit } from "Types"

import { Icon } from "@chakra-ui/react"
import { CloseIcon } from "Icons/CloseIcon"
import styles from "./search-input.module.scss"

interface Props
  extends Omit<TextInputProps, "value" | "onChange" | "autoFocus"> {
  value: string
  onChange: (value: string) => void
}

export class SearchInput extends PureComponent<Props> {
  private textInputRef = React.createRef<HTMLInputElement>()

  private handleWrapperClick = (_event: MouseEvent<HTMLDivElement>) => {
    this.textInputRef.current!.select()
  }

  private handleClear = (_event: MouseEvent<HTMLButtonElement>) => {
    this.props.onChange("")
  }

  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.currentTarget.value)
  }

  render() {
    const {
      onChange, // unused
      className,
      value,
      ...rest
    } = this.props

    return (
      // biome-ignore lint/a11y/useKeyWithClickEvents: divs aren't keyboard focused so the input will already be focused to the right element, which is what this is aiming to prevent being unable to happen
      <div
        className={classNames(styles.wrapper, className)}
        onClick={this.handleWrapperClick}
      >
        <div className={styles.search}>
          <SearchIcon />
        </div>
        <TextInput
          className={styles.input}
          ref={this.textInputRef}
          value={value}
          onChange={this.handleChange}
          {...rest}
        />
        <button
          disabled={value !== undefined && value.length === 0}
          onClick={this.handleClear}
          className={styles.clear}
          type="reset"
        >
          <Icon as={CloseIcon} boxSize={4} />
        </button>
      </div>
    )
  }
}
