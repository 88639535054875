import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { Badge, Button } from "DesignSystem/components"
import { RecordStartIcon } from "Icons/RecordStartIcon"
import { RecordStopIcon } from "Icons/RecordStopIcon"
import { ReportIcon } from "Icons/ReportIcon"
import { Dispatch, State } from "Redux/app-store"
import { addResponseAnswer } from "Redux/reducers/current-response/action-creators"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ResponseAnswer, Unpersisted } from "Types"
import { useSectionRecordingContext } from "UsabilityHub/components/UsabilityTest/context/SectionRecordingContext"
import UsabilityTestSectionQuestion from "UsabilityHub/components/UsabilityTestSectionQuestion/UsabilityTestSectionQuestion"
import React, { PropsWithChildren, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PartipantTestSection } from "../../props"

type Props = {
  usabilityTestSection: PartipantTestSection
  currentTaskIndex: number
  betweenTasks: boolean
  handleNextTask: () => void
  onClose: () => void
  onReport: () => void
}

export const LiveWebsiteTestControlPanel: React.FC<Props> = ({
  usabilityTestSection,
  currentTaskIndex,
  betweenTasks,
  handleNextTask,
  onClose,
  onReport,
}) => {
  const t = useTranslate()
  const dispatch: Dispatch = useDispatch()
  const [questionIndex, setQuestionIndex] = useState<number | null>(null)

  const { areAllStreamsActive } = useSectionRecordingContext()

  const isPreview = useSelector(
    (state: State) => state.currentResponse?.id === null
  )
  const isPanelist = useSelector(
    (state: State) => !!state.currentResponse?.order_id
  )

  if (!usabilityTestSection.live_website_test) {
    throw new Error("Live website test not found")
  }

  const tasks = usabilityTestSection.live_website_test.live_website_test_tasks

  const [afterRerender, setAfterRerender] = useState(false)

  useEffect(() => {
    if (!betweenTasks) return
    // We want to wait until all the streams have been torn down and restarted before we
    // kick off the next task — unless it's a preview, where we don't do recordings.
    if (!areAllStreamsActive && !isPreview) return

    setTimeout(() => setAfterRerender(true), 0)
  }, [betweenTasks, areAllStreamsActive, isPreview])

  useEffect(() => {
    if (!afterRerender) return

    setAfterRerender(false)
    nextStep()
  }, [afterRerender])

  if (currentTaskIndex >= tasks.length) {
    return (
      <PipContainer>
        {isPanelist && <PipReportButton onReport={onReport} />}

        <PipBody>
          <PipBadge label={t("test.live_website_test.pip_badge_instruction")} />

          <Text textStyle="ds.display.primary" color="ds.text.default">
            {t("test.live_website_test.complete_heading")}
          </Text>

          <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
            {t("test.live_website_test.complete_body")}
          </Text>
        </PipBody>

        <PipFooter>
          <Button
            leftIcon={<RecordStopIcon color="#e83701" />}
            onClick={onClose}
          >
            {t("test.live_website_test.end_task_and_recording")}
          </Button>
        </PipFooter>
      </PipContainer>
    )
  }

  const task = tasks[currentTaskIndex]

  const nextStep = () => {
    if (betweenTasks) {
      handleNextTask()
    } else if (questionIndex === null) {
      if (task.live_website_test_task_questions.length === 0) {
        handleNextTask()
      } else {
        setQuestionIndex(0)
      }
    } else if (
      questionIndex <
      task.live_website_test_task_questions.length - 1
    ) {
      setQuestionIndex(questionIndex + 1)
    } else {
      setQuestionIndex(null)
      handleNextTask()
    }
  }

  // Essentially a little loading screen between tasks while the recording streams get torn-down
  // and new ones get set up for the next task.
  if (betweenTasks) {
    return (
      <Center h="full">
        <Spinner />
      </Center>
    )
  }

  if (questionIndex !== null) {
    const questionId =
      task.live_website_test_task_questions[questionIndex]
        .usability_test_section_question_id
    const question = usabilityTestSection.questions.find(
      (q) => q.id === questionId
    )

    if (!question) {
      throw new Error(`Question ${questionId} not found`)
    }

    return (
      <PipContainer>
        {isPanelist && <PipReportButton onReport={onReport} />}

        <PipBody>
          <UsabilityTestSectionQuestion
            section={usabilityTestSection}
            question={question}
            onAnswerSubmit={(args: Readonly<Unpersisted<ResponseAnswer>>) => {
              dispatch(addResponseAnswer(args))
              nextStep()
            }}
            buttonSlot={(submitButton) => {
              return <PipFooter>{submitButton}</PipFooter>
            }}
          />
        </PipBody>
      </PipContainer>
    )
  }

  const taskBadge =
    t("test.live_website_test.pip_badge_task") +
    " " +
    (currentTaskIndex + 1) +
    " / " +
    tasks.length

  return (
    <PipContainer>
      {isPanelist && <PipReportButton onReport={onReport} />}

      <PipBody>
        <PipBadge label={taskBadge} />

        <Text textStyle="ds.display.primary" color="ds.text.default">
          {t("test.live_website_test.pip_instructions_heading")}
        </Text>

        <Text
          as="div"
          textStyle="ds.paragraph.emphasized"
          color="ds.text.default"
        >
          <DisplayBlockMarkdownText text={task.instructions} />
        </Text>
      </PipBody>

      <PipFooter>
        <Button variant="primary" onClick={nextStep}>
          {t("test.live_website_test.task_complete_button")}
        </Button>
      </PipFooter>
    </PipContainer>
  )
}

const PipContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      direction="column"
      bg="ds.surface.overlay.default"
      h="full"
      overflowY="auto"
    >
      {children}
    </Flex>
  )
}

const PipReportButton: React.FC<{ onReport: () => void }> = ({ onReport }) => {
  const t = useTranslate()

  return (
    <Flex justify="flex-end" p={2}>
      <Button
        variant="subtle"
        size="compact"
        onClick={onReport}
        leftIcon={<ReportIcon />}
      >
        {t("test.live_website_test.report_button")}
      </Button>
    </Flex>
  )
}

const PipBody: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      direction="column"
      gap={4}
      px={4}
      pt={10}
      mb="64px"
      h="auto"
      zIndex={0}
    >
      {children}
    </Flex>
  )
}

const PipBadge: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Box pb={1}>
      <Badge label={label} variant="subtle" />
    </Box>
  )
}

const PipFooter: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      width="full"
      bg="white"
      shadow="0px 0px 7px 0px rgba(1, 1, 1, .15)"
      p={3}
      mt={2}
      zIndex={1} // Needs to always be in front of the body, otherwise ranking questions get messed up
    >
      <Flex justify="flex-end">{children}</Flex>
    </Box>
  )
}

type PipIntroScreenProps = {
  handleReport: () => void
  handleStart: () => void
}

export const PipIntroScreen: React.FC<PipIntroScreenProps> = ({
  handleReport,
  handleStart,
}) => {
  const t = useTranslate()
  const isPanelist = useSelector(
    (state: State) => !!state.currentResponse?.order_id
  )

  return (
    <PipContainer>
      {isPanelist && <PipReportButton onReport={handleReport} />}

      <PipBody>
        <PipBadge label={t("test.live_website_test.pip_badge_instruction")} />

        <Text textStyle="ds.display.primary" color="ds.text.default">
          {t("test.live_website_test.pip_heading")}
        </Text>
        <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
          {t("test.live_website_test.pip_instructions")}
        </Text>
        <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
          {t("test.live_website_test.pip_next_step")}
        </Text>
      </PipBody>

      <PipFooter>
        <Button
          leftIcon={<RecordStartIcon color="#e83701" />}
          onClick={handleStart}
        >
          {t("test.live_website_test.start_task_and_recording")}
        </Button>
      </PipFooter>
    </PipContainer>
  )
}
