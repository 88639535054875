import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "DesignSystem/components"
import { getEnvState } from "JavaScripts/state"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"
import { useAdminEmulateUser } from "~/api/generated/usabilityhub-components"

const REASON_OPTIONS = ["support_enquiry", "sales_enquiry", "other"] as const

const EmulateUserSchema = Yup.object({
  reason: Yup.mixed<(typeof REASON_OPTIONS)[number]>()
    .oneOf(REASON_OPTIONS, "you must choose a reason")
    .required(),
  conversation_link: Yup.string().when("reason", {
    is: "other",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) =>
      schema.required("You must provide a link to a conversation"),
  }),
  details: Yup.string().required("You must provide details"),
})

type EmulateUserForm = Yup.InferType<typeof EmulateUserSchema>

type Props = {
  userId: number
  adminId: number
  isPanelist: boolean
  emulationToken: string | undefined
}

export const EmulateUserModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  userId,
  isPanelist,
  emulationToken, // Only used for panelists
  adminId, // Only used for panelists
}) => {
  const toast = useToast()
  const env = getEnvState()

  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid, isSubmitting, errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(EmulateUserSchema),
    defaultValues: {
      reason: undefined,
      conversation_link: "",
      details: "",
    },
  })

  const { mutateAsync: emulateUser } = useAdminEmulateUser({
    onSuccess: () => {
      window.location.href = "/"
    },
  })

  const onSubmit: SubmitHandler<EmulateUserForm> = async (values) => {
    try {
      if (isPanelist) {
        // Because the login is cookie-based and CORS exists, we can't do the panelist emulation via our API.
        // Instead, construct a URL and redirect to it.
        const queryParams = new URLSearchParams({
          ...values,
          user_id: userId.toString(),
          emulation_token: emulationToken ?? "",
          // The admin won't be authed on UC so we need to manually pass their user ID ("high trust environment")
          admin_id: adminId.toString(),
        })

        const emulatePanelistUrl = `${addProtocolAndPort(env.PANELIST_DOMAIN)}/admin/panelist_emulation`
        window.location.href = `${emulatePanelistUrl}?${queryParams.toString()}`

        return false
      }

      return emulateUser({
        body: {
          ...values,
          user_id: userId,
        },
      })
    } catch (error) {
      toast({
        title: "Error",
        description: `An error occurred while emulating the user: ${error.message}`,
        status: "error",
      })
    }
  }

  const reason = watch("reason")

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>Emulate User</ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <Text>Please confirm why you need to emulate this account:</Text>

            <FormControl isInvalid={!!errors.reason}>
              <RadioGroup
                value={reason}
                display="flex"
                flexDirection="column"
                gap={1}
                ms={2}
              >
                <Radio {...register("reason")} value="support_enquiry">
                  Support Enquiry
                </Radio>
                <Radio {...register("reason")} value="sales_enquiry">
                  Sales Enquiry
                </Radio>
                <Radio {...register("reason")} value="other">
                  Other
                </Radio>
              </RadioGroup>

              <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
            </FormControl>

            {reason && reason !== "other" && (
              <FormControl isInvalid={!!errors.conversation_link}>
                <FormLabel>Link to conversation</FormLabel>
                <Input
                  isInvalid={!!errors.conversation_link}
                  {...register("conversation_link")}
                />
                <FormErrorMessage>
                  {errors.conversation_link?.message}
                </FormErrorMessage>
              </FormControl>
            )}

            {reason && (
              <FormControl isInvalid={!!errors.details}>
                <FormLabel>Details</FormLabel>
                <Textarea
                  isInvalid={!!errors.details}
                  {...register("details")}
                />
                <FormErrorMessage>{errors.details?.message}</FormErrorMessage>
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="primary"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              Emulate
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

const addProtocolAndPort = (url: string) => {
  let urlWithProtocol = window.location.protocol + "//" + url

  if (window.location.port) {
    urlWithProtocol += `:${window.location.port}`
  }

  return urlWithProtocol
}
