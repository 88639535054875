import { Step, Steps, StepsDivider } from "Shared/components/Steps/Steps"
import React from "react"
import { Link, useMatch } from "react-router-dom"
import { useUsabilityTestPage } from "~/api/generated/usabilityhub-components"

export const UsabilityTestSteps: React.FC<{
  responseCount: number
}> = ({ responseCount }) => {
  const newTestRouteMatch = useMatch("/tests/new")

  const existingTestMatch = useMatch("/tests/:testId/*")
  const editTestRouteMatch = useMatch("/tests/:testId/edit")
  const recruitTestRouteMatch = useMatch("/tests/:testId/recruit/*")
  const resultsTestRouteMatch = useMatch("/tests/:testId/results")

  const { data: pageData, status: pageStatus } = useUsabilityTestPage(
    {
      pathParams: {
        usabilityTestId: existingTestMatch?.params.testId!, // Because of enabled below, this is safe to do,
      },
    },
    {
      enabled: !!existingTestMatch,
    }
  )

  const isInvalidExternal =
    pageStatus === "success" &&
    pageData.usability_test.is_external_study &&
    !pageData.usability_test.is_valid_external_study

  if (newTestRouteMatch) {
    return (
      <Steps>
        <Step label="Build" isActive isComplete={false} />
        <StepsDivider />
        <Step label="Recruit" isDisabled />
        <StepsDivider />
        <Step label="Results" isDisabled />
      </Steps>
    )
  }

  const testId = existingTestMatch!.params.testId!

  return (
    <Steps
      justifyContent={{ base: "flex-start", lg: "center" }}
      px={{ base: 6, sm: 8, lg: 10 }}
      overflowX="auto"
    >
      <Link to={`${testId}/edit`}>
        <Step
          label="Build"
          isActive={!!editTestRouteMatch}
          isComplete={false}
        />
      </Link>
      <StepsDivider />

      {/* External studies can be saved but not yet valid enough to place an order,
          so we should disable the recuit step in that case */}
      {isInvalidExternal || pageStatus !== "success" ? (
        <Step label="Recruit" isDisabled />
      ) : (
        <Link to={`${testId}/recruit`}>
          <Step
            label="Recruit"
            isActive={!!recruitTestRouteMatch}
            isComplete={false}
          />
        </Link>
      )}
      <StepsDivider />

      <Link to={`${testId}/results`}>
        <Step
          label="Results"
          isActive={!!resultsTestRouteMatch}
          count={responseCount}
        />
      </Link>
    </Steps>
  )
}
