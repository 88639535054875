import React from "react"

import { QuestionCheckboxIcon } from "Icons/QuestionCheckboxIcon"
import { QuestionLongTextIcon } from "Icons/QuestionLongTextIcon"
import { QuestionRadioIcon } from "Icons/QuestionRadioIcon"
import { QuestionRangeIcon } from "Icons/QuestionRangeIcon"
import { QuestionRankedIcon } from "Icons/QuestionRankedIcon"
import { QuestionShortTextIcon } from "Icons/QuestionShortTextIcon"
import { QuestionType } from "Types"

export function iconForQuestionType(type: QuestionType): React.ReactNode {
  switch (type) {
    case QuestionType.CheckboxMultipleChoice:
      return <QuestionCheckboxIcon />
    case QuestionType.LinearScale:
      return <QuestionRangeIcon />
    case QuestionType.LongAnswer:
      return <QuestionLongTextIcon />
    case QuestionType.RadioMultipleChoice:
      return <QuestionRadioIcon />
    case QuestionType.Ranking:
      return <QuestionRankedIcon />
    case QuestionType.ShortAnswer:
      return <QuestionShortTextIcon />
  }
}
