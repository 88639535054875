import { PointTransactionReason } from "Types"

// These *must* be kept in sync with ApplicationHelper::point_transaction_description
export const DISPLAY_STRING_BY_VALUE: {
  [K in PointTransactionReason]: string
} = {
  unknown: "",
  test_published: "Ordered responses for a test",
  added_responses: "Ordered additional responses for a test",
  test_deleted: "Refund unused credits for deleted test",
  response_given: "You participated in a test",
  response_deleted: "A response you gave was deleted",
  signed_up: "Welcome to Lyssna",
  referred_friend: "Somebody you invited joined Lyssna",
  was_referred: "You were invited by a friend to Lyssna",
  completed_profile: "Thanks for completing your profile",
  subscription_renewal: "Subscription renewal",
  subscription_end: "Subscription points expired",
  admin: "Lyssna Admin gave you some credits",
  order_purchase: "Order was purchased",
  pro_subscription_renewal: "Lyssna PRO subscription credits",
  payout_requested: "You requested a payout",
  published_first_test: "Congratulations on making your first test!",
  payout_rejected:
    "Return credits for acceptable responses in a rejected payout",
  order_processed: "Order processed",
  order_canceled: "Refund unused credits for canceled order",
  credit_code_claimed_credit: "You claimed some credits with a code",
  credit_code_claimed_debit: "Somebody claimed one of your credit codes",
  batch_purchase: "Purchased credits",
  manual_purchase: "Purchased credits",
  vendor_purchase: "Purchased credits",
  price_change_adjustment: "Credit adjustment due to Lyssna price change",
  test_upgrade_purchase: "Purchased credits for test upgrade",
  test_upgrade_processed: "Test upgrade processed",
  moderated_study_booking_completed: "You participated in an interview",
  moderated_study_booking_canceled:
    "An interview session was canceled by the researcher within 12 hours of the start time",
  panel_usability_test_screened_out:
    "You participated in a test and were screened-out",
  stripe_purchase_return: "Return of refunded credits",
  moderated_study_order_purchase: "Credits purchased for interview session",
  moderated_study_order_canceled:
    "Refund unused credits for a canceled interview order",
  moderated_study_order_created: "Order placed for interview session",
  moderated_study_order_slot_refunded:
    "Credits refunded for an interview session",
  automatic_purchase: "Auto top-up",
}
