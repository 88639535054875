import { FigmaFileFlow, FigmaFileVersion } from "Types"
import { kebabCase } from "lodash"
import { useTestTakingContext } from "../context/TestTakingContext"

// This is the new version of app/frontend/application/javascripts/redux/reducers/figma-file-versions/selectors.ts
// This is used only in test taking currently.

export const REAL_FIGMA_DOMAIN = "https://www.figma.com"

const createFigmaFlowPrototypeUrl = (
  figmaFileVersion: FigmaFileVersion,
  start_node_id: string,
  scaling: string
): string => {
  const { figma_file_key } = figmaFileVersion
  const queryString = new URLSearchParams({
    "starting-point-node-id": start_node_id,
    "node-id": start_node_id, // For multi-page files
    scaling: kebabCase(scaling),
    "hotspot-hints": "false", // Remove hitzone highlighting on misclick
    // Hide figma controls
    "hide-ui": "true", // hide-ui is undocumented in the V2 API but without it none of the other UI controls work
    "page-selector": "false",
    footer: "false",
    "viewport-controls": "false",
    "show-proto-sidebar": "false",
  }).toString()
  // NOTE: `REAL_FIGMA_DOMAIN` instead of `getEnvState().FIGMA_DOMAIN`
  // Backend testing mocks FIGMA_DOMAIN. If we use that here the backend tests
  // need to mock (or store responses for) every API call to Figma involved in
  // loading  a prototype: font, design, media APIs, which is more trouble
  // than it's worth.
  return `${REAL_FIGMA_DOMAIN}/proto/${figma_file_key}?${queryString}`
}

// Overload declaration for getFigmaFileVersionById
function getFigmaFileVersionById(id: number, nullable?: false): FigmaFileVersion
function getFigmaFileVersionById(
  id: number,
  nullable: true
): FigmaFileVersion | null

// Implementation for getFigmaFileVersionById
function getFigmaFileVersionById(id: number, nullable = false) {
  const { figmaFileVersions } = useTestTakingContext()
  const ffv = figmaFileVersions.find((ffv) => ffv.id === id)

  if (ffv == null && !nullable) {
    throw new Error(`No figma file version with id: ${id}`)
  }

  return ffv || null
}

// Overload declaration for getFigmaFlowPrototypeUrl
export function getFigmaFlowPrototypeUrl(
  figmaFileFlow: FigmaFileFlow,
  nullable?: false
): string
export function getFigmaFlowPrototypeUrl(
  figmaFileFlow: FigmaFileFlow,
  nullable: true
): string | undefined

// Implementation for getFigmaFlowPrototypeUrl
export function getFigmaFlowPrototypeUrl(
  figmaFileFlow: FigmaFileFlow,
  nullable = false
) {
  const figmaFileVersion = getFigmaFileVersionById(
    figmaFileFlow.figma_file_version_id,
    true
  )

  if (figmaFileVersion == null && !nullable) {
    throw new Error(
      `No figma file version with id: ${figmaFileFlow.figma_file_version_id}`
    )
  }

  return createFigmaFlowPrototypeUrl(
    figmaFileVersion!,
    figmaFileFlow.start_node_id,
    figmaFileFlow.scaling
  )
}
