import { Box, Flex, HStack, Icon, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { getSectionTypeRules } from "Constants/test-section-types"
import { Tag } from "DesignSystem/components"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { DeviceDesktopFilledIcon } from "Icons/DeviceDesktopFilledIcon"
import { RecordingsFilledIcon } from "Icons/RecordingsFilledIcon"
import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { PermittedDeviceType, UsabilityTestSectionType } from "Types"
import { AnimatingBadge } from "UsabilityHub/components/AnimatingBadge/AnimatingBadge"
import { DesktopOnlyModal } from "UsabilityHub/components/TestForm/TestFormCards/AddSectionCard/DesktopOnlyModal"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import { useModal } from "Utilities/modals/use-modal"
import { useSelector } from "react-redux"

interface AddSectionCardButtonProps {
  sectionType: UsabilityTestSectionType
  onAddSection: (sectionType: UsabilityTestSectionType) => void
}

export const AddSectionCardButton: React.FC<
  React.PropsWithChildren<AddSectionCardButtonProps>
> = ({ sectionType, onAddSection }) => {
  const {
    badgeText,
    colorScheme,
    defaultTitle: title,
    description,
    icon,
    qaId,
    isRecordable,
    permittedDeviceType,
  } = getSectionTypeRules(sectionType)
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const isSectionRecordingEnabled = isRecordable && recordingFeatureEnabled
  const { open: openDesktopOnlyModal } = useModal(DesktopOnlyModal)
  const permittedDeviceTypeFormValue: PermittedDeviceType = useSelector(
    getFormValue("permitted_device_type")
  )

  const onClick = () => {
    if (
      permittedDeviceType === "desktop_only" &&
      permittedDeviceTypeFormValue !== "desktop_only"
    ) {
      return openDesktopOnlyModal({
        onSubmit: () => onAddSection(sectionType),
      })
    }
    onAddSection(sectionType)
  }

  return (
    <HStack
      data-qa={`add-${qaId}-button`}
      as="button"
      type="button" // Prevent form submit
      spacing={4}
      alignItems="flex-start"
      textAlign="left"
      padding={4}
      _hover={{ bg: "gray.100" }}
      _focus={{ bg: "gray.100" }}
      _active={{ bg: "gray.200" }}
      borderRadius="16px"
      border="1px solid"
      borderColor="border.default"
      onClick={onClick}
    >
      <Box backgroundColor={colorScheme} rounded="full" boxSize={8} p={1.5}>
        <Icon
          as={icon}
          boxSize={5}
          flexShrink={0}
          color="white"
          display="block"
        />
      </Box>
      <Box flex="1">
        <HStack>
          <Text as="span" textStyle="ds.heading.primary">
            {title}
          </Text>
          {badgeText && (
            <AnimatingBadge colorScheme="pink">{badgeText}</AnimatingBadge>
          )}
        </HStack>
        <Text as="span" textStyle="ds.paragraph.primary" color="ds.text.subtle">
          {description}
        </Text>
        <Flex mt={2}>
          {isSectionRecordingEnabled && (
            <Tooltip
              label="This section supports screen and think-aloud recording"
              aria-label={`${title} sections support recording`}
              placement="top"
              borderRadius={4}
              shouldWrapChildren
            >
              <Tag label="Recordings" leftIcon={RecordingsFilledIcon} />
            </Tooltip>
          )}
          {permittedDeviceType === "desktop_only" && (
            <Tooltip
              label="Adding this section will set your entire test to desktop-only participation"
              aria-label="Adding this section will set your entire test to desktop-only participation"
              placement="top"
              borderRadius={4}
              shouldWrapChildren
            >
              <Tag label="Desktop only" leftIcon={DeviceDesktopFilledIcon} />
            </Tooltip>
          )}
        </Flex>
      </Box>
    </HStack>
  )
}
