import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Grid,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { DownloadCloudIcon } from "Icons/DownloadCloudIcon"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { useUploadRecording } from "UsabilityHub/hooks/useUploadRecording"
import React, { useEffect, useRef, useState } from "react"
import { unstable_usePrompt } from "react-router-dom"
import { Booking } from "../BookingCard/types"
import UploadingTile from "./uploading-tile"

const ACCEPT = ["audio/*", "video/*"] as const

const ACCEPT_REGEX = new RegExp(
  ACCEPT.map((a) => `^${a.replace("*", ".*")}$`).join("|")
)

type FileUploadProps = {
  moderatedStudyId: string
  booking: Booking
  onComplete: () => void
  onError: (error: { title: string; message: string } | null) => void
  autoUploadFailed?: boolean
}

export const FileUpload: React.FC<FileUploadProps> = ({
  moderatedStudyId,
  booking,
  onComplete,
  onError,
  autoUploadFailed = false,
}) => {
  const container = useRef<HTMLDivElement>(null)

  const fileInput = useRef<HTMLInputElement>(null)

  const [draggingOver, setDraggingOver] = useState(false)

  const [progress, setProgress] = useState(0)
  const { upload, abortUpload, uploading, complete, metadata, error } =
    useUploadRecording(moderatedStudyId, booking.id, (fraction: number) => {
      setProgress(fraction * 100)
    })

  const uploadFile = (file: File) => {
    if (!file.type.match(ACCEPT_REGEX)) return

    upload(file)
  }

  const dropped = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDraggingOver(false)

    if (e.dataTransfer.items) {
      const items = [...e.dataTransfer.items]
      if (items.length !== 1 || items[0].kind !== "file") return
      const file = items[0].getAsFile()
      if (file) {
        uploadFile(file)
      }
    } else {
      const files = [...e.dataTransfer.files]
      if (files.length !== 1) return
      uploadFile(files[0])
    }
  }

  useEffect(() => {
    if (complete) onComplete()
  }, [complete])

  unstable_usePrompt({
    when: uploading,
    message: "Are you sure you want to leave and abort the upload?",
  })

  useEffect(() => {
    if (uploading) {
      return () => {
        abortUpload()
      }
    }
  }, [uploading])

  useEffect(() => {
    if (error)
      // we currently use a generic message instead of what is inside the error object but we'll track
      // with support if we need to start including what the error is about
      onError({
        title: "We encountered an error uploading this recording",
        message: "Please try uploading the recording again",
      })
    else onError(null)
  }, [error])

  const maxRecordingFilesizeGb =
    Constants.MAX_RECORDING_FILESIZE_IN_BYTES / 1024 / 1024 / 1024

  return uploading ? (
    <UploadingTile
      metadata={metadata}
      onAbort={abortUpload}
      progress={progress}
      uploading={uploading}
    />
  ) : (
    <>
      {booking.auto_upload_recordings && !autoUploadFailed && (
        <Alert bg="#F5FAFE" color="text.default">
          <AlertIcon
            as={DownloadCloudIcon}
            color="text.default"
            stroke="text.default"
          />
          <AlertTitle>Auto-fetch from Zoom Cloud queued</AlertTitle>
          <AlertDescription>
            The recording for this session will be automatically fetched from
            Zoom Cloud if available. Alternatively upload a recording manually.{" "}
            <ExternalLink
              href="https://help.lyssna.com/en/collections/6001827-run-moderated-surveys-with-interviews"
              sx={{ "&.chakra-link": { color: "text.default" } }}
            >
              {" "}
              Learn more.
            </ExternalLink>
          </AlertDescription>
        </Alert>
      )}

      <Grid
        ref={container}
        p={4}
        border="2px dashed gray"
        borderColor="gray.200"
        borderRadius="md"
        bg="white"
        templateColumns="1fr auto"
        gap={3}
        data-dragging-over={draggingOver || undefined}
        onDragEnterCapture={() => {
          setDraggingOver(true)
        }}
        onDragOver={(e) => {
          e.preventDefault()
        }}
        onDragLeave={(e) => {
          if (!container.current?.contains(e.relatedTarget as Node)) {
            setDraggingOver(false)
          }
        }}
        onDropCapture={dropped}
        sx={{
          "&[data-dragging-over]": {
            bg: "gray.50",
          },
        }}
      >
        <Stack gap={1}>
          <Heading as="h3" size="sm" fontWeight="medium">
            Drag and drop to upload a recording
          </Heading>
          <Text mb={0}>MP4, MOV, MPEG, and MP3 are supported.</Text>
          <Text mb={0} color="text.secondary">
            {`Max file size is ${maxRecordingFilesizeGb}GB`}
          </Text>
        </Stack>
        <Button
          as="label"
          colorScheme="brand.primary"
          variant="solid"
          cursor="pointer"
          size="sm"
        >
          Browse files
          <input
            type="file"
            accept={ACCEPT.join(",")}
            ref={fileInput}
            style={{ display: "none" }}
            onChange={(e) => {
              const input = e.currentTarget
              if (input.files && input.files.length === 1) {
                uploadFile(input.files[0])
              }
            }}
          />
        </Button>
      </Grid>
    </>
  )
}
