import { ScreenerQuestion } from "Redux/reducers/test-results/reducer"
import { QuestionType } from "Types"
import { CheckboxQuestion } from "Types"
import { RadioQuestion } from "Types"

export const convertScreenerQuestionToQuestion = (
  screenerQuestion: ScreenerQuestion
): CheckboxQuestion | RadioQuestion => {
  const qualifyingOptions = screenerQuestion.screener_question_options
    .filter((option) => option.rule !== "disqualify")
    .map((option) => option.value)

  return {
    id: 0,
    text: screenerQuestion.text,
    type:
      screenerQuestion.type === "single_select"
        ? QuestionType.RadioMultipleChoice
        : QuestionType.CheckboxMultipleChoice,
    required: false,
    _clientId: "0",
    archived: false,
    has_other_option: false,
    multiple_choice_options: qualifyingOptions,
    position: 0,
    question_tags: [],
    randomized: false,
    test_logic_statement: null,
    usability_test_section_id: 0,
    min_value: 0,
    max_value: 0,
    min_label: "",
    max_label: "",
    min_selected_options: 0,
    max_selected_options: 0,
  }
}
