import { Button, Flex, Text, ToastId, useToast } from "@chakra-ui/react"
import React from "react"
import { useDispatch } from "react-redux"

import { BackIcon } from "Icons/BackIcon"
import { Dispatch } from "Redux/app-store"
import { undoLastOperation } from "Redux/reducers/test-builder/action-creators/undo"

export const useUndoToast = () => {
  const toast = useToast()
  const dispatch = useDispatch<Dispatch>()
  const toastId = React.useRef<ToastId>()

  const undoToast = React.useCallback(
    (entityName: string) => {
      // Any existing undo toasts will be invalidated, so hide 'em
      toast.closeAll()

      toastId.current = toast({
        containerStyle: {
          minW: "auto",
        },
        render: () => (
          <Flex
            align="center"
            background="gray.700"
            color="white"
            rounded="md"
            boxShadow="lg"
            gap={3}
            p={3}
            ps={4}
          >
            <Text fontWeight="semibold" wordBreak="break-word">
              {entityName} deleted
            </Text>
            <Button
              flexShrink={0}
              variant="outline"
              backgroundColor="gray.700"
              color="text.inverted"
              leftIcon={<BackIcon color="white" />}
              _hover={{
                backgroundColor: "whiteAlpha.200",
              }}
              onClick={() => {
                dispatch(undoLastOperation())
                if (toastId.current) toast.close(toastId.current)
              }}
            >
              Undo
            </Button>
          </Flex>
        ),
        duration: 10000,
        isClosable: false,
        position: "bottom",
      })
    },
    [toast, dispatch]
  )

  return undoToast
}
