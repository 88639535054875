import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Progress,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import { useFilteredResponseSections } from "Components/test-results/hooks/use-filtered-response-sections"
import { useReachedGoalScreenFilter } from "Components/test-results/hooks/use-reached-goal-filter"
import { hasGoalScreenBeenReached } from "Components/test-results/utils/response-section-figma-task/has-goal-screen-been-reached"
import { getCommonPathsCount } from "Components/test-results/utils/task-paths/get-common-paths/get-common-paths-count"
import { IconButton } from "DesignSystem/components"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { ClickFilledIcon } from "Icons/ClickFilledIcon"
import { FilterFilledIcon } from "Icons/FilterFilledIcon"
import { FilterIcon } from "Icons/FilterIcon"
import { RecordingsFilledIcon } from "Icons/RecordingsFilledIcon"
import { TimeIcon } from "Icons/TimeIcon"
import { UserIcon } from "Icons/UserIcon"
import { UserProfileFilledIcon } from "Icons/UserProfileFilledIcon"
import { UsersIcon } from "Icons/UsersIcon"
import {
  getAllResponseSectionFigmaTasks,
  getFigmaFileVersionById,
} from "Redux/reducers/test-results/selectors"
import { useSectionContext } from "UsabilityHub/contexts"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import { figmaImagePath } from "Utilities/figma-file-version"
import { formatPercentage01 } from "Utilities/number"
import { formatPreciseDuration } from "Utilities/time"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { SectionRecordingsTab } from "../Recordings/SectionRecordingsTab"
import { useSectionRecordings } from "../Recordings/useSectionRecordings"
import { CommonPathsList } from "./PathsSummaries/CommonPathsList/CommonPathsList"
import { IndividualPathsList } from "./PathsSummaries/IndividualPathsList/IndividualPathsList"
import { useTasks } from "./hooks/use-tasks"

// Disable hook lint rules to use flag to determine which filtering is used
// Flag is stable between renders so it does not cause any issues with React
const useFilteredTasks = (sectionId: number) => {
  // Filter response sections by the sectionId
  const filteredResponseSections = useFilteredResponseSections().filter(
    (responseSection) => responseSection.usability_test_section_id === sectionId
  )

  const responseSectionFigmaTasks = useSelector(getAllResponseSectionFigmaTasks)

  // Filter all tasks by checking if the corresponding responseSection exists in the filtered responseSection array
  // the filtered responseSection array is derived from the filtered response array
  return responseSectionFigmaTasks.filter((task) =>
    filteredResponseSections.some(
      (responseSection) => task.response_section_id === responseSection.id
    )
  )
}

export const TaskFlowPrototypeTaskResults: React.FC = () => {
  const { section } = useSectionContext()
  const allTasks = useTasks()

  const figmaFileFlow = section.figma_file_flow
  if (!figmaFileFlow) {
    throw new TypeError(`Section with ID ${section.id} has no FigmaFileFlow`)
  }
  if (!figmaFileFlow.goal_node_id) {
    throw new TypeError(
      `Section with ID ${section.id} has no FigmaFileFlow.goal_node_id`
    )
  }
  const ffvId = figmaFileFlow.figma_file_version_id
  const goalNodeId = figmaFileFlow.goal_node_id
  const goalImage = useSelector(getFigmaFileVersionById(ffvId)).images.find(
    (element) => element.figma_node_id === figmaFileFlow.goal_node_id
  )

  const filteredTasks = useFilteredTasks(section.id)
  const responseCountTotal = filteredTasks.length
  const reachedGoalTasks = filteredTasks.filter((task) =>
    hasGoalScreenBeenReached(task, goalNodeId)
  )
  const reachedGoalNodeCount = reachedGoalTasks.length
  const reachedGoalScreenRate =
    responseCountTotal === 0 ? 0 : reachedGoalNodeCount / responseCountTotal
  const reachedGoalScreenRawPercent = 100 * reachedGoalScreenRate

  const avgClicks = reachedGoalNodeCount
    ? Math.round(
        reachedGoalTasks.reduce(
          (sum, tr) => sum + tr.screens.flatMap((s) => s.clicks).length,
          0
        ) / reachedGoalNodeCount
      )
    : 0
  const averageMisclickRate =
    reachedGoalTasks.reduce((sum, tr) => sum + (tr.misclick_rate ?? 0), 0) /
    reachedGoalNodeCount
  const averageDuration = reachedGoalNodeCount
    ? reachedGoalTasks.reduce((sum, tr) => sum + tr.task_duration_ms, 0) /
      reachedGoalNodeCount
    : 0

  const { isFilter, toggleIsFiltered } = useReachedGoalScreenFilter(
    section.id,
    goalNodeId
  )
  const [accordionExpanded, setAccordionExpanded] = useState(false)

  const commonPathsCount = getCommonPathsCount(allTasks)
  const individualPathsCount = filteredTasks.length

  const recordings = useSectionRecordings()
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const showRecordingsTab = recordings.length > 0 && recordingFeatureEnabled
  const accordianTextSuffix = showRecordingsTab
    ? "paths, clicks and recordings"
    : "paths and clicks"

  return (
    <>
      <HStack
        borderWidth={1}
        borderColor="gray.300"
        borderRadius={4}
        px={4}
        py={3}
        spacing={1}
      >
        <HStack>
          <Box
            w="100px"
            h="70px"
            padding={2}
            borderWidth={1}
            borderRadius={2}
            borderColor="gray.200"
            alignItems="center"
          >
            <Image
              src={figmaImagePath({
                figmaFileVersionId: figmaFileFlow.figma_file_version_id,
                figmaNodeId: figmaFileFlow.goal_node_id,
              })}
              objectFit="contain"
              w="100%" // object-fit requires width and height
              h="100%" // ^
            />
          </Box>
          <VStack spacing={0} align="start">
            <Text color="green.500" fontSize="xs">
              Goal
            </Text>
            <Text flex={10} fontSize="md" fontWeight="semibold">
              {goalImage?.title || ""}
            </Text>
          </VStack>
        </HStack>
        <Spacer minW="32px" />
        <Progress
          variant="round"
          colorScheme="green"
          size="md"
          value={reachedGoalScreenRawPercent}
          aria-valuenow={reachedGoalScreenRawPercent}
          maxW="200px"
        />
        <Tooltip
          hasArrow
          placement="top"
          label="Percentage of participants who reached the goal screen"
        >
          <Text color="green.500">
            {formatPercentage01(reachedGoalScreenRate)}
          </Text>
        </Tooltip>
        <Spacer minW="32px" />
        <Tooltip
          hasArrow
          placement="top"
          label="Average number of clicks by participants who reached the goal screen"
        >
          <Flex
            alignItems="center"
            gap="1"
            color="gray.500"
            fontSize="sm"
            fontWeight="normal"
          >
            <Icon as={ClickFilledIcon} />
            <Text as="span">{avgClicks} clicks</Text>
          </Flex>
        </Tooltip>
        <Tooltip
          hasArrow
          placement="top"
          label="Percentage of misclicks by participants who reached the goal screen"
        >
          <Text as="span" color="gray.500" fontSize="sm" fontWeight="normal">
            ({formatPercentage01(averageMisclickRate)} misclicks)
          </Text>
        </Tooltip>
        <Spacer />
        <Tooltip
          hasArrow
          placement="top"
          label="Average time to complete this task by participants who reached the goal screen"
        >
          <Flex
            alignItems="center"
            gap="1"
            color="gray.500"
            fontSize="sm"
            fontWeight="normal"
          >
            <TimeIcon />
            <Text as="span">{formatPreciseDuration(averageDuration)}</Text>
          </Flex>
        </Tooltip>
        <Spacer />
        <Tooltip
          hasArrow
          placement="top"
          label="Number of participants who reached the goal screen"
        >
          <Flex
            alignItems="center"
            gap="1"
            color="gray.500"
            fontSize="sm"
            fontWeight="normal"
          >
            <Icon as={UserProfileFilledIcon} />
            <Text as="span">{reachedGoalNodeCount}</Text>
          </Flex>
        </Tooltip>
        <Spacer />

        <Tooltip
          hasArrow
          placement="top"
          label="Filter the test based on participants who have visited this screen"
        >
          <IconButton
            icon={isFilter ? <FilterFilledIcon /> : <FilterIcon />}
            aria-label="Filter"
            isSelected={isFilter}
            onClick={toggleIsFiltered}
          />
        </Tooltip>
      </HStack>
      <Accordion
        allowToggle
        onChange={(expandedIndex) => {
          setAccordionExpanded(expandedIndex === 0)
        }}
      >
        <AccordionItem border="none">
          <AccordionButton
            ps={0}
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ boxShadow: "none" }}
            fontSize="md"
            fontWeight="semibold"
            textColor="brand.primary.500"
            width="auto"
          >
            {accordionExpanded
              ? `Hide ${accordianTextSuffix}`
              : `Show ${accordianTextSuffix}`}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Stack spacing={12}>
              <Tabs isLazy>
                <TabList>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label="Identical paths taken by more than one participant"
                  >
                    <Tab data-qa="common-paths-tab">
                      <Icon as={UsersIcon} mr={2} />
                      <Text>
                        Common paths <small>{commonPathsCount}</small>
                      </Text>
                    </Tab>
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label="Paths taken by individual participants"
                  >
                    <Tab data-qa="individual-paths-tab">
                      <Icon as={UserIcon} mr={2} />
                      <Text>
                        Individual paths <small>{individualPathsCount}</small>
                      </Text>
                    </Tab>
                  </Tooltip>
                  {showRecordingsTab && (
                    <Tab data-qa="recording-tab">
                      <Icon as={RecordingsFilledIcon} mr={2} />
                      <Text>
                        Recordings <small>{recordings.length}</small>
                      </Text>
                    </Tab>
                  )}
                </TabList>
                {/* TODO: 6751 Condense all our custom-padded tabs panels into a reusable "section tab panel" */}
                <TabPanels>
                  <TabPanel p={0} pt={6}>
                    <CommonPathsList
                      filteredTasks={filteredTasks}
                      ffvId={ffvId}
                      goalNodeId={goalNodeId}
                      testId={section.usability_test_id}
                      sectionId={section.id}
                    />
                  </TabPanel>
                  <TabPanel p={0} pt={6}>
                    <IndividualPathsList
                      tasks={filteredTasks}
                      goalNodeId={goalNodeId}
                    />
                  </TabPanel>
                  {showRecordingsTab && (
                    <TabPanel p={0} pt={6}>
                      <SectionRecordingsTab />
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}
