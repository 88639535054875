import { Flex, Tooltip } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { ProIcon } from "Icons/ProIcon"
import { Response } from "Types"
import React from "react"
import { ExportResultsForm } from "../ExportResultsForm"

type ExportCSVButtonProps = {
  accountCanExportCsv: boolean
  filteredResponseIds: Response["id"][]
  usabilityTestUniqueId: string
  usabilityTestPrivateId: string
}

export const ExportCSVButton = ({
  accountCanExportCsv,
  filteredResponseIds,
  usabilityTestUniqueId,
  usabilityTestPrivateId,
}: ExportCSVButtonProps) => {
  const filteredResponsesCount = filteredResponseIds.length

  const button = (
    <Button
      leftIcon={
        <Flex
          alignItems="center"
          justifyContent="center"
          px={1.5}
          py={0.75}
          bg="ds.background.neutral.resting"
          rounded="full"
          textStyle="ds.interface.small"
        >
          {filteredResponsesCount}
        </Flex>
      }
      rightIcon={<ProIcon color="ds.icon.subtle" />}
      type="submit"
      isDisabled={!accountCanExportCsv}
    >
      Export as CSV
    </Button>
  )

  return accountCanExportCsv ? (
    <ExportResultsForm
      usabilityTestUniqueId={usabilityTestUniqueId}
      usabilityTestPrivateId={usabilityTestPrivateId}
    >
      {filteredResponseIds.map((id) => (
        <input key={id} type="hidden" name="response_ids[]" value={id} />
      ))}
      {button}
    </ExportResultsForm>
  ) : (
    <Tooltip
      hasArrow
      label="CSV exports are available on paid plans."
      placement="top"
    >
      {button}
    </Tooltip>
  )
}
