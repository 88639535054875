import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react"
import { Button, Heading } from "DesignSystem/components"
import { ArrowLeftIcon } from "Icons/ArrowLeftIcon"
import { ROUTES } from "UsabilityHub/views/routes"
import { MotionConfig } from "framer-motion"
import React from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { TemplateLibraryProvider } from "~/usabilityhub/hooks/useTemplateLibrary"
import { Contents } from "./Contents"

export const TemplateLibrary: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { templateId } = useTypedParams(ROUTES.DASHBOARD.TEMPLATES.DETAILS)

  const open = pathname.startsWith(ROUTES.DASHBOARD.TEMPLATES.path)
  const showingDetails = !!templateId

  return (
    <MotionConfig transition={{ duration: 0.5, ease: [0.4, 0, 0.2, 1] }}>
      <TemplateLibraryProvider open={open}>
        <Modal
          isOpen={open}
          size="6xl"
          closeOnOverlayClick={false}
          onClose={() => navigate(ROUTES.DASHBOARD.path)}
        >
          <ModalOverlay />
          <ModalContent
            overflow="hidden"
            h={{ base: "100vh", md: "60rem" }}
            maxH={{ md: "calc(100vh - 4rem)" }}
            w={{ base: "100vw", md: "calc(100vw - 8rem)" }}
            maxW={{ md: "75rem" }}
            borderRadius={{ base: 0, md: 12 }}
            my={{ base: 0, md: 8 }}
            display="flex"
            flexDirection="column"
          >
            <ModalHeader
              display="flex"
              alignItems="center"
              gap={6}
              p={{ base: 4, md: 8 }}
              pb={{ base: 4, md: 5 }}
            >
              {showingDetails ? (
                <>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        as={Link}
                        to={ROUTES.DASHBOARD.TEMPLATES.path}
                        display="flex"
                        alignItems={"center"}
                        gap={2}
                        _focus={{ outline: "none", boxShadow: "none" }}
                      >
                        <Icon as={ArrowLeftIcon} />
                        <Text
                          fontSize="md"
                          fontWeight="medium"
                          textDecoration="none"
                        >
                          Back
                        </Text>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  </Breadcrumb>
                  <Spacer />
                </>
              ) : (
                <>
                  <Heading as="h2" textStyle="ds.display.emphasized" flex={1}>
                    Template library
                  </Heading>
                  <Button
                    as={Link}
                    to={ROUTES.TESTS.NEW.path + "?source=templates"}
                    size="emphasized"
                  >
                    Start with a blank test
                  </Button>
                </>
              )}

              <ModalCloseButton
                alignSelf="flex-end"
                position="static"
                size="lg"
                _focus={{ outline: "none", boxShadow: "none" }}
              />
            </ModalHeader>
            <Contents />
          </ModalContent>
        </Modal>
      </TemplateLibraryProvider>
    </MotionConfig>
  )
}
