import {
  Button,
  Checkbox,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import { fullWithTime } from "Utilities/date-formats"
import React, { useState } from "react"

import { assertNever } from "UsabilityHub/components/FilterControls/utils"
import { GetModeratedStudyBookingsResponse } from "~/api/generated/usabilityhub-components"
import { ResolveMarkedBooking } from "./BookingCard/BookingCard"
import { ParticipantRatingForm, Rating } from "./ParticipantRatingForm"
import { ParticipantReportingForm } from "./ParticipantReportingForm"

type Booking = GetModeratedStudyBookingsResponse[number]

type BookingStateUpdateModalProps = {
  booking: Booking | null
  newState: ResolveMarkedBooking["newState"]
  onConfirm: (arg: ResolveMarkedBooking) => void
  onClose: () => void
}

const BookingStateUpdateModal: React.FC<BookingStateUpdateModalProps> = ({
  ...props
}) => {
  const { newState, booking, onConfirm, onClose } = props

  if (!booking) return null

  // The content components don't need to know the newState
  const partialProps = {
    booking, // booking is now !null
    onConfirm,
    onClose,
  }

  // There are 3 different states we can be transitioning to. The content of
  // this modal has been split out 3 separate components each with their own
  // local state to keep the main conditional on `newState` in one place.
  return (
    <Modal isOpen onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        {newState === "complete" ? (
          <CompleteContent {...partialProps} />
        ) : newState === "reported" ? (
          <ReportedContent {...partialProps} />
        ) : newState === "no_show" ? (
          <NoShowContent {...partialProps} />
        ) : (
          assertNever(newState)
        )}
      </ModalContent>
    </Modal>
  )
}

export default BookingStateUpdateModal

type ModalContentProps = {
  booking: Booking
  onConfirm: (arg: ResolveMarkedBooking) => void
  onClose: () => void
}

type ModalContentHeaderBodyProps = {
  headerText: string
}

const ModalBodyWithHeaderAndClose: React.FC<
  React.PropsWithChildren<ModalContentHeaderBodyProps>
> = ({ headerText, children }) => {
  return (
    <>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{children}</ModalBody>
    </>
  )
}

const ModalFooterWithHStack: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ModalFooter>
      <HStack gap={2}>{children}</HStack>
    </ModalFooter>
  )
}

const CompleteContent: React.FC<ModalContentProps> = ({
  booking,
  onConfirm,
  onClose,
}) => {
  const [markAsPaid, setMarkAsPaid] = useState(false)
  const [rating, setRating] = useState<Rating | undefined>(undefined)
  const [comment, setComment] = useState("")

  const ratingUnfinished = !rating || comment.length < 1
  const confirmButtonDisabled = booking.is_panelist && ratingUnfinished

  const confirm = () => {
    onConfirm({
      newState: "complete",
      confirm: true,
      markAsPaid,
      rating,
      comment,
    })

    onClose()
  }

  return (
    <>
      <ModalBodyWithHeaderAndClose headerText="Mark as completed">
        {booking.is_panelist ? (
          <Text>
            By continuing, you confirm that this participant attended their
            session. Once confirmed, they will receive their incentive, which is
            already covered in the order costs paid.
          </Text>
        ) : (
          <Text>
            By continuing, you confirm that this participant attended their
            session and completed it successfully.
            {booking.incentive &&
              " They are now entitled to their incentive. Please process their incentive payment as soon as possible."}
          </Text>
        )}

        <BookingSummaryGrid booking={booking} />

        {booking.is_panelist && (
          <ParticipantRatingForm
            rating={rating}
            setRating={setRating}
            comment={comment}
            setComment={setComment}
          />
        )}

        {booking.incentive && !booking.is_panelist && (
          <>
            <Text mb={2} fontWeight="medium">
              Follow-up action
            </Text>
            <Checkbox
              checked={markAsPaid}
              fontWeight="medium"
              onChange={(e) => setMarkAsPaid(e.currentTarget.checked)}
            >
              Mark as incentive paid
            </Checkbox>
          </>
        )}
      </ModalBodyWithHeaderAndClose>

      <ModalFooterWithHStack>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip
          hasArrow
          rounded="md"
          placement="top"
          label={confirmButtonDisabled && "Please rate and add a comment"}
        >
          <Button
            colorScheme="brand.primary"
            onClick={confirm}
            isDisabled={confirmButtonDisabled}
          >
            Confirm
          </Button>
        </Tooltip>
      </ModalFooterWithHStack>
    </>
  )
}

const ReportedContent: React.FC<ModalContentProps> = ({
  booking,
  onConfirm,
  onClose,
}) => {
  const [reportReason, setReportReason] = useState("")
  const [reportLink, setReportLink] = useState("")

  const confirm = () => {
    onConfirm({
      newState: "reported",
      confirm: true,
      reportReason,
      reportLink,
    })

    onClose()
  }

  return (
    <>
      <ModalBodyWithHeaderAndClose headerText="Report participant">
        <Text>
          Please tell us what went wrong with this participant{"\u2019"}s
          session. This will be reviewed by Lyssna and you will receive a
          response in 1{"\u2013"}2 business days.
        </Text>

        <BookingSummaryGrid booking={booking} />

        <ParticipantReportingForm
          reportReason={reportReason}
          setReportReason={setReportReason}
          reportLink={reportLink}
          setReportLink={setReportLink}
        />
      </ModalBodyWithHeaderAndClose>

      <ModalFooterWithHStack>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          colorScheme={"red"}
          onClick={confirm}
          isDisabled={!reportReason}
        >
          Report
        </Button>
      </ModalFooterWithHStack>
    </>
  )
}

const NoShowContent: React.FC<ModalContentProps> = ({
  booking,
  onConfirm,
  onClose,
}) => {
  const confirm = () => {
    onConfirm({ newState: "no_show", confirm: true })

    onClose()
  }

  const allowMarkAsNoShow =
    !booking.is_panelist || !booking.clicked_join_link_within_reasonable_time

  return (
    <>
      <ModalBodyWithHeaderAndClose headerText="Mark as no-show">
        {booking.is_panelist ? (
          allowMarkAsNoShow ? (
            <Text>
              By continuing, you confirm that this participant did not show up
              to their session. Once you confirm cancelation, we will
              automatically attempt to replace them with a new panelist.
            </Text>
          ) : (
            <VStack spacing={2}>
              <Text>
                Our records show that the participant attempted to join the
                session.
              </Text>
              <Text>
                If the participant did not join the session, please select
                "Report participant" instead.
              </Text>
            </VStack>
          )
        ) : (
          <Text>
            By continuing, you confirm that this participant did not show up to
            their session.
            {booking.incentive &&
              ` You don${"\u2019"}t need to pay them an incentive.`}
          </Text>
        )}

        <BookingSummaryGrid booking={booking} />
      </ModalBodyWithHeaderAndClose>

      <ModalFooterWithHStack>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        {allowMarkAsNoShow && (
          <Button colorScheme={"brand.primary"} onClick={confirm}>
            Confirm
          </Button>
        )}
      </ModalFooterWithHStack>
    </>
  )
}

const BookingSummaryGrid: React.FC<{
  booking: Booking
}> = ({ booking }) => {
  return (
    <Grid
      as="dl"
      my={6}
      mx={0}
      gridTemplateColumns="auto 1fr"
      rowGap={4}
      columnGap={8}
    >
      {booking.full_name && (
        <>
          <Text as="dt" margin={0} color="text.secondary">
            Full name
          </Text>
          <Text as="dd" margin={0}>
            {booking.full_name}
          </Text>
        </>
      )}
      <Text as="dt" margin={0} color="text.secondary">
        {booking.is_panelist ? "Name" : "Preferred name"}
      </Text>
      <Text as="dd" margin={0}>
        {booking.preferred_name}
      </Text>
      {booking.email && (
        <>
          <Text as="dt" margin={0} color="text.secondary">
            Email
          </Text>
          <Text as="dd" margin={0}>
            {booking.email}
          </Text>
        </>
      )}
      <Text as="dt" margin={0} color="text.secondary">
        Session
      </Text>
      <Text as="dd" margin={0}>
        {booking && fullWithTime(new Date(booking.starts_at))}
      </Text>
      {booking.incentive && !booking.is_panelist && (
        <>
          <Text as="dt" margin={0} color="text.secondary">
            Incentive
          </Text>
          <Text as="dd" margin={0}>
            {booking.incentive}
          </Text>
        </>
      )}
    </Grid>
  )
}
