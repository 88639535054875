import {
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { AddIcon } from "Icons/AddIcon"
import React from "react"
import NoStudiesImage from "./no-studies.svg"

type Props = {
  handleCreateStudy: () => void
}
export const ModeratedStudyEmptyState: React.FC<Props> = ({
  handleCreateStudy,
}) => {
  const isMobile = useBreakpointValue<boolean>(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  )

  return (
    <Flex
      m={isMobile ? "32px 8px" : "0 8px"}
      justifyContent="center"
      alignItems="center"
      direction="column"
      gap="3"
    >
      <Image src={NoStudiesImage} alt="No results" />
      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        mb="2"
        gap="1"
      >
        <Heading fontSize="2xl" color="text.primary" fontWeight="normal">
          No studies yet
        </Heading>

        <Text
          fontSize="md"
          color="text.secondary"
          fontWeight="normal"
          align="center"
        >
          Need help getting started? Visit our{" "}
          <Link
            href={Constants.HELP_CENTER_INTERVIEWS_GETTING_STARTED_URL}
            textDecoration="none"
            fontWeight="normal"
            target="_blank"
          >
            Help Center.
          </Link>
        </Text>
      </Flex>
      <Button
        colorScheme="brand.primary"
        leftIcon={<Icon as={AddIcon} />}
        onClick={handleCreateStudy}
      >
        Create new study
      </Button>
    </Flex>
  )
}
