import { Flex, Icon } from "@chakra-ui/react"
import { Button, IconButton, Input } from "DesignSystem/components"
import { ArrowLeftIcon } from "Icons/ArrowLeftIcon"
import { ProjectIcon } from "Icons/ProjectIcon"
import { SearchIcon } from "Icons/SearchIcon"
import { TemplateIcon } from "Icons/TemplateIcon"
import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import { HEADER_TITLE_AND_BREADCRUMB_HEIGHT } from "UsabilityHub/constants/layoutConstants"
import { useModal } from "Utilities/modals/use-modal"
import { debounce } from "lodash"
import React, { PropsWithChildren } from "react"
import { useMatch, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { ROUTES } from "../routes"
import { CreateNewStudyButton } from "./CreateNewStudyButton"
import { CreateProjectModal } from "./CreateProjectModal"
import { TemplateLibrary } from "./TemplateLibrary"

const SEARCH_DEBOUNCE_TIME_MS = 600

export const DashboardHeader: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const isSearching = useMatch("/search") !== null

  const [searchParams, setSearchParams] = useMutableQueryString()
  const query = searchParams["q"] ?? ""

  const { open: openCreateProjectModal } = useModal(CreateProjectModal)

  const debouncedSearch = debounce((q: string) => {
    setSearchParams({ q })
  }, SEARCH_DEBOUNCE_TIME_MS)

  return (
    <Flex
      display={[!isSearching ? "none" : "flex", null, null, "flex"]}
      as="header"
      w="full"
      bg="white"
    >
      <Flex
        // The search bar is intended to match the height of the title +
        // breadcrumb bar on other pages
        h={HEADER_TITLE_AND_BREADCRUMB_HEIGHT}
        w="full"
        align="center"
        gap={3}
        px={[6, null, 8, 10]}
        py={2}
        mx="auto"
        borderBottom={{ base: "1px solid", md: "none" }}
        borderColor="ds.border.default"
      >
        {isSearching && (
          <IconButton
            icon={<Icon as={ArrowLeftIcon} />}
            aria-label="Back"
            variant="secondary"
            onClick={() => navigate("/dashboard")}
          />
        )}
        <Flex
          display={[!isSearching ? "none" : "flex", null, null, "flex"]}
          flexGrow={1}
          flexShrink={1}
          overflow="hidden"
        >
          {children || (
            <Input
              maxW={isSearching ? undefined : "15rem"}
              defaultValue={query}
              autoFocus={isSearching}
              placeholder="Search"
              leftIcon={
                <Icon as={SearchIcon} color="ds.icon.subtle" boxSize={5} />
              }
              size="compact"
              rounded="full"
              onChange={(e) => debouncedSearch(e.target.value)}
              onFocus={isSearching ? undefined : () => navigate("/search")}
              data-intercom-target="search-bar"
            />
          )}
        </Flex>

        {!isSearching && (
          <>
            <Flex
              display={{ base: "none", lg: "flex" }} // Visible for desktop screens
              alignItems="center"
              gap={3}
              flexShrink={0}
            >
              <Button
                as={Link}
                to={ROUTES.DASHBOARD.TEMPLATES.path}
                variant="secondary"
                leftIcon={<Icon as={TemplateIcon} />}
              >
                Template library
              </Button>

              <Button
                onClick={openCreateProjectModal}
                variant="secondary"
                leftIcon={
                  <Icon as={ProjectIcon} color="brand.neutral.default" />
                }
                data-intercom-target="create-new-project-button"
              >
                Create project
              </Button>

              <CreateNewStudyButton />
            </Flex>
          </>
        )}
      </Flex>

      <TemplateLibrary />
    </Flex>
  )
}
