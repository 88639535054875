import Constants from "Constants/shared.json"
import { useQueryStringParams } from "Shared/hooks/useQueryStringParams"

const planParamToPlanUniqueId = (param: string) => {
  switch (param) {
    case "basic_monthly":
      return Constants.BASIC_V3_MONTHLY_PLAN_UNIQUE_ID
    case "basic_annual":
      return Constants.BASIC_V3_ANNUAL_PLAN_UNIQUE_ID
    case "pro_monthly":
      return Constants.PRO_V3_MONTHLY_PLAN_UNIQUE_ID
    case "pro_annual":
      return Constants.PRO_V3_ANNUAL_PLAN_UNIQUE_ID
    default:
      return Constants.FREE_PLAN_UNIQUE_ID
  }
}

export const useIntendedPlanId = () => {
  const query = useQueryStringParams()
  const intendedPlanFromURL = query.get("plan") || ""
  return planParamToPlanUniqueId(intendedPlanFromURL)
}
