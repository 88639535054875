import {
  FigmaFileVersion,
  RawParticipantUsabilityTest,
  RawScreenshot,
} from "Types"
import { reportErrorToSentry } from "Utilities/error"
import {
  PrototypeNotViewableError,
  isFigmaFileVersionViewable,
} from "Utilities/figma-file-version"
import { loadImage } from "Utilities/image"
import { Dispatch } from "react"
import { LoadAction as TestLoadAction } from "../reducer/usabilityTestLoadReducer"

const loadScreenshot = async (screenshot: RawScreenshot): Promise<void> => {
  try {
    const { url } = screenshot

    // Can't load a screenshot without a `url` obviously... This should never
    // happen, but if it does (in the case of the test interface, for example)
    // it should be an error.
    if (url == null) {
      const error = new TypeError(
        `screenshot.url === ${String(url)} for screenshot ID: ${screenshot.id}`
      )
      reportErrorToSentry(error, {
        extra: screenshot,
      })
      throw error
    }

    if (screenshot.media_type === "image") {
      await loadImage(url)
    }
  } catch (error) {
    throw "Failed to load image for screenshot ID: ${screenshot.id}. " + error
  }
}

export const loadUsabilityTest =
  (
    isPreview: boolean,
    usabilityTest: RawParticipantUsabilityTest,
    screenshots: ReadonlyArray<RawScreenshot>,
    figmaFileVersions: FigmaFileVersion[],
    dispatchTestLoadState: Dispatch<TestLoadAction>
  ) =>
  async () => {
    dispatchTestLoadState({ type: "startLoading" })
    try {
      // Any screenshot load failures will cause test load to fail
      await Promise.all([
        ...screenshots.map((screenshot) => loadScreenshot(screenshot)),
        ...figmaFileVersions.map((ffv) => {
          // Skip caching of response from Figma in preview mode
          const skipCache = isPreview
          return isFigmaFileVersionViewable(ffv, skipCache)
        }),
      ])
      dispatchTestLoadState({ type: "loadSuccess" })
    } catch (error) {
      dispatchTestLoadState({ type: "loadFailure" })

      // TODO: We will do this after moving cancelResponse to new API
      // if (assertPresent(getState().currentResponse).id != null) {
      //   dispatch(cancelResponse(ParticipantDeletionReason.ImagesFailedToLoad))
      // }

      // Don't report prototype not viewable errors in production, there's
      // nothing we can do about them.
      if (process.env.DEBUG || !(error instanceof PrototypeNotViewableError)) {
        reportErrorToSentry(error, {
          extra: {
            usabilityTestId: usabilityTest.id,
          },
        })
      }
    }
  }
