import {
  Screenshot as ScreenshotComponent,
  ScreenshotProps,
} from "Components/screenshot/screenshot"
import { useTestTakingContext } from "UsabilityHub/components/UsabilityTest/context/TestTakingContext"
import React from "react"
import {
  UnscaledScreenshot,
  UnscaledScreenshotProps,
} from "./UnscaledScreenshot/UnscaledScreenshot"

// These are the new versions of ScreenshotWithId and UnscaledScreenshotWithId in app/frontend/application/javascripts/components/screenshot/screenshot-with-id.tsx, but with get rid of redux.
// These are currently used only during test-taking.

type Props = {
  screenshotId: number
} & Omit<ScreenshotProps, "screenshot">

export const ScreenshotWithId: React.FC<Props> = ({
  screenshotId,
  ...props
}) => {
  const { screenshotsById } = useTestTakingContext()
  const screenshot = screenshotsById[screenshotId]
  return <ScreenshotComponent screenshot={screenshot} {...props} />
}

type UnscaledScreenshotWithIdProps = {
  screenshotId: number
} & Omit<UnscaledScreenshotProps, "screenshot">

export const UnscaledScreenshotWithId: React.FC<
  UnscaledScreenshotWithIdProps
> = ({ screenshotId, ...props }) => {
  const { screenshotsById } = useTestTakingContext()
  const screenshot = screenshotsById[screenshotId]
  return <UnscaledScreenshot screenshot={screenshot} {...props} />
}
