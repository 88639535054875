import { CheckboxIcon } from "DesignSystem/components/Checkbox"
import { AppliedFilter, FilterAttribute, FilterOptionChoice } from "Types"
import React, { useState } from "react"
import { useFilterContext } from "./FilterContext"
import { FilterMenu } from "./FilterMenu"

interface Props {
  appliedFilter?: AppliedFilter
  selectedAttribute: FilterAttribute
}

export const FilterMenuMulti: React.FC<Props> = ({
  appliedFilter = null,
  selectedAttribute,
}) => {
  const { addFilter, changeFilter } = useFilterContext()

  const [editingFilter, setEditingFilter] = useState<AppliedFilter | null>(
    appliedFilter
  )

  const onMultiFilterChange = (choice: FilterOptionChoice) => {
    if (editingFilter) {
      if (editingFilter.options.type !== "multi") return

      const currentlyActive = editingFilter.options.choices.some(
        (c) => c.value === choice.value
      )

      const newChoices = currentlyActive
        ? editingFilter.options.choices.filter((c) => c.value !== choice.value)
        : [...editingFilter.options.choices, choice]

      const updatedFilter = changeFilter(
        editingFilter.id,
        editingFilter.comparator,
        {
          type: "multi",
          choices: newChoices,
        }
      )

      setEditingFilter(updatedFilter)
    } else {
      const newFilter = addFilter(selectedAttribute, {
        type: "multi",
        choices: [choice],
      })

      setEditingFilter(newFilter)
    }
  }

  return (
    <FilterMenu
      appliedFilter={editingFilter}
      selectedAttribute={selectedAttribute}
      icon={(_, isSelected) => <CheckboxIcon isChecked={isSelected} />}
      onSelect={onMultiFilterChange}
    />
  )
}
