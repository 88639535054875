import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  HStack,
  Icon,
  Spacer,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { add } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { isEqual } from "lodash"
import React, { useEffect, useRef, useState } from "react"

import { ScrollingWrapper } from "Components/test-results/section-results/SectionResultsCards/ScrollingWrapper"
import { ListModeratedStudyBookingSlotsResponse } from "~/api/generated/usabilityhub-components"

import { IconButton } from "DesignSystem/components"
import { ChevronLeftIcon } from "Icons/ChevronLeftIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { useBookingCalendar } from "./useBookingCalendar"

const WEEKDAY_ORDER = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]

type DateType = [number, number, number] // year, month, date number

type BookingSlot =
  ListModeratedStudyBookingSlotsResponse["booking_slots"][number]

type Props = {
  moderatedStudyApplicationId: string
  timezone: string
  slot: string | null
  existingBookingId?: string
  onSlotChange: (slot: string | null) => void
  // For the booking page, we need to show a loading state for the whole page during the first time
  // retrieving the calendar data. If there is no available slots for the study, the page should be
  // redirected to the thank you page.
  //
  // If no available slots are found when users are browsing the page or when users are invited,
  // the page will show appropriate messages and a "Continue" button.
  //
  // For the reschedule page, it still show "Select a date to view available times" and "Book" button
  isFirstLoading: boolean
  setIsFirstLoading?: (isFirstLoading: boolean) => void
  setHasAvailableSlots?: (hasAvailableSlots: boolean) => void
  displayFullSlots?: boolean
}

// Total height of calendar is 48 per row (40 + 8 gap), fixed to always 6 rows
// plus 28 for the weekday headings and 48 for the title
export const calendarHeight = 48 * 6 + 28 + 48

export const BookingCalendar: React.FC<Props> = ({
  moderatedStudyApplicationId,
  timezone,
  slot,
  existingBookingId,
  onSlotChange,
  isFirstLoading,
  setIsFirstLoading,
  setHasAvailableSlots,
  displayFullSlots = false,
}) => {
  const {
    isCalendarOnlyPreview,
    year,
    month,
    setYearAndMonth,
    isLoading,
    isError,
    data,
    refetch,
    selectedDate,
    isRescheduling,
    slotsByDay,
    onDateChange,
  } = useBookingCalendar({
    moderatedStudyApplicationId,
    timezone,
    slot,
    existingBookingId,
    isFirstLoading,
    setIsFirstLoading,
    setHasAvailableSlots,
    onSlotChange,
  })

  useEffect(
    () => data && setHasAvailableSlots?.(data.has_available_booking_slots),
    [data?.has_available_booking_slots, setHasAvailableSlots]
  )

  if (isFirstLoading || isLoading)
    return (
      <Center gridColumn="1 / -1">
        <Spinner />
      </Center>
    )

  if (isError || !data)
    return (
      <Alert status="error" gridColumn="1 / -1">
        <AlertIcon />
        <AlertTitle>There was an error fetching booking slots!</AlertTitle>
        <AlertDescription>
          <Button colorScheme="red" onClick={() => refetch()}>
            Refresh
          </Button>
        </AlertDescription>
      </Alert>
    )

  return (
    <>
      <Box
        marginTop={isCalendarOnlyPreview ? 4 : 0}
        marginBottom={isCalendarOnlyPreview ? 8 : 0}
      >
        <CalendarDisplay
          year={year}
          month={month}
          slotsByDay={slotsByDay}
          earlierData={data.earlier_data}
          laterData={data.later_data}
          setYearAndMonth={setYearAndMonth}
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          hasAvailableSlots={!!data.has_available_booking_slots}
        />
      </Box>

      {selectedDate &&
      isEqual([year, month], [selectedDate[0], selectedDate[1]]) ? (
        <AvailableSlotList
          slotsByDay={slotsByDay}
          selectedTimezone={timezone}
          selectedSlot={slot}
          selectedDate={selectedDate}
          setSelectedSlot={onSlotChange}
          leftAlignText={!!isCalendarOnlyPreview}
          displayFullSlots={displayFullSlots}
        />
      ) : (
        <Center flexGrow={isCalendarOnlyPreview ? "1" : "0"}>
          <Flex
            color="text.secondary"
            textAlign="center"
            flexFlow="column"
            w={isCalendarOnlyPreview ? "100%" : "auto"}
            h={isCalendarOnlyPreview ? "100%" : "auto"}
          >
            {isRescheduling.current ? (
              "Select a date to view available times"
            ) : data.has_available_booking_slots ? (
              "No time slots available"
            ) : isCalendarOnlyPreview ? (
              <Center
                borderRadius={6}
                padding={4}
                backgroundColor="bg.surface.callout"
                justifyContent="center"
                alignItems="center"
                flex="1"
              >
                <Text fontSize="md">No time slots available</Text>
              </Center>
            ) : (
              <>
                <Text fontWeight="medium" mb={1}>
                  No time slots available
                </Text>
                <Text fontSize="sm">
                  Currently, all our time slots are booked. Please continue to
                  finish your application.
                </Text>
              </>
            )}
          </Flex>
        </Center>
      )}
    </>
  )
}

export const CalendarDisplay: React.FC<{
  year: number
  month: number
  slotsByDay: BookingSlot[][]
  earlierData: boolean
  laterData: boolean
  setYearAndMonth: (date: [number, number]) => void
  selectedDate: DateType | undefined
  onDateChange: (start_time: string) => void
  hasAvailableSlots: boolean
}> = ({
  year,
  month,
  slotsByDay,
  earlierData,
  laterData,
  setYearAndMonth,
  selectedDate,
  onDateChange,
  hasAvailableSlots,
}) => {
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay()
  const calendarPaddingDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1

  const changeMonth = (delta: number) => {
    return () => {
      const newDate = add(new Date(year, month - 1), { months: delta })

      setYearAndMonth([newDate.getFullYear(), newDate.getMonth() + 1])
    }
  }

  return (
    <Flex direction="column" data-qa="booking-calendar">
      <Flex align="center" mb={4}>
        <Text textStyle="ds.heading.primary">
          <Text as="span" fontWeight="bold">
            {new Date(year, month - 1).toLocaleString(navigator.language, {
              month: "long",
            })}
          </Text>{" "}
          <Text as="span" fontWeight="medium">
            {year}
          </Text>
        </Text>

        <Spacer flexGrow={1} />

        <IconButton
          variant="subtle"
          icon={
            <Icon boxSize={5} color="ds.icon.subtle" as={ChevronLeftIcon} />
          }
          aria-label="Previous month"
          onClick={changeMonth(-1)}
          isDisabled={!earlierData || !hasAvailableSlots}
        />
        <IconButton
          variant="subtle"
          icon={
            <Icon boxSize={5} color="ds.icon.subtle" as={ChevronRightIcon} />
          }
          aria-label="Next month"
          onClick={changeMonth(1)}
          isDisabled={!laterData || !hasAvailableSlots}
        />
      </Flex>

      <Grid
        templateColumns="repeat(7, 42px)"
        templateRows="20px"
        autoRows="42px"
        gap={2}
      >
        {WEEKDAY_ORDER.map((day) => (
          <Text key={day} fontSize="xs" align="center">
            {day}
          </Text>
        ))}

        {new Array(calendarPaddingDays).fill(null).map((_, i) => (
          <div key={`padding-${i}`} />
        ))}

        {slotsByDay.map((slotsOnDay, dayNum) => {
          // There is no day zero
          if (dayNum === 0) return null

          const selected = isEqual([year, month, dayNum], selectedDate)

          if (slotsOnDay.length > 0) {
            return (
              <Button
                key={dayNum}
                data-qa="calendar-day"
                rounded="md"
                fontSize="md"
                fontWeight="semibold"
                color="brand.neutral.default"
                background="white"
                boxShadow="sm"
                borderWidth={1}
                borderColor="gray.200"
                outline={selected ? "2px solid" : undefined}
                outlineColor={selected ? "brand.primary.500" : undefined}
                _focusVisible={{
                  borderColor: "brand.primary.200",
                  background: "brand.primary.50",
                }}
                onClick={() => {
                  onDateChange(slotsOnDay[0].start_time)
                }}
              >
                {dayNum}
              </Button>
            )
          } else {
            return (
              <Center
                key={dayNum}
                fontSize="md"
                fontWeight="normal"
                color="blackAlpha.500"
              >
                {dayNum}
              </Center>
            )
          }
        })}
      </Grid>
    </Flex>
  )
}

export const AvailableSlotList: React.FC<{
  slotsByDay: BookingSlot[][]
  selectedTimezone: string
  selectedDate: DateType | undefined
  selectedSlot: string | null
  setSelectedSlot: (slotTime: string) => void
  leftAlignText?: boolean
  displayFullSlots?: boolean
}> = ({
  slotsByDay,
  selectedTimezone,
  selectedDate,
  selectedSlot,
  setSelectedSlot,
  leftAlignText = false,
  displayFullSlots = false,
}) => {
  const selectedSlotParsed = selectedDate
    ? new Date(selectedDate[0], selectedDate[1] - 1, selectedDate[2])
    : null
  const slotsForSelectedDay = selectedDate
    ? (slotsByDay[selectedDate[2]] ?? [])
    : []

  const container = useRef<HTMLDivElement>(null)
  const [scrollContainerMaxHeight, setScrollContainerMaxHeight] = useState<
    number | null
  >(null)
  useEffect(() => {
    // Grab the whole container, find the first element (our text block) and take the height off it off to find the
    // maximum height for the inner scroll container
    if (
      container?.current!["offsetHeight"] &&
      container?.current?.children[0].clientHeight
    )
      setScrollContainerMaxHeight(
        container?.current!["offsetHeight"] -
          container?.current?.children[0].clientHeight
      )
  }, [selectedDate])

  return (
    <Grid
      flex={1}
      gap={4}
      overflow="hidden"
      minHeight="0"
      gridTemplateRows="auto 1fr"
      ref={container}
    >
      <HStack align="center" minHeight="2rem">
        <Text textStyle="ds.heading.primary">
          <Text as="span" fontWeight="bold">
            {selectedSlotParsed?.toLocaleString(navigator.language, {
              weekday: "long",
            })}
            ,
          </Text>{" "}
          <Text as="span" fontWeight="medium">
            {selectedSlotParsed?.toLocaleString(navigator.language, {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </Text>
        </Text>
      </HStack>

      <ScrollingWrapper
        key={selectedSlotParsed?.toDateString()}
        axis="y"
        position={{ base: "relative", sm: "absolute" }}
        inset={0}
        pr={6}
        maxH={
          displayFullSlots
            ? undefined
            : `calc(${scrollContainerMaxHeight}px - var(--chakra-space-4))`
        } // Matches a full height calendar minus the date header
      >
        <Flex direction="column" gap={2} p={1}>
          {slotsForSelectedDay.map((slot) => {
            const slotTime = slot.start_time
            const parsedSlotTime = utcToZonedTime(
              slot.start_time,
              selectedTimezone
            )
            // Chrome has a bug in some locales where 12pm
            // is displayed as '00:00 pm' instead of '12:00 pm'
            const humanizedSlotTime = parsedSlotTime?.toLocaleString(
              navigator.language,
              {
                timeStyle: "short",
                hourCycle: "h12",
              }
            )

            // When `leftAlignText` is true, the available slots are displayed in the calendar preview.
            // We don’t want the hover and outline effect to appear in this view.
            const outlineProps =
              !leftAlignText && selectedSlot === slotTime
                ? {
                    outline: "2px solid",
                    outlineColor: "brand.primary.500",
                  }
                : {}

            return (
              <Button
                key={slotTime}
                data-qa="time-slot"
                variant="outline"
                fontWeight="semibold"
                flexShrink={0}
                _focusVisible={{
                  borderColor: "brand.primary.200",
                  background: "brand.primary.50",
                }}
                // TODO: we'll get rid of this once these buttons do something, so borrowing leftAlignText for now as it's used in the only place we want the hover disabled
                sx={
                  leftAlignText
                    ? {
                        pointerEvents: "none",
                      }
                    : {}
                }
                justifyContent={leftAlignText ? "flex-start" : "center"}
                onClick={() => setSelectedSlot(slotTime)}
                {...outlineProps}
              >
                {humanizedSlotTime}
              </Button>
            )
          })}
        </Flex>
      </ScrollingWrapper>
    </Grid>
  )
}
