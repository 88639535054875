import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import React from "react"

import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { EventTime } from "Components/time/event-time"
import { DeleteIcon } from "Icons/DeleteIcon"
import { isAxiosErrorWithMessage } from "Services/axios"
import { AdminNote } from "Types"

type AdminNotesDeleteButton = React.FC<
  React.PropsWithChildren<{
    note: AdminNote
    deleteNote: (noteId: number) => Promise<void>
  }>
>

export const AdminNotesDeleteButton: AdminNotesDeleteButton = ({
  note,
  deleteNote,
}) => {
  const toast = useToast()

  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure()

  const handleDelete = async () => {
    try {
      await deleteNote(note.id)
      closeDeleteModal()
    } catch (e) {
      if (isAxiosErrorWithMessage(e)) {
        toast({
          description: e.response.data.message,
          status: "error",
          title: `Your note couldn${"\u2019"}t be deleted`,
        })
        closeDeleteModal()
      } else {
        throw e
      }
    }
  }

  return (
    <>
      <Tooltip hasArrow label="Delete note" placement="top">
        <IconButton
          colorScheme="red"
          variant="ghost"
          size="sm"
          aria-label="Delete note"
          icon={<Icon as={DeleteIcon} />}
          onClick={openDeleteModal}
        />
      </Tooltip>
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete this note?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Text color="gray.500" fontSize={14} mb={-2}>
                {note.creator_name}, <EventTime date={note.created_at} />
              </Text>
              <DisplayBlockMarkdownText text={note.text} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeDeleteModal}>Cancel</Button>
            <Button colorScheme="red" ml={3} onClick={handleDelete}>
              Delete note
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
