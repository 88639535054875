import {
  Box,
  ButtonGroup,
  Grid,
  IconButton,
  Progress,
  Stat,
  StatLabel,
  StatNumber,
  StatProps,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { PinFilledIcon } from "Icons/PinFilledIcon"
import { PinIcon } from "Icons/PinIcon"
import React from "react"
import { COLORS, Variant } from "./types"

type VariantRowProps = {
  variant: Variant
  pinned: boolean
  hidden: boolean
  color: (typeof COLORS)[number]
  onPin: (variant: Variant | null) => void
}

export const VariantRow: React.FC<VariantRowProps> = ({
  color,
  variant,
  pinned,
  hidden,
  onPin,
}) => {
  return (
    <Grid
      key={variant.name}
      gridTemplateColumns="1.25rem minmax(20rem, 1fr) repeat(5, 6rem) auto"
      gridTemplateRows="auto auto"
      columnGap={2}
      alignItems={"center"}
      opacity={hidden ? 0.5 : 1}
      transition="opacity 0.2s ease-in-out"
    >
      <Box
        bg={`${color}.500`}
        w={5}
        h={5}
        rounded="full"
        gridRow={1}
        gridColumn={1}
      />
      <Text fontFamily="monospace" fontSize="lg" gridRow={1} gridColumn={2}>
        {variant.name}{" "}
        <Text as="small" color="text.subtlest">
          (weight = {variant.weight})
        </Text>
      </Text>
      <VariantStat>
        <StatNumber>{variant.statistics.visitors}</StatNumber>
        <StatLabel>Visitors</StatLabel>
      </VariantStat>
      <VariantStat>
        <StatNumber>{variant.statistics.conversions}</StatNumber>
        <StatLabel>Conversions</StatLabel>
      </VariantStat>
      <VariantStat>
        <StatNumber>
          {variant.statistics.conversion_rate
            ? `${(variant.statistics.conversion_rate * 100).toFixed(2)}%`
            : "—"}
        </StatNumber>
        <StatLabel>Rate</StatLabel>
      </VariantStat>
      {variant.name !== "control" && (
        <>
          <VariantStat>
            <StatNumber>
              {variant.statistics.z_score
                ? variant.statistics.z_score.toFixed(2)
                : "—"}
            </StatNumber>
            <StatLabel>Z-score</StatLabel>
          </VariantStat>
          <VariantStat>
            <StatNumber>
              {variant.statistics.confidence
                ? `${variant.statistics.confidence * 100}%`
                : "—"}
            </StatNumber>
            <StatLabel>Confidence</StatLabel>
          </VariantStat>
        </>
      )}
      <Progress
        gridRow={2}
        gridColumn={2}
        size="sm"
        rounded="full"
        colorScheme={color}
        bg="brandPalette.neutral.mid"
        value={(variant.statistics.conversion_rate ?? 0) * 100}
        mr={6}
      />
      <ButtonGroup gridColumn={-2} gridRow={1}>
        {pinned ? (
          <Tooltip
            label={
              <>
                Showing <code>{variant.name}</code> to all visitors
              </>
            }
          >
            <IconButton
              icon={<PinFilledIcon />}
              aria-label="Unpin this variant"
              variant="solid"
              colorScheme={color}
              onClick={() => onPin(null)}
            />
          </Tooltip>
        ) : (
          <Tooltip
            label={
              <>
                Show <code>{variant.name}</code> to all visitors
              </>
            }
          >
            <IconButton
              icon={<PinIcon />}
              aria-label="Pin this variant"
              variant="ghost"
              color="brandPalette.neutral.dark"
              mixBlendMode="multiply"
              onClick={() => onPin(variant)}
            />
          </Tooltip>
        )}
      </ButtonGroup>
    </Grid>
  )
}

const VariantStat: React.FC<StatProps> = (props) => (
  <Stat
    {...props}
    display="grid"
    gridTemplateRows="subgrid"
    gridRow="1 / span 2"
    sx={{ dl: { display: "contents" }, dt: { color: "text.subtlest" } }}
  />
)
