import { ResponseSectionFigmaTask } from "Types"

/**
 * A task will be considered to have reached the goal screen when any of the screens on the path
 * match the goal node.
 *
 * Even though in theory a task flow prototype wants the goal screen as the last screen,
 * in practice other things like overlays can interfere with this. In the test-taking interface,
 * the "continue" button appears as soon as the goal screen is reached, so this is a safe bet.
 *
 * See SAM-952 for reasoning, and this Slack thread:
 * https://usabilityhub.slack.com/archives/C2R5Y4ZK9/p1736456260490509
 */
export function hasGoalScreenBeenReached(
  task: ResponseSectionFigmaTask,
  goalScreenId: string | null
) {
  if (!goalScreenId) return false

  return task.screens.some(
    (screen) => screen.presented_node_id === goalScreenId
  )
}
