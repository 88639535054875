import {
  Box,
  Flex,
  Grid,
  GridProps,
  Icon,
  useMediaQuery,
} from "@chakra-ui/react"
import { Button, Heading } from "DesignSystem/components"
import { ProjectsIcon } from "Icons/ProjectsIcon"
import { cqContainer } from "Shared/helpers/containerQuerySx"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { NewProject } from "~/api/generated/usabilityhubSchemas"
import { ROUTES } from "../routes"
import { CreateProjectModal } from "./CreateProjectModal"
import { ProjectCard } from "./ProjectCard"
import { ProjectListSkeleton } from "./ProjectListSkeleton"
import { recentProjectsGridSxProps } from "./projectListGridBreakpoints"

type Props = {
  projects: NewProject[]
  isLoading: boolean
}

export const RecentProjectList: React.FC<Props> = ({ projects, isLoading }) => {
  const isUserAllowedToCreateTests = useCurrentUser().can_manage_tests
  const { open: openCreateProjectModal } = useModal(CreateProjectModal)
  const [isLargerThan48em] = useMediaQuery("(min-width: 48em)", { ssr: false })

  const gridProps: GridProps = {
    templateColumns: "1fr",
    gap: 4,
    sx: recentProjectsGridSxProps,
  }

  return (
    <>
      <Flex justifyContent="space-between" align="flex-end">
        <Flex align="center" gap={3}>
          <Heading
            as="h2"
            textStyle="ds.display.primary"
            color="ds.text.default"
          >
            Recent projects
          </Heading>
        </Flex>
        <Button
          as={RouterLink}
          variant="secondary"
          to={ROUTES.PROJECTS.path}
          leftIcon={<Icon as={ProjectsIcon} />}
        >
          {isLargerThan48em ? "View all projects" : "View all"}
        </Button>
      </Flex>

      <Box mt={6} mb={8}>
        {isLoading ? (
          <Box sx={cqContainer()}>
            <ProjectListSkeleton numberOfItems={5} gridProps={gridProps} />
          </Box>
        ) : projects.length > 0 ? (
          <Box sx={cqContainer()}>
            <Grid {...gridProps}>
              {projects.map((project) => (
                <ProjectCard key={project.id} project={project} />
              ))}
            </Grid>
          </Box>
        ) : (
          <EmptyState
            isUserAllowedToCreateTests={isUserAllowedToCreateTests}
            openCreateProjectModal={openCreateProjectModal}
          />
        )}
      </Box>
    </>
  )
}

type EmptyStateProps = {
  isUserAllowedToCreateTests: boolean
  openCreateProjectModal: ReturnType<typeof useModal>["open"]
}

const EmptyState: React.FC<EmptyStateProps> = ({
  isUserAllowedToCreateTests,
  openCreateProjectModal,
}) => {
  return (
    <Flex
      flexDirection="column"
      gap={6}
      alignItems="center"
      justifyContent="center"
      // The extra 2px is to avoid the jump (from borders) when switching to/from the skeleton or card list
      minH="calc(9.5rem + 2px)"
      bg="ds.background.neutral.resting"
      rounded="16px"
      py={4}
    >
      <Heading
        as="h3"
        textStyle="ds.display.secondary"
        textAlign="center"
        mx={4}
      >
        {isUserAllowedToCreateTests
          ? "Organize all your studies with projects"
          : "Projects will be shown here"}
      </Heading>
      {isUserAllowedToCreateTests && (
        <Button onClick={openCreateProjectModal}>
          Create your first project
        </Button>
      )}
    </Flex>
  )
}
