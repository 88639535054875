import { Box, Icon, Text } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"

import { ClickFilledIcon } from "Icons/ClickFilledIcon"
import { LogicIcon } from "Icons/LogicIcon"
import { QuestionCheckboxIcon } from "Icons/QuestionCheckboxIcon"
import { QuestionRadioIcon } from "Icons/QuestionRadioIcon"
import { QuestionRankedIcon } from "Icons/QuestionRankedIcon"
import { TagIcon } from "Icons/TagIcon"
import { TestCardSortIcon } from "Icons/TestCardSortIcon"
import { TestPreferenceIcon } from "Icons/TestPreferenceIcon"
import { TestPrototypeIcon } from "Icons/TestPrototypeIcon"
import { getQuestionPrefix } from "Utilities/usability-test-section-question"

export type FilterValue = {
  filterId: string
  type:
    | "answer-checkbox"
    | "answer-radio"
    | "common-path"
    | "ranked"
    | "tag"
    | "preference"
    | "hitzone"
    | "card-sort"
    | "tree-test"
    | "reached-goal-screen"
    | "prototype-node"
    | "screener-checkbox"
    | "screener-radio"
  stepIndex?: number
  sectionIndex: number
  questionIndex?: number
  label: ReactNode
}

const getFilterPrefix = ({
  sectionIndex,
  stepIndex,
  questionIndex,
  type,
}: FilterValue) => {
  if (
    (type === "screener-checkbox" || type === "screener-radio") &&
    questionIndex !== undefined
  ) {
    return `Q${questionIndex + 1}`
  }

  if (stepIndex === undefined && questionIndex === undefined) {
    return sectionIndex + 1
  }

  if (stepIndex === undefined) {
    return getQuestionPrefix(sectionIndex + 1, questionIndex!, true)
  }

  if (questionIndex === undefined) {
    return `${sectionIndex + 1}/Step ${stepIndex + 1}`
  }

  return `${getQuestionPrefix(sectionIndex + 1, questionIndex, true)}/Step ${
    stepIndex + 1
  }`
}

interface FilterValueIconProps {
  type: FilterValue["type"]
}

const FilterValueIcon: FC<React.PropsWithChildren<FilterValueIconProps>> = ({
  type,
}) => {
  if (type === "hitzone") return <Icon as={ClickFilledIcon} />
  if (type === "preference") return <Icon as={TestPreferenceIcon} />
  if (
    type === "common-path" ||
    type === "reached-goal-screen" ||
    type === "prototype-node"
  )
    return <Icon as={TestPrototypeIcon} color="gray.400" />
  if (type === "ranked") return <Icon as={QuestionRankedIcon} />
  if (type === "tag") return <Icon as={TagIcon} />
  if (type === "answer-checkbox") return <Icon as={QuestionCheckboxIcon} />
  if (type === "answer-radio") return <Icon as={QuestionRadioIcon} />
  if (type === "card-sort") return <Icon as={TestCardSortIcon} />
  if (type === "tree-test") return <Icon as={LogicIcon} />
  if (type === "screener-checkbox") return <Icon as={QuestionCheckboxIcon} />
  if (type === "screener-radio") return <Icon as={QuestionRadioIcon} />

  throw new Error(`${type} is not supported`)
}

interface Props {
  filterValue: FilterValue
}

export const FilterOptionLabel: FC<React.PropsWithChildren<Props>> = ({
  filterValue,
}) => {
  const prefix = getFilterPrefix(filterValue)

  return (
    <Box as="div" display="flex" alignItems="center" overflow="hidden">
      <FilterValueIcon type={filterValue.type} />

      <Text
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        as="span"
        marginLeft="0.5em"
        title={String(filterValue.label)}
      >
        {prefix}: {filterValue.label}
      </Text>
    </Box>
  )
}
