import {
  Box,
  HStack,
  Link,
  ListItem,
  Spacer,
  Spinner,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import Constants from "Constants/shared.json"
import { Alert, Badge, Button, Heading } from "DesignSystem/components"
import { Card, CardBody } from "Shared/components/Card/Card"
import { useModal } from "Utilities/modals/use-modal"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import {
  fetchEvaluateAutomaticPurchase,
  useGetAutomaticPurchase,
} from "~/api/generated/usabilityhub-components"
import { AutoTopUpDeleteModal } from "./AutoTopUpDeleteModal"
import { AutoTopUpEditModal } from "./AutoTopUpEditModal"

export const AutoTopUp: React.FC = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { data, isLoading, isError } = useGetAutomaticPurchase({})

  const { open: showAutoTopUpEditModal } = useModal(AutoTopUpEditModal)
  const { open: showAutoTopUpDeleteModal } = useModal(AutoTopUpDeleteModal)

  const onRetry = async () => {
    await fetchEvaluateAutomaticPurchase({})
      .catch(() => {
        toast({
          title: "Auto top-up failed",
          description: "Please check your payment method and try again.",
          status: "error",
        })
      })
      .then((data) => {
        if (data) {
          toast({
            title: "Auto top-up successful",
            description: `${pluralizeWithCount(data.amount, "credit", "credits")} have been added to your account.`,
            status: "success",
          })

          return queryClient.invalidateQueries([
            "api",
            "credits",
            "automatic_purchase",
          ])
        }
      })
  }

  if (isLoading) return <Spinner />
  if (isError)
    return (
      <Alert
        mt={3}
        status="danger"
        description="Couldn't load auto top-up data. If this persists please contact support."
      />
    )

  return (
    <Box mt={3}>
      <Card>
        <CardBody direction="column" flexWrap="wrap" spacing={6}>
          <HStack>
            <Heading as="h1" textStyle="ds.heading.primary">
              Auto-top up
            </Heading>
            <Spacer />
            <Badge
              label={
                data.automatic_purchase
                  ? data.automatic_purchase.error_message
                    ? "ACTION REQUIRED"
                    : "ACTIVE"
                  : "OFF"
              }
              colorScheme={
                data.automatic_purchase &&
                !data.automatic_purchase.error_message
                  ? "success"
                  : "default"
              }
            />
          </HStack>

          <Text textStyle="ds.paragraph.emphasized">
            Automatically purchase credits when your balance falls below a set
            amount.
          </Text>

          {data.automatic_purchase ? (
            <>
              {data.automatic_purchase?.error_message ? (
                <Alert
                  title="Auto top-up issue"
                  description={
                    <Box>
                      We were unable to process your auto top-up. This might
                      have happened because:
                      <UnorderedList paddingLeft={3} marginY={3}>
                        <ListItem>
                          Your payment method needs to be updated.
                        </ListItem>
                        <ListItem>
                          There are insufficient funds in your account.
                        </ListItem>
                      </UnorderedList>
                      You can update your payment method in the settings page,
                      or for further assistance{" "}
                      <Link
                        href={`mailto:${Constants.CUSTOMER_SUPPORT_EMAIL_ADDRESS}`}
                        // force styling to override chakra's default link styling
                        style={{
                          color: "#061d29",
                          textDecoration: "underline",
                        }}
                      >
                        contact support
                      </Link>
                    </Box>
                  }
                  actions={[
                    {
                      label: "Retry auto top-up",
                      onClick: onRetry,
                    },
                  ]}
                  status="danger"
                />
              ) : (
                <Alert
                  description={`We will automatically add ${pluralizeWithCount(data.automatic_purchase.amount, "credit", "credits")},
                  when your balance drops below ${pluralizeWithCount(data.automatic_purchase.threshold, "credit", "credits")}.`}
                  status="success"
                />
              )}

              <HStack>
                <Button width="fit-content" onClick={showAutoTopUpEditModal}>
                  Edit settings
                </Button>

                <Button
                  variant="danger-subtle"
                  width="fit-content"
                  onClick={showAutoTopUpDeleteModal}
                >
                  Turn off
                </Button>
              </HStack>
            </>
          ) : (
            <Button width="fit-content" onClick={showAutoTopUpEditModal}>
              Set up auto top-up
            </Button>
          )}
        </CardBody>
      </Card>
    </Box>
  )
}
