import { State } from "Redux/app-store"
import React from "react"
import { useSelector } from "react-redux"
import { RecordingsTab } from "./RecordingsTab"

type Props = {
  taskId: string
}

export const TaskRecordingsTab: React.FC<Props> = ({ taskId }) => {
  const recordings = useSelector(
    (state: State) =>
      state.testResults?.responseSectionLiveWebsiteTestTaskRecordings
  )

  if (!recordings) {
    return null
  }

  const recordingsForTask = recordings.filter(
    (r) => r.live_website_test_task_id === taskId
  )

  return <RecordingsTab recordings={recordingsForTask} />
}
