import {
  ButtonProps,
  Alert as ChakraAlert,
  AlertDescription as ChakraAlertDescription,
  AlertTitle as ChakraAlertTitle,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  Text,
  forwardRef,
} from "@chakra-ui/react"
import { tokens } from "DesignSystem/tokens"
import { AlertCircleFilledIcon } from "Icons/AlertCircleFilledIcon"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { CloseIcon } from "Icons/CloseIcon"
import { InfoFilledIcon } from "Icons/InfoFilledIcon"
import { ProIcon } from "Icons/ProIcon"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import React from "react"
import { Button } from "./Button"
import { IconButton } from "./IconButton"

type CommonProps = {
  title?: React.ReactNode
  variant?: AlertVariant
  description: React.ReactNode
  actions?: AlertAction[]
  onClose?: () => void
}

// The "default" status comes with no built-in icon, so you must provide one
type DefaultStatusProps = {
  status?: "default"
  icon: React.FC<IconProps>
}

// The other statuses have built-in icons, so you can't provide one
type OtherStatusProps = {
  status: Exclude<AlertStatus, "default">
  icon?: never
}

export type AlertProps = CommonProps &
  (DefaultStatusProps | OtherStatusProps) &
  FlexProps

const statusIcons: Record<
  Exclude<AlertStatus, "default">,
  React.FC<IconProps>
> = {
  info: InfoFilledIcon,
  success: CheckCircleFilledIcon,
  warning: WarningFilledIcon,
  danger: AlertCircleFilledIcon,
  upgrade: ProIcon,
}

const statuses = {
  default: {
    bg: "ds.background.neutral.resting",
    color: "ds.icon.subtle",
  },
  info: {
    bg: "ds.background.information.subtle.resting",
    color: "ds.icon.info",
  },
  success: {
    bg: "ds.background.success.subtle.resting",
    color: "ds.icon.success",
  },
  warning: {
    bg: "ds.background.warning.subtle.resting",
    color: "ds.icon.warning",
  },
  danger: {
    bg: "ds.background.danger.subtle.resting",
    color: "ds.icon.danger",
  },
  upgrade: {
    bg: "ds.background.upgrade.subtle.resting",
    color: "ds.icon.upgrade",
  },
} as const

type AlertVariant = "default" | "banner"
type AlertStatus = keyof typeof statuses
type AlertAction = { label: React.ReactNode } & Pick<
  ButtonProps,
  | "onClick"
  | "leftIcon"
  | "variant"
  | "isDisabled"
  // For when we need to wrap the Alert in legacy <form> with <button type="submit">
  | "type"
>

const chakraDefaultsOverride = {
  // Chakra bakes in a pseudoelement here for the icon space, get rid of it
  _before: { display: "none" },
  // The Chakra alert uses Grid, but we need more fluid responsiveness so we're using Flexbox
  gridTemplateAreas: "none",
  gridTemplateColumns: "none",
}

/**
 * - Use `title` for the bigger bold title.
 * - Use `description` for the smaller description text.
 * - If you only need one of the above, `description` is the (required) default.
 * - `variant` controls the shape:  `"default" | "banner"`
 * - `status` controls the color and icon: `"default" | "info" | "success" | "warning" | "danger" | "upgrade"`
 * - `icon` controls the icon. It should be a React component reference like `FooIcon`.
 *   - If you use the default (or no) status, an `icon` prop is required.
 *   - If you use a non-default status, an `icon` prop is not allowed.
 * - Pass an `onClose` function to get a close button.
 * - Use `actions` for buttons. These are a array of custom props (see example below).
 *   - The size of the buttons is locked in as "compact", but you can choose a variant and icon.
 *   - If you're using the Alert inside a legacy <form> and need a button with
 *     `type="submit"`, you can specify the `type: "submit"` prop. It isn't
 *     necessary to do `type: "button"` because this is the default.
 *   - The icon passed to an action must be a JSX element `<LikeThis />` (and not a component reference `LikeThis`) because it's from the Button API.
 *
 * @example
 * <Alert
 *   // No variant prop = default variant
 *   title="I’m a default variant with the “info” status"
 *   description="This is the description."
 *   status="info"
 *   // These will give you default buttons
 *   actions={[
 *     { label: "Understood", onClick: () => {} },
 *     { label: "No thanks", onClick: () => {} },
 *   ]}
 * />
 * <Alert
 *   variant="banner"
 *   // No status prop = default status
 *   title="I’m a banner variant with the default status"
 *   description="This is the description."
 *   // Icon is required because it's a default status
 *   icon={MyFavoriteIcon}
 *   // Pass an onClose to show the close/dismiss button
 *   onClose={() => {}}
 * />
 *
 * // Example of actions props ("label" & a subset of ButtonProps)
 * actions={[
 *   { label: "Cool", onClick: () => {}, variant: "primary", leftIcon: <ThumbsUpIcon /> },
 *   { label: "Nope", onClick: () => {}, variant: "secondary" },
 * ]}
 */
export const Alert = forwardRef<AlertProps, "div">(
  (
    {
      title,
      variant = "default",
      status = "default",
      icon,
      description,
      actions,
      onClose,
      ...props
    },
    ref
  ) => {
    const statusProps = statuses[status]
    icon = status === "default" ? icon : statusIcons[status]

    const isDismissible = !!onClose

    const titleTextStyle =
      tokens.textStyles.ds.heading[
        variant === "banner" ? "secondary" : "primary"
      ]

    return (
      <ChakraAlert
        ref={ref}
        {...chakraDefaultsOverride}
        rounded={variant === "banner" ? 0 : "8px"}
        display="flex"
        alignItems="stretch"
        columnGap={3}
        p={0}
        bg={statusProps.bg}
        color="ds.text.default"
        {...props}
      >
        <Flex
          alignItems="stretch"
          flexGrow={1}
          columnGap={3}
          p={4}
          // We use the column-gap for spacing before the X button
          pe={isDismissible ? 0 : 4}
        >
          <Flex
            gridArea="icon"
            justifyContent="center"
            alignItems="flex-start"
            width={6}
            flexShrink={0}
            pt={0.5}
          >
            <Icon as={icon} boxSize={5} color={statusProps.color} />
          </Flex>
          <Flex
            flexDirection={variant === "banner" ? "row" : "column"}
            flexWrap="wrap"
            flexGrow={1}
            alignItems="baseline"
            columnGap={2}
            rowGap={variant === "banner" ? 1 : 2}
          >
            {title && (
              <ChakraAlertTitle
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                margin={0}
                // Maintain consistent title height when wrapping or when there's no description
                minH={6}
                {...titleTextStyle}
              >
                {title}
              </ChakraAlertTitle>
            )}
            {description && (
              <DescriptionAndActions
                variant={variant}
                description={description}
                actions={actions}
              />
            )}
          </Flex>
        </Flex>
        {isDismissible && (
          <DismissibleSection variant={variant} onClose={onClose} />
        )}
      </ChakraAlert>
    )
  }
)

const DescriptionAndActions: React.FC<
  Pick<AlertProps, "variant" | "description" | "actions">
> = ({ variant, description, actions }) => (
  <ChakraAlertDescription
    display="flex"
    flexGrow={1}
    {...tokens.textStyles.ds.paragraph.primary}
  >
    <Flex
      flexWrap="wrap"
      alignItems="stretch"
      justifyContent={variant === "banner" ? "space-between" : "flex-start"}
      flexDirection={variant === "banner" ? "row" : "column"}
      flexGrow={1}
      gap={3}
    >
      {/* Maintain consistent description height when wrapping or when there's no title */}
      <Flex minH={6}>
        <Text alignSelf="center">{description}</Text>
      </Flex>
      {actions && (
        <Flex gap={1.5} flexWrap="wrap">
          {actions.map((a, idx) => (
            // Don't need to specify type="button" because Chakra adds this by default
            <Button key={`alert-action-${idx}`} size="compact" {...a}>
              {a.label}
            </Button>
          ))}
        </Flex>
      )}
    </Flex>
  </ChakraAlertDescription>
)

const DismissibleSection: React.FC<Pick<AlertProps, "variant" | "onClose">> = ({
  variant,
  onClose,
}) => (
  <Flex
    py={3}
    pe={3}
    justifyContent="center"
    alignItems={variant === "banner" ? "center" : "flex-start"}
    flexShrink={0}
  >
    <IconButton
      icon={<CloseIcon />}
      aria-label="Close"
      size="flush"
      variant="secondary"
      onClick={onClose}
    />
  </Flex>
)
