import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TemplateIcon = createIcon({
  displayName: "TemplateIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      d="M4.568 2h3.864c.252 0 .498 0 .706.017.229.019.499.063.77.201a2 2 0 0 1 .874.874c.138.271.182.541.201.77.017.208.017.454.017.706v3.864c0 .252 0 .498-.017.706a2.022 2.022 0 0 1-.201.77 2 2 0 0 1-.874.874 2.022 2.022 0 0 1-.77.201C8.93 11 8.684 11 8.432 11H4.568c-.252 0-.498 0-.706-.017a2.022 2.022 0 0 1-.77-.201 2 2 0 0 1-.874-.874 2.022 2.022 0 0 1-.201-.77C2 8.93 2 8.684 2 8.432V4.568c0-.252 0-.498.017-.706.019-.229.063-.499.201-.77a2 2 0 0 1 .874-.874c.271-.138.541-.182.77-.201C4.07 2 4.316 2 4.568 2Zm-.557 2.011v.014C4 4.14 4 4.303 4 4.6v3.8a8.185 8.185 0 0 0 .011.589h.014C4.14 9 4.303 9 4.6 9h3.8a8.183 8.183 0 0 0 .589-.011v-.014C9 8.86 9 8.697 9 8.4V4.6a8.184 8.184 0 0 0-.011-.589h-.014A8.185 8.185 0 0 0 8.4 4H4.6a8.186 8.186 0 0 0-.589.011ZM17.5 15a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM13 17.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM6.5 13a1 1 0 0 1 1 1v2.5H10a1 1 0 1 1 0 2H7.5V21a1 1 0 1 1-2 0v-2.5H3a1 1 0 1 1 0-2h2.5V14a1 1 0 0 1 1-1ZM13.42 8.256l.014-.028 2.601-5.025.014-.027a6.86 6.86 0 0 1 .259-.47c.082-.131.253-.388.56-.55a1.352 1.352 0 0 1 1.264 0c.307.162.478.419.56.55.086.136.175.308.259.47l.014.027 2.6 5.025.015.028c.098.19.198.381.268.548.072.174.168.441.15.759-.022.374-.174.77-.488 1.063-.291.27-.63.332-.821.354-.175.02-.38.02-.554.02h-5.27c-.174 0-.379 0-.554-.02a1.41 1.41 0 0 1-.82-.354 1.574 1.574 0 0 1-.339-1.822c.07-.167.17-.359.268-.548ZM15.287 9h4.426L17.5 4.724 15.287 9Zm5.176-.007h.003-.003Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  ),
})
