import { Box, Grid, HStack, Icon, Text } from "@chakra-ui/react"
import { IconButton } from "Components/icon-button/icon-button"
import { Badge } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults/Badge"
import { Path } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults/Path"
import { TimeFilledIcon } from "Icons/TimeFilledIcon"
import { UsersFilledIcon } from "Icons/UsersFilledIcon"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { floorMsToS } from "Utilities/time"
import React from "react"
import { CommonPath, IndividualPath } from "./usePaths"

type PathResultProps<T extends IndividualPath | CommonPath> = {
  path: T
} & (T extends IndividualPath
  ? { onParticipantClick: (responseSectionId: number) => void }
  : { onParticipantClick?: never })

const isCommonPath = (path: IndividualPath | CommonPath): path is CommonPath =>
  "participants" in path

export const PathResult = <T extends IndividualPath | CommonPath>({
  path,
  onParticipantClick,
  ...props
}: PathResultProps<T>) => (
  <Grid
    p={6}
    border="1px solid"
    borderColor="gray.200"
    rounded={8}
    gridTemplate={'"path path" auto "badge details" auto / auto auto'}
    gap={4}
    data-path-id={path.id}
    {...props}
  >
    <Path path={path.nodes} gridArea="path" />
    <Box gridArea="badge">
      <Badge result={path.result} directness={path.directness} />
    </Box>
    <HStack gridArea="details" justifySelf="end" gap={3}>
      <HStack alignItems="center" fontSize="sm" color="text.subtlest" gap={1}>
        <TimeFilledIcon />
        {!!path?.time && (
          <Text>
            {isCommonPath(path) && "Average "}
            {floorMsToS(path.time)} seconds
          </Text>
        )}
      </HStack>
      {isCommonPath(path) ? (
        <HStack alignItems="center" fontSize="sm" color="text.subtlest" gap={1}>
          <Icon as={UsersFilledIcon} />
          <Text>{`${path.participants} participant${
            path.participants > 1 ? "s" : ""
          }`}</Text>
        </HStack>
      ) : (
        <IconButton
          aria-label="View participant"
          icon={<UserResponseIcon />}
          onClick={() => onParticipantClick?.(path.responseSectionId)}
        />
      )}
    </HStack>
  </Grid>
)
