import React from "react"
import { useLocation, useMatch } from "react-router"
import { Link } from "react-router-dom"

import { Step, Steps, StepsDivider } from "Shared/components/Steps/Steps"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { ROUTES } from "UsabilityHub/views/routes"

export const ModeratedStudyStepsSubNavigation: React.FC = () => {
  const { moderatedStudyId, moderatedStudySummary } = useModeratedStudyContext()

  const location = useLocation()

  const editRouteMatch = useMatch(
    ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId }) + "/*"
  )
  const recruitRouteMatch = useMatch(
    ROUTES.INTERVIEW.RECRUIT.buildPath({ moderatedStudyId }) + "/*"
  )
  const applicationsRouteMatch = useMatch(
    ROUTES.INTERVIEW.APPLICANTS.buildPath({ moderatedStudyId })
  )
  const sessionsRouteMatch = useMatch(
    ROUTES.INTERVIEW.SESSIONS.buildPath({ moderatedStudyId })
  )
  const resultsRouteMatch = location.pathname.startsWith(
    ROUTES.INTERVIEW.RECORDINGS.buildPath({ moderatedStudyId })
  )

  return (
    <Steps
      justifyContent={{ base: "flex-start", lg: "center" }}
      px={{ base: 6, sm: 8, lg: 10 }}
      overflowX="auto"
    >
      <Link to={ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId })}>
        <Step label="Setup" isActive={!!editRouteMatch} />
      </Link>
      <StepsDivider />
      <Link to={ROUTES.INTERVIEW.RECRUIT.buildPath({ moderatedStudyId })}>
        <Step label="Recruit" isActive={!!recruitRouteMatch} />
      </Link>
      <StepsDivider />
      <Link to={ROUTES.INTERVIEW.APPLICANTS.buildPath({ moderatedStudyId })}>
        <Step
          label="Applicants"
          isActive={!!applicationsRouteMatch}
          count={moderatedStudySummary?.applications_count}
        />
      </Link>
      <StepsDivider />
      <Link to={ROUTES.INTERVIEW.SESSIONS.buildPath({ moderatedStudyId })}>
        <Step
          label="Sessions"
          isActive={!!sessionsRouteMatch}
          count={moderatedStudySummary?.bookings_count}
        />
      </Link>
      <StepsDivider />
      <Link to={ROUTES.INTERVIEW.RECORDINGS.buildPath({ moderatedStudyId })}>
        <Step
          label="Recordings"
          isActive={!!resultsRouteMatch}
          count={moderatedStudySummary?.recordings_count}
        />
      </Link>
    </Steps>
  )
}
