import classNames from "classnames"
import React from "react"

import styles from "Components/column-form/column-form.module.scss"
import { CloseIcon } from "Icons/CloseIcon"

interface Props {
  onClick: () => void
  disabled?: boolean
  primary?: boolean
}

export const ClearButton: React.FC<Props> = ({
  onClick,
  disabled,
  primary,
}) => (
  <button
    onClick={onClick}
    className={classNames(styles.clearButton, primary && styles.primary)}
    disabled={disabled}
    type="reset"
  >
    <CloseIcon boxSize={5} />
  </button>
)
