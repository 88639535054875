import { Button } from "@chakra-ui/react"
import classNames from "classnames"
import React, { ReactElement, ReactNode, useEffect, useState } from "react"

import { BasicFlash } from "Components/basic-flash/basic-flash"
import styles from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout.module.scss"

import { MaximizeIcon } from "Icons/MaximizeIcon"
import { MinimizeIcon } from "Icons/MinimizeIcon"
import { ReportIcon } from "Icons/ReportIcon"
import { BottomPanelPortal } from "./BottomPanelPortal"

// NOTE: Using string literals here for storybook `select` helpers, which
//       requires string values.
export enum LayoutState {
  Split = "Split",
  ZoomableSplit = "ZoomableSplit",
  FocusMedia = "FocusMedia",
  FocusQuestion = "FocusQuestion",
  StartStop = "StartStop",
}

export interface AppearanceProps {
  layoutState: LayoutState
  mediaContent: ReactNode
  questionContent: ReactElement | null
  isReportButtonVisible: boolean
}

interface CallbackProps {
  onReport: () => void
}

interface Props extends AppearanceProps, CallbackProps {
  bannerChildren: ReactNode
}

const ScrollablePanel: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <div className={styles.panelScroller}>
    <div className={styles.panelInternal}>{children}</div>
  </div>
)

export const UsabilityTestLayout: React.FC<Props> = ({
  isReportButtonVisible,
  layoutState,
  mediaContent,
  onReport,
  questionContent,
  bannerChildren,
}) => {
  const [isZoomed, setIsZoomed] = useState(false)

  const handleToggleZoom = () => {
    if (layoutState === LayoutState.ZoomableSplit) {
      setIsZoomed((prevZoomed) => !prevZoomed)
    }
  }

  useEffect(() => {
    if (layoutState !== LayoutState.ZoomableSplit) {
      setIsZoomed(false)
    }
  }, [layoutState])

  const isMediaFocused =
    layoutState === LayoutState.FocusMedia ||
    (layoutState === LayoutState.ZoomableSplit && isZoomed)
  const isQuestionFocused = layoutState === LayoutState.FocusQuestion

  const mediaClass = classNames(styles.mediaPanel, {
    [styles.isFocused]: isMediaFocused,
    [styles.isHidden]: isQuestionFocused,
    [styles.isMobileHidden]: layoutState === LayoutState.StartStop,
  })
  const questionClass = classNames(styles.questionPanel, {
    [styles.isFocused]: isQuestionFocused,
    [styles.isHidden]: isMediaFocused,
    [styles.isMobileFocused]: layoutState === LayoutState.StartStop,
  })

  return (
    <div className={styles.container}>
      {bannerChildren}
      <BottomPanelPortal>
        <div className={styles.panels}>
          {isReportButtonVisible && (
            <Button className={styles.panelsButtonReport} onClick={onReport}>
              <ReportIcon />
            </Button>
          )}
          <BasicFlash />
          <div className={mediaClass}>
            {layoutState === LayoutState.ZoomableSplit && (
              <Button
                className={styles.panelsButtonZoom}
                onClick={handleToggleZoom}
              >
                {isZoomed ? <MinimizeIcon /> : <MaximizeIcon />}
              </Button>
            )}
            <ScrollablePanel>{mediaContent}</ScrollablePanel>
          </div>
          <div className={questionClass}>
            {questionContent && (
              <ScrollablePanel key={questionContent.key}>
                {questionContent}
              </ScrollablePanel>
            )}
          </div>
        </div>
      </BottomPanelPortal>
    </div>
  )
}
