import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import zoomIcon from "Images/zoom-logo.png"
import { ZoomSettingsModal } from "UsabilityHub/components/ZoomSettingsModal"
import { useZoomOAuth } from "UsabilityHub/hooks/useZoomOAuth"
import { getDateString } from "Utilities/date-formats"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import {
  GetIntegrationsResponse,
  useDeleteIntegration,
} from "~/api/generated/usabilityhub-components"

interface Props {
  data?: GetIntegrationsResponse
  onConnectDisconnect: () => void | Promise<void>
}

export const ZoomIntegrationStatus: React.FC<
  React.PropsWithChildren<Props>
> = ({ data, onConnectDisconnect }) => {
  const toast = useToast()
  const { open: openZoomSettingsModal } = useModal(
    ZoomSettingsModal,
    "integration-config"
  )
  const { handleZoomOAuth, disconnectZoomOAuth } = useZoomOAuth(
    !!data?.zoom,
    async () => {
      await onConnectDisconnect()
      openZoomSettingsModal({})
    }
  )
  const { mutateAsync: deleteIntegration, isLoading: isDeletingIntegration } =
    useDeleteIntegration({
      onSuccess: async () => {
        toast({
          title: "Disconnected from Zoom",
          status: "success",
        })
        await onConnectDisconnect()

        disconnectZoomOAuth()
      },
    })

  return (
    <Flex gap={4}>
      <Image src={zoomIcon} boxSize={10} mt={2} />
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Zoom
        </Text>
        <Text>
          Connect to Zoom to synchronize meetings on your Interview studies
        </Text>
      </Box>
      <Spacer />

      {data === undefined ? (
        <Spinner size="sm" />
      ) : data.zoom === null ? (
        <Button
          flexShrink={0}
          colorScheme="brand.primary"
          onClick={handleZoomOAuth}
        >
          Connect
        </Button>
      ) : (
        <>
          <Text
            fontSize="xs"
            color="text.secondary"
            textAlign="right"
            flexShrink={0}
          >
            Connected on
            <br />
            {getDateString(data.zoom.created_at, "medium")}
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<Icon as={MenuKebabIcon} color="gray.500" />}
            />
            <MenuList>
              <MenuItem onClick={openZoomSettingsModal}>Configure</MenuItem>
              <MenuItem
                color="red"
                isDisabled={isDeletingIntegration}
                onClick={() => {
                  deleteIntegration({
                    body: { integration: "zoom" },
                  })
                }}
              >
                Disconnect
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </Flex>
  )
}
