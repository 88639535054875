import {
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { TestInstructionIcon } from "Icons/TestInstructionIcon"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import React, { PropsWithChildren } from "react"

export const ScreenerInstructions: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Popover
      trigger="hover"
      placement="right"
      modifiers={[{ name: "preventOverflow", options: { padding: 16 } }]}
    >
      <PopoverTrigger>
        <HStack gap={1} width="max-content">
          <Text>{children}</Text>
          <TestInstructionIcon color="icon.subtle" />
        </HStack>
      </PopoverTrigger>

      <PopoverContent w="25rem">
        <PopoverHeader
          border={0}
          px={4}
          pt={4}
          fontSize="md"
          fontWeight="semibold"
          color="text.subtlest"
        >
          Screener
        </PopoverHeader>
        <PopoverBody fontSize="sm" fontWeight="normal" px={4} pb={4}>
          <Text mb={4}>
            Add screener questions to qualify participants based on certain
            targeting criteria. This can be applied to recruiting with your own
            link or recruiting from the Lyssna panel.
          </Text>
          <Text mb={4}>
            If recruiting from the Lyssna panel, applying a screener allows you
            to target criteria that is not already offered on our order form.
            There is an additional cost of 2 credits per response.
          </Text>
          <Text
            bg="brandPalette.neutral.light"
            rounded={4}
            p={3}
            color="text.subtlest"
          >
            Read more about{" "}
            <ExternalLink
              href={Constants.HELP_CENTER_SCREENER_BEST_PRACTICES_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              screener best practices
            </ExternalLink>
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
