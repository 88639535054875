import { BoxProps } from "@chakra-ui/react"
import { RawScreenshot } from "Types"
import React, { useCallback, useEffect } from "react"

import { useTestTakingContext } from "UsabilityHub/components/UsabilityTest/context/TestTakingContext"
import { AudioScreenshot } from "./AudioScreenshot"
import { ImageScreenshot } from "./ImageScreenshot"
import { VideoScreenshot } from "./VideoScreenshot"

export type UnscaledScreenshotProps = BoxProps & {
  screenshot: RawScreenshot
  canSkipAheadOnFirstWatch: boolean
  dispatchScreenshotState?: () => void
}

export const UnscaledScreenshot: React.FC<
  React.PropsWithChildren<UnscaledScreenshotProps>
> = ({ screenshot, canSkipAheadOnFirstWatch, ...boxProps }) => {
  // The viewed state is only used during test taking
  const { markScreenshotAsViewed, screenshotsViewed } = useTestTakingContext()
  const onScreenshotViewed = useCallback(() => {
    markScreenshotAsViewed(screenshot.id!)
  }, [screenshot])
  useEffect(() => {
    // Images are considered 'viewed' immediately
    if (screenshot.media_type === "image") {
      onScreenshotViewed()
    }
  }, [onScreenshotViewed, screenshot])
  switch (screenshot.media_type) {
    case "image":
      return <ImageScreenshot screenshot={screenshot} {...boxProps} />
    case "video":
      return (
        <VideoScreenshot
          onMediaEnded={onScreenshotViewed}
          screenshot={screenshot}
          canSkipAhead={
            canSkipAheadOnFirstWatch || screenshotsViewed[screenshot.id!]
          }
          {...boxProps}
        />
      )
    case "audio":
      return (
        <AudioScreenshot
          onMediaEnded={onScreenshotViewed}
          screenshot={screenshot}
          canSkipAhead={
            canSkipAheadOnFirstWatch || screenshotsViewed[screenshot.id!]
          }
          {...boxProps}
        />
      )
  }
}
