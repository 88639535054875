import { Spinner, Text } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import {
  getEstimatedDurationRange,
  getHasEstimationError,
  getIsEstimationLoading,
} from "Redux/reducers/test-builder/selectors/estimate"
import { formatMinutesRange } from "Utilities/duration"
import { capitalizeFirstLetter } from "Utilities/string"

import { TimeIcon } from "Icons/TimeIcon"
import { EstimateTooltip } from "./EstimateTooltip"

export const DurationEstimate: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const isLoading = useSelector(getIsEstimationLoading)
  const error = useSelector(getHasEstimationError)
  const estimatedDurationRange = useSelector(getEstimatedDurationRange)

  // If fetching the estimations resulted in an error, dont display anything.
  if (error) {
    return <Text>Unable to get estimate</Text>
  }

  // If there is no data and its loading only the loading text.
  if (isLoading && !estimatedDurationRange) {
    return <Text>Loading&hellip;</Text>
  }

  // This should only happen on the first render when loading has no commences yet and there is also no data
  if (!estimatedDurationRange) {
    return null
  }

  // Otherwise show the details.
  return (
    <EstimateTooltip label="This estimate is based on the number and type of test's tasks, questions, and recording requirements.">
      <TimeIcon />
      <Text>
        {capitalizeFirstLetter(
          formatMinutesRange(
            estimatedDurationRange.lower_minutes,
            estimatedDurationRange.upper_minutes,
            true
          )
        )}
      </Text>
      {/* If it is loading new estimations while we already have old estimations, only show a small spinner to indicate to the user that the information is getting updated */}
      {isLoading && <Spinner size="xs" />}
    </EstimateTooltip>
  )
}
