import React from "react"

import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { useSectionContext } from "UsabilityHub/contexts"

import {
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { BarChartIcon } from "Icons/BarChartIcon"
import { RecordingsFilledIcon } from "Icons/RecordingsFilledIcon"
import { TestTreeTestingIcon } from "Icons/TestTreeTestingIcon"
import { UserFilledIcon } from "Icons/UserFilledIcon"
import { UsersFilledIcon } from "Icons/UsersFilledIcon"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import { SectionRecordingsTab } from "../Recordings/SectionRecordingsTab"
import { useSectionRecordings } from "../Recordings/useSectionRecordings"
import { SectionResultsCard } from "../SectionResultsCard"
import { AggregatedResultsProvider } from "./AggregatedResultsProvider"
import { TreeTestAnswers } from "./TreeTestAnswers"
import { TreeTestCommonPaths } from "./TreeTestCommonPaths"
import { TreeTestIndividualPaths } from "./TreeTestIndividualPaths"
import { TreeTestOverview } from "./TreeTestOverview"
import { TreeTestPathDiagram } from "./TreeTestPathDiagram/TreeTestPathDiagram"
import { useCommonPaths, useIndividualPaths } from "./usePaths"

export const TreeTestSectionResultsCard: React.FC = () => {
  const { section } = useSectionContext()
  const treeTest = section.tree_test_attributes

  const commonPaths = useCommonPaths()

  const recordings = useSectionRecordings()
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const showRecordingsTab = recordings.length > 0 && recordingFeatureEnabled

  const individualPaths = useIndividualPaths()

  if (!treeTest) return null

  return (
    <SectionResultsCard>
      <AggregatedResultsProvider>
        <SectionResultsSectionText />
        <TreeTestOverview />
        <Tabs isLazy>
          <TabList>
            <Tab>
              <HStack spacing={2}>
                <BarChartIcon />
                <span>Totals</span>
              </HStack>
            </Tab>
            <Tab>
              <HStack spacing={2}>
                <Icon as={UsersFilledIcon} />
                <span>Common paths {commonPaths.length}</span>
              </HStack>
            </Tab>
            <Tab>
              <HStack spacing={2}>
                <Icon as={UserFilledIcon} />
                <span>Individual paths {individualPaths.length}</span>
              </HStack>
            </Tab>
            <Tab>
              <HStack spacing={2}>
                <TestTreeTestingIcon />
                <span>Path diagram</span>
              </HStack>
            </Tab>
            {showRecordingsTab && (
              <Tab>
                <HStack spacing={2}>
                  <Icon as={RecordingsFilledIcon} />
                  <span>Recordings {recordings.length}</span>
                </HStack>
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <TreeTestAnswers />
            </TabPanel>
            <TabPanel p={0}>
              <TreeTestCommonPaths paths={commonPaths} />
            </TabPanel>
            <TabPanel p={0}>
              <TreeTestIndividualPaths paths={individualPaths} />
            </TabPanel>
            <TabPanel p={0}>
              <TreeTestPathDiagram />
            </TabPanel>
            {showRecordingsTab && (
              <TabPanel
                px={0}
                pt={recordings.length > 0 ? 6 : 8}
                pb={recordings.length > 0 ? 4 : 0}
              >
                <SectionRecordingsTab />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
        <QuestionResultsList />
      </AggregatedResultsProvider>
    </SectionResultsCard>
  )
}
