import { Icon, Text, TextProps, Tooltip } from "@chakra-ui/react"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import React from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { FEATURES } from "./features"

type Feature = (typeof FEATURES)[number]

export const featureCellLabel = (feature: Feature, textProps?: TextProps) => {
  return (
    <Tooltip label={feature.tooltip}>
      <Text
        fontSize="md"
        fontWeight="semibold"
        textDecoration="1px dotted underline"
        cursor="help"
        {...textProps}
      >
        {textProps?.children ?? feature.label}
      </Text>
    </Tooltip>
  )
}
export const featureCellContents = (feature: Feature, plan: Plan) => {
  const value = feature.value(plan)

  if (typeof value === "boolean") {
    return value ? (
      <Icon as={CheckCircleFilledIcon} color="green.500" />
    ) : (
      <Icon as={RemoveFilledIcon} color="gray.300" />
    )
  } else {
    return <Text>{value}</Text>
  }
}
