import { Spinner, extendTheme } from "@chakra-ui/react"

import { components as DSComponents } from "DesignSystem/chakra-theme/components"
import { components as legacyComponents } from "./components"
import { colors as legacyColors } from "./foundations/generators/colors"

import { layerStyles as DSLayerStyles } from "DesignSystem/layerStyles"
import {
  tokens as DSTokens,
  userCrowdThemeOverrideTokens,
} from "DesignSystem/tokens"
import { getIsPanelistDomain } from "Shared/helpers/getIsPanelistDomain"
import { UserCrowdLink } from "./components/link"
import { fonts as legacyFonts } from "./foundations/fonts"
import { sizes } from "./foundations/sizes"
import { space } from "./foundations/space"
import {
  fontWeights as legacyFontWeights,
  textStyles as legacyTextStyles,
} from "./foundations/typography"
import { styles } from "./styles"

// defaultProps only allows setting default size/variant, so this is a (kind of hacky) way to
// default all spinner speeds in the app.
Spinner.defaultProps = { ...Spinner.defaultProps, speed: "1.5s" }

const LyssnaTheme = extendTheme({
  styles,
  sizes,
  space,
  colors: {
    ...legacyColors,
    ...DSTokens["colors"],
  },
  fonts: legacyFonts,
  fontWeights: legacyFontWeights,
  components: {
    ...legacyComponents,
    ...DSComponents,
  },
  textStyles: {
    ...legacyTextStyles,
    ...DSTokens["textStyles"],
  },
  semanticTokens: DSTokens["semanticTokens"],
  shadows: DSTokens["shadows"],
  layerStyles: DSLayerStyles,
})

const UserCrowdTheme = {
  ...LyssnaTheme,
  components: {
    ...LyssnaTheme.components,
    Link: UserCrowdLink,
  },
  semanticTokens: {
    ...LyssnaTheme.semanticTokens,
    ...userCrowdThemeOverrideTokens.semanticTokens,
  },
}

export const theme = getIsPanelistDomain() ? UserCrowdTheme : LyssnaTheme
