import { Heading, Text } from "@chakra-ui/layout"
import { Flex, Link } from "@chakra-ui/react"
import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { State } from "Redux/app-store"
import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"
import { LWT_TASK_DURATION_OPTIONS } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/LiveWebsiteTestSectionCard/LiveWebsiteTask"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { useSectionContext } from "UsabilityHub/contexts"
import { formatPreciseDuration } from "Utilities/time"
import React from "react"
import { useSelector } from "react-redux"
import { TaskRecordingsTab } from "./Recordings/TaskRecordingsTab"
import { SectionResultsCard } from "./SectionResultsCard"

export const LiveWebsiteTestSectionResultsCard: React.FC = () => {
  const { section } = useSectionContext()

  if (!section.live_website_test) {
    throw new TypeError("Live website test not found")
  }

  const tasks = section.live_website_test.live_website_test_tasks
  const allRecordings =
    useSelector(
      (state: State) =>
        state.testResults?.responseSectionLiveWebsiteTestTaskRecordings
    ) ?? []

  return (
    <SectionResultsCard>
      <SectionResultsSectionText />

      {tasks.map((task, taskIndex) => {
        // Find IDs of all questions attached to this task so we can filter the QuestionResultsList to just those answers.
        const questionIdAllowlist = task.live_website_test_task_questions.map(
          (q) => q.usability_test_section_question_id
        )

        const taskRecordings = allRecordings.filter(
          (r) => r.live_website_test_task_id === task.id
        )

        const { min: estimatedMin, max: estimatedMax } =
          LWT_TASK_DURATION_OPTIONS.find(
            (o) => o.max === task.estimated_duration_in_minutes
          ) ?? { min: 0, max: 0 }

        const averageDuration =
          taskRecordings.length > 0
            ? taskRecordings.reduce((acc, r) => acc + r.duration, 0) /
              taskRecordings.length
            : null

        return (
          <Flex key={task.id} direction="column" gap={6}>
            <TestCardDivider />

            <Heading as="h3">Task {taskIndex + 1}</Heading>

            <Text textStyle="ds.heading.primary" color="ds.text.default">
              Instruction
            </Text>

            <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
              {task.instructions}
            </Text>

            <Flex direction="column" gap={2}>
              <Text textStyle="ds.heading.secondary" color="ds.text.default">
                URL
              </Text>

              <Text textStyle="ds.paragraph.primary" color="ds.text.default">
                <Link href={task.url} isExternal>
                  {task.url}
                </Link>
              </Text>
            </Flex>

            <TestCardDivider />

            <Text textStyle="ds.heading.primary" color="ds.text.default">
              Results
            </Text>

            <Flex direction="column" gap={2}>
              <Text textStyle="ds.heading.secondary" color="ds.text.default">
                Average task-completion
              </Text>

              <Text textStyle="ds.paragraph.primary" color="ds.text.default">
                {averageDuration
                  ? formatPreciseDuration(averageDuration * 1000)
                  : "n/a"}{" "}
                (estimated {estimatedMin}-{estimatedMax} mins)
              </Text>

              <TaskRecordingsTab taskId={task.id} />

              <TestCardDivider />

              <Text textStyle="ds.heading.primary" color="ds.text.default">
                Follow-up questions
              </Text>

              <QuestionResultsList questionIdAllowlist={questionIdAllowlist} />
            </Flex>
          </Flex>
        )
      })}
    </SectionResultsCard>
  )
}
