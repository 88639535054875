import { createIcon } from "@chakra-ui/react"
import React from "react"

export const StatusDotIcon = createIcon({
  displayName: "StatusDotIcon",
  viewBox: "0 0 14 14",
  path: (
    <>
      <g id="dot">
        <path
          id="Shape"
          d="M7 3a4 4 0 0 1 0 8 4 4 0 0 1 0-8z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
