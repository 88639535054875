import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
import { Z_INDEX_SUBNAV } from "Constants/zIndexes"

const helpers = createMultiStyleConfigHelpers([
  "container",
  "divider",
  "step",
  "stepIndicator",
  "stepIcon",
])

export const Steps = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: "white",
      fontWeight: "medium",
      height: 12,
      position: "sticky",
      top: 0,
      zIndex: Z_INDEX_SUBNAV,
    },
    divider: {
      width: 0,
      mx: 3,
      position: "relative",
      _before: {
        content: '""',
        display: "block",
        position: "absolute",
        transform: "skew(24deg, 0deg)",
        bg: "gray.100",
        width: "2px",
        height: "50%",
        top: 0,
        left: 0,
      },
      _after: {
        content: '""',
        display: "block",
        position: "absolute",
        transform: "skew(-24deg, 0deg)",
        bg: "gray.100",
        width: "2px",
        height: "50%",
        bottom: 0,
        left: 0,
      },
    },
    step: {
      px: 4,
      h: "full",
      gap: 2,
      fontWeight: "medium",
      color: "ds.text.default",
      textStyle: "ds.interface.large",
      borderBottom: "2px solid",
      borderColor: "transparent",
      _activeLink: {
        borderColor: "ds.border.selected",
        color: "ds.text.selected",
        "> [data-component=number]": {
          // This is a bit gross but there is no `_groupActiveLink` selector
          bg: "gray.700",
          color: "white",
        },
      },
      _disabled: {
        color: "gray.400",
        cursor: "not-allowed",
      },
      _focus: { outline: 0, boxShadow: "outline" },
    },
    stepIndicator: {
      boxSize: 6,
      rounded: "full",
      fontSize: "sm",
      ml: -2,
      lineHeight: 0,
      bg: "brand.primary.100",
      _groupDisabled: {
        bg: "gray.100",
      },
    },
    stepIcon: {
      color: "currentColor",
    },
    stepCount: {
      font: "inherit",
      fontWeight: "normal",
    },
  },
})
