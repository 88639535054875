import { Box } from "@chakra-ui/react"
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  PropsWithChildren,
  useRef,
} from "react"

type VideoPlayerProps = ComponentPropsWithoutRef<"video"> & {
  url?: string
  canSkipAhead: boolean
  onMediaEnded: () => void
  onSkippedAhead: () => void
}

export const VideoPlayer = forwardRef<
  HTMLVideoElement,
  PropsWithChildren<VideoPlayerProps>
>(
  (
    { url, canSkipAhead, onMediaEnded, onSkippedAhead, children, ...props },
    ref
  ) => {
    const progress = useRef(0)
    const lastSentSkippedAhead = useRef(0)

    const handleTimeUpdate = (
      event: React.SyntheticEvent<HTMLVideoElement>
    ) => {
      const video = event.currentTarget

      if (!video.seeking) {
        progress.current = Math.max(video.currentTime, progress.current)
      }
    }

    const handleSeeking = (event: React.SyntheticEvent<HTMLVideoElement>) => {
      if (canSkipAhead) return

      const video = event.currentTarget
      const delta = video.currentTime - progress.current

      if (delta > 0.1) {
        if (resetSeek.current) clearTimeout(resetSeek.current)

        event.preventDefault()
        video.currentTime = progress.current

        resetSeek.current = window.setTimeout(() => {
          if (Date.now() - lastSentSkippedAhead.current > 10000) {
            onSkippedAhead()
            lastSentSkippedAhead.current = Date.now()
          }
        }, 100)
      }
    }

    const resetSeek = useRef<number | null>(null)

    return (
      <Box
        as="video"
        key={url}
        ref={ref}
        w="100%"
        h="100%"
        controls
        controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        onTimeUpdate={handleTimeUpdate}
        onSeeking={handleSeeking}
        onEnded={onMediaEnded}
        data-qa={`screenshot-player-${url!}`}
        {...props}
      >
        {url && <source src={url} />}
        {children}
      </Box>
    )
  }
)
