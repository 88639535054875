import { Box } from "@chakra-ui/react"
import React from "react"

export const BottomShadow: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Box
    // Shadow hack to avoid box-shadow on all sides
    position="relative"
    _after={{
      content: '""',
      position: "absolute",
      top: "calc(100% - 0px)",
      left: 0,
      right: 0,
      height: "8px",
      pointerEvents: "none",
      background:
        "linear-gradient(to bottom, rgba(6, 29, 41, 0.12) -1px, rgba(6, 29, 41, 0) 1px), linear-gradient(to bottom, rgba(6, 29, 41, 0.16) -4px, rgba(6, 29, 41, 0) 4px)",
    }}
  >
    {children}
  </Box>
)
