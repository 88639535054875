import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"

type Props = {
  handleReset: () => void
}

export const TestInstructionsResetConfirmationModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  handleReset,
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Reset test instructions</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          Are you sure you want to reset the test instructions to their default
          value?
        </ModalBody>

        <ModalFooter gap={2}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleReset()
              onClose()
            }}
          >
            Reset
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
