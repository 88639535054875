import { DeviceFrame, Dimensions } from "Types"
import classNames from "classnames"
import React from "react"

import {
  UnscaledScreenshot,
  UnscaledScreenshotProps,
} from "./UnscaledScreenshot/UnscaledScreenshot"
import { MaintainScreenshotAspectRatioContainer } from "./maintain-screenshot-aspect-ratio-container"
import styles from "./screenshot.module.scss"

export type ScreenshotProps = UnscaledScreenshotProps & {
  constrainWidthToDeviceFrame: DeviceFrame | null
  maxPlayableSize?: Dimensions
}

export const Screenshot: React.FC<React.PropsWithChildren<ScreenshotProps>> = ({
  children,
  className,
  constrainWidthToDeviceFrame,
  screenshot,
  onClick,
  maxPlayableSize,
  ...unscaledProps
}) => {
  return (
    <MaintainScreenshotAspectRatioContainer
      className={classNames(styles.container, className)}
      constrainWidthToDeviceFrame={constrainWidthToDeviceFrame}
      screenshot={screenshot}
      onClick={onClick}
      maxPlayableSize={maxPlayableSize}
    >
      <UnscaledScreenshot
        className={styles.screenshot}
        screenshot={screenshot}
        {...unscaledProps}
      />
      {children}
    </MaintainScreenshotAspectRatioContainer>
  )
}
