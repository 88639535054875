import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { CreditCardFormModalLinkButton } from "Components/credit-card-form-modal/credit-card-form-modal"
import { SupportMailtoLink } from "Shared/components/Links/SupportMailtoLink"

export const StripeSubscriptionPastDueAlert = () => {
  return (
    <Alert alignItems="flex-start" status="warning">
      <AlertIcon />
      <AlertTitle>
        Our recent attempt to process your subscription payment was
        unsuccessful.
      </AlertTitle>
      <AlertDescription>
        <Text>
          This may be because your card has insufficient funds, has expired, or
          your credit card issuer blocked the payment. You can{" "}
          <CreditCardFormModalLinkButton
            modalButtonText="Save new credit card"
            modalHeading="Change my billing details"
          >
            update your credit card
          </CreditCardFormModalLinkButton>
          , or if the transaction is being blocked you may need to call your
          credit card issuer and ask them to allow your purchase with us.
        </Text>
        <Text>
          Please contact <SupportMailtoLink /> if you need any help.
        </Text>
      </AlertDescription>
    </Alert>
  )
}
