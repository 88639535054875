import { createIcon } from "@chakra-ui/react"
import React from "react"

/**
 * This is a custom icon that we created and
 * is not part of the Untitled UI icon set.
 */
export const ProjectOpenIcon = createIcon({
  displayName: "ProjectOpenIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.09557 17.6044L0.350649 9.97109L0.0246411 7.03697C-0.219747 4.83744 1.38173 2.86282 3.58433 2.64787L7.10358 2.30443C8.24093 2.19344 9.37095 2.57799 10.2045 3.3597L11.5248 4.59787L15.7781 4.13998C17.8033 3.92195 19.626 5.26571 20.07 7.18772C22.7181 7.34899 24.5791 9.93308 23.8348 12.526L21.9125 19.2219C21.3968 21.0182 19.7539 22.2558 17.8851 22.2558H5.46871C3.25893 22.2558 1.53725 20.577 1.30323 18.5463C1.19881 18.2498 1.12781 17.9347 1.09557 17.6044ZM4.98929 20.2193C5.01998 20.2261 5.05094 20.2322 5.08215 20.2376C5.207 20.2595 5.33595 20.2709 5.46822 20.2709L17.8851 20.271C18.8687 20.271 19.7334 19.6196 20.0048 18.6742L21.9271 11.9783C22.2884 10.7195 21.4831 9.46467 20.2621 9.2109C20.2386 9.20601 20.2149 9.20148 20.1911 9.19734C20.067 9.17578 19.9388 9.16451 19.8074 9.16451H7.39098C6.40739 9.16451 5.54272 9.81588 5.27131 10.7613L3.34904 17.4572C2.9901 18.7075 3.78219 19.9539 4.98929 20.2193ZM18.2139 7.17976H7.39098C5.52217 7.17976 3.87928 8.41736 3.36361 10.2136L2.45559 13.3765L2.04432 9.1622L2.03611 9.16448L1.77808 6.84215C1.64231 5.62019 2.53202 4.52317 3.75569 4.40376L7.27493 4.06032C7.9068 3.99866 8.53458 4.2123 8.99767 4.64658L10.9086 6.43863L15.9669 5.89407C16.9425 5.78905 17.8335 6.33634 18.2139 7.17976Z"
      fill="currentColor"
    />
  ),
})
