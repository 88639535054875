import { Box, Flex, Grid, Text } from "@chakra-ui/react"
import { VariationSetMenu } from "Components/test-set/TestSetMenu/VariationSetMenu"
import { Button, Heading } from "DesignSystem/components"
import { VariationSetIcon } from "Icons/VariationSetIcon"
import { studyCardGridTemplateColumns } from "UsabilityHub/components/StudyCard/StudyCard"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { convertUsabilityTestToStudy } from "UsabilityHub/utils/convertUsabilityTestToStudy"
import React from "react"
import { Link } from "react-router-dom"
import { ListVariationSetsResponse } from "~/api/generated/usabilityhub-components"
import { TestStatus, UsabilityTestCard } from "./UsabilityTestCard"

// Slightly different from the default VariationSet type which has no nested relations
type VariationSetWithEmbeddedTests =
  ListVariationSetsResponse["variation_sets"][number]

type Props = {
  variationSet: VariationSetWithEmbeddedTests
}

export const VariationSetRow: React.FC<Props> = ({ variationSet }) => {
  const userCanManageTests = useCurrentUser().can_manage_tests

  return (
    <Box>
      <Box
        position="relative"
        sx={{
          // For devices that can hover
          "@media (hover: hover)": {
            // Hide context menu button when the card is not hovered or focused
            "&:not(:hover, :focus-within) button": {
              visibility: "hidden",
            },
          },
        }}
      >
        <Grid
          as={Link}
          to={`/sets/${variationSet.unique_id}`}
          h={20}
          templateColumns={studyCardGridTemplateColumns}
          alignItems="center"
          pe={[2, null, 4]}
          bg="white"
          borderTopRadius="16px"
          borderColor="transparent"
          borderWidth={1}
          _hover={{
            outline: "1px solid",
            outlineColor: "ds.border.hovered",
            outlineOffset: -1,
          }}
          _focusVisible={{
            outline: "none",
            boxShadow: "outline",
          }}
        >
          <Box />
          <Box gridColumn={["1", null, "2 / span 1"]} ps={[4, 4, 0]}>
            <Flex flexDirection="column" gap={2}>
              <Heading
                as="h3"
                color="ds.text.default"
                textStyle="ds.heading.primary"
                noOfLines={2}
              >
                {variationSet.name}
              </Heading>
              <Text textStyle="ds.interface.medium" color="ds.text.subtle">
                <VariationSetIcon boxSize={3.5} mr={1} />
                Variation set
              </Text>
            </Flex>
          </Box>

          <Box display={["none", null, "flex"]} />

          <Flex display={["none", null, "flex"]}>
            <TestStatus
              showDetail
              status={variationSet.status}
              statusTags={variationSet.status_tags}
            />
          </Flex>

          <Flex
            justifyContent="center"
            display={["none", null, null, null, "flex"]}
          >
            {userCanManageTests && (
              <Button as={Link} to={`/sets/${variationSet.unique_id}/recruit`}>
                Manage recruitment
              </Button>
            )}
          </Flex>

          <Box display={["none", null, null, null, "flex"]} />

          <Flex
            display={["none", null, "flex"]}
            justifyContent="flex-end"
            alignItems={["flex-start", null, "center"]}
            pe={[0, null, 2]}
            mt={[-1, 0]}
          ></Flex>
        </Grid>

        <Flex
          position="absolute"
          top={6}
          right={["0.6rem", null, "calc(1.5rem + 1px)"]}
          w={8}
          h={8}
        >
          <VariationSetMenu variationSet={variationSet} />
        </Flex>
      </Box>

      {variationSet.usability_tests.length > 0 ? (
        variationSet.usability_tests.map((test) => (
          <UsabilityTestCard
            combineAdjacent
            key={test.id}
            study={convertUsabilityTestToStudy(test)}
            isSelectable={false}
            showVariationSetInfo={false}
            disableContextMenu={false}
          />
        ))
      ) : (
        <Box
          px={7}
          py={4}
          bg="white"
          borderColor="transparent"
          borderWidth="1px"
          borderBottomRadius="16px"
        >
          <Text color="text.secondary">No tests</Text>
        </Box>
      )}
    </Box>
  )
}
