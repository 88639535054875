import { CSSReset } from "@chakra-ui/react"
import { UsabilityHubNavbar } from "Components/exports"
import { CatchRouterError } from "Components/routes/CatchRouterError"
import { installOrtto } from "JavaScripts/analytics/ortto"
import { installSegment } from "JavaScripts/analytics/segment"
import { getApplicationState } from "JavaScripts/state"
import { initializeSentry } from "Services/sentry"
import { CustomerEmulationAlert } from "Shared/components/EmulationAlert/CustomerEmulationAlert"
import { getIsOldDomain } from "Shared/helpers/getIsOldDomain"
import { NewVersionChecker } from "UsabilityHub/components/NewVersionChecker/NewVersionChecker"
import {
  CurrentAccountProvider as BaseCurrentAccountProvider,
  useMaybeCurrentAccount,
  useMaybeCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useIsInterviewsEnabled } from "UsabilityHub/hooks/useIsInterviewsEnabled"
import { OAuthErrorRoute } from "UsabilityHub/views/oauth/error/Route"
import { OAuthSuccessRoute } from "UsabilityHub/views/oauth/success/Route"
import { OnboardingRoute } from "UsabilityHub/views/setup/$stepId/Route"
import { FreeTrialRoute } from "UsabilityHub/views/setup/trial/Route"
import { SignUpRoute } from "UsabilityHub/views/users/sign_up/Route"
import { useInNoPlanState } from "Utilities/account"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React, { PropsWithChildren, useEffect, useState } from "react"
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import { InterviewOrder } from "./InterviewOrder/InterviewOrder"
import { ModeratedStudyApplicationEndPage } from "./ModeratedStudy/applicant/ApplicationEndPage"
import { ModeratedStudyBookingPage } from "./ModeratedStudy/applicant/BookingPage"
import { DeclineInvitationPage } from "./ModeratedStudy/applicant/DeclineInvitationPage"
import { DeclineResearcherReschedulePage } from "./ModeratedStudy/applicant/DeclineResearcherReschedulePage"
import { ModeratedStudyJoinPage } from "./ModeratedStudy/applicant/JoinPage"
import { ModeratedStudyBookingConfirmationPage } from "./ModeratedStudy/applicant/ModeratedStudyBookingConfirmationPage"
import { NotAcceptingParticipantsPage } from "./ModeratedStudy/applicant/NotAcceptingParticipantsPage"
import { ModeratedStudyPersonalDetailsPage } from "./ModeratedStudy/applicant/PersonalDetailsPage"
import { ModeratedStudyBookingReschedulePage } from "./ModeratedStudy/applicant/ReschedulePage"
import { RootApplicationLayout } from "./ModeratedStudy/applicant/RootApplicationLayout"
import { RootPreviewLayout } from "./ModeratedStudy/applicant/RootPreviewLayout"
import { ModeratedStudyScreenerPage } from "./ModeratedStudy/applicant/ScreenerPage"
import { ApplicationsFilterProvider } from "./ModeratedStudy/interviewer/ApplicationsFilterProvider"
import { ModeratedStudyApplicationsPage } from "./ModeratedStudy/interviewer/ApplicationsPage"
import { ModeratedStudyBookingsPage } from "./ModeratedStudy/interviewer/BookingsPage"
import { ModeratedStudyEditPage } from "./ModeratedStudy/interviewer/EditPage/EditPage"
import { Emails as ModeratedStudyEmails } from "./ModeratedStudy/interviewer/EditPage/Emails"
import { EventCalendar as ModeratedStudyEventCalendar } from "./ModeratedStudy/interviewer/EditPage/EventCalendar/EventCalendar"
import { HostsAndAvailability as ModeratedStudyHostsAndAvailability } from "./ModeratedStudy/interviewer/EditPage/HostsAndAvailability/HostsAndAvailability"
import { MeetingLink as ModeratedStudyMeetingLink } from "./ModeratedStudy/interviewer/EditPage/MeetingLink"
import { Screener as ModeratedStudyScreener } from "./ModeratedStudy/interviewer/EditPage/Screener"
import { StudyDetails as ModeratedStudyDetails } from "./ModeratedStudy/interviewer/EditPage/StudyDetails"
import { TeamMembers as ModeratedStudyTeamMembers } from "./ModeratedStudy/interviewer/EditPage/TeamMembers"
import { InterviewSessionRecordings } from "./ModeratedStudy/interviewer/Recordings/InterviewSessionRecordings"
import { ModeratedStudyRecordingsPage } from "./ModeratedStudy/interviewer/Recordings/ModeratedStudyRecordingsPage"
import { ModeratedStudyRecruitmentLinkEditPage } from "./ModeratedStudy/interviewer/RecruitmentLinkEditPage"
import { RootModeratedStudyLayout } from "./ModeratedStudy/interviewer/RootModeratedStudyLayout"
import { NewOrderPage } from "./NewOrderPage/NewOrderPage"
import { RedirectToLyssna } from "./RedirectToLyssna/RedirectToLyssna"
import { ClaimRoute } from "./codes/claim/Route"
import { HistoryRoute } from "./credits/history/Route"
import { PurchaseRoute } from "./credits/purchase/Route"
import { ProjectsPage } from "./dashboard/ProjectsPage"
import { DashboardRoute } from "./dashboard/Route"
import { DashboardFilterProvider } from "./dashboard/dashboard-filter-provider"
import { TemplateDetails } from "./dashboard/templates/$templateName/Route"
import { TemplatesList } from "./dashboard/templates/Route"
import { ModeratedStudyRecruitPage } from "./interviews/$uniqueId/recruit/ModeratedStudyRecruitPage"
import { CookiesPage } from "./legal/cookies/Route"
import { DataProcessingAgreementRoute } from "./legal/dpa/Route"
import { LegalLayout } from "./legal/layout"
import { SubprocessorsRoute } from "./legal/sub-processors/Route"
import { SubprocessorsUnsubscribe } from "./legal/sub-processors/unsubscribe/Route"
import { PrivacyRoute } from "./privacy/Route"
import { ProjectRoute } from "./projects/$projectWithSlug/Route"
import { ResearchPanelCalculatorRoute } from "./research-panel-calculator/Route"
import { ROUTES } from "./routes"
import { SearchResultsRoute } from "./search/Route"
import { SecurityRoute as LegalSecurity } from "./security/Route"
import { FeatureAccessRoute } from "./settings/FeatureAccessPage/Route"
import { RootSettingsLayout } from "./settings/RootSettingsLayout"
import { SettingsWithSidebarLayout } from "./settings/SettingsWithSidebarLayout"
import { BillingRoute } from "./settings/billing/Route"
import { BillingHistoryRoute } from "./settings/billing/history/Route"
import { OrganizationRoute } from "./settings/organization/Route"
import { ProfileRoute } from "./settings/profile/Route"
import { SecurityRoute } from "./settings/security/Route"
import { TeamRoute } from "./settings/team/Route"
import { ServicesAgreementRoute } from "./terms/Route"
import { ShowTestSetPage } from "./test_sets/$uniqueId/Route"
import { RecruitTestSetPage } from "./test_sets/$uniqueId/recruit/Route"
import { RootTestSetLayout } from "./test_sets/layout"
import { EditUsabilityTestRoute } from "./tests/$uniqueId/edit/Route"
import { UsabilityTestRecruitRoute } from "./tests/$uniqueId/recruit/Route"
import { UsabilityTestResultsRoute } from "./tests/$uniqueId/results/Route"
import { UsabilityTestLayout } from "./tests/layout"
import { NewUsabilityTestRoute } from "./tests/new/Route"
import { EditPasswordRoute } from "./users/password/edit/Route"
import { ResetPasswordRoute } from "./users/password/new/Route"
import { SAMLSignInRoute } from "./users/saml_sign_in/Route"
import { SignInRoute } from "./users/sign_in/Route"

function RequiresAuthenticatedUser({ children }: PropsWithChildren) {
  return (
    <CurrentAccountProvider
      unauthenticatedFallback={<Navigate to={ROUTES.USERS.SIGN_IN.path} />}
    >
      {/* There's already a ModalContextProvider further up the tree but we need one inside
          the CurrentAccountProvider since a lot of modals rely on having access to the currentAccount. */}
      <ModalContextProvider>
        <TrackingScripts />
        <NewVersionChecker />
        {children}
      </ModalContextProvider>
    </CurrentAccountProvider>
  )
}

function RequiresNonArchivedUser({ children }: PropsWithChildren) {
  const currentUser = useMaybeCurrentUser()
  const userIsValid = currentUser && currentUser.role !== "archived"

  if (!userIsValid) return <Navigate to={ROUTES.SETTINGS.PROFILE.path} />

  return <>{children}</>
}

function RequiresAdminUser({ children }: PropsWithChildren) {
  const currentUser = useMaybeCurrentUser()
  const currentUserIsAdmin = currentUser && currentUser.role === "admin"

  if (!currentUserIsAdmin) return <Navigate to={ROUTES.SETTINGS.PROFILE.path} />

  return <>{children}</>
}

function RequiresInterviewsEnabled({ children }: PropsWithChildren) {
  const enabled = useIsInterviewsEnabled()

  if (!enabled) return <Navigate to={ROUTES.DASHBOARD.path} />

  return <>{children}</>
}

function RequiresAccountPlanExist({ children }: PropsWithChildren) {
  const isInNoPlanState = useInNoPlanState()

  if (isInNoPlanState) return <Navigate to={ROUTES.DASHBOARD.path} />

  return <>{children}</>
}

const TrackingScripts: React.FC = () => {
  // The scripts don't like to be loaded twice, even if user/account data changes
  const [loaded, setLoaded] = useState(false)

  const currentUser = useMaybeCurrentUser()
  const currentAccount = useMaybeCurrentAccount()

  useEffect(() => {
    if (loaded) return

    installSegment(currentUser, currentAccount)
    installOrtto(currentUser, currentAccount)
    initializeSentry(currentUser)

    setLoaded(true)
  }, [loaded])

  return null
}

function Root() {
  return (
    <>
      <ModalContextProvider>
        <Outlet />
      </ModalContextProvider>
    </>
  )
}

const CurrentAccountProvider: React.FC<
  PropsWithChildren<{ unauthenticatedFallback?: React.ReactNode }>
> = ({ children, ...props }) => {
  const { isEmulatingUser } = getApplicationState()

  return (
    <BaseCurrentAccountProvider {...props}>
      {isEmulatingUser && <CustomerEmulationAlert />}
      {children}
    </BaseCurrentAccountProvider>
  )
}

const LyssnaRouter = (
  <Route
    element={<Root />}
    errorElement={
      <CatchRouterError>
        <UsabilityHubNavbar />
      </CatchRouterError>
    }
  >
    {/* Authenticated User Routes */}
    <Route
      element={
        <RequiresAuthenticatedUser>
          <Outlet />
        </RequiresAuthenticatedUser>
      }
    >
      <Route
        path={ROUTES.DASHBOARD.path}
        element={
          <RequiresNonArchivedUser>
            <DashboardRoute />
          </RequiresNonArchivedUser>
        }
      >
        <Route
          path={ROUTES.DASHBOARD.TEMPLATES.DETAILS.path}
          element={<TemplateDetails />}
        />
        <Route
          path={ROUTES.DASHBOARD.TEMPLATES.path}
          element={<TemplatesList />}
        />
      </Route>

      {/* Settings Routes */}
      <Route path={ROUTES.SETTINGS.path} element={<RootSettingsLayout />}>
        <Route
          path={ROUTES.SETTINGS.BILLING.HISTORY.path}
          element={<BillingHistoryRoute />}
        />
        <Route element={<SettingsWithSidebarLayout />}>
          <Route
            path={ROUTES.SETTINGS.BILLING.path}
            element={
              <RequiresAdminUser>
                <BillingRoute />
              </RequiresAdminUser>
            }
          />
          <Route
            path={ROUTES.SETTINGS.SECURITY.path}
            element={
              <RequiresAdminUser>
                <SecurityRoute />
              </RequiresAdminUser>
            }
          />
          <Route
            path={ROUTES.SETTINGS.ORGANIZATION.path}
            element={
              <RequiresAdminUser>
                <OrganizationRoute />
              </RequiresAdminUser>
            }
          />
          <Route
            path={ROUTES.SETTINGS.TEAM.path}
            element={
              <RequiresAdminUser>
                <TeamRoute />
              </RequiresAdminUser>
            }
          />

          <Route
            path={ROUTES.SETTINGS.PROFILE.path}
            element={<ProfileRoute />}
          />
          <Route
            path={ROUTES.SETTINGS.FEATURE_ACCESS.path}
            element={<FeatureAccessRoute />}
          />
        </Route>
      </Route>

      {/* Requiring a Plan Routes */}
      <Route
        element={
          <RequiresAccountPlanExist>
            <Outlet />
          </RequiresAccountPlanExist>
        }
      >
        <Route
          path={ROUTES.SEARCH.path}
          element={
            <RequiresNonArchivedUser>
              <DashboardFilterProvider>
                <SearchResultsRoute />
              </DashboardFilterProvider>
            </RequiresNonArchivedUser>
          }
        />
        <Route
          path={ROUTES.PROJECT.path}
          element={
            <RequiresNonArchivedUser>
              <ProjectRoute />
            </RequiresNonArchivedUser>
          }
        />
        <Route
          path={ROUTES.PROJECTS.path}
          element={
            <RequiresNonArchivedUser>
              <ProjectsPage />
            </RequiresNonArchivedUser>
          }
        />

        {/* Usability Test Routes */}
        <Route
          path={ROUTES.TESTS.path}
          element={
            <RequiresNonArchivedUser>
              <UsabilityTestLayout />
            </RequiresNonArchivedUser>
          }
        >
          <Route
            path={ROUTES.TESTS.NEW.path}
            element={<NewUsabilityTestRoute />}
          />

          <Route
            path={ROUTES.TEST.EDIT.path}
            element={<EditUsabilityTestRoute />}
          />

          <Route path={ROUTES.TEST.RECRUIT.path}>
            <Route index element={<UsabilityTestRecruitRoute />} />
            <Route
              path={ROUTES.TEST.RECRUIT.ORDERS.path}
              element={<NewOrderPage />}
            />
          </Route>

          <Route
            path={ROUTES.TEST.RESULTS.path}
            element={<UsabilityTestResultsRoute />}
          />
        </Route>

        {/* Variation Set Routes */}
        <Route
          path={ROUTES.TEST_SET.path}
          element={
            <RequiresNonArchivedUser>
              <RootTestSetLayout />
            </RequiresNonArchivedUser>
          }
        >
          <Route index element={<ShowTestSetPage />} />
          <Route
            path={ROUTES.TEST_SET.RECRUIT.path}
            element={<RecruitTestSetPage />}
          />
        </Route>

        {/* Moderated Study Routes */}
        <Route
          element={
            <RequiresNonArchivedUser>
              <RequiresInterviewsEnabled>
                <Outlet />
              </RequiresInterviewsEnabled>
            </RequiresNonArchivedUser>
          }
        >
          {/* Interviewer Routes */}
          <Route>
            <Route element={<RootModeratedStudyLayout />}>
              <Route
                path={ROUTES.INTERVIEW.EDIT.path}
                element={<ModeratedStudyEditPage />}
              >
                <Route index element={<ModeratedStudyDetails />} />
                <Route
                  path={ROUTES.INTERVIEW.EDIT.SCREENER.path}
                  element={<ModeratedStudyScreener />}
                />
                <Route
                  path={ROUTES.INTERVIEW.EDIT.TEAM.path}
                  element={<ModeratedStudyTeamMembers />}
                />
                <Route
                  path={ROUTES.INTERVIEW.EDIT.AVAILABILITY.path}
                  element={<ModeratedStudyHostsAndAvailability />}
                />
                <Route
                  path={ROUTES.INTERVIEW.EDIT.EMAILS.path}
                  element={<ModeratedStudyEmails />}
                />
                <Route
                  path={ROUTES.INTERVIEW.EDIT.LINK.path}
                  element={<ModeratedStudyMeetingLink />}
                />
                <Route
                  path={ROUTES.INTERVIEW.EDIT.CALENDAR.path}
                  element={<ModeratedStudyEventCalendar />}
                />
              </Route>
              <Route path={ROUTES.INTERVIEW.RECRUIT.path}>
                <Route index element={<ModeratedStudyRecruitPage />} />
                <Route
                  path={ROUTES.INTERVIEW.RECRUIT.LINK.path}
                  element={<ModeratedStudyRecruitmentLinkEditPage />}
                />
                <Route
                  path={ROUTES.INTERVIEW.RECRUIT.ORDERS.path}
                  element={<InterviewOrder />}
                />
              </Route>
              <Route
                path={ROUTES.INTERVIEW.APPLICANTS.path}
                element={
                  <ApplicationsFilterProvider>
                    <ModeratedStudyApplicationsPage />
                  </ApplicationsFilterProvider>
                }
              />
              <Route
                path={ROUTES.INTERVIEW.SESSIONS.path}
                element={<ModeratedStudyBookingsPage />}
              />
              <Route
                path={ROUTES.INTERVIEW.RECORDINGS.path}
                element={<ModeratedStudyRecordingsPage />}
              >
                <Route
                  path={ROUTES.INTERVIEW.RECORDINGS.SESSION.path}
                  element={<InterviewSessionRecordings />}
                />
              </Route>
            </Route>
          </Route>

          {/* Moderated study preview routes — still authed, but not in the same layout */}
          <Route
            path={ROUTES.INTERVIEW.PREVIEW.path}
            element={<RootPreviewLayout />}
          >
            <Route
              path={ROUTES.INTERVIEW.PREVIEW.APPLY.path}
              element={<ModeratedStudyPersonalDetailsPage />}
            />
            <Route
              path={ROUTES.INTERVIEW.PREVIEW.QUESTIONS.path}
              element={<ModeratedStudyScreenerPage />}
            />
            <Route
              path={ROUTES.INTERVIEW.PREVIEW.THANKYOU.path}
              element={<ModeratedStudyApplicationEndPage />}
            />
            <Route
              path={ROUTES.INTERVIEW.PREVIEW.CLOSED.path}
              element={<NotAcceptingParticipantsPage />}
            />
            <Route
              path={ROUTES.INTERVIEW.PREVIEW.BOOK.path}
              element={<ModeratedStudyBookingPage />}
            />
            <Route
              path={ROUTES.INTERVIEW.PREVIEW.CONFIRMED.path}
              element={<ModeratedStudyBookingConfirmationPage />}
            />
          </Route>
        </Route>

        {/* Credit Routes */}
        <Route path={ROUTES.CREDITS.path}>
          <Route
            path={ROUTES.CREDITS.HISTORY.path}
            element={<HistoryRoute />}
          />
          <Route
            path={ROUTES.CREDITS.PURCHASE.path}
            element={<PurchaseRoute />}
          />
        </Route>

        <Route path={ROUTES.CODES.CLAIM.path} element={<ClaimRoute />} />

        {/* Unstyled Routes */}
        <Route>
          <Route
            path={ROUTES.OAUTH.SUCCESS.path}
            element={
              <RequiresNonArchivedUser>
                <OAuthSuccessRoute />
              </RequiresNonArchivedUser>
            }
          />
          <Route
            path={ROUTES.OAUTH.ERROR.path}
            element={
              <RequiresNonArchivedUser>
                <OAuthErrorRoute />
              </RequiresNonArchivedUser>
            }
          />
        </Route>
      </Route>
    </Route>

    {/* Legal Routes, visible for both authenticated and unauthenticated users */}
    <Route element={<LegalLayout />}>
      <Route
        path={ROUTES.LEGAL.TERMS.path}
        element={<ServicesAgreementRoute />}
      />
      <Route path={ROUTES.LEGAL.PRIVACY.path} element={<PrivacyRoute />} />
      <Route path={ROUTES.LEGAL.SECURITY.path} element={<LegalSecurity />} />
      <Route
        path={ROUTES.LEGAL.SECURITY.RESPONSIBLE_DISCLOSURE.path}
        element={
          <Navigate
            to={`${ROUTES.LEGAL.SECURITY.path}#responsible-disclosure-program`}
            replace
          />
        }
      />
      <Route
        path={ROUTES.LEGAL.DPA.path}
        element={<DataProcessingAgreementRoute />}
      />
      <Route
        path={ROUTES.LEGAL.SUBPROCESSORS.path}
        element={<SubprocessorsRoute />}
      />
      <Route
        path={ROUTES.LEGAL.SUBPROCESSORS.UNSUBSCRIBE.path}
        element={<SubprocessorsUnsubscribe />}
      />
      <Route path={ROUTES.LEGAL.COOKIES.path} element={<CookiesPage />} />
    </Route>

    <Route
      path={ROUTES.PANEL_ESTIMATE.path}
      element={<ResearchPanelCalculatorRoute />}
    />

    {/* Unauthenticated User Routes */}
    <Route
      element={
        <>
          <TrackingScripts />
          <Outlet />
        </>
      }
    >
      {/* Moderated Study Participant Routes */}
      <Route element={<RootApplicationLayout />}>
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLY.path}
          element={<ModeratedStudyPersonalDetailsPage />}
        />
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.QUESTIONS.path}
          element={<ModeratedStudyScreenerPage />}
        />
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.THANKYOU.path}
          element={<ModeratedStudyApplicationEndPage />}
        />
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.CLOSED.path}
          element={<NotAcceptingParticipantsPage />}
        />
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.BOOK.path}
          element={<ModeratedStudyBookingPage />}
        />
        <Route
          path={
            ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.INVITATION_DECLINE.path
          }
          element={<DeclineInvitationPage />}
        />
        <Route
          path={
            ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.RESCHEDULE_DECLINE.path
          }
          element={<DeclineResearcherReschedulePage />}
        />
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.CONFIRMED.path}
          element={<ModeratedStudyBookingConfirmationPage />}
        />
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.RESCHEDULE.path}
          element={<ModeratedStudyBookingReschedulePage />}
        />
        <Route
          path={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.JOIN.path}
          element={<ModeratedStudyJoinPage />}
        />
      </Route>

      <Route path={ROUTES.USERS.SIGN_IN.path} element={<SignInRoute />} />
      <Route
        path={ROUTES.USERS.SAML_SIGN_IN.path}
        element={<SAMLSignInRoute />}
      />
      <Route path={ROUTES.SETUP.TRIAL.path} element={<FreeTrialRoute />} />
      <Route path={ROUTES.USERS.SIGN_UP.path} element={<SignUpRoute />} />
      <Route
        path={ROUTES.SETUP.ONBOARDING.path}
        element={<OnboardingRoute />}
      />
      <Route
        path={ROUTES.USERS.PASSWORD.NEW.path}
        element={<ResetPasswordRoute />}
      />
      <Route
        path={ROUTES.USERS.PASSWORD.EDIT.path}
        element={<EditPasswordRoute />}
      />
    </Route>

    <Route element={<Navigate to={ROUTES.USERS.SIGN_IN.path} />} />
  </Route>
)

const UsabilityHubRouter = (
  // Always send them to Lyssna. We don't care how.
  // If it errors, send them to Lyssna. If it doesn't error
  // Send them to Lyssna via the component to keep their path
  <Route
    element={<RedirectToLyssna />}
    errorElement={<Navigate to={`${window.location.hostname}/users/sign_in`} />}
  >
    <Route path="*" element={<RedirectToLyssna />} />
  </Route>
)

const lyssnaRouterObject = createBrowserRouter(
  createRoutesFromElements(LyssnaRouter)
)

const usabilityHubRouterObject = createBrowserRouter(
  createRoutesFromElements(UsabilityHubRouter)
)

export const UsabilityHub: React.FC<React.PropsWithChildren<unknown>> = () => {
  // the only legitimate use of getIsSecondaryDomain afaik, todo: rename it
  const router = getIsOldDomain()
    ? usabilityHubRouterObject
    : lyssnaRouterObject
  return (
    <>
      <CSSReset />
      <RouterProvider router={router} />
    </>
  )
}
