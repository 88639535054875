import {
  Collapse,
  Divider,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { iconForQuestionType } from "Components/question-type-icon/question-type-icon"
import { Button } from "DesignSystem/components"
import { BarChartIcon } from "Icons/BarChartIcon"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { ListIcon } from "Icons/ListIcon"
import { State } from "Redux/app-store"
import { ScreenerQuestion } from "Redux/reducers/test-results/reducer"
import { SortMethod } from "Types"
import {
  getQuestionTypeLabel,
  isCheckboxQuestion,
  isRadioQuestion,
} from "Utilities/usability-test-section-question"
import { noop } from "lodash"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useFilteredResponses } from "../hooks/use-filtered-responses"
import { Name, ProgressBox } from "../progress-box/progress-box"
import { AnswerCounts } from "../question-results/answer-counts/answer-counts"
import { countCheckboxAnswers } from "../question-results/answer-counts/checkbox-answer-counts"
import { getKey } from "../question-results/answer-counts/helpers"
import { countRadioAnswers } from "../question-results/answer-counts/radio-answer-counts"
import { ScreenerAnswerCountItem } from "../question-results/answer-counts/screener-answer-count-item"
import { AnswerList } from "../question-results/answer-list/answer-list"
import { NoResponses } from "../question-results/no-responses"
import { convertScreenerQuestionToQuestion } from "./convertScreenerQuestionToQuestion"
import { convertScreenerResponsesToAnswers } from "./convertScreenerResponsesToAnswers"

type Props = {
  screenerQuestion: ScreenerQuestion
  position: number
}

export const ScreenerQuestionResults: React.FC<Props> = ({
  screenerQuestion,
  position,
}) => {
  const question = convertScreenerQuestionToQuestion(screenerQuestion)

  const filteredResponseIds = useFilteredResponses().map(
    (response) => response.id
  )
  const screenerResponses =
    useSelector((state: State) => state.testResults?.screenerResponses) ?? []
  const visibleScreenerResponses = screenerResponses.filter(
    (screenerResponse) =>
      filteredResponseIds.includes(screenerResponse.response_id)
  )

  const answers = convertScreenerResponsesToAnswers(
    visibleScreenerResponses,
    screenerQuestion.id
  )

  const disqualifyingOptions =
    screenerQuestion.screener_question_options.filter(
      (answer) => answer.rule === "disqualify"
    )

  return (
    <>
      {position > 0 && <Divider my={4} />}

      <Flex direction="column" gap={5}>
        <Flex gap={2} alignItems="center">
          {iconForQuestionType(question.type)}
          <Text textStyle="label">
            {`Q${position + 1}. `}
            {getQuestionTypeLabel(question.type)}
          </Text>
        </Flex>

        <DisplayBlockMarkdownText text={question.text} />

        <Flex direction="column" gap={3}>
          <Tabs isLazy>
            <TabList alignItems="center" color="gray.600">
              <Tab>
                <Icon as={BarChartIcon} mr={2} />
                <Text>Totals</Text>
              </Tab>
              <Tab>
                <Icon as={ListIcon} mr={2} />
                <Text>
                  Answers <small>{answers.length}</small>
                </Text>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0} pb={0}>
                {isCheckboxQuestion(question) && (
                  <AnswerCounts
                    countItemComponent={ScreenerAnswerCountItem}
                    questionId={position}
                    countByAnswer={countCheckboxAnswers(
                      question,
                      answers,
                      false
                    )}
                    responseCount={answers.length}
                    sortMethod={SortMethod.PopularityDescending}
                    getKey={getKey}
                  />
                )}

                {isRadioQuestion(question) && (
                  <AnswerCounts
                    countItemComponent={ScreenerAnswerCountItem}
                    questionId={position}
                    countByAnswer={countRadioAnswers(
                      question,
                      answers,
                      false,
                      false
                    )}
                    responseCount={answers.length}
                    sortMethod={SortMethod.PopularityDescending}
                    getKey={getKey}
                  />
                )}
              </TabPanel>
              <TabPanel px={0} pb={0}>
                {answers.length === 0 ? (
                  <NoResponses />
                ) : (
                  <AnswerList
                    question={question}
                    answers={answers}
                    isSelectable={false}
                    isSelectedById={{}}
                    onSelect={noop}
                    onDeselect={noop}
                    showAnswerTagButton={false}
                    showAdminActions={false}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>

          {disqualifyingOptions.length > 0 && (
            <DisqualifyingOptions
              options={disqualifyingOptions.map((o) => o.value)}
            />
          )}
        </Flex>
      </Flex>
    </>
  )
}

const DisqualifyingOptions: React.FC<{
  options: string[]
}> = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Flex direction="column" align="flex-start" gap={2} pb={5}>
      <Button
        variant="subtle"
        size="flush"
        rightIcon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
        onClick={() => setIsOpen((v) => !v)}
      >
        Disqualifying choices
      </Button>

      <Collapse in={isOpen} style={{ width: "100%" }}>
        <Flex direction="column" width="full" gap={3} pt={4}>
          {options.map((option) => (
            <ProgressBox
              key={option}
              progress={0}
              isFilter={false}
              isFiltered={false}
            >
              <Name isLiteral>{option}</Name>
            </ProgressBox>
          ))}
        </Flex>
      </Collapse>
    </Flex>
  )
}
