import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Button as ButtonDS } from "DesignSystem/components"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import Constants from "Constants/shared.json"
import { FigmaIcon } from "Shared/icons/FigmaIcon"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import { ExternalLink } from "~/shared/components/Links/ExternalLink"

import { FormFieldError } from "../../FormFieldError"
import { OptionToggle, OptionToggleButton } from "../../OptionToggle"
import { SectionInstructionField } from "../../SectionFields/SectionInstructionField"
import { InstructionsModalStartPositionSelect } from "../InstructionsModalStartPositionSelect"

import { HelpPopover } from "Components/exports"
import { isSectionRecordingEnabled } from "Components/test-results/section-results/SectionResultsCards/Recordings/useSectionRecordings"
import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"
import { useModal } from "Utilities/modals/use-modal"
import { TestInstructionsChangeSectionTypeModal } from "../../SectionFields/TestInstructionsChangeSectionTypeModal"
import { TestInstructionsField } from "../../SectionFields/TestInstructionsField"
import { FigmaFlowEditor } from "./FigmaFlowEditor/FigmaFlowEditor"
import { FigmaFlowSkeleton } from "./FigmaFlowEditor/FigmaFlowSkeleton"
import { FigmaFlowImport } from "./FigmaFlowImport/FigmaFlowImport"
import { useImportFigmaFlow } from "./FigmaFlowImport/useImportFigmaFlow"
import { useImportUrl } from "./FigmaFlowImport/useImportUrl"
import { StartAndGoalSelection } from "./StartAndGoalSelection"
import { useFigmaFileVersionSync } from "./useFigmaFileVersionSync"

const PrototypeTypeToggle: React.FC<WrappedFieldProps> = ({ input }) => {
  const { section } = useSectionContext()
  const isRecordingEnabled = isSectionRecordingEnabled()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef(null)

  const changeToFreeFlow = () => {
    if (areInstructionsCustomized) {
      openChangeSectionTypeModal({
        handleChangeSectionType: () => input.onChange("free_roam"),
      })
    } else {
      input.onChange("free_roam")
    }

    onClose()
  }

  const areInstructionsCustomized = !!section.test_instructions
  const { open: openChangeSectionTypeModal } = useModal(
    TestInstructionsChangeSectionTypeModal
  )

  const handleChangeSectionType = (sectionType: "task_flow" | "free_roam") => {
    if (sectionType === "free_roam" && isRecordingEnabled) {
      onOpen()
    } else if (areInstructionsCustomized) {
      openChangeSectionTypeModal({
        handleChangeSectionType: () => input.onChange(sectionType),
      })
    } else {
      input.onChange(sectionType)
    }
  }

  return (
    <OptionToggle>
      <OptionToggleButton
        {...input}
        targetValue="task_flow"
        title="Task flow"
        description="Ask participants to complete a task"
        onChange={handleChangeSectionType}
      />
      <OptionToggleButton
        {...input}
        title="Free flow"
        targetValue="free_roam"
        description="Ask questions while participants roam around"
        onChange={handleChangeSectionType}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader
            textStyle="ds.display.emphasized"
            fontWeight={500}
            pt={6}
          >
            Recordings not supported in free flow
          </AlertDialogHeader>
          <AlertDialogCloseButton mt={3} />
          <AlertDialogBody textStyle="ds.paragraph.primary">
            You can switch between 'task' and 'free' flow options. Please note
            that recording is not available in free flow mode.
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonDS variant="secondary" ref={cancelRef} onClick={onClose}>
              Keep as task flow
            </ButtonDS>
            <ButtonDS variant="primary" ml={3} onClick={changeToFreeFlow}>
              Switch to free flow
            </ButtonDS>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </OptionToggle>
  )
}

const WrappedPrototypeSectionFigmaFlow: React.FC<
  React.PropsWithChildren<WrappedFieldProps>
> = ({ meta }) => {
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()

  const { importUrl, setImportUrl, error, figmaFileKey, startNodeId, scaling } =
    useImportUrl()
  const { isImporting, importFigmaFlow } = useImportFigmaFlow(sectionIndex)
  useFigmaFileVersionSync(section.figma_file_flow?.figma_file_version_id)
  const prototypeType = section.prototype_type || "task_flow"

  // Import handler
  const handleImport = () => {
    if (figmaFileKey && startNodeId && scaling) {
      importFigmaFlow(figmaFileKey, startNodeId, scaling)
    }
  }

  return (
    <Stack spacing={4}>
      <Field
        name={`sections[${sectionIndex}].prototype_type`}
        component={PrototypeTypeToggle}
      />
      <HStack align="flex-start" spacing={3}>
        <SectionInstructionField
          rows={1}
          expandable
          placeholder="Keep this short and straightforward"
          required={prototypeType === "task_flow"}
          label="Instructions"
          helpIconSlot={
            <HelpPopover>
              A specific task you may want to ask the participant to perform as
              part of this prototype test. Keep these short and straightforward.
            </HelpPopover>
          }
        />
        <InstructionsModalStartPositionSelect />
      </HStack>

      <TestInstructionsField
        defaultLocalePath={`test.instructions.prototype_task.default_system_instructions.${prototypeType}`}
        tooltipSlot={
          <Flex direction="column" gap={2}>
            <Text>
              Information to ensure participants understand how to interact with
              a prototype test of this type. These instructions will be
              translated if your test has been set up using one of our supported
              languages.
            </Text>
            <Text>
              Customizing test information allows you to edit what is shown to
              participants. We don’t recommend removing them unless in very
              specific situations. If more detailed instructions are needed, add
              an instruction section at the start of the test.
            </Text>
          </Flex>
        }
      />

      <TestCardDivider />

      {isImporting ? (
        <FigmaFlowSkeleton prototypeType={prototypeType} />
      ) : !section.figma_file_flow ? (
        <>
          <FigmaFlowFileNameTitle>Figma prototype</FigmaFlowFileNameTitle>
          <FigmaFlowImport
            handleImport={handleImport}
            setImportUrl={setImportUrl}
            error={error}
            importUrl={importUrl}
          />
          <Text fontSize="sm">
            Ensure the share settings of your file, not only the flow, are set
            to 'Anyone with the link: can view'. For large files, we recommend
            duplicating the flow you{"\u2019"}re testing into a separate file.{" "}
            <ExternalLink
              href={Constants.HELP_CENTER_HOW_TO_SET_UP_FIGMA_PROTOTYPE_URL}
            >
              Learn more.
            </ExternalLink>
          </Text>
        </>
      ) : (
        <>
          <FigmaFlowEditor figmaFileFlow={section.figma_file_flow} />
          <StartAndGoalSelection
            sectionIndex={sectionIndex}
            prototypeType={prototypeType}
            figmaFileFlow={section.figma_file_flow}
          />
        </>
      )}
      <FormFieldError {...meta} />
    </Stack>
  )
}

export const PrototypeSectionFigmaFlow: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const sectionIndex = useSectionIndexContext()

  return (
    <Field
      name={`sections[${sectionIndex}].figma_file_flow`}
      component={WrappedPrototypeSectionFigmaFlow}
    />
  )
}

export const FigmaFlowFileNameTitle: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <HStack align="center">
      <Box>
        <Icon as={FigmaIcon} boxSize={5} m={0.5} />
      </Box>
      <Heading
        as="h4"
        fontSize="md"
        display="inline"
        fontWeight="semibold"
        color="text.primary"
      >
        {children}
      </Heading>
    </HStack>
  )
}
