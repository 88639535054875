import { Icon } from "@chakra-ui/react"
import { CheckIcon } from "Icons/CheckIcon"
import { CloseIcon } from "Icons/CloseIcon"
import { RestrictedIcon } from "Icons/RestrictedIcon"
import React from "react"

export const Checkmark = () => <Icon as={CheckIcon} color="green.300" />

export const Cross = () => <Icon as={CloseIcon} color="red.500" />

export const NotApplicable = () => <Icon as={RestrictedIcon} color="gray.400" />
