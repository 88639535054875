import { Box } from "@chakra-ui/react"
import { STEP_SEQUENCE } from "UsabilityHub/components/Onboarding/constants"
import React from "react"

type Props = {
  index: number
}

export const OnboardingProgress: React.FC<Props> = ({ index }) => {
  return (
    <Box
      h={2}
      w={{ base: "100%", lg: "48" }}
      maxW="155px"
      pos="relative"
      rounded="full"
      sx={{
        "--steps": String(STEP_SEQUENCE.length),
        "--index": String(index),
        "--step-width": "calc((100% + 0.125rem) / var(--steps) - 0.125rem)",
        background: `repeating-linear-gradient(to right, var(--chakra-colors-gray-200) 0%, var(--chakra-colors-gray-200) var(--step-width), transparent var(--step-width), transparent calc(var(--step-width) + 0.125rem) )`,
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          roundedStart: "full",
          width:
            "calc((var(--step-width) + 0.125rem) * var(--index) - 0.125rem)",
          bg: "ds.background.selected.bold.resting",
          transition: "width 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
        },
      }}
    />
  )
}
