import { Flex, useBreakpointValue } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { IconButton } from "DesignSystem/components"
import { ArrowLeftIcon } from "Icons/ArrowLeftIcon"
import { ModeratedStudyContextMenu } from "UsabilityHub/components/ModeratedStudyContextMenu"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Link } from "react-router-dom"
import { usePutApiModeratedStudiesId } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { InPlaceEditor } from "~/usabilityhub/components/InPlaceEditor/InPlaceEditor"
import { HeaderBreadcrumbs, HeaderBreadcrumbsData } from "../HeaderBreadcrumbs"
import { HeaderTitleAndBreadcrumb } from "../HeaderTitleAndBreadcrumb"
import { ModeratedStudyAlerts } from "./ModeratedStudyAlerts"
import { SessionLimitIndicator } from "./SessionLimitIndicator"

export const ModeratedStudySubNavigation = () => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const { moderatedStudyId, moderatedStudy, moderatedStudySummary } =
    useModeratedStudyContext()

  const { mutateAsync } = usePutApiModeratedStudiesId({
    onSuccess: () => {
      const key = queryKeyFn({
        operationId: "getModeratedStudy",
        path: "/api/moderated_studies/{moderated_study_id}",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })
      return queryClient.invalidateQueries(key)
    },
  })

  const handleSave = async (newName: string) => {
    return mutateAsync({
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
      body: {
        internal_name: newName,
        external_name: moderatedStudy.external_name ?? "",
        description: moderatedStudy.description ?? "",
      },
    })
  }

  const showBreadcrumbs = useBreakpointValue<boolean>(
    {
      base: false,
      lg: true,
    },
    { ssr: false }
  )

  const breadcrumbsData = React.useMemo(() => {
    const data: HeaderBreadcrumbsData = [
      { name: "Dashboard", to: ROUTES.DASHBOARD.path },
    ]
    // TODO once projects are supported in moderated studies
    // if (moderatedStudy.project?.name) {
    //   data.push({
    //     name: usabilityTest.project.name,
    //     to:
    //       isSPA && usabilityTest.project.id
    //         ? generateFriendlyProjectPath({
    //             id: usabilityTest.project.id,
    //             name: usabilityTest.project.name,
    //           })
    //         : undefined,
    //   })
    // }
    if (moderatedStudy.internal_name) {
      data.push({
        name: moderatedStudy.internal_name,
        isCurrentPage: true,
      })
    }
    return data
  }, [moderatedStudy.internal_name])

  const renameEditorRef = React.useRef<HTMLDivElement>(null)

  return (
    <HeaderTitleAndBreadcrumb>
      {showBreadcrumbs ? (
        <HeaderBreadcrumbs
          breadcrumbsData={breadcrumbsData}
          replaceCurrentPageWith={
            <Editor
              key="header-breadcrumbs-inline-editor"
              name={moderatedStudy.internal_name}
              handleSave={handleSave}
              triggerRef={renameEditorRef}
            />
          }
        />
      ) : (
        <Flex gap={2} alignItems="center">
          <IconButton
            icon={<ArrowLeftIcon />}
            aria-label="Back"
            variant="secondary"
            as={Link}
            to={ROUTES.DASHBOARD.path}
            flexShrink={0}
          />
          <Editor
            key="header-breadcrumbs-inline-editor"
            name={moderatedStudy.internal_name}
            handleSave={handleSave}
            triggerRef={renameEditorRef}
          />
        </Flex>
      )}

      <Flex gap={2} align="center" flexShrink={0}>
        <Flex display={{ base: "none", lg: "flex" }}>
          {/* moderatedStudySummary exists check prevents a flicker on a false negative before data load */}
          {moderatedStudySummary && (
            <ModeratedStudyAlerts
              showText
              hasAvailabilities={
                !!moderatedStudySummary.has_available_booking_slots
              }
            />
          )}
        </Flex>

        <SessionLimitIndicator />
        <ModeratedStudyContextMenu
          moderatedStudyId={moderatedStudyId}
          studyName={moderatedStudy.internal_name}
          // Archived mod studies can't be edited, so on this page it'll always be false
          isArchived={false}
          onRename={() => {
            renameEditorRef.current?.dispatchEvent(
              new MouseEvent("click", { bubbles: true })
            )
          }}
          createdInfo={
            moderatedStudy.creator
              ? {
                  name: moderatedStudy.creator.name,
                  avatarUrl: moderatedStudy.creator.avatar_url,
                  createdAt: moderatedStudy.created_at,
                }
              : undefined
          }
        />
      </Flex>
    </HeaderTitleAndBreadcrumb>
  )
}

const Editor: React.FC<{
  name: string
  handleSave: (name: string) => Promise<void>
  triggerRef: React.Ref<HTMLDivElement>
}> = ({ name, handleSave, triggerRef }) => (
  <InPlaceEditor
    value={name}
    textStyle="ds.interface.medium"
    color="ds.text.default"
    handleSave={handleSave}
    // Hack to stop noOfLines rule in breadcrumb from clipping descenders (g etc.)
    lineHeight={2}
    inputProps={{
      maxLength: 100,
      size: "sm",
      color: "ds.text.default",
      rounded: "6px",
      _focus: {
        boxShadow: "none",
        borderColor: "ds.border.focused",
      },
    }}
    buttonProps={{ size: "default", alignSelf: "center" }}
    triggerRef={triggerRef}
  />
)
