import { useScreenersFilter } from "Components/test-results/hooks/use-screeners-filter"
import { CountItemProps } from "Components/test-results/question-results/answer-counts/answer-counts"
import { CountItem } from "Components/test-results/question-results/answer-counts/count-item"
import { FC } from "react"
import React from "react"
import { otherSymbol } from "./helpers"

type Props = CountItemProps<string | typeof otherSymbol>

export const ScreenerAnswerCountItem: FC<Props> = ({
  questionId,
  option,
  ...props
}) => {
  const { isFilter, isFiltered, toggleScreenerFilter } = useScreenersFilter(
    questionId,
    option
  )

  let text: string
  if (option === otherSymbol) {
    text = "Other"
  } else if (option === null) {
    text = "Passed"
  } else {
    text = String(option)
  }

  return (
    <CountItem
      {...props}
      isFilter={isFilter}
      isFilterRequested={isFilter}
      isFiltered={isFiltered}
      onFilterChange={toggleScreenerFilter}
      text={text}
      isLiteral={text === option}
    />
  )
}
