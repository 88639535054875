import {
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"

import { CreditCardFormModalButton } from "Components/credit-card-form-modal/credit-card-form-modal"
import { CreditCard } from "Types"

import { CreditCardIcon } from "Icons/CreditCardIcon"
import { getMonthYearString } from "Utilities/date-formats"
import { RemoveCardModal } from "./RemoveCardModal"

type CreditCardOnFile = React.FC<
  React.PropsWithChildren<{
    creditCard?: CreditCard
    manualSubscription: boolean
  }>
>

export const CreditCardOnFile: CreditCardOnFile = ({
  creditCard,
  manualSubscription,
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  return (
    <Stack>
      <Heading>Credit card on file</Heading>
      {manualSubscription && (
        <Text>
          This credit card is used to pay for credit purchases. Your
          subscription is managed and invoiced manually.
        </Text>
      )}
      {creditCard && (
        <HStack>
          <Icon as={CreditCardIcon} />
          {/* Flex required for strange centering issue on final credit card dot section */}
          <Flex display="inline-flex">
            <Text>{"•••• ".repeat(3)}</Text>
          </Flex>
          <Text>{creditCard.last4}</Text>
          {creditCard.expiry && (
            <Text>{`(${getMonthYearString(creditCard.expiry)})`}</Text>
          )}
        </HStack>
      )}
      {creditCard ? (
        <HStack>
          <CreditCardFormModalButton
            modalButtonText="Save new credit card"
            modalHeading="Change my billing details"
            buttonProps={{ variant: "outline" }}
          >
            Update card
          </CreditCardFormModalButton>
          <Button colorScheme="red" onClick={onOpen}>
            Remove card
          </Button>
        </HStack>
      ) : (
        <HStack>
          <CreditCardFormModalButton
            modalButtonText="Add credit card"
            modalHeading="Add a credit card"
            buttonProps={{ variant: "outline" }}
          >
            Add a credit card
          </CreditCardFormModalButton>
        </HStack>
      )}
      <RemoveCardModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  )
}
