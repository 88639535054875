import { DeviceType } from "./deviceType"

export const PERMITTED_DEVICE_TYPES = [
  "any",
  "mobile_only",
  "desktop_only",
] as const

export type PermittedDeviceType = (typeof PERMITTED_DEVICE_TYPES)[number]

/**
 * Reduces a list of `DeviceType`s to a single `PermittedDeviceType` value.
 */
export const deviceTypesToPermittedDeviceType = (
  deviceTypes: DeviceType[]
): PermittedDeviceType => {
  if (deviceTypes.includes("mobile") && deviceTypes.includes("desktop"))
    return "any"
  // Covers the ["mobile", "tablet"] case
  if (deviceTypes.includes("mobile") && !deviceTypes.includes("desktop"))
    return "mobile_only"
  if (deviceTypes.length === 1 && deviceTypes.includes("desktop"))
    return "desktop_only"

  return "any"
}
