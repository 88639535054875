import { Page } from "Shared/components/Page/Page"
import { BottomShadow } from "UsabilityHub/components/BottomShadow"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"
import { UsabilityTestDisabledBanner } from "UsabilityHub/components/UsabilityTestDisabledBanner/UsabilityTestDisabledBanner"
import { UsabilityTestHeader } from "UsabilityHub/components/UsabilityTestHeader/UsabilityTestHeader"
import { UsabilityTestInVariationSetBanner } from "UsabilityHub/components/UsabilityTestInVariationSetBanner/UsabilityTestInVariationSetBanner"
import { UsabilityTestSteps } from "UsabilityHub/components/UsabilityTestSteps/UsabilityTestSteps"
import { PromptConfigurationProvider } from "UsabilityHub/contexts/PromptConfigurationContext"
import React from "react"
import { Outlet, useMatch } from "react-router"
import { useUsabilityTestPage } from "~/api/generated/usabilityhub-components"

export function UsabilityTestLayout() {
  const testId = useMatch("/tests/:testId/*")?.params.testId!

  const { data } = useUsabilityTestPage(
    {
      pathParams: { usabilityTestId: testId },
    },
    { enabled: testId !== "new" }
  )
  const test = data?.usability_test
  const testSet = test?.variation_set

  return (
    <PromptConfigurationProvider>
      <Page>
        <UsabilityHubNavbar showLogoInsteadOfDashboardButton />
        {test && (
          <>
            <UsabilityTestHeader
              redirect
              isDeletionAllowed
              id={test.id}
              uniqueId={testId}
            />
            <BottomShadow>
              <UsabilityTestSteps responseCount={test.response_count} />
            </BottomShadow>
          </>
        )}

        {testSet && (
          <UsabilityTestInVariationSetBanner
            variationSetName={testSet.name}
            variationSetUniqueId={testSet.unique_id}
            variationSetTestCount={testSet.tests_count}
          />
        )}
        {test && test.admin_disabled && (
          <UsabilityTestDisabledBanner
            uniqueId={test.unique_id}
            hasIncorrectLanguage={test.flagged_for_incorrect_language}
          />
        )}

        <Outlet />
      </Page>
    </PromptConfigurationProvider>
  )
}
