import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import { axios, isAxiosErrorWithMessage } from "Services/axios"
import {
  Card,
  CardBody,
  CardFooter,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import { HelpCenterLink } from "Shared/components/Links/HelpCenterLink"
import { SupportMailtoLink } from "Shared/components/Links/SupportMailtoLink"
import { usersPath } from "Shared/constants/routes"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"

type ChangePassword = React.FC<React.PropsWithChildren<unknown>>

export const ChangePassword: ChangePassword = () => {
  const toast = useToast()
  const currentUser = useCurrentUser()
  const { omniauth_service: omniauthService } = currentUser
  const currentUserIsOmniauthed = omniauthService !== null

  return (
    <Formik
      initialValues={{
        current_password: "",
        password: "",
        password_confirmation: "",
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          const { data } = await axios.patch(usersPath(), values)
          toast({
            title: data.message,
            status: "success",
          })
          resetForm()
        } catch (error) {
          if (isAxiosErrorWithMessage(error)) {
            toast({
              title: error.response.data.message,
              status: "error",
            })
          } else {
            throw error
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card>
            <LegacyCardHeader>Change your password</LegacyCardHeader>
            <CardBody>
              {currentUserIsOmniauthed && (
                <Alert status="warning">
                  <AlertIcon />
                  <AlertDescription>
                    You can{"\u2019"}t change your password because your account
                    is authenticated with {omniauthService}.
                    {omniauthService === "Google" && (
                      <>
                        {" "}
                        For more information, head to the{" "}
                        <HelpCenterLink articleSlug="5079020-signing-up-with-google">
                          help center
                        </HelpCenterLink>{" "}
                        or{" "}
                        <SupportMailtoLink>
                          contact our support team
                        </SupportMailtoLink>
                        .
                      </>
                    )}
                  </AlertDescription>
                </Alert>
              )}
              <FormControl isDisabled={currentUserIsOmniauthed} isRequired>
                <FormLabel>Current password</FormLabel>
                <Field name="current_password">
                  {({ field }: FieldProps) => (
                    <Input {...field} type="password" />
                  )}
                </Field>
              </FormControl>
              <FormControl isDisabled={currentUserIsOmniauthed} isRequired>
                <FormLabel>New password</FormLabel>
                <Field name="password">
                  {({ field }: FieldProps) => (
                    <Input {...field} type="password" />
                  )}
                </Field>
              </FormControl>
              <FormControl isDisabled={currentUserIsOmniauthed} isRequired>
                <FormLabel>Confirm new password</FormLabel>
                <Field name="password_confirmation">
                  {({ field }: FieldProps) => (
                    <Input {...field} type="password" />
                  )}
                </Field>
              </FormControl>
              <CardFooter>
                <Button
                  isDisabled={currentUserIsOmniauthed}
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme="brand.primary"
                >
                  Change password
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  )
}
