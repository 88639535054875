import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { DemographicPreset } from "~/api/generated/usabilityhubSchemas"
import { DeletePresetModal } from "./DeletePresetModal"
import { RenamePresetModal } from "./RenamePresetModal"

type Props = {
  preset: DemographicPreset
}

export const PresetRowContextMenu: React.FC<Props> = ({ preset }) => {
  const { open: openRenameModal } = useModal(RenamePresetModal)
  const { open: openDeleteModal } = useModal(DeletePresetModal)

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon boxSize={5} as={MenuKebabIcon} color="gray.500" />}
        size="md"
        variant="solid"
      />
      <MenuList overflow="hidden" zIndex="dropdown">
        <MenuItem onClick={() => openRenameModal({ preset })}>Rename</MenuItem>
        <MenuItem color="red.500" onClick={() => openDeleteModal({ preset })}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
