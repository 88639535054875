import {
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"
import { noop } from "lodash"
import React, { useState } from "react"

import { CheckboxAnswerCounts } from "Components/test-results/question-results/answer-counts/checkbox-answer-counts"
import { RadioAnswerCounts } from "Components/test-results/question-results/answer-counts/radio-answer-counts"
import AnswerList from "Components/test-results/question-results/answer-list/answer-list-container"
import { Props } from "Components/test-results/question-results/props"
import { SortMethod } from "Types"
import {
  SectionResultsQuestionHeading,
  SectionResultsQuestionText,
} from "UsabilityHub/components/TestResults/SectionResultsLabels"
import {
  isCheckboxQuestion,
  isRadioQuestion,
} from "Utilities/usability-test-section-question"
import { Values } from "Utilities/values"

import { BarChartIcon } from "Icons/BarChartIcon"
import { ListIcon } from "Icons/ListIcon"
import { NoResponses } from "./no-responses"

const TabId = {
  AnswerCounts: 0,
  AnswerList: 1,
} as const

export const MultipleChoiceQuestionResults: React.FC<
  React.PropsWithChildren<Props>
> = ({ question, answers }) => {
  const [, setSelectedTabId] = useState<Values<typeof TabId>>(
    TabId.AnswerCounts
  )

  return (
    <Stack spacing={5}>
      <SectionResultsQuestionHeading />
      <SectionResultsQuestionText />
      <Tabs
        isLazy
        onChange={(index: Values<typeof TabId>) => {
          setSelectedTabId(index)
        }}
      >
        <TabList alignItems="center" color="gray.600">
          <Tab>
            <Icon as={BarChartIcon} mr={2} />
            <Text>Totals</Text>
          </Tab>
          <Tab>
            <Icon as={ListIcon} mr={2} />
            <Text>
              Answers <small>{answers.length}</small>
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0} pb={0}>
            {isRadioQuestion(question) && (
              <RadioAnswerCounts
                question={question}
                answers={answers}
                sortMethod={SortMethod.PopularityDescending}
              />
            )}
            {isCheckboxQuestion(question) && (
              <CheckboxAnswerCounts
                question={question}
                answers={answers}
                sortMethod={SortMethod.PopularityDescending}
              />
            )}
          </TabPanel>
          <TabPanel px={0} pb={0}>
            {answers.length === 0 ? (
              <NoResponses />
            ) : (
              <AnswerList
                question={question}
                answers={answers}
                isSelectable={false}
                isSelectedById={{}}
                onSelect={noop}
                onDeselect={noop}
                showAnswerTagButton={false}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
