import { List, ListIcon, ListItem, Text } from "@chakra-ui/react"
import React from "react"

import { LinkButton } from "Components/button/link-button"
import { PoweredLogo } from "Components/powered-logo/powered-logo"
import { CheckIcon } from "Icons/CheckIcon"
import thankYouImage from "Images/app-illustrations/tester-thankyou.svg"
import { newUserRegistrationUrl } from "Shared/constants/routes"
import styles from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/uh-branded-thank-you.module.scss"
import {
  Body,
  Container,
  Header,
  Title,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"

interface Props {
  panelDomain: string
}

export function UhBrandedThankYou({ panelDomain }: Readonly<Props>) {
  const signUpHref = newUserRegistrationUrl(panelDomain, {
    utm_source: "lyssna",
    utm_campaign: "end-of-test-cta",
  })

  return (
    <div className={styles.panelBreakout}>
      <div className={styles.main}>
        <div className={styles.image}>
          <img
            alt="Person sitting at a laptop with a piggy-bank on their desk, and coins being placed into the piggy-bank"
            src={thankYouImage}
          />
        </div>
        <div className={styles.text}>
          <Container>
            <Header>
              <Title>Thanks for the help</Title>
            </Header>
            <Body>
              <Text>
                Found that fun? Join our panel of dedicated testers and get paid
                to take tests and surveys like this one.
              </Text>
              <List mt={4} pb={5} spacing={2}>
                <ListItem>
                  <ListIcon
                    as={CheckIcon}
                    color="brand.secondary.500"
                    position="relative"
                    top="-2px"
                  />
                  Choose your hours of availability
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={CheckIcon}
                    color="brand.secondary.500"
                    position="relative"
                    top="-2px"
                  />
                  Get notified instantly for new tests
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={CheckIcon}
                    color="brand.secondary.500"
                    position="relative"
                    top="-2px"
                  />
                  Give feedback on designs and interfaces
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={CheckIcon}
                    color="brand.secondary.500"
                    position="relative"
                    top="-2px"
                  />
                  Get paid via PayPal
                </ListItem>
              </List>
            </Body>
            <LinkButton colorScheme="brand.secondary" href={signUpHref}>
              Become a tester
            </LinkButton>
            <PoweredLogo />
          </Container>
        </div>
      </div>
    </div>
  )
}
