import { Text } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"

import { CalendarIcon } from "Icons/CalendarIcon"
import { formatBookingDate } from "../formatBookingDate"
import { IconDefinitionList } from "./IconDefinitionList"

type Props = {
  selectedSlot: string | null
  startTime: string | null
  endTime: string | null
  timezone: string
  different: boolean
  booking: NonNullable<ModeratedStudyApplicationFlow["booking"]>
}

export const RescheduleBookingTimes: React.FC<Props> = ({
  selectedSlot,
  startTime,
  endTime,
  timezone,
  different,
  booking,
}) => {
  return (
    <IconDefinitionList>
      <IconDefinitionList.Item icon={CalendarIcon} label="Current">
        <Text
          position="relative"
          display="inline-block"
          _after={{
            content: '""',
            position: "absolute",
            left: 0,
            right: 0,
            top: "50%",
            borderTop: "1px solid currentColor",
            transformOrigin: "0 0",
            transform: `scaleX(${!!selectedSlot && different ? 1 : 0})`,
            transition: "transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {formatBookingDate(booking)}
        </Text>
      </IconDefinitionList.Item>
      <IconDefinitionList.Item icon={CalendarIcon} label="New">
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            key={!selectedSlot || !different ? "empty" : "selected"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {(startTime && endTime && (
              <Text color="green.500">
                {timezone &&
                  formatBookingDate({
                    starts_at: startTime,
                    ends_at: endTime,
                    participant_timezone: timezone,
                  })}
              </Text>
            )) || <Text color="text.secondary">Select a new time</Text>}
          </motion.div>
        </AnimatePresence>
      </IconDefinitionList.Item>
    </IconDefinitionList>
  )
}
