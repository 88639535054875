import { createIcon } from "@chakra-ui/react"
import React from "react"

export const RecordStartIcon = createIcon({
  displayName: "RecordStartIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <g>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
        stroke="currentColor"
        fill="currentColor"
      />
    </g>
  ),
})
