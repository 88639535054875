import {
  Box,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Avatar, Button, IconButton } from "DesignSystem/components"
import { CreditCardIcon } from "Icons/CreditCardIcon"
import { ExamplesIcon } from "Icons/ExamplesIcon"
import { FeatureFlagIcon } from "Icons/FeatureFlagIcon"
import { HelpCenterIcon } from "Icons/HelpCenterIcon"
import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { LockIcon } from "Icons/LockIcon"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { OrganizationIcon } from "Icons/OrganizationIcon"
import { PrivacyIcon } from "Icons/PrivacyIcon"
import { ProjectIcon } from "Icons/ProjectIcon"
import { SettingsSlidersIcon } from "Icons/SettingsSlidersIcon"
import { SignOutIcon } from "Icons/SignOutIcon"
import { TemplateIcon } from "Icons/TemplateIcon"
import { TestingGuidesIcon } from "Icons/TestingGuidesIcon"
import { UserProfileIcon } from "Icons/UserProfileIcon"
import { UsersIcon } from "Icons/UsersIcon"
import { onClickAddOrttoCookieParam } from "JavaScripts/analytics/ortto"
import { getEnvState } from "JavaScripts/state"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { useSignout } from "Shared/hooks/useSignout"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { CreateNewStudyButton } from "UsabilityHub/views/dashboard/CreateNewStudyButton"
import { CreateProjectModal } from "UsabilityHub/views/dashboard/CreateProjectModal"
import { ROUTES } from "UsabilityHub/views/routes"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { Link } from "react-router-dom"
import { FeatureFlagModal } from "../FeatureFlagModal/FeatureFlagModal"
import { CreditsButton } from "./CreditsButton"

type Props = {
  isOnDashboard?: boolean
}

export const MobileMenu: React.FC<Props> = ({ isOnDashboard }) => {
  const signout = useSignout()
  const currentUser = useCurrentUser()

  const showFeatureFlagLink = getEnvState().ANYONE_CAN_EDIT_THEIR_FEATURE_FLAGS
  const { open: openCreateProjectModal } = useModal(CreateProjectModal)
  const { open: showFeatureFlagModal } = useModal(FeatureFlagModal)

  const featureAccessItem = (
    <Button
      variant="secondary"
      justifyContent="flex-start"
      as={RoutedLink}
      to={ROUTES.SETTINGS.FEATURE_ACCESS.path}
      leftIcon={<Icon as={SettingsSlidersIcon} />}
    >
      Feature access
    </Button>
  )

  return (
    <Stack
      display={{ base: "flex", lg: "none" }}
      p={4}
      bg="white"
      borderBottom="2px solid"
      borderColor="gray.100"
    >
      <Flex
        as={RoutedLink}
        to={ROUTES.SETTINGS.PROFILE.path}
        height={12}
        alignSelf="flex-start"
      >
        <Avatar
          appearance="profile"
          name={currentUser.name}
          profileImageSrc={currentUser.avatar_url_medium}
        />
        <Box ml={3}>
          <Text textStyle="ds.paragraph.emphasized">{currentUser.name}</Text>
          <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
            {currentUser.email}
          </Text>
        </Box>
      </Flex>
      <Divider />
      <Flex>
        <Stack width="50%" alignItems="flex-start">
          <CreditsButton justifyContent="flex-start" />
          <Button
            variant="secondary"
            justifyContent="flex-start"
            as={RoutedLink}
            to={ROUTES.SETTINGS.PROFILE.path}
            leftIcon={<Icon as={UserProfileIcon} />}
          >
            Profile
          </Button>
          {currentUser.role !== "admin" && featureAccessItem}
          {currentUser.role === "admin" && (
            <>
              <Button
                variant="secondary"
                justifyContent="flex-start"
                as={RoutedLink}
                to={ROUTES.SETTINGS.ORGANIZATION.path}
                leftIcon={<Icon as={OrganizationIcon} />}
              >
                Organization
              </Button>
              <Button
                variant="secondary"
                justifyContent="flex-start"
                as={RoutedLink}
                to={ROUTES.SETTINGS.TEAM.path}
                leftIcon={<Icon as={UsersIcon} />}
              >
                Team members
              </Button>
              <Button
                variant="secondary"
                justifyContent="flex-start"
                as={RoutedLink}
                to={ROUTES.SETTINGS.BILLING.path}
                leftIcon={<Icon as={CreditCardIcon} />}
              >
                Plan &amp; billing
              </Button>
              {featureAccessItem}
              <Button
                variant="secondary"
                justifyContent="flex-start"
                as={RoutedLink}
                to={ROUTES.SETTINGS.SECURITY.path}
                leftIcon={<Icon as={LockIcon} />}
              >
                Security
              </Button>
            </>
          )}
        </Stack>
        <Stack width="50%" alignItems="flex-start">
          <Button
            variant="secondary"
            justifyContent="flex-start"
            as={RoutedLink}
            to={ROUTES.LEGAL.TERMS.path}
            leftIcon={<Icon as={PrivacyIcon} />}
          >
            Privacy &amp; terms
          </Button>
          <Button
            as="a"
            href={Constants.EXAMPLES_URL}
            variant="secondary"
            justifyContent="flex-start"
            target="_blank"
            rightIcon={<Icon as={LinkExternalIcon} />}
            leftIcon={<Icon as={ExamplesIcon} />}
            onClick={onClickAddOrttoCookieParam}
          >
            Examples
          </Button>
          <Button
            as="a"
            href={Constants.TESTING_GUIDES_URL}
            variant="secondary"
            justifyContent="flex-start"
            target="_blank"
            rightIcon={<Icon as={LinkExternalIcon} />}
            leftIcon={<Icon as={TestingGuidesIcon} />}
            onClick={onClickAddOrttoCookieParam}
          >
            Testing guides
          </Button>
          <Button
            as="a"
            href={Constants.HELP_CENTER_URL}
            variant="secondary"
            justifyContent="flex-start"
            target="_blank"
            rightIcon={<Icon as={LinkExternalIcon} />}
            leftIcon={<Icon as={HelpCenterIcon} />}
          >
            Help center
          </Button>

          {showFeatureFlagLink && (
            <Button
              variant="secondary"
              justifyContent="flex-start"
              leftIcon={<Icon as={FeatureFlagIcon} />}
              onClick={showFeatureFlagModal}
            >
              Feature flags
            </Button>
          )}

          <Button
            variant="secondary"
            justifyContent="flex-start"
            onClick={signout}
            leftIcon={<Icon as={SignOutIcon} />}
          >
            Sign out
          </Button>
        </Stack>
      </Flex>

      {isOnDashboard && (
        <Flex
          gap={2}
          flexBasis={["100%", null, "auto"]}
          display={{ base: "flex", lg: "none" }} // Visible for mobile screens
          ms="auto"
        >
          <Menu placement="bottom-end" eventListeners={false}>
            <MenuButton as={IconButton} icon={<Icon as={MenuKebabIcon} />}>
              More
            </MenuButton>
            <MenuList>
              <MenuItem
                as={Link}
                to={ROUTES.DASHBOARD.TEMPLATES.path}
                icon={<Icon as={TemplateIcon} />}
              >
                Template library
              </MenuItem>
              <MenuItem
                onClick={openCreateProjectModal}
                rounded={"md"}
                icon={<Icon as={ProjectIcon} />}
              >
                Create project
              </MenuItem>
            </MenuList>
          </Menu>

          <CreateNewStudyButton />
        </Flex>
      )}
    </Stack>
  )
}
