import { updateOrttoTrackingUser } from "JavaScripts/analytics/initialize-ortto.js"
import { trackSignedUp } from "JavaScripts/analytics/track"
import { useLocalStorage } from "Shared/hooks/useLocalStorage"
import { STEP_SEQUENCE } from "UsabilityHub/components/Onboarding/constants"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { ROUTES } from "UsabilityHub/views/routes"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useRegisterNewUser } from "~/api/generated/usabilityhub-components"
import { SignUpData } from "./constants"
import { useIntendedPlanId } from "./useIntendedPlan"

export const useSignUp = () => {
  const navigate = useNavigate()
  const intendedPlanId = useIntendedPlanId()
  const refreshTeamMembers = useRefreshTeamMembers()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const [, setRedirect] = useLocalStorage<string | null>("redirect_url", null)
  const [error, setError] = useState<string | null>(null)
  const { mutateAsync: registerNewUser } = useRegisterNewUser()

  const signUp = async (values: SignUpData) => {
    try {
      const { recaptchaResponse, ...user } = values
      const data = await registerNewUser({
        body: {
          user,
          intended_plan_unique_id: intendedPlanId,
          "g-recaptcha-response": recaptchaResponse,
        },
      })
      trackSignedUp("signup_form")

      // to fix an issue where the capture widget isn't updating
      // add a call to ortto to update the user's email right away
      updateOrttoTrackingUser(values.email)

      void refreshCurrentAccount()
      void refreshTeamMembers()

      setRedirect(data.redirect_to || ROUTES.DASHBOARD.path)
      setError(null)
      navigate(
        ROUTES.SETUP.ONBOARDING.buildPath({ stepId: STEP_SEQUENCE[1] }),
        {
          replace: true,
        }
      )
    } catch (error) {
      if ("payload" in error) {
        setError(error.payload.message)
      }
    }
  }
  return { signUp, error, intendedPlanId }
}
