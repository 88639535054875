import { Flex, Spinner, useToast } from "@chakra-ui/react"
import React, { FC, useEffect } from "react"
import { Navigate, Outlet, useParams } from "react-router"

import { UsabilityHubNavbar } from "Components/exports"
import { Page } from "Shared/components/Page/Page"
import { ModeratedStudyStepsSubNavigation } from "UsabilityHub/components/ModeratedStudy/ModeratedStudyStepsSubnav"
import { ModeratedStudySubNavigation } from "UsabilityHub/components/ModeratedStudy/ModeratedStudySubnav"
import { ROUTES } from "UsabilityHub/views/routes"
import {
  useGetModeratedStudy,
  useGetModeratedStudySummary,
} from "~/api/generated/usabilityhub-components"

import { BottomShadow } from "UsabilityHub/components/BottomShadow"
import { ParticipantInfoDrawerContextProvider } from "UsabilityHub/views/ModeratedStudy/interviewer/ParticipantInfoDrawer/ParticipantInfoDrawer"
import { ModeratedStudyContextProvider } from "./ModeratedStudyContext"

type ModeratedStudyEditRouteParams = {
  moderatedStudyId: string
}

export const RootModeratedStudyLayout: FC = () => {
  const { moderatedStudyId } = useParams<ModeratedStudyEditRouteParams>()

  const toast = useToast()

  const {
    data: moderatedStudy,
    error: studyError,
    isLoading: isLoadingStudy,
  } = useGetModeratedStudy({
    pathParams: {
      moderatedStudyId: moderatedStudyId!,
    },
  })

  const { data: moderatedStudySummary } = useGetModeratedStudySummary(
    {
      pathParams: {
        moderatedStudyId: moderatedStudyId!,
      },
    },
    {
      retry: false,
    }
  )

  useEffect(() => {
    if (studyError && studyError.status !== 404)
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        status: "error",
      })
  }, [studyError])

  if (!moderatedStudyId || (studyError && studyError.status === 404)) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <Page title="Setup">
      <UsabilityHubNavbar showLogoInsteadOfDashboardButton />
      {isLoadingStudy && (
        <Flex
          mt="32"
          justifyContent="center"
          alignItems="center"
          direction="column"
          gap="4"
        >
          <Spinner size="lg" thickness="4px" color="gray.500" />
          Fetching...
        </Flex>
      )}
      {!isLoadingStudy && moderatedStudy && (
        <ModeratedStudyContextProvider
          moderatedStudy={moderatedStudy}
          moderatedStudySummary={moderatedStudySummary}
        >
          <ParticipantInfoDrawerContextProvider>
            <ModeratedStudySubNavigation />
            <BottomShadow>
              <ModeratedStudyStepsSubNavigation />
            </BottomShadow>
            <Outlet />
          </ParticipantInfoDrawerContextProvider>
        </ModeratedStudyContextProvider>
      )}
    </Page>
  )
}
