import { Box, Flex, Grid, Spinner } from "@chakra-ui/react"
import { Alert, Button, Heading } from "DesignSystem/components"
import { ArrowLeftIcon } from "Icons/ArrowLeftIcon"
import { PageMain } from "Shared/components/Page/Page"
import { StickyToBottom } from "UsabilityHub/components/StickyToBottom"
import {
  PAGE_CONTENT_MAX_WIDTH,
  STUDY_STEPPER_HEIGHT,
} from "UsabilityHub/constants/layoutConstants"
import React, { PropsWithChildren, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, Navigate } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  useGetNewOrderPage,
  useListDemographics,
} from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../routes"
import { DemographicsPanel } from "./DemographicsPanel"
import { NoOfPanelistsSection } from "./NoOfPanelistsSection"
import { OrderFormProvider } from "./OrderFormProvider"
import { OrderSummaryPanel } from "./OrderSummaryPanel"
import { DEFAULT_NUM_PARTICIPANTS, RESPONSE_SCALE } from "./constants"
import { Panel } from "./types"

const PAGE_BOTTOM_SPACING = "2.5rem"

/**
 * Note:
 *
 * You can use the `enable_submit=true` query param to force the submit
 * button to be enabled for local dev purposes.
 */
export const NewOrderPage: React.FC = () => {
  const { testId: usabilityTestUniqueId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const [panel, setPanel] = useState<Panel>("usabilityhub")

  const {
    data: newOrderPageData,
    isLoading: isNewOrderPageLoading,
    isError: isNewOrderPageError,
  } = useGetNewOrderPage({
    pathParams: { usabilityTestId: usabilityTestUniqueId },
  })

  const {
    data: demographics,
    isLoading: isDemographicsLoading,
    isError: isDemographicsError,
  } = useListDemographics({})

  if (isDemographicsLoading || isNewOrderPageLoading) {
    return (
      <Flex mt="32" justifyContent="center" alignItems="center">
        <Helmet>
          <title>Order responses</title>
        </Helmet>
        <Spinner />
      </Flex>
    )
  }

  if (newOrderPageData?.is_usability_test_archived) {
    return (
      <Navigate
        to={ROUTES.TEST.RECRUIT.buildPath({
          testId: usabilityTestUniqueId,
        })}
      />
    )
  }

  if (isDemographicsError) {
    return (
      <Alert
        status="danger"
        description="There was an error loading the demographics. Try refreshing the page."
      />
    )
  }

  if (isNewOrderPageError) {
    return (
      <Alert
        status="danger"
        description="There was an error loading the test. Try refreshing the page."
      />
    )
  }

  return (
    <PageMain>
      <Flex flexDirection="column" align="center" gap={10} mt={10}>
        <Heading
          as="h1"
          textStyle="ds.display.emphasized"
          mx={8}
          textAlign="center"
          // Balance the wrapped line widths on mobile
          css={{ textWrap: "balance" }}
        >
          Order responses from the Lyssna Panel
        </Heading>
        <Button
          as={Link}
          to={ROUTES.TEST.RECRUIT.buildPath({
            testId: usabilityTestUniqueId,
          })}
          leftIcon={<ArrowLeftIcon />}
        >
          Back to recruitment options
        </Button>

        <OrderFormProvider
          context="usability_tests"
          demographics={demographics}
          testHasScreener={newOrderPageData.has_screener}
          testHasRecordings={newOrderPageData.has_recordings}
          testIsExternal={newOrderPageData.is_external}
          defaultNumberOfPanelists={DEFAULT_NUM_PARTICIPANTS}
        >
          <Grid
            w={["90%", null, null, `min(90vw, ${PAGE_CONTENT_MAX_WIDTH})`]}
            templateColumns={["1fr", null, null, "1fr 26.25rem"]}
            alignItems="start"
            gap={10}
            pb={PAGE_BOTTOM_SPACING}
          >
            <Flex direction="column" gap={4}>
              <Card>
                <NoOfPanelistsSection responseScale={RESPONSE_SCALE}>
                  Number of participants
                </NoOfPanelistsSection>
              </Card>

              <Card>
                <DemographicsPanel
                  showPresets
                  demographics={demographics}
                  activePanel={panel}
                />
              </Card>
            </Flex>
            <StickyToBottom
              stickyElementCSSVarName="order-summary-panel-height"
              // This applies when the panel is shorter than the viewport.
              topOffset={`${STUDY_STEPPER_HEIGHT} + 1rem`}
              bottomOffset={PAGE_BOTTOM_SPACING}
            >
              <OrderSummaryPanel
                demographics={demographics}
                panel={panel}
                setPanel={setPanel}
              />
            </StickyToBottom>
          </Grid>
        </OrderFormProvider>
      </Flex>
    </PageMain>
  )
}

const Card: React.FC<PropsWithChildren> = ({ children }) => (
  <Box
    bg="ds.surface.raised.resting"
    rounded="16px"
    boxShadow="ds.raised"
    p={6}
  >
    {children}
  </Box>
)
