import { Box, Center, Flex, Image, Text } from "@chakra-ui/react"
import {
  SkipUsabilityTestForm,
  SkipUsabilityTestModal,
} from "Components/skip-usability-test-modal/skip-usability-test-modal"
import { Alert, Button, Heading } from "DesignSystem/components"
import { useLanguage } from "Hooks/use-language"
import { State } from "Redux/app-store"
import { getCurrentResponse } from "Redux/reducers/current-response/selectors"
import { axios } from "Services/axios"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ParticipantDeletionReason } from "Types"
import { DocumentPictureInPicture } from "UsabilityHub/components/DocumentPictureInPicture/DocumentPictureInPicture"
import { useDocumentPictureInPictureContext } from "UsabilityHub/components/DocumentPictureInPicture/DocumentPictureInPictureProvider"
import { useSectionRecordingContext } from "UsabilityHub/components/UsabilityTest/context/SectionRecordingContext"
import { ROUTES } from "UserCrowd/views/routes"
import React, { ComponentProps, useRef, useState } from "react"
import { useSelector } from "react-redux"
import responsesApi from "~/api/responsesApi"
import testInterfaceApi from "~/api/testInterfaceApi"
import { OuterProps } from "../../props"
import {
  LiveWebsiteTestControlPanel,
  PipIntroScreen,
} from "./LiveWebsiteTestControlPanel"
import exampleImage from "./lwt_example_image.png"

type Props = {
  usabilityTestSection: OuterProps["usabilityTestSection"]
  isStarted: boolean
  handleStart: () => void
  handleFinish: () => void
  handleTaskEnd: (isLastTask: boolean) => void
  handleTaskStart: () => void
}

export const LiveWebsiteTestTaskActive: React.FC<Props> = ({
  usabilityTestSection,
  isStarted,
  handleStart,
  handleFinish,
  handleTaskEnd,
  handleTaskStart,
}) => {
  const t = useTranslate()
  const languageCode = useSelector(
    (state: State) => state.participantUsabilityTest?.language_code
  )
  const language = useLanguage(languageCode ?? null)
  const { startRecording } = useSectionRecordingContext()

  const {
    open: openPip,
    close: closePip,
    isOpen: isPipOpen,
    isSupported: isPipSupported,
  } = useDocumentPictureInPictureContext()
  const responseId = useSelector(getCurrentResponse)?.id

  const [isReportModalOpen, setIsReportModalOpen] = useState(false)
  const [showFullPageReportForm, setShowFullPageReportForm] = useState(false)
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0)
  const taskTab = useRef<Window | null>(null)

  if (!usabilityTestSection.live_website_test) {
    throw new Error("Live website test not found")
  }

  const tasks = usabilityTestSection.live_website_test.live_website_test_tasks

  const openTaskTab = (index?: number) => {
    const taskWindow = window.open(
      tasks[index ?? currentTaskIndex].url,
      "lwtWindow"
    )

    taskTab.current = taskWindow
  }

  const handleOpenPip = () => {
    if (isPipSupported) {
      openPip(400, window.innerHeight)
    }
  }

  const handleStartLiveWebsiteTest = () => {
    handleStart()
    openTaskTab()
    startRecording(tasks[0].id)
  }

  const handleEndLiveWebsiteTest = () => {
    taskTab.current?.close()
    closePip()
    handleFinish()
  }

  const handleReport = () => {
    setShowFullPageReportForm(true)
  }

  const handleSkip = async (reason: ParticipantDeletionReason) => {
    // TODO: This ought to move to OpenAPI at some point
    await axios.put(responsesApi.cancel.path({ id: responseId }), {
      deletion_reason: reason,
    })

    const dashboardPath = ROUTES.DASHBOARD.path

    window.location.href =
      reason === ParticipantDeletionReason.Skipped
        ? dashboardPath
        : testInterfaceApi.flagged.path()
  }

  const [taskEnded, setTaskEnded] = useState(false)

  const handleNextTask = () => {
    const newIndex = currentTaskIndex + 1
    const isLastTask = newIndex === tasks.length

    if (taskEnded) {
      if (!isLastTask) {
        openTaskTab(newIndex)
      }

      setCurrentTaskIndex(newIndex)

      handleTaskStart()
      setTaskEnded(false)
    } else {
      handleTaskEnd(isLastTask)

      if (isLastTask) {
        setCurrentTaskIndex(newIndex)
      } else {
        setTaskEnded(true)
      }
    }
  }

  return (
    <>
      <Flex
        justify="space-between"
        bg="ds.surface.raised.resting"
        mx="max(-50px, -8vw)" // UsabilityTestLayout adds padding on the left and right, see the .panelScroller class for info
        w="100vw"
      >
        <Center
          display={["none", null, null, "flex"]}
          flexGrow={1}
          flexBasis={0}
          bgColor="ds.surface.sunken"
          p={6}
        >
          <Image maxW="631px" w="full" src={exampleImage} />
        </Center>

        <Center
          flexGrow={1}
          flexBasis={0}
          w="full"
          h="full"
          ps="min(50px, 8vw)"
        >
          <Flex direction="column" maxW="500px" align="flex-start">
            <Heading
              as="h3"
              textStyle="ds.display.primary"
              color="ds.text.default"
              mb={4}
            >
              {t("test.live_website_test.heading")}
            </Heading>

            <Text
              textStyle="ds.paragraph.emphasized"
              color="ds.text.default"
              mb={6}
            >
              {t("test.live_website_test.instructions")}
            </Text>

            <Alert
              status="info"
              description={t("test.live_website_test.keep_tab_open")}
            />

            {!isPipOpen && (
              <Button variant="primary" onClick={handleOpenPip} mt={8}>
                {t("test.live_website_test.reopen_pip")}
              </Button>
            )}
          </Flex>
        </Center>
      </Flex>

      {isPipOpen && (
        <DocumentPictureInPicture>
          {isStarted ? (
            <>
              <LiveWebsiteTestControlPanel
                usabilityTestSection={usabilityTestSection}
                currentTaskIndex={currentTaskIndex}
                handleNextTask={handleNextTask}
                betweenTasks={taskEnded}
                onClose={handleEndLiveWebsiteTest}
                onReport={handleReport}
              />

              {language && (
                <FullPageSkipForm
                  isExternal
                  isOpen={showFullPageReportForm}
                  onClose={() => setShowFullPageReportForm(false)}
                  onSkip={handleSkip}
                  language={language}
                />
              )}
            </>
          ) : showFullPageReportForm ? (
            language && (
              <FullPageSkipForm
                isExternal
                isOpen={showFullPageReportForm}
                onClose={() => setShowFullPageReportForm(false)}
                onSkip={handleSkip}
                language={language}
              />
            )
          ) : (
            <PipIntroScreen
              handleReport={handleReport}
              handleStart={handleStartLiveWebsiteTest}
            />
          )}
        </DocumentPictureInPicture>
      )}

      {language && (
        <SkipUsabilityTestModal
          isExternal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSkip={handleSkip}
          language={language}
        />
      )}
    </>
  )
}

const FullPageSkipForm: React.FC<
  {
    isOpen: boolean
  } & ComponentProps<typeof SkipUsabilityTestForm>
> = ({ isOpen, ...skipFormProps }) => {
  const t = useTranslate()
  return (
    <Flex
      direction="column"
      display={isOpen ? "flex" : "none"}
      position="absolute"
      top={0}
      left={0}
      h="full"
      bg="white"
      gap={2}
      p={4}
      overflowY="scroll"
    >
      <Heading as="h3" textStyle="ds.heading.secondary">
        {t("test.live_website_test.skip_heading")}
      </Heading>
      <Text fontSize="md" fontWeight="normal">
        {t("test.live_website_test.skip_body")}
      </Text>
      <Box mt="auto">
        <SkipUsabilityTestForm {...skipFormProps} />
      </Box>
    </Flex>
  )
}
