import {
  Box,
  Flex,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Avatar, Button, IconButton } from "DesignSystem/components"
import { CalendarAddIcon } from "Icons/CalendarAddIcon"
import { CalendarIcon } from "Icons/CalendarIcon"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { DeleteIcon } from "Icons/DeleteIcon"
import { InfoIcon } from "Icons/InfoIcon"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import { ConflictCalendarModal } from "UsabilityHub/components/ConflictCalendarModal/ConflictCalendarModal"
import { googleCalendarCredentialsError } from "UsabilityHub/components/ModeratedStudy/ModeratedStudyAlerts"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { useModal } from "Utilities/modals/use-modal"
import { pluralizeWithCount } from "Utilities/string"
import React, { useMemo, useState } from "react"
import { usePutModeratedStudyMembers } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyMember } from "~/api/generated/usabilityhubSchemas"
import { useModeratedStudyContext } from "../../ModeratedStudyContext"
import { Card } from "../Card"
import { useStudyDetails } from "../StudyDetailsProvider"
import { AddMember } from "./AddMember"

export const Hosts: React.FC = () => {
  const { moderatedStudyId, moderatedStudy } = useModeratedStudyContext()

  const { teamMembersForm, setMutatingTeamMembers } = useStudyDetails()

  const { reset: resetBookingCalendar } = teamMembersForm

  const { members } = moderatedStudy
  const mainHost = members.find((member) => member.role === "main_host")
  if (!mainHost) throw new Error("missing main host")
  const coHostIds = members
    .filter((member) => member.role === "co_host")
    .map((member) => member.id)
  const activeCollaborators = useTeamMembers({
    ignoreRoles: ["guest", "archived"],
    onlyActive: true,
  })
  const availableCollaborators = useMemo(
    () =>
      activeCollaborators.filter((activeCollaborator) => {
        return !members.some(
          (member) => member.email === activeCollaborator.email
        )
      }),
    [members, activeCollaborators]
  )

  const user = useCurrentUser()
  const currentMember = members.find((m) => m.id === user.id)
  const hasConnectedCalendars =
    currentMember?.has_google_oauth_credentials ||
    currentMember?.has_microsoft_oauth_credentials
  const toast = useToast()
  const queryClient = useQueryClient()
  const { open: openConflictCalendarModal } = useModal(ConflictCalendarModal)
  const [confirmMainHostSwitch, setConfirmMainHostSwitch] =
    useState<ModeratedStudyMember | null>(null)

  const { mutate } = usePutModeratedStudyMembers({
    onMutate: () => setMutatingTeamMembers(true),
    onSettled: () => setMutatingTeamMembers(false),
    onSuccess: (_, variables) => {
      if (variables.body.host_id !== mainHost.id) {
        // Clear the booking calendar if the main host has changed.
        // This will happen on reload but because there's a watch on the form we need to trigger this to update immediately
        resetBookingCalendar({
          userId: variables.body.host_id,
          bookingCalendarId: null,
          bookingCalendarSummary: null,
          bookingCalendarType: null,
        })
      }
      return queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
    },
    onError: (error) => {
      toast({
        title: "There was a problem updating the members",
        description: error.payload.message,
        status: "error",
      })
    },
  })

  const confirmChangeMainHost = (memberId: number) => {
    mutate({
      pathParams: { moderatedStudyId },
      body: {
        host_id: memberId,
        cohost_ids: [mainHost.id, ...coHostIds.filter((id) => id !== memberId)],
      },
    })
  }

  const setMemberRole = (
    memberId: number,
    role: ModeratedStudyMember["role"]
  ) => {
    const member = members.find((m) => m.id === memberId)
    if (!member || member?.role === role) {
      return
    }

    if (role === "main_host") {
      // If we're using Zoom meeting links there's a confirmation modal step
      if (moderatedStudy.location_type === "zoom") {
        setConfirmMainHostSwitch(member)
      } else {
        // Otherwise make the change immediately
        confirmChangeMainHost(member.id)
      }
    }
  }

  const addMember = (memberId: number) => {
    mutate({
      pathParams: { moderatedStudyId: moderatedStudyId },
      body: {
        host_id: mainHost.id,
        cohost_ids: [memberId, ...coHostIds],
      },
    })
  }

  const removeMember = (memberId: number) => {
    mutate({
      pathParams: { moderatedStudyId: moderatedStudyId },
      body: {
        host_id: mainHost.id,
        cohost_ids: coHostIds.filter((id) => id !== memberId),
      },
    })
  }

  return (
    <>
      <Card.Root id="hosts">
        <Card.Header title="Hosts" />
        <Card.Body>
          <Stack spacing={6}>
            <Text as="p" mb={0} textStyle="ds.paragraph.primary">
              Team members added as co-hosts will be invited to all interview
              sessions, and will be notified about any updates for the session.
              Connect calendars to avoid scheduling conflicts for interview
              slots.{" "}
              <Link
                href="https://help.lyssna.com/en/articles/8042472-setting-up-your-moderated-study-with-interviews#h_06529455d9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>
            </Text>
            <Box
              display="grid"
              gridTemplateColumns="[full-start border-start name-start] 1fr [name-end role-start] 9.5rem [role-end calendar-start] 12.5rem [calendar-end border-end actions-start] auto [actions-end full-end]"
              gap={4}
            >
              <Box
                display="grid"
                gridTemplateColumns="subgrid"
                gridColumn="full"
                alignItems="center"
              >
                <Box gridColumn="name">
                  <Text as="div" textStyle="ds.heading.secondary">
                    Hosts
                  </Text>
                </Box>
                <HStack gridColumn="role" align="center" gap={1}>
                  <Text as="div" textStyle="ds.heading.secondary">
                    Role
                  </Text>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label="All hosts receive notifications when participants book or modify a session. Automated emails to participants about their session are sent from the main host."
                  >
                    <Icon as={InfoIcon} color="ds.icon.subtle" />
                  </Tooltip>
                </HStack>
                <HStack gridColumn="calendar" align="center" gap={1}>
                  <Text as="div" textStyle="ds.heading.secondary">
                    Availability
                  </Text>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label="Each host can connect their calendar(s) to check for availability. When booking a session, applicants will only be offered time slots when all hosts are available."
                  >
                    <Icon as={InfoIcon} color="ds.icon.subtle" />
                  </Tooltip>
                </HStack>
              </Box>
              <Box
                display="grid"
                gridColumn="full"
                gridAutoRows="3.5rem"
                gridTemplateColumns="subgrid"
                rowGap={2}
              >
                {members.map((member) => {
                  const isMe = member.id === user.id

                  return (
                    <Box
                      key={member.email}
                      display="grid"
                      gridTemplateColumns="subgrid"
                      gridColumn="full"
                      alignItems="center"
                    >
                      <Box
                        display="grid"
                        gridTemplateColumns="subgrid"
                        gridColumn="border"
                        alignItems="center"
                        boxShadow="inset 0 0 0 1px var(--chakra-colors-gray-200)"
                        px={4}
                        py={3}
                        rounded={8}
                      >
                        <HStack gridColumn="name" alignItems="center" gap={2}>
                          <Avatar
                            size="small"
                            appearance="profile"
                            name={member.name}
                            profileImageSrc={member.avatar}
                          />
                          <Text textStyle="ds.paragraph.emphasized">
                            {member.name}
                          </Text>
                        </HStack>
                        <Box gridColumn="role">
                          <RoleSelect
                            member={member}
                            setMemberRole={setMemberRole}
                            isDisabled={
                              !user.can_manage_tests ||
                              // can't change main host, can only select a new one
                              member.role === "main_host"
                            }
                          />
                        </Box>
                        <Button
                          gridColumn="calendar"
                          aria-label="Check availability on these calendars"
                          isDisabled={!isMe}
                          onClick={() =>
                            openConflictCalendarModal({
                              initialSelectedCalendars:
                                member.conflict_calendars,
                              hasGoogleCalendarError:
                                member.has_google_calendar_error,
                            })
                          }
                        >
                          <Icon
                            as={
                              member.conflict_calendars.length === 0
                                ? CalendarAddIcon
                                : CalendarIcon
                            }
                            boxSize={4}
                            me={2}
                          />
                          {isMe && !hasConnectedCalendars
                            ? "Connect calendar"
                            : pluralizeWithCount(
                                member.conflict_calendars.length,
                                "calendar",
                                "calendars"
                              )}
                          {member.has_google_calendar_error && (
                            <Tooltip
                              label={googleCalendarCredentialsError(
                                "conflict",
                                member === currentMember
                              )}
                            >
                              <Icon
                                as={WarningFilledIcon}
                                color="ds.icon.danger"
                                aria-label="Attention"
                                ml={1}
                              />
                            </Tooltip>
                          )}
                        </Button>
                      </Box>
                      <Tooltip
                        hasArrow
                        placement="top"
                        label="Please assign another main host before removing this host"
                        isDisabled={member.role !== "main_host"}
                      >
                        <Box gridColumn="actions">
                          <IconButton
                            aria-label="Remove member"
                            icon={
                              <DeleteIcon
                                boxSize={5}
                                color="brand.neutral.default"
                              />
                            }
                            variant="secondary"
                            onClick={() => {
                              removeMember(member.id)
                            }}
                            isDisabled={
                              !user.can_manage_tests ||
                              member.role === "main_host"
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            <Box gridColumn="full">
              <AddMember
                label="Add host"
                variant="default"
                collaborators={availableCollaborators}
                onSelect={addMember}
              />
            </Box>
          </Stack>
        </Card.Body>
      </Card.Root>

      {confirmMainHostSwitch && (
        <SwitchHostWhenZoomEnabledModal
          newHost={confirmMainHostSwitch}
          handleClose={() => setConfirmMainHostSwitch(null)}
          handleSwitch={() => confirmChangeMainHost(confirmMainHostSwitch.id)}
        />
      )}
    </>
  )
}

type RoleSelectProps = {
  member: ModeratedStudyMember
  setMemberRole: (memberId: number, role: ModeratedStudyMember["role"]) => void
  isDisabled: boolean
}

const RoleSelect: React.FC<RoleSelectProps> = ({
  member,
  setMemberRole,
  isDisabled,
}) => {
  type StudyRole = {
    value: ModeratedStudyMember["role"]
    title: string
    description: string
  }
  const studyRoles: StudyRole[] = [
    {
      value: "main_host",
      title: "Main host",
      description: "All communication will be sent from the main host",
    },
    {
      value: "co_host",
      title: "Co-host",
      description: "Co-hosts will be added as hosts on all new bookings",
    },
    // {
    //   value: "observer",
    //   title: "Observer",
    //   description: "Will be sent a separate invitation to watch the session",
    // },
  ]

  const assertNever = (role: never): never => {
    throw new Error(`Unsupported role: ${JSON.stringify(role)}`)
  }
  const humanizeRole = (role: ModeratedStudyMember["role"]) => {
    switch (role) {
      case "main_host":
        return "Main host"
      case "co_host":
        return "Co-host"
      case "observer":
        return "Observer"
      default:
        assertNever(role)
    }
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        w="full"
        textAlign="left"
        rightIcon={<ChevronDownIcon />}
        aria-label={`Select this user${"\u2019"}s role in the study`}
        variant="outline"
        isDisabled={isDisabled}
        border="1px solid"
        borderColor={isDisabled ? "ds.border.disabled" : "ds.border.input"}
        bg={isDisabled ? "ds.background.disabled" : "ds.background.input"}
        color={isDisabled ? "ds.text.disabled" : "ds.text.default"}
        _hover={{
          bg: isDisabled ? undefined : "ds.background.input.hovered",
        }}
        ml={-2}
      >
        {humanizeRole(member.role)}
      </MenuButton>

      <MenuList overflow="hidden">
        {studyRoles.map((studyRole, index) => (
          <MenuItem
            key={studyRole.value}
            borderTopColor={index > 0 ? "gray.200" : undefined}
            borderTopWidth={index > 0 ? "1px" : undefined}
            onClick={() => {
              setMemberRole(member.id, studyRole.value)
            }}
            isDisabled={studyRole.value === member.role}
          >
            <VStack width="full" alignItems="start">
              <Text textStyle="ds.paragraph.primary">{studyRole.title}</Text>
              <Text color="text.secondary" textStyle="ds.paragraph.secondary">
                {studyRole.description}
              </Text>
            </VStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

type SwitchHostWhenZoomEnabledModalProps = {
  handleClose: () => void
  handleSwitch: () => void
  newHost: ModeratedStudyMember
}

const SwitchHostWhenZoomEnabledModal: React.FC<
  SwitchHostWhenZoomEnabledModalProps
> = ({ handleClose, handleSwitch, newHost }) => {
  const currentUser = useCurrentUser()
  const newHostIsCurrentUser = currentUser?.id === newHost.id
  const newHostIsOnZoom = newHost.has_zoom_oauth_credentials

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Switch to main host?</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={2}>
              {newHostIsOnZoom || newHostIsCurrentUser ? (
                <Text>
                  This study uses Zoom for meeting links. By proceeding,{" "}
                  {newHostIsCurrentUser ? "you" : newHost.name} will become the
                  Zoom host for the future sessions.
                </Text>
              ) : (
                <Text>
                  {newHost.name} is not connected to Zoom. As this study uses
                  Zoom for meeting links, please contact {newHost.name} to log
                  in and connect their Zoom account.
                </Text>
              )}

              <Text>
                <strong>Note:</strong> Links for previously booked sessions
                remain unchanged.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              onClick={() => {
                handleSwitch()
                handleClose()
              }}
            >
              Confirm change
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
