import { SingleResponseResultDataProvider } from "UsabilityHub/components/ResultsDataProvider/SingleResponseResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React from "react"
import { TestResults } from "./test-results-container"

type Props = {
  responseId: number
}

// Since we render the TestResults component from a mount_react in `admin/responses/show.haml`,
// it won't have all the providers it needs.
export const StandaloneSingleResponseTestResultsWrapper: React.FC<Props> = ({
  responseId,
}) => {
  return (
    <ModalContextProvider>
      <SingleResponseResultDataProvider responseId={responseId}>
        <TestResults />
      </SingleResponseResultDataProvider>
    </ModalContextProvider>
  )
}
