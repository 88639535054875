import {
  Icon,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ROLE_OPTIONS } from "Constants/role"
import { LockFilledIcon } from "Icons/LockFilledIcon"
import { PlanDrawerFeatures, Role } from "Types"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { useCurrentPlan } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { Plan, TeamMember } from "~/api/generated/usabilityhubSchemas"
import { DeleteUserModal } from "./DeleteUserModal"

type UserRoleMenuList = React.FC<
  React.PropsWithChildren<{
    onRoleSelect: (roleValue: string) => void
    selectedPlan?: Readonly<Plan> // used during upgrading plan process
    user?: TeamMember
    isDeletable: boolean
    filterRoleOptions?: Role[]
  }>
>

export const UserRoleMenuList: UserRoleMenuList = ({
  onRoleSelect,
  selectedPlan,
  user,
  isDeletable,
  filterRoleOptions = [],
}) => {
  const plan = useCurrentPlan()

  const requestedFeatures: { [key: string]: PlanDrawerFeatures } = {
    team_permissions: [
      {
        feature: "team_permissions",
      },
    ],
    viewer_permissions: [
      {
        feature: "viewer_permissions",
      },
    ],
  }

  const hasPlanPermission = (feature: string) => {
    let currentPlan = plan
    if (selectedPlan) {
      currentPlan = selectedPlan
    }
    if (currentPlan && feature === "team_permissions") {
      return currentPlan.team_permissions
    }
    if (currentPlan && feature === "viewer_permissions") {
      return currentPlan.viewer_permissions
    }
    return false
  }

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()

  const filteredRoleOptions = ROLE_OPTIONS.filter(
    (role) => !filterRoleOptions.includes(role.value)
  )

  return (
    <>
      <MenuList right={0} width={150} overflow="hidden">
        {filteredRoleOptions.map((role, index) => {
          // Don't show archive button for "invited" users
          if (role.value === "archived" && user && user.sign_in_count === 0)
            return

          return role.requiredFeature &&
            !hasPlanPermission(role.requiredFeature) ? (
            <PlanDrawerTrigger
              key={index}
              as={MenuItem}
              variant="unstyled"
              requestedFeatures={requestedFeatures[role.requiredFeature]}
              source="team_members_page_role_selection"
              flexDirection="row"
              flexWrap="wrap"
              textAlign="left"
              isDisabled={!!selectedPlan}
              p={3}
              display="block"
              h="initial"
              whiteSpace="normal"
            >
              <Text fontSize="sm" pointerEvents="none">
                <Icon
                  as={LockFilledIcon}
                  boxSize={4}
                  verticalAlign="bottom"
                  paddingBottom={1}
                  color="gray.500"
                />
                {role.label}
                {!role.paid && " (unlimited)"}
              </Text>
              <Text fontSize="sm" color="gray.500" pointerEvents="none">
                {role.shortDescription}
              </Text>
            </PlanDrawerTrigger>
          ) : (
            <MenuItem
              key={index}
              onClick={() => onRoleSelect(role.value)}
              flexDirection="row"
              flexWrap="wrap"
              textAlign="left"
            >
              <Text pointerEvents="none">
                {role.label}
                {!role.paid && " (unlimited)"}
              </Text>
              <Text color="gray.500" pointerEvents="none">
                {role.shortDescription}
              </Text>
            </MenuItem>
          )
        })}
        {isDeletable && user && (
          <>
            <MenuDivider m={0} />
            <MenuItem
              onClick={() => {
                onDeleteModalOpen()
              }}
              flexDirection="row"
              flexWrap="wrap"
              textAlign="left"
            >
              <Text flex="1 0 100%" pointerEvents="none" textColor="red.500">
                Delete
              </Text>
            </MenuItem>
            <DeleteUserModal
              isOpen={isDeleteModalOpen}
              onClose={onDeleteModalClose}
              user={user}
            />
          </>
        )}
      </MenuList>
    </>
  )
}
