import {
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react"
import { TestInstructionIcon } from "Icons/TestInstructionIcon"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import React from "react"

export const TreeTestTaskInstructions = () => {
  return (
    <Popover
      trigger="hover"
      placement="right"
      modifiers={[{ name: "preventOverflow", options: { padding: 16 } }]}
    >
      <PopoverTrigger>
        <HStack gap={1} width="max-content">
          <Text as="span" fontSize="sm" fontWeight="semibold">
            Task instructions
          </Text>
          <TestInstructionIcon color="icon.subtle" />
        </HStack>
      </PopoverTrigger>
      <PopoverContent w="25rem">
        <PopoverHeader
          border={0}
          px={4}
          pt={4}
          fontSize="md"
          fontWeight="semibold"
          color="text.subtlest"
        >
          Tree testing
        </PopoverHeader>
        <PopoverBody px={4} pb={4}>
          <Text mb={4}>
            Ask participants to imagine a real-life scenario, using clear and
            simple language. Then instruct them on their objective for the task
            e.g. “Imagine you're shopping online for a new phone. Navigate
            through the categories to find the specs of the latest model.”
          </Text>
          <Text mb={4}>
            If more detailed instructions are needed, add an instruction section
            at the start of the test.
          </Text>
          <Text
            bg="brandPalette.neutral.light"
            rounded={4}
            p={3}
            color="text.subtlest"
          >
            Read more about{" "}
            <ExternalLink
              href="https://help.lyssna.com/en/articles/8993275-tree-test-sections"
              rel="noopener noreferrer"
              target="_blank"
            >
              Tree testing best practices
            </ExternalLink>
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
