import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ProIcon = createIcon({
  displayName: "ProIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M13.3401 5.29478C13.1284 4.94018 13.2531 4.47942 13.4798 4.13491C13.6689 3.84761 13.7795 3.50054 13.7795 3.12676C13.7795 2.13623 13.0032 1.33325 12.0456 1.33325C11.088 1.33325 10.3118 2.13623 10.3118 3.12676C10.3118 3.50054 10.4223 3.84761 10.6114 4.13491C10.8382 4.47942 10.9629 4.94018 10.7511 5.29478L7.75699 10.3093C7.57322 10.6172 7.20415 10.7469 6.87711 10.6186L3.94309 9.46807C3.61861 9.3408 3.46768 8.96064 3.46768 8.60167C3.46768 7.61116 2.69141 6.80819 1.73384 6.80819C0.776267 6.80819 0 7.61116 0 8.60167C0 9.59222 0.776267 10.3952 1.73384 10.3952C1.94238 10.3952 2.14488 10.5239 2.18992 10.7345L3.74039 17.985L3.74144 17.995V18.0299C3.74144 18.0361 3.74635 18.0412 3.75241 18.0412H20.3389C20.3449 18.0412 20.3498 18.0361 20.3498 18.0299V17.995L20.3509 17.985L21.9168 10.662C21.9518 10.4987 22.1045 10.3952 22.2662 10.3952C23.2237 10.3952 24 9.59222 24 8.60167C24 7.61116 23.2237 6.80819 22.2662 6.80819C21.3086 6.80819 20.5323 7.61116 20.5323 8.60167C20.5323 8.97706 20.3777 9.37805 20.0384 9.51108L17.2142 10.6186C16.8871 10.7469 16.5181 10.6172 16.3342 10.3093L13.3401 5.29478Z M19.6198 19.8347C20.023 19.8347 20.3498 20.1728 20.3498 20.5899V21.9114C20.3498 22.3285 20.023 22.6666 19.6198 22.6666H4.47147C4.06831 22.6666 3.74144 22.3285 3.74144 21.9114V20.5899C3.74144 20.1728 4.06831 19.8347 4.47147 19.8347H19.6198Z"
      fill="currentColor"
    />
  ),
})
