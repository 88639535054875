import { useCallback, useContext } from "react"
import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createScreenerFilter } from "../filters/create-filters/screener-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"
const { isFilter, isFiltered, addFilter, removeFilter, toggleFilter } =
  getFilterUtilityFunctions(createScreenerFilter)

export function useScreenersFilter(questionIndex: number, answer: string) {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleAddScreenerFilter = () => {
    setTestResultsFilters((filters) =>
      addFilter(filters, questionIndex, answer)
    )
  }

  const handleRemoveScreenerFilter = () => {
    setTestResultsFilters((filters) =>
      removeFilter(filters, questionIndex, answer)
    )
  }

  const handleToggleFilter = useCallback(() => {
    setTestResultsFilters((filters) =>
      toggleFilter(filters, questionIndex, answer)
    )
  }, [])

  return {
    isFiltered: isFiltered(testResultsFilters, questionIndex, answer),
    isFilter: isFilter(testResultsFilters, questionIndex, answer),
    addScreenerFilter: handleAddScreenerFilter,
    removeScreenerFilter: handleRemoveScreenerFilter,
    toggleScreenerFilter: handleToggleFilter,
  }
}
