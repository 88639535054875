import {
  Alert,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { LyssnaLogoIcon } from "Shared/icons/LyssnaLogoIcon"
import { GoogleSignIn } from "UsabilityHub/components/GoogleSignIn/GoogleSignIn"
import { ROUTES } from "UsabilityHub/views/routes"
import { partial } from "lodash"
import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { Link as RoutedLink } from "react-router-dom"
import { STEP_DEFINITION, SignUpData } from "./constants"

type Props = {
  recaptchaSiteKey: string | null
  intendedPlanId: string
  error: string | null
  signUp: (values: SignUpData) => Promise<void>
}

export const AccountForm: React.FC<Props> = ({
  recaptchaSiteKey,
  intendedPlanId,
  signUp,
  error,
}) => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null)
  const resetRecaptchaResponse = () => {
    recaptchaRef.current?.reset()
  }

  const form = useForm<SignUpData>({
    mode: "onTouched",
    defaultValues: STEP_DEFINITION.defaultValues,
    resolver: yupResolver(STEP_DEFINITION.schema),
  })
  const { handleSubmit, register, setValue } = form
  const errors = form.formState.errors

  const [submitting, setSubmitting] = useState(false)
  const onSubmit = handleSubmit(async () => {
    try {
      setSubmitting(true)
      await signUp(form.getValues())
      resetRecaptchaResponse()
    } finally {
      setSubmitting(false)
    }
  })

  return (
    <>
      <Stack
        as="form"
        spacing={6}
        onSubmit={onSubmit}
        maxW="27.5rem"
        justifySelf="center"
      >
        <Box
          as="fieldset"
          disabled={submitting || undefined}
          display="contents"
        >
          <Stack alignSelf="center" align="center">
            <LyssnaLogoIcon h="3.125rem" w="auto" />
            <Text color="text.subtlest" fontWeight="medium">
              Sign up for free. No credit card required.
            </Text>
          </Stack>
          {error && (
            <Alert status="error" rounded="md">
              {error}
            </Alert>
          )}
          <FormControl isInvalid={!!errors.name || undefined}>
            <FormLabel fontWeight="medium" fontSize="md">
              Your name
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter your full name"
              required
              autoComplete=""
              {...register("name")}
            />
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.email || undefined}>
            <FormLabel fontWeight="medium" fontSize="md">
              Your work email
            </FormLabel>
            <Input
              type="email"
              placeholder="name@company.com"
              required
              autoComplete="email"
              {...register("email")}
            />
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.password || undefined}>
            <FormLabel fontWeight="medium" fontSize="md">
              Password
            </FormLabel>
            <Input
              type="password"
              placeholder="8+ characters"
              required
              {...register("password")}
            />
            {errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>
          {recaptchaSiteKey !== null && (
            <FormControl isInvalid={"recaptchaResponse" in errors}>
              <ReCAPTCHA
                ref={recaptchaRef}
                onErrored={resetRecaptchaResponse}
                onExpired={resetRecaptchaResponse}
                onChange={partial(setValue, "recaptchaResponse")}
                sitekey={recaptchaSiteKey}
              />
              <FormErrorMessage>
                Please confirm you’re not a robot.
              </FormErrorMessage>
            </FormControl>
          )}
          <Button type="submit" variant="solid" colorScheme="brand.primary">
            Create free account
          </Button>
          <Text
            as="span"
            alignSelf="center"
            textTransform="uppercase"
            fontWeight="medium"
            color="text.secondary"
          >
            or
          </Text>
          <GoogleSignIn
            buttonVariant="signup_with"
            intent="sign_up"
            requestParams={{ intended_plan_unique_id: intendedPlanId }}
            alignSelf="stretch"
            display="flex"
            justifyContent="stretch"
            sx={{
              ".g_id_signin": {
                flex: 1,
                "> div": {
                  display: "grid",
                  placeItems: "center",
                },
                "div[role=button]": {
                  div: {
                    justifyContent: "center",
                  },
                  span: {
                    flexGrow: 0,
                  },
                },
              },
            }}
          />
          <Text fontSize="sm" textAlign="center" color="text.subtlest" mt={8}>
            Already have a Lyssna account?{" "}
            <Link as={RoutedLink} to={ROUTES.USERS.SIGN_IN.buildPath({})}>
              Sign in
            </Link>
          </Text>
          <Text
            color="text.subtlest"
            fontSize="sm"
            className="account-setup-small-print"
            my={0}
            mx={{ base: 0, lg: -16 }}
            textAlign="center"
          >
            By signing up, you agree to Lyssna{"\u2019"}s{" "}
            <ExternalLink href={ROUTES.LEGAL.TERMS.buildPath({})} isExternal>
              Terms of Service
            </ExternalLink>{" "}
            and{" "}
            <ExternalLink href={ROUTES.LEGAL.PRIVACY.buildPath({})} isExternal>
              Privacy Policy
            </ExternalLink>
            .
          </Text>
        </Box>
      </Stack>
    </>
  )
}
