import {
  Box,
  BoxProps,
  ButtonGroup,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useMergeRefs,
} from "@chakra-ui/react"
import { UniqueIdentifier } from "@dnd-kit/core"
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { AddIcon } from "Icons/AddIcon"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { DeleteIcon } from "Icons/DeleteIcon"
import { DragHandleIcon } from "Icons/DragHandleIcon"
import { UncheckedCircleIcon } from "Icons/UncheckedCircleIcon"
import { TreeNode } from "Types"
import React, { CSSProperties, forwardRef } from "react"
import { useTreeContext } from "./TreeProvider"

type ItemProps = BoxProps & {
  node: TreeNode
  readOnly?: boolean
  clone?: boolean
  depth?: number
}

const animateLayoutChanges: AnimateLayoutChanges = (args) => {
  const { isSorting, wasDragging } = args

  if (isSorting || wasDragging) {
    return defaultAnimateLayoutChanges(args)
  }

  return true
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ node, readOnly = false, clone, depth, ...props }, ref) => {
    const {
      flattened,
      expand,
      collapse,
      indent,
      outdent,
      remove,
      rename,
      select,
      deselect,
      editing,
      setEditing,
      addSiblingOf,
      addChildOf,
      isSelected,
      isExpanded,
    } = useTreeContext()

    const isEditing = editing === node.id

    const expanded = isExpanded(node)

    const toggle = () => (expanded ? collapse : expand)(node)

    const confirmEdit = (label: string, nextNode?: TreeNode | null) => {
      if (label) {
        rename({ id: node.id, label })
        setEditing(nextNode?.id ?? null)
      }
    }

    const cancelEdit = () => {
      setEditing(null)
      if (node.label) {
        focusNode(node.id, false)
      } else {
        const index = flattened.findIndex((n) => n.id === node.id)
        if (index > 0) {
          focusNode(flattened[index - 1].id, false)
        }
        remove({ id: node.id })
      }
    }

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      confirmEdit(e.currentTarget.value.trim())
    }

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Enter":
          if (!readOnly) {
            confirmEdit(e.currentTarget.value.trim())
            // Shift + Enter -> add a child
            // Enter -> add a sibling
            if (e.shiftKey) {
              addChildOf({ id: node.id })
            } else {
              addSiblingOf({ id: node.id })
            }
          }
          e.preventDefault()
          e.stopPropagation()
          break
        case "Escape":
          cancelEdit()
          e.preventDefault()
          e.stopPropagation()
          break
        case "ArrowLeft":
        case "ArrowRight":
        case "Home":
        case "End":
        case " ":
          e.stopPropagation()
      }
    }

    const focusNode = (id: UniqueIdentifier, forceEditing?: boolean) => {
      const edit = forceEditing ?? isEditing
      if (edit) {
        setEditing(id)
      } else {
        const el = document.querySelector(
          `[data-node-id="${id}"]`
        ) as HTMLElement | null
        el?.focus()
      }
    }

    const handleItemKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case "Enter":
          if (!isEditing) {
            setEditing(node.id)
            e.preventDefault()
            e.stopPropagation()
          }
          break
        case "ArrowUp":
        case "ArrowDown": {
          const index = flattened.findIndex((n) => n.id === node.id)
          if (index === -1) return
          if (index > 0 && e.key === "ArrowUp") {
            focusNode(flattened[index - 1].id)
          } else if (index < flattened.length - 1 && e.key === "ArrowDown") {
            focusNode(flattened[index + 1].id)
          }
          e.preventDefault()
          e.stopPropagation()
          break
        }
        case "ArrowRight":
        case "ArrowLeft":
          e.preventDefault()
          e.stopPropagation()
          if (e.key === "ArrowRight") {
            expand({ id: node.id })
          } else {
            collapse({ id: node.id })
          }
          break
        case "Tab":
          if (!readOnly) {
            e.preventDefault()
            e.stopPropagation()
            if (e.shiftKey) {
              outdent({ id: node.id })
            } else {
              const thisIndex = flattened.findIndex((n) => n.id === node.id)
              const previousIndex = thisIndex - 1
              if (flattened[previousIndex]?.depth === node.depth) {
                expand(flattened[previousIndex])
              }
              indent({ id: node.id })
            }
          }
          break
        case " ":
          e.preventDefault()
          e.stopPropagation()
          if (!readOnly) {
            if (isSelected(node)) {
              deselect({ id: node.id })
            } else {
              select({ id: node.id })
            }
          }
          break
        case "Home":
        case "End":
          e.preventDefault()
          e.stopPropagation()
          if (e.key === "Home") {
            focusNode(flattened[0].id)
          } else {
            focusNode(flattened[flattened.length - 1].id)
          }
          break
      }
    }

    const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if ((e.target as HTMLElement).closest("button, input")) return
      e.currentTarget.focus()
    }

    const {
      attributes,
      listeners,
      setDraggableNodeRef,
      setDroppableNodeRef,
      transform,
      transition,
      isSorting,
      isDragging,
    } = useSortable({
      id: node.id,
      animateLayoutChanges,
      disabled: readOnly,
    })

    const style: CSSProperties = {
      transform: CSS.Translate.toString(transform),
      transition,
    }

    const wrapperRef = useMergeRefs(setDroppableNodeRef, ref)

    const borderColor =
      !isDragging && isSelected(node) ? "green.500" : undefined

    return (
      <Box
        ref={wrapperRef}
        key={node.id}
        pl="calc(var(--depth) * var(--indent))"
        alignItems="center"
        style={{ ...style, "--depth": depth ?? node.depth } as CSSProperties}
        zIndex={isDragging ? 1 : undefined}
        opacity={isDragging ? 0.5 : undefined}
        pos="relative"
        outline="none"
        pointerEvents={clone || isSorting || isDragging ? "none" : undefined}
        data-tree-node-id={node.id}
        {...props}
        sx={{
          ...(props.sx ?? {}),
          "&:hover, &:focus-within": {
            ".actions": {
              opacity: 1,
            },
          },
        }}
      >
        <Grid
          ref={setDraggableNodeRef}
          key={node.id}
          gap="0.375rem"
          pl="0.375rem"
          alignItems="center"
          gridTemplate={
            readOnly
              ? `"expand label actions" 2.5rem / 1.5rem 20rem auto`
              : `"expand handle label actions" 2.5rem / 1.5rem 1.25rem 20rem auto`
          }
          rounded="md"
          aria-expanded={node.right > node.left + 1 ? expanded : undefined}
          data-node-id={node.id}
          tabIndex={0}
          onFocus={(e) =>
            e.currentTarget
              .querySelector<HTMLInputElement>('[type="text"]')
              ?.focus()
          }
          onKeyDown={handleItemKeyDown}
          onClick={handleItemClick}
        >
          {!readOnly && (
            <DragHandleIcon
              gridArea="handle"
              color="gray.500"
              outline="none"
              cursor="grab"
              boxSize={5}
              {...attributes}
              {...listeners}
            />
          )}
          {node.right > node.left + 1 && (
            <IconButton
              variant="ghost"
              size="xs"
              p={0}
              gridArea="expand"
              aria-label={expanded ? "Collapse" : "Expand"}
              icon={<ChevronDownIcon boxSize={6} color="gray.500" />}
              transition="transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
              transform={`rotate(${expanded ? 0 : -90}deg)`}
              onClick={toggle}
            />
          )}
          <InputGroup
            gridArea="label"
            rounded={6}
            boxShadow={clone ? "md" : undefined}
          >
            <Input
              required
              type="text"
              gridArea="label"
              defaultValue={node.label}
              outline="none"
              lineHeight="1.5rem"
              onFocus={(e) => e.target.select()}
              borderStyle={isDragging ? "dashed" : undefined}
              borderColor={borderColor}
              _hover={{ borderColor }}
              readOnly={readOnly || undefined}
              autoFocus={isEditing || undefined}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
            />
            <InputRightElement>
              <Tooltip label="Mark as correct answer">
                <Box
                  gridArea="checkbox"
                  pos="relative"
                  display="grid"
                  placeContent="center"
                  className="actions"
                  opacity={isSelected(node) ? 1 : 0}
                  m={-1}
                  w={6}
                  h={6}
                >
                  {isSelected(node) ? (
                    <CheckCircleFilledIcon color="green.500" boxSize={5} />
                  ) : (
                    <UncheckedCircleIcon color="grey.dark" boxSize={5} />
                  )}
                  {!readOnly && (
                    <Input
                      type="checkbox"
                      pos="absolute"
                      inset={0}
                      opacity={0}
                      checked={isSelected(node) ?? false}
                      cursor="pointer"
                      onChange={(e) =>
                        (e.currentTarget.checked ? select : deselect)(node)
                      }
                    />
                  )}
                </Box>
              </Tooltip>
            </InputRightElement>
          </InputGroup>

          {!readOnly && (
            <ButtonGroup
              className="actions"
              opacity={0}
              gridArea="actions"
              variant="ghost"
              size="sm"
              px={1}
              spacing={2}
            >
              <Tooltip label="Add child node">
                <IconButton
                  aria-label={`Add a child of ${node.label}`}
                  icon={<AddIcon boxSize={5} color="gray.500" />}
                  onClick={() => addChildOf(node)}
                />
              </Tooltip>
              <Tooltip label="Delete node">
                <IconButton
                  aria-label={`Delete ${node.label}`}
                  icon={<DeleteIcon boxSize={5} color="gray.500" />}
                  onClick={() => remove(node)}
                />
              </Tooltip>
            </ButtonGroup>
          )}
        </Grid>
      </Box>
    )
  }
)
