import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "DesignSystem/components"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import { Beacon } from "UsabilityHub/components/Beacon"
import { assertNever } from "UsabilityHub/components/FilterControls/utils"
import { CanceledOrCompleteLabel } from "UsabilityHub/components/recruitment-summary-card/CanceledOrCompleteLabel"
import {
  RecruitmentSummaryCardManageButton,
  RecruitmentSummaryCardManageButtonProps,
} from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCardManageButton"
import React from "react"
import { useCancelModeratedStudyOrder } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudyOrder } from "~/api/generated/usabilityhubSchemas"

type RecruitButtonProps = {
  order: ModeratedStudyOrder
}

export const RecruitingButton: React.FC<RecruitButtonProps> = ({ order }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const manageButtonStates: Record<
    string,
    RecruitmentSummaryCardManageButtonProps
  > = {
    active: {
      buttonLabel: "Recruiting",
      buttonIcon: <Beacon colorScheme="green" />,
      ...{
        menuItems: [
          {
            text: "Cancel order",
            icon: (
              <RemoveFilledIcon boxSize="1.125rem" color="ds.icon.danger" />
            ),
            onClick: onOpen,
          },
        ],
      },
    },
  }

  if (order.state === "canceled" || order.state === "complete") {
    return <CanceledOrCompleteLabel state={order.state} />
  }

  // We're nested inside an AccordionButton, so anything interactive needs to stop propagation to prevent the accordian being toggled
  if (order.state === "active")
    return (
      <>
        <RecruitmentSummaryCardManageButton {...manageButtonStates["active"]} />
        <CancelModal order={order} isOpen={isOpen} onClose={onClose} />
      </>
    )

  // Make sure we get a TS error if we forget to handle a state
  assertNever(order.state as never)
}

type CancelModalProps = {
  order: ModeratedStudyOrder
  isOpen: boolean
  onClose: () => void
}

const CancelModal: React.FC<CancelModalProps> = ({
  order,
  isOpen,
  onClose,
}) => {
  const toast = useToast()
  const { queryKeyFn } = useUsabilityhubContext()
  const queryClient = useQueryClient()
  const { mutate: cancelOrderMutation } = useCancelModeratedStudyOrder({
    onSuccess: () => {
      toast({
        title: "Order canceled",
        status: "success",
      })

      const key = queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/recruitment_links",
        operationId: "getApiModeratedStudiesModeratedStudyIdRecruitmentLinks",
        variables: {
          pathParams: {
            moderatedStudyId: order.moderated_study_id,
          },
        },
      })
      return queryClient.invalidateQueries(key)
    },
    onError: () => {
      toast({
        title: "Unable to cancel your order",
        status: "error",
      })
    },
  })

  const cancelOrder = () => {
    cancelOrderMutation({
      pathParams: {
        moderatedStudyId: order.moderated_study_id,
        id: order.id,
      },
    })
  }

  const bookingsCount = order.booking_counts.upcoming
  const originalSlots = order.requested_response_count
  const emptySlots = order.empty_slots

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel order</ModalHeader>
        <ModalCloseButton />

        <ModalBody as={VStack} spacing={2} align="left">
          {emptySlots < originalSlots ? (
            <>
              <Text>
                {originalSlots - emptySlots} out of {originalSlots} sessions
                have already been fulfilled for this order.
              </Text>

              <Text>
                Canceling this order will only refund the remaining {emptySlots}{" "}
                of {originalSlots} sessions.
                {bookingsCount > 0 &&
                  " It will not cancel any upcoming sessions. These can be managed via the Sessions page."}
              </Text>
            </>
          ) : (
            <Text>Your order will be fully refunded if canceled.</Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Flex gap={3}>
            <Button variant="secondary" size="emphasized" onClick={onClose}>
              Close
            </Button>
            <Button variant="danger" size="emphasized" onClick={cancelOrder}>
              Cancel order
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
