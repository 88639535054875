const sectionAndQuestionRegex =
  /^answer\/section:(?<sectionId>[\d]+)\/question:(?<questionId>[\d]+)\/[\w]+/

const sectionAndScreenshotRegex =
  /^answer\/section:(?<sectionId>[\d]+)\/screenshot:(?<screenshotId>[\d]+)\/[\w]+/

const sectionOnlyRegex = /^answer\/section:(?<sectionId>[\d]+)\/[\w]+/

const screenerQuestionRegex = /^answer\/screener:(?<questionIndex>[\w]+)\/[\w]+/

type SectionAndQuestionCategory =
  `answer/section:${number}/question:${number}/${string}`

type SectionAndQuestion = {
  sectionId: number
  questionId: number
}

type SectionAndScreenshotCategory =
  `answer/section:${number}/screenshot:${number}/${string}`

type ScreenerQuestionCategory = `answer/screener:${string}/${string}`

type ScreenerQuestion = {
  questionIndex: number
}

type SectionAndScreenshot = {
  sectionId: number
  screenshotId: number
}

type SectionOnlyCategory = `answer/section:${number}/${string}`

type SectionOnly = {
  sectionId: number
}

export function getFilterMeta(
  group: SectionAndQuestionCategory
): SectionAndQuestion
export function getFilterMeta(
  group: SectionAndScreenshotCategory
): SectionAndScreenshot
export function getFilterMeta(group: ScreenerQuestionCategory): ScreenerQuestion
export function getFilterMeta(group: SectionOnlyCategory): SectionOnly
export function getFilterMeta(
  group:
    | SectionAndQuestionCategory
    | SectionAndScreenshotCategory
    | SectionOnlyCategory
    | ScreenerQuestionCategory
):
  | SectionAndQuestion
  | SectionAndScreenshot
  | SectionOnly
  | ScreenerQuestion
  | undefined {
  const hasSectionAndQuestion = sectionAndQuestionRegex.test(group)

  if (hasSectionAndQuestion) {
    const match = group.match(sectionAndQuestionRegex)!

    return {
      sectionId: parseInt(match.groups!.sectionId),
      questionId: parseInt(match.groups!.questionId),
    }
  }

  const hasSectionAndScreenshot = sectionAndScreenshotRegex.test(group)
  if (hasSectionAndScreenshot) {
    const match = group.match(sectionAndScreenshotRegex)!

    return {
      sectionId: parseInt(match.groups!.sectionId),
      screenshotId: parseInt(match.groups!.screenshotId),
    }
  }

  const hasSectionOnly = sectionOnlyRegex.test(group)

  if (hasSectionOnly) {
    const match = group.match(sectionOnlyRegex)!

    return {
      sectionId: parseInt(match.groups!.sectionId),
    }
  }

  const hasScreenerQuestion = screenerQuestionRegex.test(group)

  if (hasScreenerQuestion) {
    const match = group.match(screenerQuestionRegex)!

    return {
      questionIndex: parseInt(match.groups!.questionIndex),
    }
  }

  return undefined
}
