import { omitBy } from "lodash"
import { useState } from "react"
import { EmailAddress } from "./types"
import { OnboardingApi } from "./useOnboardingApi"

type Result = { status: "success" } | { status: "failure"; error: string }
type InviteTeamMembersResults = Record<EmailAddress, Result>

export const useInviteTeamMembers = ({
  api,
  alreadyInvited,
}: { api: OnboardingApi; alreadyInvited: EmailAddress[] }) => {
  const [results, setResults] = useState<InviteTeamMembersResults>(() =>
    alreadyInvited.reduce((acc, email) => {
      acc[email] = { status: "success" }
      return acc
    }, {} as InviteTeamMembersResults)
  )
  const invite = async (emails: EmailAddress[]) => {
    if (!emails.length) {
      return true
    }
    // Clear previous errors
    setResults((rs) => omitBy(rs, (r) => r.status === "failure"))
    const result = await Promise.all(
      emails.map(async (email) => {
        try {
          await api.inviteTeamMember({
            name: null,
            email,
            role: "admin",
          })
          setResults((r) => ({ ...r, [email]: { status: "success" } }))
        } catch (e) {
          const error = e.payload?.message
          setResults((r) => ({ ...r, [email]: { status: "failure", error } }))
          throw e // Fail fast
        }
      })
    ).catch(() => false)
    return result !== false
  }

  return { invite, invited: results }
}
