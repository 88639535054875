import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { Button, Heading } from "DesignSystem/components"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ProjectIcon } from "Icons/ProjectIcon"
import { ResultsReadyIcon } from "Icons/ResultsReadyIcon"
import { VariationSetIcon } from "Icons/VariationSetIcon"
import {
  ExternalStudyTag,
  UsabilityTestTag,
} from "Shared/components/StudyTypeTags"
import { Beacon } from "UsabilityHub/components/Beacon"
import {
  SelectableUsabilityTestCard,
  StudyCard,
  UnselectableUsabilityTestCard,
} from "UsabilityHub/components/StudyCard/StudyCard"
import { UsabilityTestContextMenu } from "UsabilityHub/components/UsabilityTestContextMenu/UsabilityTestContextMenu"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import React from "react"
import { Link } from "react-router-dom"
import { Study } from "~/api/generated/usabilityhubSchemas"
import { ROUTES } from "../routes"

type Props = {
  study: Extract<Study, { type: "usability_test" }>
  showProjectInfo?: boolean
  showVariationSetInfo?: boolean
  disableContextMenu: boolean
  combineAdjacent?: boolean
} & (SelectableUsabilityTestCard | UnselectableUsabilityTestCard)

export const UsabilityTestCard: React.FC<Props> = ({
  study,
  disableContextMenu,
  showProjectInfo = true,
  showVariationSetInfo = true,
  combineAdjacent = false,
  ...props
}) => {
  const userCanManageTests = useCurrentUser().can_manage_tests

  const testLink = `/tests/${study.unique_id}/results`

  return (
    <StudyCard
      {...props}
      combineAdjacent={combineAdjacent}
      study={study}
      link={testLink}
      tagSlot={
        study.is_external_study ? <ExternalStudyTag /> : <UsabilityTestTag />
      }
      studyNameSlot={<UsabilityTestName test={study} />}
      statsSlot={<UsabilityTestStats test={study} />}
      contextMenuSlot={
        <UsabilityTestContextMenu
          test={{
            ...study,
            id: Number(study.id),
          }}
          isDisabled={disableContextMenu}
          isExternalStudy={study.is_external_study}
        />
      }
      projectInfoSlot={
        <>
          {showProjectInfo && <UsabilityTestProjectInfo test={study} />}
          {showVariationSetInfo && (
            <UsabilityTestVariationSetInfo test={study} />
          )}
        </>
      }
      manageButtonSlot={
        !study.admin_disabled &&
        userCanManageTests && <UsabilityTestManageButton test={study} />
      }
    />
  )
}

export const TestStatus: React.FC<{
  status: string
  statusTags: string[]
  showDetail: boolean
}> = ({ status, statusTags, showDetail }) => {
  const showDetailsLine = statusTags && showDetail

  return (
    <Flex
      alignItems={showDetailsLine ? "center" : "baseline"}
      color="ds.text.default"
    >
      <Flex
        me={2}
        transform={[
          "translateY(2px)",
          null,
          showDetailsLine ? "translateY(-9px)" : null,
        ]}
      >
        {status === "Ready" ? (
          <ResultsReadyIcon boxSize={4} color="ds.icon.subtle" />
        ) : status === "Complete" ? (
          <CheckCircleFilledIcon color="ds.icon.success" />
        ) : (
          <Beacon colorScheme="brand" boxSize={4} />
        )}
      </Flex>
      <Flex flexDir="column" gap={0.5}>
        <Text textStyle="ds.heading.secondary">{status}</Text>
        {showDetailsLine && (
          <Text textStyle="ds.paragraph.secondary" color="ds.text.subtle">
            {statusTags.join(", ")}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

const UsabilityTestName: React.FC<{
  test: Study
}> = ({ test }) => {
  return (
    <Heading as="h3" textStyle="ds.heading.primary" color="ds.text.default">
      {test.name}

      {test.admin_disabled && (
        <Text as="span" color="red.500" fontSize="xs" ps={1} pt={1}>
          ADMIN DISABLED
        </Text>
      )}
    </Heading>
  )
}

const UsabilityTestStats: React.FC<{
  test: Extract<Study, { type: "usability_test" }>
}> = ({ test }) => {
  return (
    <Flex direction="column" gap={0.5} minW="200px">
      <Text
        textStyle="ds.heading.secondary"
        color="ds.text.default"
        whiteSpace="nowrap"
      >
        {test.response_count}{" "}
        <Text as="span" textStyle="ds.paragraph.primary">
          {test.response_count === 1 ? "Response" : "Responses"}
        </Text>
      </Text>

      <Text textStyle="ds.paragraph.secondary" color="ds.text.subtle">
        {test.status_tags.join(", ")}
      </Text>
    </Flex>
  )
}

const UsabilityTestProjectInfo: React.FC<{ test: Study }> = ({ test }) => {
  if (!test.project) {
    return null
  }

  return (
    <Button
      as={Link}
      to={generateFriendlyProjectPath(test.project)}
      variant="subtle"
      size="flush"
    >
      <Flex gap={1.5} align="center">
        <ProjectIcon />
        <Text
          textStyle="ds.interface.medium"
          color="ds.text.subtle"
          // noOfLines below requires an overflow: hidden rule,
          // so we need a bigger line height so the descenders don't get cut off
          lineHeight={1.25}
          noOfLines={1}
        >
          {test.project.name}
        </Text>
      </Flex>
    </Button>
  )
}

const UsabilityTestVariationSetInfo: React.FC<{
  test: Extract<Study, { type: "usability_test" }>
}> = ({ test }) => {
  if (!test.variation_set) {
    return null
  }

  return (
    <Tooltip hasArrow label="Variation set" placement="top" rounded="md">
      <Button
        as={Link}
        to={ROUTES.TEST_SET.buildPath({
          testSetId: test.variation_set.unique_id,
        })}
        variant="subtle"
        size="flush"
      >
        <Flex gap={1.5} align="center">
          <VariationSetIcon boxSize={3.5} />
          <Text
            textStyle="ds.interface.medium"
            color="ds.text.subtle"
            lineHeight={1.25}
            noOfLines={1}
          >
            {test.variation_set.name}
          </Text>
        </Flex>
      </Button>
    </Tooltip>
  )
}

const UsabilityTestManageButton: React.FC<{ test: Study }> = ({ test }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (test.status === "Ready") {
    return (
      <Button
        w="8.5rem"
        as={Link}
        to={`/tests/${test.unique_id}/recruit`}
        zIndex={1}
      >
        Recruit
      </Button>
    )
  }

  return (
    <Menu isLazy onOpen={onOpen} onClose={onClose}>
      <MenuButton
        rightIcon={<ChevronDownIcon />}
        as={Button}
        w="8.5rem"
        zIndex={1}
        isSelected={isOpen}
      >
        {test.status === "Collecting" ? (
          <Flex justifyContent="center" alignItems="center" gap={2}>
            <Beacon colorScheme="brand" boxSize={4} />
            <Text>Recruiting</Text>
          </Flex>
        ) : (
          <Flex justifyContent="center" alignItems="center" gap={2}>
            <Icon
              as={CheckCircleFilledIcon}
              boxSize={4}
              color="ds.icon.success"
            />
            <Text>Complete</Text>
          </Flex>
        )}
      </MenuButton>
      <Portal>
        <MenuList zIndex="dropdown" overflow="hidden">
          <MenuItem as={Link} to={`/tests/${test.unique_id}/recruit`}>
            Manage recruitment
          </MenuItem>
          <MenuItem as={Link} to={`/tests/${test.unique_id}/results`}>
            View results
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
