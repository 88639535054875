import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { CategoryCardPairCountItem } from "Components/test-results/section-results/SectionResultsCards/CategoryCardPairCountItem"
import { ExpandablePanel } from "Components/test-results/section-results/SectionResultsCards/ExpandablePanel"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { CloseIcon } from "Icons/CloseIcon"
import { SearchIcon } from "Icons/SearchIcon"
import { TestCardSortIcon } from "Icons/TestCardSortIcon"
import React, { useState } from "react"
import { useCardSortResultsContext } from "./CardSortResultsContext"
import {
  SORT_TYPE_LABELS,
  SortType,
  sortCards,
} from "./card-sort-cards/sorting"
import {
  humanizeGroupLabel,
  isItalicCategoryGroupNames,
  notUnsortedGroupLabel,
} from "./card-sort-categories/grouping"

export const CardSortSectionResultsCardsTab: React.FC = () => {
  const { sectionId, cards, groupedCategories, sortData } =
    useCardSortResultsContext()
  const [searchTerm, setSearchTerm] = useState("")
  const [sortType, setSortType] = useState<SortType>("position")

  const filteredCards = cards.filter((c) =>
    c.label.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const groupLabelByCategoryId = Object.entries(groupedCategories).reduce(
    (categoriesGroups, [groupLabel, categories]) => {
      categories.forEach((c) => {
        categoriesGroups[Number(c.id)] = groupLabel
      })
      return categoriesGroups
    },
    {} as Record<string, string>
  )

  const sortedCards = sortCards(filteredCards, sortType, {
    cards: sortData,
    groupLabelByCategoryId,
  })

  return (
    <>
      <Flex gap={2} position="sticky" top={0} zIndex={4} bg="white" py={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} boxSize={5} color="gray.500" />
          </InputLeftElement>
          {searchTerm !== "" && (
            <InputRightElement>
              <IconButton
                variant="ghost"
                size="sm"
                icon={<Icon as={CloseIcon} boxSize={5} color="gray.500" />}
                aria-label="Clear search"
                onClick={() => setSearchTerm("")}
              />
            </InputRightElement>
          )}
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search cards"
          />
        </InputGroup>

        <Menu>
          <MenuButton
            as={Button}
            variant="outline"
            rightIcon={<Icon as={ChevronDownIcon} />}
            fontWeight="normal"
            flexShrink={0}
            data-testid="sort-by-menu"
          >
            Sort: {SORT_TYPE_LABELS[sortType]}
          </MenuButton>
          <MenuList>
            {Object.entries(SORT_TYPE_LABELS).map(
              ([sortTypeValue, sortTypeLabel]) => {
                return (
                  <MenuItem
                    value={sortTypeValue}
                    key={sortTypeValue}
                    onClick={() => setSortType(sortTypeValue as SortType)}
                    fontWeight={sortType === sortTypeValue ? "bold" : "normal"}
                  >
                    {sortTypeLabel}
                  </MenuItem>
                )
              }
            )}
          </MenuList>
        </Menu>
      </Flex>

      {sortedCards.length > 0 ? (
        <Stack spacing={3} divider={<StackDivider />}>
          {sortedCards.map((card) => {
            const relatedSortData = sortData.filter(
              (cardSort) => cardSort.card_sort_card_id === card.id
            )

            const categoryGroupFrequencies = relatedSortData.reduce(
              (acc, cardSortCategoryCard) => {
                const categoryId = Number(
                  cardSortCategoryCard.card_sort_category_id ??
                    cardSortCategoryCard.card_sort_open_category_id
                )

                const groupLabel = groupLabelByCategoryId[categoryId]

                return {
                  ...acc,
                  [groupLabel]: acc[groupLabel] + 1,
                }
              },
              Object.fromEntries(
                Object.keys(groupedCategories).map((groupLabel) => [
                  groupLabel,
                  0,
                ])
              ) as Record<string, number>
            )

            const categoryGroupsByFreq = Object.entries(
              categoryGroupFrequencies
            )
              .filter(([, freq]) => freq > 0)
              .sort(([, freqA], [, freqB]) => freqB - freqA)

            const categoryCountExcludingUnsorted = categoryGroupsByFreq.filter(
              ([label]) => notUnsortedGroupLabel(label)
            ).length

            return (
              <Grid
                templateColumns="repeat(2, minmax(0,1fr))"
                key={card.id}
                gap={4}
              >
                <Box>
                  <Flex
                    align="center"
                    rounded="md"
                    borderWidth="1px"
                    p={4}
                    data-testid="card-sort-card"
                    gap={2}
                  >
                    <Icon as={TestCardSortIcon} boxSize={4} />

                    {card.uploaded_image_url && (
                      <Center
                        bg="white"
                        borderColor="gray.200"
                        borderWidth={1}
                        rounded="sm"
                        minW="100px"
                        w="100px"
                        h="80px"
                      >
                        <Image
                          borderRadius="sm"
                          maxH="100%"
                          maxW="100%"
                          src={card.uploaded_image_url}
                        />
                      </Center>
                    )}
                    <Text wordBreak="break-word">{card.label}</Text>
                    <Text
                      fontSize="xs"
                      color="gray.500"
                      whiteSpace="nowrap"
                      ms="auto"
                    >
                      {categoryCountExcludingUnsorted}{" "}
                      {categoryCountExcludingUnsorted === 1
                        ? "category"
                        : "categories"}
                    </Text>
                  </Flex>
                </Box>
                <Box>
                  <ExpandablePanel
                    numberToShow={3}
                    maxH="188px"
                    pluralEntityName="categories"
                    items={categoryGroupsByFreq.map(([groupLabel, freq]) => {
                      const categories = groupedCategories[groupLabel]
                      return (
                        <CategoryCardPairCountItem
                          key={groupLabel}
                          sectionId={sectionId}
                          categoryIds={categories.map((c) => Number(c.id))}
                          cardId={card.id!}
                          count={freq}
                          total={relatedSortData.length}
                        >
                          <Text
                            textOverflow="ellipsis"
                            overflow="hidden"
                            fontStyle={
                              isItalicCategoryGroupNames(groupLabel, categories)
                                ? "italic"
                                : "normal"
                            }
                            pe={1}
                          >
                            {humanizeGroupLabel(groupLabel)}
                          </Text>
                        </CategoryCardPairCountItem>
                      )
                    })}
                  />
                </Box>
              </Grid>
            )
          })}
        </Stack>
      ) : (
        <Alert wordBreak="break-all">
          <AlertIcon />
          {searchTerm === "" ? (
            <>No category data.</>
          ) : (
            <>
              No cards matched the search &ldquo;{searchTerm}&rdquo;.
              <Link flexShrink={0} onClick={() => setSearchTerm("")} ml={1}>
                Clear search
              </Link>
            </>
          )}
        </Alert>
      )}
    </>
  )
}
