import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { WarningFilledIcon } from "Icons/WarningFilledIcon"
import { GoogleCalendarIcon } from "Shared/icons/GoogleCalendarIcon"
import { MicrosoftOutlookIcon } from "Shared/icons/MicrosoftOutlookIcon"
import { assertNever } from "UsabilityHub/components/FilterControls/utils"
import { googleCalendarCredentialsError } from "UsabilityHub/components/ModeratedStudy/ModeratedStudyAlerts"
import React from "react"
import {
  useGetGoogleCalendars,
  useListOutlookCalendars,
} from "~/api/generated/usabilityhub-components"
import {
  ModeratedStudy,
  ModeratedStudyCalendar,
  ModeratedStudyMember,
} from "~/api/generated/usabilityhubSchemas"

type Props = {
  currentMember: ModeratedStudyMember
  bookingCalendar: ModeratedStudy["booking_calendar"] | null
  newBookingCalendar: {
    summary: string
    provider: ModeratedStudyCalendar["provider"]
  } | null
  setBookingCalendar: (calendar: ModeratedStudyCalendar) => void
}

export const BookingCalendarDropdown: React.FC<Props> = ({
  bookingCalendar,
  newBookingCalendar,
  setBookingCalendar,
  currentMember,
}) => {
  const currentUserIsMainHost = currentMember.role === "main_host"
  const currentUserHasConnectedSomething =
    currentMember.has_google_oauth_credentials ||
    currentMember.has_microsoft_oauth_credentials
  const showGoogle =
    currentUserIsMainHost && currentMember.has_google_oauth_credentials
  const showMicrosoft =
    currentUserIsMainHost && currentMember.has_microsoft_oauth_credentials

  const { data: googleCalendars } = useGetGoogleCalendars(
    {},
    { enabled: showGoogle }
  )

  const { data: outlookCalendars } = useListOutlookCalendars(
    {},
    { enabled: showMicrosoft }
  )

  const allCalendars = [...(googleCalendars ?? []), ...(outlookCalendars ?? [])]

  // The calendars are sorted in provider order, so we can use this to show a provider heading
  let currentProvider: ModeratedStudyCalendar["provider"]

  const tooltip = bookingCalendar?.google_calendar_error
    ? googleCalendarCredentialsError("booking", currentUserIsMainHost)
    : googleCalendars
      ? "Only the Main host can select a booking calendar"
      : "Please connect a calendar first"
  const showTooltip =
    bookingCalendar?.google_calendar_error ||
    !(currentUserHasConnectedSomething && currentUserIsMainHost)

  return (
    <Menu>
      <Tooltip hasArrow rounded="md" isDisabled={!showTooltip} label={tooltip}>
        <MenuButton
          as={Button}
          isDisabled={
            allCalendars.length === 0 ||
            // User's can only see their own calendars and the booking calendar must belong to the main host,
            // so only the main host can set the booking calendar
            !currentUserIsMainHost
          }
          rightIcon={<ChevronDownIcon />}
          textAlign="left"
          aria-label="Select which calendar to use to make bookings"
          variant="outline"
          fontWeight="normal"
          textOverflow="ellipsis"
          justifyContent="flex-start"
        >
          <Text
            as="div"
            // display="block" must come after noOfLines={1} to override
            // its display property for text truncation to work
            noOfLines={1}
            display="block"
            textOverflow="ellipsis"
            // arbitrary minW so it doesn't look weirdly small when there's no Google integration
            minW={28}
          >
            {allCalendars.length > 0 ? (
              newBookingCalendar ? (
                <>
                  {providerIcon(newBookingCalendar.provider)}{" "}
                  {newBookingCalendar.summary}
                </>
              ) : bookingCalendar ? (
                <>
                  {bookingCalendar.google_calendar_error ? (
                    <Icon
                      as={WarningFilledIcon}
                      color="ds.icon.danger"
                      aria-label="Attention"
                    />
                  ) : (
                    providerIcon(bookingCalendar.provider)
                  )}{" "}
                  {bookingCalendar.summary}
                </>
              ) : (
                "Select a calendar"
              )
            ) : null}
          </Text>
        </MenuButton>
      </Tooltip>
      <MenuList maxW="406px" color="text.primary">
        <Text p={3} fontSize="sm" color="text.secondary">
          Main host{"\u2019"}s calendar:
        </Text>
        {allCalendars?.map((calendar) => {
          const providerHeading =
            currentProvider !== calendar.provider
              ? providerHeadingFor(calendar.provider)
              : null

          currentProvider = calendar.provider

          return (
            <React.Fragment key={calendar.id}>
              {providerHeading}
              <MenuItem
                fontSize="sm"
                fontWeight="normal"
                onClick={() => {
                  setBookingCalendar(calendar)
                }}
              >
                <Box
                  // display="block" must come after noOfLines={1} to override
                  // its display property for text truncation to work
                  noOfLines={1}
                  display="block"
                  maxW="100%"
                  whiteSpace="nowrap"
                >
                  <Text as="span">{calendar.summary}</Text>{" "}
                </Box>
              </MenuItem>
            </React.Fragment>
          )
        })}
      </MenuList>
    </Menu>
  )
}

const providerIcon = (provider: ModeratedStudyCalendar["provider"]) => {
  if (!provider) return null

  switch (provider) {
    case "google":
      return <GoogleCalendarIcon boxSize={4} />
    case "microsoft":
      return <MicrosoftOutlookIcon boxSize={4} />
    default:
      return assertNever(provider)
  }
}

const providerFriendlyName = (provider: ModeratedStudyCalendar["provider"]) => {
  if (!provider) return null

  switch (provider) {
    case "google":
      return "Google Calendar"
    case "microsoft":
      return "Microsoft Outlook"
    default:
      return assertNever(provider)
  }
}

const providerHeadingFor = (provider: ModeratedStudyCalendar["provider"]) => {
  return (
    <Flex gap={1.5} align="center" px={3}>
      {providerIcon(provider)}

      <Text
        fontSize="xs"
        color="text.subtlest"
        fontWeight="600"
        textTransform="uppercase"
      >
        {providerFriendlyName(provider)}
      </Text>
    </Flex>
  )
}
