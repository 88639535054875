import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { CheckIcon } from "Icons/CheckIcon"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { DeleteIcon } from "Icons/DeleteIcon"
import { RefreshIcon } from "Icons/RefreshIcon"
import { ReportIcon } from "Icons/ReportIcon"
import { RestrictedIcon } from "Icons/RestrictedIcon"
import { StatusDotIcon } from "Icons/StatusDotIcon"
import React from "react"
import { MarkBookingFunc, isComplete } from "./BookingCard"
import { Booking } from "./types"
import useBookingActions from "./useBookingActions"

type Props = {
  booking: Booking
  bookingActions: ReturnType<typeof useBookingActions>
  onMarkBooking: MarkBookingFunc
}
export const BookingContextMenu: React.FC<Props> = ({
  booking,
  onMarkBooking,
  bookingActions,
}) => {
  const {
    openCancelBookingModal,
    openRescheduleBookingModal,
    markAsComplete,
    markAsPaid,
    markAsNoShow,
    markAsReported,
  } = bookingActions

  const handleCancelBooking = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    openCancelBookingModal()
  }

  const handleRescheduleBooking = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    openRescheduleBookingModal()
  }

  const handleMarkAsComplete = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { confirm, markAsPaid, rating, comment } = await onMarkBooking({
      booking,
      as: "complete",
    })
    if (confirm) {
      markAsComplete({ mark_as_paid: !!markAsPaid, rating, comment })
    }
  }

  const handleMarkAsReported = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { confirm, reportReason, reportLink } = await onMarkBooking({
      booking,
      as: "reported",
    })
    if (confirm) {
      markAsReported({ reportReason, reportLink })
    }
  }

  const handleMarkAsPaid = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    markAsPaid()
  }

  const handleMarkAsNoShow = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { confirm } = await onMarkBooking({
      booking,
      as: "no_show",
    })
    if (confirm) {
      markAsNoShow()
    }
  }

  const bookingHasStarted = booking.starts_at < new Date().toISOString()
  const bookingActive = booking.state === "booked"
  const bookingComplete = isComplete(booking.state)
  const isPanelist = booking.is_panelist

  // Each boolean here corresponds to showing/hiding one menu item below
  const canMarkIncentivePaid =
    bookingComplete && booking.incentive && !booking.incentive_paid
  const canMarkAsComplete = bookingActive && bookingHasStarted
  const canMarkAsNoShow = bookingActive && bookingHasStarted
  const canReport = bookingActive && isPanelist && bookingHasStarted
  const canRequestReschedule = bookingActive
  const canCancel = bookingActive && !bookingHasStarted

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        variant="outline"
        size="sm"
        fontWeight="normal"
        rightIcon={<Icon as={ChevronDownIcon} />}
        position="relative"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        Actions
        {booking.starts_at < new Date().toISOString() &&
          booking.state === "booked" && (
            <StatusDotIcon
              color="red.500"
              position="absolute"
              top="-0.5rem"
              right="-0.5rem"
              boxSize="21px"
              pointerEvents="none"
            />
          )}
      </MenuButton>
      <MenuList overflow="hidden">
        {canMarkIncentivePaid && (
          <MenuItem onClick={handleMarkAsPaid} icon={<CheckIcon />}>
            Mark as Incentive paid
          </MenuItem>
        )}

        {canMarkAsComplete && (
          <MenuItem
            onClick={handleMarkAsComplete}
            icon={<CheckIcon color="green.500" />}
            color="green.500"
          >
            Mark as Completed
          </MenuItem>
        )}

        {canMarkAsNoShow && (
          <MenuItem
            onClick={handleMarkAsNoShow}
            icon={<RestrictedIcon color="red.500" />}
            color="red.500"
          >
            Mark as No-show
          </MenuItem>
        )}

        {canReport && (
          <>
            <MenuDivider my={0} />
            <MenuItem
              onClick={handleMarkAsReported}
              icon={<ReportIcon color="ds.icon.subtle" />}
            >
              Report participant
            </MenuItem>
          </>
        )}

        {canRequestReschedule && (
          <MenuItem onClick={handleRescheduleBooking} icon={<RefreshIcon />}>
            Request to reschedule
          </MenuItem>
        )}

        {canCancel && (
          <MenuItem
            onClick={handleCancelBooking}
            icon={<DeleteIcon color="red.500" />}
            color="red.500"
          >
            Cancel session
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
