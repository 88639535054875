import {
  RawParticipantUsabilityTest,
  RawScreenshot,
  UsabilityTestSection,
} from "Types"
import { Dictionary, keyBy } from "lodash"

// This is the new version of the usabilityTestSectionScreenshotsSelector and usabilityTestScreenshotsSelector in app/frontend/application/javascripts/redux/reducers/screenshots/selectors.ts.

export const getUsabilityTestSectionScreenshots = (
  screenshotsById: Dictionary<RawScreenshot>,
  section: UsabilityTestSection
): RawScreenshot[] => {
  return section.section_screenshots.map(
    (sectionScreenshot) => screenshotsById[sectionScreenshot.screenshot_id]
  )
}

export function getUsabilityTestScreenshots(
  screenshots: ReadonlyArray<RawScreenshot>,
  usabilityTest: RawParticipantUsabilityTest
): ReadonlyArray<Readonly<RawScreenshot>> {
  const screenshotsById = keyBy(screenshots, "id")
  return usabilityTest.sections.flatMap((section: UsabilityTestSection) =>
    getUsabilityTestSectionScreenshots(screenshotsById, section)
  )
}
