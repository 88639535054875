import { DeviceFrame, Dimensions, RawScreenshot, Screenshot } from "Types"
import { maximumDeviceFrameScreenDimensions } from "Utilities/device-frame"
import { containWidth, scaleDown } from "Utilities/dimensions"

// Audio files have no width or height so manually
// set one for the audio player display
const AUDIO_SCREENSHOT_DIMENSIONS = {
  width: 480,
  height: 320,
}

export function screenshotOnScreenDimensions(
  screenshot: Readonly<RawScreenshot>,
  deviceFrame: Readonly<DeviceFrame> | null,
  maxPlayableSize?: Dimensions
): Dimensions {
  switch (screenshot.media_type) {
    case "image":
      // Contain width on images but allow height to overflow
      return containWidth(
        screenshot,
        deviceFrame == null
          ? screenshot.width * screenshot.display_scale
          : maximumDeviceFrameScreenDimensions(deviceFrame).width
      )
    case "video":
      // Scale to fit within container
      return scaleDown(
        screenshot,
        deviceFrame == null
          ? maxPlayableSize || screenshot
          : maximumDeviceFrameScreenDimensions(deviceFrame)
      )
    case "audio":
      // Scale to fit within container
      return scaleDown(
        AUDIO_SCREENSHOT_DIMENSIONS,
        deviceFrame == null
          ? maxPlayableSize || AUDIO_SCREENSHOT_DIMENSIONS
          : maximumDeviceFrameScreenDimensions(deviceFrame)
      )
  }
}

export function screenshotSrc(
  screenshot: Readonly<Screenshot>
): string | undefined {
  return screenshot.url || screenshot._url || undefined
}

export const isScreenshotPlayable = (screenshot: RawScreenshot): boolean =>
  screenshot.media_type === "audio" || screenshot.media_type === "video"
