import {
  Button,
  Center,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { CloseIcon } from "Icons/CloseIcon"
import { SearchIcon } from "Icons/SearchIcon"
import { CardSortType } from "Types"
import React from "react"
import {
  CLOSED_FILTER_TYPE_LABELS,
  ClosedFilterType,
  FilterType,
  OPEN_FILTER_TYPE_LABELS,
} from "./filtering"
import { SORT_TYPE_LABELS, SortType } from "./sorting"

interface Props {
  showGroupingControls: boolean
  groupingEnabled: boolean
  handleGroup: () => void
  disableSelectAll: boolean
  allSelected: boolean
  selectAll: () => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  setSortType: (sortType: SortType) => void
  sortType: SortType
  filterType: FilterType
  setFilterType: (filterType: FilterType) => void
  cardSortType: CardSortType
}

export const ControlBar: React.FC<Props> = ({
  showGroupingControls,
  groupingEnabled,
  handleGroup,
  disableSelectAll,
  allSelected,
  selectAll,
  searchTerm,
  setSearchTerm,
  setSortType,
  sortType,
  filterType,
  setFilterType,
  cardSortType,
}) => {
  return (
    <Flex gap={2} position="sticky" top={0} zIndex={4} bg="white" py={4}>
      {showGroupingControls && (
        <Center h="40px" flexShrink={0}>
          <Checkbox
            variant="mdWithSmFont"
            isChecked={allSelected}
            onChange={selectAll}
            isDisabled={disableSelectAll}
          >
            Select all
          </Checkbox>
        </Center>
      )}

      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIcon} boxSize={5} color="gray.500" />
        </InputLeftElement>
        {searchTerm !== "" && (
          <InputRightElement>
            <IconButton
              variant="ghost"
              size="sm"
              icon={<Icon as={CloseIcon} boxSize={5} color="gray.500" />}
              aria-label="Clear search"
              onClick={() => setSearchTerm("")}
            />
          </InputRightElement>
        )}
        <Input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search categories"
          data-testid="search-bar"
        />
      </InputGroup>

      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<Icon as={ChevronDownIcon} />}
          fontWeight="normal"
          flexShrink={0}
          data-testid="sort-by-menu"
        >
          Sort: {SORT_TYPE_LABELS[sortType]}
        </MenuButton>
        <MenuList>
          {Object.entries(SORT_TYPE_LABELS).map(
            ([sortTypeValue, sortTypeLabel]) => {
              return (
                <MenuItem
                  value={sortTypeValue}
                  key={sortTypeValue}
                  onClick={() => setSortType(sortTypeValue as SortType)}
                  fontWeight={sortType === sortTypeValue ? "bold" : "normal"}
                >
                  {sortTypeLabel}
                </MenuItem>
              )
            }
          )}
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<Icon as={ChevronDownIcon} />}
          fontWeight="normal"
          flexShrink={0}
          data-testid="filter-by-menu"
        >
          Show:{" "}
          {cardSortType === "closed"
            ? CLOSED_FILTER_TYPE_LABELS[filterType as ClosedFilterType]
            : OPEN_FILTER_TYPE_LABELS[filterType]}
        </MenuButton>
        <MenuList>
          {Object.entries(
            cardSortType === "closed"
              ? CLOSED_FILTER_TYPE_LABELS
              : OPEN_FILTER_TYPE_LABELS
          ).map(([filterTypeValue, filterTypeLabel]) => {
            return (
              <MenuItem
                value={filterTypeValue}
                key={filterTypeValue}
                onClick={() => setFilterType(filterTypeValue as FilterType)}
                fontWeight={filterType === filterTypeValue ? "bold" : "normal"}
              >
                {filterTypeLabel}
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>

      {showGroupingControls && (
        <Button
          colorScheme="brand.primary"
          flexShrink={0}
          isDisabled={!groupingEnabled}
          onClick={handleGroup}
        >
          Group
        </Button>
      )}
    </Flex>
  )
}
