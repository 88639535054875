import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTestOrders } from "UsabilityHub/hooks/usePollTestOrders"
import { FunctionalModal } from "Utilities/modals/types"
import React, { useState } from "react"
import { useCancelOrder } from "~/api/generated/usabilityhub-components"
import { Order } from "~/api/generated/usabilityhubSchemas"

type Props = {
  order: Order
}

export const CancelOrderModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  order,
}) => {
  const toast = useToast()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const refreshOrders = useRefreshTestOrders(order.usability_test_unique_id)
  const [reason, setReason] = useState<string | null>(null)

  const { mutate: cancelOrder } = useCancelOrder({
    onSuccess: async (data) => {
      let message = "Order canceled."

      const refundedCredits = data.refunded_credits
      if (refundedCredits) {
        message += ` ${refundedCredits} credits were refunded to your account.`
      }

      toast({ title: message, status: "success" })

      await refreshCurrentAccount()
      await refreshOrders()

      onClose()
    },
    onError: (error) => {
      toast({
        title: error.payload.message ?? "Unable to cancel order",
        status: "error",
      })
    },
  })

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const r = e.target.value.trim()
    setReason(r.length === 0 ? null : r)
  }

  const handleCancel = async () => {
    cancelOrder({
      pathParams: { id: order.id },
      body: { reason },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxW="lg" my="auto">
        <ModalHeader>Are you sure you want to cancel this order?</ModalHeader>

        <ModalBody display="flex" flexDirection="column" gap={2}>
          <Box mb={3}>
            {order.screener ? (
              <>
                <Text>
                  If your order is canceled, any unfilled responses will be
                  refunded to your credit balance.
                </Text>
                <Text>
                  However, as panelists are paid to complete your screener, only
                  part of your screener fees for the unfilled portion of your
                  order may be refunded.
                </Text>
                <Link
                  href={Constants.HELP_CENTER_SCREENER_FEE_REFUND_POLICY_URL}
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Read more about our screener fulfilment policy.
                </Link>
              </>
            ) : (
              <>
                If your order is canceled, any unfilled responses will be
                refunded to your credit balance.
              </>
            )}
          </Box>

          <FormControl>
            <FormLabel fontWeight="medium">
              Please let us know why you{"\u2019"}re canceling
            </FormLabel>
            <Textarea
              textColor="text.primary"
              onChange={handleReasonChange}
              placeholder="Your feedback helps us improve"
              autoFocus
            />
          </FormControl>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button variant="outline" onClick={onClose}>
            Keep my order
          </Button>
          <Button colorScheme="red" onClick={handleCancel}>
            Confirm cancelation
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
