import { Button, Text } from "@chakra-ui/react"
import React from "react"

import CarouselIndicator from "Components/preference-test-layout/carousel-indicator"
import styles from "Components/preference-test-layout/preference-carousel.module.scss"
import { ArrowLeftIcon } from "Icons/ArrowLeftIcon"
import { ArrowRightIcon } from "Icons/ArrowRightIcon"
import { useTranslate } from "Shared/hooks/useTranslate"
import { UsabilityTestSection } from "Types"
import { useTestTakingContext } from "UsabilityHub/components/UsabilityTest/context/TestTakingContext"
import { getUsabilityTestSectionScreenshots } from "UsabilityHub/components/UsabilityTest/screenshots/getUsabilityTestScreenshots"
import { SectionTaskFooter } from "UsabilityHub/components/UsabilityTestSectionTask/SectionTask"

interface Props {
  onPrevious: () => void
  onNext: () => void
  onConfirm: () => void
  selectedIndex: number
  optionCount: number
  section: UsabilityTestSection
}

export const CarouselControls: React.FC<React.PropsWithChildren<Props>> = ({
  onPrevious,
  onNext,
  onConfirm,
  optionCount,
  selectedIndex,
  section,
}) => {
  const translate = useTranslate()

  const { screenshotsById, screenshotsViewed } = useTestTakingContext()

  const sectionScreenshots = getUsabilityTestSectionScreenshots(
    screenshotsById,
    section
  )

  const hasViewedAllScreenshots = sectionScreenshots.every(
    (screenshot) => screenshotsViewed[screenshot.id!]
  )

  return (
    <SectionTaskFooter>
      <CarouselIndicator count={optionCount} current={selectedIndex} />
      <div className={styles.carouselNavButtons}>
        <Button onClick={onPrevious}>
          <ArrowLeftIcon />
        </Button>
        <Button
          colorScheme="brand.primary"
          onClick={onConfirm}
          mx={3}
          isDisabled={!hasViewedAllScreenshots}
        >
          {translate("test.buttons.choose_alternative")}
        </Button>
        <Button onClick={onNext}>
          <ArrowRightIcon />
        </Button>
      </div>
      <Text px={2} py={2} textAlign="center" fontSize="sm" color="gray.600">
        {translate("test.instructions.preference_test.review_all_options")}
      </Text>
    </SectionTaskFooter>
  )
}
