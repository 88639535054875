import { Flex, Icon, Select, SelectProps, Skeleton } from "@chakra-ui/react"
import React, { forwardRef } from "react"

import { DemographicsIcon } from "Icons/DemographicsIcon"
import { useListTimezones } from "~/api/generated/usabilityhub-components"

type Props = {
  showIcon?: boolean
  iconInside?: boolean
  showChevron?: boolean
}

export const TimezoneChanger: React.FC<SelectProps & Props> = forwardRef(
  (
    { iconInside = false, showIcon = true, showChevron = true, ...props },
    ref
  ) => {
    const { data: timezoneData, isLoading } = useListTimezones(
      {},
      {
        // This data changes very rarely, cache aggressively
        cacheTime: 1000 * 60 * 60 * 24, // 1 day
        staleTime: 1000 * 60 * 60 * 24, // 1 day
      }
    )

    if (isLoading) return <Skeleton h={10} my="auto" /> // The <Skeleton/> height must match the real selector's to ensure accurate height calculation for the time slots scrollable view in <BookingCalendar/> within availability preview drawer.
    if (!timezoneData) return null

    return (
      <Flex align="center" position="relative">
        {showIcon &&
          (iconInside ? (
            <Flex position="absolute" left={3.5}>
              <GlobeIcon {...props} />
            </Flex>
          ) : (
            <GlobeIcon {...props} />
          ))}

        <Select
          variant="ghost"
          w="fit-content"
          cursor="pointer"
          background="transparent"
          color="brand.neutral.default"
          ref={ref}
          aria-label="Timezone"
          icon={showChevron ? undefined : <></>}
          _focusVisible={{
            boxShadow: "outline",
            rounded: "md",
          }}
          sx={
            showIcon && iconInside
              ? {
                  "--input-padding": "36px",
                }
              : undefined
          }
          {...props}
        >
          {timezoneData.timezones.map((tz) => (
            <option key={tz.value} value={tz.value}>
              {replaceHyphenWithEnDashForNegatives(tz.label)}
            </option>
          ))}
        </Select>
      </Flex>
    )
  }
)

TimezoneChanger.displayName = "TimezoneChanger"

const GlobeIcon = () => (
  <Icon
    as={DemographicsIcon}
    color="brand.neutral.default"
    fontSize="sm"
    me={2}
  />
)

const replaceHyphenWithEnDashForNegatives = (label: string) =>
  label.replace(/-(\d+)/, "\u2013$1")
