import { Flex } from "@chakra-ui/react"
import { HEADER_TITLE_AND_BREADCRUMB_HEIGHT } from "UsabilityHub/constants/layoutConstants"
import React from "react"

export const HeaderTitleAndBreadcrumb: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Flex
    minH={HEADER_TITLE_AND_BREADCRUMB_HEIGHT}
    alignItems="center"
    justifyContent="space-between"
    gap={4}
    bg="ds.surface.raised.resting"
    borderBottomWidth="1px"
    borderColor="ds.border.default"
    px={{ base: 6, sm: 8, lg: 10 }}
    overflowX="auto"
  >
    {children}
  </Flex>
)
