import {
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react"
import { ModalHeader } from "@chakra-ui/react"
import { Spinner } from "@chakra-ui/react"
import { ModalCloseButton } from "@chakra-ui/react"
import { Center } from "@chakra-ui/react"
import { ModalContent } from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"
import { Grid } from "@chakra-ui/react"
import { Flex, Switch, Text, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import Constants from "Constants/shared.json"
import { Alert, Button } from "DesignSystem/components"
import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"
import { upperFirst } from "lodash"
import React, { Suspense, useEffect, useState } from "react"
import {
  useListDisabledFeatures,
  useListTestsWithRecordingEnabled,
  useToggleFeature,
} from "~/api/generated/usabilityhub-components"

export const RECORDING_KEY = "recordings"

export const FeatureAccessForm: React.FC = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === "admin"
  const { data: disabledFeatures } = useListDisabledFeatures(
    {},
    { suspense: true }
  )

  const {
    open: openRecordingsControlModal,
    close: closeRecordingsControlModal,
  } = useModal(RecordingsControlModal)

  const { mutate: toggleFeature, isLoading: isSubmittingRecordings } =
    useToggleFeature({
      onSuccess: (_data, variables) => {
        closeRecordingsControlModal()
        toast({
          title: `${upperFirst(variables.body.feature_name)} feature access turned ${variables.body.enabled ? "on" : "off"}`,
          status: "success",
        })
        return queryClient.invalidateQueries(["api", "disabled_features"], {
          exact: true,
        })
      },
      onError: (_data, variables) => {
        toast({
          title: `Failed to turn ${variables.body.enabled ? "on" : "off"} ${variables.body.feature_name} feature access`,
          status: "error",
        })
      },
    })

  const onChangeRecordings = (enabled: boolean) => {
    toggleFeature({
      body: {
        feature_name: RECORDING_KEY,
        enabled,
      },
    })
  }

  return (
    <Box
      bgColor="ds.surface.raised.resting"
      borderRadius="16px"
      boxShadow="ds.raised"
      p={6}
      pt={0}
    >
      <Text textStyle="ds.display.primary" my="26px">
        Manage feature access on your account
      </Text>
      <Flex direction="column" gap={6} textStyle="ds.paragraph.primary">
        <Text>
          Enable/disable access to Lyssna features for your entire workspace.
          This setting applies to all members in your team.
        </Text>
        <Flex direction="column">
          <Text textStyle="ds.heading.primary">Recordings</Text>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>
              Gain deeper insights by recording participants' screen
              interactions and their spoken thoughts during test activities.
            </Text>
            <Switch
              isDisabled={!isAdmin}
              isChecked={
                !disabledFeatures?.disabled_features.includes(RECORDING_KEY)
              }
              onChange={(e) =>
                openRecordingsControlModal({
                  isEnabled: e.target.checked,
                  onSubmit: () => onChangeRecordings(!e.target.checked),
                  isSubmitting: isSubmittingRecordings,
                })
              }
            />
          </Flex>
          <Link
            isExternal
            href={Constants.HELP_CENTER_RECORDINGS_URL}
            textStyle="ds.heading.secondary"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
          >
            Learn more
          </Link>
        </Flex>
      </Flex>
    </Box>
  )
}

type Props = {
  isEnabled: boolean
  onSubmit: () => void
  isSubmitting: boolean
}

const RecordingsControlModal: FunctionalModal<Props> = ({
  isEnabled,
  onClose,
  onSubmit,
  isSubmitting,
}) => {
  // When disable the recordings, we need to check if there are any tests that have recordings enabled. So the submit button is disabled by default until we check the list of tests.
  const [canDisableRecordings, setCanDisableRecordings] = useState(isEnabled)

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text textStyle="ds.display.primary">
            {isEnabled ? "Enable" : "Disable"} Recordings
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" textStyle="ds.paragraph.primary" gap={6}>
            <Text>
              Are you sure you want to {isEnabled ? "enable" : "disable"} the
              Recordings feature? This will turn {isEnabled ? "on" : "off"}{" "}
              access for everyone in your workspace. You can{" "}
              {isEnabled ? "disable" : "enable"} it again later if needed.
            </Text>
            {!isEnabled && (
              <Suspense
                fallback={
                  <Center minH="200px">
                    <Spinner />
                  </Center>
                }
              >
                <RecordingEnabledTestList
                  setCanDisableRecordings={setCanDisableRecordings}
                />
              </Suspense>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSubmit}
              isLoading={isSubmitting}
              isDisabled={!canDisableRecordings}
            >
              Yes, {isEnabled ? "enable" : "disable"} Recordings
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const RecordingEnabledTestList: React.FC<{
  setCanDisableRecordings: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setCanDisableRecordings }) => {
  const { data: tests_with_recording_enabled } =
    useListTestsWithRecordingEnabled(
      {},
      { refetchInterval: 10000, suspense: true }
    )

  const testCount = tests_with_recording_enabled?.tests?.length

  useEffect(() => {
    setCanDisableRecordings(!!(testCount === 0))
  }, [testCount])

  if (testCount === 0) {
    return null
  }

  return (
    <Alert
      status="warning"
      description={
        <Flex direction="column" gap={3}>
          <Text textStyle="ds.paragraph.primary">
            You must first turn off the recording requirements for the following
            tests:
          </Text>
          <Box width="full" overflow="scroll" maxH="215px">
            <Grid
              bgColor="ds.background.neutral.resting"
              p={3}
              gap={3}
              width="full"
              borderRadius="8px"
            >
              {tests_with_recording_enabled?.tests?.map((test) => (
                <Flex key={test.unique_id}>
                  <Link
                    href={ROUTES.TEST.EDIT.buildPath({
                      testId: test.unique_id,
                    })}
                    target="_blank"
                    textStyle="ds.heading.secondary"
                  >
                    {test.name}{" "}
                    <Icon
                      as={LinkExternalIcon}
                      ml={0.5}
                      boxSize={4}
                      verticalAlign="middle"
                    />
                  </Link>
                </Flex>
              ))}
            </Grid>
          </Box>
        </Flex>
      }
    />
  )
}
