import { useTestResultViewState } from "Components/test-results/context/test-result-view-state"
import { fetchUpdateFigmaSection } from "~/api/generated/usabilityhub-components"

export const useUpdateFigmaSection = () => {
  const { commonPathNamesBySectionId, setCommonPathNamesBySectionId } =
    useTestResultViewState()

  // Allows updating the commonPathNames of a given section. Handles doing an
  // optimistic update and rollback if the API call fails.
  return async ({
    usabilityTestId,
    sectionId,
    commonPathNames,
  }: {
    usabilityTestId: number
    sectionId: number
    commonPathNames?: { [name: string]: string }
  }) => {
    if (commonPathNames) {
      setCommonPathNamesBySectionId((v) => ({
        ...v,
        [sectionId]: commonPathNames,
      }))
    }

    try {
      await fetchUpdateFigmaSection({
        pathParams: {
          usabilityTestId,
        },
        body: {
          section_id: sectionId,
          common_path_names:
            commonPathNames ?? commonPathNamesBySectionId[sectionId],
        },
      })
    } catch (error) {
      // Revert to the original values if the request fails
      setCommonPathNamesBySectionId(() => commonPathNamesBySectionId)

      throw error
    }
  }
}
