import { createIcon } from "@chakra-ui/react"
import React from "react"

export const EditFilledIcon = createIcon({
  displayName: "EditFilledIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M18.7071 1.29289C18.3166 0.902369 17.6834 0.902369 17.2929 1.29289C16.9024 1.68342 16.9024 2.31658 17.2929 2.70711L21.2929 6.70711C21.6834 7.09763 22.3166 7.09763 22.7071 6.70711C23.0976 6.31658 23.0976 5.68342 22.7071 5.29289L18.7071 1.29289Z"
        fill="currentColor"
      />
      <path
        d="M14.5365 4.28045C14.8378 4.18256 15.1623 4.18256 15.4635 4.28045C15.6962 4.35603 15.8648 4.48158 15.9759 4.57587C16.0717 4.65724 16.1723 4.75787 16.2564 4.84212L19.1578 7.74349C19.2421 7.82769 19.3427 7.92824 19.4241 8.02409C19.5184 8.13517 19.6439 8.30376 19.7195 8.5364C19.8174 8.83766 19.8174 9.16219 19.7195 9.46345C19.6439 9.69608 19.5184 9.86468 19.4241 9.97576C19.3427 10.0716 19.242 10.1722 19.1578 10.2564L8.7703 20.6439C8.58362 20.8308 8.41887 20.9958 8.22797 21.1348C8.05998 21.2572 7.87973 21.3618 7.69012 21.447C7.47465 21.5437 7.24969 21.6049 6.99479 21.6743L2.26314 22.9647C1.91693 23.0591 1.54667 22.9608 1.29292 22.707C1.03917 22.4533 0.940838 22.083 1.03526 21.7368L2.32569 17.0052C2.39504 16.7503 2.45624 16.5253 2.55298 16.3098C2.63811 16.1202 2.74272 15.94 2.86511 15.772C3.00419 15.5811 3.16915 15.4163 3.35606 15.2296L13.7436 4.84215C13.8278 4.75789 13.9283 4.65725 14.0242 4.57587C14.1353 4.48158 14.3039 4.35603 14.5365 4.28045Z"
        fill="currentColor"
      />
    </>
  ),
})
