import { Box } from "@chakra-ui/react"
import { getCurrentResponse } from "Redux/reducers/current-response/selectors"
import { DisplayModal } from "Shared/components/DisplayModal/DisplayModal"
import { useDismissibleNotice } from "Shared/hooks/useDismissibleNotice"
import { UsabilityTestSectionType } from "Types"
import { AnimatePresence, MotionConfig, Variants, motion } from "framer-motion"
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"
import { useSelector } from "react-redux"
import { useSectionRecordingContext } from "../../UsabilityTest/context/SectionRecordingContext"
import { PartipantTestSection } from "../props"
import { NextSectionRecorded } from "./NextSectionRecorded"
import { ThinkAloudDemo } from "./ThinkAloudDemo"

type Props = {
  isDisabled: boolean
  usabilityTestSection: PartipantTestSection
}

type State = "loading" | "thinkAloudDemo" | "recordingPrompt"

type Direction = "left" | "right"

export const SectionRecordingPromptModal: React.FC<Props> = ({
  isDisabled,
  usabilityTestSection,
}) => {
  const { user_id } = useSelector(getCurrentResponse)

  const loggedIn = !!user_id
  const usesTaskLevelRecording =
    usabilityTestSection.type === UsabilityTestSectionType.LiveWebsiteTest

  const { sectionRecordingTypes, startRecording } = useSectionRecordingContext()

  const [state, setState] = useState<State>("loading")

  const [demoRequired, setDemoRequired] = useState<boolean | null>(
    loggedIn ? null : false
  )

  const panelistLoaded = (demoViewed: boolean) => {
    setDemoRequired(!demoViewed)
  }

  useEffect(() => {
    if (demoRequired === null) return

    const shouldShowDemo =
      demoRequired && sectionRecordingTypes.includes("microphone")

    setState(shouldShowDemo ? "thinkAloudDemo" : "recordingPrompt")
  }, [demoRequired, sectionRecordingTypes])

  const [isOpen, setIsOpen] = useState(true)

  const [demoWatched, setDemoWatched] = useState(false)

  const continueAndStartRecording = () => {
    setIsOpen(false)
  }

  const startRecordingIfNecessary = () => {
    // If we are doing task-level recording, the PIP window will kick off the recording.
    // See <LiveWebsiteTestTaskActive /> for more info
    if (!usesTaskLevelRecording) {
      startRecording(null) // Takes either a taskId or null if we're recording the whole section
    }
  }

  const demoCompleted = () => {
    setDemoWatched(true)
    setState("recordingPrompt")
  }

  const direction: Direction =
    state === "recordingPrompt" && !demoRequired ? "right" : "left"

  return (
    <DisplayModal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      closeButton={false}
      overlayProps={{ backdropFilter: "blur(4px)" }}
      modalContentProps={{
        h: ["full", "auto"],
        minH: ["0", state === "thinkAloudDemo" ? "auto" : "500px"],
        maxH: ["calc(100dvh - 40px)", "unset"],
      }}
      onClose={() => setIsOpen(false)}
      onCloseComplete={startRecordingIfNecessary}
    >
      {loggedIn && (
        <PanelistChecker demoWatched={demoWatched} onLoad={panelistLoaded} />
      )}
      <Box
        as={motion.div}
        key="pages"
        layout="position"
        layoutId="pages"
        pos="relative"
        display="grid"
        alignItems="stretch"
        flex={1}
        overflow="clip"
        minH="100%"
      >
        {state !== "loading" && (
          <MotionConfig transition={{ type: "tween", ease: [0.4, 0, 0.2, 1] }}>
            <AnimatePresence
              initial={false}
              mode="popLayout"
              custom={direction}
            >
              {state === "thinkAloudDemo" && (
                <Page
                  key="thinkAloudDemo"
                  id="thinkAloudDemo"
                  direction={direction}
                >
                  <ThinkAloudDemo
                    unskippable={!!demoRequired && !demoWatched}
                    onContinue={demoCompleted}
                  />
                </Page>
              )}
              {state === "recordingPrompt" && (
                <Page
                  key="recordingPrompt"
                  id="recordingPrompt"
                  direction={direction}
                >
                  <NextSectionRecorded
                    isDisabled={isDisabled}
                    usesTaskLevelRecording={!!usesTaskLevelRecording}
                    justWatchedDemo={demoWatched}
                    onContinue={continueAndStartRecording}
                    onShowThinkAloudDemo={() => setState("thinkAloudDemo")}
                  />
                </Page>
              )}
            </AnimatePresence>
          </MotionConfig>
        )}
      </Box>
    </DisplayModal>
  )
}

const variants: Variants = {
  initial: (direction: Direction) => {
    return {
      x: direction === "right" ? "-100%" : "100%",
      opacity: 0,
    }
  },
  in: {
    x: 0,
    opacity: 1,
  },
  out: (direction: Direction) => {
    return {
      x: direction === "right" ? "100%" : "-100%",
      opacity: 0,
    }
  },
}

const Page = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ id: State; direction: Direction }>
>(({ id, direction, children }, ref) => {
  return (
    <Box
      as={motion.div}
      gridArea="1 / 1"
      ref={ref}
      key={id}
      style={{ minHeight: "100%" }}
      variants={variants}
      initial="initial"
      animate="in"
      exit="out"
      custom={direction}
    >
      {children}
    </Box>
  )
})

// Little null component as a way of conditionally calling a hook
const PanelistChecker: React.FC<{
  demoWatched: boolean | null
  onLoad: (demoWatched: boolean) => void
}> = ({ demoWatched, onLoad }) => {
  const {
    isLoading,
    isOpen: showDemo,
    dismiss: dismissDemo,
  } = useDismissibleNotice("think_aloud_demo")

  useEffect(() => {
    if (isLoading) return

    onLoad(!showDemo)
  }, [isLoading, showDemo, onLoad])

  useEffect(() => {
    if (demoWatched) {
      dismissDemo()
    }
  }, [demoWatched, dismissDemo])

  return null
}
