import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { ThemedButton } from "Components/button/themed-button"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { PoweredLogo } from "Components/powered-logo/powered-logo"
import { useTranslate } from "Shared/hooks/useTranslate"
import { TestBranding, TestBrandingType, WelcomeMessageCopy } from "Types"
import CustomerLogo from "UsabilityHub/components/UsabilityTestLayout/CustomerLogo"
import MediaPlaceholder from "UsabilityHub/components/UsabilityTestLayout/MediaPlaceholder"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import {
  Body,
  Container,
  Footer,
  Header,
  Title,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"

import Constants from "Constants/shared.json"
import { Requirements } from "Participants/components/Requirements"
import {
  StudyRequirements,
  isStudyRequirement,
} from "Participants/components/StudyRequirements"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { ExternalStudyWelcome } from "UsabilityHub/components/UsabilityTestSectionTask/SectionTasks/ExternalStudyTask/ExternalStudyWelcome"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { LiveWebsiteTestBrowserNotSupportedPage } from "./LiveWebsiteTestBrowserNotSupportedPage"
import styles from "./intro-outro.module.scss"

interface Props {
  isLoaded: boolean
  onStart: () => void
  copy: WelcomeMessageCopy
  branding: TestBranding
  hasPlayableScreenshot: boolean
  allowedRecordingTypes: RecordingType[]
  isPanelist: boolean
  isExternalStudy: boolean
  isLiveWebsiteTest: boolean
}

export const WelcomeMessage: React.FC<React.PropsWithChildren<Props>> = ({
  copy,
  isLoaded,
  onStart,
  branding,
  hasPlayableScreenshot = false,
  allowedRecordingTypes = [],
  isPanelist = false,
  isExternalStudy,
  isLiveWebsiteTest,
}) => {
  const translate = useTranslate()

  const hasRecordings = allowedRecordingTypes?.length > 0
  const additionalPermissions = []
  if (isLiveWebsiteTest) {
    additionalPermissions.push("external_website")
  }

  const studyRequirements = [
    ...allowedRecordingTypes,
    ...additionalPermissions,
  ].filter(isStudyRequirement)

  if (isExternalStudy) {
    return <ExternalStudyWelcome handleStart={onStart} />
  }

  const isLiveWebsiteTestCompatibleBrowser =
    "documentPictureInPicture" in window
  const isLiveWebsiteTestAndIncompatibleBrowser =
    isLiveWebsiteTest && !isLiveWebsiteTestCompatibleBrowser

  if (isLiveWebsiteTestAndIncompatibleBrowser) {
    return <LiveWebsiteTestBrowserNotSupportedPage />
  }

  return (
    <div className={styles.container}>
      <div className={styles.scroller}>
        {branding.type !== TestBrandingType.UsabilityHub &&
          branding.logo_url && (
            <div className={styles.customerLogo}>
              <CustomerLogo url={branding.logo_url} />
            </div>
          )}
        <div className={styles.content}>
          <Container>
            <Header>
              <Title>{copy.welcome_heading}</Title>
            </Header>
            <Body>
              <DisplayBlockMarkdownText text={copy.welcome_message} />
              {(hasRecordings || isLiveWebsiteTest) && (
                <Text mt={4}>{translate("test.welcome.welcome_prompt")}: </Text>
              )}
            </Body>
            <Footer>
              <Box>
                {hasPlayableScreenshot && (
                  <Alert mb={3}>
                    <AlertIcon />
                    <Box flex="1">
                      <AlertTitle>
                        {translate("test.welcome.audio_video_heading")}
                      </AlertTitle>
                      <AlertDescription>
                        {translate("test.welcome.audio_video_message")}
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}
                {hasRecordings && (
                  <Box mb={8}>
                    <Requirements.Root>
                      <StudyRequirements requirements={studyRequirements} />
                    </Requirements.Root>
                  </Box>
                )}

                <ThemedButton
                  onClick={onStart}
                  isLoading={!isLoaded}
                  loadingText={translate("test.buttons.loading_test")}
                >
                  {copy.welcome_button_text}
                </ThemedButton>
              </Box>
            </Footer>
          </Container>
        </div>
        {hasRecordings && !isPanelist && (
          <ExternalLink
            href={Constants.HELP_CENTER_TEST_RECORDINGS_PARTICIPANT_URL}
            color="text.subtlest"
            textDecoration="none"
            fontSize={14}
            mb={8}
          >
            {translate("test.recording.learn_more")}
          </ExternalLink>
        )}
        {branding.type === TestBrandingType.UsabilityHub && <PoweredLogo />}
      </div>
    </div>
  )
}

export default function welcomeContent(
  welcomeMessageProps: Props
): AppearanceProps {
  return {
    isReportButtonVisible: true,
    layoutState: LayoutState.StartStop,
    mediaContent: <MediaPlaceholder />,
    questionContent: <WelcomeMessage {...welcomeMessageProps} />,
  }
}
