import { Button, ButtonGroup, Heading, Stack, Text } from "@chakra-ui/react"
import { UniqueIdentifier } from "@dnd-kit/core"
import { AddIcon } from "Icons/AddIcon"
import { Dispatch } from "Redux/app-store"
import {
  addCorrectNode,
  clearCorrectNodes,
  removeCorrectNode,
} from "Redux/reducers/test-builder-form/action-creators/tree_testing"
import { TreeNode } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { useSectionContext } from "UsabilityHub/contexts/SectionContext"
import React, { PropsWithChildren, useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldProps } from "redux-form"
import { updateTreeNodes } from "../../../../../../../application/javascripts/redux/reducers/test-builder-form/action-creators/tree_testing"
import { FormFieldError } from "../../FormFieldError"
import { CorrectPaths } from "./CorrectPaths"
import { Toolbar } from "./Toolbar"
import { ImportTree } from "./Toolbar/ImportTree"
import { Tree } from "./Tree"
import TreeProvider, { TreeContext } from "./TreeProvider"
import { buildTree } from "./useTree"

const INITIAL_TREE = buildTree([])

const WrappedTreeContainer: React.FC<PropsWithChildren<WrappedFieldProps>> = ({
  meta,
  children,
}) => {
  const { readOnly } = useUsabilityTestUserActivityContext()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const dispatch = useDispatch<Dispatch>()

  const correctNodes = section.tree_test_attributes?.correct_nodes ?? []

  const correctNodeIds = useMemo(
    () => new Set<UniqueIdentifier>(correctNodes),
    [correctNodes]
  )

  const initialTree = useMemo(
    () => section.tree_test_attributes?.nodes || INITIAL_TREE,
    [section]
  )

  const onChange = useCallback(
    (tree: TreeNode[]) => {
      dispatch(updateTreeNodes(sectionIndex, tree))
    },
    [dispatch, sectionIndex]
  )

  const onSelect = useCallback(
    (node: TreeNode) => {
      dispatch(addCorrectNode(sectionIndex, node))
    },
    [dispatch, sectionIndex]
  )

  const onDeselect = useCallback(
    (node: TreeNode) => {
      dispatch(removeCorrectNode(sectionIndex, node))
    },
    [dispatch, sectionIndex]
  )

  const onClearCorrectNodes = useCallback(() => {
    dispatch(clearCorrectNodes(sectionIndex))
  }, [dispatch])

  return (
    <TreeProvider
      tree={initialTree}
      section={section}
      correctNodeIds={correctNodeIds}
      onChange={onChange}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onClearCorrectNodes={onClearCorrectNodes}
    >
      <TreeContext.Consumer>
        {({ tree, addNewRoot }) =>
          tree.length > 0 ? (
            <>
              <Stack spacing={2}>
                <Heading as="h4" size="xs" fontWeight="semibold" mb={0}>
                  Tree
                </Heading>
                <Toolbar readOnly={readOnly} />
              </Stack>
              <Tree readOnly={readOnly} />
              {children}
              <CorrectPaths />
              <FormFieldError {...meta} />
            </>
          ) : (
            <Stack spacing={2}>
              <Heading as="h4" size="xs" fontWeight="semibold" mb={0}>
                Tree
              </Heading>
              <Stack
                rounded={6}
                p={6}
                spacing={3}
                bg="bg.page"
                alignItems="center"
              >
                <Text color="text.subtlest" fontSize="sm">
                  Create your tree by adding a node or import the data from a
                  CSV file
                </Text>
                <ButtonGroup spacing={6} size="sm">
                  <Button
                    variant="solid"
                    colorScheme="brand.primary"
                    leftIcon={<AddIcon />}
                    onClick={addNewRoot}
                  >
                    Add node
                  </Button>
                  <ImportTree variant="solid" />
                </ButtonGroup>
              </Stack>
              <FormFieldError {...meta} />
              {children}
            </Stack>
          )
        }
      </TreeContext.Consumer>
    </TreeProvider>
  )
}

export const TreeContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const sectionIndex = useSectionIndexContext()

  return (
    <Field
      name={`sections[${sectionIndex}].tree_test_attributes`}
      component={WrappedTreeContainer}
    >
      {children}
    </Field>
  )
}
