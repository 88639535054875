import {
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"

type Props = {
  handleChangeLanguage: () => void
  sectionNames: string[]
}

export const TestInstructionsChangeLanguageModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  handleChangeLanguage,
  sectionNames,
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Change test language</ModalHeader>

        <ModalCloseButton />

        <ModalBody display="flex" flexDirection="column" gap={2}>
          <Text>
            It looks like you've already customized some test information in the
            following sections:
          </Text>

          <UnorderedList sx={{ fontVariantNumeric: "tabular-nums" }}>
            {sectionNames.map((name) => (
              <ListItem key={name}>{name}</ListItem>
            ))}
          </UnorderedList>

          <Text>
            These customizations will not be translated unless you reset them
            manually.
          </Text>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleChangeLanguage()
              onClose()
            }}
          >
            Change language
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
