import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { Checkbox } from "DesignSystem/components/Checkbox"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import {
  DEVICE_PERIPHERAL_OPTIONS,
  DEVICE_TYPE_OPTIONS,
} from "Shared/constants/testTakingDeviceRequirements"
import { useModal } from "Utilities/modals/use-modal"
import { capitalizeFirstLetter } from "Utilities/string"
import { minutesToHoursAndMinutes } from "Utilities/time"
import { sortBy } from "lodash"
import React, { PropsWithChildren, ReactNode, useEffect, useRef } from "react"
import AutosizeTextarea from "react-autosize-textarea"
import { FieldError, useController } from "react-hook-form"
import { useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks } from "~/api/generated/usabilityhub-components"
import { DurationLockedModal } from "../moderated-study-builder/duration-locked-modal"
import { DURATION_MINS } from "../moderated-study-builder/forms/useAvailabilitySectionForm"
import { Card } from "./Card"
import { useStudyDetails } from "./StudyDetailsProvider"

export const StudyDetails: React.FC = () => {
  const {
    moderatedStudyId,
    studyDetailsForm,
    deviceRequirementForm,
    availabilityForm,
  } = useStudyDetails()

  const {
    handleSubmit: handleDetailsSubmit,
    register,
    formState: { errors: detailsErrors },
  } = studyDetailsForm

  const {
    handleSubmit: handleDeviceSubmit,
    control,
    formState: { errors: deviceErrors },
  } = deviceRequirementForm

  const {
    register: availabilityRegister,
    watch: availabilityWatch,
    setValue: availabilitySetValue,
    formState: { errors: availabilityErrors },
  } = availabilityForm

  const { field: deviceTypesField } = useController({
    name: "device_types",
    control,
  })

  const { field: devicePeripheralsField } = useController({
    name: "device_peripherals",
    control,
  })

  const deviceTypes = deviceRequirementForm.watch("device_types")

  const devicePeripherals = deviceRequirementForm.watch("device_peripherals")

  const { open: openDurationLockedModal } = useModal(DurationLockedModal)

  const deviceTypesLabel =
    deviceTypesField.value.length === 0
      ? "No devices"
      : deviceTypesField.value.length === DEVICE_TYPE_OPTIONS.length
        ? "Any device"
        : sortBy(
            deviceTypesField.value.map(capitalizeFirstLetter),
            (t: (typeof DEVICE_TYPE_OPTIONS)[number]) =>
              DEVICE_TYPE_OPTIONS.indexOf(t)
          ).join(" or ")

  const { data: orderData } =
    useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks({
      pathParams: { moderatedStudyId },
    })

  const previousDurationRef = useRef<number | null>(null)
  const currentDuration = parseInt(
    availabilityWatch("eventDurationMins").toString()
  )

  // If there are active orders, show a modal when attempting to change duration.
  // It won't block them from changing it, but it will let them know that changes
  // won't affect active orders.
  useEffect(() => {
    const previousDuration = previousDurationRef.current
    previousDurationRef.current = currentDuration

    if (previousDuration === currentDuration || previousDuration === null)
      return

    const activeOrderCount = orderData?.moderated_study_orders.filter(
      (o) => o.state === "active" && o.event_duration_mins !== currentDuration
    ).length
    if (!activeOrderCount) return

    openDurationLockedModal({
      resetDuration: () => {
        previousDurationRef.current = previousDuration
        availabilitySetValue("eventDurationMins", previousDuration)
      },
      numOrders: activeOrderCount,
    })
  }, [currentDuration])

  const changeDeviceType = (deviceType: string, checked: boolean) => {
    if (checked) {
      deviceTypesField.onChange([...deviceTypes, deviceType])
    } else {
      deviceTypesField.onChange(deviceTypes.filter((t) => t !== deviceType))
    }
  }

  const changePeripheral = (peripheral: string, checked: boolean) => {
    if (checked) {
      devicePeripheralsField.onChange([...devicePeripherals, peripheral])
    } else {
      devicePeripheralsField.onChange(
        devicePeripherals.filter((p) => p !== peripheral)
      )
    }
  }

  return (
    <Card.Root>
      <Card.Header title="Study details" />

      <Card.Body>
        <Stack gap={6}>
          <Box as="form" display="contents" onChange={handleDetailsSubmit}>
            <VerboseFormControl
              label="Study name"
              description="The name shown to participants on your study page and calendar events."
              error={detailsErrors.external_name}
            >
              <Input
                {...register("external_name")}
                type="text"
                placeholder="Enter a name for your event"
              />
            </VerboseFormControl>

            <VerboseFormControl
              label="Description"
              description={
                "Make it clear and descriptive to ensure participants understand the session\u2019s purpose."
              }
              error={detailsErrors.description}
            >
              <Textarea
                as={AutosizeTextarea}
                {...register("description")}
                type="text"
                placeholder="Enter a name for your event"
              />
            </VerboseFormControl>

            <VerboseFormControl
              label="Duration"
              description="Specify the length of each session."
              error={availabilityErrors.eventDurationMins}
            >
              <Select
                maxW="200px"
                {...availabilityRegister("eventDurationMins")}
              >
                {DURATION_MINS.map((duration) => (
                  <option key={duration} value={duration}>
                    {minutesToHoursAndMinutes(duration)}
                  </option>
                ))}
              </Select>
            </VerboseFormControl>
          </Box>

          <Box as="form" display="contents" onChange={handleDeviceSubmit}>
            <VerboseFormControl
              label="Device"
              description="Select the device(s) participants can use to join the session."
              error={deviceErrors.device_types as FieldError | undefined}
            >
              <FormControl isInvalid={!!deviceErrors.device_types}>
                <Menu isLazy closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    minW="197px"
                    textAlign="left"
                    variant="outline"
                    size="md"
                    fontWeight="normal"
                    sx={{
                      borderColor: deviceErrors.device_types
                        ? "red.500"
                        : "ds.border.input",
                    }}
                    rightIcon={<Icon as={ChevronDownIcon} />}
                  >
                    {deviceTypesLabel}
                  </MenuButton>
                  <MenuList py={2}>
                    {DEVICE_TYPE_OPTIONS.map((deviceType) => (
                      <MenuItem
                        padding={0}
                        key={deviceType}
                        sx={{
                          label: {
                            flex: 1,
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Checkbox
                          isChecked={deviceTypes.includes(deviceType)}
                          onCheckedChange={(checked) =>
                            changeDeviceType(deviceType, checked)
                          }
                          key={`device-type-${deviceType}`}
                        >
                          {capitalizeFirstLetter(deviceType)}
                        </Checkbox>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </FormControl>
            </VerboseFormControl>

            <VerboseFormControl
              label="Additional requirements"
              error={deviceErrors.device_peripherals as FieldError | undefined}
            >
              <HStack gap={4}>
                {DEVICE_PERIPHERAL_OPTIONS.map((peripheral) => (
                  <Checkbox
                    isChecked={devicePeripherals.includes(peripheral)}
                    onCheckedChange={(checked) =>
                      changePeripheral(peripheral, checked)
                    }
                    key={`peripheral-${peripheral}`}
                  >
                    {`Must use ${peripheral}`}
                  </Checkbox>
                ))}
              </HStack>
            </VerboseFormControl>
          </Box>
        </Stack>
      </Card.Body>
    </Card.Root>
  )
}

// Temporary component until we roll out the DS version

type VerboseFormControlProps = {
  label: ReactNode
  description?: ReactNode
  error?: FieldError
}

const VerboseFormControl: React.FC<
  PropsWithChildren<VerboseFormControlProps>
> = ({ label, description, error, children }) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel mb={3}>
        <Stack gap={1}>
          <Text textStyle="ds.heading.secondary">{label}</Text>
          {description && (
            <Text textStyle="ds.paragraph.secondary">{description}</Text>
          )}
        </Stack>
      </FormLabel>
      {children}
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
