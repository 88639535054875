import { Icon } from "@chakra-ui/react"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import React from "react"

interface Props {
  disqualified: boolean
  notRelevant: boolean
}

export const AnswerResultIcon: React.FC<Props> = ({
  disqualified,
  notRelevant,
}) => {
  return disqualified ? (
    <Icon as={RemoveFilledIcon} color="ds.icon.warning" />
  ) : notRelevant ? null : (
    <Icon as={CheckCircleFilledIcon} color="ds.icon.success" />
  )
}
