import { Flex, HStack, IconButton, Text, useToast } from "@chakra-ui/react"
import { CloseIcon } from "Icons/CloseIcon"
import { DismissableBanner } from "Shared/components/DismissableBanner/DismissableBanner"
import { UseTemplateButton } from "UsabilityHub/components/UseTemplateButton"
import { isString } from "lodash"
import React, { PropsWithChildren, useMemo } from "react"
import { NavigateFunction } from "react-router"
import {
  useCheckDynamicTemplateExists,
  useGetDynamicTemplates,
} from "~/api/generated/usabilityhub-components"
import { useTestRecordingContext } from "./context/TestRecordingContext"

export const PreviewBanner: React.FC<PropsWithChildren> = ({ children }) => {
  const id = location.pathname.split("/").pop()

  const { isLoading, isError } = useCheckDynamicTemplateExists(
    { pathParams: { id: id || "" } },
    { retry: false, enabled: !!id }
  )

  const isTemplate = !isLoading && !isError

  const { data: templateData } = useGetDynamicTemplates(
    {},
    {
      enabled: isTemplate,
    }
  )

  // Using allowedRecordingTypes on purpose here
  // we want to know any are enabled and ignore the preview check
  const { allowedRecordingTypes } = useTestRecordingContext()

  const template = useMemo(
    () => templateData?.find((t) => t.content?.templateId === id),
    [templateData, id]
  )

  const [error, setError] = React.useState<React.ReactNode | null>(null)

  const navigate: NavigateFunction = (
    location,
    options: { replace?: boolean } = {}
  ) => {
    if (!options.replace && isString(location)) {
      window.location.pathname = location
    }
  }

  const toast = useToast()

  if (error) {
    toast({
      description:
        "Something went wrong while trying to apply this template. Please try again later or contact support.",
      status: "error",
    })
  }

  if (isLoading) return null

  if (isTemplate) {
    return (
      <HStack
        justify="space-between"
        py={3}
        px={8}
        spacing={8}
        bg="bg.brand.bold.default"
      >
        <Text color="white">
          <Text as="span" fontWeight="medium">
            {template ? `Template: ${template.content?.title}` : "Template"}
          </Text>
          {". "}
          This is a preview of what the test participants see
        </Text>
        {template && (
          <UseTemplateButton
            template={{
              id: String(template.id),
              title: template.content?.title || "",
              localTemplateId: template.content?.templateId || "",
              teams: [],
              methodologies: [],
              useCases: [],
              shortDescription: template.content?.description || "",
              overview: "",
              discover: [],
              discoverContent: null,
              howToUse: [],
            }}
            bg="teal.50"
            color="teal.800"
            _hover={{ bg: "teal.100" }}
            onNavigate={navigate}
            onError={setError}
          >
            Use this template
          </UseTemplateButton>
        )}
      </HStack>
    )
  }

  return (
    <DismissableBanner>
      {({ dismissBanner }) => (
        <Flex
          justify="center"
          align="center"
          h={14}
          bg="blue.400"
          color="white"
        >
          <Flex align="center" p={2}>
            <Text textAlign="center">
              This is a preview. Your response will not be saved.
              {allowedRecordingTypes.length > 0 &&
                " To preview the participant recording experience, create a 'Recruit with Lyssna link' on the Recruit page"}
            </Text>

            {children}

            <IconButton
              icon={<CloseIcon boxSize={5} />}
              variant="ghost"
              aria-label={"close"}
              onClick={dismissBanner}
              color="white"
              _hover={{
                bg: "none",
              }}
            />
          </Flex>
        </Flex>
      )}
    </DismissableBanner>
  )
}
