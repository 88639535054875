import { ButtonProps } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { CreditsIcon } from "Icons/CreditsIcon"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import {
  useCurrentUser,
  useMaybeCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import { useGetAccountTotalCredits } from "~/api/generated/usabilityhub-components"

type CreditsButton = React.FC<React.PropsWithChildren<ButtonProps>>

export const CreditsButton: CreditsButton = (buttonProps) => {
  const currentUser = useCurrentUser()
  const currentAccount = useMaybeCurrentAccount()

  // This query should be invalidated after
  // - a test is archived
  // - a test is deleted
  // - a project is archived
  // - a project is deleted
  // - credits are purchased
  // - a new order is created
  const { data } = useGetAccountTotalCredits(
    {},
    // If the user leaves their computer for some time, the credits can get out of date.
    // We'll check in with the server after fifteen minutes.
    {
      staleTime: 15 * 60 * 1000, // 15 minutes
      refetchOnWindowFocus: true,
    }
  )

  if (!currentAccount || currentUser.role === "archived") return null

  if (!data) return null

  const creditCount = data.total_credits

  return (
    <Button
      as={RoutedLink}
      to={ROUTES.CREDITS.PURCHASE.path}
      variant="secondary"
      leftIcon={<CreditsIcon color="ds.icon.warning" />}
      data-intercom-target="credits-link"
      flexShrink={0}
      {...buttonProps}
    >
      {creditCount === 0
        ? "Buy credits"
        : pluralizeWithCount(creditCount, "credit", "credits")}
    </Button>
  )
}
