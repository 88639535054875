import { Box, ComponentWithAs, IconProps } from "@chakra-ui/react"
import { MicrophoneIcon } from "Icons/MicrophoneIcon"
import { RecordScreenIcon } from "Icons/RecordScreenIcon"
import { RecordVideoIcon } from "Icons/RecordVideoIcon"
import { Requirements } from "Participants/components/Requirements"
import { useTranslate } from "Shared/hooks/useTranslate"
import React, { useEffect } from "react"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { StepContentProps } from "./RecordingSetupGuideContent"

type RecordingTypeInfo = {
  titleKey: string
  contentKey: string
  type: RecordingType
  icon: ComponentWithAs<"svg", IconProps>
}

const RECORDING_TYPES_INFO: RecordingTypeInfo[] = [
  {
    type: "screen",
    titleKey: "test.recording.screen.label",
    contentKey: "test.recording.screen.all_set_content",
    icon: RecordScreenIcon,
  },
  {
    type: "microphone",
    titleKey: "test.recording.microphone.label",
    contentKey: "test.recording.microphone.all_set_content",
    icon: MicrophoneIcon,
  },
  {
    type: "camera",
    titleKey: "test.recording.camera.label",
    contentKey: "test.recording.camera.all_set_content",
    icon: RecordVideoIcon,
  },
]

export const AllSetContent: React.FC<StepContentProps> = ({ setPassable }) => {
  const { allowedRecordingTypes, audioDeviceId, screenStream, videoDeviceId } =
    useTestRecordingContext()
  useEffect(() => {
    setPassable(true)
  }, [])

  const recordingTypesToShow = allowedRecordingTypes.includes("camera")
    ? allowedRecordingTypes.filter((x) => x !== "microphone")
    : allowedRecordingTypes
  const recordingTypesInfo = RECORDING_TYPES_INFO.filter((recordingTypeInfo) =>
    recordingTypesToShow.includes(recordingTypeInfo.type)
  )

  const isRecordingTypeReady = (recordingType: RecordingType) => {
    switch (recordingType) {
      case "microphone":
        return !!audioDeviceId
      case "screen":
        return !!screenStream
      case "camera":
        return !!videoDeviceId && !!audioDeviceId
      default:
        throw new Error("recording type is not supported")
    }
  }

  const translate = useTranslate()

  return (
    <>
      <Requirements.Root>
        {recordingTypesInfo.map((recordingTypeInfo, index) => (
          <Requirements.Item
            key={`${recordingTypeInfo.type}-${index}`}
            icon={recordingTypeInfo.icon}
            heading={translate(recordingTypeInfo.titleKey)}
            description={translate(recordingTypeInfo.contentKey)}
            isReady={isRecordingTypeReady(recordingTypeInfo.type)}
          />
        ))}
      </Requirements.Root>

      <Box bg="gray.50" borderRadius="lg" p={4} fontSize="sm">
        <b>{translate("test.recording.important")}: </b>
        {translate("test.recording.warning")}
      </Box>
    </>
  )
}
