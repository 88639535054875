import {
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { EditFilledIcon } from "Icons/EditFilledIcon"
import { ListIcon } from "Icons/ListIcon"
import { MinusCircleIcon } from "Icons/MinusCircleIcon"
import { useCardSortResultsContext } from "UsabilityHub/views/tests/$uniqueId/results/section-results/section-results-cards/CardSortResultsContext"
import { AnimatePresence, motion } from "framer-motion"
import { maxBy } from "lodash"
import React, { useState } from "react"
import {
  EitherCategory,
  extractGroupId,
  getCategoryLabel,
  humanizeGroupLabel,
  isItalicCategory,
} from "./grouping"

interface Props {
  editingExisting?: boolean
  selectedGroupMap: Record<string, EitherCategory[]>
  onClose: () => void
  afterSave?: () => void
  readonly?: boolean
}

const MotionFlex = motion(Flex)

export const GroupingModal: React.FC<Props> = ({
  editingExisting = false,
  selectedGroupMap,
  onClose,
  afterSave,
  readonly = false,
}) => {
  const oldName = defaultGroupName(selectedGroupMap)

  const { sortData, updateGroupings } = useCardSortResultsContext()
  const allCategories = Object.values(selectedGroupMap).flat()
  const [newName, setNewName] = useState(oldName)
  const [editingName, setEditingName] = useState(!editingExisting)
  const [categoryIds, setCategoryIds] = useState(allCategories.map((c) => c.id))
  const newGroupID = extractGroupId(Object.keys(selectedGroupMap)[0])

  // When creating a new group, you can save as long as the name isn't blank.
  // If you're editing an existing one you can't save until you've made a change.
  const saveDisabled =
    newName.trim() === "" ||
    (editingExisting &&
      newName === oldName &&
      categoryIds.length === allCategories.length)

  const handleSave = async () => {
    await updateGroupings(newGroupID, categoryIds, newName)
    afterSave?.()
    onClose()
  }

  const handleUngroup = async () => {
    await updateGroupings(newGroupID, [], oldName)
    afterSave?.()
    onClose()
  }

  const removeFromSelection = (id: string | null) => {
    setCategoryIds(categoryIds.filter((c) => c !== id))
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={6}>
          <Flex align="center" gap={3}>
            {editingExisting && !editingName ? (
              <Flex align="center" flexGrow={1}>
                <Text fontWeight="bold">{oldName}</Text>
                {!readonly && (
                  <IconButton
                    variant="ghost"
                    size="sm"
                    icon={<Icon as={EditFilledIcon} />}
                    onClick={() => setEditingName(true)}
                    aria-label="Edit group name"
                  />
                )}
                <Spacer />
              </Flex>
            ) : (
              <Input
                autoFocus
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
            )}
            {!readonly && (
              <Button
                colorScheme="brand.primary"
                disabled={saveDisabled}
                flexShrink={0}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
            {!readonly ? (
              editingExisting && !editingName ? (
                <Button
                  variant="outline"
                  color="red.500"
                  flexShrink={0}
                  onClick={handleUngroup}
                >
                  Ungroup
                </Button>
              ) : (
                <Button flexShrink={0} onClick={onClose}>
                  Cancel
                </Button>
              )
            ) : null}
          </Flex>

          {categoryIds.length ? (
            <Stack spacing={4} mt={4} overflow="hidden">
              <AnimatePresence>
                {categoryIds.map((categoryId) => {
                  const category = allCategories.find(
                    (c) => c.id === categoryId
                  )!

                  const cardsForCategory = sortData.filter((c) => {
                    const sortDataCategoryId =
                      c.card_sort_category_id ?? c.card_sort_open_category_id
                    return sortDataCategoryId === Number(categoryId)
                  }).length

                  return (
                    <MotionFlex
                      layout
                      key={category.id}
                      align="center"
                      borderColor="gray.200"
                      borderWidth={1}
                      rounded="md"
                      gap={4}
                      p={3}
                      exit={{ opacity: 0, transition: { duration: 0.15 } }}
                    >
                      <Text
                        flexGrow={1}
                        fontStyle={
                          isItalicCategory(category) ? "italic" : "normal"
                        }
                      >
                        {getCategoryLabel(category)}
                      </Text>

                      <Flex align="center" color="gray.500" gap={1}>
                        <Icon as={ListIcon} />
                        <Text fontSize="xs">
                          {cardsForCategory} card
                          {cardsForCategory === 1 ? "" : "s"}
                        </Text>
                      </Flex>

                      {!readonly && (
                        <IconButton
                          variant="ghost"
                          size="xs"
                          icon={<Icon as={MinusCircleIcon} boxSize={4} />}
                          aria-label="Remove from grouping"
                          disabled={categoryIds.length <= 2}
                          onClick={() => removeFromSelection(category.id)}
                        />
                      )}
                    </MotionFlex>
                  )
                })}
              </AnimatePresence>
            </Stack>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const defaultGroupName = (groupMap: Record<string, EitherCategory[]>) => {
  const bestGroup = maxBy(
    Object.entries(groupMap),
    ([, categories]) => categories.length
  )

  return bestGroup ? humanizeGroupLabel(bestGroup[0]) : ""
}
