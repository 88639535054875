import React, { PropsWithChildren, useCallback, useRef, useState } from "react"

type DocumentPictureInPictureContextType = {
  open: (width: number, height: number) => void
  close: () => void
  isOpen: boolean
  pipWindow: Window | null
  isSupported: boolean
}

const DocumentPictureInPictureContext =
  React.createContext<DocumentPictureInPictureContextType | null>(null)

export const useDocumentPictureInPictureContext = () => {
  const context = React.useContext(DocumentPictureInPictureContext)

  if (context === null) {
    throw new TypeError(
      `useDocumentPictureInPictureContext must be rendered within a DocumentPictureInPictureContextProvider`
    )
  }

  return context
}

export const DocumentPictureInPictureProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [pip, setPip] = useState<Window | null>(null)
  const observerRef = useRef<MutationObserver | null>(null)

  const copyStyles = useCallback(() => {
    const pipWindow = window.documentPictureInPicture?.window

    if (!pipWindow) return

    // Clear existing styles to avoid duplication
    pipWindow.document.head.innerHTML = ""

    // Copy styles from the current page into the PIP window
    ;[...document.styleSheets].forEach((stylesheet) => {
      try {
        if (stylesheet.href) {
          const linkElement = document.createElement("link")
          linkElement.rel = "stylesheet"
          linkElement.href = stylesheet.href
          pipWindow.document.head.appendChild(linkElement)
        } else {
          const styleText = [...stylesheet.cssRules]
            .map((rule) => rule.cssText)
            .join("")
          const styleElement = document.createElement("style")
          styleElement.textContent = styleText
          pipWindow.document.head.appendChild(styleElement)
        }
      } catch (e) {
        // Ignore any errors copying, but log for diagnostic purposes
        console.error("Failed to copy stylesheet", stylesheet, e)
      }
    })
  }, [])

  const open = useCallback(async (width: number, height: number) => {
    if (!("documentPictureInPicture" in window)) {
      console.error("Document picture in picture not supported")
    }

    const pipWindow = await window.documentPictureInPicture.requestWindow({
      width,
      height,
      disallowReturnToOpener: true,
    })

    pipWindow.addEventListener("pagehide", close)

    // Immediately copy styles after creating the PiP window
    copyStyles()

    // Add MutationObserver for future style changes
    const observer = new MutationObserver(copyStyles)
    observerRef.current = observer

    observer.observe(document.head, {
      childList: true,
      subtree: true,
    })

    setPip(pipWindow)
  }, [])

  const close = useCallback(() => {
    window.documentPictureInPicture?.window?.close()
    observerRef.current?.disconnect()
    setPip(null)
  }, [])

  return (
    <DocumentPictureInPictureContext.Provider
      value={{
        open,
        close,
        isOpen: !!pip,
        pipWindow: pip,
        isSupported: "documentPictureInPicture" in window,
      }}
    >
      {children}
    </DocumentPictureInPictureContext.Provider>
  )
}
