import {
  Box,
  Flex,
  Grid,
  Spinner,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { UsabilityHubNavbar } from "Components/exports"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import { DashboardLoadingIndicator } from "UsabilityHub/components/DashboardLoadingIndicator/dashboard-loading-indicator"
import { AddFilterButton } from "UsabilityHub/components/FilterControls/AddFilterButton"
import { useFilterContext } from "UsabilityHub/components/FilterControls/FilterContext"
import { FilterList } from "UsabilityHub/components/FilterControls/FilterList"
import { serializeAppliedFilter } from "UsabilityHub/components/FilterControls/utils"
import { useInfiniteListProjects } from "UsabilityHub/hooks/useInfiniteProjectList"
import { useInfiniteListUsabilityTests } from "UsabilityHub/hooks/useInfiniteUsabilityTestList"
import { useListModeratedStudies } from "~/api/generated/usabilityhub-components"

import { ActiveMenu } from "../dashboard/ActiveMenu"
import { DashboardHeader } from "../dashboard/DashboardHeader"
import { ProjectCard } from "../dashboard/ProjectCard"
import { ProjectListSkeleton } from "../dashboard/ProjectListSkeleton"
import { SortMenu } from "../dashboard/SortMenu"
import { StudyList } from "../dashboard/StudyList"
import { useViewMenuParams } from "../dashboard/useViewMenuParams"

import NoResultsImage from "Images/app-illustrations/lyssna/no-results.png"
import { useInfiniteListVariationSets } from "UsabilityHub/hooks/useInfiniteVariationSetList"
import { convertUsabilityTestToStudy } from "UsabilityHub/utils/convertUsabilityTestToStudy"
import { AllDone } from "~/usabilityhub/components/AllDone"
import { VariationSetRow } from "../dashboard/VariationSetRow"
import { NoResults } from "./NoResults"
import { SearchResultsModeratedStudiesTab } from "./SearchResultsModeratedStudiesTab"
import { SearchTabs } from "./SearchTabs"

export const SearchResultsRoute: React.FC = () => {
  const [searchParams] = useMutableQueryString()
  const { showArchived, sortBy, sortDirection } = useViewMenuParams()
  const [tabIndex, setTabIndex] = useState(0)
  const query = searchParams["q"] || ""
  const [isLargerThan48em] = useMediaQuery("(min-width: 48em)", { ssr: false })
  const [isLargerThan62em] = useMediaQuery("(min-width: 62em)", { ssr: false })

  const { filters } = useFilterContext()
  const serializedFilters = filters.map(serializeAppliedFilter)

  const projectQuery = useInfiniteListProjects({
    search: query,
    sort_by: sortBy,
    sort_direction: sortDirection,
    show_archived: showArchived,
  })
  const projectsLoaderRef = useInfiniteScroll<HTMLDivElement>(
    projectQuery.fetchNextPage
  )

  const testQuery = useInfiniteListUsabilityTests(
    {
      search: query,
      sort_by: sortBy,
      sort_direction: sortDirection,
    },
    [
      ...serializedFilters,
      {
        attribute: "archived",
        comparator: showArchived ? "eq" : "neq",
        values: [],
      },
    ]
  )
  const testsLoaderRef = useInfiniteScroll<HTMLDivElement>(
    testQuery.fetchNextPage
  )

  const variationSetsQuery = useInfiniteListVariationSets({
    search: query,
    sort_by: sortBy,
    sort_direction: sortDirection,
  })

  const variationSetsLoaderRef = useInfiniteScroll<HTMLDivElement>(
    variationSetsQuery.fetchNextPage
  )

  const moderatedStudiesQuery = useListModeratedStudies(
    {
      queryParams: {
        show_archived: showArchived,
        search: query,
        sort_by: sortBy,
        sort_direction: sortDirection,
      },
    },
    {
      keepPreviousData: true,
    }
  )

  const allProjects =
    projectQuery.data?.pages.flatMap((page) => page.projects) ?? []
  const allTests =
    testQuery.data?.pages.flatMap((page) => page.usability_tests) ?? []
  const allVariationSets =
    variationSetsQuery.data?.pages.flatMap((page) => page.variation_sets) ?? []
  const allModeratedStudies = moderatedStudiesQuery.data ?? []

  const decoratedTests = allTests.map(convertUsabilityTestToStudy)

  const projectPagesShown = projectQuery.data?.pages.length ?? 0
  const testPagesShown = testQuery.data?.pages.length ?? 0

  const variationSetPagesShown = variationSetsQuery.data?.pages.length ?? 0

  const projectListGridProps = {
    templateColumns: "repeat(auto-fill, minmax(15rem, 1fr))",
    gap: 4,
  }

  const isFetching =
    testQuery.isFetching ||
    projectQuery.isFetching ||
    variationSetsQuery.isFetching ||
    moderatedStudiesQuery.isFetching

  return (
    <Page title="Dashboard">
      <Tabs
        isLazy
        variant="soft-rounded"
        index={tabIndex}
        onChange={(i) => setTabIndex(i)}
        size="md"
      >
        <Box display={["none", null, "block"]}>
          <UsabilityHubNavbar showLogoInsteadOfDashboardButton />
        </Box>
        <DashboardHeader />
        <Box
          position="relative"
          // Shadow hack to avoid box-shadow on all sides
          _after={{
            content: '""',
            position: "absolute",
            top: "calc(100% - 0px)",
            left: 0,
            right: 0,
            height: "8px",
            pointerEvents: "none",
            background:
              "linear-gradient(to bottom, rgba(6, 29, 41, 0.12) -1px, rgba(6, 29, 41, 0) 1px), linear-gradient(to bottom, rgba(6, 29, 41, 0.16) -4px, rgba(6, 29, 41, 0) 4px)",
          }}
        >
          <Box
            display={["block", null, "none"]}
            px={0}
            py={2}
            bg="white"
            overflowX="auto"
          >
            <Box mx={6} display="inline-block" whiteSpace="nowrap">
              {/* We need to make sure only one instance of SearchTabs is rendered for them to work */}
              {!isLargerThan48em && (
                <SearchTabs
                  testQuery={testQuery}
                  projectQuery={projectQuery}
                  variationSetsQuery={variationSetsQuery}
                  moderatedStudiesQuery={moderatedStudiesQuery}
                />
              )}
            </Box>
          </Box>
        </Box>
        <PageMain>
          <PageContent pt="0 !important" maxW="8xl">
            <Flex justify="space-between">
              <Flex
                basis="full"
                align={["start", null, "center"]}
                gap={2}
                flexDirection={["column", null, "row"]}
              >
                <Box display={["none", null, "block"]}>
                  {isLargerThan48em && (
                    <SearchTabs
                      testQuery={testQuery}
                      projectQuery={projectQuery}
                      variationSetsQuery={variationSetsQuery}
                      moderatedStudiesQuery={moderatedStudiesQuery}
                    />
                  )}
                </Box>
                <Flex
                  gap="2"
                  h="full"
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex h="full" alignItems="center" gap={2}>
                    {tabIndex === 0 && (
                      <>
                        <AddFilterButton showClearButton />
                        <DashboardLoadingIndicator
                          query={
                            [
                              testQuery,
                              projectQuery,
                              variationSetsQuery,
                              moderatedStudiesQuery,
                            ][tabIndex]
                          }
                        />
                      </>
                    )}
                  </Flex>
                  <Flex align="center" gap={2}>
                    {tabIndex !== 2 && (
                      <ActiveMenu
                        iconOnly={!isLargerThan62em}
                        listType="studies"
                      />
                    )}
                    <SortMenu iconOnly={!isLargerThan62em} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <TabPanels
              opacity={isFetching ? 0.7 : 1}
              style={{ pointerEvents: isFetching ? "none" : "auto" }}
              transition="opacity 0.2s ease-out"
            >
              <TabPanel px={0} pt={0} pb={3}>
                {filters.length > 0 && (
                  <Box
                    mt={2}
                    pt={2}
                    borderTop="1px solid"
                    borderColor="ds.border.default"
                  >
                    <FilterList />
                  </Box>
                )}
                <Box pt={6}>
                  {testQuery.data === undefined ? (
                    <StudyList context="search" isLoading studies={[]} />
                  ) : allTests.length > 0 ? (
                    <>
                      <StudyList
                        context="search"
                        isLoading={false}
                        studies={decoratedTests}
                      />

                      {testQuery.isFetchingNextPage && (
                        <Flex justify="center" mt={10}>
                          <Spinner />
                        </Flex>
                      )}

                      <Flex justify="center">
                        {testQuery.hasNextPage ? (
                          <div ref={testsLoaderRef} />
                        ) : (
                          testPagesShown > 1 && <AllDone />
                        )}
                      </Flex>
                    </>
                  ) : (
                    <NoResults
                      image={NoResultsImage}
                      title="No test results found"
                    />
                  )}
                </Box>
              </TabPanel>

              <TabPanel px={0} py={6}>
                <SearchResultsModeratedStudiesTab
                  moderatedStudies={allModeratedStudies}
                />
              </TabPanel>

              <TabPanel px={0} py={6}>
                {projectQuery.data === undefined ? (
                  <ProjectListSkeleton
                    numberOfItems={8}
                    gridProps={projectListGridProps}
                  />
                ) : allProjects.length > 0 ? (
                  <>
                    <Grid {...projectListGridProps}>
                      {allProjects.map((project) => (
                        <ProjectCard key={project.id} project={project} />
                      ))}
                    </Grid>

                    {projectQuery.isFetchingNextPage && (
                      <Flex justify="center" mt={10}>
                        <Spinner />
                      </Flex>
                    )}

                    <Flex justify="center">
                      {projectQuery.hasNextPage ? (
                        <div ref={projectsLoaderRef} />
                      ) : (
                        projectPagesShown > 1 && <AllDone />
                      )}
                    </Flex>
                  </>
                ) : (
                  <NoResults
                    title="No project results found"
                    image={NoResultsImage}
                  />
                )}
              </TabPanel>

              <TabPanel px={0} py={6}>
                {variationSetsQuery.data === undefined ? (
                  <Flex justify="center" mt={10}>
                    <Spinner />
                  </Flex>
                ) : allVariationSets.length > 0 ? (
                  <>
                    <Flex
                      direction="column"
                      gap={4}
                      boxShadow="ds.raised"
                      borderRadius="16px"
                    >
                      {allVariationSets.map((variationSet) => (
                        <VariationSetRow
                          key={variationSet.id}
                          variationSet={variationSet}
                        />
                      ))}
                    </Flex>

                    {variationSetsQuery.isFetchingNextPage && (
                      <Flex justify="center" mt={10}>
                        <Spinner />
                      </Flex>
                    )}

                    <Flex justify="center">
                      {variationSetsQuery.hasNextPage ? (
                        <div ref={variationSetsLoaderRef} />
                      ) : (
                        variationSetPagesShown > 1 && <AllDone />
                      )}
                    </Flex>
                  </>
                ) : (
                  <NoResults
                    title="No variation set results found"
                    image={NoResultsImage}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </PageContent>
        </PageMain>
      </Tabs>
    </Page>
  )
}
