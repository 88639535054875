import {
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { Avatar, IconButton } from "DesignSystem/components"
import { MenuKebabIcon } from "Icons/MenuKebabIcon"
import { ArchiveModeratedStudyModal } from "UsabilityHub/views/ModeratedStudy/interviewer/ArchiveModeratedStudyModal"
import { DuplicateModeratedStudyModal } from "UsabilityHub/views/ModeratedStudy/interviewer/DuplicateModeratedStudyModal"
import { ROUTES } from "UsabilityHub/views/routes"
import { useModal } from "Utilities/modals/use-modal"
import { formatDistanceToNow } from "date-fns"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useUnarchiveModeratedStudy } from "~/api/generated/usabilityhub-components"

type Props = {
  moderatedStudyId: string
  studyName: string
  isArchived: boolean
  onRename: () => void
  // Will not show the edit action if not provided
  // (e.g. on a single interview page, since you're already editing it)
  editLinkTo?: (path: string) => string
  buttonVariant?: "default" | "subtle"
  createdInfo?: {
    name: string
    avatarUrl: string | null
    createdAt: string
  }
}

export const ModeratedStudyContextMenu: React.FC<Props> = ({
  moderatedStudyId,
  studyName,
  isArchived,
  onRename,
  editLinkTo,
  buttonVariant = "default",
  createdInfo,
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { open: openDuplicateModal } = useModal(DuplicateModeratedStudyModal)
  const { open: openArchiveModal } = useModal(ArchiveModeratedStudyModal)
  const { isOpen, onOpen, onClose } = useDisclosure()

  // DSIconButton variant can't take a responsive array at the moment
  const buttonSize = useBreakpointValue({ base: "compact", lg: "default" })

  const { mutate: unarchiveMutation } = useUnarchiveModeratedStudy({
    onSuccess: () => {
      toast({
        title: "Study unarchived",
        status: "success",
      })

      navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId }))
    },
    onError: () => {
      toast({
        title: "Error unarchiving study",
        status: "error",
      })
    },
  })

  const onDuplicate = () => {
    openDuplicateModal({
      moderatedStudyId,
      studyName,
    })
  }

  const onArchive = () => {
    openArchiveModal({
      moderatedStudyId,
      studyName,
    })
  }

  const onUnarchive = () => {
    unarchiveMutation({
      pathParams: {
        moderatedStudyId,
      },
    })
  }

  const canRename = !isArchived
  const canDuplicate = true
  const canEdit = !isArchived
  const canArchive = !isArchived
  const canUnarchive = isArchived

  return (
    <Menu isLazy onOpen={onOpen} onClose={onClose}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<MenuKebabIcon boxSize={4} color="ds.icon.subtle" />}
        variant={buttonVariant}
        isSelected={isOpen}
        size={buttonSize}
        data-hover-only={{ base: "false", lg: "true" }}
        zIndex={1}
      />
      <Portal>
        <MenuList
          zIndex="dropdown"
          border="none"
          shadow="ds.overlay"
          overflow="hidden"
          maxW="14.375rem"
        >
          {createdInfo && <CreatedInfoMenuItem createdInfo={createdInfo} />}
          {canRename && <MenuItem onClick={onRename}>Rename</MenuItem>}
          {canDuplicate && <MenuItem onClick={onDuplicate}>Duplicate</MenuItem>}
          {canEdit && editLinkTo && (
            <MenuItem as={Link} to={editLinkTo("edit")}>
              Edit
            </MenuItem>
          )}
          {canArchive && <MenuItem onClick={onArchive}>Archive</MenuItem>}
          {canUnarchive && <MenuItem onClick={onUnarchive}>Unarchive</MenuItem>}
        </MenuList>
      </Portal>
    </Menu>
  )
}

const CreatedInfoMenuItem: React.FC<{
  createdInfo: { name: string; avatarUrl: string | null; createdAt: string }
}> = ({ createdInfo }) => {
  const createdAgo = formatDistanceToNow(new Date(createdInfo.createdAt), {
    addSuffix: true,
  })

  return (
    <>
      <MenuItem
        // Meant to be informational, not interactive
        isDisabled
        _disabled={{ cursor: "default", _active: { bg: "inherit" } }}
        px={4}
        py={3}
      >
        <Flex alignItems="center" gap={2}>
          <Avatar
            appearance="profile"
            name={createdInfo.name}
            profileImageSrc={createdInfo.avatarUrl ?? undefined}
          />
          <Text textStyle="ds.paragraph.secondary">
            Created {createdAgo} by {createdInfo.name}
          </Text>
        </Flex>
      </MenuItem>
      <MenuDivider my={0} color="ds.border.default" />
    </>
  )
}
