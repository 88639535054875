import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"

type Props = {
  handleChangeSectionType: () => void
}

export const TestInstructionsChangeSectionTypeModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  handleChangeSectionType,
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Change section type</ModalHeader>

        <ModalCloseButton />

        <ModalBody display="flex" flexDirection="column" gap={2}>
          <Text>
            It looks like you've already customized the test information for
            this section. When changing section type, ensure your custom
            instructions are still accurate, or reset to default instructions.
          </Text>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleChangeSectionType()
              onClose()
            }}
          >
            Change section type
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
