import classNames from "classnames"
// biome-ignore lint/suspicious/noShadowRestrictedNames: legacy code
import { parseInt } from "lodash"
import React, { ChangeEvent, InputHTMLAttributes, PureComponent } from "react"

import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import { ChevronUpIcon } from "Icons/ChevronUpIcon"
import { Omit } from "Types"

import { clamp } from "../../../math/number"

import styles from "./number-input.module.scss"

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
  required?: boolean
  value: number | null
  onChange: (value: number | null) => void
  min?: number
  max?: number
  scale?: number
}

export class NumberInput extends PureComponent<Props> {
  static defaultProps = {
    scale: 1,
  }

  private static readonly defaultValue = 0

  private inputElementRef = React.createRef<HTMLInputElement>()

  // -- Handlers --

  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, placeholder, scale } = this.props
    const stringValue = event.target.value

    // Ensure that if a placeholder is provided we can display it on empty value
    if (placeholder !== undefined && stringValue === "") return onChange(null)
    // It's important to not clamp this value because it might be a partial
    // number string.
    onChange((parseInt(stringValue, 10) || 0) * scale!)
  }

  private handleUp = () => {
    const { value, scale } = this.props
    const nonNullValue = value === null ? NumberInput.defaultValue : value
    this.setValue(nonNullValue + 1 * scale!)
  }

  private handleDown = () => {
    const { value, scale } = this.props
    const nonNullValue = value === null ? NumberInput.defaultValue : value
    this.setValue(nonNullValue - 1 * scale!)
  }

  // -- Private interface --

  private isValid(): boolean {
    const { value, min, max, required } = this.props
    if (required && value === null) return false
    if (value !== null) {
      if (min !== undefined && value < min) return false
      if (max !== undefined && value > max) return false
    }
    return true
  }

  private setValue(nextValue: number) {
    const { min, max, onChange } = this.props
    const v = clamp(nextValue, min, max)
    onChange(v)
  }

  // -- React lifecyle --

  componentDidUpdate() {
    // If this element is focused and its value becomes 0, select the 0 so the
    // next number you enter replaces it, rather than appending (e.g. "1"
    // instead of "01")
    if (
      this.inputElementRef.current === document.activeElement &&
      this.props.value === 0
    ) {
      this.inputElementRef.current!.select()
    }
  }

  render() {
    const {
      onChange, // unused
      onFocus, // unused
      className,
      value,
      min,
      max,
      scale,
      disabled,
      ...props
    } = this.props
    const nonNullValue = value === null ? NumberInput.defaultValue : value
    const scaledValue = nonNullValue / scale!
    const scaledMin = (min || 0) / scale!
    const scaledMax = (max === undefined ? Infinity : max) / scale!
    return (
      <div className={classNames(styles.container, className)}>
        <input
          className={classNames(styles.input, {
            [styles.invalid]: !this.isValid(),
          })}
          ref={this.inputElementRef}
          value={value === null ? "" : scaledValue}
          type="number"
          min={scaledMin}
          max={scaledMax}
          onChange={this.handleChange}
          disabled={disabled}
          {...props}
        />
        <div className={styles.buttons}>
          <button
            className={classNames(styles.spinButton, styles.upButton)}
            type="button"
            disabled={
              disabled || (max !== undefined && scaledValue >= scaledMax)
            }
            onClick={this.handleUp}
          >
            <ChevronUpIcon />
          </button>
          <button
            className={classNames(styles.spinButton, styles.downButton)}
            type="button"
            disabled={
              disabled || (min !== undefined && scaledValue <= scaledMin)
            }
            onClick={this.handleDown}
          >
            <ChevronDownIcon />
          </button>
        </div>
      </div>
    )
  }
}
