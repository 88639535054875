import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TestWelcomeIcon = createIcon({
  displayName: "TestWelcomeIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M18.365 5.636a.684.684 0 0 0-.967-.024l-5.106 5.107a1 1 0 0 1-1.415-1.414L15.2 4.984a.684.684 0 0 0-.024-.966.684.684 0 0 0-.966-.024L7.53 10.672a1 1 0 0 1-1.414-1.414L8.08 7.294a.684.684 0 0 0-.023-.967.684.684 0 0 0-.967-.023L5.52 7.875c-2.852 2.85-2.825 7.547.117 10.489 2.942 2.942 7.638 2.968 10.489.118l3.928-3.929a.684.684 0 0 0-.023-.966.684.684 0 0 0-.967-.024L17.1 15.528a1 1 0 1 1-1.414-1.415l4.32-4.32a.684.684 0 0 0-.023-.967.684.684 0 0 0-.966-.024l-4.321 4.322a1 1 0 0 1-1.415-1.415l5.107-5.107a.684.684 0 0 0-.023-.966Zm2.127 1.176a2.711 2.711 0 0 0-.713-2.59 2.711 2.711 0 0 0-2.59-.714 2.72 2.72 0 0 0-.6-.905c-1.04-1.04-2.74-1.078-3.795-.023L9.902 5.472c-.117-.2-.26-.388-.431-.559-1.041-1.041-2.74-1.078-3.795-.023L4.105 6.46c-3.658 3.658-3.579 9.62.118 13.317 3.696 3.697 9.659 3.776 13.317.118l3.928-3.928c1.055-1.055 1.018-2.754-.023-3.795a2.727 2.727 0 0 0-.56-.43l.536-.537c1.055-1.054 1.018-2.753-.023-3.794a2.72 2.72 0 0 0-.906-.6Z"
      fillRule="evenodd"
    />
  ),
})
