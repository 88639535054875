import { Flex, Text } from "@chakra-ui/react"
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import React from "react"

export const CanceledOrCompleteLabel: React.FC<{
  state: "canceled" | "auto_canceled" | "complete"
}> = ({ state }) => {
  const icon =
    state === "complete" ? (
      <CheckCircleFilledIcon color="ds.icon.success" />
    ) : (
      <RemoveFilledIcon color="ds.icon.danger" />
    )
  const text = (
    <Text textStyle="ds.interface.medium">
      {state === "complete" ? "Complete" : "Canceled"}
    </Text>
  )

  return (
    <Flex alignItems="center" gap={2}>
      {icon}
      {text}
    </Flex>
  )
}
