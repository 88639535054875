import { Box, Flex, Icon, Stack, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { InfoFilledIcon } from "Icons/InfoFilledIcon"
import { formatPreciseDuration } from "Utilities/time"

type DurationIndicator = React.FC<
  React.PropsWithChildren<{
    showTimes?: boolean
    actualTime: number
    estimatedTime: number
  }>
>

export const DurationIndicator: DurationIndicator = ({
  actualTime,
  estimatedTime,
  showTimes = false,
}) => {
  const proportion = actualTime / estimatedTime

  return (
    <Box width="100%">
      {showTimes && (
        <Text fontSize="sm" my={1}>
          {`Actual: ${formatPreciseDuration(actualTime)}`}
          <br />
          {`Estimated: ${formatPreciseDuration(estimatedTime)}`}
          <Tooltip
            label="Estimated durations are based only on the questions the panelist saw. Durations will vary if some questions were hidden due to test logic."
            placement="top"
          >
            <span>
              <Icon as={InfoFilledIcon} ml={2} color="gray.600" />
            </span>
          </Tooltip>
        </Text>
      )}
      <Stack
        borderColor="gray.300"
        borderRadius="md"
        borderWidth={1}
        flexGrow={1}
        h={6}
        isInline
        justifyContent="center"
        overflow="hidden"
        spacing={0}
      >
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          paddingLeft="2px"
          paddingY="2px"
          w="50%"
        >
          <Box
            backgroundColor="red.500"
            w={`${proportion < 1 ? (1 - proportion) * 100 : 0}%`}
            h="90%"
          />
        </Flex>
        <Box borderColor="black" borderLeftWidth={3} />
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          paddingRight="2px"
          paddingY="2px"
          w="50%"
        >
          <Box
            backgroundColor="green.500"
            w={`${proportion > 1 ? (proportion - 1) * 100 : 0}%`}
            h="90%"
          />
        </Flex>
      </Stack>
    </Box>
  )
}
