import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Icon,
  Input,
  Select,
  Spacer,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import { sortBy } from "lodash"
import React, { useCallback, useState } from "react"
import { FieldError, UseFormRegisterReturn } from "react-hook-form"

import { AddIcon } from "Icons/AddIcon"
import {
  AVAILABILITY_ALIGNMENT_OPTIONS,
  AvailabilityOverrides,
  BUFFER_MINS,
  MINIMUM_NOTICE_OPTIONS,
  useAvailabilitySectionForm,
} from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useAvailabilitySectionForm"
import { useModal } from "Utilities/modals/use-modal"

import { TimezoneChanger } from "Shared/components/TimezoneChanger/TimezoneChanger"

import { Heading } from "DesignSystem/components"
import { HelpCircleIcon } from "Icons/HelpCircleIcon"
import { ShowIcon } from "Icons/ShowIcon"
import { AvailabilityCalendarPreviewModalTrigger } from "./AvailabilityCalendarPreview"
import { DateOverridesRow } from "./DateOverridesRow"
import { DayAvailability } from "./DayAvailability"
import { DateOverrides, DateType, DayOverrideModal } from "./DayOverrideModal"
import { DAYS, DAY_LABELS, Day } from "./days"

type ModeratedStudyAvailabilityFormProps = {
  availabilityForm: ReturnType<typeof useAvailabilitySectionForm>
}

const formatBufferMins = (bufferMins: number) => {
  if (bufferMins === 0) {
    return "No buffer"
  } else if (bufferMins <= 60) {
    return `${bufferMins} min`
  } else {
    const hours = Math.floor(bufferMins / 60)
    const mins = (bufferMins - hours * 60) % 60
    return `${hours} hr ${mins > 0 ? `${mins} min` : ""}`
  }
}

const convertDateStringToNumbers = (dateString: string): DateType => {
  const [year, month, date] = dateString.split("-")
  return [Number(year), Number(month) - 1, Number(date)]
}

const convertNumbersToDateString = (dateNumbers: number[]): string => {
  const [year, month, date] = dateNumbers
  return `${year}-${(month + 1).toString().padStart(2, "0")}-${date
    .toString()
    .padStart(2, "0")}`
}

// TODO responsive
export const ModeratedStudyAvailabilityForm = ({
  availabilityForm,
}: ModeratedStudyAvailabilityFormProps) => {
  const { open: openDayOverrideModal } = useModal(DayOverrideModal)

  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = availabilityForm

  const availabilityOverrides = watch("availabilityOverrides")
  const availabilityByDay = watch("availabilityByDay")

  const AddOrUpdateAvailabilityOverrides = useCallback(
    (dateOverrides: DateOverrides, oldDate?: DateType) => {
      const availabilityOverrides = getValues("availabilityOverrides")
      const oldDateString = oldDate ? convertNumbersToDateString(oldDate) : ""
      const newDateString = convertNumbersToDateString(dateOverrides.date)
      const newAvailabilityOverrides = oldDate
        ? availabilityOverrides?.map((item) => {
            if (item.date === oldDateString) {
              return {
                ...item,
                date: newDateString,
                availability: dateOverrides.overrides,
              }
            } else {
              return item
            }
          })
        : [
            ...availabilityOverrides,
            {
              date: newDateString,
              availability: dateOverrides.overrides,
            },
          ]

      setValue("availabilityOverrides", newAvailabilityOverrides ?? [])
    },
    [getValues, setValue]
  )
  const convertToDateOverridesArray = useCallback(
    (data: AvailabilityOverrides) => {
      if (!data || Object.keys(data).length === 0) {
        return []
      }
      return data.map((overridesObject) => {
        return {
          date: convertDateStringToNumbers(overridesObject.date),
          overrides: overridesObject.availability,
        }
      })
    },
    []
  )

  const copyAvailabilityByDay = (sourceDay: Day, targetDays: Day[]) => {
    const newAbd = (Object.keys(availabilityByDay) as Day[]).reduce(
      (output, day) => {
        return {
          ...output,
          [day]: targetDays.includes(day)
            ? availabilityByDay[sourceDay]
            : availabilityByDay[day],
        }
      },
      availabilityByDay
    )

    setValue("availabilityByDay", newAbd)
    setDayAvailabilityCopyCounter((c) => c + 1)
  }

  // see implementation of DayAvailability below for explanation
  const [dayAvailabilityCopyCounter, setDayAvailabilityCopyCounter] =
    useState(0)

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <VStack spacing={6} color="text.primary">
        <HStack w="full">
          <Heading as="h3" textStyle="ds.display.primary">
            Availability
          </Heading>
          {/*
           * TODO: We'll add this later after beta
           * see https://linear.app/usabilityhub/issue/PRD-3204/remove-ts-prune-ignore-next-rules-for-temporarily-commented-out-work
           * */}
          <Spacer />
          <AvailabilityCalendarPreviewModalTrigger
            leftIcon={<Icon as={ShowIcon} />}
            color="text.primary"
            size="sm"
            fontSize="sm"
            lineHeight={5}
            fontWeight="semibold"
            variant="outline"
          >
            Preview availability
          </AvailabilityCalendarPreviewModalTrigger>
        </HStack>

        <HStack w="full" align="stretch" spacing={4}>
          <VStack alignItems="flex-start" flexGrow={1}>
            <FormControl isInvalid={!!errors.startsAt}>
              <FormLabel
                color="text.primary"
                fontSize="md"
                lineHeight={6}
                fontWeight="medium"
              >
                Start date
              </FormLabel>
              <Input type="date" {...register("startsAt")}></Input>
              <FormErrorMessage>{errors?.startsAt?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
          <VStack alignItems="flex-start" flexGrow={1}>
            <FormControl isInvalid={!!errors.endsAt}>
              <FormLabel
                color="text.primary"
                fontSize="md"
                lineHeight={6}
                fontWeight="medium"
              >
                End date
              </FormLabel>
              <Input type="date" {...register("endsAt")}></Input>
              <FormErrorMessage>{errors?.endsAt?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </HStack>

        <Grid gridTemplateColumns="1fr 1fr 1fr" gap={4} w="full">
          <Stack
            direction="column"
            flexGrow={2}
            spacing={2}
            gridColumn="1 / span 2"
          >
            <Flex direction="row">
              <Heading as="h4" textStyle="ds.heading.primary">
                Weekly hours
              </Heading>
              <Spacer />
              <TimezoneChanger
                {...register("timezone")}
                fontSize="sm"
                lineHeight={5}
                fontWeight="medium"
                size="xs"
              />
            </Flex>
            <Stack
              direction="column"
              border="1px solid"
              borderColor="gray.200"
              borderRadius={6}
              paddingX={6}
              paddingY={4}
              spacing={6}
            >
              <Flex direction="column" gap={4}>
                {DAYS.map((day) => (
                  <DayAvailability
                    // by changing the counter, the key changes, which forces a rerender
                    // without this, multiple timeslots won't visually update when copied
                    key={`${day}-${dayAvailabilityCopyCounter}`}
                    name={DAY_LABELS[day].shortLabel}
                    control={control}
                    setValue={setValue}
                    day={day}
                    availability={availabilityByDay[day]}
                    copyAvailabilityByDay={copyAvailabilityByDay}
                  />
                ))}
              </Flex>
              <Text
                textColor="text.secondary"
                fontSize="sm"
                lineHeight={5}
                fontWeight="normal"
              >
                Invitees will be shown availabilities based on your weekly
                hours. If you want to change availability on specific days,
                please add a date override.
              </Text>
            </Stack>
          </Stack>

          <VStack flexGrow={1} alignItems="flex-start" spacing={2}>
            <Heading as="h4" textStyle="ds.heading.primary">
              Date overrides
            </Heading>
            <Flex
              direction="column"
              border="1px solid"
              borderColor="gray.200"
              borderRadius={6}
              h="full"
              w="full"
            >
              {availabilityOverrides.length > 0 ? (
                <VStack overflowY="scroll" w="full" h="full" spacing={0}>
                  {sortBy(availabilityOverrides, "date").map((item, index) => (
                    <DateOverridesRow
                      key={`DateOverridesRow.${index}`}
                      date={item.date}
                      overrides={item.availability}
                      editOverride={() =>
                        openDayOverrideModal({
                          AddOrUpdateAvailabilityOverrides:
                            AddOrUpdateAvailabilityOverrides,
                          availabilityOverrides: convertToDateOverridesArray(
                            getValues("availabilityOverrides")
                          ),
                          initialDate: convertDateStringToNumbers(item.date),
                        })
                      }
                      deleteOverride={(date: string) =>
                        setValue(
                          "availabilityOverrides",
                          getValues("availabilityOverrides").filter(
                            (item) => item.date !== date
                          )
                        )
                      }
                    />
                  ))}
                </VStack>
              ) : (
                <VStack h="full" justifyContent="center" m={4}>
                  <Text
                    color="text.secondary"
                    fontSize="md"
                    lineHeight={6}
                    fontWeight="semibold"
                    textAlign="center"
                  >
                    You don{"\u2019"}t have any overrides
                  </Text>
                  <Text
                    color="text.secondary"
                    fontSize="sm"
                    lineHeight={5}
                    fontWeight="normal"
                    textAlign="center"
                  >
                    Add dates when your availability changes from your normal
                    weekly hours
                  </Text>
                </VStack>
              )}

              <Flex borderTopWidth={1} borderColor="gray.200">
                <Button
                  w="full"
                  leftIcon={<Icon as={AddIcon} color="brand.neutral.default" />}
                  size="sm"
                  lineHeight={5}
                  fontWeight="semibold"
                  variant="ghost"
                  onClick={() =>
                    openDayOverrideModal({
                      AddOrUpdateAvailabilityOverrides:
                        AddOrUpdateAvailabilityOverrides,
                      availabilityOverrides: convertToDateOverridesArray(
                        getValues("availabilityOverrides")
                      ),
                    })
                  }
                >
                  Add a date
                </Button>
              </Flex>
            </Flex>
          </VStack>
        </Grid>

        <Grid
          w="full"
          alignSelf="start"
          gap="4"
          gridTemplateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: "repeat(auto-fit, minmax(165px, 1fr))",
          }}
        >
          <EventBuffer
            label="Buffer before session"
            registration={register("preEventBufferMins")}
            error={errors.preEventBufferMins}
          />

          <EventBuffer
            label="Buffer after session"
            registration={register("postEventBufferMins")}
            error={errors.postEventBufferMins}
          />

          <Flex direction="column" alignItems="flex-start" gap={2}>
            <FormControl isInvalid={!!errors.availabilityAlignment}>
              <FormLabel
                display="flex"
                alignItems="center"
                color="text.primary"
                fontSize="md"
                lineHeight={6}
                fontWeight="medium"
                whiteSpace="nowrap"
              >
                Session start times
                <Tooltip label="Select the interval times that are offered to your participants">
                  <Icon
                    as={HelpCircleIcon}
                    color="text.secondary"
                    ml={1}
                    boxSize={4}
                  />
                </Tooltip>
              </FormLabel>
              <Select
                {...register("availabilityAlignment")}
                fontSize="sm"
                lineHeight={5}
                fontWeight="normal"
                size="sm"
                borderRadius={6}
              >
                {AVAILABILITY_ALIGNMENT_OPTIONS.map(([label, minutes]) => (
                  <option key={minutes} value={minutes}>
                    {label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.availabilityAlignment?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex direction="column" alignItems="flex-start" gap={2}>
            <FormControl isInvalid={!!errors.minimumNotice}>
              <FormLabel
                display="flex"
                alignItems="center"
                color="text.primary"
                fontSize="md"
                lineHeight={6}
                fontWeight="medium"
                whiteSpace="nowrap"
              >
                Minimum notice
                <Tooltip label="Select the minimum amount of time between when an applicant makes a booking and the earliest available time">
                  <Icon
                    as={HelpCircleIcon}
                    color="text.secondary"
                    ml={1}
                    boxSize={4}
                  />
                </Tooltip>
              </FormLabel>
              <Select
                {...register("minimumNotice")}
                fontSize="sm"
                lineHeight={5}
                fontWeight="normal"
                size="sm"
                borderRadius={6}
              >
                {MINIMUM_NOTICE_OPTIONS.map((hours) => (
                  <option key={hours} value={hours}>
                    {hours} {hours === 1 ? "hour" : "hours"}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.minimumNotice?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </Grid>
      </VStack>
    </form>
  )
}

type EventBufferProps = {
  label: string
  registration: UseFormRegisterReturn
  error: FieldError | undefined
}

const EventBuffer = ({ label, registration, error }: EventBufferProps) => {
  return (
    <VStack alignItems="flex-start" spacing={2}>
      <FormControl isInvalid={!!error}>
        <FormLabel
          color="text.primary"
          fontSize="md"
          lineHeight={6}
          fontWeight="medium"
          whiteSpace="nowrap"
        >
          {label}
        </FormLabel>
        <Select
          {...registration}
          fontSize="sm"
          lineHeight={5}
          fontWeight="normal"
          size="sm"
          borderRadius={6}
        >
          {BUFFER_MINS.map((duration) => (
            <option key={duration} value={duration}>
              {formatBufferMins(duration)}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    </VStack>
  )
}
