import { AdminDashboardContextProvider } from "Admin/components/AdminDashboardContextProvider/AdminDashboardContextProvider"
import { TestResults } from "Components/test-results/test-results-container"
import { AdminUsabilityTestResultDataProvider } from "UsabilityHub/components/ResultsDataProvider/AdminUsabilityTestResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React from "react"

type Props = {
  usabilityTestUniqueId: string
}

export const AdminDashboardTestResults: React.FC<Props> = ({
  usabilityTestUniqueId,
}) => {
  return (
    <AdminDashboardContextProvider>
      <ModalContextProvider>
        <AdminUsabilityTestResultDataProvider
          usabilityTestUniqueId={usabilityTestUniqueId}
        >
          <TestResults />
        </AdminUsabilityTestResultDataProvider>
      </ModalContextProvider>
    </AdminDashboardContextProvider>
  )
}
