export const initialLoadState = {
  _isLoading: false,
  _isLoaded: false,
  _didLoadFail: false,
}

export type LoadAction =
  | { type: "startLoading" }
  | { type: "loadSuccess" }
  | { type: "loadFailure" }

export const usabilityTestLoadReducer = (
  state: typeof initialLoadState,
  action: LoadAction
) => {
  switch (action.type) {
    case "startLoading":
      return { ...state, _isLoading: true }
    case "loadSuccess":
      return { ...state, _isLoading: false, _isLoaded: true }
    case "loadFailure":
      return {
        ...state,
        _isLoading: false,
        _isLoaded: false,
        _didLoadFail: true,
      }
  }
  return state
}
