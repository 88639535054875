import { AdminResponseReview } from "Admin/components/AdminResponseReview/AdminResponseReview"
import { ConfirmReviseReportedResponsesModal } from "Admin/components/AdminResponseReview/confirm-revise-reported-responses-modal"
import { Dispatch } from "Redux/app-store"
import { saveReview } from "Redux/reducers/test-results/action-creators"
import { saveThirdPartyOrderReview } from "Redux/reducers/test-results/action-creators"
import { getAllResponses } from "Redux/reducers/test-results/selectors"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { ReviewingBanner } from "./ReviewingBanner"

type Props = {
  orderId: number
  revising: boolean
  isThirdPartyOrder: boolean
  reportedResponseIds?: number[]
}

export const OrderResponsesReview: React.FC<Props> = ({
  orderId,
  revising,
  isThirdPartyOrder,
  reportedResponseIds,
}) => {
  const dispatch = useDispatch<Dispatch>()
  const { open: openConfirmModal } = useModal(
    ConfirmReviseReportedResponsesModal
  )

  const onSaveThirdPartyOrder = async () => {
    await dispatch(saveThirdPartyOrderReview())
  }

  const responses = useSelector(getAllResponses)

  const handleSave = async () => {
    if (isThirdPartyOrder) {
      let needConfirmModal = false
      if (revising && reportedResponseIds) {
        const countReportedResponses = responses.filter(
          (response) =>
            response.review_status === 0 &&
            reportedResponseIds.includes(response.id)
        ).length

        if (countReportedResponses > 0) {
          openConfirmModal({
            numOfReportedResponses: countReportedResponses,
            handleConfirm: onSaveThirdPartyOrder,
          })
          needConfirmModal = true
        }
      }

      if (!needConfirmModal) {
        await onSaveThirdPartyOrder()
      }
    } else {
      await dispatch(saveReview())
    }
  }

  return (
    <AdminResponseReview revising={revising} onSave={handleSave}>
      <ReviewingBanner
        orderId={orderId}
        isThirdPartyOrder={!!isThirdPartyOrder}
      />
    </AdminResponseReview>
  )
}
