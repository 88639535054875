import { Breadcrumb, BreadcrumbItem, Flex, Text } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { ChevronRightIcon } from "Icons/ChevronRightIcon"
import { HomeIcon } from "Icons/HomeIcon"
import React from "react"
import { Link } from "react-router-dom"

export type HeaderBreadcrumbsData = (
  | {
      name: string
      isCurrentPage: boolean
      to?: never
    }
  | {
      name: string
      // This uses `?` because some pre-current-page breadcrumbs
      // can't be links when viewed as an unauthed user
      to?: string
      isCurrentPage?: never
    }
)[]

type Props = {
  breadcrumbsData: HeaderBreadcrumbsData
  replaceCurrentPageWith?: React.ReactNode
}

export const HeaderBreadcrumbs: React.FC<Props> = ({
  breadcrumbsData,
  replaceCurrentPageWith,
}) => (
  <Breadcrumb
    textStyle="ds.interface.medium"
    color="ds.text.subtle"
    separator={<ChevronRightIcon boxSize={4} color="ds.icon.subtle" />}
    spacing={1}
  >
    {breadcrumbsData.map((breadcrumb) => {
      if (replaceCurrentPageWith && breadcrumb.isCurrentPage)
        return replaceCurrentPageWith

      return (
        <BreadcrumbItem
          key={`breadcrumb-${breadcrumb.name}`}
          isCurrentPage={breadcrumb.isCurrentPage}
        >
          <ButtonOrLabel
            to={breadcrumb.to}
            isCurrentPage={breadcrumb.isCurrentPage}
            ariaLabel={breadcrumb.name}
          >
            {breadcrumb.name === "Dashboard" ? (
              // We hide the "Dashboard" text if there's 3+ breadcrumbs
              <Flex align="center" gap={1.5}>
                <HomeIcon boxSize={4} color="ds.icon.subtle" />
                {breadcrumbsData.length <= 2 && (
                  <Text as="span">{breadcrumb.name}</Text>
                )}
              </Flex>
            ) : (
              breadcrumb.name
            )}
          </ButtonOrLabel>
        </BreadcrumbItem>
      )
    })}
  </Breadcrumb>
)

const ButtonOrLabel: React.FC<
  React.PropsWithChildren<{
    to?: string
    isCurrentPage?: boolean
    ariaLabel: string
  }>
> = ({ to, isCurrentPage, ariaLabel, children }) => {
  if (to) {
    return (
      <Button
        as={Link}
        to={to}
        variant={isCurrentPage ? "secondary" : "subtle"}
        size="flush"
        noOfLines={1}
        aria-label={ariaLabel}
        // This is a hack to correct for the changed line height caused by
        // the CSS rules applied by the noOfLines prop above. Removing both
        // props looks the same in terms of positioning, except it wraps
        // instead of truncating.
        lineHeight={1.2}
      >
        {children}
      </Button>
    )
  }

  return (
    // Made to look like a subtle button but without the clicky part
    <Text textStyle="ds.interface.medium" color="ds.text.default">
      {children}
    </Text>
  )
}
