import { Flex, useDisclosure } from "@chakra-ui/react"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import {
  GetApiTestResultsResponse,
  useGetApiTestResults,
} from "~/api/generated/usabilityhub-components"
import { useIsSharedTestResults } from "../context/shared-test-results"
import { CSVExportSuggestionAlert } from "./CSVExportSuggestionAlert"
import { HiddenResponsesAlert } from "./HiddenResponsesAlert"
import { ShareTestResultsModal } from "./ShareTestResultsModal"
import { BREAKPOINT_MAX } from "./breakpoints"
import { RatingForm } from "./rating-form/RatingForm"
import { Rating } from "./rating-form/types"
import { SummaryActions } from "./summary-actions/SummaryActions"
import { SummaryMetrics } from "./summary-metrics/SummaryMetrics"
import { useTestResultsSummary } from "./useTestResultsSummary"

export type SummaryStatus = "ready" | "collecting" | "complete"
export type StatusTags = GetApiTestResultsResponse["status_tags"]

export const TestResultsSummary: React.FC = () => {
  const {
    usabilityTest,
    filteredResponseIds,
    accountCanExportCsv,
    panelOrdersCount,
    completedPanelResponsesCount,
    allResponsesCount,
    responsesLimitCount,
    hiddenResponseCount,
    hasScreener,
    screenedOutResponseCount,
  } = useTestResultsSummary()
  const [isRatingFormVisible, setIsRatingFormVisible] = React.useState(false)

  const usabilityTestUniqueId = usabilityTest.unique_id
  const usabilityTestPrivateId = usabilityTest.private_id

  const currentUser = useMaybeCurrentUser()

  const { data: testResults, isLoading: isLoadingTestResults } =
    useGetApiTestResults(
      {
        pathParams: { usabilityTestUniqueId },
      },
      {
        // This API requires auth and we might be on the shared results page with no logged-in user
        enabled: !!currentUser,
      }
    )

  const persistedRating = (testResults?.customer_rating as Rating) ?? null
  const [lastRating, setLastRating] = React.useState(persistedRating)
  const [hasRatedAtLeastOnce, setHasRatedAtLeastOnce] = React.useState(false)

  // Because the persistedRating comes from an async API call
  // it won't be available on first render
  React.useEffect(() => {
    if (persistedRating !== lastRating) {
      setLastRating(persistedRating)
      setHasRatedAtLeastOnce(true)
    }
  }, [persistedRating])

  // We only want to change the button to say "Update rating" after they've closed
  // the rating form, not immediately when they submit a rating
  React.useEffect(() => {
    if (lastRating !== null) {
      setHasRatedAtLeastOnce(true)
    }
  }, [isRatingFormVisible])

  const {
    isOpen: isShareLinkModalOpen,
    onOpen: openShareLinkModal,
    onClose: closeShareLinkModal,
  } = useDisclosure()

  const participantsCount = allResponsesCount + hiddenResponseCount
  const filteredResponsesCount = filteredResponseIds.length
  const responseCap = Math.min(allResponsesCount, responsesLimitCount)

  const status = isLoadingTestResults
    ? null
    : ((testResults?.status?.toLowerCase() as SummaryStatus) ?? "ready")
  const statusTags = (
    isLoadingTestResults ? [] : (testResults?.status_tags ?? [])
  ) as StatusTags

  const isShared = useIsSharedTestResults()

  const handleShareClick = openShareLinkModal
  const handleRateClick = () => {
    setIsRatingFormVisible(!isRatingFormVisible)
  }

  const handleRatingFormClose = () => {
    setIsRatingFormVisible(false)
  }

  const showRatingButton =
    panelOrdersCount > 0 &&
    completedPanelResponsesCount > 0 &&
    !!status &&
    ["collecting", "complete"].includes(status)

  return (
    <Flex sx={cqContainer()} flexDirection="column" gap={4} mb={8}>
      {allResponsesCount >= responsesLimitCount && (
        <CSVExportSuggestionAlert
          allResponsesCount={allResponsesCount}
          responsesLimitCount={responsesLimitCount}
          accountCanExportCsv={accountCanExportCsv}
          usabilityTestUniqueId={usabilityTestUniqueId}
          usabilityTestPrivateId={usabilityTestPrivateId}
        />
      )}
      <Flex
        flexDirection="column"
        gap={4}
        bg="ds.surface.raised.resting"
        rounded="16px"
        shadow="ds.raised"
        p={4}
        sx={cq(BREAKPOINT_MAX, { p: 6 })}
      >
        <SummaryMetrics
          status={status}
          statusTags={statusTags}
          participantsCount={participantsCount}
          filteredResponsesCount={filteredResponsesCount}
          responseCap={responseCap}
          usabilityTestUniqueId={usabilityTestUniqueId}
          hasScreener={hasScreener}
          screenedOutResponseCount={screenedOutResponseCount}
        />
        <SummaryActions
          handleShareClick={handleShareClick}
          handleRateClick={handleRateClick}
          isRatingFormVisible={isRatingFormVisible}
          hasRatedAlready={hasRatedAtLeastOnce}
          usabilityTestUniqueId={usabilityTestUniqueId}
          usabilityTestPrivateId={usabilityTestPrivateId}
          filteredResponseIds={filteredResponseIds}
          accountCanExportCsv={accountCanExportCsv}
          showRatingButton={showRatingButton}
        />
        {isRatingFormVisible && (
          <RatingForm
            onClose={handleRatingFormClose}
            usabilityTestUniqueId={usabilityTestUniqueId}
            lastRating={lastRating}
            setLastRating={setLastRating}
          />
        )}
        {isShareLinkModalOpen && (
          <ShareTestResultsModal
            isOpen={isShareLinkModalOpen}
            onClose={closeShareLinkModal}
            usabilityTestId={usabilityTest.id}
          />
        )}
        {!isShared && hiddenResponseCount > 0 && (
          <HiddenResponsesAlert hiddenResponseCount={hiddenResponseCount} />
        )}
      </Flex>
    </Flex>
  )
}
