import { Icon } from "@chakra-ui/react"
import React from "react"

import { CheckCircleFilledIcon } from "Icons/CheckCircleFilledIcon"
import { HelpCircleFilledIcon } from "Icons/HelpCircleFilledIcon"
import { RemoveFilledIcon } from "Icons/RemoveFilledIcon"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

export function getScreenerQuestionOptionRuleLabel(
  type: NonNullable<ScreenerQuestion["options"]>[0]["rule"]
): React.ReactNode {
  if (type === "qualify") {
    return (
      <>
        <Icon as={CheckCircleFilledIcon} color="green.400" boxSize={4} me={2} />
        Qualify
      </>
    )
  }

  if (type === "disqualify") {
    return (
      <>
        <Icon as={RemoveFilledIcon} color="red.600" boxSize={4} me={2} />
        Disqualify
      </>
    )
  }

  if (type === "not_relevant") {
    return (
      <>
        <Icon as={HelpCircleFilledIcon} color="gray.300" boxSize={4} me={2} />
        Not relevant
      </>
    )
  }

  throw new Error("type is not supported")
}
