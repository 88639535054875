import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react"
import React, { forwardRef, useState } from "react"

import { DeleteIcon } from "Icons/DeleteIcon"
import CustomerLogo from "UsabilityHub/components/UsabilityTestLayout/CustomerLogo"

const MAX_SIZE = 2 * 1024 * 1024
const MAX_SIZE_IN_MB = MAX_SIZE / 1024 / 1024

type FileInputProps = React.PropsWithChildren<{
  name: string
  srcUrl: string
  onChange: (file: File | null) => void
}>

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ name, srcUrl, onChange }, ref) => {
    const [defaultSrc, setDefaultSrc] = useState<string | null>(srcUrl)
    const [src, setSrc] = useState<FileReader["result"]>(null)
    const img = src || defaultSrc
    return (
      <Stack spacing={4}>
        {img && <CustomerLogo url={img as string} />}
        <Stack direction="row" align="center" spacing={2}>
          <Box>
            <Input
              ref={ref}
              accept="image/*"
              id={name}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                const target = event.currentTarget
                if (!target.files!.length) return
                const file = target.files![0]
                if (file.size > MAX_SIZE) {
                  target.value = ""
                  window.alert(
                    `Your image must be less than ${MAX_SIZE_IN_MB}MB in size, please choose a smaller file.`
                  )
                } else {
                  const fileReader = new FileReader()
                  fileReader.onload = (e) => {
                    setSrc(e.target!.result)
                    onChange(file)
                  }
                  fileReader.readAsDataURL(file)
                }
              }}
              type="file"
              display="none"
            />
            <Button
              as={FormLabel}
              size="sm"
              htmlFor={name}
              pb={0}
              cursor="pointer"
              mb={0}
            >
              {img ? "Change file" : "Choose file"}
            </Button>
          </Box>
          {img && (
            <IconButton
              aria-label="Delete"
              icon={<Icon as={DeleteIcon} />}
              size="sm"
              onClick={() => {
                setSrc(null)
                setDefaultSrc(null)
                onChange(null)
              }}
            />
          )}
          <FormHelperText mt={0}>
            The maximum size is {MAX_SIZE_IN_MB}MB
          </FormHelperText>
        </Stack>
      </Stack>
    )
  }
)
