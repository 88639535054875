import { Box, Button, Flex, Heading, Tag, Text } from "@chakra-ui/react"
import { LinkButton } from "Components/button/link-button"
import { ReportIcon } from "Icons/ReportIcon"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"

type Props = {
  isPanelist: boolean
  onContinue: () => void
  showReportModal: () => void
  initialFocusRef: React.RefObject<HTMLButtonElement>
}

export const ScreenerWelcomeScreen: React.FC<Props> = ({
  isPanelist,
  onContinue,
  showReportModal,
  initialFocusRef,
}) => {
  const t = useTranslate()

  return (
    <Flex direction="column" justify="center" h="full" mx={[6, 12]}>
      <Flex direction="column" align="flex-start">
        {isPanelist && (
          <Button
            position="absolute"
            top={4}
            right={4}
            onClick={showReportModal}
          >
            <ReportIcon />
          </Button>
        )}

        <Tag textTransform="uppercase">{t("test.screener.screener_tag")}</Tag>

        <Heading as="h2" fontFamily="promo" size="lg" mt={6}>
          {t("test.screener.welcome.heading")}
        </Heading>

        <Text mt={4}>{t("test.screener.welcome.body")}</Text>

        <Box rounded="md" bg="bg.surface.callout" p={3} mt={4}>
          <Text>{t("test.screener.welcome.callout")}</Text>
        </Box>

        <Flex mt={8} gap={2}>
          <Button
            colorScheme="brand.primary"
            onClick={onContinue}
            ref={initialFocusRef}
          >
            {t("test.buttons.continue")}
          </Button>

          {isPanelist && (
            <LinkButton href={ROUTES.DASHBOARD.path} variant="ghost">
              Return to dashboard
            </LinkButton>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
