import {
  Avatar,
  Flex,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { EventTime } from "Components/time/event-time"
import { AdminNote } from "Types"

import { BookmarkFilledIcon } from "Icons/BookmarkFilledIcon"
import { EditFilledIcon } from "Icons/EditFilledIcon"
import { AdminNotesDeleteButton } from "./AdminNotesDeleteButton"
import { NoteEditor } from "./NoteEditor"

interface Props {
  note: AdminNote
  updateNote: (note: AdminNote) => Promise<void>
  deleteNote: (noteId: number) => Promise<void>
  editable: boolean
}
export const SingleAdminNote: React.FC<Props> = ({
  note,
  updateNote,
  deleteNote,
  editable,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const handleSave = async (note: AdminNote) => {
    await updateNote(note)
    setIsEditing(false)
  }

  const handleTogglePinned = () =>
    updateNote({
      ...note,
      pinned_at: note.pinned_at ? null : new Date().toISOString(),
    })

  return (
    <Flex
      p={4}
      pr={1}
      justify="space-between"
      bg={note.pinned_at ? "yellow.50" : "white"}
      borderColor="gray.200"
      key={note.id}
      className="AdminNote"
      gap={3}
    >
      <Avatar
        size="md"
        name={note.creator_name}
        src={note.creator_avatar_url}
      />
      <Stack flexGrow={1}>
        <Text flex="1 0 auto" color="gray.500" fontSize={14} fontWeight="500">
          {note.creator_name}, <EventTime date={note.created_at} />
        </Text>
        {isEditing ? (
          <NoteEditor
            note={note}
            onSave={handleSave}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <DisplayBlockMarkdownText text={note.text} allowLinks />
        )}
      </Stack>
      <Stack mt={-1} spacing={0} data-group>
        <AdminNotesDeleteButton note={note} deleteNote={deleteNote} />

        {editable && (
          <Tooltip hasArrow label="Edit note" placement="top">
            <IconButton
              icon={<Icon as={EditFilledIcon} />}
              variant="ghost"
              size="sm"
              aria-label="Edit note"
              onClick={() => setIsEditing((v) => !v)}
            />
          </Tooltip>
        )}

        <Tooltip
          hasArrow
          label={note.pinned_at ? "Un-pin note" : "Pin note"}
          placement="top"
        >
          <IconButton
            icon={<Icon as={BookmarkFilledIcon} />}
            colorScheme="yellow"
            variant="ghost"
            size="sm"
            aria-label={note.pinned_at ? "Un-pin note" : "Pin note"}
            onClick={handleTogglePinned}
            visibility={note.pinned_at ? "visible" : "hidden"}
            _groupHover={{ visibility: "visible" }}
          />
        </Tooltip>
      </Stack>
    </Flex>
  )
}
