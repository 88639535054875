import { ReviewPracticeTestResultDataProvider } from "UsabilityHub/components/ResultsDataProvider/ReviewPracticeTestResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React from "react"
import { AdminPracticeTestReview } from "./AdminPracticeTestReview"

type Props = {
  usabilityTestUniqueId: string
}

// This wrapper is needed because we use mount_react to render AdminResponseReview directly
// so it doesn't have access to the context providers from the SPA.
export const AdminPracticeTestReviewWrapper: React.FC<Props> = ({
  usabilityTestUniqueId,
}) => {
  return (
    <ModalContextProvider>
      <ReviewPracticeTestResultDataProvider
        usabilityTestUniqueId={usabilityTestUniqueId}
      >
        <AdminPracticeTestReview />
      </ReviewPracticeTestResultDataProvider>
    </ModalContextProvider>
  )
}
