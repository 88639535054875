import React, { ReactElement, ReactNode, useEffect, useRef } from "react"

import { SkipUsabilityTestModal } from "Components/skip-usability-test-modal/skip-usability-test-modal"
import { Dispatch } from "Redux/app-store"
import { cancelResponse } from "Redux/reducers/current-response/action-creators"
import { isAxiosError, isBadRequestError } from "Services/axios"
import {
  AutomaticDeletionReason,
  Language,
  ParticipantDeletionReason,
  ParticipantResponse,
  UsabilityTestSectionQuestion as Question,
  Response,
  ResponseAnswer,
  ResponseDemographicProfile,
  Screenshot,
  TestBranding,
  ThankYouMessageCopy,
  Unpersisted,
  UsabilityTestSection,
  UsabilityTestSectionType,
  WelcomeMessageCopy,
} from "Types"
import { abandonedErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/abandoned-error-content"
import {
  maliciousErrorContent,
  tooFastErrorContent,
} from "UsabilityHub/components/UsabilityTest/content-factory/automatically-deleted-content"
import demographicQuestionsContent from "UsabilityHub/components/UsabilityTest/content-factory/demographic-questions-content/demographic-questions-content.container"
import { disconnectedErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/disconnected-error-content"
import { loadErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/load-error-content"
import { submitErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/submit-error-content"
import submittingContent from "UsabilityHub/components/UsabilityTest/content-factory/submitting-content"
import thankYouContent from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/thankYouContent"
import { timedOutErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/timed-out-error-content"
import welcomeContent from "UsabilityHub/components/UsabilityTest/content-factory/welcome-content"
import {
  AppearanceProps as LayoutAppearanceProps,
  LayoutState,
  UsabilityTestLayout,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import UsabilityTestSectionQuestion from "UsabilityHub/components/UsabilityTestSectionQuestion/UsabilityTestSectionQuestion"
import { UsabilityTestSectionTask } from "UsabilityHub/components/UsabilityTestSectionTask/UsabilityTestSectionTask"
import { ROUTES } from "UsabilityHub/views/routes"
import { beforeUnloadHandler } from "Utilities/before-unload-handler"
import { reportErrorToSentry } from "Utilities/error"
import { minDuration, neverResolve } from "Utilities/promise"
import {
  ResponsePhase,
  ResponseState,
  isDeleted,
  isPanelOrdered,
  isPreview,
  isRecruited,
} from "Utilities/response"
import { isParticipantDeletionReason } from "Utilities/response-deletion-reason"
import { isSuccess, promiseToResult } from "Utilities/result"
import { useDispatch } from "react-redux"
import type {
  RecordingType,
  RecruitmentLink,
} from "~/api/generated/usabilityhubSchemas"
import TestInterfaceApi from "~/api/testInterfaceApi"
import { InformationTaskMedia } from "../UsabilityTestSectionTask/SectionTasks/InformationTaskMedia"
import { UsabilityTestPreviewBanner } from "./UsabilityTestPreviewBanner"
import recordingSetupGuideContent from "./content-factory/recording-setup-guide-content/RecordingSetupGuideContent"
import { unhandledDeletionReasonContent } from "./content-factory/unhandled-deletion-reason-content"
import { TestBrandingContextProvider } from "./context/testBranding"
import { DisconnectedBanner } from "./disconnected-banner"

interface CallbackProps {
  addResponseAnswer: (answer: Readonly<Unpersisted<ResponseAnswer>>) => void
  addResponseDemographics: (
    demographics: Partial<ResponseDemographicProfile>
  ) => void
  keepResponseAlive: () => void
  submitResponse: () => Promise<void>
  loadUsabilityTest: (isPreview: boolean) => Promise<void>
  updateResponse: (fields: Partial<Response>) => void
}

interface DataProps {
  didLoadFail: boolean
  isLoaded: boolean
  isConnected: boolean
  language: Language
  recruitmentLink: RecruitmentLink
  redirectLink: string | null
  response: ParticipantResponse
  responseState: ResponseState
  screenshots: ReadonlyArray<Screenshot>
  testBranding: TestBranding
  thankYouCopy: ThankYouMessageCopy
  welcomeCopy: WelcomeMessageCopy
  hasPlayableScreenshot: boolean
  allowedRecordingTypes: RecordingType[]
  areAllRecordingsUploaded: boolean
  isExternalStudy: boolean
  isLiveWebsiteTest: boolean
  cleanupScreenStream: () => void
  recordingSetupGuideFinished: boolean
}

type Props = CallbackProps & DataProps

function sectionLayoutState(
  usabilityTestSection: UsabilityTestSection,
  question: Question | null
): LayoutState {
  const { type } = usabilityTestSection

  if (type === UsabilityTestSectionType.Information) {
    if (question !== null) {
      reportErrorToSentry(
        new TypeError(`Section of type ${type} should not have any questions`),
        { extra: { usabilityTestSection, question } }
      )
    }

    if (usabilityTestSection.section_screenshots.length > 0) {
      return LayoutState.ZoomableSplit
    }

    return LayoutState.FocusQuestion
  }

  // If there is no current question, focus the media pane.
  if (question === null) {
    return LayoutState.FocusMedia
  }

  // We have a question - some sections must be hidden during questions,
  // check if this is one of them...
  switch (usabilityTestSection.type) {
    case UsabilityTestSectionType.FiveSecondTest:
    case UsabilityTestSectionType.Questions:
      return LayoutState.FocusQuestion
    case UsabilityTestSectionType.PrototypeTask:
      return LayoutState.FocusMedia
  }

  // ...Otherwise it's a split with optional zoom.
  return LayoutState.ZoomableSplit
}

function sectionContent(props: Props): LayoutAppearanceProps {
  const {
    addResponseAnswer,
    responseState: { usabilityTestSection, question, responseSection },
  } = props

  // TODO: Enforce statically.
  if (usabilityTestSection === null) throw new Error("Nah!")

  const taskNode: ReactNode = (
    <UsabilityTestSectionTask
      key={usabilityTestSection.id}
      usabilityTestSection={usabilityTestSection}
      responseSection={responseSection}
    />
  )

  const questionNode: ReactElement | null = question && (
    <UsabilityTestSectionQuestion
      section={usabilityTestSection}
      key={question.id}
      question={question}
      onAnswerSubmit={addResponseAnswer}
    />
  )

  const content = {
    isReportButtonVisible:
      usabilityTestSection.type !== UsabilityTestSectionType.PrototypeTask &&
      usabilityTestSection.type !== UsabilityTestSectionType.ExternalStudy,
    layoutState: sectionLayoutState(usabilityTestSection, question),
    questionContent: questionNode,
    mediaContent: taskNode,
  }

  if (usabilityTestSection.type === "information") {
    const hasMedia = usabilityTestSection.section_screenshots.length > 0
    return {
      ...content,
      questionContent: taskNode,
      mediaContent: hasMedia ? (
        <InformationTaskMedia usabilityTestSection={usabilityTestSection} />
      ) : null,
    }
  }

  return content
}

function testContent(props: Props): LayoutAppearanceProps {
  switch (props.responseState.phase) {
    case ResponsePhase.DemographicQuestions: {
      const { addResponseDemographics, recruitmentLink, response } = props
      const demographicQuestionsProps = {
        addResponseDemographics,
        recruitmentLink,
        thirdPartyOrderId: response.third_party_order_id,
        permitUnload,
      }
      return demographicQuestionsContent(demographicQuestionsProps)
    }
    case ResponsePhase.TakingTest:
      return sectionContent(props)
    case ResponsePhase.Complete: {
      permitUnload()
      return thankYouContent(props)
    }
  }
}

const [preventUnload, permitUnload] = beforeUnloadHandler()

interface State {
  isAbleToRetrySubmit: boolean
  isSkipModalOpen: boolean
  isSubmitting: boolean
  startTime: number | null
  submitError: string | null
  beforeSubmit: boolean
}

export const UsabilityTestImpl = (props: Props) => {
  const dispatch = useDispatch<Dispatch>()

  const [state, setState] = React.useState<State>({
    isAbleToRetrySubmit: false,
    isSkipModalOpen: false,
    isSubmitting: false,
    startTime: null,
    submitError: null,
    beforeSubmit: false,
  })

  // Initial beforeSubmit state. It will be set to true if users are starting the test.
  // Besides, if the response is a preview, we don't need to check the beforeSubmit state.
  useEffect(() => {
    if (
      props.responseState.phase !== ResponsePhase.Complete &&
      !isPreview(props.response)
    ) {
      setState((prevState) => ({ ...prevState, beforeSubmit: true }))
    }
  }, [props.responseState.phase, props.response.id])

  const [waitForRecordingUploads, setWaitForRecordingUploads] =
    React.useState(false)
  const prevResponsePhase = useRef(props.responseState.phase)

  // -- Event handlers --

  const onSkip = async (reason: ParticipantDeletionReason) => {
    await dispatch(cancelResponse(reason))
    permitUnload()

    // TODO: Should this be used by testers? See also, comment in EditPasswordForm
    const dashboard = ROUTES.DASHBOARD.path

    window.location.href =
      reason === ParticipantDeletionReason.Skipped
        ? dashboard
        : TestInterfaceApi.flagged.path()
    await neverResolve()
  }

  const handleStart = () => {
    const { isLoaded, response } = props

    if (!isLoaded) {
      reportErrorToSentry(
        new Error("Started test before screenshots finished loading")
      )
    }

    if (isRecruited(response) && !isPreview(response)) {
      // Recruited participant only need to be protected against drop-off once
      // they have started the test as they can come back later and finish it.
      preventUnload()
    }
    setState((prevState) => ({ ...prevState, startTime: performance.now() }))
  }

  const handleOpenSkipModal = () => {
    setState((prevState) => ({ ...prevState, isSkipModalOpen: true }))
  }

  const handleCloseSkipModal = () => {
    setState((prevState) => ({ ...prevState, isSkipModalOpen: false }))
  }

  const submit = async () => {
    if (isPreview(props.response)) return permitUnload()
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }))

      const result = await minDuration(
        1800,
        promiseToResult(props.submitResponse())
      )

      if (isSuccess(result)) {
        permitUnload()
        setState((prevState) => ({
          ...prevState,
          submitError: null,
        }))
      } else {
        const { error } = result
        if (isAxiosError(error)) {
          if (error.response !== undefined) {
            if (isBadRequestError<string>(error)) {
              // Invalid request, server returns reason.
              setState((prevState) => ({
                ...prevState,
                submitError: error.response.data,
                isAbleToRetrySubmit: false,
              }))
            } else {
              // Unexpected response.
              setState((prevState) => ({
                ...prevState,
                submitError: `Something went wrong while submitting your response: ${error.response!.statusText}`,
                isAbleToRetrySubmit: true,
              }))
            }
          } else if (error.request && error.request.status === 0) {
            // Disconnected client.
            setState((prevState) => ({
              ...prevState,
              submitError:
                "We were unable to submit your response, you appear to be offline",
              isAbleToRetrySubmit: true,
            }))
          }
        } else {
          // A non-network error occurred.
          setState((prevState) => ({
            ...prevState,
            submitError: "An unexpected error occurred.",
            isAbleToRetrySubmit: true,
          }))

          throw error
        }
      }
    } finally {
      setState((prevState) => ({
        ...prevState,
        isSubmitting: false,
        beforeSubmit: false,
      }))
    }
  }

  useEffect(() => {
    const { loadUsabilityTest, keepResponseAlive, response } = props

    if (isPanelOrdered(response)) {
      // Ordered responses must be kept alive to avoid idle timeouts
      keepResponseAlive()
    }
    if (!isRecruited(response)) {
      // Participant coming from the panel or a third-party panel should be
      // warned before they leave the test because they can't redo it.
      preventUnload()
    }

    void loadUsabilityTest(isPreview(response))
  }, [])

  useEffect(() => {
    const {
      responseState: { phase },
      updateResponse,
    } = props

    if (
      phase === ResponsePhase.Complete &&
      prevResponsePhase.current !== phase
    ) {
      prevResponsePhase.current = phase
      let { startTime } = state
      if (startTime === null) {
        reportErrorToSentry(
          new TypeError("Completed response without setting `startTime`")
        )
        startTime = performance.now()
      }
      updateResponse({ duration_ms: performance.now() - startTime })
      props.cleanupScreenStream()
      if (props.areAllRecordingsUploaded) {
        void submit()
      } else {
        setWaitForRecordingUploads(true)
        setState((prevState) => ({ ...prevState, isSubmitting: true }))
      }
    }
  }, [props.responseState.phase, props.areAllRecordingsUploaded])

  useEffect(() => {
    if (waitForRecordingUploads && props.areAllRecordingsUploaded) {
      setWaitForRecordingUploads(false)
      void submit()
    }
  }, [waitForRecordingUploads, props.areAllRecordingsUploaded])

  const handleSkip = async (reason: ParticipantDeletionReason) => {
    setState((prevState) => ({ ...prevState, isSubmitting: true }))
    await onSkip(reason)
    setState((prevState) => ({ ...prevState, isSubmitting: false }))
  }

  const {
    isConnected,
    welcomeCopy,
    language,
    response,
    testBranding,
    isLoaded,
    didLoadFail,
    hasPlayableScreenshot,
    allowedRecordingTypes,
    recordingSetupGuideFinished,
    isExternalStudy,
    isLiveWebsiteTest,
  } = props
  const {
    isAbleToRetrySubmit,
    isSkipModalOpen,
    isSubmitting,
    startTime,
    submitError,
  } = state
  let content: LayoutAppearanceProps

  const isPanelist = isPanelOrdered(response)

  // Handle deletion reasons before checking `didSubmitFail`. If a response
  // gets submitted after it is deleted the submission fails. Prefer to check
  // the deletion reasons first.
  //
  // TODO: Consider actually returning the deleted response on submission? May
  //       not be necessary.
  if (didLoadFail) {
    content = loadErrorContent()
  } else if (
    // There is a moment when the response phase is set to complete, but the
    // response has not yet been submitted. Without this check, the thank you
    // content would be shown before the response is actually submitted.
    (state.beforeSubmit &&
      props.responseState.phase === ResponsePhase.Complete) ||
    isSubmitting
  ) {
    content = submittingContent()
  } else if (
    isDeleted(response) &&
    // Inactive is handled separately, see also `<TimeoutGracePeriodModal />`
    response.deletion_reason !== ParticipantDeletionReason.Inactive
  ) {
    switch (response.deletion_reason) {
      case AutomaticDeletionReason.Disconnected:
        content = disconnectedErrorContent()
        break
      case AutomaticDeletionReason.TimedOut:
        content = timedOutErrorContent()
        break
      case AutomaticDeletionReason.Malicious:
        content = maliciousErrorContent({ responseId: response.id })
        break
      case AutomaticDeletionReason.TooFast:
        content = tooFastErrorContent({ responseId: response.id })
        break
      case ParticipantDeletionReason.CanceledToStartAnotherResponse:
        content = abandonedErrorContent()
        break
      default:
        content = unhandledDeletionReasonContent()
        // If the user has flagged the test then just continue with test interface.
        if (isParticipantDeletionReason(response.deletion_reason)) {
          content = testContent(props)
        } else {
          // Otherwise report this error as unexpected.
          reportErrorToSentry(
            new Error(
              `Unexpected deletion reason: ${response.deletion_reason}`
            ),
            {
              extra: { id: response.id, reason: response.deletion_reason },
            }
          )
        }
        break
    }
  } else if (submitError !== null) {
    content = submitErrorContent({
      message: submitError,
      response,
      retry: isAbleToRetrySubmit ? submit : null,
    })
  } else if (startTime === null) {
    content = welcomeContent({
      copy: welcomeCopy,
      isLoaded,
      onStart: handleStart,
      branding: testBranding,
      hasPlayableScreenshot,
      allowedRecordingTypes,
      isPanelist,
      isExternalStudy,
      isLiveWebsiteTest,
    })
  } else if (!recordingSetupGuideFinished) {
    content = recordingSetupGuideContent()
  } else {
    content = testContent(props)
  }

  // Only show report button to our panelists.
  if (!isPanelist) {
    content.isReportButtonVisible = false
  }

  let bannerNode: ReactNode = null
  if (isPreview(response)) {
    bannerNode = (
      <UsabilityTestPreviewBanner
        setStarted={(started: boolean) =>
          setState((prevState) => ({
            ...prevState,
            startTime: started ? performance.now() : null,
          }))
        }
      />
    )
  } else if (!isConnected) {
    bannerNode = <DisconnectedBanner />
  }

  return (
    <TestBrandingContextProvider branding={testBranding}>
      <SkipUsabilityTestModal
        isOpen={isSkipModalOpen}
        onClose={handleCloseSkipModal}
        onSkip={handleSkip}
        language={language}
      />

      <UsabilityTestLayout
        bannerChildren={bannerNode}
        onReport={handleOpenSkipModal}
        {...content}
      />
    </TestBrandingContextProvider>
  )
}
