import { Box, Stack } from "@chakra-ui/react"
import React, { FC, ReactNode, useContext } from "react"
import { connect, useSelector } from "react-redux"

import { ClearButton } from "Components/column-form/clear-button"
import { ClearableFormSection } from "Components/column-form/clearable-form-section"
import { Dispatch } from "Redux/app-store"
import {
  getAllFigmaFileVersions,
  getAllResponseSectionFigmaTasks,
  getAllResponseSections,
  getAllScreenerQuestions,
  getUsabilityTest,
} from "Redux/reducers/test-results/selectors"
import {
  ResponseSection,
  ResponseSectionFigmaTask,
  TestResultsFigmaFileVersion,
} from "Types"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { useTestResultViewState } from "../context/test-result-view-state"

import { getOptionsFromFilter } from "./get-filter-values"
import { FilterOption } from "./types"

interface OptionImplProps {
  readonly label: ReactNode
  readonly onClear: () => void
}

const ClearableOptionImpl: React.FC<OptionImplProps> = ({ label, onClear }) => (
  <Box display="flex" alignItems="center">
    <ClearButton onClick={onClear} primary />
    {label}
  </Box>
)

const ClearableOption = connect(
  null,
  (dispatch: Dispatch, ownProps: FilterOption) => ({
    onClear() {
      dispatch(ownProps.clearAction)
    },
  })
)(ClearableOptionImpl)

interface ClearableOptionsProps {
  label: string
  readonly options: readonly FilterOption[]
  readonly onClear: () => void
}

const ClearableOptions: React.FC<ClearableOptionsProps> = ({
  label,
  options,
  onClear,
}) => {
  // Don't show the header if no filters are applied
  if (options.length === 0) return null

  return (
    <ClearableFormSection
      title={label}
      onClear={onClear}
      disabled={options.length === 0}
      infoMessage="Toggle these filters in the test results view"
    >
      <Stack spacing="0.25em">
        {options.map((option, index) => (
          <ClearableOption key={index} {...option} />
        ))}
      </Stack>
    </ClearableFormSection>
  )
}

export const AnswerFilterSection: FC = () => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )
  const { commonPathNamesBySectionId, viewState } = useTestResultViewState()
  const test = useSelector(getUsabilityTest)
  const responseSections = useSelector(getAllResponseSections)
  const tasks = useSelector(getAllResponseSectionFigmaTasks)
  const figmaFileVersions = useSelector(getAllFigmaFileVersions)
  const screenerQuestions = useSelector(getAllScreenerQuestions)

  const handleClearAllAnswerFilters = () => {
    setTestResultsFilters((filters) =>
      filters.filter(
        (filter) =>
          !filter.category.startsWith("answer") ||
          filter.category.startsWith("answer/screener")
      )
    )
  }

  const handleClearAllScreenerFilters = () => {
    setTestResultsFilters((filters) =>
      filters.filter((filter) => !filter.category.startsWith("answer/screener"))
    )
  }

  const answerFilters = testResultsFilters.filter(
    (filter) =>
      filter.category.startsWith("answer") &&
      !filter.category.startsWith("answer/screener")
  )
  const answerOptions = getOptionsFromFilter(
    answerFilters,
    {
      test,
      responseSections: responseSections as ResponseSection[],
      clientHitzones: viewState.screenshotClientHitzones,
      tasks: tasks as ResponseSectionFigmaTask[],
      figmaFileVersions: figmaFileVersions as TestResultsFigmaFileVersion[],
      screenerQuestions,
    },
    setTestResultsFilters,
    commonPathNamesBySectionId
  )

  const screenerFilters = testResultsFilters.filter((filter) =>
    filter.category.startsWith("answer/screener")
  )
  const screenerOptions = getOptionsFromFilter(
    screenerFilters,
    {
      test,
      responseSections: responseSections as ResponseSection[],
      clientHitzones: viewState.screenshotClientHitzones,
      tasks: tasks as ResponseSectionFigmaTask[],
      figmaFileVersions: figmaFileVersions as TestResultsFigmaFileVersion[],
      screenerQuestions,
    },
    setTestResultsFilters,
    commonPathNamesBySectionId
  )

  return (
    <>
      <ClearableOptions
        label="Screener"
        options={screenerOptions}
        onClear={handleClearAllScreenerFilters}
      />
      <ClearableOptions
        label="Answers"
        options={answerOptions}
        onClear={handleClearAllAnswerFilters}
      />
    </>
  )
}
