import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Grid,
  HStack,
  Heading,
  Progress,
  Select,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { PlayIcon } from "Icons/PlayIcon"
import { TranscriptIcon } from "Icons/TranscriptIcon"
import { interviewTranscriptRequestedGoogle } from "JavaScripts/analytics/google"
import { SupportMailtoLink } from "Shared/components/Links/SupportMailtoLink"
import { useChannel } from "Shared/hooks/useChannel"
import { ROUTES } from "UsabilityHub/views/routes"
import { padStart, partial, toPairs, zip } from "lodash"
import React, {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import LANGUAGES from "~/../../config/transcription_languages.json"
import {
  GetModeratedStudyBookingRecordingsResponse,
  useCreateModeratedStudyInterviewTranscript,
  useGetModeratedStudyInterviewTranscript,
  useGetModeratedStudyQuota,
  useUpdateModeratedStudyInterviewTranscript,
} from "~/api/generated/usabilityhub-components"
import { InterviewTranscript } from "~/api/generated/usabilityhubSchemas"
import { SpeakerName } from "./SpeakerName"
import { UploadTranscript } from "./UploadTranscript"

const LANGUAGE_OPTIONS = toPairs(LANGUAGES).map(([value, label]) => ({
  label,
  value,
}))

type WatcherParams = {
  recording_id: string
}

type WatcherMessage = {
  status: "complete" | "error"
}

type TranscriptProps = {
  recording: GetModeratedStudyBookingRecordingsResponse[number]
  time: number
  playing: boolean
  onSeek: (time: number) => void
}

export const Transcript: React.FC<TranscriptProps> = ({
  recording,
  time,
  playing,
  onSeek,
}) => {
  const toast = useToast()
  const container = useRef<HTMLDivElement>(null)
  const { data: quotaInfo } = useGetModeratedStudyQuota({})

  const [language, setLanguage] = useState<keyof typeof LANGUAGES>("auto")

  const { moderatedStudyId, sessionId } = useTypedParams(
    ROUTES.INTERVIEW.RECORDINGS.SESSION
  )

  const [transcript, setTranscript] = useState<InterviewTranscript | null>(null)

  const speakerNames = transcript?.speaker_names || []

  const { isLoading, data, error, refetch } =
    useGetModeratedStudyInterviewTranscript(
      {
        pathParams: {
          moderatedStudyId,
          moderatedStudyBookingId: sessionId || "",
          recordingId: recording.id,
        },
      },
      {
        retry: (count, error) => count < 3 && error?.status !== 404,
      }
    )

  const transcriptLoading = isLoading && !error

  useEffect(() => {
    if (transcriptLoading) return
    setTranscript(data || null)
  }, [data, transcriptLoading])

  const { isLoading: requested, mutate } =
    useCreateModeratedStudyInterviewTranscript({
      onSuccess: (data) => {
        setTranscript(data)
        interviewTranscriptRequestedGoogle()
      },
      onError: (error) => {
        toast({
          status: "error",
          title: "Error requesting transcription",
          description: error?.payload.message,
        })
      },
    })

  const requestTranscript = () => {
    if (!sessionId || !recording?.id) return

    mutate({
      pathParams: {
        moderatedStudyId,
        moderatedStudyBookingId: sessionId,
        recordingId: recording.id,
      },
      body: {
        language_code: language,
      },
    })
  }

  useChannel<WatcherParams, WatcherMessage>(
    "TranscriptionProcessingChannel",
    { recording_id: recording.id },
    {
      onMessage: (message) => {
        if (message.status === "complete" || message.status === "error")
          refetch()
      },
    }
  )

  const currentTurn = useMemo(() => {
    if (!playing || !transcript?.turns?.length) return

    let low = 0
    let high = transcript.turns.length - 1

    while (low < high) {
      const mid = Math.floor((low + high) / 2)
      const turn = transcript.turns[mid]

      if (turn.starts_at <= time && (!turn.ends_at || turn.ends_at > time)) {
        return turn
      } else if (turn.starts_at < time) {
        low = mid + 1
      } else {
        high = mid - 1
      }
    }

    return transcript.turns[low]
  }, [time, playing, transcript])

  useEffect(() => {
    if (!playing || !currentTurn || !container.current) return

    container.current
      .querySelector(`[data-timestamp="${currentTurn.starts_at}"]`)
      ?.scrollIntoView({ block: "start", behavior: "smooth" })
  }, [playing, currentTurn])

  const { mutateAsync: updateTranscript } =
    useUpdateModeratedStudyInterviewTranscript()

  const setSpeakerName = (index: number, name: string) => {
    if (!sessionId) return

    const newSpeakerNames = [...speakerNames]
    newSpeakerNames[index] = name
    setTranscript(
      (current) => current && { ...current, speaker_names: newSpeakerNames }
    )

    updateTranscript({
      pathParams: {
        moderatedStudyId,
        moderatedStudyBookingId: sessionId,
        recordingId: recording.id,
      },
      body: {
        speaker_names: newSpeakerNames,
      },
    })
  }

  const {
    isOpen: showUpload,
    onOpen: openUpload,
    onClose: closeUpload,
  } = useDisclosure()

  // While the quota query is loading, disable the button.
  // After loading, enable the button as long as we have enough quota to transcribe this video.
  const [notEnoughQuotaVisible, setNotEnoughQuotaVisible] = useState(false)

  const durationInHours = (recording.duration ?? 0) / 60 / 60
  const noTranscriptionQuotaLeft =
    quotaInfo &&
    quotaInfo.transcription_hours.used >= quotaInfo.transcription_hours.quota
  const isAllowedToTranscribe =
    quotaInfo &&
    quotaInfo.transcription_hours.quota - quotaInfo.transcription_hours.used >=
      durationInHours

  return (
    <Stack ref={container} mx={-4} px={4} overflowY="auto">
      {notEnoughQuotaVisible && <NotEnoughQuotaAlert />}
      {transcript && !transcript.error_message ? (
        transcript.status === "complete" ? (
          transcript.turns?.map((turn) => (
            <Stack
              key={turn.starts_at}
              py={2}
              spacing={2}
              data-timestamp={turn.starts_at}
            >
              <HStack>
                <SpeakerName
                  name={
                    speakerNames[turn.speaker] || `Speaker ${turn.speaker + 1}`
                  }
                  onChange={partial(setSpeakerName, turn.speaker)}
                />
                <Button
                  variant="ghost"
                  leftIcon={<PlayIcon />}
                  size="sm"
                  color="text.secondary"
                  onClick={() => onSeek(turn.starts_at)}
                >
                  {formatTimestamp(turn.starts_at)}
                </Button>
              </HStack>
              <Box
                lang={
                  transcript.language_code === "auto"
                    ? undefined
                    : transcript.language_code
                }
                dir="auto"
              >
                {formatUtterance(turn.text)}
              </Box>
            </Stack>
          ))
        ) : (
          <TranscriptInfo heading="Transcription in progress">
            <Text>We will email you when this process is completed.</Text>
            <Progress
              isIndeterminate
              colorScheme="brand.primary"
              rounded="sm"
            />
          </TranscriptInfo>
        )
      ) : (
        !transcriptLoading && (
          <>
            {transcript?.error_message && (
              <Alert status="error">
                <AlertTitle>
                  We encountered an error transcribing this recording
                </AlertTitle>
                <AlertDescription>
                  Please try again or{" "}
                  <SupportMailtoLink>
                    get in touch with support
                  </SupportMailtoLink>
                </AlertDescription>
              </Alert>
            )}
            <TranscriptInfo heading="Transcribe this recording">
              <Text>Request an AI-generated transcript of this recording.</Text>
              <HStack justifyContent="space-between">
                <ButtonGroup>
                  <Tooltip
                    hasArrow
                    rounded="md"
                    placement="bottom"
                    label="You've reached your monthly transcription limit"
                    isDisabled={!noTranscriptionQuotaLeft}
                  >
                    <Button
                      variant="solid"
                      colorScheme="brand.primary"
                      size="sm"
                      isDisabled={noTranscriptionQuotaLeft}
                      isLoading={requested}
                      loadingText="Please wait…"
                      onClick={
                        isAllowedToTranscribe
                          ? requestTranscript
                          : () => setNotEnoughQuotaVisible(true)
                      }
                    >
                      Begin transcription
                    </Button>
                  </Tooltip>
                  <Button
                    variant="ghost"
                    size="sm"
                    colorScheme="brand.primary"
                    onClick={openUpload}
                  >
                    Upload transcript
                  </Button>
                  <UploadTranscript
                    moderatedStudyId={moderatedStudyId}
                    sessionId={sessionId || ""}
                    recordingId={recording.id}
                    isOpen={showUpload}
                    onClose={closeUpload}
                    onSuccess={() => refetch()}
                  />
                </ButtonGroup>
                <Select
                  aria-label="Transcription language"
                  w="auto"
                  size="sm"
                  rounded="md"
                  value={language}
                  onChange={(e) =>
                    setLanguage(e.currentTarget.value as keyof typeof LANGUAGES)
                  }
                >
                  {LANGUAGE_OPTIONS.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </HStack>
            </TranscriptInfo>
          </>
        )
      )}
    </Stack>
  )
}

const formatTimestamp = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000) % 60
  const minutes = Math.floor(milliseconds / 1000 / 60) % 60
  const hours = Math.floor(milliseconds / 1000 / 60 / 60)

  return `${hours ? `${hours}:` : ""}${padStart(
    String(minutes),
    2,
    "0"
  )}:${padStart(String(seconds), 2, "0")}`
}

const formatUtterance = (text: string) => {
  const matches = Array.from(text.matchAll(/<([^>]+)>\??/g)).map((match) => ({
    start: match.index || 0,
    end: (match.index || 0) + match[0].length,
    token: match[1],
  }))
  if (!matches.length) return text
  return zip([null, ...matches], matches).map(([prev, match], i) => (
    <Fragment key={i}>
      {prev
        ? text.substring(prev.end || 0, match?.start || text.length)
        : match
          ? text.substring(0, match?.start)
          : undefined}
      {match && <Badge variant="outline">{match.token}</Badge>}
    </Fragment>
  ))
}

const TranscriptInfo: React.FC<PropsWithChildren<{ heading: string }>> = ({
  heading,
  children,
}) => (
  <Grid templateColumns="auto 1fr" bg="gray.50" rounded="md" p={4} gap={4}>
    <TranscriptIcon boxSize="1.5rem" />
    <Stack spacing={3}>
      <Heading as="h4" size="sm" fontWeight="medium">
        {heading}
      </Heading>
      {children}
    </Stack>
  </Grid>
)

const NotEnoughQuotaAlert: React.FC = () => {
  return (
    <Alert status="error" mb={3}>
      <AlertTitle>Unable to transcribe this recording</AlertTitle>
      <AlertDescription>
        Video length exceeds your remaining transcription hours quota. Check
        your quota
      </AlertDescription>
    </Alert>
  )
}
