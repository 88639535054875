import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Link,
  ListProps,
  OrderedList,
  Stack,
  StackProps,
  StylesProvider,
  UnorderedList,
  useMultiStyleConfig,
  useStyles,
} from "@chakra-ui/react"
import { Button, Heading } from "DesignSystem/components"
import { DownloadIcon } from "Icons/DownloadIcon"
import React from "react"

const styleConfig = {
  parts: [
    "container",
    "heading",
    "downloadLink",
    "intro",
    "sectionLink",
    "list",
  ],
  baseStyle: {
    downloadLink: {
      ml: 4,
    },
    intro: {
      fontSize: "lg",
    },
    sectionLink: {
      ml: 2,
      textDecoration: "none",
    },
    list: {
      pl: 4,
    },
  },
}

type Document = React.FC<
  React.PropsWithChildren<
    {
      title: string
      downloadUrl?: string
    } & BoxProps
  >
>

export const Document: Document = ({
  title,
  downloadUrl,
  children,
  ...props
}) => {
  const styles = useMultiStyleConfig("Document", { styleConfig })
  return (
    <StylesProvider value={styles}>
      <Box as="article" sx={styles.container} {...props}>
        <Flex align="baseline" justify="space-between" mb={8}>
          <Heading as="h1" textStyle="ds.display.emphasized">
            {title}
          </Heading>
          {downloadUrl && (
            <Button
              as="a"
              size="emphasized"
              sx={styles.downloadLink}
              download
              href={downloadUrl}
              leftIcon={<Icon as={DownloadIcon} boxSize={3.5} />}
            >
              Download PDF
            </Button>
          )}
        </Flex>
        <Stack spacing={6}>{children}</Stack>
      </Box>
    </StylesProvider>
  )
}

type DocumentIntro = React.FC<React.PropsWithChildren<StackProps>>

export const DocumentIntro: DocumentIntro = (props) => {
  const styles = useStyles()
  return <Stack spacing={3} sx={styles.intro} {...props} />
}

type DocumentSection = React.FC<
  React.PropsWithChildren<
    {
      title: string
    } & StackProps
  >
>

export const DocumentSection: DocumentSection = ({
  title,
  children,
  ...props
}) => {
  const styles = useStyles()
  const id = props.id ? props.id : title.replace(/\s+/g, "-").toLowerCase()
  return (
    <Stack as="section" sx={styles.section} id={id} {...props}>
      <Flex align="baseline">
        <Heading as="h2" textStyle="ds.display.primary">
          {title}
        </Heading>
        <Link href={`#${id}`} sx={styles.sectionLink}>
          #
        </Link>
      </Flex>
      {children}
    </Stack>
  )
}

// The preferred place to apply this would be on the theme level,
// unfortunately chakra does not yet support styling lists via the theme
type DocumentList = React.FC<React.PropsWithChildren<StackProps & ListProps>>

export const DocumentList: DocumentList = (props) => {
  const styles = useStyles()
  return <Stack as={UnorderedList} spacing={1} sx={styles.list} {...props} />
}

// The preferred place to apply this would be on the theme level,
// unfortunately chakra does not yet support styling lists via the theme
type OrderedDocumentList = React.FC<
  React.PropsWithChildren<StackProps & ListProps>
>

export const OrderedDocumentList: DocumentList = (props) => {
  const styles = useStyles()
  return <Stack as={OrderedList} spacing={1} sx={styles.list} {...props} />
}
