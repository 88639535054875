import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { CloseIcon } from "Icons/CloseIcon"
import { DownloadIcon } from "Icons/DownloadIcon"
import { ImageIcon } from "Icons/ImageIcon"
import { MaximizeIcon } from "Icons/MaximizeIcon"
import React from "react"

type ToolbarProps = {
  fullScreen: boolean
  setFullScreen: (fullScreen: boolean) => void
  onDownload: (format: "svg" | "png") => void
}

export const Toolbar: React.FC<ToolbarProps> = ({
  fullScreen,
  setFullScreen,
  onDownload,
}: ToolbarProps) => {
  return (
    <HStack>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="ghost"
          icon={<DownloadIcon />}
          aria-label="Download"
        />
        <MenuList py={2}>
          <MenuItem icon={<ImageIcon />} onClick={() => onDownload("svg")}>
            Download as SVG
          </MenuItem>
          <MenuItem icon={<ImageIcon />} onClick={() => onDownload("png")}>
            Download as PNG
          </MenuItem>
        </MenuList>
      </Menu>
      <IconButton
        variant="ghost"
        icon={fullScreen ? <CloseIcon /> : <MaximizeIcon />}
        aria-label={`${fullScreen ? "Exit" : "Enter"} full screen`}
        onClick={() => setFullScreen(!fullScreen)}
      />
    </HStack>
  )
}
