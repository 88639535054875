import {
  Button as ChakraButton,
  Flex,
  Icon,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { Alert, Button, Heading } from "DesignSystem/components"
import { SendIcon } from "Icons/SendIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import {
  SendModeratedStudyPreviewEmailPathParams,
  useSendModeratedStudyPreviewEmail,
} from "~/api/generated/usabilityhub-components"
import { useStudyDetails } from "~/usabilityhub/views/ModeratedStudy/interviewer/EditPage/StudyDetailsProvider"
import { useModeratedStudyContext } from "~/usabilityhub/views/ModeratedStudy/interviewer/ModeratedStudyContext"

type EmailType = SendModeratedStudyPreviewEmailPathParams["email"]

const PREVIEWABLE_EMAILS = [
  {
    name: "Invitation",
    oldDescription:
      "Participants receive this email when you invite them to book a time for a session.",
    description:
      "Applicants receive this email when you invite them to the book a time.",
    email: "invited",
  },
  {
    name: "Confirmation",
    oldDescription:
      "Participants receive this email when they successfully book themselves for a session.",
    description:
      "Participants receive this email after they successfully book themselves for a session.",
    email: "booking_confirmation",
  },
  {
    name: "Reminder",
    oldDescription:
      "Participants receive this email 24 hours and 15 minutes before the session.",
    description:
      "Participants receive this email 24 hours and 15 minutes before the session.",
    email: "reminder_15m",
  },
  {
    name: "Rescheduled",
    oldDescription:
      "Participants receive this email when a session is rescheduled.",
    description:
      "Participants receive this email when you have rescheduled their session.",
    email: "rescheduled",
  },
  {
    name: "Canceled",
    oldDescription:
      "Participants receive this email when when a session is canceled.",
    description:
      "Participants receive this email when their session is cancelled.",
    email: "canceled_by_researcher",
  },
] as const

export const EmailPreviewSection: React.FC = () => {
  const toast = useToast()
  const { moderatedStudy } = useModeratedStudyContext()
  const { isLoading, separatePages: newUi } = useStudyDetails()
  const currentUser = useCurrentUser()

  const { mutate: sendEmailMutation } = useSendModeratedStudyPreviewEmail({
    onSuccess: (_, { pathParams: { email } }) => {
      const name =
        PREVIEWABLE_EMAILS.find((e) => e.email === email)?.name ?? "Email"
      newUi
        ? toast({
            title: `${name} preview sent`,
            description: (
              <>
                An email has been sent to <b>{currentUser.email}</b>
              </>
            ),
            status: "success",
          })
        : toast({
            title: "Email sent",
            description: "A preview has been sent to your email address.",
            status: "success",
          })
    },
    onError: () => {
      toast({
        title: "Error sending email",
        description:
          "There was a problem sending the email. Contact support if this persists.",
        status: "error",
      })
    },
  })

  const triggerEmail = (email: EmailType) => {
    sendEmailMutation({
      pathParams: {
        moderatedStudyId: moderatedStudy.id,
        email,
      },
    })
  }

  return isLoading ? (
    <Spinner size="xl" color="teal" />
  ) : newUi ? (
    <Flex direction="column" gap={6}>
      <Heading as="h3" textStyle="ds.display.primary">
        Automated emails
      </Heading>
      <Text textStyle="ds.paragraph.primary">
        These emails are automatically sent to participants when a session is
        booked, modified or coming up. You can send a preview to see exactly
        what your participants will receive.
      </Text>
      <Alert
        variant="default"
        status="info"
        description={
          <>
            Previews will be sent to your email <b>{currentUser.email}</b>
          </>
        }
      />

      {PREVIEWABLE_EMAILS.map(({ name, email, description }) => {
        return (
          <Flex key={email}>
            <Flex flexGrow={1} direction="column" gap={0.5}>
              <Text textStyle="ds.heading.primary">{name}</Text>
              <Text textStyle="ds.paragraph.primary">{description}</Text>
            </Flex>

            <Button
              variant="default"
              leftIcon={<Icon as={SendIcon} />}
              onClick={() => triggerEmail(email)}
              flexShrink={0}
            >
              Send preview
            </Button>
          </Flex>
        )
      })}
    </Flex>
  ) : (
    <OldEmailPreviewSection triggerEmail={triggerEmail} />
  )
}

const OldEmailPreviewSection: React.FC<{
  triggerEmail: (email: EmailType) => void
}> = ({ triggerEmail }) => {
  return (
    <Flex direction="column" gap={6}>
      <Heading as="h3" textStyle="ds.display.primary">
        Automated emails
      </Heading>

      {PREVIEWABLE_EMAILS.map(({ name, email, oldDescription }) => {
        return (
          <Flex key={email}>
            <Flex flexGrow={1} direction="column" gap={0.5}>
              <Text color="text.primary" fontWeight="medium">
                {name}
              </Text>
              <Text color="text.secondary" fontSize="sm" fontWeight="normal">
                {oldDescription}
              </Text>
            </Flex>

            <ChakraButton
              variant="outline"
              size="sm"
              leftIcon={<Icon as={SendIcon} />}
              flexShrink={0}
              onClick={() => triggerEmail(email)}
              ml={2}
            >
              Send preview
            </ChakraButton>
          </Flex>
        )
      })}
    </Flex>
  )
}
