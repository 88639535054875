import { sortBy } from "lodash"

import { FilterAttribute, FilterOptionChoice } from "Types"
import {
  AFTER,
  BEFORE,
  CONTAINS,
  DOES_NOT_CONTAIN,
  IS,
  IS_NOT,
} from "UsabilityHub/components/FilterControls/comparators"
import {
  ModeratedStudyOrder,
  ModeratedStudyRecruitmentLink,
} from "~/api/generated/usabilityhubSchemas"

const DATE_IN_THE_PAST = [
  { label: "1 week ago", value: "7" },
  { label: "2 weeks ago", value: "14" },
  { label: "3 weeks ago", value: "21" },
  { label: "1 month ago", value: "30" },
  { label: "2 months ago", value: "60" },
  { label: "3 months ago", value: "90" },
  { label: "6 months ago", value: "180" },
  { label: "1 year ago", value: "365" },
]

const STATIC_APPLICATION_ATTRIBUTES: FilterAttribute[] = [
  {
    label: "Application date",
    value: "created_at",
    possibleComparators: [BEFORE, AFTER],
    possibleOptions: {
      type: "single",
      choices: DATE_IN_THE_PAST,
    },
  },
  {
    label: "Status",
    value: "status",
    possibleComparators: [IS, IS_NOT],
    possibleOptions: {
      type: "multi",
      pluralEntity: "states",
      choices: [
        { label: "Complete", value: "complete" },
        { label: "Canceled", value: "canceled" },
        { label: "Booked", value: "booked" },
        { label: "Invited", value: "invited" },
        { label: "No-show", value: "no_show" },
        { label: "Reported", value: "reported" },
        { label: "Applied", value: "applied" },
      ],
    },
  },
  {
    label: "Qualified",
    value: "eligible",
    possibleComparators: [IS, IS_NOT],
    possibleOptions: { type: "static" },
  },
  {
    label: "Name",
    value: "name",
    possibleComparators: [CONTAINS, DOES_NOT_CONTAIN],
    possibleOptions: { type: "freeText" },
  },
]

type LocationChoice = FilterOptionChoice

export const generateApplicationsFilters = (
  orders: ModeratedStudyOrder[],
  recruitmentLinks: ModeratedStudyRecruitmentLink[],
  locationChoices: LocationChoice[]
): FilterAttribute[] => {
  const sources = recruitmentLinks
    .map((link) => ({
      label: link.name,
      value: link.id,
    }))
    .concat(
      orders.map((order) => ({
        label: order.name,
        value: order.id,
      }))
    )

  const sortedSources = sortBy(sources, "name")

  const sourcesFilter: FilterAttribute = {
    label: "Source",
    value: "source",
    possibleComparators: [IS, IS_NOT],
    possibleOptions: {
      type: "multi",
      pluralEntity: "sources",
      choices: sortedSources,
    },
  }

  const locationFilter: FilterAttribute = {
    label: "Location",
    value: "location",
    possibleComparators: [IS, IS_NOT],
    possibleOptions: {
      type: "multi",
      pluralEntity: "locations",
      choices: locationChoices ?? [],
    },
  }

  return locationChoices
    ? [...STATIC_APPLICATION_ATTRIBUTES, sourcesFilter, locationFilter]
    : [...STATIC_APPLICATION_ATTRIBUTES, sourcesFilter]
}
