import {
  Tab as ChakraTab,
  TabProps as ChakraTabProps,
  MenuItem,
  Tooltip,
} from "@chakra-ui/react"
import React, { useContext } from "react"
import { LinkProps } from "react-router-dom"
import { CollapsibleTabListContext } from "./Context"

type TabProps = ChakraTabProps & {
  isDisabled?: boolean
  to?: LinkProps["to"]
}

export const Tab = ({ isDisabled, ...props }: TabProps) => {
  if (isDisabled) {
    return (
      <Tooltip
        label="This feature is not available on your account"
        hasArrow
        rounded="sm"
      >
        <TabInner isDisabled {...props} />
      </Tooltip>
    )
  }

  return <TabInner {...props} />
}

Tab.displayName = "CollapsibleTabList.Tab"

const TabInner = ({ children, ...props }: TabProps) => {
  const { display } = useContext(CollapsibleTabListContext)

  if (display === "menu") {
    return <MenuItem {...props}>{children}</MenuItem>
  }

  return <ChakraTab {...props}>{children}</ChakraTab>
}
