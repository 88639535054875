import React from "react"

import { getEnvState } from "JavaScripts/state"
import { REAL_FIGMA_DOMAIN } from "UsabilityHub/components/UsabilityTest/figma-file-versions/getFigmaFlowPrototype"

interface FigmaEmbedProps {
  prototypeUrl: string
}

export const FigmaEmbed: React.FC<React.PropsWithChildren<FigmaEmbedProps>> = ({
  prototypeUrl,
}) => {
  let embedUrl = prototypeUrl

  // `prototypeUrl` is null in testing - Redux is hard to mock :(
  if (prototypeUrl) {
    // https://www.figma.com/developers/embed
    const [url, search] = prototypeUrl.split("?")
    const searchParams = new URLSearchParams(search)
    searchParams.set("embed-host", "usabilityhub")
    searchParams.set("client-id", getEnvState().FIGMA_CLIENT_ID)
    // REAL_FIGMA_DOMAIN instead of getEnvState().FIGMA_DOMAIN because
    // FIGMA_DOMAIN may have been mocked but the source of prototypeUrl won't be
    embedUrl = `${url.replace(REAL_FIGMA_DOMAIN, getEnvState().FIGMA_EMBED_DOMAIN)}?${searchParams.toString()}`
  }

  return (
    <iframe
      title="Figma content"
      width="100%"
      height="100%"
      id="iframe"
      data-testid="figma-iframe-embed"
      src={embedUrl}
      allowFullScreen
    />
  )
}
