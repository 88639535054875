import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useQueryClient } from "@tanstack/react-query"
import AsyncStripeProvider from "Components/async-stripe-provider/async-stripe-provider"
import { CreditCardDeclinedMessage } from "Components/credit-card-declined-message"
import { CreditCardFields } from "Components/credit-card-fields/credit-card-fields"
import { CardDetails } from "Components/payments/card-details"
import { Button, Heading, Input } from "DesignSystem/components"
import autoTopUpImage from "Images/app-illustrations/lyssna/auto-top-up.png"
import { CreditCardDeclinedError } from "Services/stripe"
import { DisplayModal } from "Shared/components/DisplayModal/DisplayModal"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useStripeCards } from "UsabilityHub/hooks/useStripeCards"
import { FunctionalModal } from "Utilities/modals/types"
import React, { ReactNode, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"
import {
  useGetAutomaticPurchase,
  useSetAutomaticPurchase,
} from "~/api/generated/usabilityhub-components"

const AutoTopUpSchema = Yup.object({
  threshold: Yup.number()
    .integer("Threshold must be an integer")
    .min(1, "Threshold is too low")
    .required("Threshold is required")
    .nullable()
    .typeError("Threshold must be an integer"),
  amount: Yup.number()
    .integer("Amount must be an integer")
    .min(1, "Amount is too low")
    .required("Amount is required")
    .nullable()
    .typeError("Amount must be an integer"),
})

type AutoTopUpType = Yup.InferType<typeof AutoTopUpSchema>

export const AutoTopUpEditModal: FunctionalModal = ({ isOpen, onClose }) => {
  return (
    <AsyncStripeProvider>
      <AutoTopUpEditModalImpl isOpen={isOpen} onClose={onClose} />
    </AsyncStripeProvider>
  )
}

const AutoTopUpEditModalImpl: FunctionalModal = ({ isOpen, onClose }) => {
  const toast = useToast()

  const stripe = useStripe()
  const elements = useElements()
  const { replaceCustomerCard } = useStripeCards()
  const cardLast4 = useCurrentAccount().stripe_card_last4
  const [isCreditCardFormVisible, setIsCreditCardFormVisible] = useState(
    !cardLast4
  )
  const [cardName, setCardName] = useState("")
  const showCreditCardForm = () => {
    if (stripe !== null) {
      setIsCreditCardFormVisible(true)
    } else {
      toast({
        status: "error",
        title: `We couldn${"\u2019"}t load the payment service. Please refresh the page and try again.`,
      })
    }
  }

  const queryClient = useQueryClient()
  const { data, isLoading } = useGetAutomaticPurchase({})
  const existingAutoPayment = !!data?.automatic_purchase
  const { mutate: setAutomaticPurchase } = useSetAutomaticPurchase({
    onSuccess: () => {
      if (existingAutoPayment) {
        toast({
          title: "Auto top-up updated",
          description: "Changes saved.",
          status: "success",
        })
      } else {
        toast({
          title: "Auto top-up enabled",
          description: `We${"\u2019"}ll automatically purchase credits when your balance falls below your set amount.`,
          status: "success",
        })
      }

      return queryClient.invalidateQueries([
        "api",
        "credits",
        "automatic_purchase",
      ])
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.payload.message,
        status: "error",
      })
    },
  })

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
  } = useForm<AutoTopUpType>({
    resolver: yupResolver(AutoTopUpSchema),
    defaultValues: {
      threshold: data?.automatic_purchase?.threshold ?? null,
      amount: data?.automatic_purchase?.amount ?? null,
    },
  })

  const onSubmit: SubmitHandler<AutoTopUpType> = async (values) => {
    if (isCreditCardFormVisible) {
      try {
        const cardElement = elements ? elements.getElement(CardElement) : null
        await replaceCustomerCard(cardElement, cardName, stripe)
      } catch (error) {
        const errorMessage: ReactNode =
          error instanceof CreditCardDeclinedError ? (
            <CreditCardDeclinedMessage />
          ) : (
            (error.message as string)
          )
        toast({
          status: "error",
          title: errorMessage,
        })

        return
      }
    }

    if (!values || !values.amount || !values.threshold) return

    if (data?.automatic_purchase) {
      setAutomaticPurchase({
        body: {
          current_purchase_id: data.automatic_purchase.id,
          threshold: values.threshold,
          amount: values.amount,
        },
      })
    } else {
      setAutomaticPurchase({
        body: {
          threshold: values.threshold,
          amount: values.amount,
        },
      })
    }

    onClose()
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <DisplayModal isCentered isOpen={isOpen} onClose={onClose}>
      <DisplayModal.TwoPaneContent
        content={
          <Box
            height="full"
            bgColor="ds.background.information.subtle.resting"
            justifyItems="center"
            alignContent="center"
            borderRadius={16}
          >
            <Image src={autoTopUpImage} alt="Auto top-up" width="286px" />
          </Box>
        }
        controls={
          <>
            <Button
              variant="secondary"
              isDisabled={isSubmitting}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {existingAutoPayment ? "Save changes" : "Confirm and activate"}
            </Button>
          </>
        }
      >
        <Stack
          width="100%"
          spacing={4}
          // alignItems="flex-start"
          maxH="377px"
          overflowY="auto"
          overflowX="hidden"
          // paddingRight={3}
        >
          <Heading as="h1" textStyle="ds.display.primary">
            {existingAutoPayment ? "Edit auto top-up" : "Set up auto top-up"}
          </Heading>
          {!existingAutoPayment && (
            <Text textStyle="ds.body.primary">
              Automatically purchase credits when your balance falls below a set
              amount.
            </Text>
          )}
          <FormControl isInvalid={!!errors.amount}>
            <FormLabel
              textStyle="ds.heading.secondary"
              fontSize="sm"
              fontWeight={500}
            >
              Add this amount
            </FormLabel>
            <Input
              placeholder="0 credits"
              type="number"
              {...register("amount")}
            />
            <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.threshold}>
            <FormLabel
              textStyle="ds.heading.secondary"
              fontSize="sm"
              fontWeight={500}
            >
              When my balance drops below
            </FormLabel>
            <Input
              placeholder="0 credits"
              type="number"
              {...register("threshold")}
            />
            <FormErrorMessage>{errors.threshold?.message}</FormErrorMessage>
          </FormControl>

          {isCreditCardFormVisible ? (
            <Stack
              width="100%"
              borderRadius={12}
              p={6}
              bgColor="ds.background.neutral.resting"
              fontSize="sm"
            >
              <Text textStyle="ds.paragraph.primary">
                This card will be saved to your account.
              </Text>
              <CreditCardFields name={cardName} onNameChange={setCardName} />
            </Stack>
          ) : (
            <Stack
              width="100%"
              borderRadius={12}
              p={6}
              textAlign="center"
              bgColor="ds.background.neutral.resting"
              fontSize="sm"
            >
              <CardDetails cardLast4={cardLast4} />
              <Button
                variant="link"
                color="ds.link.default"
                size="flush"
                onClick={showCreditCardForm}
              >
                Change billing details
              </Button>
            </Stack>
          )}
        </Stack>
      </DisplayModal.TwoPaneContent>
    </DisplayModal>
  )
}
