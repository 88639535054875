import { SystemStyleObject } from "@chakra-ui/react"

/**
 * Utility function to make container queries more readable in Chakra.
 *
 * - You will need to specify a container for this to work (as in CSS). The
 * `cqContainer()` utility can do this (see below) although you can do it
 * manually too.
 * - Container queries must be put in the `sx` prop, where they apply to the
 * current element (`&`).
 * - It assumes you want `min-width` for the size, since it's the Chakra default.
 * - Put your smallest styles as props on the Chakra component, and then use
 * this to apply rules for larger container sizes.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/CSS/container-type
 * @link https://developer.mozilla.org/en-US/docs/Web/CSS/container-name
 *
 * @example
 * // first, set a parent element as the container
 * <Box sx={cqContainer()} />
 *
 * // single query on a child component
 * <Flex
 *   p={4}
 *   flexDir="column"
 *   // at 32rem and larger, apply these styles
 *   sx={cq("32rem", { p: 4, flexDir: "row" })}
 * />
 *
 * // multiple queries
 * <Flex
 *   p={4}
 *   flexDir="column"
 *   sx={{
 *     ...cq("32rem", { p: 4, flexDir: "row" }),
 *     ...cq("48rem", { p: 8 })
 *     ...cq("72rem", { p: 12 })
 *   }}
 * />
 */
export const cq = (
  minWidth: string,
  rules: SystemStyleObject,
  containerName: SystemStyleObject["containerName"] = ""
) => {
  return {
    [`@container ${containerName} (min-width: ${minWidth})`]: {
      "&": rules,
    },
  }
}

/**
 * Utility function for creating a container for container queries.
 * Used in tandem with the `cq` function on children.
 */
export const cqContainer = (
  type: SystemStyleObject["containerType"] = "inline-size",
  name: SystemStyleObject["containerName"] = ""
) => {
  const containerName = name ? { containerName: name } : {}
  return {
    containerType: type,
    ...containerName,
  }
}
