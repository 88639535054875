import { Box, BoxProps } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import React, { useLayoutEffect, useMemo, useRef } from "react"
import { OnboardingAnimationProps } from "../constants"
import scribble1 from "./scribble-1.png"
import scribble2 from "./scribble-2.png"
import scribble3 from "./scribble-3.png"
import scribble4 from "./scribble-4.png"

const ROLE_COLORS: Record<string, string> = {
  Designer: "red",
  Researcher: "yellow",
  Marketer: "cyan",
  "Product manager": "purple",
  "Founder / CEO": "pink",
  Student: "teal",
} as const

const TAGS: Record<string, string> = {
  Designer:
    "M179.778 0C172.098 0.00078427 164.734 2.78908 159.304 7.75158C153.874 12.7141 150.823 19.4443 150.823 26.462C150.823 22.987 150.074 19.5459 148.619 16.3354C147.164 13.1249 145.031 10.2078 142.342 7.75053C139.653 5.29331 136.461 3.34415 132.948 2.01431C129.434 0.684466 125.669 0 121.866 0C114.187 0.00078427 106.822 2.78908 101.392 7.75158C95.9622 12.7141 92.9117 19.4443 92.9117 26.462C92.9117 22.987 92.1627 19.5459 90.7075 16.3354C89.2522 13.1249 87.1192 10.2078 84.4303 7.75053C81.7413 5.29331 78.5491 3.34415 75.0358 2.01431C71.5225 0.684466 67.757 0 63.9543 0C56.2748 0.00078427 48.9102 2.78908 43.4803 7.75158C38.0504 12.7141 35 19.4443 35 26.462V72.2681C35 79.2857 38.0504 86.016 43.4803 90.9785C48.9102 95.941 56.2748 98.7293 63.9543 98.73C71.6343 98.73 78.9997 95.9421 84.4303 90.9795C89.8609 86.0169 92.9117 79.2862 92.9117 72.2681C92.9117 79.2857 95.9622 86.016 101.392 90.9785C106.822 95.941 114.187 98.7293 121.866 98.73C129.546 98.73 136.911 95.9421 142.342 90.9795C147.773 86.0169 150.823 79.2862 150.823 72.2681C150.823 79.2857 153.874 86.016 159.304 90.9785C164.734 95.941 172.098 98.7293 179.778 98.73C187.458 98.73 194.823 95.9421 200.254 90.9795C205.684 86.0169 208.735 79.2862 208.735 72.2681V26.462C208.735 22.987 207.986 19.5459 206.531 16.3354C205.076 13.1249 202.943 10.2078 200.254 7.75053C197.565 5.29331 194.373 3.34415 190.859 2.01431C187.346 0.684466 183.58 0 179.778 0Z",
  Researcher:
    "M0 50.0502C0 31.7971 12.1365 18.7627 32.8832 18.1017L211.308 17C229.469 17 242.328 29.5591 243.752 47.4062C243.752 65.6593 229.469 82.3292 211.308 82.3292C187.827 82.7295 55.4799 82.585 32.8832 82.88C16.7357 83.0908 0 68.3033 0 50.0502Z",
  Marketer:
    "M222.633 89.0495L193.827 74.1536L165.02 89.0495L136.217 74.1536L107.413 89.0495L78.61 74.1536L49.8034 89.0495L21 74.1536L21 10L49.8034 24.8958L78.61 10L107.413 24.8958L136.217 10L165.02 24.8958L193.827 10L222.633 24.8958V89.0495Z",
  "Product manager":
    "M228.528 26.6769C228.528 20.1322 225.772 13.8555 220.867 9.22765C215.961 4.59984 209.308 2 202.37 2L41.1576 2C34.2201 2 27.5668 4.59984 22.6613 9.22765C17.7558 13.8555 15 20.1322 15 26.6769C15.0002 31.4347 16.4584 36.091 19.1989 40.0851C21.9395 44.0793 25.8456 47.2409 30.4468 49.1891C25.8456 51.1373 21.9395 54.2989 19.1989 58.293C16.4584 62.2872 15.0002 66.9435 15 71.7013C15 78.246 17.7558 84.5227 22.6613 89.1505C27.5668 93.7784 34.2201 96.3782 41.1576 96.3782H202.37C209.308 96.3782 215.961 93.7784 220.867 89.1505C225.772 84.5227 228.528 78.246 228.528 71.7013C228.528 66.9435 227.07 62.2872 224.329 58.293C221.589 54.2989 217.682 51.1373 213.081 49.1891C217.682 47.2409 221.589 44.0793 224.329 40.0851C227.07 36.091 228.528 31.4347 228.528 26.6769Z",
  "Founder / CEO":
    "M17 9.7019L19.5514 91.3929H204.278L227.354 48.8339L200.978 8L17 9.7019Z",
  Student:
    "M220.101 84.974L121.553 81.351L23 84.974L32.2505 49.987L23 15L121.553 18.623L220.101 15L210.856 50.0307L220.101 84.974Z",
} as const

export const RoleAnimation: React.FC<OnboardingAnimationProps<"role">> = ({
  values,
}) => {
  const { role, preferredName, customRoleTitle, roleTitle } = values

  const definedRole = role && role in ROLE_COLORS

  const { background, light, hello } = useMemo(() => {
    if (definedRole) {
      return {
        background: `brandPalette.${ROLE_COLORS[role]}.mid`,
        hello:
          role === "Researcher"
            ? "white"
            : `brandPalette.${ROLE_COLORS[role]}.light`,
        light: `brandPalette.${ROLE_COLORS[role]}.light`,
      }
    }

    return {
      background: `brandPalette.neutral.dark`,
      hello: `brandPalette.neutral.light`,
      light: `brandPalette.neutral.mid`,
    }
  }, [role, definedRole])

  return (
    <Box pos="relative">
      <Box
        as={motion.div}
        pos="absolute"
        inset={0}
        display="grid"
        placeContent="center"
        alignItems="center"
      >
        <Box
          as={motion.img}
          src={scribble1}
          role="presentation"
          gridArea="1/1"
          animate={
            definedRole
              ? { rotate: "-115deg", x: 0, y: 0, opacity: 0, scale: 0 }
              : { rotate: "-115deg", x: -160, y: -165, opacity: 1, scale: 1 }
          }
        />
        <Box
          as={motion.img}
          src={scribble2}
          role="presentation"
          gridArea="1/1"
          animate={
            definedRole
              ? { rotate: "-115deg", x: 0, y: 0, opacity: 0, scale: 0 }
              : { rotate: "125deg", x: 165, y: 140, opacity: 1, scale: 1 }
          }
        />
        <Box
          as={motion.img}
          src={scribble3}
          role="presentation"
          gridArea="1/1"
          animate={
            definedRole
              ? customRoleTitle && roleTitle
                ? { rotate: "-54deg", x: -195, y: 70, opacity: 1, scale: 1 }
                : { rotate: "-109deg", x: -165, y: 130, opacity: 1, scale: 1 }
              : { rotate: "-109deg", x: 0, y: 0, opacity: 0, scale: 0 }
          }
        />
        <Box
          as={motion.img}
          src={scribble4}
          role="presentation"
          gridArea="1/1"
          animate={
            definedRole
              ? { rotate: "-50deg", x: 180, y: -155 }
              : { rotate: "-50deg", x: 0, y: 0, opacity: 0, scale: 0 }
          }
        />
      </Box>
      <Box
        as={motion.div}
        key="label"
        pos="relative"
        animate={{ rotate: definedRole ? "-5deg" : "5deg" }}
      >
        <svg width="376" height="283" viewBox="-10 -10 376 283">
          <title>Name tag</title>
          {/* outer box */}
          <g filter="drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05))">
            <Box
              as="path"
              d="M298.645 7.45554C221.546 5.29388 102.824 2.80177 39.411 2.75871C13.1521 2.74087 12.293 8.74327 13.1385 22.6322C15.8567 67.2821 14.0737 127.7 13.0013 224.861C12.9963 232.246 15.7844 240.104 37.5741 239.516C129.13 240.665 181.718 240.217 317.431 240.309C340.11 240.324 340.95 236.989 343.239 230.212C339.607 146.392 338.906 102.167 339.418 24.6621C339.43 7.48323 321.5 8.09635 298.645 7.45554Z"
              fill={background}
              filter="drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.10))"
              transition="fill 0.2s ease-in-out"
            />
          </g>
          {/* inner box */}
          <Box
            as="path"
            d="M317.971 126.932C317.971 126.932 163.675 126.153 124.336 126.801C84.9977 127.449 36.3347 126.741 36.3347 126.741L36.2744 215.564L136.547 216.592L319.287 216.716L317.971 126.932Z"
            fill="white"
          />
          {/* hello */}
          <Box as="g" fill={hello} transition="fill 0.2s ease-in-out">
            <path d="M97.5249 76.9006L97.1167 60.3129L78.7944 60.7637L79.2025 77.3514L71.0713 77.5515L70.0977 37.9796L78.2289 37.7795L78.6223 53.7709L96.9447 53.3201L96.5512 37.3287L104.682 37.1286L105.656 76.7005L97.5249 76.9006Z" />
            <path d="M119.208 76.028L118.571 36.4492L146.384 36.0014L146.497 42.9954L126.816 43.3123L126.963 52.4209L142.794 52.1659L142.907 59.16L127.075 59.4149L127.228 68.903L147.614 68.5747L147.726 75.5688L119.208 76.028Z" />
            <path d="M159.213 75.3611L160.211 35.7897L168.342 35.9946L167.521 68.5732L186.385 69.0486L186.209 76.0413L159.213 75.3611Z" />
            <path d="M196.277 75.3654L196.5 35.7821L204.634 35.8281L204.45 68.4165L223.32 68.5231L223.28 75.518L196.277 75.3654Z" />
            <path d="M244.744 74.3765C232.81 73.2956 225.511 64.3043 226.607 52.2075C227.703 40.1107 236.498 32.5769 248.432 33.6579C260.421 34.7437 267.719 43.735 266.624 55.8317C265.528 67.9285 256.733 75.4623 244.744 74.3765ZM234.978 52.9656C234.264 60.8501 238.144 66.7006 245.38 67.356C252.671 68.0163 257.539 62.9581 258.253 55.0736C258.967 47.1891 255.087 41.3386 247.796 40.6783C240.56 40.0229 235.692 45.0811 234.978 52.9656Z" />
          </Box>
          {/* my name is */}
          <Box as="g" fill={hello} transition="fill 0.2s ease-in-out">
            <path d="M225.593 98.5924C226.806 100.152 228.114 100.702 229.896 100.696C232.014 100.689 232.783 99.8353 232.779 98.8456C232.776 97.8954 232.161 97.5214 230.339 97.2305L227.864 96.8429C225.388 96.4552 223.84 95.2727 223.832 92.838C223.822 89.948 226.43 88.2963 229.518 88.286C231.695 88.2788 233.994 89.0035 235.406 91.0773L233.353 92.7073C232.299 91.3252 230.912 90.7755 229.506 90.7802C227.883 90.7856 226.737 91.5219 226.741 92.6106C226.744 93.6003 227.3 93.9547 228.864 94.1871L231.122 94.5358C233.776 94.9427 235.679 95.8469 235.688 98.5587C235.698 101.488 233.328 103.199 229.825 103.21C226.955 103.22 225.25 102.632 223.521 100.381L225.593 98.5924Z" />
            <path d="M216.799 103.016L216.751 88.566L219.72 88.5561L219.768 103.006L216.799 103.016Z" />
            <path d="M196.084 104.276L196.036 89.8255L206.19 89.7916L206.199 92.3451L199.013 92.3691L199.024 95.6946L204.804 95.6753L204.813 98.2288L199.033 98.2481L199.044 101.712L206.487 101.687L206.496 104.241L196.084 104.276Z" />
            <path d="M191.686 89.2279L191.077 103.665L188.11 103.54L188.584 92.3066L188.545 92.3049L184.451 103.385L181.722 103.27L178.518 91.8816L178.478 91.8799L178.004 103.113L175.196 102.995L175.805 88.5575L180.433 88.7529L183.319 99.6922L183.359 99.6939L187.157 89.0367L191.686 89.2279Z" />
            <path d="M168.749 103.385L167.491 100.064L161.433 100.084L160.257 103.413L157.288 103.423L162.683 88.9549L166.206 88.9432L171.916 103.374L168.749 103.385ZM162.316 97.6065L166.572 97.5923L164.415 91.8788L164.355 91.879L162.316 97.6065Z" />
            <path d="M151.522 88.9802L154.33 88.8495L155.002 103.284L151.284 103.457L145.588 94.3292L144.9 93.1723L145.392 103.731L142.584 103.862L141.912 89.4274L145.63 89.2544L151.266 98.3849L152.019 99.6577L151.522 88.9802Z" />
            <path d="M131.311 89.6195L125.325 98.3757L124.926 103.626L121.965 103.401L122.364 98.1507L117.771 88.5903L121.106 88.8438L124.176 95.4694L128.192 89.3825L131.311 89.6195Z" />
            <path d="M113.758 88.137L113.806 102.587L110.837 102.597L110.8 91.3536L110.76 91.3538L107.175 102.609L104.444 102.618L100.724 91.3872L100.685 91.3874L100.722 102.631L97.9115 102.64L97.8633 88.19L102.495 88.1746L105.877 98.9712L105.916 98.9711L109.225 88.1521L113.758 88.137Z" />
          </Box>
        </svg>
        <AutoResizeText
          w="18rem"
          h="4rem"
          transform="rotate(3deg)"
          pos="absolute"
          left="2rem"
          bottom="4rem"
          display="grid"
          placeContent="center"
          fontFamily="Caveat"
          lineHeight={1}
          whiteSpace="nowrap"
          textTransform="uppercase"
          p={1}
        >
          {preferredName}
        </AutoResizeText>

        <AnimatePresence mode="wait">
          {definedRole && !customRoleTitle && (
            <Box
              as={motion.div}
              key={role}
              pos="absolute"
              right="-3rem"
              bottom="-2rem"
              display="grid"
              initial={{ scale: 0, rotate: 0, opacity: 0 }}
              animate={{ scale: 1, rotate: "-5deg", opacity: 1 }}
              exit={{ scale: 0, rotate: 0, opacity: 0 }}
            >
              <Box
                as="svg"
                gridArea="1/1"
                width="264"
                height="119"
                viewBox="-10 -10 264 119"
              >
                <title>Role</title>
                <g filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))">
                  <Box
                    as="path"
                    d={TAGS[role]}
                    fill={light}
                    filter="drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.10))"
                  />
                </g>
              </Box>
              <Box
                gridArea="1/1"
                display="grid"
                placeContent="center"
                fontSize="1.5rem"
                lineHeight="1.1"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="0.06rem"
                color="brandPalette.neutral.darkest"
                px="2rem"
                textAlign="center"
                w="264px"
              >
                {role}
              </Box>
            </Box>
          )}
        </AnimatePresence>
      </Box>
      <AnimatePresence>
        {customRoleTitle && roleTitle && (
          <Box
            as={motion.div}
            key="custom"
            pos="absolute"
            left={0}
            right={0}
            top="100%"
            display="grid"
            placeContent="center"
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
          >
            <AutoResizeText
              maxW="100%"
              bg={light}
              maxFontSize={24}
              fontWeight="bold"
              letterSpacing="0.06rem"
              textTransform="uppercase"
              whiteSpace="nowrap"
              boxShadow="md"
              h="3rem"
              lineHeight="1.5rem"
              padding="0.75rem"
              borderRadius="1.5rem"
            >
              {roleTitle}
            </AutoResizeText>
          </Box>
        )}
      </AnimatePresence>
    </Box>
  )
}

const AutoResizeText: React.FC<
  BoxProps & { minFontSize?: number; maxFontSize?: number }
> = ({ minFontSize = 4, maxFontSize = 48, children, ...props }) => {
  const boxRef = useRef<HTMLElement>(null)

  useLayoutEffect(() => {
    const box = boxRef.current
    if (!box) return

    let min = minFontSize
    let max = maxFontSize

    while (max - min > 0.5) {
      const size = (min + max) / 2
      box.style.fontSize = `${size}px`
      if (
        box.scrollHeight > box.offsetHeight ||
        box.scrollWidth > box.offsetWidth
      ) {
        max = size - 1
      } else {
        min = size
      }
    }
  }, [children])

  return (
    <Box ref={boxRef} overflow="hidden" {...props}>
      {children}
    </Box>
  )
}
