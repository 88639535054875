import { Button, HStack, Icon } from "@chakra-ui/react"
import React from "react"

import { ClickIcon } from "Icons/ClickIcon"
import { HeatMapIcon } from "Icons/HeatMapIcon"
import { ImageIcon } from "Icons/ImageIcon"
import { ScreenView } from "../ScreenResults"

interface ScreenResultTabsProps {
  clickView: ScreenView
  setClickView: (view: ScreenView) => void
}

export function ScreenResultTabs({
  clickView,
  setClickView,
}: ScreenResultTabsProps) {
  return (
    <HStack
      spacing={0}
      px={4}
      borderBottomColor="gray.200"
      borderBottomWidth={1}
    >
      {/* Can't have tabs in tabs so use buttons*/}
      <Button
        aria-selected={clickView === "IMAGE"}
        variant="tab"
        onClick={() => setClickView("IMAGE")}
        data-qa="image-view"
      >
        <Icon as={ImageIcon} mr={2} />
        Image
      </Button>
      <Button
        aria-selected={clickView === "HEAT_MAP"}
        variant="tab"
        onClick={() => setClickView("HEAT_MAP")}
        data-qa="heat-map-view"
      >
        <Icon as={HeatMapIcon} mr={2} />
        Heat map
      </Button>
      <Button
        aria-selected={clickView === "CLICK_MAP"}
        variant="tab"
        onClick={() => setClickView("CLICK_MAP")}
        data-qa="click-map-view"
      >
        <Icon as={ClickIcon} mr={2} />
        Clicks
      </Button>
    </HStack>
  )
}
