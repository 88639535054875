import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  StackProps,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "Icons/ChevronDownIcon"
import React from "react"

export const PathsList: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <Stack spacing={4}>{children}</Stack>
}

export const PathRow: React.FC<React.PropsWithChildren<StackProps>> = ({
  children,
  ...stackProps
}) => {
  return (
    <Stack
      borderWidth={1}
      borderColor="gray.200"
      rounded="md"
      p="4"
      spacing={4}
      {...stackProps}
    >
      {children}
    </Stack>
  )
}

export const PathImages: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Box position="relative" role="group">
      <Flex gap="2" overflowX="auto">
        {children}
      </Flex>
    </Box>
  )
}

interface PathSortSelectProps<
  T extends { [key in K]: string },
  K extends string,
> {
  onSelect: (value: keyof T) => void
  value: K
  options: T
}

export const PathSortSelect = <
  T extends { [key in K]: string },
  K extends string,
>({
  onSelect,
  value,
  options,
}: PathSortSelectProps<T, K>) => {
  return (
    <HStack>
      <Spacer />
      <FormLabel>Sort by</FormLabel>
      <Box>
        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            variant="outline"
            rightIcon={<Icon as={ChevronDownIcon} />}
            fontWeight="normal"
            flexShrink={0}
            w="auto"
            rounded="md"
            value={value}
          >
            {options[value]}
          </MenuButton>
          <MenuList>
            {Object.entries(options).map(([sortTypeValue, sortTypeLabel]) => {
              if (typeof sortTypeLabel !== "string") return

              return (
                <MenuItem
                  value={sortTypeValue}
                  key={sortTypeValue}
                  onClick={() => onSelect(sortTypeValue as keyof T)}
                  fontWeight={value === sortTypeValue ? "bold" : "normal"}
                >
                  {sortTypeLabel}
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  )
}
