import { Icon } from "@chakra-ui/react"
import { CreditCardIcon } from "Icons/CreditCardIcon"
import { LockIcon } from "Icons/LockIcon"
import { OrganizationIcon } from "Icons/OrganizationIcon"
import { SettingsSlidersIcon } from "Icons/SettingsSlidersIcon"
import { UserProfileIcon } from "Icons/UserProfileIcon"
import { UsersIcon } from "Icons/UsersIcon"
import { SideNav, SideNavLink } from "Shared/components/SideNav/SideNav"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { ROUTES } from "../routes"

type SettingsSideNav = React.FC<React.PropsWithChildren<unknown>>

export const SettingsSideNav: SettingsSideNav = () => {
  const currentUser = useCurrentUser()

  const featureAccessItem = (
    <SideNavLink
      leftIcon={<Icon as={SettingsSlidersIcon} />}
      to={ROUTES.SETTINGS.FEATURE_ACCESS.path}
    >
      Feature access
    </SideNavLink>
  )

  return (
    <SideNav>
      <SideNavLink
        leftIcon={<Icon as={UserProfileIcon} />}
        to={ROUTES.SETTINGS.PROFILE.path}
      >
        Profile
      </SideNavLink>
      {currentUser.role !== "admin" && featureAccessItem}
      {currentUser.role === "admin" && (
        <>
          <SideNavLink
            leftIcon={<Icon as={OrganizationIcon} />}
            to={ROUTES.SETTINGS.ORGANIZATION.path}
          >
            Organization
          </SideNavLink>
          <SideNavLink
            leftIcon={<Icon as={UsersIcon} />}
            to={ROUTES.SETTINGS.TEAM.path}
          >
            Team members
          </SideNavLink>
          <SideNavLink
            leftIcon={<Icon as={CreditCardIcon} />}
            to={ROUTES.SETTINGS.BILLING.path}
          >
            Plan &amp; billing
          </SideNavLink>
          {featureAccessItem}
          <SideNavLink
            leftIcon={<Icon as={LockIcon} />}
            to={ROUTES.SETTINGS.SECURITY.path}
          >
            Security
          </SideNavLink>
        </>
      )}
    </SideNav>
  )
}
