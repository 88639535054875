import {
  Box,
  Flex,
  HeadingProps,
  Icon,
  Input,
  InputProps,
  Text,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react"
import { Button, ButtonProps } from "DesignSystem/components"
import { EditFilledIcon } from "Icons/EditFilledIcon"
import React, { FormEvent, useRef } from "react"

type InPlaceEditorProps = {
  value: string
  handleSave: (newValue: string) => Promise<void>
  textStyle: string
  inputProps?: InputProps
  buttonProps?: ButtonProps
  defaultIsEditing?: boolean
  triggerRef?: React.Ref<HTMLDivElement>
} & HeadingProps

export const InPlaceEditor: React.FC<InPlaceEditorProps> = ({
  value,
  handleSave,
  inputProps,
  buttonProps,
  defaultIsEditing = false,
  textStyle,
  triggerRef,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isEditing, { on: startEditing, off: stopEditing }] =
    useBoolean(defaultIsEditing)
  const [isSaving, { on: startSaving, off: stopSaving }] = useBoolean(false)

  useOutsideClick({
    ref: inputRef,
    handler: stopEditing,
  })

  if (!isEditing) {
    return (
      <Text
        ref={triggerRef}
        display="flex"
        alignItems="center"
        textStyle={textStyle}
        noOfLines={1}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          startEditing()
        }}
        sx={{
          cursor: "pointer",
          "&:not(:hover) svg": {
            display: "none",
          },
        }}
        {...rest}
      >
        {value}
        <Icon
          as={EditFilledIcon}
          color="ds.icon.subtle"
          ms={2}
          transform="translateY(-0.15em)"
        />
      </Text>
    )
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const formData = e.target as typeof e.target & {
      value: { value: string }
    }
    const newName = formData.value.value

    startSaving()
    try {
      await handleSave(newName)
      stopEditing()
    } finally {
      // The handleSave function will implement error handling, but if it raises
      // we need to make sure the loading spinner goes away.
      stopSaving()
    }
  }

  return (
    <Box
      as="form"
      w="full"
      onSubmit={handleSubmit}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
      }}
    >
      <Flex gap={2} w="full">
        <Input
          ref={inputRef}
          autoFocus
          name="value"
          isRequired
          defaultValue={value}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              stopEditing()
            }
          }}
          {...inputProps}
        />
        <Button
          type="submit"
          variant="primary"
          size="compact"
          isLoading={isSaving}
          {...buttonProps}
        >
          Save
        </Button>
      </Flex>
    </Box>
  )
}
